import * as actions from '../actions/tags';

export const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_TAGS:
      return action.tags
  
    default:
      return state
  }
}