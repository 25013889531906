import * as actions from '../../actions/projects/projects';


export const projectsInitialState = {
  results: [] as any[],
  count: 0,
  next: null,
  previous: null,
  project: {} as any,
  updatedProject: {} as any,
  loading: false,
  componentProjects: {
    results: [],
    count: 0,
    next: null,
    previous: null
  },
  projectView: {
    readonly: true
  }
};

export default function (state = projectsInitialState, action) {
  switch (action.type) {

    case actions.GET_PROJECTS_LIST:
      return {
        ...state,
        ...action.data
      }
    case actions.DELETE_PROJECTS:
      return { ...state, results: action.people }

    case actions.APPEND_PROJECTS:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }

    case actions.GET_PROJECT_DETAILS:
    case actions.CHANGE_PROJECT:
    case actions.CREATE_PROJECT:
    case actions.UPDATE_PROJECT:
      return {
        ...state,
        project: action.project,
        updatedProject: {
          id: action.project?.id
        }
      }


    case actions.CHANGE_PROJECT_FIELD:
      return {
        ...state,
        updatedProject: {
          ...state.updatedProject,
          ...action.field
        }
      }


    case actions.CLEAR_PROJECT:
    case actions.DELETE_PROJECT:
      return {
        ...state,
        project: {},
        updatedProject: {},
        count: --state.count
      }

    case actions.PROJECTS_CREATE_NOTE:
      return {
        ...state,
        project: {
          ...state.project,
          notes: [
            ...state.project.notes,
            action.note
          ]
        }
      }

    case actions.PROJECTS_UPDATE_NOTE:
      return {
        ...state,
        project: {
          ...state.project,
          notes: state.project.notes
            ? state.project.notes.map(note => note.id === action.note.id ? action.note : note)
            : []
        }
      }


    case actions.PROJECTS_DELETE_NOTE:
      return {
        ...state,
        project: {
          ...state.project,
          notes: state.project.notes
            ? state.project.notes.filter(note => note.id !== action.id)
            : []
        }
      }

    case actions.SET_ERRORS:
      return {
        ...state,
        project: {
          ...state.project,
          errors: action.errors
        }
      }


    case actions.CLEAR_TAGS:
      return {
        ...state,
        project: {
          ...state.project,
          tags: []
        }
      }

    case actions.CHANGE_SUMMARY:
      return {
        ...state,
        project: {
          ...state.project,
          cp_summary: action.summary
        }
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }


    case actions.SET_COMPONENT_PROJECTS:
      return {
        ...state,
        componentProjects: action.data
      }


    case actions.CLEAR_PROJECTS_LOCAL:
      return {
        ...state,
        componentProjects: {
          results: [],
          count: 0,
          next: null,
          previous: null
        }
      }


    case actions.CLEAR_ALL:
      return { ...projectsInitialState }
  

    case actions.SET_READONLY:
      return {
        ...state,
        projectView: {
          ...state.projectView,
          readonly: action.state
        }
      }


    default:
      return state
  }
}