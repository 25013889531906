import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchPeoplePromise } from '../actions/people/people';
import { useShowAll, useTimeout } from '../shared/hooks';
import { MembershipType } from '../shared/types';
import { Theme } from '../theme';
import { arrayRange } from '../utils/utils';
import MembershipList from './project/membership-list/MembershipList';
import ShowAll from './ShowAll';
import TextInput from './TextInput';

const styles = (theme: Theme) => createStyles({
  root: {

  },
  header: {
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 2.18,
    letterSpacing: 0.53,
    color: '#233539',
    margin: '0 0 3px'
  },
  searchWrapper: {
    marginBottom: '10px',
  },
  content: {},
  input: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.5,
    height: 'initial',
    color: '#4192ec',
  }
})

interface IProps extends WithStyles<typeof styles> {
  header: string
  type: MembershipType
  placeholder: string
  list: any[]
  children?: any
  noLink?: boolean
}

const SearchInput = (props: IProps) => {
  const { classes } = props;
  const showAll = useShowAll();
  const timeout = useTimeout(400);
  const dispatch = useDispatch();
  const cpLabels = useSelector((state: any) => state.types.provider_relation_types);
  const [list, setList] = useState<any[]>(props.list);
  const [searchList, setSearchList] = useState<any[]>([]);
  const [search, setSearch] = useState<string>('');




  const dispatchSearchPeople = (str: string): any => {
    return dispatch(searchPeoplePromise(str, props.type))
  }



  const getExistingPeopleIds = (): number[] => {
    return props.list.map((item: any) => item.person.id)
  }



  const getPeopleList = (list: any[]): any[] => {
    const existingPeopleIds = getExistingPeopleIds()
    return list
      .filter((item: any) => !existingPeopleIds.includes(item.id))
      .map((item: any) => ({ person: item }))
  }



  const handleSearch = () => {
    search !== ''
      ? timeout.reset(() => {
        dispatchSearchPeople(search)
          .then((res: any[]) => {
            const existingIds = getExistingPeopleIds();
            const newList = res.filter((item: any) => !existingIds.includes(item.id))
            setSearchList(getPeopleList(newList))
          })
      })
      : setSearchList([])
  }



  const filterByFields = (item: any, searchStr: string): boolean => {
    let included = false;
    ['first_name', 'last_name', 'company'].map((field: string) => {
      const searchWords = searchStr.split(' ');
      searchWords.map((word: string) => {
        if (item[field].toLowerCase().includes(word)) {
          included = true
        }
      })
    })

    return included
  }



  const getCPLabel = (id: number): string => {
    const label = cpLabels.find((item: any) => item.id === id);
    return (label && label.name) || 'Label';
  }



  const filterPropsList = (): any[] => {
    const searchStr = search.toLowerCase();
    return props.list.filter((item: any) => {
      let included = filterByFields(item.person, searchStr);
      const cpLabel = getCPLabel(item.state);
      if (included) return true;

      if (item.state) {
        switch (props.type) {
          case MembershipType.Team:
            break;
          case MembershipType.CapitalProvider:
            return cpLabel.toLowerCase().includes(searchStr)
        }
      }

      return included
    })
  }



  const getFilteredList = (): any[] => {
    if (!search) return props.list;
    return filterPropsList()
  } 



  const filterList = (): void => {
    const newList = !!search
      ? getFilteredList()
      : props.list;
    setList(showAll.value ? newList : arrayRange(0, 4, newList))
  }


  


  useEffect(() => {
    filterList();
  }, [search, props.list, showAll.value])



  useEffect(() => {
    handleSearch()
  }, [search])



  useEffect(() => {
    const existingIds = getExistingPeopleIds();
    const newList = searchList.filter((item: any) => !existingIds.includes(item.person.id))
    setSearchList(newList)
  }, [props.list])






  return (
    <div className={classes.root}>
      <h3 className={classes.header}>{props.header}</h3>
      <div className={classes.searchWrapper}>
        <TextInput
          value={search}
          onChange={(value: string) => setSearch(value)}
          placeholder={props.placeholder}
          inputStyle={classes.input}
        />
      </div>
      <div className={classes.content}>
        <MembershipList list={list} type={props.type} noLink={props.noLink} />
        {props.list.length > 4 || (!!search && list.length > 4)
          ? <ShowAll showAll={showAll} count={!!search ? list.length : props.list.length} />
          : null
        }
      </div> 
      {searchList && searchList.length > 0 &&
        <div className={classes.content}>
          <MembershipList new list={searchList} type={props.type} noLink={props.noLink} />
        </div>
      }

    </div>
  )
}


export default withStyles(styles)(SearchInput)