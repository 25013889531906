import * as actions from '../actions/external-contacts';

export const initialState = {
  results: [] as any[],
  count: 0,
  next: null,
  previous: null,
  initiated: false,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_EXTERNAL_CONTACTS:
      return {
        ...state,
        ...action,
        initiated: true
      }


    case actions.REMOVE_EXTERNAL_CONTACT:
      return {
        ...state,
        results: state.results.filter((contact: any) => !action.ids.includes(contact.id)),
        count: --state.count
      }


    case actions.APPEND_EXTERNAL_CONTACTS:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }


    case actions.SET_INITIATED_EXTERNAL_CONTACTS:
      return {
        ...state,
        initiated: action.state
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }
      

    default:
      return state
  }
}