import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findEmailTemplates, getEmailTemplates } from '../../actions/email-templates';
import { Theme } from '../../theme';
import SlideInContainerList from '../SlideInContainerList';

const styles = (theme: Theme) => createStyles({
  root: {
    zIndex: 1,
    height: 'calc(100vh - 59px)',
    maxHeight: 'calc(100vh - 59px)',
  }
})

interface IProps extends WithStyles<typeof styles> {
  onClose: () => any;
  onOpen: (item: any) => any;
  opened?: boolean;
  isCompany: boolean;
}

const LoadTemplate = (props: IProps) => {
  const { classes, isCompany } = props;
  const dispatch = useDispatch();
  const templates = useSelector((state: any) => state.emailManagement.templates.results);


  const onOpen = (e: any, item: any): void => {
    e.preventDefault();
    e.stopPropagation();

    props.onOpen(item)
  }

  const onSearchChange = (search: string): Promise<any> => {
    return search
      ? dispatchFindEmailTemplates(search)
      : dispatchGetEmailTemplates();
  }



  const dispatchGetEmailTemplates = (): any => {
    return dispatch(getEmailTemplates([], isCompany ? 'is_company=1' : ''))
  }

  const dispatchFindEmailTemplates = (search: string): any => {
    return dispatch(findEmailTemplates(`${search}${isCompany ? '&is_company=1' : ''}`))
  }


  


  return (
    <SlideInContainerList
      items={templates}
      header="Load Template"
      opened={props.opened}
      itemField="name"
      itemsName="templates"
      onOpen={onOpen}
      onClose={props.onClose}
      onSearchChange={onSearchChange}
      className={classes.root}
    />
  )
}


export default withStyles(styles)(LoadTemplate)