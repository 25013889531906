import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { changeImportedDuplicates, clearImportedDuplicates, getImportedDuplicate, mergeImportedDuplicate } from '../../actions/contact-management/imported-duplicates';
import { ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES } from '../../constants/routes';
import { currentImportedDuplicateActions } from '../../reducers/contact-management/current-imported-duplicate';
import { Theme } from '../../theme';
import DuplicatesMergeContainer from './DuplicatesMergeContainer';



const styles = (theme: Theme) => createStyles({
  
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  id?: number;
  className?: string;  
}

const ImportedDuplicateMergePage = (props: IProps) => {
  const currentDuplicate = useSelector((state: any) => state.contactManagement.currentImportedDuplicate.data);
  const duplicates = useSelector((state: any) => state.contactManagement.importedDuplicates.results);


  
  const onMerge = (withCP: boolean = false, dontIncludeCP?: boolean): any => {
    const body = withCP ? currentDuplicate : { ...currentDuplicate, capital_provider: dontIncludeCP ? undefined : null };
    return mergeImportedDuplicate(duplicates[1].id, duplicates[0].id, body)
  }



  return (
    <DuplicatesMergeContainer isImportedDuplicates
      duplicateIds={props.id}
      duplicates={duplicates}
      currentDuplicate={currentDuplicate}
      backURL={ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES}
      actions={currentImportedDuplicateActions}
      actionMerge={onMerge}
      actionGetById={getImportedDuplicate}
      actionChangeField={changeImportedDuplicates}
      actionClear={clearImportedDuplicates}
      backLabel="Back to Imported Duplicates"
    />
  )
}


export default withStyles(styles)(
  withRouter(ImportedDuplicateMergePage)
)