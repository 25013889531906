import * as React from "react";
import { Theme, palette } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  root: {

  },
  header: {
    ...theme.typography.body2,
    fontSize: 11,
    textTransform: 'uppercase',
    marginBottom: 16,
    fontWeight: 500,
    color: '#233539'
  },
  children: {

  }
});

interface IProps extends WithStyles<typeof styles> {
  header?: any;
  children?: any;
  hidden?: boolean;
}

const SectionWithHeader = (props: IProps) => {
  const { classes } = props;



  if(props.hidden) {
    return null
  }



  return (
    <div className={classes.root}>

      {props.header && (
        <div className={classes.header}>{props.header}</div>
      )}
      
      <div className={classes.children}>{props.children}</div>

    </div>
  );
}

export default withStyles(styles)(SectionWithHeader)