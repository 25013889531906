/**
 * Actions
 */

import { TableType } from "../../shared/types";
import { ISort } from "../../shared/interfaces";

export const LOADING_MORE     = 'LIST_LOADING_MORE';
export const SET_SELECTED     = 'LIST_SET_SELECTED';
export const SELECTED_ADD     = 'LIST_SELECTED_ADD';
export const SELECTED_REMOVE  = 'LIST_SELECTED_REMOVE';
export const SET_ROWS         = 'LIST_SET_ROWS';
export const SET_FILTERS      = 'SET_FILTERS';
export const SET_ONE_FILTERS  = 'SET_ONE_FILTERS'; 
export const SET_REF          = 'LIST_SET_REF';



/**
 * Action creators
 */

export function setLoadingMore(tableType: TableType, isLoading: boolean): object {
  return {type: LOADING_MORE, tableType, isLoading}
}

export function setSelectedForList(tableType: TableType, selected: number[]): object {
  return {type: SET_SELECTED, tableType, selected}
}

export function clearSelectedForList(tableType: TableType): object {
  return {type: SET_SELECTED, tableType, selected: []}
}

export function selectedAdd(tableType: TableType, id: number): object {
  return {type: SELECTED_ADD, tableType, id}
}

export function selectedRemove(tableType: TableType, id: number): object {
  return {type: SELECTED_REMOVE, tableType, id}
}

export function setListRows(tableType: TableType, rows: any[]): object {
  return {type: SET_ROWS, tableType, rows}
}

export function setFilters(tableType: TableType, filters: any): object {
  return {type: SET_FILTERS, tableType, filters}
}

export function setOneFilter(tableType: TableType, name: string, value: any): object {
  return {type: SET_ONE_FILTERS, tableType, name, value}
}

export function setListRef(tableType: TableType, ref: any): object {
  return {type: SET_REF, tableType, ref}
}