import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const DisplayColumnsIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="15" height="15" viewBox="0 0 15 15">
      <g fill="currentColor">
        <path d="M13.49 0H1.498C.673 0 0 .671 0 1.499v11.99c0 .826.671 1.5 1.499 1.5h11.99c.826 0 1.5-.672 1.5-1.5V1.5c0-.826-.672-1.499-1.5-1.499zm0 13.49H1.498V1.498h11.99v11.99z"/>
        <path d="M4.496 1.499H5.995000000000001V13.49H4.496zM8.993 1.499H10.492V13.49H8.993z"/>
      </g>
    </SvgIcon>
  );
};