import capitalProviderService from '../services/capital-provider';
import { SelectableCapitalProviderFields, SelectableCapitalProviderTypes } from '../shared/models/Person';
import { setError } from './common/notifications';
import { getErrorCode } from '../services/helpers/helpers';
import { PrepareObject } from '../shared/types';
import { prepareFields } from './helpers/helpers';


/**
 * Actions
 */

export const UPDATE_PROVIDER  = "UPDATE_PROVIDER";
export const CHANGE_PROVIDER  = "CHANGE_PROVIDER";
export const SET_PROVIDER     = "SET_PROVIDER"
export const CLEAR_PROVIDER   = "CLEAR_PROVIDER"

export const SET_ERRORS       = "SET_ERRORS"



/**
 * Action creators
 */

export function updateSingleProvider(capitalProvider: any): object {
  return {type: UPDATE_PROVIDER, capitalProvider}
}

export function changeProvider(capitalProvider: any): object {
  return {type: CHANGE_PROVIDER, capitalProvider}
}

export function setProvider(capitalProvider: any): object {
  return {type: SET_PROVIDER, capitalProvider}
}

export function setProviderErrors(errors: object): object {
  return {type: SET_ERRORS, errors}
}

export function clearProvider(): object {
  return {type: CLEAR_PROVIDER}
}


/**
 * Async action creators
 */

export const getProvider = (id: number) => {
  return (dispatch, getState) => {
    return capitalProviderService.getProvider(id)
      .then((res: any) => {
        const response = prepareFields(PrepareObject.CPResponse, res, getState().types);
        dispatch(setProvider(response))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log(err)
        const errors = err && err.response && err.response.data;
        if(errors) {
          dispatch(setProviderErrors(errors))
        }
        dispatch(setError("Couldn't find capital provider", err))
        return Promise.reject(false)
      })
  }
}

export const updateProvider = (capitalProvider: any) => {
  return (dispatch, getState) => {
    const body = prepareFields(PrepareObject.CPRequest, capitalProvider);
    return capitalProviderService.updateCapitalProvider(body)
      .then((res: any) => {
        const response = prepareFields(PrepareObject.CPResponse, res, getState().types);
        dispatch(updateSingleProvider(response))
        return Promise.resolve(response)
      })
      .catch(err => {
        const errors = err && err.response && err.response.data;
        if(errors) {
          dispatch(setProviderErrors(errors))
        }
        dispatch(setError("Couldn't update capital provider", err))
        return Promise.reject(false)
      })
  }
}

export const createProvider = (capitalProvider: any) => {
  return (dispatch, getState) => {
    const body = prepareFields(PrepareObject.CPRequest, capitalProvider);
    return capitalProviderService.createCapitalProvider(body)
      .then((res: any) => {
        const response = prepareFields(PrepareObject.CPResponse, res, getState().types);
        dispatch(setProvider(response))
        return Promise.resolve(response)
      })
      .catch(err => {
        const errors = err && err.response && err.response.data;
        if(errors) {
          dispatch(setProviderErrors(errors))
        }
        dispatch(setError("Couldn't create capital provider", err))
        return Promise.reject(false)
      })
  }
}