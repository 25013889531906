import React, { useEffect, useState } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import ClearIcon from '@material-ui/icons/Clear';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '15px 16px',
    backgroundColor: '#fff',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    boxShadow: '0 -1px 6px 0 rgba(189, 194, 196, 0.6)',
    boxSizing: 'border-box'
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.56,
    color: theme.palette.primary.main,
    width: '81%',
    display: 'flex'
  },
  icon: {
    fontSize: 19,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 19,
    right: 14
  }
})

interface IProps extends WithStyles<typeof styles> {
  
}

const MobileWarning = (props: IProps) => {
  const { classes } = props;
  const [isClosed, setIsClosed] = useState<boolean>(false);


  const onClose = (e: any): void => {
    localStorage.setItem('mobileWarningSeen', 'true');
    setIsClosed(true)
  }


  useEffect(() => {
    if(localStorage.getItem('mobileWarningSeen')) {
      setIsClosed(true)
    }
  }, [localStorage.getItem('mobileWarningSeen')])


  if(isClosed) return null;


  return (
    <div className={classes.root}>
      <span className={classes.text}>
        We recommend using the desktop version of FinTech8 to access all features.
      </span>
      <ClearIcon className={classes.icon} onClick={onClose} />
    </div>
  )
}


export default withStyles(styles)(MobileWarning)