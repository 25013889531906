import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { RadioProps, Radio as RadioMU } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  checkedIcon: {
    fontSize: 18,
    color: theme.palette.primary.main
  },
  uncheckedIcon: {
    fontSize: 18,
    color: theme.palette.primary.main
  }
})

interface IProps extends WithStyles<typeof styles> {
  className?: string;
}

const Radio = (props: IProps) => {
  const { classes, ...otherProps } = props;

  return (
    <RadioMU icon={<RadioButtonUncheckedIcon className={classes.uncheckedIcon} />}
      checkedIcon={<RadioButtonCheckedIcon className={classes.checkedIcon} />}
      classes={{
        root: classNames(classes.root, props.className)
      }}
      {...otherProps}
    />
  )
}


export default withStyles(styles)(Radio)