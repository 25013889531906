import { ProfileType } from "../shared/types";
import { IPerson } from "../shared/models/Person";
import { IProject, MembershipState } from "../shared/models/Project";

export function isAuthenticated(user: any) : boolean {
  return !!user?.id
}

export function isAdmin(user: any) : boolean {
  return user?.profile_type !== undefined && user.profile_type === ProfileType.Admin
}

export function isOwner(user: IPerson, team: any[]) : boolean {
  return user && team && team
    .filter(membership => membership.state === MembershipState.Owner)
    .map(membership => membership.person.id)
    .includes(user.id)
}

export function isMember(user: IPerson, team: any[]) : boolean {
  return user && team && team
    .map(membership => membership.person.id)
    .includes(user.id)
}

export function isGuest(user: any) : boolean {
  return user?.profile_type !== undefined && user.profile_type === ProfileType.Guest
}

export function isClient(user: any) : boolean {
  return user?.profile_type !== undefined && user.profile_type === ProfileType.Client
}

export function isUser(user: any) : boolean {
  return user?.profile_type !== undefined && user.profile_type === ProfileType.User
}

export function getUsername(user: any) : string { 
  if(!user || user == {}) return 'User No Longer Exists';
  return `${user.first_name || ''} ${user.last_name || ''}`
}

export function getUsernameWithCompany(user: any) : string { 
  if(!user?.first_name) return 'User No Longer Exists';
  return `${user.first_name || ''} ${user.last_name || ''} - ${user.company || 'No company'}`
}