import emailDraftsService from '../services/email-drafts';
import { currentEmailDraftsActions } from '../reducers/email/current-drafts';
import { updateFromFormGenerator, sanitizeData } from './common/form';
import { ISort } from '../shared/interfaces';
import { formatSortObject } from './helpers/helpers';
import { setError } from './common/notifications';

/**
 * Actions
 */

export const SET_DRAFTS             = 'SET_DRAFTS';
export const APPEND_DRAFTS          = 'APPEND_DRAFTS';
export const REMOVE_MULTIPLE_DRAFTS = 'REMOVE_MULTIPLE_DRAFTS';
export const REMOVE_DRAFT           = 'REMOVE_DRAFT';
export const SET_LOADING            = 'DRAFTS_SET_LOADING';
export const SET_LOADING_FILES      = 'DRAFTS_SET_LOADING_FILES';



/**
 * Action creators
 */

export function setDrafts(drafts: any): object {
  return {type: SET_DRAFTS, drafts}
}

export function appendDrafts(drafts: any): object {
  return {type: APPEND_DRAFTS, ...drafts}
}

export function removeMultipleDrafts(ids: number[]): object {
  return {type: REMOVE_MULTIPLE_DRAFTS, ids}
}

export function removeDraft(id: number): object {
  return {type: REMOVE_DRAFT, id}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}

export function setLoadingFiles(state: boolean): object {
  return {type: SET_LOADING_FILES, state}
}



/**
 * Async action creators
 */

export const getEmailDrafts = (sort: ISort[], search: string) => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));

    return emailDraftsService.getEmailDrafts(body)
      .then((res: any) => dispatch(setDrafts(res)))
      .catch(err => {
        console.log('An error occured while getting email drafts.')
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getNextDrafts = (): any => {
  return (dispatch, getState) => {
    const nextURL = getState().emailManagement?.drafts?.next;
    dispatch(setLoading(true));

    
    if(!nextURL) return;

    return emailDraftsService.getNextDrafts(nextURL)
      .then((res: any) => {
        dispatch(appendDrafts(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting email drafts.');
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const deleteMultipleDrafts = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));


    return emailDraftsService.deleteMultipleDrafts(ids)
      .then((res: any) => dispatch(removeMultipleDrafts(ids)))
      .catch(err => {
        console.log('An error occured while removing email drafts.')
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const createDraft = (body?: any) => {
  return (dispatch) => {
    return emailDraftsService.createDraft(body)
      .then((res: any) => {
        dispatch(currentEmailDraftsActions.actionUpdateField(res.id, res))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured.');
        return Promise.reject(err)
      })
  }
}

export const getDraft = (id: number) => {
  return (dispatch) => {
    return emailDraftsService.getDraft(id)
      .then((res: any) => {
        dispatch(currentEmailDraftsActions.actionUpdateField(res.id, res))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured.');
        return Promise.reject(err)
      })
  }
}

export const saveDraft = (body: any) => {
  return (dispatch) => {
    const data = sanitizeData(body);
    return emailDraftsService.saveDraft(data)
      .then((res: any) => {

      })
      .catch(err => {
        console.log('An error occured while saving an email draft.')
      })
  }
}

export const saveDraftAsTemplate = (body: any) => {
  return (dispatch) => {
    const data = sanitizeData(body);
    return emailDraftsService.saveDraft(data, true)
      .then((res: any) => {

      })
      .catch(err => {
        console.log('An error occured while saving an email template.')
      })
  }
}

export const uploadDraftFiles = (id: number, files: any) => {
  return (dispatch, getState) => {
    const state = getState().emailManagement.currentDrafts?.data?.[id];
    dispatch(setLoadingFiles(true));


    return emailDraftsService.uploadDraftFiles(id, files)
      .then((res: any) => {
        dispatch(currentEmailDraftsActions.actionUpdateField(id, {
          ...state,
          uploaded_files: res.uploaded_files
        }))
      })
      .catch(err => {
        console.log('An error occured while uploading email files.')
      })
      .finally(() => dispatch(setLoadingFiles(false)))
  }
}


export const deleteDraftFile = (id: number, fileId: number) => {
  return (dispatch, getState) => {
    const state = getState().emailManagement.currentDrafts?.data?.[id];
    dispatch(setLoadingFiles(true));


    return emailDraftsService.deleteDraftFile(fileId)
      .then((res: any) => {
        dispatch(currentEmailDraftsActions.actionUpdateField(id, {
          ...state,
          uploaded_files: state.uploaded_files.filter((file: any) => file.id !== fileId)
        }))
      })
      .catch(err => {
        console.log('An error occured while deleting email file.')
      })
      .finally(() => dispatch(setLoadingFiles(false)))
  }
}

export const sendDraft = (body: any) => {
  return (dispatch) => {
    const data = sanitizeData(body);
    dispatch(setLoading(true));


    return emailDraftsService.sendDraft(data)
      .then((res: any) => {
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while sending an email'));
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const sendCompanyDraft = (body: any) => {
  return (dispatch) => {
    const data = sanitizeData(body);
    dispatch(setLoading(true));


    return emailDraftsService.sendDraftAsCompany(data)
      .then((res: any) => {
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while sending an email'));
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const deleteDraft = (id: number) => {
  return (dispatch) => {
    return emailDraftsService.deleteDraft(id)
      .then((res: any) => {
        dispatch(removeDraft(id))
      })
      .catch(err => {
        console.log('An error occured while deleting email file.')
      })
  }
}



export const createForwardEmail = (id: number) => {
  return (dispatch) => {
    return emailDraftsService.createForwardEmail(id)
      .then((res: any) => {
        dispatch(currentEmailDraftsActions.actionUpdateField(res.id, res))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured.');
        return Promise.reject(err)
      })
  }
}