import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { setLoadedPage } from '../../actions/common/loading';
import { setPrintData } from '../../actions/common/print';
import { getNextProjects, getProjects } from '../../actions/projects/projects';
import List from '../../components/list/List';
import ListHeader from '../../components/list/ListHeader';
import { ROUTE_PROJECTS, ROUTE_PROJECT_NEW } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { IPrintData, ISort } from '../../shared/interfaces';
import { PrintType, TableType } from '../../shared/types';
import { Theme } from '../../theme';
import { isAdmin, isUser } from '../../utils/user';
import { goTo } from '../../utils/utils';



const styles = (theme: Theme) => createStyles({
  root: {
    
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  onPageLoaded: (page: string) => any;
}


const ProjectsListPage = (props: IProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const projects = useSelector((state: any) => state.projects);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);



  
  const hasFullAccess = () : boolean => {
    return isAdmin(user) || isUser(user)
  }



  const handleNext = (): Promise<any> => {
    return dispatchGetNextProjects();
  }




  const handleOpen = (e: any, id: number) : void => {
    goTo(e, `/projects/${id}/`, props.history)
  };




  const handleSort = (sort: ISort[], search: string) : Promise<any> => {
    return dispatchGetProjects(sort, search)
      .then((res: any) => {
        if(!pagesLoaded[ROUTE_PROJECTS]) {
          dispatch(setLoadedPage(ROUTE_PROJECTS))
        }
      })
  }



  const handlePrint = (e: any): any => {
    // const printData: IPrintData = { title: 'Projects', list: projects.results }
    // dispatch(setPrintData(PrintType.PROJECTS, printData));
    // window.open(`/print/projects`, '_blank')
  }



  const isInitialLoading = (): boolean => {
    return !pagesLoaded[ROUTE_PROJECTS]
  }




  const dispatchGetNextProjects = (): any => {
    return dispatch(getNextProjects())
  }



  const dispatchGetProjects = (sort: ISort[], search: string): any => {
    return dispatch(getProjects(sort, search))
  }


  



  useEffect(() => {
    ReactGA.pageview(props.location.pathname)
    
    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])





  

  return (
    <Fragment>
      <ListHeader
        loading={isInitialLoading()}
        addButtonRoute={ROUTE_PROJECT_NEW}
        fullAccess={hasFullAccess()}
        title="Projects"
        count={projects.count}
        onPrint={handlePrint}
      />

      <List
        loading={projects.loading}
        initialLoading={isInitialLoading()}
        rows={projects.results}
        count={projects.count}
        onOpen={handleOpen}
        fullAccess={hasFullAccess()}
        tableType={TableType.PROJECTS}
        onSort={handleSort}
        onNext={handleNext}
      />
    </Fragment>
  );
}



export default withStyles(styles)(
  withRouter(ProjectsListPage)
)