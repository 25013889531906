import Request from '../components/request/Request';
import { API_URL } from '../constants/api';


function getProvider(id: number) {
  return Request.get(`${API_URL}/capital_provider/${id}/`)
}

function updateCapitalProvider(capitalProvider: any) {
  return Request.patch(`${API_URL}/capital_provider/${capitalProvider.id}/`, capitalProvider)
}

function createCapitalProvider(capitalProvider: any) {
  return Request.post(`${API_URL}/capital_provider/`, capitalProvider)
}


export default {
  getProvider,
  updateCapitalProvider,
  createCapitalProvider
}