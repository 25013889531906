import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { ISearchRow, ISelectOptionBasic, SearchPerson } from '../../shared/models/Search';
import { SearchType } from '../../shared/types';
import { Theme } from '../../theme';
import SearchSelectCondition from './row-elements/SearchSelectCondition';
import SearchSelectField from './row-elements/SearchSelectField';
import SearchSelectType from './row-elements/SearchSelectType';
import SearchSelectValue from './row-elements/SearchSelectValue';
import { useSearchData } from './row-elements/hooks/advanced-search-hooks';

const styles = (theme: Theme) => createStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    alignSelf: 'flex-start'
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 10
  },
  subRow: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #d9e4e8',
    margin: '10px 0',
    padding: '14px 15px',
    '& $rowWrapper:last-child': {
      marginBottom: 0,
    }
  },
  deleteIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    right: -30,
    transform: 'translateY(-50%)',
    transition: 'transform .1s ease',
    cursor: 'pointer'
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center'
  },
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
  type: SearchType;
  children?: any;
  onDelete?: () => any;
}


const SearchRow = (props: IProps) => {
  const { classes, type, idx, subRowIdx } = props;
  const { row, subRow } = useSearchData(idx, subRowIdx);





  const isFieldClientClass = (): boolean => {
    return row.field?.id === SearchPerson.UserType && subRow?.field?.id === SearchPerson.ClientClass
  }



  const canDelete = (): boolean => {
    return !!props.onDelete && !isFieldClientClass()
  }







  return (
    <div className={classes.rowWrapper}>
      <div className={classes.row}>
        <div className={classes.rowContent}>

          <SearchSelectType
            idx={props.idx}
            subRowIdx={props.subRowIdx}
            type={type}
          />

          
          <SearchSelectField idx={props.idx} subRowIdx={props.subRowIdx} />

          <SearchSelectCondition idx={props.idx} subRowIdx={props.subRowIdx} />
          
          <SearchSelectValue idx={props.idx} subRowIdx={props.subRowIdx} />

        </div>
        {canDelete()
          ? <ClearIcon className={classes.deleteIcon} onClick={props.onDelete} />
          : null
        }
      </div>
      {props.children
        ? <div className={classes.subRow}>{props.children}</div>
        : null
      }
    </div>
  )
}




export default withStyles(styles)(SearchRow)