import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { clearPeopleLocal, searchPeopleLocal } from '../../../../actions/people/people';
import { countries } from '../../../../constants/countries';
import { useTimeout } from '../../../../shared/hooks';
import { SearchPerson, SearchProject, SearchTask } from '../../../../shared/models/Search';
import { Theme } from '../../../../theme';
import Autocomplete from '../../../Autocomplete';
import { useSearchData } from '../hooks/advanced-search-hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    minWidth: 250,
    maxWidth: 250
  }
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
  [otherProps: string]: any;
}

const SearchSelectValueAutocomplete = (props: IProps) => {
  const { classes, idx, subRowIdx, ...otherProps } = props;
  const { dispatch, currentRow, onChange } = useSearchData(idx, subRowIdx);
  const timeout = useTimeout(400);
  const companies = useSelector((state: any) => state.organizations);
  const tags = useSelector((state: any) => state.tags);
  const people = useSelector((state: any) => state.people.componentPeople.results);

  






  const options = useMemo((): any[] => {
    switch(currentRow.field?.id) {
      case SearchPerson.Country:
      case SearchPerson.HomeCountry:
        return countries


      case SearchPerson.Company:
        return companies


      case SearchPerson.Tags:
      case SearchProject.Tags:
        return tags

      case SearchTask.AddedBy:
      case SearchTask.AssignedTo:
        return people
      
      default: 
        return []
    }
  }, [currentRow.field?.id, countries, companies, tags, people])




    
  const handleChange = useCallback((option: any): any => {

    if([SearchTask.AddedBy, SearchTask.AssignedTo].includes(currentRow.field.id)) {
      
      if(!!option?.name) {
        
        timeout.reset(() => dispatchSearch(option.name))

      } else {
        timeout.clear();
        dispatchClearSearch()
      }
    }

    return onChange({ value: option })
  }, [currentRow.field.id])



  



  const dispatchSearch = useCallback((search: string): any => {
    return dispatch(searchPeopleLocal(search))
  }, [])



  const dispatchClearSearch = useCallback((): any => {
    return dispatch(clearPeopleLocal())
  }, [])





  return useMemo(() => (
    <Autocomplete
      doNotSort
      items={options}
      value={currentRow.value}
      onChange={handleChange}
      maxItems={20}
      className={classes.root}
      {...otherProps as any}
    />
  ), [currentRow.value, options, handleChange])
}


export default withStyles(styles)(SearchSelectValueAutocomplete)