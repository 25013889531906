import React, { useState, useEffect, Fragment, MouseEvent } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import AddButton from './AddButton';
import TextInput from './TextInput';
import CheckIcon from '@material-ui/icons/Check';
import { clickFix, formatDateTime } from '../utils/utils';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { useAnchor } from '../shared/hooks';
import PopoverTooltip from './PopoverTooltip';
import moment from 'moment';



const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',

  },
  notesReadonly: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.67,
    color: '#233539',
    display: 'flex',
    flexDirection: 'column'
  },
  inputWrapper: {
    width: '100%',
    marginRight: 12
  },
  input: {
    padding: '9px 13px !important',
    lineHeight: '1.67 !important',
    minHeight: '50px !important',
    boxSizing: 'border-box',
  },
  inputBaseInputWrapper: {
    borderRadius: '2px !important',
  },
  checkIcon: {
    fontSize: 21,
    color: theme.palette.primary.main
  },
  editIcon: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginLeft: 10
  },
  clearIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    marginLeft: 5
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  notesContainerWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  notesContainer: {
    width: '100%',
    borderRadius: 2,
    backgroundColor: '#f7f9fa',
    overflowY: 'auto',
    display: 'flex',
    maxHeight: 50,
    height: '100%',
    padding: '8px 11px',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    boxSizing: 'border-box',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      // WebkitAppearance: 'none',
      width: 18,
      height: '100%',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      height: 30,
      borderRadius: 20,
      border: '7px solid transparent',
      backgroundColor: '#dadfe2',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-button': {
      width: 0,
      height: 0,
      display: 'none',
    },
  },
  notesTimestamp: {
    fontFamily: 'Montserrat',
    fontSize: 9,
    fontWeight: 'normal',
    lineHeight: 1.33,
    color: '#657274',
    marginBottom: 3
  }
})

interface IProps extends WithStyles<typeof styles> {
  relations: any[];
  readonly?: boolean;
  action?: (relationId: number, note: any) => Promise<any>;
}

const GroupedNote = (props: IProps) => {
  const { classes, relations } = props;
  const removeAnchor = useAnchor();
  const [rel, setRel] = useState<any>(undefined);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [noteValue, setNoteValue] = useState<string>('');





  const getRelationWithNote = (): string|undefined => {
    const filteredRelations = relations.filter((item: any) => !!item.notes);
    return !!filteredRelations.length ? filteredRelations[0] : relations[0];
  }


  const getTimestamp = (): string => {
    if(!rel) return '';

    if(rel.notes_updated_at || rel.updated_at) {
      return `Amended: ${formatDateTime(rel.notes_updated_at ?? rel.updated_at)}`
    }


    if(rel.notes_created_at || rel.created_at) {
      return `Added: ${formatDateTime(rel.notes_created_at ?? rel.created_at)}`
    }


    return ''
  }


  const getBody = (value: string|null): any => {
    return {
      notes: value || null,

      notes_created_at: !!value
        ? rel?.notes ? (rel.notes_created_at ?? moment().format()) : moment().format()
        : null,

      notes_updated_at: !!value
        ? (rel?.notes && rel.notes_created_at) ? moment().format() : undefined
        : null
    }
  }




  const onAdd = (e: any): void => {
    setEditMode(true)
  }


  const onUpdateNote = (e: MouseEvent<SVGSVGElement>): void => {
    if(!rel?.id) return;


    if(noteValue) {
      props.action?.(rel.id, getBody(noteValue))
        .then((res: any) => {})
        .catch((err: any) => {})
        .finally(() => {
          setEditMode(false);
          setNoteValue('');
        })

      return
    } 


    setEditMode(false);
  }


  const onEdit = (e: MouseEvent<SVGSVGElement>): void => {
    setEditMode(true)
  }


  const onRemove = (e: MouseEvent<SVGSVGElement>): void => {
    if(!rel?.id) return;

    props.action?.(rel.id, getBody(null))
      .then((res: any) => {})
      .catch((err: any) => {})
      .finally(() => {
        setEditMode(false);
        setNoteValue('');
        removeAnchor.close();
      })
  }




  useEffect(() => {

    if(relations.length) {
      setRel(getRelationWithNote())
    }

    
  }, [relations])


  useEffect(() => {

    if(!!rel?.notes) {
      setNoteValue(rel.notes)
    }

  }, [rel])





  return <>

    <div className={classNames(classes.notesReadonly, 'show-on-print-important')}>
      <span className={classes.notesTimestamp}>{getTimestamp()}</span>
      {noteValue}
    </div>




    <div className={classNames(classes.root, 'hide-on-print')} onClick={clickFix}>

      {rel?.notes && !editMode && (
        <div className={classes.notesContainerWrapper}>
          <div className={classes.notesContainer}>
            <span className={classes.notesTimestamp}>{getTimestamp()}</span>
            {noteValue}
          </div>
          <EditIcon className={classes.editIcon} onClick={onEdit} />
          <ClearIcon className={classes.clearIcon} onClick={removeAnchor.set} />
        </div>
      )}


      {!rel?.notes && !editMode && (
        <AddButton text="Add Note" onClick={onAdd} />
      )}


      {editMode && (
        <div className={classes.wrapper}>
          <TextInput multiline
            value={noteValue}
            onChange={setNoteValue}
            onEnter={onUpdateNote}
            inputStyle={classes.input}
            className={classes.inputWrapper}
            wrapperClass={classes.inputBaseInputWrapper}
          />
          <CheckIcon className={classes.checkIcon} onClick={onUpdateNote} />
       </div> 
      )}



      <PopoverTooltip anchor={removeAnchor} actions={[{label: 'Delete?', onClick: onRemove}]} />



    </div>
  </>
}


export default withStyles(styles)(GroupedNote)