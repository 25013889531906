/**
 * Actions
 */



export const SET_SEARCH             = "SET_SEARCH";
export const CLEAR_SEARCH           = "CLEAR_SEARCH";
export const SET_HIGHLIGHT          = "SET_HIGHLIGHT";



/**
 * Action creators
 */

export function setSearch(search?: string): object {
  return {type: SET_SEARCH, search}
}

export function clearSearch(): object {
  return {type: CLEAR_SEARCH}
}

export function setHighlight(state: boolean): object {
  return {type: SET_HIGHLIGHT, state}
}