import createMuiTheme, { Theme as T } from "@material-ui/core/styles/createMuiTheme";
import { CSSProperties } from "@material-ui/styles";


declare module "@material-ui/core/styles/createMixins" {
  interface Mixins {
    scrollbar: (theme: Theme, grey?: boolean) => CSSProperties;
  }
}




export const palette = {
  skyblue050: '#e8f2fd',
  skyblue100: '#c6def9',
  skyblue200: '#a0c9f6',
  skyblue300: '#7ab3f2',
  skyblue400: '#5ea2ef',
  skyblue500: '#4192ec',
  skyblue600: '#3b8aea',
  skyblue700: '#327fe7',
  skyblue800: '#2a75e4',
  skyblue900: '#1c63df',

  violet050: '#f7eefd',
  violet100: '#ebd4fb',
  violet200: '#ddb7f8',
  violet300: '#cf9af5',
  violet400: '#c585f2',
  violet500: '#bb6ff0',
  violet600: '#b567ee',
  violet700: '#ac5cec',
  violet800: '#a452e9',
  violet900: '#9640e5',

  seagreen050: '#e7f6f6',
  seagreen100: '#c2e9e8',
  seagreen200: '#9adbd9',
  seagreen300: '#71cdc9',
  seagreen400: '#52c2be',
  seagreen500: '#34b7b2',
  seagreen600: '#2fb0ab',
  seagreen700: '#27a7a2',
  seagreen800: '#219f99',
  seagreen900: '#15908a',

  orange050: '#fdf1e8',
  orange100: '#f9dcc5',
  orange200: '#f5c59f',
  orange300: '#f1ad79',
  orange400: '#ee9c5c',
  orange500: '#eb8a3f',
  orange600: '#e98239',
  orange700: '#e57731',
  orange800: '#e26d29',
  orange900: '#dd5a1b',

  red050: '#fce9e9',
  red100: '#f8c8c9',
  red200: '#f3a3a5',
  red300: '#ee7e81',
  red400: '#ea6366',
  red500: '#e6474b',
  red600: '#e34044',
  red700: '#df373b',
  red800: '#db2f33',
  red900: '#d52023',

  palegrey050: '#fafcfc',
  palegrey100: '#f4f8f9',
  palegrey200: '#ecf2f4',
  palegrey300: '#e4ecef',
  palegrey400: '#dfe8eb',
  palegrey500: '#d9e4e8',
  palegrey600: '#d5e1e5',
  palegrey700: '#cfdde2',
  palegrey800: '#cad9de',
  palegrey900: '#c0d1d8',

  dark050: '#e5e7e7',
  dark100: '#bdc2c4',
  dark200: '#919a9c',
  dark300: '#657274',
  dark400: '#445357',
  dark500: '#233539',
  dark600: '#1f3033',
  dark700: '#1a282c',
  dark800: '#152224',
  dark900: '#0c1617',

  yellow50: '#fff9ed',
  yellow100: '#fef0d1',
  yellow200: '#fde6b3',
  yellow300: '#fcdb94',
  yellow400: '#fcd47d',
  yellow500: '#fbcc66',
  yellow600: '#fac75e',
  yellow700: '#fac053',
  yellow800: '#f9b949',
  yellow900: '#f8ad38',
  yellowA100: '#ffffff',
  yellowA200: '#ffffff',
  yellowA400: '#fff0da',
  yellowA700: '#ffe6c1',

  mist050: '#fefefe',
  mist100: '#fdfdfe',
  mist200: '#fbfcfd',
  mist300: '#f9fbfc',
  mist400: '#f8fafb',
  mist500: '#f7f9fa',
  mist600: '#f6f8f9',
  mist700: '#f5f7f9',
  mist800: '#f3f6f8',
  mist900: '#f1f5f6',

  white: '#ffffff'
};

export type TextStyle =
  | 'h1'
  | 'button'
  | 'link'
  | 'h2'
  | 'input'
  | 'data1'
  | 'data2'

export const CustomTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#4192ec",
      light: '#e8f2fd',
      contrastText: "#fff",
    },
    secondary: {
      main: "#e8f2fd",
      contrastText: "#4192ec"
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      //Montserrat Regular
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "normal",
      color: palette.dark500,
    },
    h2: {
      //Montserrat Regular
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: palette.dark500
    },
    body1: { // input
      //Montserrat Regular
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#4192ec",
    },
    body2: { // data2
      //Montserrat Light
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: palette.dark300
    },
    button: {
      //Montserrat Medium
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: palette.white,
      textTransform: 'initial'
    },
    caption: { //link
      //Montserrat Medium
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: 1.22,
      letterSpacing: "normal",
      color: "#4192ec",
    },
  },
  mixins: {
    scrollbar: (theme: Theme, grey: boolean = false) => ({
      '&::-webkit-scrollbar': {
        width: 18,
        height: 18,
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        height: 30,
        borderRadius: 20,
        border: '7px solid transparent',
        backgroundColor: grey ? '#dadfe2' : '#c8def4',
        backgroundClip: 'padding-box',
      },
      '&::-webkit-scrollbar-button': {
        width: 0,
        height: 0,
        display: 'none',
      },
    })
  }
})

export type Theme = (typeof CustomTheme) & T;

export const datepickerTheme = (readonly?: boolean) => createMuiTheme({
  palette: {
    text: {
      primary: '#233539'
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: 'Montserrat',
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#e8f2fd'
      }
    },
    MuiDialogActions: {
      root: {
        display: "none"
      }
    },
    MuiPickersDay: {
      day: {
        width: 25,
        height: 25,
        margin: '2px 5px',
        '& > span > p': {
          fontSize: 12,
        },
        '&:hover': {
          backgroundColor: readonly ? 'transparent' : '#e8f2fd',
          cursor: readonly ? 'unset' : 'pointer',
        },
        animation: 'none'
      },
      daySelected: {
        backgroundColor: readonly ? '#657274' : '#4192ec',
        color: '#fff !important',
        '&:hover': {
          backgroundColor: readonly ? '#657274' : '#e8f2fd',
          cursor: readonly ? 'unset' : 'pointer',
          color: readonly ? '#fff !important' : '#233539 !important'
        },
      },
      current: {
        color: '#4192ec'
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          color: '#4192ec !important'
        },
      },
      yearSelected: {
        color: '#4192ec !important',
      }
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        minHeight: '175px !important',
        marginTop: '5px !important'
      }
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        minHeight: 'initial !important',
      },
      iconButton: {
        color: readonly ? '#657274' : '#4192ec',
        padding: 0
      }
    },
  }
});

export const paginationTheme = () => createMuiTheme({
  palette: {
    text: {
      primary: '#233539'
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: 'Montserrat',
  },
  overrides: {
    MuiTablePagination: {
      root: {
        color: '#919a9c',
        borderBottom: 'unset'
      },
      spacer: {
        flex: 'unset',
      },
      caption: {
        flex: '1 1 100%',
        flexShrink: 'unset'
      },
    },
    MuiIconButton: {
      colorInherit: {
        color: '#4192ec'
      }
    }
  },
})