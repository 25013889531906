import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MembershipType } from '../../../shared/types';
import { Theme } from '../../../theme';
import { getIds } from '../../../utils/utils';
import Dropdown from '../../Dropdown';
import PersonWithAvatar from '../../PersonWithAvatar';
import { useTaskAccess, useTaskData } from './hooks/tasks-hooks';

const styles = (theme: Theme) => createStyles({
  dropdown: {
    marginBottom: 10,
  },
  assignees: {
    display: 'flex',
    flexDirection: 'column'
  },
  hidden: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  className?: string;
  headerClassName?: string;
}

const TaskAssignees = (props: IProps) => {
  const { classes, className, headerClassName } = props;
  const access = useTaskAccess();
  const members = useSelector((state: any) => state.teamMembers);
  const { value: assigneesDetails = [], onChangeMultiple } = useTaskData('assignees_details');




  const assignees = useMemo((): number[] => (
    getIds(assigneesDetails)
  ), [assigneesDetails]);


  

  const notAssigned = useMemo((): any[] => (
    members.filter((member: any) => !assignees.includes(member.person.id))
  ), [assignees, members]);


  


  const onSelect = (option: any): void => {
    if(!access.hasAccessToEditOwnStuff) return;

    onChangeMultiple({
      assignees: [...assignees, option.person.id],
      assignees_details: [...assigneesDetails, option.person]
    })
  }



  const onDelete = (assignee: any): void => {
    if(!access.hasAccessToEditOwnStuff) return;

    const filteredAssigneesDetails = assigneesDetails.filter((item: any) => item.id !== assignee.id);
    onChangeMultiple({
      assignees: getIds(filteredAssigneesDetails),
      assignees_details: filteredAssigneesDetails
    })
  }




  const memoAssignees = useMemo(() => (
    assigneesDetails.map((assignee: any) => (
      <PersonWithAvatar
        key={assignee.id}
        person={assignee}
        onDelete={access.hasAccessToEditOwnStuff ? onDelete : undefined}
        readonly={!access.hasAccessToEditOwnStuff}
      />
    ))
  ), [assigneesDetails, access.hasAccessToEditOwnStuff])





  return (
    <div className={className}>
      <div className={headerClassName}>
        <h2>{access.hasAccessToEditOwnStuff ? "Assign To" : "Assigned To"}</h2>
      </div>


      <Dropdown
        placeholder="Select a person"
        options={notAssigned}
        type={MembershipType.Team}
        onSelect={onSelect}
        className={classNames(
          classes.dropdown,
          {[classes.hidden]: !access.hasAccessToEditOwnStuff}
        )}
      />

      <div className={classNames(
        classes.assignees,
        {[classes.hidden]: !assignees?.length}
      )}>
        {memoAssignees}
      </div>


    </div>
  )
}


export default withStyles(styles)(TaskAssignees)