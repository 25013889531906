import React, { Fragment } from "react";
import { Theme, palette } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {Button, WithStyles, Popover} from "@material-ui/core";
import classNames from 'classnames';
import {ClickFunction} from "../utils/typescriptUtils";
import { useOpen, useAnchor } from "../shared/hooks";

const styles = (theme: Theme) => createStyles({
  root: {
    ...theme.typography.button,
    fontSize: 12,
    color: theme.palette.primary.main,
    backgroundColor: palette.white,
    height: 40,
    borderRadius: 3,
    padding: '4px 12px',
    '&:hover': {
      backgroundColor: palette.skyblue050,
    },
    '&:focus': {
      outline: theme.palette.primary.main
    }
  },
  textPrimary: {
    color: palette.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: palette.skyblue700,
    },
  },
  small: {
    height: 30
  },
  tiny: {
    height: 24,
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 0,
    paddingBottom: 0
  },
  label: {
    justifyContent: 'left',
    height: 24,
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '24px',
    padding: 0,
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  primary: {
    '&:hover': {
      backgroundColor: '#377cc8',
      '& svg': {
        color: '#fff !important'
      },
      '& span': {
        color: '#fff !important'
      }
    }
  },
  focusVisible: {

  },
  disabled: {
    color: '#fff !important',
    backgroundColor: '#b2bdc2',
    cursor: 'default',
    'pointer-events': 'auto !important',
    '&:hover': {
      backgroundColor: '#b2bdc2 !important',
    }
  },
  popover: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.58,
    color: '#657274',
    padding: 10
  }
});

export enum ButtonSize {
  Normal,
  Small,
  Tiny,
  Label
}

interface IProps extends WithStyles<typeof styles> {
  primary?: boolean,
  onClick?: ClickFunction,
  children?: any,
  fullWidth?: boolean,
  className?: string,
  size?: ButtonSize,
  type?: any,
  disabled?: boolean,
  popover?: string|boolean
}

const CustomButton = (props: IProps) => {
  const { classes, size = ButtonSize.Normal } = props;
  const popoverAnchor = useAnchor();



  return (
    <Fragment>
      <Button disableRipple
        tabIndex={0}
        onClick={props.onClick}
        color={props.primary ? 'primary' : 'default'}
        fullWidth={props.fullWidth}
        className={props.className}
        type={props.type}
        disabled={props.disabled}
        focusVisibleClassName={classes.focusVisible}
        onMouseEnter={popoverAnchor.set}
        onMouseLeave={popoverAnchor.close}
        classes={{
          root: classNames([
            classes.root,
            {
              [classes.small]: size === ButtonSize.Small,
              [classes.tiny]: size === ButtonSize.Tiny,
              [classes.label]: size === ButtonSize.Label,
              [classes.primary]: props.primary
            },
          ]),
          textPrimary: classes.textPrimary,
          disabled: classes.disabled
        }}
      >
        {props.children}
      </Button>


      {props.popover && (
        <Popover
          open={!!popoverAnchor.value}
          anchorEl={popoverAnchor.value}
          PaperProps={{ className: classes.popover }}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <span>{props.popover}</span>
        </Popover>
      )}


    </Fragment>
  );
}



export default withStyles(styles)(CustomButton)