import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { memo, MouseEvent } from 'react';
import { Theme } from '../../../theme';
import { BoardIcon } from '../../icons/BoardIcon';
import TableRowCell from './TableRowCell';
import classNames from 'classnames';


const styles = (theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    maxWidth: 400
  },
  boardIcon: {
    fontSize: 16,
    marginRight: 10
  },
})

interface IProps extends WithStyles<typeof styles> {
  onClick: (e: MouseEvent<HTMLDivElement>) => any;
  className?: string;
}

const TableRowBoard = ({ classes, className, onClick }: IProps) => (
  <TableRowCell className={classNames(classes.root, className)}>
    <div onClick={onClick}>
      <BoardIcon className={classes.boardIcon} />
    </div>
  </TableRowCell>
)


export default withStyles(styles)(
  memo(TableRowBoard)
)