import React, { ChangeEvent, memo } from 'react';
import { Theme } from '../theme';
import SwitchMU from '@material-ui/core/Switch';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';


const styles = (theme: Theme) => createStyles({
  root: {
    width: 82,
    padding: 0,
    height: 'initial'
  },
  track: {
    width: '100%',
    height: 20,
    borderRadius: 50,
    opacity: 1,
    position: 'relative',
    backgroundColor: '#18ccc4',
    '&:before, &:after': {
      top: '55%',
      transform: 'translateY(-50%)',
      color: '#fff',
      fontSize: 10,
      fontWeight: 500,
      opacity: 0,
      textTransform: 'capitalize',
      display: 'flex',
      lineHeight: 2,
      position: 'absolute',
      transition: 'opacity .1s ease',
    },
    '&:before': {
      content: (props: any) => `"${props.labelChecked ?? 'on'}"`,
      left: 9
    },
    '&:after': {
      content: (props: any) => `"${props.labelUnchecked ?? 'off'}"`,
      opacity: 1,
      right: 10
    }
  },
  thumb: {
    height: 15,
    width: 15,
    position: 'absolute',
    top: '50%',
    left: 3,
    transform: 'translate(0, -50%)',
    boxShadow: 'none',
    backgroundColor: '#fff',
    opacity: 1
  },
  switchBase: {
    padding: 10,
    transform: 'unset !important',
  },
  checked: {
    '&$switchBase': {
      left: 'calc(100% - 20px)',
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        '&:before': {
          opacity: 1
        },
        '&:after': {
          opacity: 0
        }
      }
    },
  }
})






interface IProps extends WithStyles<typeof styles> {
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => any;
  labelChecked?: string;
  labelUnchecked?: string;
  className?: string;
  name?: string;
}



const Switch = (props: IProps) => (
  <SwitchMU
    checked={!!props.checked}
    classes={props.classes}
    onChange={props.onChange}
    className={props.className}
    name={props.name}
  />
)


export default withStyles(styles)(
  memo(Switch)
)