import * as actions from '../actions/board-columns';

export const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_BOARD_COLUMNS:
      return action.columns

    case actions.CHANGE_COLUMN:
      return [...state].map((column: any) => column.id === action.column.id ? action.column : column)
  
    default:
      return state
  }
}