import { FormControlLabel, Paper } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { ChangeEvent, FormEvent, Fragment, useEffect, useState, useRef, createRef, Ref } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import DuplicateItems from '../../components/duplicates/DuplicateItems';
import { PersonIcon } from '../../components/icons/PersonIcon';
import Radio from '../../components/Radio';
import Skeleton from '../../components/Skeleton';
import requestManager from '../../middleware/requestManager';
import { DuplicatablePersonField, EmailTypes, PhoneTypes, DuplicatableCPField } from '../../shared/types';
import { Theme } from '../../theme';
import { getUsername } from '../../utils/user';
import { getCategoryLabel, isCP } from '../../utils/utils';
import DuplicateRadioGroup from '../../components/duplicates/DuplicateRadioGroup';
import { useOpen } from '../../shared/hooks';
import classNames from 'classnames';





const styles = (theme: Theme) => createStyles({
  paper: {
    position: 'relative',
    minHeight: 547,
    borderRadius: 0,
    margin: '0 0 55px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
    padding: '16px 20px',
    width: 'calc(100% - 232px)',
    marginLeft: 232
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '13px 0 17px'
  },
  headerTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    color: '#233539',
    marginBottom: 1
  },
  headerDescription: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#657274',
  },
  contactBadge: {
    marginBottom: 23,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #d9e4e8',
    borderRadius: 4,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.67,
    color: '#657274',
    boxSizing: 'border-box',
    width: 'max-content',
    padding: '15px 70px 15px 20px',
    '& strong': {
      fontWeight: 500,
      color: '#233539'
    }
  },
  contactBadgeIcon: {
    fontSize: 14,
    marginRight: 14
  },
  compareWrapper: {
    overflow: 'auto',
    paddingBottom: 20
  },
  compare: {
    width: 'max-content',
    overflow: 'auto'
  },
  compareHeader: {
    width: '100%',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 2.18,
    letterSpacing: 0.53,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 15,
    paddingBottom: 6,
    borderBottom: '1px solid #d9e4e8',
  },
  compareHeaderDetail: {
    paddingRight: 20,
    width: 214,
    minWidth: 214,
    boxSizing: 'border-box',
  },
  compareHeaderContacts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  compareHeaderItem: {
    paddingRight: 20,
    minWidth: 436,
    width: 436,
    boxSizing: 'border-box',
    '&:last-child': {
      paddingRight: 0,
      minWidth: 386,
      maxWidth: 386
    }
  },
  compareHeaderItemWithError: {
    color: '#d52023'
  },
  compareFields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 19,
    borderBottom: '1px solid #d9e4e8',
    marginBottom: 18,
    position: 'relative',
    '&:last-child': {
      borderBottom: 0,
      marginBottom: 0
    }
  },
  compareFieldsList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    transition: 'all .3s ease',
    opacity: 1,
    overflow: 'hidden'
  },
  compareFieldsHidden: {
    paddingBottom: 0,
    '& $compareFieldsList': {
      height: 0,
      opacity: 0,
      paddingBottom: 0
    },
    '& $compareFieldsHeaderWrapper': {
      marginBottom: 0
    }
  },
  compareFieldsHeaderWrapper: {
    display: 'flex',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    lineHeight: 1.71,
    marginBottom: 10,
    flexDirection: 'column'
  },
  compareFieldsHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 2
  },
  compareFieldsHeaderTitle: {
    fontSize: 14,
    color: '#233539',
  },
  compareFieldsHeaderDescription: {
    fontSize: 12,
    color: '#657274',
    lineHeight: 1.5,
    fontWeight: 400
  },
  compareFieldsHeaderToggle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 10
  },
  form: {
  },
  formButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // position: 'absolute',
    // bottom: 16,
    // right: 20,
  },
  mergeButton: {
    fontWeight: 300,
    padding: '4px 16px',
  },
  errorIcon: {
    fontSize: 18
  },
  duplicateUsername: {
    transition: 'color .1s ease',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  skeletonBack: {
    height: 20,
    width: '20%',
    marginBottom: 13,
  },
  skeletonDetails: {
    height: 42,
    width: '65%',
    marginBottom: 17,
  },
  skeletonOriginalContact: {
    height: 52,
    width: '50%',
    marginBottom: 23
  },
  skeletonMergeContainer: {
    height: 200,
    maxHeight: '30%',
    width: '100%',
    marginBottom: 20
  },
  skeletonButton: {
    width: 120,
    height: 40,
    marginRight: 0,
    marginLeft: 'auto',
  },
  duplicateUsernameCounter: {
    color: 'inherit',
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  duplicateIds?: string | number;
  duplicates: any[];
  currentDuplicate: any;
  backURL: string;
  actions: any;
  backLabel: string;
  isImportedDuplicates?: boolean;
  actionMerge: (withCP: boolean, dontIncludeCp?: boolean) => any;
  actionGetById: (...args: any[]) => any;
  actionChangeField: (...args: any[]) => any;
  actionClear: (...args: any[]) => any;
}

const DuplicatesMergeContainer = (props: IProps) => {
  const { classes, currentDuplicate, duplicates, actions, isImportedDuplicates } = props;
  const dispatch = useDispatch();
  const cpControls = useOpen(true);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [radios, setRadios] = useState<any>({});
  const [cpRadios, setCpRadios] = useState<any>({});
  const [dontIncludeCp, setDontIncludeCp] = useState<boolean>(false);
  const [errorRefs, setErrorRefs] = useState<any>({});
  const containerRef = createRef<HTMLDivElement>();





  const getFields = (): string[] => {
    if (!duplicates?.length) return [];

    return Object.keys(DuplicatablePersonField);
  }


  const getCpFields = (): string[] => {
    if (!duplicates?.length) return [];

    return Object.keys(DuplicatableCPField)
  }


  const isShowCP = (): boolean => {
    return getDuplicateValue('categories', true).some(isCP);
  }



  const getCps = (): any[] => {
    if (!duplicates?.length) return [];

    return duplicates
      .filter((item: any) => item.categories?.some(isCP))
      .map((item: any) => item.capital_provider)
      .filter((item: any) => !!item)
  }


  const areArrayFieldsValid = (): boolean => {
    if (!duplicates?.length) return false;

    const errors = {};
    ['emails', 'phones'].forEach((type: string) => {

      const currentValues = getDuplicateValue(type)
        .filter((item: any) => item === 0 || !!item);

      const allValues = duplicates
        .map((item: any) => item[type])
        .flat()
        .filter((item: any) => item === 0 || !!item);

      errors[type] = currentValues.length !== allValues.length;
    })



    setFieldErrors(errors)
    return Object.values(errors).every((item: any) => !item)
  }



  const hasPhoneNumbers = (): boolean => {
    if (!duplicates?.length) return false;

    return !!duplicates
      .map((item: any) => item.phones)
      .flat()
      .filter((item) => !!item)
      .length;
  }



  const onBack = (): void => {
    props.history.push(props.backURL)
  }



  const onRadioChange = (e: ChangeEvent<HTMLInputElement>, dupIdx: string | number): void => {
    const { name } = e.target;
    if (duplicates?.[dupIdx]?.[name] === undefined) return;

    setRadios({ ...radios, [name]: +dupIdx })

    if (name === 'categories') {
      handleChangeMultiple({
        ...currentDuplicate,
        categories: duplicates[dupIdx].categories,
        subcategories: duplicates[dupIdx].subcategories,
      })
      return
    }

    handleChange(name, duplicates[dupIdx][name])
  }



  const onCpRadioChange = (e: ChangeEvent<HTMLInputElement>, dupIdx: string | number): void => {
    const { name } = e.target;
    if (duplicates?.[dupIdx]?.capital_provider?.[name] === undefined) return;

    setCpRadios({ ...cpRadios, [name]: +dupIdx });

    handleChange('capital_provider', {
      ...currentDuplicate.capital_provider,
      [name]: duplicates[dupIdx]?.capital_provider?.[name]
    })
  }



  const onDuplicateArrayValueRemove = (itemIdx: number, newValue: any[], fieldName: string, oldValue: any): void => {
    const updatedValue = currentDuplicate[fieldName]?.filter((item: any) => item !== oldValue);
   
    if (updatedValue !== undefined) {
      handleChange(fieldName, updatedValue)

      if (updatedValue.every((item: any) => !item)) {
        handleChange(fieldName, [])
      }
    }

    dispatchChangeField(itemIdx, fieldName, newValue)
  }



  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    e.stopPropagation();


    if (!duplicates?.length || !areArrayFieldsValid()) return;

    dispatch(props.actionMerge(cpControls.value, dontIncludeCp))
      .then((res: any) => onBack())
  }



  const onContactClick = (e: any, duplicate: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (!duplicate?.id) return;
    window.open(`/contacts/${duplicate.id}`, '_blank')
  }



  const handleChangeMultiple = (values: any): void => {
    return dispatch(actions.actionUpdateMultiple(values))
  }



  const handleChange = (name: string, value: any): void => {
    return dispatch(actions.actionUpdateField(name, value))
  }



  const onCpToggle = (): void => {
    if(cpControls.value) {
      cpControls.close()
      setDontIncludeCp(true);
      handleChange('capital_provider', undefined);
    } else {
      cpControls.open();
      setDontIncludeCp(false);
      selectFirstCP();
    }
  }



  const onErrorRefs = (field: string, refs: Ref<HTMLDivElement>[]): void => {
    setErrorRefs({...errorRefs, [field]: refs})
  }





  /**
   * If there is only 1 duplicate item then don't compare fields
   */
  const areDuplicatesFieldsEmtpyOrSame = (field: string, items: any[] = []): boolean => {
    if(items.length === 1) return false;
    


    const value = items[0]?.[field];


    return items.every((dup: any, idx: number) => {
      if (dup[field] === value) {
        return true
      }


      /**
       * Additional !value check required for next scenario:
       * 
       * const originalDuplicate = {a: "abc"};
       * const dup1 = {a: "abc"};
       * const dup2 = {a: null}
       */

      if ([null, ''].includes(dup[field]) && !value) {
        return true;
      }


      if (dup[field]?.id !== undefined && value?.id !== undefined && value.id === dup[field].id) {
        return true
      }


      if (Array.isArray(dup[field]) && !dup[field].length) {
        return true
      }


      if(JSON.stringify(dup[field]) === JSON.stringify(value)) {
        return true
      }


      return false
    })
  }



  const getDuplicateValue = (field: string, asArray?: boolean): any => {
    const value = currentDuplicate[field] ?? duplicates[0]?.[field] ?? (asArray ? [] : '');
    return value?.id ? value.id : value
  }




  const getArrayItems = (field: any): any[] => {
    if (!field || !duplicates.length) return [];

    return duplicates.map((duplicate: any) => duplicate[field])
  }




  const initRadios = (fields: string[] = [ ], items: any[] = [], exclude: string[] = [], initialIdx: number = 0): any => {
    const newFields = fields
      .map((field: string) => {

        if (exclude.includes(field)) return undefined;
        
        if (areDuplicatesFieldsEmtpyOrSame(field, items)) return undefined;

        return field
      })
      .filter((field?: string) => !!field)
      .map((field?: string) => [field, initialIdx])

    return Object.fromEntries(newFields)
  }



  const getDupIdxForCp = (): number|undefined => {
    return duplicates.findIndex((item: any) => item.categories.some(isCP) && !!item.capital_provider)
  }



  const selectFirstCP = (): void => {
    const cps = getCps();
    handleChange('capital_provider', cps[0])
  }






  const dispatchGetByIds = (ids?: string | number): any => {
    return dispatch(props.actionGetById(ids))
  }



  const dispatchChangeField = (id: number, fieldName: string, value: any[]): any => {
    return dispatch(props.actionChangeField(id, fieldName, value))
  }


  const dispatchClearCurrent = (): any => {
    return dispatch(actions.actionResetForm())
  }


  const dispatchClear = (): any => {
    return dispatch(props.actionClear())
  }









  useEffect(() => {
    dispatchGetByIds(props.duplicateIds)
      .catch((err: any) => {
        if (err?.response?.status === 404) {
          return onBack()
        }
      })
    

    return () => {
      dispatchClearCurrent();
      dispatchClear();

      requestManager.cancelAllRequests()
    }
  }, [])









  useEffect(() => {

    if (!currentDuplicate?.emails && !!duplicates?.[0]?.emails) {
      handleChange('emails', duplicates[0].emails)
    }


    const cps = getCps();
    if (!currentDuplicate?.capital_provider && !!cps.length && !dontIncludeCp) {


      if(cps.length === 1 && cps[0]?.id === duplicates[0]?.capital_provider?.id) {
        return;
      }


      if(isShowCP()) {
        selectFirstCP()
      }
    }



  


  }, [duplicates, currentDuplicate, cpControls.value, dontIncludeCp])



  useEffect(() => {
    if(!isShowCP() || dontIncludeCp) {
      cpControls.close()
    } else {
      cpControls.open()
    }
  }, [duplicates, currentDuplicate, cpControls.value, dontIncludeCp])










  useEffect(() => {
    if (!!Object.keys(radios).length || !duplicates?.length) return;


    const newRadios = initRadios(getFields(), duplicates, ['emails', 'phones', 'subcategories']);
    if(!!Object.keys(newRadios).length) {
      setRadios(newRadios);
    }


    const cps = getCps();
    const dupIdxForCp = getDupIdxForCp();
    if (!!cps.length) {
      const newCpRadios = initRadios(getCpFields(), cps, [], dupIdxForCp)
      if(!!Object.keys(newCpRadios).length) {
        setCpRadios(newCpRadios);
      }
    }

  }, [radios, duplicates])






  useEffect(() => {
    const refValues = Object.values(errorRefs).flat();
    if(!!containerRef?.current && !!refValues?.[0]?.current) {
      const ref = refValues[0].current;
      const cont = containerRef.current;

      const containerOffset = cont.offsetWidth + cont.scrollLeft;
      const refOffset = ref.offsetWidth + ref.offsetLeft;
      if(containerOffset < refOffset) {
        cont.scrollLeft = refOffset;
      }
       
      if(window.innerHeight / 2 > ref.offsetTop) {
        window.scroll(0, ref.offsetTop - window.innerHeight / 2)
      }
    }
  }, [containerRef, errorRefs])









  if (!duplicates.length) {
    return (
      <Paper className={classes.paper}>
        <Skeleton className={classes.skeletonBack} />
        <Skeleton className={classes.skeletonDetails} />

        {isImportedDuplicates && (
          <Skeleton className={classes.skeletonOriginalContact} />
        )}

        <Skeleton className={classes.skeletonMergeContainer} />
        <Skeleton className={classes.skeletonButton} />
      </Paper>
    )
  }






  return (
    <Paper className={classes.paper}>

      <BackButton onClick={onBack}>{props.backLabel}</BackButton>

      <div className={classes.header}>
        <span className={classes.headerTitle}>Merge Details</span>
        <span className={classes.headerDescription}>Please select the correct personal details to save for this contact. Duplicate details will be deleted. </span>
      </div>



      {!!isImportedDuplicates && (
        <div className={classes.contactBadge}>
          <PersonIcon className={classes.contactBadgeIcon} type={duplicates?.[0]?.profile_type} />
          <span>
            <strong>{getUsername(duplicates?.[0])}</strong>
            {duplicates?.[0]?.company ? `, ${duplicates?.[0]?.company}` : ''}
            {getCategoryLabel(duplicates?.[0]) ? `, ${getCategoryLabel(duplicates?.[0])}` : ''}
          </span>
        </div>
      )}





      <form className={classes.form} onSubmit={onSubmit}>

        <div ref={containerRef} className={classes.compareWrapper}>
          <div className={classes.compare}>

            <div className={classes.compareHeader}>
              <span className={classes.compareHeaderDetail}>Detail</span>

              <div className={classes.compareHeaderContacts}>

                {!isImportedDuplicates && duplicates.map((duplicate: any, dupIdx: number) => (
                  <span key={dupIdx} className={classes.compareHeaderItem}>
                    <a href="#" className={classes.duplicateUsername} onClick={(e: any) => onContactClick(e, duplicate)}>
                      <span className={classes.duplicateUsernameCounter}>{dupIdx + 1} </span>
                      {getUsername(duplicate)} {!dupIdx && '(Original Contact)'}
                    </a>
                  </span>
                ))}




                {!!isImportedDuplicates && (
                  <Fragment>
                    <span className={classes.compareHeaderItem}>
                      <a href="#" className={classes.duplicateUsername} onClick={(e: any) => onContactClick(e, duplicates?.[0])}>
                        Original Contact
                      </a>
                    </span>
                    <span className={classes.compareHeaderItem}>
                      <span>
                        Duplicate Contact
                      </span>
                    </span>
                  </Fragment>
                )}



              </div>

            </div>




            <DuplicateItems title="Email Addresses"
              description={<Fragment>
                Only <strong>one</strong> email address for work and home may be used on FinTech8.
                </Fragment>}
              field="emails"
              items={getArrayItems('emails')}
              activeItems={getDuplicateValue('emails')}
              onChange={(newValue: any[]) => handleChange('emails', newValue)}
              errors={!!fieldErrors?.emails}
              onRemove={onDuplicateArrayValueRemove}
              valuesList={EmailTypes}
              onErrorRefs={onErrorRefs}
            />


            {hasPhoneNumbers() && (
              <DuplicateItems title="Phone Numbers"
                field="phones"
                items={getArrayItems('phones')}
                activeItems={getDuplicateValue('phones')}
                onChange={(newValue: any[]) => handleChange('phones', newValue)}
                errors={!!fieldErrors?.phones}
                onRemove={onDuplicateArrayValueRemove}
                valuesList={PhoneTypes}
                onErrorRefs={onErrorRefs}
              />
            )}





            <div className={classes.compareFields}>
              {Object.entries(radios).map(([field, value]: any) => (
                <DuplicateRadioGroup key={field}
                  field={field}
                  value={value}
                  labels={DuplicatablePersonField}
                  duplicates={duplicates}
                  onChange={onRadioChange}
                />
              ))}
            </div>



            {!!getCps().length && <>
              <div className={classNames(
                classes.compareFields,
                { [classes.compareFieldsHidden]: !cpControls.value }
              )}>

                <div className={classes.compareFieldsHeaderWrapper}>
                  <div className={classes.compareFieldsHeader}>
                    <div className={classes.compareFieldsHeaderTitle}>
                      <span>Capital Provider</span>
                      {isShowCP() && getCps().length > 1 && !cpControls.value && " (Original)"}
                    </div>

                      {isShowCP() && (
                        <span onClick={onCpToggle}
                          className={classes.compareFieldsHeaderToggle}
                        >
                          {getCps().length > 1 && (cpControls.value ? 'Use original' : 'Open merge view')}
                        </span>
                      )}
                      
                  </div>
                  
                  <span className={classes.compareFieldsHeaderDescription}>
                    {!isShowCP() && `By merging these contacts, you will lose the Capital Provider data.`}
                    {isShowCP() && getCps().length === 1 && "The Capital Provider data selected here will be merged into the updated contact"}
                  </span>  
                  
                </div>


                <div className={classes.compareFieldsList}>
                  {Object.entries(cpRadios).map(([field, value]: any) => (
                    <DuplicateRadioGroup key={field} isCP
                      field={field}
                      value={value}
                      labels={DuplicatableCPField}
                      duplicates={duplicates}
                      onChange={onCpRadioChange}
                    />
                  ))}
                </div>
              </div>
            </>}


          </div>

        </div>



        <div className={classes.formButton}>
          <Button primary type="submit" className={classes.mergeButton}>Merge Contact</Button>
        </div>



      </form>




    </Paper>
  )
}


export default withStyles(styles)(
  withRouter(DuplicatesMergeContainer)
)