import React, { MouseEvent } from 'react';
import { Theme, palette } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import { PeopleIcon } from '../icons/PeopleIcon';
import { ProjectsIcon } from '../icons/ProjectsIcon';
import { CalendarIcon } from '../icons/CalendarIcon';
import { SearchIcon } from '../icons/SearchIcon';
import { ROUTE_PROJECTS, ROUTE_SEARCH, ROUTE_PEOPLE, ROUTE_CALENDAR } from '../../constants/routes';
import { isRoute, goTo } from '../../utils/utils';
import { Tabs, Tab } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    marginLeft: 20
  },
  icon: {
    margin: '0 8px 0 0 !important'
  },
  iconPeople: {
    fontSize: 17
  },
  iconProjects: {
    fontSize: 17
  },
  iconCalendar: {
    fontSize: 15
  },
  iconSearch: {
    fontSize: 17
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.17,
    color: '#90a0a7',
    width: 'initial',
    minWidth: 115,
    textTransform: 'unset',
    opacity: 1,
    minHeight: 'unset',
    height: '100%',
    padding: '0 !important',
    '&$tabSelected': {
      backgroundColor: '#f7f9fa',
      color: theme.palette.primary.main
    }
  },
  tabSelected: {},
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tabs: {
    height: '100%'
  },
  indicator: {
    display: 'none'
  },
  flexContainer: {
    height: '100%'
  }
})


enum TAB_OPTIONS {
  PROJECTS,
  PEOPLE,
  CALENDAR,
  SEARCH
}



interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  
}


const HeaderTabs = (props: IProps) => {
  const { classes, history } = props;




  const tabs = [
    {
      label: 'Projects',
      href: ROUTE_PROJECTS,
      icon: <ProjectsIcon className={classNames(classes.icon, classes.iconProjects)} />,
      classes: { wrapper: classes.wrapper, selected: classes.tabSelected },
      onClick: (e: any) => goTo(e, ROUTE_PROJECTS, history)
    },
    {
      label: 'Contacts',
      href: ROUTE_PEOPLE,
      icon: <PeopleIcon className={classNames(classes.icon, classes.iconPeople)} />,
      classes: { wrapper: classes.wrapper, selected: classes.tabSelected },
      onClick: (e: any) => goTo(e, ROUTE_PEOPLE, history)
    },
    {
      label: 'Calendar',
      href: '/calendar/month',
      icon: <CalendarIcon className={classNames(classes.icon, classes.iconCalendar)} />,
      classes: { wrapper: classes.wrapper, selected: classes.tabSelected },
      onClick: (e: any) => goTo(e, '/calendar/month', history)
    },
    {
      label: 'Search',
      href: ROUTE_SEARCH,
      icon: <SearchIcon className={classNames(classes.icon, classes.iconSearch)} />,
      classes: { wrapper: classes.wrapper, selected: classes.tabSelected },
      onClick: (e: any) => goTo(e, ROUTE_SEARCH, history)
    },
  ];

  const getTabSelected = () => {
    
    if(isRoute(ROUTE_PEOPLE)) {
      return TAB_OPTIONS.PEOPLE
    }

    if(isRoute(ROUTE_PROJECTS)) {
      return TAB_OPTIONS.PROJECTS
    }

    if(isRoute(ROUTE_SEARCH)) {
      return TAB_OPTIONS.SEARCH
    }

    if(isRoute(ROUTE_CALENDAR)) {
      return TAB_OPTIONS.CALENDAR
    }

    return false
  }




  return (
    <div className={classes.root}>
      <Tabs
        value={getTabSelected()}
        classes={{
          root: classes.tabs,
          indicator: classes.indicator,
          flexContainer: classes.flexContainer
        }}
      >
        {tabs.map((tabProps: any) => (
          <Tab key={tabProps.label}
            component="a"
            className={classes.tab}
            {...tabProps}
          />
        ))}
      </Tabs>
    </div>
  )
}


export default withStyles(styles)(
  withRouter(HeaderTabs)
)