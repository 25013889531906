import * as actions from '../../actions/common/list';
import { TableType } from '../../shared/types';


const initialState = Object.keys(TableType)
  .filter((k: string) => !isNaN(+k))
  .reduce((acc: object, curr: string) => {
    acc[curr] = {
      loadingMore: false,
      rows: [],
      selected: [],
      filters: {},
      ref: null
    };
    return acc
  }, {})



export default function(state = initialState, action) {
  const prevState = {...state[action.tableType]};
  switch (action.type) {


    case actions.LOADING_MORE:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          loadingMore: action.isLoading
        }
      }


    case actions.SET_SELECTED:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          selected: action.selected
        }
      }

      
    case actions.SELECTED_ADD:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          selected: [...new Set([...prevState.selected, action.id])]
        }
      }

      
    case actions.SELECTED_REMOVE:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          selected: prevState.selected.filter((id: number) => id !== action.id)
        }
      }

      
    case actions.SET_ROWS:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          rows: action.rows
        }
      }


    case actions.SET_FILTERS:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          filters: action.filters
        }
      }


    case actions.SET_ONE_FILTERS:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          filters: {
            ...prevState.filters,
            [action.name]: action.value
          }
        }
      }


    case actions.SET_REF:
      return {
        ...state,
        [action.tableType]: {
          ...prevState,
          ref: action.ref
        }
      }



    default:
      return state
  }
}