import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from 'classnames'
import React, { Fragment, useEffect, useState } from "react"
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar"
import 'react-circular-progressbar/dist/styles.css'
import ReactGA from 'react-ga'
import { connect, useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { getBoardColumns } from "../../actions/board-columns"
import { setLoadedPage } from "../../actions/common/loading"
import { formatSortObject } from "../../actions/helpers/helpers"
import { getProjectDetails, getProjects } from "../../actions/projects/projects"
import { getAllTasks, getClosingProcessTasks, searchTasks } from "../../actions/tasks"
import BaseCard from "../../components/BaseCard"
import Footer from "../../components/footer/Footer"
import List from "../../components/list/List"
import MobileList from "../../components/list/mobile/MobileList"
import MobileWarning from "../../components/MobileWarning"
import BoardHeader from "../../components/project/board/BoardHeader"
import BoardProgress from "../../components/project/board/BoardProgress"
import MembershipList from "../../components/project/membership-list/MembershipList"
import TaskDialog from "../../components/project/task/TaskDialog"
import { projectsInitialState } from "../../reducers/projects/projects"
import { initialState } from "../../reducers/tasks"
import { useWindowSize } from "../../shared/hooks"
import { ISort } from "../../shared/interfaces"
import { MembershipType, TableType } from "../../shared/types"
import { Theme } from "../../theme"
import { getProgress, getProgressBgColor, getProgressColor, goTo, isRoute } from "../../utils/utils"
import { ROUTE_TASK, ROUTE_DASHBOARD } from "../../constants/routes"

const styles = (theme: Theme) => createStyles({
  root: {

  },
  
  header: {
    color: '#233539',
    fontSize: 12,
    fontFamily: 'Montserrat',
    marginTop: 30,
    textTransform: 'uppercase',
    fontWeight: 500
  },
  card: {
    margin: '10px 0 30px'
  },
  cardList: {
    paddingTop: 10,
    marginBottom: 30,
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fit, 190px)',
    gridTemplateRows: '230px min-content',
    gridGap: 12,
    justifyContent: 'space-between',
  },
  littleCard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: '10px 15px',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
    boxSizing: "border-box",
    justifyContent: 'space-between',
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.5,
    position: 'relative',
    '&:last-child': {
      marginBottom: 0
    }
  },
  littleCardHeader: {
    fontWeight: 500,
    maxWidth: '80%',
    color: '#233539',
    paddingBottom: 5,
    fontFamily: 'Montserrat',
    fontSize: 14,
    lineHeight: 1.71,
  },
  progressColumn: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 3,
  },
  progressNumber: {
    fontSize: 18,
    fontWeight: 500
  },
  progressText: {
    fontSize: 12,
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainContainer: {
    width: '66%'
  },
  rightContainer: {
    width: '33%'
  },
  members: {
    padding: 5
  },
  littleCardTasks: {
    display: 'flex',
    alignItems: 'center'
  },
  littleCardTasksSquare: {
    marginRight: 10,
    width: 8,
    height: 8
  },
  littleCardTasksNumber: {
    color: '#657274',
    fontWeight: 500,
    marginRight: 4
  },
  littleCardTasksLabel: {
    color: '#657274',
    fontWeight: 400
  },
  boardProgress: {
    marginBottom: 30
  },
  progressCardWrapper: {
    width: '100%',
    alignContent: 'center'
  },
  progressCard: {
    width: 120,
    margin: '0 auto'
  },
  mobileRoot: {
    '& $littleCard': {
      flex: '0 1 48%',
      border: 0,
      minWidth: 'unset',
      height: '55.46vw',
      marginBottom: 16,
      '&:last-child, &:nth-last-child(2):nth-child(odd)': {
        marginBottom: 0
      }
    },
    '& $littleCardHeader': {
      maxWidth: '100%'
    },
    '& $gridContainer': {
      flexDirection: 'column'
    },
    '& $mainContainer': {
      width: '100%'
    },
    '& $rightContainer': {
      width: '100%'
    },
    '& $members': {
      padding: '10px 15px',
    }
  },
  cards: {
    display: 'flex',
    flexDirection: 'column'
  },
  '@media (max-width: 479.98px)': {
    cardList: {
      gridTemplateColumns: 'repeat(auto-fit, 165px)',
    }
  }
});

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  user: any,
  tasks: any[],
  taskCount: number,
  closingProcessTasks: any[],
  closingProcessTasksCount: number,
  projects: any[],
  projectsCount: number,
  project: any,
  team: any[],
  onTaskFetch: (search?: string) => any,
  onTaskSort: (sort: ISort[], search: string) => any,
  onClosingProcessTaskFetch: (sort: ISort[], search: string) => any,
  onProjectsFetch: (sort?: ISort[]) => any,
  onProjectFetch: (projectId?: number) => any,
  onPageLoaded: (page: string) => any
}

const DASHBOARD_TASK_LIST         = "DASHBOARD_TASK_LIST";
const DASHBOARD_CLOSED_TASK_LIST  = "DASHBOARD_CLOSED_TASK_LIST";

const DashboardClientPage = (props: IProps) => {
  const { classes, history, user, projects, project, team } = props;
  const loading = useSelector((state: any) => state.loading);
  const windowSize = useWindowSize();
  const [initiated, setInitiated] = useState<boolean>(false);
  const [sortTasks, setSortTasks] = useState<any>({});
  const [sortClosingTasks, setSortClosingTasks] = useState<any>({});





  
  const getProjectIdFromHistory = () : number => {
    const params = props.match.params as any;
    return params && +params.id
  }
  


  const projectId = getProjectIdFromHistory();



  const handleOpenTask = (e: any, id: number): void => {
    if(!projectId) return;
    goTo(e, `/task/${id}`, history)
  }



  const onProjectChange = (id: number): void => {
    history.push(`${ROUTE_DASHBOARD}/${id}`)
  }



  const onInit = () => {
    if (!projectId || !projects.length) {
      props.onProjectsFetch()
    }
    if (projectId && (!props.project.id || projectId !== project.id)) {

      props.onProjectFetch(projectId)
        .then(() => {
          if(!initiated) {
            setInitiated(true);
            return;
          }

          handleSort(sortTasks.sort, sortTasks.search)
          handleSortClosing(sortClosingTasks.sort, sortClosingTasks.search)
        })
        .catch((err: any) => {
          if(err.response?.status === 404) {
            history.push(ROUTE_DASHBOARD)
          }
        })
    }
  }



  const handleSort = (sort: ISort[], search: string): Promise<any> => {
    setSortTasks({ sort, search });
    return props.onTaskSort(sort, `project=${projectId}&assignees=${user.id}${!!search ? `&${search}` : ''}`)
      .then((res: any) => {
        if(isLoading(DASHBOARD_TASK_LIST)) {
          props.onPageLoaded(DASHBOARD_TASK_LIST)
        }
      })
  }



  const handleSortClosing = (sort: ISort[], search: string): Promise<any> => {
    setSortClosingTasks({ sort, search });
    return props.onClosingProcessTaskFetch(sort, `project=${projectId}&column__name=Closing+Process${!!search ? `&${search}` : ''}`)
      .then((res: any) => {
        if(isLoading(DASHBOARD_CLOSED_TASK_LIST)) {
          props.onPageLoaded(DASHBOARD_CLOSED_TASK_LIST)
        }
      })
  }



  const formatColumnName = (text: string) => {
    return text.includes('Capital Provider') ? text.replace('Capital Provider', 'CP') : text
  }



  const isLoading = (page: string): boolean => {
    return !loading.pagesLoaded[page]
  }







  useEffect(() => {
    ReactGA.pageview(props.location.pathname)
  }, [])



  useEffect(() => {
    if(isRoute(ROUTE_TASK)) return;
    onInit();
  }, [projectId]);



  useEffect(() => {
    if (!projectId && projects.length) {
      if(isRoute(ROUTE_TASK)) return;
      history.push(`${ROUTE_DASHBOARD}/${projects[0].id}`)
    }
  }, [projects])








  return (
    <div className={windowSize.isMobile ? classes.mobileRoot : undefined}>
      {project.id && <div>
        <BoardHeader
          project={props.project}
          onProjectChange={onProjectChange}
        />

        <BoardProgress noCP project={props.project} className={classes.boardProgress} />

        

        <div className={classes.cards}>
          <span className={classes.header}>Project Progress</span>

          <div className={classes.cardList}>
            {project.columns.map((column: any, index: number) => (
              <div key={column.name} className={classes.littleCard}>
                <span className={classes.littleCardHeader}>{formatColumnName(column.name)}</span>

                <div className={classes.progressCardWrapper}>
                  <div className={classes.progressCard}>
                    <CircularProgressbarWithChildren
                      value={getProgress(column)}
                      strokeWidth={4}
                      styles={buildStyles({
                        trailColor: getProgressBgColor(getProgress(column)),
                        pathColor: getProgressColor(getProgress(column)),
                        textSize: 12,
                      })}
                    >
                      <span className={classes.progressNumber} style={{color: getProgressColor(getProgress(column))}}>
                        {`${Math.round(getProgress(column))}%`}
                      </span>
                      <span className={classes.progressText}>complete</span>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>

                <div className={classes.littleCardTasks}>
                  <span className={classes.littleCardTasksSquare} style={{backgroundColor: getProgressColor(getProgress(column))}} />
                  <span className={classes.littleCardTasksNumber}>
                    {column.progress ? column.progress.total : column.tasks_count}
                  </span>
                  <span className={classes.littleCardTasksLabel}>tasks</span>
                </div>
              </div>
            ))}
          </div>
        </div>





        <div className={classes.gridContainer}>
          <div className={classes.mainContainer}>
            {windowSize.isMobile
              ? (
                  <Fragment>
                    <MobileList
                      smallHeader disableCaption
                      rows={props.tasks}
                      header={`My Tasks (${props.taskCount})`}
                      type={TableType.TASKS}
                      onSort={handleSort}
                    />

                    <MobileList
                      smallHeader disableCaption
                      rows={props.closingProcessTasks}
                      header={`Closing Process Tasks (${props.closingProcessTasksCount})`}
                      type={TableType.CLOSING_TASKS}
                      onSort={handleSortClosing}
                    />
                  </Fragment>
                )
              : (
                  <Fragment>
                    <span className={classes.header}>{`My Tasks (${props.taskCount})`}</span>

                    <List
                      initialLoading={isLoading(DASHBOARD_TASK_LIST)}
                      rows={props.tasks}
                      count={props.taskCount}
                      onOpen={handleOpenTask}
                      fullAccess={false}
                      tableType={TableType.TASKS}
                      onSort={handleSort}
                      className={classes.card}
                      emptyLabel="You have no"
                    />

                    <span className={classes.header}>{`Closing Process Tasks (${props.closingProcessTasksCount})`}</span>

                    <List
                      initialLoading={isLoading(DASHBOARD_CLOSED_TASK_LIST)}
                      rows={props.closingProcessTasks}
                      count={props.closingProcessTasksCount}
                      onOpen={handleOpenTask}
                      fullAccess={false}
                      tableType={TableType.CLOSING_TASKS}
                      onSort={handleSortClosing}
                      className={classes.card}
                      emptyLabel="You have no"
                    />
                  </Fragment>
                )
            }
          </div>

          <div className={classes.rightContainer}>
            <span className={classes.header}>{`Project Team (${team.length})`}</span>

            <BaseCard
              extraClass={classNames([
                classes.card,
                classes.members
              ])}
            >
              <MembershipList
                readonly noLink
                list={team}
                type={MembershipType.Team}
              />
            </BaseCard>

          </div>
        </div>
      </div>}

      <Footer />

      {windowSize.isMobile
        ? <MobileWarning />
        : null
      }
    </div>
  );
}

const mapStateToProps = state => {
  const { board: { tasks }, projects, user: { user }, teamMembers, closingProcessTasks } = state

  return {
    user,
    tasks: tasks && tasks.results || initialState.results,
    taskCount: tasks && tasks.count || 0,
    closingProcessTasks: tasks && tasks.closingProcessTasks || initialState.results,
    closingProcessTasksCount: tasks && tasks.closingProcessTasksCount || 0,
    projects: projects && projects.results || projectsInitialState.results,
    projectsCount: projects && projects.count || 0,
    project: projects && projects.project || projects.results[0],
    team: teamMembers || [],
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTaskFetch: search => dispatch(searchTasks(search)),
    onTaskSort: (sort, search) => dispatch(getAllTasks(sort, search)),
    onClosingProcessTaskFetch: (sort, search) => dispatch(getClosingProcessTasks(sort, search)),
    onProjectsFetch: sort => dispatch(getProjects(sort)),
    onProjectFetch: projectId => dispatch(getProjectDetails(projectId)),
    onGetBoardColumns: projectId => dispatch(getBoardColumns(projectId)),
    onPageLoaded: page => dispatch(setLoadedPage(page))
  }
}

export default
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(
      withRouter(DashboardClientPage)
    )
  )