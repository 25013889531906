import React, { useMemo } from 'react';
import TaskStatusLabel from '../board/TaskStatusLabel';
import { useTaskData } from './hooks/tasks-hooks';
import classNames from 'classnames';
import { Theme } from '../../../theme';
import { createStyles, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';


const styles = (theme: Theme) => createStyles({
  hidden: {
    display: 'none'
  }
})


interface IProps extends WithStyles<typeof styles> {
  isNew?: boolean;
  className?: string;
  headerClassName?: string;
}

const TaskStatus = ({ classes, isNew, className, headerClassName }: IProps) => {
  const { value: state } = useTaskData('state');
  const { value: dueDate } = useTaskData('due_date');


  

  return useMemo(() => (
    <div className={classNames(
      className,
      {[classes.hidden]: isNew}
    )}>
      <div className={headerClassName}>
        <h2>Status</h2>
      </div>

  
      <TaskStatusLabel type={state} dueDate={dueDate} />
    </div>
  ), [isNew, state, dueDate])
}


export default withStyles(styles)(TaskStatus)