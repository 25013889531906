import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../../utils/user';
import { getIds } from '../../../utils/utils';
import Select from '../../Select';
import { useProjectData } from '../hooks/projects-hooks';
import { EntityField } from '../../../shared/interfaces';


interface IProps extends EntityField {
  items: string;
}

const ProjectInfoSelect = (props: IProps) => {
  const { name, items, ...otherProps } = props;
  const { value, error, readonly, onChange } = useProjectData(name);
  const types = useSelector((state: any) => state.types[items] ?? []);

  




  return useMemo(() => (
    <Select
      value={value}
      readonly={readonly}
      onSelect={onChange}
      valuesList={types}
      error={error}
      {...otherProps}
    />
  ), [readonly, value, error, types])
}


export default ProjectInfoSelect