import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import * as React from "react";
import { connect } from "react-redux";
import { resetPassword } from "../actions/auth";
import { Theme } from "../theme";
import ButtonForm from "./forms/ButtonForm";
import LoginPageFormContainer from "./LoginPageCard";
import Reset from "./passwordReset/Reset";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
  id?: number,
  token: string,
  onGoToMainPage: Function,
  onResetSuccess?: Function,
  onReset: Function,
  invite?: boolean,
  errors: string[],
}

const PasswordReset = (props: IProps) => {
  const { token } = props;

  const handleNewPassword = (password: string) => {
    const data = {
      person: props.id,
      token,
      password
    }

    props.onReset(data)
      .then(props.onResetSuccess)
  }
  if(!token) {
    return (
      <LoginPageFormContainer>
        <ButtonForm
          header="Error occured"
          description={"The link might be dead or inactive."}
          buttons={[
            {
              name: 'Go to main page',
              onClick: () => props.onGoToMainPage(),
              primary: true,
            },
          ]}
          />
      </LoginPageFormContainer>
    )
  }
  
  if(token) {
    return (
      <LoginPageFormContainer>
        <Reset
          onPasswordReset={handleNewPassword}
          header={props.invite ? "Create a password" : "Password Reset"}
          description={props.invite ? "Setup your password for your new FinTech8 account" : "Enter your new password for your FinTech8 account"}
          responseErrors={props.errors}
        />
      </LoginPageFormContainer>
    )
  }

  return null
};

const mapDispatchToProps = dispatch => {
  return {
    onReset: data => dispatch(resetPassword(data))
  }
}

const mapStateToProps = state => {
  return {
    errors: state.user.errors,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PasswordReset))