import React, { memo } from 'react';
import { TableCell } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';



const TableRowCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '6px 20px 6px 0',
      '&:first-child': {
        padding: '14px 10px 14px 20px',
        textAlign: 'center',
        minWidth: 16
      }
    },
    body: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  })
)((props: any) => <TableCell {...props} />);


export default memo(TableRowCell)