import emailTemplatesService from '../services/email-templates';
import { updateFromFormGenerator, sanitizeData } from './common/form';
import { currentEmailTemplateActions } from '../reducers/email/current-template';
import { setSuccess } from './common/notifications';
import { ISort } from '../shared/interfaces';
import { formatSortObject } from './helpers/helpers';

/**
 * Actions
 */

export const SET_TEMPLATES              = 'SET_TEMPLATES';
export const APPEND_TEMPLATES           = 'APPEND_TEMPLATES';
export const APPEND_SINGLE_TEMPLATE     = 'APPEND_SINGLE_TEMPLATE';
export const REMOVE_MULTIPLE_TEMPLATES  = 'REMOVE_MULTIPLE_TEMPLATES';
export const REMOVE_TEMPLATE            = 'REMOVE_TEMPLATE';
export const SET_LOADING                = 'TEMPLATES_SET_LOADING';




/**
 * Action creators
 */

export function setTemplates(templates: any): object {
  return {type: SET_TEMPLATES, templates}
}

export function appendTemplates(templates: any): object {
  return {type: APPEND_TEMPLATES, ...templates}
}

export function removeMultipleTemplates(ids: number[]): object {
  return {type: REMOVE_MULTIPLE_TEMPLATES, ids}
}

export function removeTemplate(id: number): object {
  return {type: REMOVE_TEMPLATE, id}
}

export function appendSingleTemplate(template: any): object {
  return {type: APPEND_SINGLE_TEMPLATE, template}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}



/**
 * Async action creators
 */

export const getEmailTemplates = (sort: ISort[], search: string) => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));


    return emailTemplatesService.getEmailTemplates(body)
      .then((res: any) => dispatch(setTemplates(res)))
      .catch(err => {
        console.log('An error occured while getting email templates.')
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const getNextTemplates = (): any => {
  return (dispatch, getState) => {
    const nextURL = getState().emailManagement?.templates?.next;
    dispatch(setLoading(true));


    if(!nextURL) return;

    return emailTemplatesService.getNextTemplates(nextURL)
      .then((res: any) => {
        dispatch(appendTemplates(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting email templates.');
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const getTemplatePromise = (id: number) => {
  return (dispatch) => {
    return emailTemplatesService.getTemplate(id)
      .then((res: any) => Promise.resolve(res))
      .catch(err => {
        console.log('An error occured while removing an email template.');
        return Promise.reject(err)
      })
  }
}

export const deleteMultipleTemplates = (ids: number[]) => {
  return (dispatch) => {
    return emailTemplatesService.deleteMultipleTemplates(ids)
      .then((res: any) => dispatch(removeMultipleTemplates(ids)))
      .catch(err => {
        console.log('An error occured while removing email templates.')
      })
  }
}

export const deleteTemplate = (id: number) => {
  return (dispatch) => {
    return emailTemplatesService.deleteTemplate(id)
      .then((res: any) => dispatch(removeTemplate(id)))
      .catch(err => {
        console.log('An error occured while removing an email template.')
      })
  }
}


export const createTemplate = () => {
  return (dispatch) => {
    return emailTemplatesService.createTemplate()
      .then((res: any) => {
        dispatch(currentEmailTemplateActions.actionSetFormData(res))
        dispatch(appendSingleTemplate(res))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while removing an email template.');
        return Promise.reject(err)
      })
  }
}

export const uploadTemplateFiles = (id: number, files: any) => {
  return (dispatch, getState) => {
    const state = getState().emailManagement.currentTemplate?.data;
    return emailTemplatesService.uploadTemplateFiles(id, files)
      .then((res: any) => {
        dispatch(currentEmailTemplateActions.actionUpdateField('uploaded_files', res.uploaded_files))
      })
      .catch(err => {
        console.log('An error occured while uploading email template files.')
      })
  }
}

export const deleteTemplateFile = (id: number, fileId: number) => {
  return (dispatch, getState) => {
  return emailTemplatesService.deleteTemplateFile(fileId)
      .then((res: any) => {
        const templateFiles = getState().emailManagement.currentTemplate?.data;
        dispatch(currentEmailTemplateActions.actionUpdateField(
          'uploaded_files', 
          templateFiles?.uploaded_files?.filter((file: any) => file.id !== fileId)
        ))
      })
      .catch(err => {
        console.log('An error occured while deleting email template file.')
      })
  }
}

export const saveTemplate = (body: any) => {
  return (dispatch) => {
    const data = sanitizeData(body);
    return emailTemplatesService.saveTemplate(data)
      .then((res: any) => {
        dispatch(setSuccess({
          message: 'An email template was changed.'
        }))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('Failed to change an email template.');
        return Promise.reject(err)
      })
  }
}

export const saveTemplateAsDraft = (id: number, body: any) => {
  return (dispatch, getState) => {
    const data = sanitizeData(body);
    return emailTemplatesService.saveTemplateAsDraft(id, data)
      .then((res: any) => {
        
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('Failed to change an email template.');
        return Promise.reject(err)
      })
  }
}

export const findEmailTemplates = (searchStr: string = ''): any => {
  return (dispatch) => {
    return emailTemplatesService.findEmailTemplates(searchStr)
      .then((res: any) => {
        dispatch(dispatch(setTemplates(res)));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while searching the search history')
        return Promise.reject(err)
      })
  }
}


export const createTemplatePromise = (form) =>
  updateFromFormGenerator(form, currentEmailTemplateActions, emailTemplatesService.createTemplate, {
    successMessage: 'An email template was created',
    errorMessage: 'Failed to create an email template'
  });