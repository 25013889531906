import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { Theme } from '../theme';

const styles = (theme: Theme) => createStyles({
  chip: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: '5px 10px',
    borderRadius: 14,
    display: 'inline-flex',
    marginBottom: 11,
  },
})

interface IProps extends WithStyles<typeof styles> {

}

const MailForwardedChip = (props: IProps) => {
  const { classes } = props;



  return (
    <span className={classes.chip}>Forwarded</span>
  )
}


export default withStyles(styles)(MailForwardedChip)