import React from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { MembershipType, TeamMembershipType } from '../../../shared/types';
import MembershipItemProvider from './MembershipItemProvider';
import MembershipItemTeam from './MembershipItemTeam';
import { useSelector } from 'react-redux';
import { isAdmin, isOwner } from '../../../utils/user';
import { Divider } from '@material-ui/core';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#fff',
  },
  header: {
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 2.18,
    letterSpacing: 0.53,
    color: '#233539',
    margin: '0 0 3px'
  },
  extraHeader: {
    borderTop: '1px solid #d9e4e8',
    paddingTop: 10,
    marginTop: 5,
    display: 'block',
  }
})

interface IProps extends WithStyles<typeof styles> {
  list: any[];
  type: MembershipType;
  new?: boolean;
  className?: any;
  readonly?: boolean;
  noLink?: boolean;
}

const MembershipList = (props: IProps) => {
  const { classes } = props;
  const user = useSelector((state: any) => state.user.user);
  const team = useSelector((state: any) => state.teamMembers)

  const showOwnerOptions = (person: any): boolean => {
    return (isOwner(user, team) || isAdmin(user)) && !person?.is_universal_owner
  }

  const getItem = (item: any, index: number) => {
    switch (props.type) {
      case MembershipType.CapitalProvider:
        return (
          <MembershipItemProvider
            key={item.id || index}
            id={item.id}
            person={item.person}
            state={item.state}
            readonly={props.readonly}
          />
        )
      case MembershipType.Team:
        return (
          <MembershipItemTeam
            key={item.id || index}
            id={item.id}
            person={item.person}
            state={item.state}
            showOwnerOptions={showOwnerOptions(item.person)}
            readonly={props.readonly}
            noLink={props.noLink}
          />
        )
    }
  }

  const getHeader = () => {
    switch (props.type) {
      case MembershipType.CapitalProvider:
        return (
          <span className={classNames(classes.header, classes.extraHeader)}>
            Add more capital providers...
          </span>
        )
      case MembershipType.Team:
        return (
          <span className={classNames(classes.header, classes.extraHeader)}>
            Add more members...
          </span>
        )
    }
  }

  return (
    <div>
      {props.new && getHeader()}
      <div className={classNames(classes.root, props.className)}>{props.list.map(getItem)}</div>
    </div>
  )
}


export default withStyles(styles)(MembershipList)