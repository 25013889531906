/**
 * Actions
 */

export const SET_LOADING_REQUEST      = 'SET_LOADING_REQUEST';
export const CLEAR_LOADING_REQUEST    = 'CLEAR_LOADING_REQUEST';
export const SET_LOADED_PAGE          = 'SET_LOADED_PAGE';


/**
 * Action creators
 */

export function setLoading(request: string): object {
  return {type: SET_LOADING_REQUEST, request}
}

export function clearLoading(request: string): object {
  return {type: CLEAR_LOADING_REQUEST, request}
}

export function setLoadedPage(page: string): object {
  return {type: SET_LOADED_PAGE, page}
}