import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { countries } from '../../../constants/countries';
import { AutocompleteType } from '../../../shared/types';
import Autocomplete from '../../Autocomplete';
import { usePersonData } from '../hooks/people-hooks';
import { EntityField } from '../../../shared/interfaces';


interface IProps extends EntityField {
  type: AutocompleteType;
}

const ContactInfoAutocomplete = ({ name, type, ...otherProps }: IProps) => {
  const { value, error, readonly, onChange } = usePersonData(name);
  const organizations = useSelector((state: any) => state.organizations);





  const getItems = (): any[] => {
    switch(type) {

      case AutocompleteType.Companies:
        return organizations

      
      case AutocompleteType.Countries:
        return countries


      default:
        return []
    }
  }



  
  return useMemo(() => (
    <Autocomplete doNotSort
      items={getItems()}
      value={value}
      readonly={readonly}
      onChange={onChange}
      error={error}
      maxItems={5}
      type={type}
      {...otherProps}
    />
  ), [readonly, value, error, getItems()])
}


export default ContactInfoAutocomplete