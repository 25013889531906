import * as React from "react";
import { Theme } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  date: {
    //...theme.typography.body2,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    lineHeight: 1.2,
    fontSize: 10,
    color: '#657274'
  },
  description: {
    
  },
  content: {
    ...theme.typography.body2,
    position: 'relative',
    margin: 0
  }
});

interface IProps extends WithStyles<typeof styles> {
  header: any;
  date?: string;
  description?: any;
  children?: any;
  showOnlyContent?: boolean;
  className?: any;
}

const NoteBaseContainer = (props: IProps) => {
  const { classes } = props;

  return (
    <div >
      {!props.showOnlyContent && (
        <React.Fragment>
          <div className={classes.headerContainer}>
            {props.header}
            {props.date
              ? (
                  <div className={classes.date}>
                    {props.date}
                  </div>
                )
              : null
            }
          </div>
          {props.description && (
            <div className={classes.description}>
              {props.description}
            </div>
          )}
        </React.Fragment>
      )}
      <div className={classNames(
        classes.content,
        {[props.className]: !!props.className}
      )}>
        {props.children}
      </div>
    </div>
  );
  
}

export default withStyles(styles)(NoteBaseContainer)