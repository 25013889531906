import { createStyles } from "@material-ui/core"
import { withStyles, WithStyles } from "@material-ui/styles"
import React, { useEffect } from "react"
import ReactGA from 'react-ga'
import { useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import Button from '../../components/Button'
import { BlueDanger } from "../../components/icons/BlueDanger"
import { ROUTE_LOGIN, ROUTE_DASHBOARD } from "../../constants/routes"
import { Theme } from "../../theme"
import { isAuthenticated } from "../../utils/user"


const styles = (theme: Theme) => createStyles({
  root: {
    background: '#f7f9fa',
    minHeight: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    padding: 0,
    width: '100%',
    margin: '10px auto 0',
    maxWidth: 800,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '25px 20px 32px',
    backgroundColor: '#ffffff',  
    borderRadius: 4,
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  dangerSymbol: {
    // transform: 'scale(3)'
    height: 70,
    width: 70,
    paddingBottom: 12,
  },
  header: {
    paddingBottom: 13,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#233539',
  },
  description: {
    paddingBottom: 18,
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: 1.5,
    color: '#657274',
  },
  returnBtn: {
    width: 122,
  },
})


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
}

const NotFoundPage = (props: IProps) => {
  const { classes, history, } = props
  const user = useSelector((state: any) => state.user)

  useEffect(() => {
    ReactGA.pageview(props.location.pathname)
  }, [])

  const onButtonClick = () => {
    isAuthenticated(user)
      ? history.push(ROUTE_DASHBOARD)
      : history.push(ROUTE_LOGIN)
  }

  return (
    <div className={classes.root}>
      <div className={classes.errorContainer}>
        <BlueDanger className={classes.dangerSymbol} />
        <span className={classes.header}>Sorry, something went wrong.</span>
        <span className={classes.description}>The page you’re looking for can’t be found. Please double check the URL and try again.</span>
        <Button
          onClick={onButtonClick}
          className={classes.returnBtn}
          primary
        >
          Return home
        </Button>
      </div>
    </div>
  )
}


export default withStyles(styles)(
  withRouter(NotFoundPage)
)
