import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import OutlookConnect from '../components/OutlookConnect';
import PasswordResetRequest from '../components/PasswordResetRequest';
import ProjectBoard from '../components/project/board/ProjectBoard';
import TaskDialog from '../components/project/task/TaskDialog';
import ActivityPage from '../containers/activity/ActivityPage';
import CalendarPage from '../containers/calendar/CalendarPage';
import LoginPage from '../containers/common/LoginPage';
import MainContainer from '../containers/common/MainContainer';
import NotFoundPage from '../containers/common/NotFoundPage';
import PasswordResetContainer from '../containers/common/PasswordResetContainer';
import ContactManagementContainer from '../containers/contact-management/ContactManagementContainer';
import Dashboard from '../containers/dashboard/Dashboard';
import EmailManagementContainer from '../containers/emails-management/EmailManagementContainer';
import CookiePolicy from '../containers/legal/CookiePolicy';
import PrivacyPolicy from '../containers/legal/PrivacyPolicy';
import TermsAndConditions from '../containers/legal/TermsAndConditions';
import EmailComponent from '../containers/people/email/EmailComponent';
import EmailCompose from '../containers/people/email/EmailCompose';
import EmailSuccessPage from '../containers/people/email/EmailSuccessPage';
import PeopleListPage from '../containers/people/PeopleListPage';
import PersonPage from '../containers/people/PersonPage';
import UserPreferencesContainer from '../containers/preferences/UserPreferencesContainer';
import PrintContainer from '../containers/print/PrintContainer';
import ProjectCapitalProvidersPage from '../containers/projects/ProjectCapitalProvidersPage';
import ProjectPage from '../containers/projects/ProjectPage';
import ProjectsListPage from '../containers/projects/ProjectsListPage';
import SearchPage from '../containers/search/SearchPage';
import { SearchTypeByPath } from '../shared/models/Search';
import BulkEditDialog from '../containers/people/edit/BulkEditDialog';
import { RouteInterface } from '../shared/interfaces';



export const ROUTE_ROOT                                         = '/';
export const ROUTE_ALL                                          = '*';
export const ROUTE_NOT_FOUND                                    = '/not-found';

export const ROUTE_LOGIN                                        = '/login';
export const ROUTE_PASSWORD_RESET                               = '/password-reset';
export const ROUTE_RESET                                        = '/reset/:id/:token';
export const ROUTE_INVITE                                       = '/invite/:id/:token';
export const ROUTE_OUTLOOK_CONNECT                              = '/outlook-connect';

export const ROUTE_PRIVACY_POLICY                               = '/privacy-policy';
export const ROUTE_COOKIE_POLICY                                = '/cookie-policy';
export const ROUTE_TS_AND_CS                                    = '/terms-and-conditions';

export const ROUTE_DASHBOARD                                    = '/dashboard';
export const ROUTE_DASHBOARD_PROJECT                            = '/dashboard/:id?';

export const ROUTE_PEOPLE                                       = '/contacts';
export const ROUTE_PERSON_GET                                   = '/contacts/:id';
export const ROUTE_PERSON_NEW                                   = '/contacts/new';
export const ROUTE_PEOPLE_EDIT_MULTIPLE                         = '/contacts/editMultiple';
export const ROUTE_PEOPLE_EMAIL                                 = '/contacts/email';
export const ROUTE_PEOPLE_ASSIGN_TO_PROJECT                     = '/contacts/assignToProject';
export const OLD_ROUTE_PEOPLE                                   = '/people'

export const ROUTE_PROJECTS                                      = '/projects';
export const ROUTE_PROJECT_GET                                  = '/projects/:id';
export const ROUTE_PROJECT_CP                                   = '/projects/:id/email-tracker';
export const ROUTE_PROJECT_NEW                                  = '/projects/new';
export const ROUTE_PROJECT_BOARD                                = '/projects/:id/tasks';
export const ROUTE_PROJECT_PRINT_CP                             = '/projects/:id/email-tracker/print';

export const ROUTE_CALENDAR                                     = '/calendar/:range';

export const ROUTE_SEARCH                                       = '/search';
export const ROUTE_SEARCH_TYPE                                  = '/search/:type?';
export const ROUTE_SEARCH_SAVED                                 = '/search/:type?/saved/:savedSearchId?';
export const ROUTE_SEARCH_PEOPLE_EDIT_MULTIPLE                  = '/search/contacts/editMultiple';
export const ROUTE_SEARCH_PEOPLE_EMAIL                          = '/search/contacts/email';
export const ROUTE_SEARCH_PEOPLE_ASSIGN_TO_PROJECT              = '/search/contacts/assignToProject';

export const ROUTE_PRINT                                        = '/print/:type';

export const ROUTE_EMAIL_MANAGEMENT                             = '/email-management';
export const ROUTE_EMAIL_MANAGEMENT_DRAFTS                      = '/email-management/drafts';
export const ROUTE_EMAIL_MANAGEMENT_DRAFT                       = '/email-management/drafts/:id';
export const ROUTE_EMAIL_MANAGEMENT_TEMPLATES                   = '/email-management/templates';
export const ROUTE_EMAIL_MANAGEMENT_TEMPLATE                    = '/email-management/templates/:id';
export const ROUTE_EMAIL_MANAGEMENT_TEMPLATE_NEW                = '/email-management/templates/new';

export const ROUTE_EMAIL_SUCCESS                                = '/email/success';
export const ROUTE_EMAIL_DRAFT                                  = '/email/drafts/:id';
export const ROUTE_SINGLE_EMAIL                                 = '/email/:id';
export const ROUTE_SINGLE_EMAIL_PRINT                           = '/email/:id/print';

export const ROUTE_CONTACT_MANAGEMENT                           = '/contact-management';
export const ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATE_MERGE  = '/contact-management/imported-duplicates/merge/:id'
export const ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES       = '/contact-management/imported-duplicates';
export const ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATE_MERGE  = '/contact-management/possible-duplicates/merge';
export const ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES       = '/contact-management/possible-duplicates';
export const ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS              = '/contact-management/new-contacts';
export const ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS          = '/contact-management/deleted-contacts';

export const ROUTE_PREFERENCES                                  = '/preferences';
export const ROUTE_PREFERENCES_EMAIL_SIGNATURE                  = '/preferences/email-signature';

export const ROUTE_ACTIVITY                                     = '/activity';

export const ROUTE_TASK_NEW                                     = '/task/new';
export const ROUTE_TASK                                         = '/task/:taskId';

export const ROUTE_EDIT_MULTIPLE_CONTACTS                       = '/edit-multiple-contacts';









export const overlayRoutes: RouteInterface[] = [
  {
    path: ROUTE_TASK_NEW,
    exact: true,
    render: () => <TaskDialog isNew />
  },
  {
    path: ROUTE_TASK,
    exact: true,
    render: (props: any) => Number.isInteger(+props.match?.params?.taskId)
      ? <TaskDialog id={+props.match.params.taskId} />
      : null
  },
  {
    path: ROUTE_EDIT_MULTIPLE_CONTACTS,
    exact: true,
    render: (props: any) => {
      const params = new URLSearchParams(props.location.search);
      const items = params.get('items')?.split(',') ?? [];
      const filteredItems = items.map((item: string) => +item).filter((item: number) => !isNaN(item));

      if(!filteredItems.length) {
        return <Redirect to={ROUTE_PEOPLE} />
      }

      if(items.length !== filteredItems.length) {
        return <Redirect to={`${ROUTE_EDIT_MULTIPLE_CONTACTS}?items=${filteredItems.join(',')}`} />
      }


      return (
        <BulkEditDialog ids={filteredItems} />
      )
    }
  }
]





export const routes: RouteInterface[] = [
  {
    path: ROUTE_RESET,
    component: PasswordResetContainer
  },
  {
    path: ROUTE_PASSWORD_RESET,
    component: PasswordResetRequest
  },
  {
    path: ROUTE_INVITE,
    render: () => <PasswordResetContainer invite />
  },
  {
    path: ROUTE_LOGIN,
    component: LoginPage
  },
  {
    path: ROUTE_OUTLOOK_CONNECT,
    component: OutlookConnect
  },
  {
    path: ROUTE_PRIVACY_POLICY,
    component: PrivacyPolicy
  },
  {
    path: ROUTE_COOKIE_POLICY,
    component: CookiePolicy
  },
  {
    path: ROUTE_TS_AND_CS,
    component: TermsAndConditions
  },
  {
    path: [ROUTE_PRINT, ROUTE_PROJECT_PRINT_CP],
    render: (props: any) => <PrintContainer type={SearchTypeByPath[props.match.params.type]} typePath={props.match.params.type} {...props} />
  },
  {
    path: ROUTE_EMAIL_SUCCESS,
    component: EmailSuccessPage
  },
  {
    path: ROUTE_EMAIL_DRAFT,
    render: (props: any) => Number.isInteger(+props.match?.params?.id)
      ? <EmailCompose id={+props.match.params.id} {...props} />
      : <NotFoundPage />
  },
  {
    path: ROUTE_SINGLE_EMAIL_PRINT,
    render: (props: any) => Number.isInteger(+props.match?.params?.id)
      ? <EmailComponent readonly id={+props.match.params.id} />
      : <NotFoundPage />
  },
  {
    path: ROUTE_SINGLE_EMAIL,
    render: (props: any) => Number.isInteger(+props.match?.params?.id)
      ? <EmailComponent id={+props.match.params.id} />
      : <NotFoundPage />
  },
  {
    path: ROUTE_ROOT,
    component: MainContainer
  },
  {
    path: [ROUTE_NOT_FOUND, ROUTE_ALL],
    component: NotFoundPage
  },
];




export const authenticatedRoutes: RouteInterface[] = [
  {
    path: OLD_ROUTE_PEOPLE,
    render: (props: any) => props.location.pathname.includes(OLD_ROUTE_PEOPLE)
      ? <Redirect to={`${props.location.pathname.replace(OLD_ROUTE_PEOPLE, ROUTE_PEOPLE)}${props.location.search}`} />
      : <Redirect to={ROUTE_PEOPLE} />
  },
  {
    path: [
      ROUTE_PEOPLE,
      ROUTE_PEOPLE_EDIT_MULTIPLE,
      ROUTE_PEOPLE_EMAIL,
      ROUTE_PEOPLE_ASSIGN_TO_PROJECT,
      ROUTE_EDIT_MULTIPLE_CONTACTS
    ],
    render: () => <PeopleListPage />,
    exact: true
  },
  {
    path: ROUTE_PROJECTS,
    component: ProjectsListPage,
    exact: true
  },
  {
    path: ROUTE_PERSON_NEW,
    render: () => <PersonPage createNew />
  },
  {
    path: ROUTE_PERSON_GET,
    component: PersonPage
  },
  {
    path: [ROUTE_PROJECT_BOARD, ROUTE_TASK],
    render: (props: any) => <ProjectBoard id={+props.match.params.id} />
  },
  {
    path: ROUTE_PROJECT_NEW,
    render: () => <ProjectPage createNew />
  },
  {
    path: [ROUTE_PROJECT_CP, ROUTE_PROJECT_PRINT_CP],
    render: (props: any) => <ProjectCapitalProvidersPage projectId={props.match.params.id} {...props} />
  },
  {
    path: ROUTE_PROJECT_GET,
    render: (props: any) => Number.isInteger(+props.match?.params?.id)
      ? <ProjectPage id={+props.match.params.id} />
      : <NotFoundPage />
  },
  {
    path: ROUTE_CALENDAR,
    component: CalendarPage
  },
  {
    path: [ROUTE_DASHBOARD_PROJECT, ROUTE_DASHBOARD],
    component: Dashboard
  },
  {
    path: [
      ROUTE_SEARCH_SAVED,
      ROUTE_SEARCH_TYPE,
      ROUTE_SEARCH_PEOPLE_EDIT_MULTIPLE,
      ROUTE_SEARCH_PEOPLE_EMAIL,
      ROUTE_SEARCH_PEOPLE_ASSIGN_TO_PROJECT,
      ROUTE_SEARCH,
    ],
    render: (props: any) => (
      <SearchPage
        type={SearchTypeByPath[props.match.params.type]}
        typePath={props.match?.params?.type}
        searchId={+props.match?.params?.savedSearchId}
        {...props}
      />
    )
  },
  {
    path: [
      ROUTE_EMAIL_MANAGEMENT,
      ROUTE_EMAIL_MANAGEMENT_DRAFT,
      ROUTE_EMAIL_MANAGEMENT_DRAFTS,
      ROUTE_EMAIL_MANAGEMENT_TEMPLATE,
      ROUTE_EMAIL_MANAGEMENT_TEMPLATES,
      ROUTE_EMAIL_MANAGEMENT_TEMPLATE_NEW
    ],
    component: EmailManagementContainer
  },
  {
    path: [
      ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATE_MERGE,
      ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES,
      ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATE_MERGE,
      ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES,
      ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS,
      ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS,
      ROUTE_CONTACT_MANAGEMENT
    ],
    component: ContactManagementContainer
  },
  {
    path: [
      ROUTE_PREFERENCES_EMAIL_SIGNATURE,
      ROUTE_PREFERENCES
    ],
    component: UserPreferencesContainer
  },
  {
    path: [
      ROUTE_ACTIVITY,
    ],
    component: ActivityPage
  },
  {
    path: [ROUTE_NOT_FOUND, ROUTE_ALL],
    component: NotFoundPage
  },
];


export const guestRoutes: RouteInterface[] = [
  {
    path: [ROUTE_DASHBOARD_PROJECT, ROUTE_DASHBOARD],
    component: Dashboard
  },
  {
    path: [ROUTE_NOT_FOUND, ROUTE_ALL],
    component: NotFoundPage
  },
];




export const renderRoutes = (routes: RouteInterface[]) => (
  routes.map((props: RouteInterface, idx: number) => (
    <Route key={idx} {...props} />
  ))
)

export const renderRoutesWithSwitch = (routes: RouteInterface[]) => (
  <Switch>{renderRoutes(routes)}</Switch>
)