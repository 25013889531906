import { LinearProgress } from "@material-ui/core";
import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { logout, updateCurrentUser } from "../../actions/auth";
import { getOrganizations } from "../../actions/organizations";
import { getOutlookStatus } from "../../actions/outlook";
import { clearAllProjectsData } from "../../actions/projects/projects";
import { getTags } from "../../actions/tags";
import { getTypes } from "../../actions/types";
import Header from "../../components/header/Header";
import { ROUTE_DASHBOARD, ROUTE_TASK } from "../../constants/routes";
import { useWindowSize } from "../../shared/hooks";
import { Theme } from "../../theme";
import { isAuthenticated, isGuest } from '../../utils/user';
import { isRoute } from "../../utils/utils";
import MainRoutes from "./MainRoutes";
import UserConfig from "./UserConfig";


const styles = (theme: Theme) => createStyles({
  container: {
    padding: '76px 20px 20px',
    background: '#f7f9fa',
    minHeight: '100%',
    height: 'auto',
    boxSizing: 'border-box',
    position: 'relative'
  },
  guestContainer: {
    padding: '76px 20px 56px 20px',
    background: '#f7f9fa',
    minHeight: '100%',
    boxSizing: 'border-box',
    position: 'relative'
  },
  containerMobile: {
    padding: '76px 15px 97px', // 97px due to footer height at the end
    position: 'relative'
  },
  loading: {
    zIndex: 2000,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%'
  }
});

interface IProps extends WithStyles<typeof styles> {
}


const MainContainer = (props: IProps) => {
  const { classes } = props;
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state: any) => state.user.token);
  const user = useSelector((state: any) => state.user.user);
  const isLoading = useSelector((state: any) => state.loading.isLoading);





  const onInit = (): void => {
    dispatch(getTypes());
    dispatch(getOrganizations());
    dispatch(getTags());


    if(token && user?.id) {
      dispatch(getOutlookStatus(user.id));
      dispatch(updateCurrentUser())
    }
  }



  const handleRedirect = (): void => {
    if (isAuthenticated(user)) {


      if(isRoute(ROUTE_TASK)) {
        return
      }


      if (isGuest(user) && history.location.pathname !== "/") {
        return history.push(ROUTE_DASHBOARD)
      }

      if (history.location.pathname === "/") {
        return history.push(ROUTE_DASHBOARD)
      }

    } else {
      return history.push('/login')
    }
  }



  const onLogout = (): any => {
    dispatchLogout()
      .then((res: any) => {
        dispatchClearEverything()
        history.push('/login')
      })
  }







  const dispatchLogout = (): any => {
    return dispatch(logout())
  }



  const dispatchClearEverything = (): any => {
    dispatch(clearAllProjectsData())
  }




  useEffect(() => {
    onInit();
    handleRedirect()
  }, [])






  if(!isAuthenticated(user)) {
    return null
  }


  if(windowSize.isMobile && !isRoute(ROUTE_DASHBOARD)) {
    history.push(ROUTE_DASHBOARD);
    return null;
  }


  return (
    <div>
      {isLoading && <LinearProgress className={classes.loading} />}
      
      <Header onLogoutClick={onLogout} />


      <div id="MainContainer"
        className={classNames(
          classes.container,
          {[classes.containerMobile]: windowSize.isMobile}
        )}
      >

          <UserConfig />
          <MainRoutes />

      </div>
    </div>
  )
}


export default withStyles(styles)(MainContainer);
