import activityService from '../services/activity';
import { ISort, IDialogMenuItem } from '../shared/interfaces';
import { formatSortObject, forceDownloadFile } from './helpers/helpers';
import { setError } from './common/notifications';
import moment from 'moment';
import { TableType } from '../shared/types';
import { FiberManualRecordSharp } from '@material-ui/icons';

/**
 * Actions
 */

export const SET_ACTIVITY     = 'SET_ACTIVITY';
export const UPDATE_ACTIVITY  = 'UPDATE_ACTIVITY';
export const SET_LOADING      = 'ACTIVITY_SET_LOADING';



/**
 * Action creators
 */

export function setActivity(activity: any): object {
  return {type: SET_ACTIVITY, activity}
}

export function updateAtivity(activity: any): object {
  return {type: UPDATE_ACTIVITY, activity}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}



/**
 * Async action creators
 */


const formatFilters = (body: string, filters: any): string => {
  if(!Object.keys(filters).length) return body;

  const fields = [] as string[];
  if(!!filters.typesIds?.length) fields.push(`&types=${filters.typesIds.join(',')}`);
  if(!!filters.user?.id) fields.push(`&person=${filters.user.id}`);
  if(!!filters.dateFrom) fields.push(`&date_from=${filters.dateFrom}`);
  if(!!filters.dateTo) fields.push(`&date_to=${filters.dateTo}`);

  return `${!body.includes('?') ? '?' : ''}${body}${fields.join('')}`
}




export const getActivity = (sort: ISort[], search: string, filters: any): any => {
  return (dispatch) => {
    let body = formatSortObject(sort, search)
    body = formatFilters(body, filters);
    dispatch(setLoading(true))


    return activityService.getActivity(body)
      .then((res: any) => {
        dispatch(setActivity(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting the types.');
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const advancedSearchActivity = (body: string) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    return activityService.getActivity(body)
      .then((res: any) => {
        dispatch(setActivity(res))
      })
      .catch(err => {
        console.log('An error occured while getting the types.')
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const getNextActivity = () => {
  return (dispatch, getState) => {
    const next = getState().activity.next

    if(!next) return;

    return activityService.getNextActivity(next)
      .then((res: any) => {
        dispatch(updateAtivity(res))
      })
      .catch(err => {
        console.log('An error occured while getting the types.')
      })
  }
}


export const exportActivity = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return activityService.exportActivity(ids)
      .then((res: any) => {
        if(!res) return;
        forceDownloadFile(res, `ft8-activity-${moment().format('YYYYMMDD')}.csv`)
      })
      .catch(err => {
        console.log(err, err.response)
        dispatch(setError("An error occured while exporting activity", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}
