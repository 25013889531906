import { LinearProgress, CircularProgress } from "@material-ui/core";
import { createStyles, withStyles, WithStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { deleteDraft, deleteDraftFile, getDraft, saveDraft, saveDraftAsTemplate, sendCompanyDraft, sendDraft, uploadDraftFiles } from "../../../actions/email-drafts";
import { saveTemplateAsDraft } from "../../../actions/email-templates";
import BaseCard from "../../../components/BaseCard";
import EmailComposeFooter from "../../../components/email/EmailComposeFooter";
import EmptyMail, { EmptyIcon } from "../../../components/EmptyMail";
import { ROUTE_EMAIL_SUCCESS } from "../../../constants/routes";
import { currentEmailDraftsActions } from "../../../reducers/email/current-drafts";
import { emailPeopleActions } from "../../../reducers/people/email";
import { useTimeout } from "../../../shared/hooks";
import { Theme } from "../../../theme";
import EmailDraftForm from "./EmailDraftForm";
import EmailComposeInlineFooter from "../../../components/email/EmailComposeInlineFooter";


const styles = (theme: Theme) => createStyles({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 60px',
    boxSizing: 'border-box',
    overflow: 'auto',
    height: 'initial !important',
    minHeight: '100%'
  },
  cardInline: {
    boxShadow: 'none !important',
    padding: 20
  },
  content: {
    paddingTop: 0
  },
  outlookPrompt: {
    padding: '60px 0 19px 0'
  },
  loading: {
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 10000
  },
  pendingLoading: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    flexDirection: 'column',
    transition: 'all .3s ease'
  },
  pendingLoadingText: {
    marginTop: 20,
    fontSize: 16,
    fontWeight: 500,
    color: '#657274'
  },
  pendingLoadingHidden: {
    zIndex: -1,
    visibility: 'hidden',
    opacity: 0
  }
});



interface IProps extends WithStyles<typeof styles> {
  id: number;
  inline?: boolean;
  forward?: boolean;
  className?: string;
  onSend?: (store: any) => any;
  onCancel?: () => any;
  onUnmount?: (draftId: number) => any;
  pending?: boolean;
}


const EmailCompose = (props: IProps) => {
  const { classes, id, inline } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const drafts = useSelector((state: any) => state.emailManagement.currentDrafts.data);
  const sendPending = useSelector((state: any) => state.emailManagement.drafts.loading);
  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const notificationTimeout = useTimeout(3000);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notification, setNotification] = useState<string>('');


  const getStore = (): any => {
    return (id !== undefined && drafts[id]) ?? {}
  }



  const onChange = (obj: any): void => {
    if(id === undefined) return;

    dispatch(currentEmailDraftsActions.actionUpdateField(id, {
      ...drafts[id],
      ...obj
    }))
  }



  const showDraftSavedNotification = (type: string): void => {
    switch(type) {
      case 'draft':
        setNotification('Your draft was saved successfully');
        break;
      case 'template':
        setNotification('Your template was saved successfully');
        break;
    }
    setShowNotification(true);
    notificationTimeout.reset(() => setShowNotification(false))
  }



  const onGoBack = () => {
    dispatch(emailPeopleActions.actionResetForm());
    window.close();
  };



  const succeed = () => {
    history.push(ROUTE_EMAIL_SUCCESS)
  }



  const onCancel = (): void => {
    props.onCancel?.()
  }




  const onSend = () => {
    const store = getStore();

    if(props.onSend !== undefined) {
      return props.onSend(store)
    }

    if(store.is_company) {
      dispatchSendCompanyEmail(store).then((res: any) => succeed())
    } else {
      dispatchSendEmail(store).then((res: any) => succeed())
    }

  };




  const onLoadTemplateSelect = (template: any): Promise<boolean> => {
    const store = getStore();
    if(!store || !template) return Promise.reject(false);

    const { to, cc, bcc } = store;
    return dispatchSaveTemplateAsDraft(template.id, {to, cc, bcc})
      .then((res: any) => {

        dispatchDeleteDraft(store.id);

        if(res.id) {
          history.push(`/email/drafts/${res.id}`);
          return Promise.resolve(true)
        }
      })
      .catch((err: any) => {
        console.log(err);
        return Promise.reject(false)
      })
  }



  const onFilesUpload = (files: any): void => {
    if(id === undefined) return;

    dispatchUploadFiles(id, files)
  }



  const onFileDelete = (fileId: number): void => {
    if(id === undefined) return;
    
    dispatchDeleteDraftFile(id, fileId)
  }



  const onSaveAsDraft = (): void => {
    dispatchSaveDraft(getStore())
      .then((res: any) => showDraftSavedNotification('draft'))
  }



  const onSaveAsTemplate = (name: string): void => {
    const body = {...getStore(), name};
    dispatchSaveDraftAsTemplate(body)
      .then((res: any) => showDraftSavedNotification('template'))
  }






  const dispatchSendEmail = (body: any): any => {
    return dispatch(sendDraft(body))
  }

  const dispatchSendCompanyEmail = (body: any): any => {
    return dispatch(sendCompanyDraft(body))
  }

  const dispatchGetDraft = (id: number): any => {
    return dispatch(getDraft(id))
  }

  const dispatchSaveDraft = (body: any): any => {
    return dispatch(saveDraft(body))
  }

  const dispatchUploadFiles = (id: number, files: any): any => {
    return dispatch(uploadDraftFiles(id, files))
  }

  const dispatchDeleteDraftFile = (id: number, fileId: number): any => {
    return dispatch(deleteDraftFile(id, fileId))
  }

  const dispatchSaveDraftAsTemplate = (body: any): any => {
    return dispatch(saveDraftAsTemplate(body))
  }

  const dispatchSaveTemplateAsDraft = (templateId: number, body: any): any => {
    return dispatch(saveTemplateAsDraft(templateId, body))
  }

  const dispatchDeleteDraft = (id: number): any => {
    return dispatch(deleteDraft(id))
  }






  useEffect(() => {
    if(id !== undefined) {
      dispatchGetDraft(id)
    }
  }, [id])



  useEffect(() => {
    return () => props.onUnmount?.(id)
  }, [])





  
  return (
    <BaseCard extraClass={classNames(
      classes.card,
      {[classes.cardInline]: inline},
      props.className
    )}>
      {isLoading && <LinearProgress className={classes.loading} />}

      {user.is_ms_authorized
        ? (
          <Fragment>

            <div className={classNames(
              classes.pendingLoading,
              {[classes.pendingLoadingHidden]: props.pending === undefined ? !sendPending : !props.pending }
            )}>
              <CircularProgress size={60} />
              <span className={classes.pendingLoadingText}>
                Sending email...
              </span>
            </div>

            <EmailDraftForm store={getStore()} forward={props.forward}
              {...{ onChange, onFilesUpload, onFileDelete }}
            />

            
            {!inline && (
              <EmailComposeFooter store={getStore()}
                {...{ onSend, notification, showNotification, onLoadTemplateSelect, onSaveAsDraft, onSaveAsTemplate, onGoBack }}
              /> 
            )}


            {inline && (
              <EmailComposeInlineFooter store={getStore()}
              {...{ onSend, onCancel }}
              />
            )}



          </Fragment>
        )
        : (
          <div className={classes.outlookPrompt}>
            <EmptyMail 
              icon={EmptyIcon.Mail}
              text='You need to be authenticated with Outlook to do that'
              showConnect={true}
            />
          </div>
        )
      }

    </BaseCard>
)};

export default withStyles(styles)(EmailCompose)
