import AddIcon from '@material-ui/icons/Add';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAddingNewComment } from '../../../../actions/tasks';
import { Theme } from '../../../../theme';


const styles = (theme: Theme) => createStyles({
  addIcon: {
    fontSize: 20,
    color: '#4192ec'
  },
  add: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > span': {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 2,
      color: theme.palette.primary.main,
    }
  },
  addHidden: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  count: number;
  className?: string;
  readonly?: boolean;
}

const TaskCommentsHeader = (props: IProps) => {
  const { classes, className, readonly, count } = props;
  const dispatch = useDispatch();
  const isAdding = useSelector((state: any) => state.board.tasks.taskView.newComment);
  



  


  const dispatchSetIsAdding = (): any => {
    return dispatch(setAddingNewComment(true))
  }





  return useMemo(() => (
    <div className={className}>
      <h2>Comments {!!count ? `(${count})` : ''}</h2>

        <div
          onClick={dispatchSetIsAdding}
          className={classNames(
            classes.add,
            {[classes.addHidden]: !readonly || isAdding}
          )}>
          <AddIcon className={classes.addIcon} />
          <span>Add</span>
        </div>

    </div>
  ), [isAdding, readonly, count])
}


export default withStyles(styles)(TaskCommentsHeader)