import { Chip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useShowAll, useTimeout } from '../shared/hooks';
import { IAutocompleteTag, IDialogMenuItem } from '../shared/interfaces';
import { Theme } from '../theme';
import { getIds } from '../utils/utils';
import DialogMenu from './DialogMenu';
import TextInput from './TextInput';


const ADORNMENT_PADDING_DEFAULT = 8;

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 60,
    overflow: 'hidden',
  },
  tagsEditable: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid transparent',
    padding: `8px 70px 0 ${ADORNMENT_PADDING_DEFAULT}px`,
    outline: 'none',
    backgroundColor: theme.palette.primary.light,
    transition: 'all .1s ease',
    overflow: 'unset',
    '&:focus-within, &:focus': {
      borderColor: '#4192ec',
      backgroundColor: 'transparent',
      outline: 'none',
      '& $startAdornment': {
        outline: 'none',
        fontWeight: 500,
      },
      '& $chipX': {
        display: 'flex'
      },
      '& $tagChip': {
        margin: '0 10px 8px 0',
        '& span': {
          padding: 8,
          '&:after': {
            content: '""'
          }
        }
      }
    },
    '& $chipX': {
      display: 'none'
    },
    '& $tagChip': {
      margin: '0 4px 8px 0',
      '& > span': {
        padding: 0,
        '&:after': {
          content: '","',
        },
      },
      '&:first-child:last-child': {
        '& > span:after': {
          content: '""'
        }
      },
      '&:nth-last-child(2)': {
        marginRight: 0,
        '& > span:after': {
          content: '""'
        }
      }
    }
  },
  fullheight: {
    maxHeight: 'unset'
  },
  tagChip: {
    borderRadius: 14,
    backgroundColor: '#e8f2fd',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.67,
    color: '#4192ec',
    margin: '0 5px 10px 0',
    display: 'inline-flex',
    float: 'left',
    transition: 'all .1s ease',
    maxWidth: '100%'
  },
  chipX: {
    color: '#4192ec',
  },
  inputWrapper: {
    display: 'inline-flex',
    maxWidth: '100%',
    marginTop: 2,
    position: 'relative'
  },
  input: {
    '& input': {
      fontSize: 12,
      fontFamily: 'Montserrat',
      fontWeight: 400,
      lineHeight: 1.67,
      height: 'initial',
      borderRadius: '0',
      border: '0',
      color: '#4192ec',
      padding: '0',
      backgroundColor: 'transparent',
      marginBottom: 10,
      '&:focus': {
        backgroundColor: 'transparent'
      }
    }
  },
  dialog: {
    position: 'absolute',
    top: '120%',
    maxHeight: 205,
    overflow: 'auto',
  },
  spacingBottom: {
    marginBottom: 10
  },
  startAdornment: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.67,
    color: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: 8,
    transform: 'translateY(-50%)',
  },
  disabled: {
    '& $tags': {
      backgroundColor: '#e9eced',
      pointerEvents: 'none',
      '&:hover, &:focus, &:focus-within': {
        borderColor: 'transparent'
      },
      '& input, & input::placeholder': {
        color: '#657274',
        opacity: 0.5,
      }
    }
  },
  personThemeTags: {
    '& $dialog': {
      maxHeight: 325,
      width: '100%',
      left: 0,
      border: 0
    },
    '& $inputWrapper': {
      position: 'unset'
    }
  },
  onlyOne: {
    padding: '8px 8px 0',
  }
})

interface IProps extends WithStyles<typeof styles> {
  tags: IAutocompleteTag[];
  onChange: (tags: IDialogMenuItem|any[]) => any;
  getValue: any;
  getSortValue?: any;
  searchAction?: any; 
  readonly?: boolean;
  placeholder?: string;
  spacingBottom?: boolean;
  startAdornment?: string;
  onlyOne?: boolean;
  disabled?: boolean;
  personTheme?: boolean;
  getSubtitle?: any;
}

const AutocompleteTags = (props: IProps) => {
  const { classes, readonly, tags } = props;
  const showAll = useShowAll();
  const timeout = useTimeout(700);
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>('');
  const [items, setItems] = useState<IDialogMenuItem[]>([]);
  const [adornmentPaddingOffset, setAdornmentPaddingOffset] = useState<number>(ADORNMENT_PADDING_DEFAULT);
  const [activeIdx, setActiveIdx] = useState<number>(-1);
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const [offsetHeight, setOffsetHeight] = useState<number>(0);

  let inputRef: HTMLInputElement;
  let adornmentRef: HTMLInputElement | null;
  let dialogRef = useRef<HTMLDivElement>();



  const getFormattedTag = (tag: any): IDialogMenuItem => {
    return {
      id: tag.id,
      title: props.getValue(tag),
      subtitle: props.getSubtitle?.(tag),
      sortValue: props.getSortValue?.(tag)
    }
  }


  const focusInput = (e: any) => {
    inputRef && inputRef.focus()
  }


  const handleDelete = (e: any, tag: any): void => {
    props.onChange(tags.filter((item: any) => item.id !== tag.id));
    focusInput(e)
  }


  const handleSelect = (tag: any): void => {
    setValue('');

    const formattedTag = props.personTheme ? getFormattedTag(tag) : tag;
    let newTags = [...tags, formattedTag];

    if(props.getSortValue) {
      newTags = newTags.sort((a: any, b: any) => a.sortValue.localeCompare(b.sortValue));
    }

    props.onChange(newTags);
  }

  const onClickAway = (e: any): void => {
    setItems([]);
  }



  const handleKey = (e: any): void => {
    if(!items?.length) {
      if(activeIdx !== -1) setActiveIdx(-1)
      return
    }


    switch(e.key) {

      case 'ArrowDown':
        e.preventDefault()
        e.stopPropagation();


        return setActiveIdx(activeIdx + 1 >= items.length ? 0 : activeIdx + 1)
      
      case 'ArrowUp':
        e.preventDefault()
        e.stopPropagation();

        return setActiveIdx(activeIdx - 1 < 0 ? items.length - 1 : activeIdx - 1)

      case 'Enter':
        return handleSelect(items[activeIdx])

      case 'Escape':
        return setItems([])

      case 'Tab':
        return setItems([])

    }
  }

  const onActiveItemOffsetChange = (offsetTop: number, offsetHeight: number) => {
    setOffsetTop(offsetTop);
    setOffsetHeight(offsetHeight);
  }


  
  /**
   * If adornment is present then we need to adjust padding in order for it 
   * to behave like label with following input
   * +9 is required to add some extra space between adornment and input content
   */
  useEffect(() => {
    if(adornmentRef && adornmentRef.offsetWidth) {
      setAdornmentPaddingOffset(adornmentRef.offsetWidth + ADORNMENT_PADDING_DEFAULT + 9)
    }
  }, [])


  useEffect(() => {
    if(value) {
      
      timeout.reset(() => {
        dispatch(props.searchAction(value))
          .then((res: any) => {
            let newItems = res
              .filter((item: any) => !getIds(tags).includes(item.id))
              .map((item: any) => props.personTheme ? item : getFormattedTag(item))

            setItems(newItems)
          })
      })
      
    } else {
      timeout.clear()
      setItems([])
    }

    
  }, [value])



  useEffect(() => {

    if(dialogRef?.current) {
      const offset = offsetTop + offsetHeight;
      const top = dialogRef.current.scrollTop;
      const bottom = dialogRef.current.scrollTop + dialogRef.current.offsetHeight;

      if(offset > bottom || offsetTop < top) {
        dialogRef.current.scrollTop = offset - dialogRef.current.offsetHeight
      }

    }

  }, [offsetTop])



  return (
    <div className={classNames(
      classes.root,
      {[classes.disabled]: props.disabled}
    )}>
      <div
        tabIndex={1}
        className={classNames(
          classes.tags,
          {
            [classes.fullheight]: showAll.value || !readonly,
            [classes.tagsEditable]: !readonly,
            [classes.spacingBottom]: props.spacingBottom,
            [classes.personThemeTags]: props.personTheme,
            [classes.onlyOne]: props.onlyOne
          }
        )}
        style={{paddingLeft: adornmentPaddingOffset}}
        onClick={focusInput}
        onKeyDown={handleKey}
      >

        {props.startAdornment && (
          <span ref={(ref: HTMLInputElement) => adornmentRef = ref} className={classes.startAdornment}>{props.startAdornment}</span>
        )}

        {tags && tags.map((tag: IAutocompleteTag) => (
          <Chip
            key={tag.id}
            size="small"
            label={tag.title}
            className={classes.tagChip}
            onDelete={readonly ? undefined : (e: any) => handleDelete(e, tag)}
            deleteIcon={readonly ? undefined : <ClearIcon className={classes.chipX} />}
          />
        ))}

        
          {(!props.onlyOne || (props.onlyOne && !tags?.length)) && (
            <div className={classes.inputWrapper}>
              <TextInput transparent initialHeight
                value={value}
                onRef={(ref: HTMLInputElement) => inputRef = ref}
                className={classes.input}
                onChange={(value: any) => !props.disabled && setValue(value)}
                placeholder={props.placeholder}
              />

              <DialogMenu
                ref={dialogRef}
                items={items}
                open={!!items.length}
                className={classes.dialog}
                onSelect={handleSelect}
                onClose={onClickAway}
                activeItemIdx={activeIdx}
                onActiveItemOffsetChange={onActiveItemOffsetChange}
                personTheme={props.personTheme}
              />
            </div>
          )}

        
      </div>
    </div>
  )
}


export default withStyles(styles)(AutocompleteTags)