import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const EmptyDraftsIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="33" height="28" viewBox="0 0 33 28">
      <g fill="currentColor">
        <path d="M16.746 16.41h.032c.232-.01.45-.076.65-.196.21-.129.383-.313.532-.609L23.52.978c.076-.203.053-.43-.065-.614-.117-.183-.315-.3-.533-.315-.539-.036-1.097.088-1.57.363L1.197 12.084c-.51.296-.905.744-1.142 1.298-.085.198-.071.424.036.611.108.187.297.312.511.34l16.054 2.072c.03.004.06.006.09.006z"/>
        <path d="M32.635 14.5L25.668 2.397c-.133-.23-.387-.361-.655-.344-.267.021-.497.193-.592.442L19.22 16.18l-.064.13c-.234.447-.583.82-1.01 1.08-.43.26-.925.398-1.44.398l-.139-.007L1.47 15.831c-.259-.034-.517.082-.664.3-.146.217-.156.497-.025.724l4.569 7.938H3.44c-.382 0-.691.308-.691.687v1.375c0 .38.31.688.691.688h26.104c.382 0 .691-.308.691-.688V25.48c0-.38-.309-.687-.69-.687h-9.212L31.63 18.25c1.308-.758 1.758-2.44 1.005-3.75z"/>
      </g>
    </SvgIcon>
  );
};