import React, { Fragment } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import TextInput from './TextInput';
import AddButton from './AddButton';
import LabelledListItem from "./LabelledItem";
import LabelledListAddButton from './LabelledListAddButton';



const styles = (theme: Theme) => createStyles({
  
})

interface IProps extends WithStyles<typeof styles> {
  onAdd: (...args: any[]) => any;
  addButtonLabel: string;
  label: string;
  required?: boolean;
  readonly?: boolean;
  errors?: any[];
  addButtonHidden?: boolean;
  children: any;
  hidden?: boolean;
}

const LabelledListItemWithAddButton = (props: IProps) => {
  return (
    <Fragment>
      <LabelledListItem
        label={props.label}
        required={props.required}
        readonly={props.readonly}
        errors={props.errors}
        hidden={props.hidden}
      >
        {props.children}
      </LabelledListItem>


      <LabelledListAddButton
        onClick={props.onAdd}
        label={props.addButtonLabel}
        hidden={props.addButtonHidden}
      />
      
    </Fragment>
  )
}


export default withStyles(styles)(LabelledListItemWithAddButton)