import React, { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportPeople } from '../../actions/people/people';
import { highlight } from '../../utils/component-utils';


interface IProps {
  people: any[];
  className?: string;
}

const PersonToExportLink = (props: IProps) => {
  const { people } = props;
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search);




  const onClick = (e: MouseEvent<HTMLSpanElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if(!people.length) return;

    dispatch(exportPeople(people))
  }




  return (
    <span className={props.className} onClick={onClick}>
      {highlight(`${people.length} ${people.length > 1 ? 'contacts' : 'contact'}`, search.search, search.highlight)}
    </span>
  )
}


export default PersonToExportLink