import * as actions from '../actions/organizations';

export const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_ORGANIZATIONS:
      return action.organizations
  
    default:
      return state
  }
}