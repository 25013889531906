import React, { Fragment, useCallback, useMemo } from "react";
import { Theme } from "../theme";
import { WithStyles, createStyles, withStyles } from "@material-ui/styles";
import Text from './Text';
import classNames from 'classnames';
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { IPersonShort, IDialogMenuItem } from "../shared/interfaces";
import { getUsername } from "../utils/user";
import { highlight } from "../utils/component-utils";

const styles = (theme: Theme) => createStyles({
  name: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.67,
    color: '#233539'
  },
  label: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.67,
    marginRight: 5,
    color: '#233539'
  },
  email: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.67,
    color: '#233539'
  },
  link: {
    textDecoration: 'unset',
    color: 'unset',
    cursor: 'pointer',
    '&:hover': {
      color: '#4192ec'
    }
  },
  lightColor: {
    color: '#657274'
  }
});

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  label: string;
  id?: number;
  name?: string;
  type?: string;
  authors?: IPersonShort[];
  externalEmails?: string[];
  highlightText?: any;
  lightColor?: boolean;
}

const AuthorLabel = (props: IProps) => {
  const { classes, authors, externalEmails, id, name } = props;



  const items = useMemo((): (IPersonShort|string)[] => {
    return [ ...(!!authors?.length ? authors : []), ...(!!externalEmails?.length ? externalEmails : []) ]
  }, [authors, externalEmails])



  const highlightSearch = (text: string): any => {
    return highlight(text, props.highlightText?.sortValue ?? props.highlightText ?? '')
  }



  const memoAuthors = useMemo((): any => {
    if(!items.length) return null;

    return items.map(((item: IPersonShort|string, idx: number) => (
      <Fragment key={(item as IPersonShort)?.id ?? item}>
        {typeof item === 'string'
          ? (
              <a href={`mailto:${item}`} className={classes.link}>
                {highlightSearch(item)}
              </a>
            )
          : (
              <a href={`/contacts/${item.id}/`} className={classes.link}>
                {highlightSearch(getUsername(item))}
              </a>
            )
        }

        {(items.length > 1 && idx !== items.length - 1) ? ', ' : ''}
      </Fragment>
    )))
  }, [items])



  if(!items.length && !(id && name)) return null;


  return (
    <div>
      <span className={classes.label}>{props.label}</span>
      <span className={classNames(
        classes.name,
        {
          [classes.email]: props.type === 'email',
          [classes.lightColor]: props.lightColor
        }
      )}>

        {id && name && (
          <a href={`/contacts/${id}/`} className={classes.link}>
            {highlightSearch(name)}
          </a>
        )}


        {memoAuthors}

      </span>
    </div>
  );
}

export default withRouter(withStyles(styles)(AuthorLabel))