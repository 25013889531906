import { currentPossibleDuplicateActions } from '../../reducers/contact-management/current-possible-duplicate';
import duplicateService from '../../services/contact-management';
import { ISort } from '../../shared/interfaces';
import { setError, setSuccess } from '../common/notifications';
import { formatDuplicates, formatSortObject, prepareFields } from '../helpers/helpers';
import { PrepareObject } from '../../shared/types';

/**
 * Actions
 */

export const SET_POSSIBLE_DUPLICATES            = 'SET_POSSIBLE_DUPLICATES';
export const DELETE_POSSIBLE_DUPLICATES         = 'DELETE_POSSIBLE_DUPLICATES';
export const CHANGE_POSSIBLE_DUPLICATES         = 'CHANGE_POSSIBLE_DUPLICATES';
export const SET_INITIATED_POSSIBLE_DUPLICATES  = 'SET_INITIATED_POSSIBLE_DUPLICATES';
export const CLEAR_POSSIBLE_DUPLICATES          = 'CLEAR_POSSIBLE_DUPLICATES';
export const SET_LOADING                        = 'POSSIBLE_DUPLICATES_SET_LOADING';
export const APPEND_POSSIBLE_DUPLICATES         = 'APPEND_POSSIBLE_DUPLICATES';



/**
 * Action creators
 */

export function setPossibleDuplicates(data: any): object {
  return {type: SET_POSSIBLE_DUPLICATES, data}
}

export function deletePossibleDuplicates(ids: number[]): object {
  return {type: DELETE_POSSIBLE_DUPLICATES, ids}
}

export function changePossibleDuplicates(idx: number, fieldName: string, value: any): object {
  return {type: CHANGE_POSSIBLE_DUPLICATES, idx, fieldName, value}
}

export function setInitiated(state: boolean): object {
  return {type: SET_INITIATED_POSSIBLE_DUPLICATES, state}
}

export function clearPossibleDuplicates(): object {
  return {type: CLEAR_POSSIBLE_DUPLICATES}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}

export function appendPossibleDuplicates(data: any): object {
  return {type: APPEND_POSSIBLE_DUPLICATES, ...data}
}




/**
 * Async action creators
 */

export const getPossibleDuplicates = (sort: ISort[], search: string) => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));


    return duplicateService.getPossibleDuplicates(body)
      .then((res: any) => {
        if(!res.results) return Promise.reject(res);
        dispatch(setPossibleDuplicates(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting possible duplicates.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const getNextPossibleDuplicates = (sort?: ISort[]) => {
  return (dispatch, getState) => {
    const nextURL = getState().contactManagement.possibleDuplicates.next;
    dispatch(setLoading(true));


    return duplicateService.getNextPossibleDuplicates(nextURL)
      .then((res: any) => {
        if(!res.results) return Promise.reject(res);
        dispatch(appendPossibleDuplicates(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting possible duplicates.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const getPossibleDuplicatesByIds = (ids: string) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return duplicateService.getPossibleDuplicatesByIds(ids)
      .then((res: any) => {

        const results = formatDuplicates(res.results);
        dispatch(setPossibleDuplicates({
          ...res,
          results
        }));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log(err, err.response)
        dispatch(setError('An error occured while getting possible duplicates.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const ignorePossibleDuplicates = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));


    return duplicateService.ignorePossibleDuplicates(ids.join(','), {ignored_as_duplicate: true})
      .then((res: any) => {
        dispatch(deletePossibleDuplicates(ids));
        dispatch(setSuccess({
          message: 'Duplicates ignored.'
        }))
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting a possible duplicate.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const mergePossibleDuplicates = (id: number, mergeIds: string, body: any) => {
  return (dispatch, getState) => {
    const { people, capitalProvider } = getState();
    const newBody = prepareFields(PrepareObject.PersonRequest, body, { people, capitalProvider }, true);
    dispatch(setLoading(true));

    return duplicateService.mergePossibleDuplicates(id, mergeIds, newBody)
      .then((res: any) => {
        dispatch(currentPossibleDuplicateActions.actionResetForm());
        dispatch(setSuccess({
          message: 'Duplicates successfully merged.'
        }))
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while merging possible duplicate.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}