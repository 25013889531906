import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { ChangeEvent, Fragment, useState, useRef, createRef, Ref, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TableColumn } from '../../../shared/interfaces';
import { Theme } from '../../../theme';
import NewContactsActions from '../../NewContactsActions';
import TableSingleRow from './TableSingleRow';
import { TableRowProps } from '@material-ui/core';
import { PAGINATION_LIMIT } from '../../../constants/index';
import { TableType } from '../../../shared/types';
import { useDispatch, useSelector } from 'react-redux';
import { setListRef } from '../../../actions/common/list';



const styles = (theme: Theme) => createStyles({
  
});



interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  rows: any[];
  count?: number;
  columns: TableColumn[];
  tableType: TableType;
  fullAccess?: boolean;
  onOpen?: (e: any, rowId: number) => any
  onInvite?: (e: any, rowId: number) => any
  onNext?: () => any;
  className?: string
  currentPage: number
  groupBy?: string
  wrapColumns?: boolean
  selectable?: boolean
}


const TableRows = (props: IProps) => {
  const { rows, count } = props;
  const dispatch = useDispatch();
  const { selected } = useSelector((state: any) => state.list[props.tableType]);
  const [newContactsEmail, setNewContactsEmail] = useState<string | undefined>(undefined);
  const [newContactsProps, setNewContactsProps] = useState<any>(undefined);





  const shouldRenderNewContactsAction = (): boolean => {
    return props.columns.some((item: TableColumn) => item.type === 'new_contacts_actions')
  }



  const hasRef = (idx: number): boolean => {
    if(!count || count === rows.length) return false;

    return count > PAGINATION_LIMIT && rows.length < count && idx === rows.length - 101
  }






  const dispatchSetListRef = (ref: Ref<TableRowProps>|null): any => {
    return dispatch(setListRef(props.tableType, ref))
  }
  



  



  useEffect(() => {
    if(count === rows.length) {
      dispatchSetListRef(null)
    }
  }, [count, rows.length])







  return (
    <>
      {rows.map((row: any, rowIdx: number) => (
        <TableSingleRow
          key={row.id}
          onRef={dispatchSetListRef}
          hasRef={hasRef(rowIdx)}
          wrapColumns={props.wrapColumns}
          selectable={props.selectable}
          isSelected={selected.includes(row.id)}
          row={row}
          idx={rowIdx}
          currentPage={props.currentPage}
          columns={props.columns}
          onOpen={props.onOpen}
          className={props.className}
          groupBy={props.groupBy}
          fullAccess={props.fullAccess}
          onInvite={props.onInvite}
          tableType={props.tableType}
          setNewContactsEmail={setNewContactsEmail}
          setNewContactsProps={setNewContactsProps}
        />
      ))}



      {shouldRenderNewContactsAction() && (
        <NewContactsActions email={newContactsEmail}
          onCancel={() => setNewContactsEmail(undefined)}
          menuProps={newContactsProps}
        />
      )}
      




    </>
  )
}


export default withStyles(styles)(withRouter(TableRows))