import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Theme } from '../theme';
import { getUsername } from '../utils/user';
import Avatar from './Avatar';
import { useSelector } from 'react-redux';
import { getCategoryLabel } from '../utils/utils';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    transition: 'all .1s ease',
    padding: '5px 0',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f7f9fa',
      padding: '5px 10px',
      '& $deleteIcon': {
        opacity: 1
      }
    },
  },
  readonly: {
    '&:hover': {
      padding: '5px 0',
      backgroundColor: 'transparent'
    }
  },
  icon: {
    fontSize: 10,
    fontWeight: 500,
    width: 25,
    height: 25,
    border: '1px solid #fff',
    position: 'relative',
    left: 0,
    '&:first-child': {
      left: 0,
      '&:last-child': {
        borderColor: 'transparent',
        left: 0
      }
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 11
  },
  title: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.67,
    color: '#233539',
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.67,
    color: '#657274',
  },
  deleteIcon: {
    fontSize: '18px',
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '10px',
    top: '7px',
    cursor: 'pointer'
  }
})

interface IProps extends WithStyles<typeof styles> {
  person: any;
  className?: any;
  onClick?: (person: any) => any;
  onDelete?: (person: any) => any;
  readonly?: boolean;
}

const PersonWithAvatar = (props: IProps) => {
  const { classes, person } = props;
  const types = useSelector((state: any) => state.types);




  const caption = useMemo((): string => {
    const categoryLabel = getCategoryLabel(person);
    return `${person.company}${!!categoryLabel ? `, ${categoryLabel}` : ''}`
  }, [person, types.categories, types.subcategories])



  const handleClick = (e: any) : void => {
    e.preventDefault();
    e.stopPropagation();

    props.onClick?.(person);
  }



  const handleDelete = (e: any) : void => {
    e.preventDefault();
    e.stopPropagation();

    props.onDelete?.(person);
  }
  




  return (
    <div
      onClick={handleClick}
      className={classNames(
        classes.root,
        {[classes.readonly]: props.readonly},
        props.className
      )}
    >
      <Avatar person={person} />
      <div className={classes.info}>
        <span className={classes.title}>{getUsername(person)}</span>
        <span className={classes.caption}>{caption}</span>
      </div>

      {!!props.onDelete
        ? <ClearIcon onClick={handleDelete} className={classes.deleteIcon} />
        : null
      }
    </div>
  )
}


export default withStyles(styles)(PersonWithAvatar)