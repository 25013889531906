import Request from '../../components/request/Request';
import { API_URL } from '../../constants/api';
import { IProject } from '../../shared/models/Project';


function getProjects(body?: string) {
  return Request.get(`${API_URL}/projects/${body || ''}`)
}

function getNextProjects(url) {
  return Request.get(url)
}

function getProjectsDetails(id: number) {
  return Request.get(`${API_URL}/projects/details/${id}/`)
}

function getProject(id: number) {
  return Request.get(`${API_URL}/projects/${id}/`)
}

function getProjectDetails(id: number) {
  return Request.get(`${API_URL}/projects/${id}/`)
}

function createProject(person: IProject) {
  return Request.post(`${API_URL}/projects/`, person)
}

function updateProject(person: IProject) {
  return Request.patch(`${API_URL}/projects/${person.id}/`, person)
}

function replaceProject(person: IProject) {
  return Request.put(`${API_URL}/projects/${person.id}/`, person)
}

function deleteProject(id: number,) {
  return Request.delete(`${API_URL}/projects/${id}/`)
}

function deleteProjects(ids: number[]) {
  return Request.delete(`${API_URL}/projects/multiple/`, { data: { ids } }, true)
}

function importCsv(formData: FormData) {
  return Request.post(`${API_URL}/projects/importCsv/`, formData, { 'Content-Type': 'multipart/form-data' })
}

function getFiles(id: number) {
  return Request.get(`${API_URL}/projects/${id}/files/`)
}

function createNote(note: any) {
  return Request.post(`${API_URL}/notes/projects/`, note)
}

function updateNote(note: any) {
  return Request.patch(`${API_URL}/notes/projects/${note.id}/`, note)
}

function deleteNote(id: number) {
  return Request.delete(`${API_URL}/notes/projects/${id}/`)
}

function getProjectsAssignables() {
  return Request.get(`${API_URL}/projects/assignable/`)
}

function exportProjectsSearch(body: string, selected: number[]) {
  const data = !!selected.length ? { selected } : null;
  return Request.downloadFile(`${API_URL}/search/projects/export/${body}`, data)
}




export default {
  getProjects,
  getNextProjects,
  getProjectsDetails,
  getProject,
  getProjectDetails,
  createProject,
  updateProject,
  replaceProject,
  deleteProject,
  deleteProjects,
  createNote,
  updateNote,
  deleteNote,
  getProjectsAssignables,
  
  importCsv,
  getFiles,
  
  exportProjectsSearch
}