import Request from "../components/request/Request";
import { API_URL } from "../constants/api";



function getImportedDuplicates(body: string = '') {
  return Request.get(`${API_URL}/people/import-duplicates/${body}`)
}


function getNextImportedDuplicates(url: string) {
  return Request.get(`${url}`)
}


function getImportedDuplicate(id: number) {
  return Request.get(`${API_URL}/people/import-duplicates/${id}/`)
}


function dismissImportedDuplicates(ids: number[]) {
  return Request.delete(`${API_URL}/people/import-duplicates/delete_multiple/`, { data: { ids } }, true)
}


function mergeImportedDuplicates(id: number, mergeId: number, body: any) {
  return Request.patch(`${API_URL}/people/import-duplicates/${id}/merge_to_person/?person=${mergeId}`, body)
}








function getPossibleDuplicates(body: string = '') {
  return Request.get(`${API_URL}/people/existing-duplicates-v2/${body}`)
}


function getNextPossibleDuplicates(url: string) {
  return Request.get(`${url}`)
}


function getPossibleDuplicatesByIds(ids: string) {
  return Request.get(`${API_URL}/people/existing-duplicates-v2/?ids=${ids}`)
}


function ignorePossibleDuplicates(ids: string, data: any) {
  return Request.post(`${API_URL}/people/existing-duplicates/ignore/?people=${ids}`, data)
}


function mergePossibleDuplicates(id: number, mergeIds: string, body: any) {
  return Request.patch(`${API_URL}/people/existing-duplicates/${id}/merge_from_people/?people=${mergeIds}`, body)
}








function getDeletedContacts(body: string = '') {
  return Request.get(`${API_URL}/people/${body ? `${body}&is_deleted=true` : '?is_deleted=true'}`)
}


function getNextDeletedContacts(url: string) {
  return Request.get(`${url}`)
}


function reactivateDeletedContacts(ids: string, data: any) {
  return Request.patch(`${API_URL}/people/update_multiple/?items=${ids}&is_deleted=true`, data)
}


function deletePermanently(body: any) {
  return Request.delete(`${API_URL}/people/permanently_delete_multiple/`, {data: {...body}}, true)
}










export default {
  getImportedDuplicate,
  getNextImportedDuplicates,
  getImportedDuplicates,
  dismissImportedDuplicates,
  mergeImportedDuplicates,


  getPossibleDuplicatesByIds,
  getPossibleDuplicates,
  getNextPossibleDuplicates,
  ignorePossibleDuplicates,
  mergePossibleDuplicates,


  getDeletedContacts,
  getNextDeletedContacts,
  reactivateDeletedContacts,
  deletePermanently
}