import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearSelectedForList } from '../../actions/common/list';
import { setLoadedPage } from '../../actions/common/loading';
import { deletePermanently, getDeletedContacts, getNextDeletedContacts, reactivateDeletedContacts } from '../../actions/contact-management/deleted-contacts';
import ConfirmDialog from '../../components/ConfirmDialog';
import { DeletedContactsIcon } from '../../components/icons/DeletedContactsIcon';
import List from '../../components/list/List';
import { ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { useOpen } from '../../shared/hooks';
import { ISort } from '../../shared/interfaces';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import { isAdmin } from '../../utils/user';
import { getCompaniesCounter } from '../../utils/utils';
import ManageableTableCard from '../common/ManageableTableCard';




const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  list: {
    boxShadow: 'none',
    margin: 0
  },
  header: {
    display: 'flex',
    fontSize: 12,
    lineHeight: 2,
    fontFamily: 'Montserrat',
    color: '#919a9c',
    padding: '0 19px',
    width: '100%',
    textTransform: 'lowercase',
    height: 0,
    opacity: 0,
    transform: 'scaleY(0)',
    transition: 'all .1s ease',
  },
  headerVisible: {
    opacity: 1,
    height: '100%',
    transform: 'scaleY(1)',
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  className?: string;  
}

const DeletedContactsPage = (props: IProps) => {
  const { classes } = props;
  const reactivateControls = useOpen();
  const permanentDeleteControls = useOpen();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const search = useSelector((state: any) => state.search.search);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const contacts = useSelector((state: any) => state.contactManagement.deletedContacts);
  const selected = useSelector((state: any) => state.list[TableType.DELETED_CONTACTS].selected);




  
  const getCounterHeader = (): any => {
    if(!selected.length) return null;

    const filteredContacts = contacts.results.filter((item: any) => selected.includes(item.id));
    const len = filteredContacts.length;
    return `${len} ${len === 1 ? 'contact' : 'contacts'} selected${getCompaniesCounter(filteredContacts)}`
  }




  const onReactivate = (e: any): void => {
    if(!selected.length) return;

    dispatchReactivateDeletedContacts(selected)
      .finally(() => {
        reactivateControls.close();
        dispatchClearSelected()
      })
  }


  const onPermanentDelete = (e: any): void => {
    if(!selected.length) return;

    dispatchDeletePermanently(selected)
      .finally(() => {
        permanentDeleteControls.close();
        dispatchClearSelected()
      })
  }


  const onNext = (): Promise<any> => {
    return dispatchNextDeletedContacts()
  }

  
  const onSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchGetDeletedContacts(sort, search)
      .then((res: any) => {
        if(!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS]) {
          dispatchOnPageLoaded(ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS)
        }
      })
  }



  const dispatchGetDeletedContacts = (sort: ISort[], search: string): any => {
    return dispatch(getDeletedContacts(sort, search))
  }


  const dispatchReactivateDeletedContacts = (ids: number[]): any => {
    return dispatch(reactivateDeletedContacts(ids))
  }


  const dispatchDeletePermanently = (ids: number[]): any => {
    return dispatch(deletePermanently(ids))
  }


  const dispatchNextDeletedContacts = (): any => {
    return dispatch(getNextDeletedContacts())
  }


  const dispatchOnPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }


  const dispatchClearSelected = (): any => {
    return dispatch(clearSelectedForList(TableType.DELETED_CONTACTS))
  }





  useEffect(() => {
    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])

  


  return (
    <ManageableTableCard
      isEmpty={contacts.initiated && !contacts.results.length && search === undefined}
      description="The following contacts have been deleted. They may be reactivated and added as active contacts again."
      actions={[
        { label: 'Reactivate', onClick: reactivateControls.open, disabled: !selected.length, popover: !selected.length && "Select a contact first" },
        { label: 'Delete Permanently', onClick: permanentDeleteControls.open, disabled: !selected.length, popover: !selected.length && "Select a contact first", isVisible: isAdmin(user) },
      ]}
      itemsEmpty={{
        icon: DeletedContactsIcon,
        title: 'No deleted contacts',
        description: ''
      }}
    >


      
      <List absolutePagination fullAccess selectable
        loading={contacts.loading}
        initialLoading={!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS]}
        className={classes.list}
        rows={contacts.results}
        count={contacts.count ?? contacts.results.length}
        tableType={TableType.DELETED_CONTACTS}
        onSort={onSort}
        onNext={onNext}
        header={(
          <div className={classNames(
            classes.header,
            {[classes.headerVisible]: !!selected.length}
          )}>
            {getCounterHeader()}
          </div>
        )}
      />
      


      <ConfirmDialog
        title="Confirm Reactivation"
        open={reactivateControls.value}
        confirmLabel="Reactivate"
        onCancel={reactivateControls.close}
        onConfirm={onReactivate}
      >
        Are you sure you want to reactivate {selected.length > 1 ? 'these contacts' : 'this contact'}?
      </ConfirmDialog>




      <ConfirmDialog
        title="Confirm Permanent Deletion"
        open={permanentDeleteControls.value}
        confirmLabel="Delete Permanently"
        onCancel={permanentDeleteControls.close}
        onConfirm={onPermanentDelete}
      >
        Are you sure you want to permanently delete {selected.length > 1 ? 'these contacts' : 'this contact'}?
      </ConfirmDialog>



    </ManageableTableCard>

  )
}


export default withStyles(styles)(
  withRouter(DeletedContactsPage)
)