import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const ContactListIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="32" height="24" viewBox="0 0 32 24">
      <defs>
        <path id="contactListPath" d="M28 10.667V9.333L24 12l-4-2.667v1.334l4 2.666 4-2.666zM29.333 4H2.667A2.675 2.675 0 0 0 0 6.667v18.666C0 26.8 1.2 28 2.667 28h26.666c1.467 0 2.654-1.2 2.654-2.667L32 6.667C32 5.2 30.8 4 29.333 4zM10.667 8c2.213 0 4 1.787 4 4s-1.787 4-4 4c-2.214 0-4-1.787-4-4s1.786-4 4-4zm8 16h-16v-1.333c0-2.667 5.333-4.134 8-4.134 2.666 0 8 1.467 8 4.134V24zm10.666-8H18.667V8h10.666v8z"/>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 -4)">
        <path d="M0 0h32v32H0z"/>
        <mask id="contactListMask" fill="#fff">
          <use xlinkHref="#contactListPath"/>
        </mask>
        <path fill="currentColor" d="M0 0h32v32H0z" mask="url(#contactListMask)"/>
      </g>
    </SvgIcon>
  );
};