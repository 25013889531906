import * as React from "react";
import {TextStyle} from "../theme";

import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles, Theme} from "@material-ui/core";
import classNames from 'classnames';

const styles = (theme: Theme) => {
  const styleMap: Record<TextStyle, any> = {
    h1: { ...theme.typography.h1 },
    h2: { ...theme.typography.h2 },
    link: { ...theme.typography.caption },
    button: { ...theme.typography.button },
    input: { ...theme.typography.body1 },
    data1: { ...theme.typography.body2 },
    data2: { ...theme.typography.body2 },
  };
  return createStyles(styleMap);
};

interface IProps extends WithStyles<typeof styles> {
  variant?: TextStyle,
  className?: string,
  children?: any
  span?: boolean
}

class Text extends React.Component<IProps> {
  render() {
    const {classes, variant, children, className, span} = this.props;
    const newClassName = classNames(variant && classes[variant], className);
    if (span) return (
      <span className={newClassName}>
        {children}
      </span>
    );
    return (
      <div className={newClassName}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Text)