import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


const getTasks = (body?: string): Promise<any> => {
  return Request.get(`${API_URL}/tasks/${body || ''}`)
}

function getTask(taskId: number) {
  return Request.get(`${API_URL}/tasks/${taskId}/`)
}

function createTask(task: any) {
  return Request.post(`${API_URL}/tasks/`, task)
}

function updateTask(taskId: number, task: any) {
  return Request.patch(`${API_URL}/tasks/${taskId}/`, task)
}

function updateTaskStatus(taskId: number, body: any) {
  return Request.patch(`${API_URL}/tasks/${taskId}/`, body)
}

function submitTask(taskId: number) {
  return Request.post(`${API_URL}/tasks/${taskId}/submit/`)
}

function approveTask(taskId: number) {
  return Request.post(`${API_URL}/tasks/${taskId}/approve/`)
}

function rejectTask(taskId: number) {
  return Request.post(`${API_URL}/tasks/${taskId}/reject/`)
}

function deleteTask(taskId: number) {
  return Request.delete(`${API_URL}/tasks/${taskId}/`)
}

function getNextTasks(url: string) {
  return Request.get(url)
}

function exportTasksSearch(body: string, selected: number[]) {
  const data = !!selected.length ? { selected } : null;
  return Request.downloadFile(`${API_URL}/search/tasks/export/${body}`, data)
}





export default {
  getTasks,
  getTask,
  createTask,
  updateTaskStatus,
  updateTask,
  submitTask,
  approveTask,
  rejectTask,
  deleteTask,
  getNextTasks,
  exportTasksSearch
}