import {createStyles, withStyles} from "@material-ui/styles";
import {Theme} from "../../../../theme";
import React, {Fragment} from 'react';
import {useDispatch} from "react-redux";
import {resetBorderFilters} from "../../../../actions/board-filters";



const styles = (theme: Theme) => createStyles({
    root: {

    },


    grey: {
        color: 'grey',

        fontFamily: 'Montserrat',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 'normal',
        textAlign: 'center',
        padding: 10,
        borderTop: '1px solid #d9e4e8'
        // borderTop: '1px solid #ddd'
    }

});


const FilterReset = (props) => {

    const dispatch = useDispatch();

    const resetFilters = () => {
        dispatch(resetBorderFilters())
    };

    return <Fragment>
         <div className={props.classes.grey} onClick={resetFilters}>
             Reset Filters
         </div>
    </Fragment>
};

export default withStyles(styles)(FilterReset)