import * as React from "react";
import { Theme, palette } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  lineSeparator: {
    '&:not(:last-child):not(:empty)': {
      paddingBottom: 15,
      marginBottom: 15,
      borderBottomColor: palette.palegrey500,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid'
    },
    '&:last-child': {
      // paddingBottom: '9px !important',
      marginBottom: '0 !important'
    }
  },
  spaceSeparator: {
    '&:not(:last-child)': {
      marginBottom: 24,
    }
  },
  noPaddingBottom: {
    paddingBottom: '0 !important',
    marginBottom: '15px !important'
  }
});

export enum SeparatorMode {
  Line,
  Space
}

interface IProps extends WithStyles<typeof styles> {
  children?: any;
  mode?: SeparatorMode;
  noBottomSpace?: boolean;
  className?: any;
}

class SeparatedList extends React.Component<IProps> {

  public static defaultProps: Partial<IProps> = {
    mode: SeparatorMode.Line
  };

  renderChild(child: any) {
    const { classes } = this.props;
    if (child == null)
      return null;
    switch(this.props.mode) {
      case SeparatorMode.Line:
        return (
          <div className={classNames(
            classes.lineSeparator,
            {
              [classes.noPaddingBottom]: this.props.noBottomSpace,
              [this.props.className]: !!this.props.className
            }
          )}>
            {child}
          </div>
        );
      case SeparatorMode.Space:
        return (
          <div className={classes.spaceSeparator}>
            {child}
          </div>
        );
    }
  }

  render() {
    const {children} = this.props;
    return (
      <div>
        {React.Children.map(children, (child)=>this.renderChild(child))}
      </div>
    );
  }
}

export default withStyles(styles)(SeparatedList)