import React, { memo } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { clickFix } from '../utils/utils';
import { highlight } from '../utils/component-utils';

const styles = (theme: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: '#233539',
    transition: 'color .1s ease',
    display: 'block',
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
})


const areEqual = (prev: IProps, next: IProps): boolean => {
  return prev.tel === next.tel && prev.search === next.search
}


interface IProps extends WithStyles<typeof styles> {
  tel: string;
  search: string;
  defaultVal: string;
}

const PhoneLink = ({ classes, tel, search, defaultVal }: IProps) => (
  <div onClick={clickFix}>
    <a href={`tel:${tel}`} className={classes.link}>
      {highlight(tel, search) || defaultVal}
    </a>
  </div>
)


export default withStyles(styles)(
  memo(PhoneLink, areEqual)
)