import * as actions from '../actions/comments';

export const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_COMMENTS:
      return action.comments
  
    case actions.ADD_COMMENT:
      return [...state, action.comment]

    case actions.UPDATE_COMMENT:
      return [
        ...state.map((comment: any) => comment.id === action.comment.id ? action.comment : comment),
      ]

    case actions.DELETE_COMMENT:
      return [...state].filter((i: any) => i.id !== action.commentId)

    case actions.CLEAR_COMMENTS:
      return initialState
    
    default:
      return state
  }
}