import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, WithStyles } from "@material-ui/core";
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { Fragment, useEffect, useState, useMemo, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListRows, setLoadingMore, setSelectedForList } from "../../actions/common/list";
import { setSearch, setHighlight } from "../../actions/search";
import { ARCHIVED_SEARCH, PAGINATION_LIMIT } from "../../constants/index";
import tableColumns from '../../constants/table-columns';
import { ISort, ManageableTableColumn, SortDirection, TableColumn } from "../../shared/interfaces";
import { InputType, TableType, TableTypeNames, TableTypeSelector } from "../../shared/types";
import { Theme } from "../../theme";
import { highlight } from "../../utils/component-utils";
import { getIds, groupBy, formatDate, formatISort } from "../../utils/utils";
import BaseCard from "../BaseCard";
import CapitalProviderLabel from "../CapitalProviderLabel";
import GroupedNote from "../GroupedNote";
import { GearIcon } from "../icons/GearIcon";
import ManageColumns from "../manage-columns/ManageColumns";
import Skeleton from "../Skeleton";
import EmptyListBody from "./EmptyListBody";
import TableHeaders from "./table-header/TableHeaders";
import TableRows from "./table-row/TableRows";
import { useOpen } from "../../shared/hooks";
import TableSumRow from "./table-sum-row/TableSumRow";





const styles = (theme: Theme) => createStyles({
  '@keyframes loadingAnimation': {
    '0%': {
      top: 0,
      opacity: 0
    },
    '10%': {
      opacity: 1
    },
    '90%': {
      opacity: 1
    },
    '100%': {
      top: '95%',
      opacity: 0
    }
  },
  root: {
    transition: 'opacity .1s ease',
    marginTop: 20,
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  disabledRoot: {
    opacity: 0.6,
    pointerEvents: 'none',
    position: 'relative',
    paddingBottom: 100, // TODO
    '&:before': {
      content: '""',
      position: 'absolute',
      background: 'linear-gradient(transparent, rgba(0, 0, 0, 0.11), transparent)',
      left: 0,
      right: 0,
      height: '20%',
      maxHeight: 200,
      zIndex: 1,
      animation: '2s infinite $loadingAnimation ease-in-out',
    }
  },
  hidden: {
    display: 'none'
  },
  rootBaseCard: {
    borderRadius: 0,
    position: 'relative',
    boxShadow: 'none',
    maxHeight: 'calc(100vh - 155px)',
    overflow: 'auto',
  },
  noShadow: {
    boxShadow: 'unset'
  },
  manageColumns: {
    cursor: 'pointer',
    minWidth: 40,
    padding: 0,
    '& > div': {
      display: 'flex'
    }
  },
  manageColumnsIcon: {
    fontSize: 15,
    color: '#90A0A7',
    transition: 'transform .1s ease',
    transform: 'scale(1) rotateZ(0)',
    '&:hover': {
      transform: 'scale(1.2) rotateZ(90deg)'
    }
  },
  tableHead: {
    borderBottom: '1px solid rgb(224, 224, 224)'
  },
  skeleton: {
    width: '100%',
    height: 48,
    marginBottom: 5,
  },
  skeletonHidden: {
    display: 'none'
  },

  groupTableRow: {
    borderLeft: '6px solid #4192ec',
    cursor: 'pointer',
    '& > td': {
      padding: '12px 40px 12px 16px',
      '&:first-child': {
        padding: '12px 20px 12px 16px',
      },
      '&:last-child': {
        position: 'relative'
      }
    }
  },
  groupTableRowCell: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.67,
    color: '#233539',
    padding: '8px 10px 8px 5px !important',
  },
  groupTableRowsCollapsed: {
    display: 'none',
    '&:hover': {
      backgroundColor: '#c6def9'
    }
  },
  groupButton: {
    position: 'absolute',
    fontSize: 24,
    top: '50%',
    left: '50%',
    color: theme.palette.primary.main,
    transform: 'translate(-50%, -50%) rotate(180deg)',
    transition: 'transform .1s ease',
  },
  groupButtonToggled: {
    transform: 'translate(-50%, -50%)'
  },
  collapseAll: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    lineHeight: 1.45,
    color: '#4192ec',
    cursor: 'pointer',
    minWidth: 70,
    textAlign: 'right',
    marginLeft: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    '& > span': {
      marginRight: 3,
      '& > strong': {
        fontWeight: 600
      }
    },
    '&:first-child:last-child': {
      marginLeft: 0,
      width: '100%'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  groupCellForCount: {
    paddingLeft: '17px !important',
    fontFamily: 'Montserrat',
    fontSize: 10,
    lineHeight: 2.1,
    color: '#90a0a7',
  },
  manageColumnsCellGrouped: {
    justifyContent: 'center'
  },
  loadingWrapper: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1
  },
  loadingWrapperHidden: {
    height: 0,
    visibility: 'hidden',
    zIndex: -1
  },
  stickyHeader: {
    '& th': {
      backgroundColor: '#fff'
    }
  },
  loadingWrapperContainer: {
    left: 0,
    right: 0,
    top: 0,
    position: 'sticky',
    width: '100%',
  }
});





export const getColumns = (columns: TableColumn[], activeColumns: ManageableTableColumn[]): TableColumn[] => {
  if (!activeColumns?.length) return [];

  return activeColumns
    .filter((item: ManageableTableColumn) => item.active)
    .map((item: ManageableTableColumn) => columns.find((column: TableColumn) => item.type === column.type))
    .filter((item: any) => item !== undefined) as TableColumn[]
}




interface IProps extends WithStyles<typeof styles> {
  rows: any[];
  count?: number;
  tableType: TableType;
  fullAccess?: boolean;
  onOpen?: (e: any, id: number) => any;
  onInvite?: (e: any, id: number) => any;
  onSort?: (sort: ISort[], search: string) => Promise<any>;
  onNext?: () => Promise<any>;
  header?: any;
  className?: any;
  groupBy?: string;
  loading?: boolean;
  initialLoading?: boolean;
  absolutePagination?: boolean;
  noManageColumns?: boolean;
  searchConditions?: string;
  emptyLabel?: string;
  groupsCollapsed?: string[];
  wrapColumns?: boolean;
  selectable?: boolean;
  search?: string;
  triggerSearch?: boolean;
  searchItem?: any;
}


const List = (props: IProps) => {
  const { classes, rows, triggerSearch, searchItem } = props;
  const manageColumnsControls = useOpen();
  const dispatch = useDispatch();
  const activeColumns = useSelector((state: any) => state.user.user.table_column_prefs?.[!!searchItem ? ARCHIVED_SEARCH : TableTypeSelector[props.tableType]] ?? []);
  const searchRedux = useSelector((state: any) => state.search.search);
  const list = useSelector((state: any) => state.list[props.tableType]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [groupsCollapsed, setGroupsCollapsed] = useState<string[]>(props.groupsCollapsed ?? [])
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [sort, setSort] = useState<ISort[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  let baseCardRef = useRef() as any;



  const search = useMemo(() => props.search ?? searchRedux, [props.search, searchRedux])
  const tablePrefsSort = useMemo(() => formatISort(activeColumns), [activeColumns])





  const onNext = (): void => {
    dispatchSetLoadingMore(true)
  }





  const toggleGroup = (e: any, group: string): void => {
    return setGroupsCollapsed(groupsCollapsed.includes(group)
      ? groupsCollapsed.filter((collapsedGroup: string) => collapsedGroup !== group)
      : [...groupsCollapsed, group]
    )
  }



  const toggleAllGroups = (e: any): void => {
    if (!props.groupBy) return
    const groupedRows = groupBy(rows, props.groupBy);
    const groups = Object.entries(groupedRows).map(([group, groupRows]) => group)

    if (groupsCollapsed.length != groups.length) return setGroupsCollapsed(groups)
    return setGroupsCollapsed([])
  }


  const getGroupFor = (groupFor: string, group: string, groupRows: any[]): string => {
    switch(groupFor) {

      case 'company':
        return group;


      case 'last_contacted':
        const lastContacted = groupRows
          .filter((row: any) => !!row.last_contacted)
          .sort((a: any, b: any) => Date.parse(b.last_contacted) - Date.parse(a.last_contacted))
          ?.[0]?.last_contacted ?? ''
        return groupsCollapsed.includes(group) ? formatDate(lastContacted) : ''


      default:
        return groupRows?.[0]?.[groupFor] ?? ''
    }
  }



  const getGroupColumn = (column: TableColumn, groupRows: any[]): any => {
    switch (column.type) {
      case 'cp_manage_state':
        const ids = getIds(groupRows);
        const state = groupRows.sort((a: any, b: any) => b[column.groupDetails]?.state - a[column.groupDetails]?.state)?.[0]?.[column.groupDetails]?.state;
        if(!state) return null;

        return (
          <CapitalProviderLabel
            label={state}
            action={(state: number) => dispatch(column.action(ids, state))}
          />
        )


      case 'cp_manage_note':
        return (
          <GroupedNote
            relations={groupRows ?? []}
            action={(relationId: number, notes: any) => dispatch(column.action(relationId, notes))}
          />
        )

        
      default:
        return null
    }
  }



  const renderTableRows = (): any => {
    if (props.groupBy) {
      const groupedRows = groupBy(rows, props.groupBy);
      const entries = Object.entries(groupedRows);


      sort.map((item: ISort) => {
        const col = columns.find((column: TableColumn) => column.type === item.type);
        if (!!col?.isGroupProp && !!col.groupDetails && !!col.search) {
          const type = Array.isArray(col.search) ? col.search[0] : col.search;


          if(!entries.every((item: any) => !!item?.[1]?.[0]?.[col.groupDetails]?.[type])) return;

          entries.sort((a: any, b: any) => {
            return item.direction === SortDirection.ASC
              ? a[1][0][col.groupDetails][type] - b[1][0][col.groupDetails][type]
              : b[1][0][col.groupDetails][type] - a[1][0][col.groupDetails][type]
          })
        }
      })



      return entries.map(([group, groupRows], idx: number) => {
        sort.map((item: ISort) => {
          const col = columns.find((column: TableColumn) => column.type === item.type);
          if (!col) return;




          if (!col.isGroupProp) {
            
            groupRows.sort((a: any, b: any) => {

              if (col?.valueType === InputType.DATE) {
                return item.direction === SortDirection.ASC
                  ? (new Date(col.value(a)).getTime() || 0) - (new Date(col.value(b)).getTime() || 0)
                  : (new Date(col.value(b)).getTime() || 0) - (new Date(col.value(a)).getTime() || 0)
              }


              return item.direction === SortDirection.ASC
                ? col.value(a).localeCompare(col.value(b))
                : col.value(b).localeCompare(col.value(a))
            })
          }











        })



        return (
          <Fragment key={group}>
            <TableRow className={classNames(classes.groupTableRow, 'group-table-row')}
              onClick={(e: any) => toggleGroup(e, group)}
            >
              <TableCell className={classes.groupCellForCount}>{idx + 1}</TableCell>

              {!!columns.length && columns.map((item: TableColumn, idx: number) => {

                const content: any[] = [];

                if (item.groupFor) {
                  const val = getGroupFor(item.groupFor, group, groupRows);
                  content.push(
                    <TableCell key={idx} className={classes.groupTableRowCell}>
                      {highlight(val, search)}
                    </TableCell>
                  )
                }


                if (item.isGroupProp) {
                  content.push(<TableCell key={idx}>{getGroupColumn(item, groupRows)}</TableCell>)
                }


                if (!item.groupFor && !item.isGroupProp) {
                  content.push(<TableCell />)
                }


                if (idx === columns.length - 1) {
                  content.push(
                    <TableCell key={`${idx}-arrow`}>
                      <ArrowDownIcon className={classNames(
                        classes.groupButton,
                        { [classes.groupButtonToggled]: groupsCollapsed.includes(group) }
                      )} />
                    </TableCell>
                  )
                }

                return content
              })}
            </TableRow>

            <TableRows
              selectable={props.selectable}
              wrapColumns={props.wrapColumns}
              groupBy={props.groupBy}
              rows={groupRows}
              count={props.count}
              columns={columns}
              tableType={props.tableType}
              fullAccess={props.fullAccess}
              onOpen={props.onOpen}
              onInvite={props.onInvite}
              onNext={onNext}
              currentPage={currentPage}
              className={classNames(
                { [classes.groupTableRowsCollapsed]: groupsCollapsed.includes(group) }
              )}
            />
          </Fragment>
        )
      })
    }

    if (!rows?.length) return null;

    return (
      <TableRows
        wrapColumns={props.wrapColumns}
        selectable={props.selectable}
        rows={rows}
        count={props.count}
        columns={columns}
        tableType={props.tableType}
        fullAccess={props.fullAccess}
        onOpen={props.onOpen}
        onInvite={props.onInvite}
        onNext={onNext}
        currentPage={currentPage}
      />
    )
  }



  const onScroll = (e: any): void => {
    if(!list.ref) return;
    
    const { innerHeight } = window;
    const { top: refTop } = list.ref.getBoundingClientRect() ?? {};
    

    if(refTop - innerHeight <= 0) {
      onNext?.()
    }
  }





  const dispatchSetLoadingMore = (state: boolean): any => {
    return dispatch(setLoadingMore(props.tableType, state))
  }



  const dispatchSetListRows = (newRows: any[]): any => {
    return dispatch(setListRows(props.tableType, newRows))
  }



  const dispatchSetHighlight = (state: boolean): any => {
    return dispatch(setHighlight(state))
  }



  const dispatchClearSearch = (): any => {
    return dispatch(setSearch(undefined))
  }



  const dispatchClearSelected = (): any => {
    return dispatch(setSelectedForList(props.tableType, []))
  }








  useEffect(() => {
    const columns = getColumns(tableColumns[TableTypeSelector[searchItem?.type ?? props.tableType]], activeColumns)
    if (columns) setColumns(columns)
  }, [activeColumns, searchItem?.columns])




  useEffect(() => {
    const newSort = !!searchItem?.sorting?.length ? searchItem.sorting : tablePrefsSort;
    if(JSON.stringify(newSort) !== JSON.stringify(sort)) {
      setSort(newSort)
    }
  }, [tablePrefsSort, sort, searchItem?.sorting])




  useEffect(() => {
    setCurrentPage(0);
    dispatchClearSelected();

    if(!sort.length || !props.onSort) return;

    if(triggerSearch === undefined || !!triggerSearch) {
      props.onSort(sort, !!search ? `limit=${PAGINATION_LIMIT}&search=${search}` : `limit=${PAGINATION_LIMIT}`);
    }

  }, [sort, search, triggerSearch])

  








  useEffect(() => {
    if(!list.loadingMore) return;

    props.onNext?.().then(() => dispatchSetLoadingMore(false))
  }, [list.loadingMore])



  useEffect(() => {
    if(selected.length >= 100 && !list.selected.length) {
      dispatchSetLoadingMore(true)
    }

    setSelected(list.selected)
  }, [list.selected, selected])



  useEffect(() => {
    if(!props.loading) {
      dispatchSetHighlight(true)
    }
  }, [props.loading])






  useEffect(() => {
    return () => {
      dispatchClearSearch();
      dispatchClearSelected();
    }
  }, [])




  useEffect(() => {
    dispatchSetListRows(getIds(rows))
  }, [rows])



  useEffect(() => {
    baseCardRef?.current?.addEventListener('scroll', onScroll)
    return () => baseCardRef?.current?.removeEventListener('scroll', onScroll)
  }, [list.ref, baseCardRef])




  
  return (
    <div>


      <div className={classNames(
        classes.root,
        classes.noShadow,
        {
          [classes.skeletonHidden]: !props.initialLoading,
          [props.className]: props.className
        },
      )}>
        <Skeleton className={classes.skeleton} count={10} />
      </div>




      <div className={classNames(
        {[classes.hidden]: props.initialLoading}
      )}>



        {props.header && (
          <div className={classNames(classes.header, 'hide-on-print')}>

            {props.header}

            {props.groupBy && (
              <span className={classes.collapseAll} onClick={toggleAllGroups}>
                {groupsCollapsed.length != Object.entries(groupBy(rows, props.groupBy)).length ? 'Collapse All' : 'Expand All'}
              </span>
            )}
          </div>
        )}





        <div className={classNames(
          classes.root,
          'print-version',
          {
            [classes.disabledRoot]: props.loading && !!rows.length,
            [props.className]: props.className,
          },
        )}>

          <BaseCard ref={baseCardRef} extraClass={classNames(classes.rootBaseCard, 'root-base-card')}>

            <Table stickyHeader className={classNames(classes.stickyHeader, "print-version")}>
              <TableHead className={classes.tableHead}>
                <TableRow>

                  
                  <TableHeaders
                    searchItem={searchItem}
                    selectable={props.selectable}
                    columns={columns}
                    tableType={props.tableType}
                    noSort={!props.onSort && !props.groupBy}
                    fullAccess={props.fullAccess}
                  />


                



                  {props.fullAccess && !props.noManageColumns && (
                    <TableCell className={classNames(classes.manageColumns, 'manage-columns')}>
                      <div onClick={manageColumnsControls.open}
                        className={classNames(
                          { [classes.manageColumnsCellGrouped]: !!props.groupBy }
                        )}
                      >
                        <GearIcon className={classes.manageColumnsIcon} />
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>



              {!!rows.length && (
                <TableBody>
                  <TableSumRow
                    isTop
                    type={props.tableType}
                    columns={columns}
                    rows={rows}
                    hidden={rows.length <= PAGINATION_LIMIT}
                  />


                  {renderTableRows()}


                  <TableSumRow
                    type={props.tableType}
                    columns={columns}
                    rows={rows}
                  />
                </TableBody>
              )}


            </Table>




            <EmptyListBody hidden={!!rows.length || list.loadingMore}
              label={TableTypeNames[props.tableType]}
              emptyLabel={props.emptyLabel}
            />



            <div className={classes.loadingWrapperContainer}>
              <div className={classNames(
                classes.loadingWrapper,
                {[classes.loadingWrapperHidden]: !list.loadingMore}
              )}>
                <CircularProgress size={40} />
              </div>
            </div>
            

          </BaseCard>



          
          {/* <TablePagination
            rows={rows}
            count={props.count}
            page={currentPage}
            onPageChange={setCurrentPage}
            onNext={props.onNext}
            loading={loading}
            absolute={props.absolutePagination}
          /> */}




          {props.fullAccess && !props.noManageColumns && (
            <ManageColumns
              tableType={props.tableType}
              opened={manageColumnsControls.value}
              onClose={manageColumnsControls.close}
            />
          )}

        </div>
      </div>
      


      
    </div>
  );
}



export default withStyles(styles)(List)





  