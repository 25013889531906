import React, { useEffect } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    background: '#fff',
    display: 'flex',
    padding: 30,
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#657274',
  }
})

interface IProps extends WithStyles<typeof styles> {
  
}

const EmailSuccessPage = (props: IProps) => {
  const { classes } = props;


  useEffect(() => {
    setTimeout(() => {
      window.close()
    }, 3000)
  })


  return (
    <div className={classes.root}>
      Email was successfully sent. Redirecting...
    </div>
  )
}


export default withStyles(styles)(EmailSuccessPage)