import { createRef, MouseEvent, Ref, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePersonField } from "../actions/people/people";
import { IAnchor, IShowAll } from "./interfaces";
import { changeProjectField } from "../actions/projects/projects";




export const useShowAll = () : IShowAll => {
  const [showAll, setShowAll] = useState<boolean>(false);

  return {
    value: showAll,
    toggle: () => setShowAll(!showAll)
  }
}




export const useAnchor = () : IAnchor => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  return {
    value: anchorEl,
    set: (el: MouseEvent<any>) => setAnchorEl(el.currentTarget),
    toggle: (el: MouseEvent<any>) => setAnchorEl(!!anchorEl ? null : el.currentTarget),
    close: () => setAnchorEl(null),
  }
}




export const useTimeout = (time: number) : any => {
  const [searchTimeout, setSearchTimeout] = useState<any|null>(null);
  
  return {
    value: searchTimeout,
    clear: () => {
      clearTimeout(searchTimeout);
      setSearchTimeout(null);
    },
    reset: (callback: Function) => {
      clearTimeout(searchTimeout);
      const int = setTimeout(() => {
        callback();
        clearTimeout(searchTimeout);
      }, time)
      setSearchTimeout(int)
    }
  }
}




export const useOpen = (initOpened: boolean = false) : any => {
  const [opened, setOpened] = useState<boolean>(initOpened);

  return {
    value: opened,
    open: () => setOpened(true),
    close: () => setOpened(false),
    toggle: (state?: boolean) => setOpened(typeof state === 'boolean' ? state : !opened)
  }
}




export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
      isMobile: isClient ? window.innerWidth <= 480 : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect((): any => {
    if (!isClient) {
      return false;
    }
    
    const handleResize = () => {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, []);

  return windowSize;
}


export const useRefs = (length: number): Ref<any>[] => {
  const [refs, setRefs] = useState<Ref<any>[]>([]);


  useEffect(() => {
    const newRefs = Array(length).fill(0).map((_, i) => refs[i] || createRef())
    setRefs(newRefs)
  }, [length])

  return refs
}