import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import Axios from "axios";

export const processResponse = (res: AxiosResponse) => {
  if(axios.isCancel(res)) {
    throw new Axios.Cancel('Request was cancelled');
  }

  return res.data
};

export function baseHeaders(extraHeaders?: object): AxiosRequestConfig {
  const state = JSON.parse(localStorage.getItem('state') || '{}')
  const token = (state['user'] || {})['token'] || ''
  const cancelToken = axios.CancelToken.source();

  return {
    cancelToken: cancelToken.token,
    headers: {
      ...extraHeaders,
      'Authorization': `Token ${token}`
    }
  }
}

export function getErrorCode(error: any): number {
  return (error && error.response && error.response.status) || 0;
}