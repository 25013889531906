import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearSelectedForList } from '../../actions/common/list';
import { setLoadedPage } from '../../actions/common/loading';
import { clearPossibleDuplicates, getNextPossibleDuplicates, getPossibleDuplicates, ignorePossibleDuplicates } from '../../actions/contact-management/possible-duplicates';
import ConfirmDialog from '../../components/ConfirmDialog';
import { PossibleDuplicatesIcon } from '../../components/icons/PossibleDuplicatesIcon';
import List from '../../components/list/List';
import { ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES, ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATE_MERGE } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { useOpen } from '../../shared/hooks';
import { ISort } from '../../shared/interfaces';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import ManageableTableCard from '../common/ManageableTableCard';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  list: {
    boxShadow: 'none',
    margin: 0
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  className?: string;  
}

const PossibleDuplicatesPage = (props: IProps) => {
  const { classes, history } = props;
  const ignoreControls = useOpen();
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search.search);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const duplicates = useSelector((state: any) => state.contactManagement.possibleDuplicates);
  const selected = useSelector((state: any) => state.list[TableType.POSSIBLE_DUPLICATES].selected);



  const onMerge = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    if(!selected.length) return;
    dispatchClearPossibleDuplicates();
    history.push(`${ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATE_MERGE}?items=${[...selected]}`)
  }

  
  const onConfirmIgnore = (): void => {
    if(!selected.length) return;

    dispatchIgnoreDuplicates(selected)
      .then(() => {
        ignoreControls.close();
        dispatchClearSelected()
      })
  }


  

  const onSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchGetPossibleDuplicates(sort, search)
      .then(() => {
        if(!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES]) {
          dispatchOnPageLoaded(ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES)
        }
      })
  }



  const onNext = (): Promise<any> => {
    return dispatchGetNextPossibleDuplicates()
  }


  const dispatchGetPossibleDuplicates = (sort: ISort[], search: string): any => {
    return dispatch(getPossibleDuplicates(sort, search))
  }


  const dispatchIgnoreDuplicates = (ids: number[]): any => {
    return dispatch(ignorePossibleDuplicates(ids))
  }


  const dispatchClearPossibleDuplicates = (): any => {
    return dispatch(clearPossibleDuplicates())
  }


  const dispatchOnPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }


  const dispatchGetNextPossibleDuplicates = (): any => {
    return dispatch(getNextPossibleDuplicates())
  }


  const dispatchClearSelected = (): any => {
    return dispatch(clearSelectedForList(TableType.POSSIBLE_DUPLICATES))
  }




  const renderDescription = (
    <span>Possible Duplicates are active contacts on FinTech8 but either their full name or alternate email address matches another contact on FinTech8.<br />Please process them by using Merge or Ignore function.</span>
  )
  
  


  useEffect(() => {
    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])

  



  return (
    <ManageableTableCard
      isEmpty={duplicates.initiated && !duplicates.results.length && search === undefined}
      description={renderDescription}
      actions={[
        { label: 'Merge', onClick: onMerge, disabled: !selected.length || selected.length < 2, popover: !selected.length && "Select a duplicate first" },
        { label: 'Ignore', onClick: ignoreControls.open, disabled: !selected.length, popover: !selected.length && "Select a duplicate first" }
      ]}
      itemsEmpty={{
        icon: PossibleDuplicatesIcon,
        title: 'No possible duplicates',
        description: 'Manage duplicate contacts that may already exist on FinTech8.'
      }}
    >


      
      <List absolutePagination fullAccess selectable
        loading={duplicates.loading}
        initialLoading={!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES]}
        className={classes.list}
        rows={duplicates.results}
        count={duplicates.count ?? duplicates.results.length}
        tableType={TableType.POSSIBLE_DUPLICATES}
        onSort={onSort}
        onNext={onNext}
      />
      


      <ConfirmDialog
        title="Confirm Ignore"
        open={ignoreControls.value}
        confirmLabel="Ignore"
        onCancel={ignoreControls.close}
        onConfirm={onConfirmIgnore}
      >
        Are you sure you want to ignore {selected.length > 1 ? 'these duplicates' : 'this duplicate'}?
      </ConfirmDialog>


    </ManageableTableCard>

  )
}


export default withStyles(styles)(
  withRouter(PossibleDuplicatesPage)
)