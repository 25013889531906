import React, { useMemo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { TableColumn } from '../../../shared/interfaces';
import { TableCell } from '@material-ui/core';
import { TABLE_SUM_ROWS } from '../../../shared/types';
import { formatNumber } from '../../../utils/utils';

const styles = (theme: Theme) => createStyles({
  root: {
    height: 50,
    padding: '0 0 0 5px',
    fontSize: 12,
    fontWeight: 700
  }
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  column: TableColumn;
  rows: any[];
  lastCol?: boolean;
  className?: string;
}

const TableSumRowColumn = (props: IProps) => {
  const { classes, idx, column, rows } = props;



  const sum = useMemo((): string => {
    if(!TABLE_SUM_ROWS.includes(column.type)) return '';

    const newSum = rows
      .map((row: any) => column.numericValue?.(row))
      .reduce((a: any, b: any) => a + b, 0)

    return !!newSum ? `£${formatNumber(newSum)}` : '--'
  }, [rows, idx, column])

  


  return useMemo(() => (
    <TableCell
      className={classNames(classes.root, props.className)}
      colSpan={!!props.lastCol ? 2 : 1}
    >
      {sum}
    </TableCell>
  ), [sum, idx, props.lastCol])
}


export default withStyles(styles)(TableSumRowColumn)