import React, { Fragment } from 'react';
import { Theme } from '../../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import Button, { ButtonSize } from '../../../Button';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  buttons: {
    display: 'flex',
    backgroundColor: '#fff',
    padding: '11px 10px',
    position: 'relative',
    justifyContent: 'space-between',
    '&:first-child': {
      flex: 3,
      justifyContent: 'flex-start',
      '&:last-child': {
        justifyContent: 'space-between',
      }
    },
    '&:last-child': {
      flex: 2,
      justifyContent: 'flex-end',
    }
  },
  button: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    textAlign: 'center',
    color: theme.palette.primary.main,
    padding: '6px 15px',
    minWidth: 75,
    height: 'initial',
    border: `1px solid transparent`,
    marginRight: 10,
    '&:first-child': {
      '&:last-child': {
        margin: 0
      } 
    },
    '&:last-child': {
      margin: 0
    }
  },
  primary: {
    color: '#fff'
  },
  secondary: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  success: {
    color: '#15908a',
    border: `1px solid #15908a`,
    width: 120
  },
  error: {
    color: '#d52023',
    border: `1px solid #d52023`,
    width: 120
  },
  wide: {
    width: 250
  }
})

interface IProps extends WithStyles<typeof styles> {
  buttons: any;
}

const TaskControlButtons = (props: IProps) => {
  const { classes, buttons } = props;




  const getClassName = (type: string) : string|undefined => {
    switch(type) {
      case 'primary': return classes.primary;
      case 'secondary': return classes.secondary;
      case 'success': return classes.success;
      case 'error': return classes.error;
    }
  }



  if(!buttons.length) return null;




  return (
    <div className={classes.buttons}>
      {props.buttons.map((button: any) => (
        <Button
          key={button.type + button.label}
          size={ButtonSize.Tiny}
          primary={button.type === 'primary' || button.type === 'primaryBig'}
          className={classNames(
            classes.button,
            getClassName(button.type),
            {[classes.wide]: button.wide}
          )}
          onClick={button.onClick}
        >
          {button.label}
        </Button>
      ))}
    </div>
  )
}


export default withStyles(styles)(TaskControlButtons)