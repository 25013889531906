import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { createTemplate } from '../../actions/email-templates';
import { clearSearch } from '../../actions/search';
import Button from '../../components/Button';
import { EmailIcon } from '../../components/icons/EmailIcon';
import { TemplatesIcon } from '../../components/icons/TemplatesIcon';
import { ROUTE_EMAIL_MANAGEMENT, ROUTE_EMAIL_MANAGEMENT_DRAFTS, ROUTE_EMAIL_MANAGEMENT_TEMPLATE, ROUTE_EMAIL_MANAGEMENT_TEMPLATES, ROUTE_DASHBOARD } from '../../constants/routes';
import { Theme } from '../../theme';
import { isAdmin, isUser } from '../../utils/user';
import { isRoute } from '../../utils/utils';
import ManagementContainer from '../common/ManagementContainer';
import EmailDraftsPage from './EmailDraftsPage';
import EmailTemplatePage from './EmailTemplatePage';
import EmailTemplatesPage from './EmailTemplatesPage';
import NewContactsPage from './NewContactsPage';


const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  composeEmailButton: {
    fontWeight: 400,
    width: 177,
    height: 'initial',
    padding: '8px 12px'

  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  content: {
    width: '100%',
    marginLeft: 232
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  
}

const EmailManagementContainer = (props: IProps) => {
  const { classes, history } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);



  const onInit = (): void => {
    if(!isAdmin(user) && !isUser(user)) {
      return history.push(ROUTE_DASHBOARD)
    }

    if(isRoute(ROUTE_EMAIL_MANAGEMENT, true)) {
      return history.push(ROUTE_EMAIL_MANAGEMENT_DRAFTS)
    }
  }


  const onCreateNewTemplate = (): void => {
    dispatchCreateTemplate()
      .then((res: any) => {
        history.push(`/email-management/templates/${res.id}/`)
      })
  }



  const dispatchCreateTemplate = (): any => {
    return dispatch(createTemplate())
  }





  useEffect(() => {
    onInit()

    return () => {
      dispatch(clearSearch())
    }
  }, [])





  const routes = [
    { id: 0, label: 'Email Drafts', icon: EmailIcon,      path: ROUTE_EMAIL_MANAGEMENT_DRAFTS },
    { id: 1, label: 'Templates',    icon: TemplatesIcon,  path: ROUTE_EMAIL_MANAGEMENT_TEMPLATES }
  ]



  return (
    <ManagementContainer title="Email Management"
      actions={isRoute(ROUTE_EMAIL_MANAGEMENT_TEMPLATES, true) && (
        <Button primary onClick={onCreateNewTemplate} className={classes.composeEmailButton}>
          Create New Template
        </Button>
      )}
      tabs={routes}
    >


      <Switch>
        <Route path={ROUTE_EMAIL_MANAGEMENT_DRAFTS} render={() => <EmailDraftsPage className={classes.content} />} />
        <Route path={ROUTE_EMAIL_MANAGEMENT_TEMPLATE} render={(props: any) => (
          Number.isInteger(+props.match?.params?.id)
            ? <EmailTemplatePage className={classes.content} id={+props.match.params.id} />
            : <Redirect to={ROUTE_EMAIL_MANAGEMENT_TEMPLATES} />
        )} />

        <Route path={ROUTE_EMAIL_MANAGEMENT_TEMPLATES} render={() => <EmailTemplatesPage className={classes.content} />} />
      </Switch>


    </ManagementContainer>
  )
}


export default withStyles(styles)(
  withRouter(EmailManagementContainer)
)