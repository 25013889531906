import { Popover } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useAnchor } from '../../../shared/hooks';
import { TaskAction, TaskType, TaskTypeNames } from '../../../shared/types';
import { Theme } from '../../../theme';
import { formatDateForLabel } from '../../../utils/utils';
import { CheckIcon } from '../../icons/CheckIcon';
import { NotificationIcon } from '../../icons/NotificationIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.64,
    color: '#657274',
    backgroundColor: '#f3f5f6',
    padding: '3px 12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    maxWidth: 100,
    boxSizing: 'border-box',
    '& > span': {
      marginLeft: 5
    }
  },
  active: {

  },
  submitted: {
    color: '#4caf50',
    backgroundColor: '#e6f1e8',
    transition: 'box-shadow .1s ease',
    '&:hover, &$active': {
      boxShadow: '0 0 5px 0 #4caf50',
    }
  },
  iconSubmitted: {
    color: '#4caf50',
  },
  approved: {
    color: '#15908a',
    backgroundColor: '#e0edee'
  },
  iconApproved: {
    color: '#15908a',
  },
  todo: {
    boxSizing: 'border-box',
  },
  todoSoon: {
    color: '#dd5a1b',
    backgroundColor: 'rgba(221, 90, 27, 0.05)',
    '& > $iconTodo': {
      color: '#dd5a1b'
    }
  },
  todoExpired: {
    color: '#d52023',
    backgroundColor: 'rgba(213, 32, 35, 0.05)',
    '& > $iconTodo': {
      color: '#d52023'
    }
  },
  iconTodo: {
    fontSize: '14px !important'
  },
  icon: {
    fontSize: 12,
  },
  popover: {

  },
  popoverPaper: {
    display: 'flex',
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    zIndex: 1000,
    overflow: 'unset',
    '&:after': {
      width: 0,
      height: 0,
      content: '""',
      borderTop: '6px solid #fff',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      bottom: 0,
      left: '50%',
      top: 'calc(100% - 1px)',
      position: 'absolute',
      transform: 'translate(-50%, 0)',
    },
    '&:before': {
      content: '""',
      height: 10,
      top: '50%',
      left: '50%',
      width: 1,
      position: 'absolute',
      transform: 'translate(calc(-50% + 1px), -50%)',
      backgroundColor: '#dae4e8',
    }
  },
  option: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.82,
    color: theme.palette.primary.main,
    padding: '7px 15px',
    cursor: 'pointer',
    transition: 'background .1s ease',
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '&:hover': {
      background: 'linear-gradient(180deg, rgba(232,242,253,1) 0%, rgba(255,255,255,1) 100%)',
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  type: TaskType;
  dueDate?: string;
  onApproveOrReject?: (action: TaskAction) => any;
}

const TaskStatusLabel = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();

  const getDueDate = () : string => {
    return props.dueDate
      ? formatDateForLabel(props.dueDate)
      : TaskTypeNames[TaskType.ToDo]
  }

  const isEndingSoon = () : boolean => {
    const now = moment();
    const due = moment(props.dueDate);
    return due.diff(now, 'hours') <= 24
  }

  const isExpired = () : boolean => {
    const now = moment();
    const due = moment(props.dueDate);
    return due < now
  }

  const handleSetAnchor = (e: any): void => {
    if(!props.onApproveOrReject) return;
    e.preventDefault();
    e.stopPropagation();
    
    anchor.set(e)
  }

  const onPopoverClose = (e: any): void => {
    if(!props.onApproveOrReject) return;
    e.preventDefault();
    e.stopPropagation();

    anchor.close();
  }

  const approveTask = (e: any): void => {
    if(!props.onApproveOrReject) return;
    e.preventDefault();
    e.stopPropagation();

    props.onApproveOrReject(TaskAction.Approve);
    onPopoverClose(e)
  }

  const rejectTask = (e: any): void => {
    if(!props.onApproveOrReject) return;
    e.preventDefault();
    e.stopPropagation();

    props.onApproveOrReject(TaskAction.Reject);
    onPopoverClose(e)
  }

  const renderPopover = (): any => {
    if(!props.onApproveOrReject) return null;

    return (
      <Popover
        disableRestoreFocus
        className={classes.popover}
        classes={{ paper: classes.popoverPaper }}
        open={!!anchor.value}
        anchorEl={anchor.value}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onPopoverClose}
      >
        <span className={classes.option} onClick={approveTask}>Approve</span>
        <span className={classes.option} onClick={rejectTask}>Reject</span>
      </Popover>
    )
  }


  switch(props.type) {
    case TaskType.Submitted:
      return (
        <Fragment>
          <div
            className={classNames(
              classes.root,
              classes.submitted,
              {[classes.active]: !!anchor.value}
            )}
            onClick={handleSetAnchor}
          >
            <CheckIcon className={classNames(classes.icon, classes.iconSubmitted)} />
            <span>{TaskTypeNames[TaskType.Submitted]}</span>
          </div>

          {renderPopover()}
        </Fragment>
      )
    case TaskType.Approved:
      return (
        <div className={classNames(classes.root, classes.approved)}>
          <CheckIcon className={classNames(classes.icon, classes.iconApproved)} />
          <CheckIcon className={classNames(classes.icon, classes.iconApproved)} />
          <span>{TaskTypeNames[TaskType.Approved]}</span>
        </div>
      )
    case TaskType.ToDo:
      return (
        <div className={classNames(
          classes.root,
          classes.todo,
          {
            [classes.todoSoon]: isEndingSoon(),
            [classes.todoExpired]: isExpired(),
          }
        )}>
          <NotificationIcon className={classNames(classes.icon, classes.iconTodo)} />
          <span>{getDueDate()}</span>
        </div>
      )
  }

  return null
}


export default withStyles(styles)(TaskStatusLabel)