import React from 'react';
import AutocompleteTags from '../../../AutocompleteTags';
import { IDialogMenuItem } from '../../../../shared/interfaces';
import { Theme } from '../../../../theme';
import { withStyles, createStyles } from '@material-ui/styles';


const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  ccWrapper: {
    position: 'absolute',
    top: '50%',
    right: 12,
    transform: 'translate(0, calc(-50% - 5px))',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 2,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      marginRight: 14,
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: -8,
        transform: 'translate(0, -50%)',
        height: 20,
        width: 1,
        backgroundColor: theme.palette.primary.main
      },
      '&:last-child': {
        marginRight: 0,
        '&:after': {
          content: 'unset'
        }
      },
      '&:hover': {
        fontWeight: 500
      }
    }
  },
  
})

const InputAutocompleteTagsField = (props: any) => {

  const {classes, value: tags, actions, shouldRender, onChange, ...otherProps} = props;

  if(shouldRender !== undefined && !shouldRender) return null;

  const onRemove = (oldTag: any) => {
    const newTags = tags.filter((tag: any) => tag.id !== oldTag.id);
    onChange(newTags)
  };

  const onAdd = (newTag: IDialogMenuItem): void => {
    return onChange(tags ? [...tags, newTag] : [newTag])
  }

  const onNewTags = (newTags: IDialogMenuItem[]): void => {
    return onChange(newTags)
  }

  return (
    <div className={classes.root}>

      <AutocompleteTags
        tags={tags || []}
        onChange={onNewTags}
        onAdd={onAdd}
        onDelete={onRemove}
        {...otherProps}
      />

      {actions && (
        <div className={classes.ccWrapper}>
          {actions.map((action: any) => (
            <span tabIndex={0} key={action.id} onClick={action.action}>{action.label}</span>
          ))}
        </div>
      )}
    </div>
  )
}


export default withStyles(styles)(InputAutocompleteTagsField)