import * as actions from '../../actions/common/notifications';

export const notificationsInitialState = {};

export default function(state = notificationsInitialState, action) {
  switch (action.type) {

    case actions.SET_ERROR:
    case actions.SET_SUCCESS:
      return {type: action.notificationType, ...action.data}
    
    case actions.CLEAR_NOTIFICATION:
      return {}
  
    default:
      return state
  }
}