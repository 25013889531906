import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function getColumns(projectId: number) {
  return Request.get(`${API_URL}/projects/columns/?project=${projectId}`)
}

function changeColumnTitle(id: number, body: any) {
  return Request.patch(`${API_URL}/projects/columns/${id}/`, body)
}

function toggleHiddenState(body: any) {
  return Request.post(`${API_URL}/projects/columns/change_columns_prefs/`, body)
}


export default {
  getColumns,
  changeColumnTitle,
  toggleHiddenState
}