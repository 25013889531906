import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';
import { Draggable } from "react-beautiful-dnd";
import { ManageableTableColumn } from '../../shared/interfaces';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import ManageColumnsCheckbox from './ManageColumnsCheckbox';
import ManageColumnsText from './ManageColumnsText';



const styles = (theme: Theme) => createStyles({
  rowDragging: {
    backgroundColor: theme.palette.primary.light,
  }
})




interface IProps extends WithStyles<typeof styles> {
  column: ManageableTableColumn;
  tableType: TableType;
  className?: string;
  onToggle: (column: ManageableTableColumn, checked: boolean) => any;
}



const ManageColumnsDraggable = (props: IProps) => {
  const { classes, column } = props;



  const onToggle = useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    props.onToggle(column, checked)
  }, [column, props.onToggle])
  





  return (
    <Draggable
      index={column.order}
      draggableId={column.type}
      isDragDisabled={!column.active}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames(
            {[classes.rowDragging]: snapshot.isDragging},
            props.className,
            provided.draggableProps.style
          )}
        >

          <ManageColumnsCheckbox checked={column.active} onToggle={onToggle}/>
          <ManageColumnsText label={column.label} />

        </div>
      )}
    </Draggable>
  )
}


export default withStyles(styles)(
  ManageColumnsDraggable
)