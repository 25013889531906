import * as React from "react";
import { Theme } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import Button, {ButtonSize} from "./Button";
import AddIcon from '@material-ui/icons/Add';
import {ClickFunction} from "../utils/typescriptUtils";

const styles = (theme: Theme) => createStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 4,
    minWidth: 0,
    backgroundColor: 'transparent',
    textAlign: 'left',
    height: 'initial',
    padding: '2px 0'
  },
  icon: {
    fontSize: 20
  },
  text: {
    marginLeft: 3
  }
});

interface IProps extends WithStyles<typeof styles> {
  onClick: ClickFunction,
  fullWidth?: boolean,
  text: string
}

class AddButton extends React.Component<IProps> {

  public static defaultProps: Partial<IProps> = {
    text: 'Add'
  };

  render() {
    const {classes, onClick, fullWidth, text} = this.props;
    return (
      <Button
        primary={false}
        size={ButtonSize.Tiny}
        onClick={onClick}
        fullWidth={fullWidth}
        classes={{root: classes.root}}
      >
        <AddIcon className={classes.icon}/>
        <div className={classes.text}>
          {text}
        </div>
      </Button>
    );
  }
}

export default withStyles(styles)(AddButton)