import * as React from "react";
import { Theme } from "../../theme";
import { createStyles, withStyles } from "@material-ui/styles";
import { WithStyles, Grid } from "@material-ui/core";
import { PaperPlane } from "../icons/PaperPlane";

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '21px 38px 21px 38px',
    height: 218,
    justifyContent: 'space-between',
    borderRadius: '4px',
    boxShadow: '0 16px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    fontFamily: 'Montserrat',
    color: '#657274',
    fontSize: 14,
    lineHeight: 1.43,
  },
  header: {
    fontSize: 16,
    color: '#233539',
    fontWeight: 500,
  },
  planeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  planeCircle: {
    width: 100,
    height: 100,
    backgroundColor: '#e8f2fd',
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: 50,
  },
  emailAddress: {
    color: '#4192ec'
  }
});

interface IProps extends WithStyles<typeof styles> {
  email: string
}

class EmailSent extends React.Component<IProps> {
  formatEmail(email: String) {
    const { classes } = this.props
    return (
      <span className={classes.emailAddress}>{email}</span>
    )
  }

  render() {
    const { classes, email } = this.props;

    return (
      <div className={classes.root}>
        <span className={classes.header}>Email sent</span>
        <div className={classes.planeContainer}>
          <div className={classes.planeCircle}>
            <PaperPlane />
          </div>
        </div>
        <span>Check your {this.formatEmail(email)} inbox for instructions from us on how to reset your password.</span>
      </div>
    );
  }
}

export default withStyles(styles)(EmailSent)