import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const TaskIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <path id="a" d="M19 2h-4.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V4h2v3h10V4h2v16z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <g>
          <path d="M0 0h24v24H0z"/>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a"/>
          </mask>
          <path fill="currentColor" d="M0 0h24v24H0z" mask="url(#b)"/>
        </g>
        <path fill="currentColor" fillRule="nonzero" d="M15.812 10l-4.673 4.64-1.95-1.895L8 13.925 11.139 17 17 11.18z"/>
      </g>
    </SvgIcon>
  );
};