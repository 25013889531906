import React from 'react';
import Select from '../../Select';


interface IProps {
  onChange: (value: any) => any;
  [otherProps: string]: any;
}

const BulkEditSelect = (props: IProps) => {
  const { onChange, ...otherProps } = props;

  return (
    <Select onSelect={onChange} {...({...otherProps} as any)} />
  )
}


export default BulkEditSelect