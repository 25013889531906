import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const OutlookIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="64" height="64" viewBox="0 0 64 64">
    <defs>
      <path id="outlook" d="M0 .034h29.965v30.042H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="32" cy="32" r="32" fill="currentColor" fillRule="nonzero"/>
      <g transform="translate(17 17)">
				<mask id="outlookMask" fill="#fff">
					<use xlinkHref="#outlook"/>
        </mask>
        <path fill="#FFF" d="M0 3.068v23.567l17.587 3.446V.034L0 3.068M18.678 14.342v7.904h9.946s1.455-.202 1.334-2.112l.007-10.326-7.284 4.929s-2.062 1.665-4.003-.395" mask="url(#outlookMask)"/>
      </g>
      <path fill="#FFF" d="M35.678 23.133h10.027s1.18-.081 1.18 1.204l-9.064 6.037-2.143-1.366v-5.875"/>
      <path fill="currentColor" d="M25.79 34.948c-1.064 0-1.926-1.414-1.926-3.158s.862-3.158 1.925-3.158c1.064 0 1.926 1.414 1.926 3.158s-.862 3.158-1.926 3.158zm.046-8.48c-2.211 0-4.004 2.387-4.004 5.33 0 2.944 1.793 5.33 4.004 5.33 2.212 0 4.004-2.386 4.004-5.33 0-2.943-1.792-5.33-4.004-5.33z"/>
    </g>

    </SvgIcon>
  );
};