import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearPeopleLocal, searchPeopleLocal } from '../actions/people/people';
import { useTimeout } from '../shared/hooks';
import { ButtonWithMenuProps, SelectItem } from '../shared/interfaces';
import { AutocompleteType, SELECT_ITEM_NONE_ID } from '../shared/types';
import { Theme } from '../theme';
import Autocomplete from './Autocomplete';
import ConfirmDialog from './ConfirmDialog';
import Menu from './Menu';



const styles = (theme: Theme) => createStyles({
  confirmDialog: {
    minWidth: 350
  },
  confirmDialogDescription: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.71,
    color: '#657274',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  autocompleteWrapper: {
    display: 'flex',
    width: '100%'
  },
  autocomplete: {
    width: '100%',
  },
})


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  email?: string;
  onCancel: () => any;
  menuProps: ButtonWithMenuProps;
}

const NewContactsActions = (props: IProps) => {
  const { classes, history, email, menuProps } = props;
  const timeout = useTimeout(700);
  const dispatch = useDispatch();
  const people = useSelector((state: any) => state.people.componentPeople.results);
  const [autocompleteValue, setAutocompleteValue] = useState<SelectItem|undefined>(undefined);


  
  



  const onAddToExistingContact = (e: any): void => {
    if(!email || !autocompleteValue?.id) return;

    history.push(`/contacts/${autocompleteValue.id}?email=${email}`)
  }



  const onAutocompleteValueChange = (newValue?: SelectItem|string): void => {
    if(typeof newValue === 'string') return;
    setAutocompleteValue(newValue)
  }



  const dispatchSearchPeople = (searchStr: string = ''): any => {
    return dispatch(searchPeopleLocal(searchStr))
  }


  const dispatchClearPeople = (): any => {
    return dispatch(clearPeopleLocal())
  }

 


  
  useEffect(() => {
    timeout.reset(() => dispatchSearchPeople(autocompleteValue?.name ?? ''))
  }, [autocompleteValue])



  useEffect(() => {
    if(!email) {
      setAutocompleteValue(undefined);
      dispatchClearPeople()
    }
  }, [email])







  return (
    <Fragment>

      {!!menuProps && (
         <Menu optionValue="label"
          anchor={menuProps.anchor}
          options={menuProps.options}
          onSelect={menuProps.onSelect}
        />
      )}
     

      

      <ConfirmDialog
        title="Assign to Existing Contact"
        open={!!email}
        confirmLabel="Add"
        onCancel={props.onCancel}
        onConfirm={onAddToExistingContact}
        confirmDisabled={[undefined, SELECT_ITEM_NONE_ID].includes(autocompleteValue?.id)}
        className={classes.confirmDialog}
      >
        <span className={classes.confirmDialogDescription}>
          Select existing contact to assign email to
        </span>
        <div className={classes.autocompleteWrapper}>
          <Autocomplete doNotSort
            items={people}
            value={autocompleteValue}
            onChange={onAutocompleteValueChange}
            maxItems={20}
            className={classes.autocomplete}
            type={AutocompleteType.People}
          />
        </div>
      </ConfirmDialog>

    </Fragment>
  )
}


export default withStyles(styles)(
  withRouter(NewContactsActions)
)