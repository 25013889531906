import {withStyles} from "@material-ui/core";
import {Theme} from "../../theme";
import {createStyles} from "@material-ui/styles";
import * as React from "react";
import classNames from 'classnames';



const styles = (theme: Theme) => createStyles({
    root: {
        overflowY: 'auto',
        width: '100%',
        padding: '15px 30px 25px',
        boxSizing: 'border-box',
        display: 'flex'
    }
});


const ModalContentComponent = ({children, classes, className=''}) => {

    return <div className={classNames(classes.root, className)}>
        {children}
    </div>
};



export const ModalContent = withStyles(styles)(ModalContentComponent);