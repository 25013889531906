import moment from 'moment';
import projectsService from '../../services/projects/projects';
import { INote, ISort } from '../../shared/interfaces';
import { IProject } from '../../shared/models/Project';
import { PrepareObject } from '../../shared/types';
import { setError, setSuccess } from '../common/notifications';
import { formatSearch, formatSortObject, prepareFields, forceDownloadFile } from '../helpers/helpers';
import { setMembers } from '../people/membership';
import { setProviders } from './providers';


/**
 * Actions
 */

export const GET_PROJECTS_LIST          = "GET_PROJECTS_LIST";
export const APPEND_PROJECTS            = "APPEND_PROJECTS";
export const DELETE_PROJECTS            = "DELETE_PROJECTS";

export const GET_PROJECT_DETAILS        = "GET_PROJECT_DETAILS";
export const CREATE_PROJECT             = "CREATE_PROJECT";
export const CHANGE_PROJECT             = "CHANGE_PROJECT";
export const UPDATE_PROJECT             = "UPDATE_PROJECT";
export const DELETE_PROJECT             = "DELETE_PROJECT";
export const CLEAR_PROJECT              = "CLEAR_PROJECT";
export const CHANGE_SUMMARY             = "CHANGE_SUMMARY";

export const SET_ERRORS                 = "SET_ERRORS";

export const PROJECTS_CREATE_NOTE       = "PROJECTS_CREATE_NOTE";
export const PROJECTS_UPDATE_NOTE       = "PROJECTS_UPDATE_NOTE";
export const PROJECTS_DELETE_NOTE       = "PROJECTS_DELETE_NOTE";

export const CLEAR_TAGS                 = "CLEAR_TAGS";

export const CHANGE_PROJECT_FIELD       = "CHANGE_PROJECT_FIELD";
export const SET_LOADING                = "PROJECTS_SET_LOADING";
export const SET_COMPONENT_PROJECTS     = "SET_COMPONENT_PROJECTS";
export const CLEAR_PROJECTS_LOCAL       = "CLEAR_PROJECTS_LOCAL";
export const CLEAR_ALL                  = "CLEAR_ALL_PROJECTS";

export const SET_READONLY               = "PROJECTS_SET_READONLY";



/**
 * Action creators
 */

export function getProjectsList(data: object): object {
  return {type: GET_PROJECTS_LIST, data};
}


export function getSingleProjectDetails(project: IProject): object {
  return {type: GET_PROJECT_DETAILS, project};
}

export function appendToProjectsList(data: any): object {
  return {type: APPEND_PROJECTS, ...data};
}

export function clearProject(): object {
  return {type: CLEAR_PROJECT};
}

export function changeProject(project: IProject): object {
  return {type: CHANGE_PROJECT, project};
}

export function createSingleProject(project: IProject): object {
  return {type: CREATE_PROJECT, project};
}

export function updateSingleProject(project: any): object {
  return {type: UPDATE_PROJECT, project};
}

export function deleteSingleProject(): object {
  return {type: DELETE_PROJECT};
}

export function deleteProjectsBunch(projects: IProject[]): object {
  return {type: DELETE_PROJECTS, projects};
}

export function createSingleNote(note: any): object {
  return {type: PROJECTS_CREATE_NOTE, note}
}

export function updateSingleNote(note: any): object {
  return {type: PROJECTS_UPDATE_NOTE, note}
}

export function deleteSingleNote(id: number): object {
  return {type: PROJECTS_DELETE_NOTE, id}
}

export function setProjectErrors(errors: object): object {
  return {type: SET_ERRORS, errors}
}

export function clearTags(): object {
  return {type: CLEAR_TAGS}
}

export function changeSummary(summary: any) : object {
  return {type: CHANGE_SUMMARY, summary}
}

export function changeProjectField(field: any): object {
  return {type: CHANGE_PROJECT_FIELD, field};
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}

export function setProjectsForComponent(data: any): object {
  return {type: SET_COMPONENT_PROJECTS, data}
}

export function clearProjectsLocal(): object {
  return {type: CLEAR_PROJECTS_LOCAL}
}

export function clearAllProjectsData(): object {
  return {type: CLEAR_ALL}
}

export function setReadonly(state: boolean): object {
  return {type: SET_READONLY, state}
}




/**
 * Async action creators
 */

export const getProjects = (sort?: ISort[], search: string = ''): any => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));
    
    return projectsService.getProjects(body)
      .then((res: any) => {
        dispatch(getProjectsList({
          results: res.results,
          count: res.count,
          next: res.next,
          previous: res.previous,
        }));
        return Promise.resolve(res);
      })
      .catch(err => {
        dispatch(setError("No projects found", err));
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const getProjectsAssignables = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return projectsService.getProjectsAssignables()
      .then((res: any) => {
        dispatch(setProjectsForComponent({
          results: res
        }))
      })
      .catch(err => {
        dispatch(setError("No projects found", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const getNextProjects = () => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const nextURL = getState().projects.next;
    if(!nextURL) return;
    
    return projectsService.getNextProjects(nextURL)
      .then((res: any) => {
        dispatch(appendToProjectsList({
          results: res.results,
          count: res.count,
          next: res.next,
          previous: res.previous,
        }))
      })
      .catch(err => {
        dispatch(setError("No projects found", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const getProjectDetails = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return projectsService.getProjectDetails(id)
      .then((res: any) => {
        const { user, provider_relations, members, ...project } = res;
        dispatch(getSingleProjectDetails(project));
        dispatch(setProviders(provider_relations));
        dispatch(setMembers(members));
        return Promise.resolve(project);
      })
      .catch(err => {
        if(err?.response?.status === 404) {
          dispatch(setError("Project not found", err))
        }
        return Promise.reject(err);
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const createProject = (project: IProject) => {
  return (dispatch, getState) => {
    const { teamMembers: members, projectProviders: { providers }, notes } = getState();
    const reducersForRequest = {
      members,
      providers,
      notes
    }
    const body = prepareFields(PrepareObject.ProjectRequest, project, reducersForRequest);
    dispatch(setLoading(true));
    

    return projectsService.createProject(body)
      .then((res: IProject) => {
        const response = prepareFields(PrepareObject.ProjectResponse, res, getState().types);
        dispatch(createSingleProject(response));
        dispatch(appendToProjectsList({
          results: [response]
        }));
        dispatch(setSuccess({
          message: "Project successfully created"
        }))
        return Promise.resolve(res.id);
      })
      .catch(err => {
        const errors = err && err.response && err.response.data;
        if(errors) {
          dispatch(setProjectErrors(errors))
        }
        dispatch(setError("Couldn't create a project", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const updateProject = (project: IProject) => {
  
  return (dispatch, getState) => {
    const { teamMembers: members, projectProviders: { providers }, notes } = getState();
    const reducersForRequest = {
      members,
      providers,
      notes
    }
    const body = prepareFields(PrepareObject.ProjectRequest, project, reducersForRequest);
    dispatch(setLoading(true));
    

    return projectsService.updateProject(body)
      .then((res: IProject) => {
        const response = prepareFields(PrepareObject.ProjectResponse, res, getState().types);
        dispatch(updateSingleProject(response));
        dispatch(setSuccess({
          message: "Project successfully updated"
        }))
        return Promise.resolve(true)
      })
      .catch(err => {
        const errors = err && err.response && err.response.data;
        if(errors) {
          dispatch(setProjectErrors(errors))
        }
        dispatch(setError("Couldn't update this project", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const deleteProject = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return projectsService.deleteProject(id)
      .then(() => {
        dispatch(deleteSingleProject());
        dispatch(setSuccess({
          message: "Project successfully deleted"
        }))
        return Promise.resolve(true)
      })
      .catch(err => {
        dispatch(setError("Couldn't delete this project", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const deleteProjects = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return projectsService.deleteProjects(ids)
      .then((res: IProject[]) => {
        dispatch(deleteProjectsBunch(res));
        dispatch(setSuccess({
          message: "Projects successfully deleted"
        }))
        return Promise.resolve(true)
      })
      .catch(err => {
        dispatch(setError("Couldn't delete projects", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const createNote = (note: any, id: number) => {
  return (dispatch) => {
    const body = {
      text: note.text,
      project: id,
      created_at: moment.utc(new Date).format('YYYY-MM-DDTHH:mm:ss')
    };
    dispatch(setLoading(true));
    
    return projectsService.createNote(body)
      .then((res: INote) => {
        dispatch(createSingleNote(res));
      })
      .catch(err => {
        dispatch(setError("Couldn't create note", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const updateNote = (note: any) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return projectsService.updateNote(note)
      .then((res: INote) => dispatch(updateSingleNote(res)))
      .catch(err => {
        dispatch(setError("Couldn't update note", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const deleteNote = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return projectsService.deleteNote(id)
      .then((res: IProject[]) => dispatch(deleteSingleNote(id)))
      .catch(err => {
        dispatch(setError("Couldn't delete note", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const exportProjectsSearch = (body: string = '', ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return projectsService.exportProjectsSearch(body, ids)
      .then((res: any) => {
        if(!res) return;
        forceDownloadFile(res, `ft8-projects-${moment().format('YYYYMMDD')}.csv`)
      })
      .catch(err => {
        console.log(err, err.response)
        dispatch(setError("An error occured while exporting search", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}