import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const ExistingDuplicatesIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="15" height="17" viewBox="0 0 15 17">
      <g fill="currentColor">
        <path d="M14.954 1.865C14.954.835 14.115 0 13.08 0H5.249C4.215 0 3.377.835 3.377 1.865v9.154c0 1.03.838 1.865 1.872 1.865h7.832c1.034 0 1.873-.835 1.873-1.865V1.865zm-3.235 7.967H6.61c-.281 0-.51-.227-.51-.508 0-.28.229-.509.51-.509h5.108c.282 0 .51.228.51.509 0 .28-.228.508-.51.508zm0-2.034H6.61c-.281 0-.51-.228-.51-.508 0-.281.229-.51.51-.51h5.108c.282 0 .51.229.51.51 0 .28-.228.508-.51.508zm0-2.034H6.61c-.281 0-.51-.228-.51-.509 0-.28.229-.508.51-.508h5.108c.282 0 .51.227.51.508s-.228.509-.51.509z"/>
        <path d="M5.28 13.926c-1.592 0-2.887-1.297-2.887-2.891V1.85c0-.059.014-.113.017-.17h-.526c-1.03 0-1.868.838-1.868 1.87v10.885c0 1.032.837 1.871 1.868 1.871h8.152c1.03 0 1.868-.839 1.868-1.87v-.511H5.28z"/>
      </g>
    </SvgIcon>
  );
};