import React, { Fragment } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Skeleton as SkeletonMU } from '@material-ui/lab';
import { range } from '../utils/utils';

const styles = (theme: Theme) => createStyles({
  
})

interface IProps extends WithStyles<typeof styles> {
  count?: number;
  className?: string;
}

const Skeleton = (props: IProps) => {
  return props.count
    ? <Fragment>
        {range(props.count).map((item: number) => (
          <SkeletonMU key={item} variant="rect" className={props.className} />
        ))}
      </Fragment>
    : <SkeletonMU variant="rect" className={props.className} />
}


export default withStyles(styles)(Skeleton)