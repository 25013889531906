import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { ProviderLabel } from '../shared/models/Project';
import { ProviderColorHex } from '../shared/types';
import { Theme } from '../theme';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useAnchor } from '../shared/hooks';
import Menu from './Menu';
import { useSelector, useDispatch } from 'react-redux';
import { updateProvider } from '../actions/projects/providers';
import classNames from 'classnames';



const styles = (theme: Theme) => createStyles({
  root: {
    borderRadius: 14,
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 2,
    textAlign: 'center',
    padding: '2px 0',
    display: 'block',
    width: 'max-content',
    minWidth: 125,
    boxSizing: 'border-box'
  },
  rootWrapper: {
    display: 'flex'
  },
  contacted: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  interested: {
    color: '#ffc143',
    backgroundColor: 'rgba(255, 193, 67, 0.15)'
  },
  negotiating: {
    color: '#dd5a1b',
    backgroundColor: '#f8ded1'
  },
  termsAgreed: {
    color: '#15908a',
    backgroundColor: '#e7f6f6'
  },
  rejected: {
    color: '#d52023',
    backgroundColor: 'rgba(213, 32, 35, 0.1)'
  },
  moreButton: {
    display: 'flex',
    marginLeft: 10,
    cursor: 'pointer'
  },
  moreIcon: {

  }
})

interface IProps extends WithStyles<typeof styles> {
  label: ProviderLabel;
  relationId?: number;
  action?: (label: number) => any;
  customLabel?: string;
  className?: string;
}

const CapitalProviderLabel = (props: IProps) => {
  const { classes, relationId, action } = props;
  const anchor = useAnchor();
  const dispatch = useDispatch();
  const labels = useSelector((state: any) => state.types.provider_relation_types);





  const getOptions = (): any[] => {
    if(!relationId && action) {
      return labels
        // .filter((label: any) => label.id !== props.label)
        .map((label: any) => ({
          ...label,
          onClick: (e: any) => action(label.id)
        }))
    }

    return labels
      .filter((label: any) => label.id !== props.label)
      .map((label: any) => ({
        ...label,
        onClick: (e: any) => {
          if(relationId) {
            dispatch(updateProvider(relationId, label.id));
            anchor.close();
          }
        }
      }))
  }



  const getLabelName = (): string => {
    const label = labels.find((item: any) => item.id === props.label);
    return props.customLabel ?? label?.name ?? 'Label';
  }



  const toggleMenu = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    anchor.set(e)
  }



  const onSelect = (e: any, option: any): void => {
    e.preventDefault();
    e.stopPropagation();

    option.onClick()
  }




  const renderChangeAction = (): any => {
    if(!relationId && !action) return null;

    return (
      <div className={classNames(classes.moreButton, 'hide-on-print')}>
        <MoreHorizIcon className={classes.moreIcon} onClick={toggleMenu} />
        <Menu
          anchor={anchor}
          options={getOptions()}
          onSelect={onSelect}
          optionValue="name"
        />
      </div>
    )
  }




  return (
    <div className={classNames(classes.rootWrapper, props.className)}>
      <span className={classes.root} style={ProviderColorHex[props.label]}>
        {getLabelName()}
      </span>
      {renderChangeAction()}
    </div>
  )
}


export default withStyles(styles)(CapitalProviderLabel)