import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import * as React from "react";
import { Theme } from "../../theme";
import TextInput from "../TextInput";
import { default as BaseForm, IBaseFormProps } from "./BaseForm";

const styles = (theme: Theme) => createStyles({
  label: {
    textAlign: 'left',
    fontFamily: 'Montserrat',
    color: '#657274',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 2.18,
  },
  input: {
    // add space between elements:
    fontSize: '12px !important',
    marginBottom: 0,
    '&:first-of-type': {
      marginBottom: 16
    }
  },
  error: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    color: '#d52023'
  }
});

export interface IInput {
  value: string,
  label: string,
  onChange: (value: string) => void,
  onEnter?: (event) => any,
  type?: string,
  errorMessage?: string,
}

interface IProps extends IBaseFormProps, WithStyles<typeof styles> {
  inputs: IInput[]
}

class TextInputForm extends React.Component<IProps> {

  createInputs() {
    const { inputs, classes } = this.props;
    return inputs.map((input: IInput, idx: number) => (
      <>
        <span className={classes.label}>{input.label}</span>
        <TextInput
          key={`${input.label}-${input.type}`}
          className={classes.input}
          value={input.value}
          onChange={input.onChange}
          onEnter={input.onEnter}
          type={input.type}
          error={input.errorMessage != null}
        />
        {input.errorMessage && <span className={classes.error}>{input.errorMessage}</span>}
      </>
    ))
  }

  render() {
    const { header, description } = this.props;
    return (
      <BaseForm
        header={header}
        description={description}
      >
        {this.createInputs()}
      </BaseForm>
    );
  }
}

export default withStyles(styles)(TextInputForm)