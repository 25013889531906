import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const BoardIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <defs>
        <path d="M2.99999996,13 L11,13 L11,2.99999996 L2.99999996,2.99999996 L2.99999996,13 L2.99999996,13 Z M2.99999996,21 L11,21 L11,15 L2.99999996,15 L2.99999996,21 L2.99999996,21 Z M13,21 L21,21 L21,11 L13,11 L13,21 L13,21 Z M13,2.99999996 L13,8.99999996 L21,8.99999996 L21,2.99999996 L13,2.99999996 L13,2.99999996 Z" id="path-1"></path>
    </defs>
    <g id="Projects" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="FinTech9-Projects-List-View" transform="translate(-1370.000000, -286.000000)">
            <g id="dashboard-copy-2" transform="translate(1367.000000, 283.000000)">
                <polygon id="Base" points="-4.99999828e-08 -4.9999926e-08 24.0000001 -4.9999926e-08 24.0000001 24.0000001 -4.99999828e-08 24.0000001"></polygon>
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Icon" fillRule="nonzero"></g>
                <rect id="↳-🎨-Color" fill="#4192EC" fillRule="nonzero" mask="url(#mask-2)" x="0" y="0" width="24" height="24"></rect>
            </g>
        </g>
    </g>
    </SvgIcon>
  );
};