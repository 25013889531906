import { Paper } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, useHistory, withRouter } from 'react-router';
import { advancedSearch, advancedSearchNext, clearResults, clearSearchRows, removeFromResults, setResults, setSearchRows } from '../../actions/advanced-search';
import { changeUserTablePrefs } from '../../actions/auth';
import { clearSelectedForList } from '../../actions/common/list';
import { setLoadedPage } from '../../actions/common/loading';
import { deletePeople, exportPeopleSearch } from '../../actions/people/people';
import { exportProjectsSearch } from '../../actions/projects/projects';
import { createSearchHistoryItem, getSearchHistory, getSearchHistoryItem } from '../../actions/search-history';
import { exportTasksSearch } from '../../actions/tasks';
import Button from '../../components/Button';
import ConfirmDialog from '../../components/ConfirmDialog';
import { BookmarkIcon } from '../../components/icons/BookmarkIcon';
import List from '../../components/list/List';
import ListHeader from '../../components/list/ListHeader';
import PrintDescription from '../../components/print/PrintDescription';
import SaveSearchDialog from '../../components/SaveSearchDialog';
import SearchButtons from '../../components/search/SearchButtons';
import SearchPageRows from '../../components/search/SearchPageRows';
import SearchHistory from '../../components/SearchHistory';
import { ARCHIVED_SEARCH } from '../../constants/index';
import { ROUTE_SEARCH } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { useOpen } from '../../shared/hooks';
import { ISort, ManageableTableColumn } from '../../shared/interfaces';
import { ISearchRow, ISearchSubRow, SearchPerson, SearchTypeName } from '../../shared/models/Search';
import { PrintType, SearchTitleFromSearch, SearchType, SELECT_ITEM_NONE_ID, TableSelectorFromSearch, TableType, TableTypeFromSearch } from '../../shared/types';
import { Theme } from '../../theme';
import { isAdmin, isUser } from '../../utils/user';
import { getCompaniesCounter, getIds, goTo } from '../../utils/utils';
import AssignPeopleToProjectModal from '../people/assign-people-to-project/AssignPeopleToProjectModal';
import EmailModal from "../people/email/EmailModal";


const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  header: {
    
  },
  paper: {
    padding: 20,
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 25,
    marginBottom: 17,
    borderBottom: '1px solid #d9e4e8',
    '&:last-child': {
      marginBottom: 0,
      border: 0,
      paddingBottom: 0
    }
  },
  sectionHeader: {
    fontSize: 13,
    lineHeight: 1.85,
    color: '#233539',
    marginBottom: 11
  },
  sectionContent: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 20,
    width: '100%'
  },
  rowsContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start'
  },
  searchButton: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    color: '#fff',
    width: 80,
    height: 40
  },
  listWrapper: {
    margin: '42px 0'
  },
  list: {
    marginTop: 0
  },
  title: {
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    margin: 0
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 17px'
  },
  savedSearchesButton: {
    height: 'initial',
    padding: '3px 10px',
    fontWeight: 400,
    lineHeight: 2
  },
  savedSearchesIcon: {
    color: '#fff',
    fontSize: 13,
    marginRight: 10
  },
  saveSearch: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  saveSearchWrapper: {
    display: 'flex',
    marginRight: 20,
    position: 'relative'
  },
  saveSearchDisabled: {
    color: '#e9eced',
    pointerEvents: 'none',
  },
  skeleton: {
    height: 500
  }
})

interface IProps extends WithStyles<typeof styles> {
  type: SearchType;
  typePath: string;
  searchId?: number;
}

const SearchPage = (props: IProps) => {
  const { classes, type, searchId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const searchHistoryVisibility = useOpen();
  const deleteControls = useOpen();
  const listControls = useOpen();
  const user = useSelector((state: any) => state.user.user);
  const loadedPages = useSelector((state: any) => state.loading);
  const list = useSelector((state: any) => state.list);
  const { rows, loading, list: { results, count } } = useSelector((state: any) => state.advancedSearch);
  const searchItem = useSelector((state: any) => state.searchHistory.current);
  const [query, setQuery] = useState<string>('');
  const [selected, setSelected] = useState<number[]>([]);
  const [triggerSearch, setTriggerSearch] = useState<boolean|undefined>(false);
  const saveSearchVisibility = useOpen();
  




  const onInit = (): void => {
    dispatch(getSearchHistory())
  }




  const initiateRows = (): void => {
    if(searchId) {

      dispatchGetSearchHistoryItem(searchId)
        .then((res: any) => {
          dispatchSetRows(res.rows);
          dispatchSetTablePrefs(res.columns)
          searchHistoryVisibility.close();
        })

      return
    }

    return dispatchClearRows()
  }



  const handleSearchTypeSelect = (searchType: SearchType) : void => {
    setResults(null);
    dispatchClearSearch(TableTypeFromSearch[type]);
    history.push(`/search/${SearchTypeName[searchType]}`);
    initiateRows();
  }



  const handleEditMultiple = () => {
    history.push(`/edit-multiple-contacts?items=${selected.join(',')}`, { backURL: window.location.pathname })
  };




  const formatSearch = (row: ISearchRow) : string => {
    const { field, condition, value } = row;

    if(!condition?.format || !value) return '';

    const val = Array.isArray(value)
      ? value
      : ([undefined, SELECT_ITEM_NONE_ID].includes(value?.id)
          ? (value?.name ?? value)?.toLowerCase()
          : value.id
        )


    return condition.format(field.value, val);
  }




  const areRowsValid = (): any => {
    return rows
      .map((row: any) => !Object.values(row).some((item: any) => !item))
      .every(i => i)
  }



  const constructQuery = (): string => {

    const constructedRows = rows
      .filter((row: ISearchRow) => !!row.condition && !!row.value)
      .map((row: ISearchRow) => {


        if(row.field?.id === SearchPerson.ContactType && !!row.subRows?.length) {
          
          
          
          const cpSubRows = row.subRows
            .filter((subRow: ISearchSubRow) => subRow.field?.id !== SearchPerson.Subcategory)
            .filter((subRow: ISearchSubRow) => !!subRow.condition?.format)
            .map((subRow: ISearchSubRow) => subRow.condition.format(subRow.field.value, subRow.value ?? []))
            .join('&')

          
          const subRows = row.value
            .map((item: any) => {
              const subRow = row.subRows?.find((subRow: ISearchSubRow) => subRow?.args?.categoryId === item.id);
              return !!subRow?.value ? subRow.condition?.format('', subRow.value) : item.id
            })
            .filter((item: string|number) => !!item)
            .join(',')

          const rowValue = row.condition?.format?.(row.field.value, subRows);

          if(!!rowValue) {
            return `${rowValue}${!!cpSubRows ? `&${cpSubRows}` : ''}`
          }
        }


        const search = formatSearch(row);
        const searchSubValues = row.subRows && !!row.subRows.length
          ? `&${row.subRows.map(formatSearch).join('&')}`
          : ''


        return `${search}${searchSubValues}`
      });
    
    return `?${constructedRows.join('&')}`
  }



  const onSearch = (): any => {
    if(!listControls.value) {
      listControls.open();
    }

    setTriggerSearch(false);
    setTimeout(() => setTriggerSearch(true), 10);
  }



  const dispatchOnSearch = (sort: ISort[], body: string): Promise<any> => {
    return dispatch(advancedSearch(type, sort, body))
  }




  const getPrintType = (): any => {
    switch(type) {
      case SearchType.People:
        return PrintType.PEOPLE
      case SearchType.Projects:
        return PrintType.PROJECTS
      case SearchType.Tasks:
        return PrintType.TASKS
    }
  }


  const onResultOpen = (e: any, rowId: number): void => {
    let url;

    switch(type) {
    case SearchType.People:
        url = `/contacts/${rowId}`;
        break;

      case SearchType.Projects:
        url = `/projects/${rowId}`;
        break;

      case SearchType.Tasks:
        const task = results?.find((item: any) => item.id === rowId);
        url = `/task/${task.id}`;
        break;
    }

    if(!url) return;
    goTo(e, url, history)
  }

  const hasFullAccess = (): boolean => {
    return isAdmin(user) || isUser(user)
  }

  const canManageAdmins = (): boolean => {
    if(isAdmin(user)) return true;
    if(!isUser(user) || !results) return false;
    
    const admins = results.filter(isAdmin);
    const adminsIds = getIds(admins);
    return !selected.some((id: number) => adminsIds.includes(id))
  }

  const hasDeleteAccess = (): boolean => {
    return isAdmin(user)
  }

  const exportResults = (): any => {
    if(!type) return; 

    switch(type) {
      case SearchType.People:
        return dispatch(exportPeopleSearch(query, selected))

      case SearchType.Projects:
        return dispatch(exportProjectsSearch(query, selected))

      case SearchType.Tasks:
        return dispatch(exportTasksSearch(query, selected))
    }
    
  }

  const handleDelete = () => {
    dispatchDeleteMultiple(selected)
      .then((res: any) => {
        dispatchRemoveFromResults(selected);
      })
      .finally(deleteControls.close)
  };

  const getRowValueName = (row: any): string => {
    return !!(row.value && row.value.id) ? row.value.name : row.value
  }



  const printDescription = useMemo((): any => {
    const validRows = areRowsValid() ? rows.map((row: any, idx: number) => ({
      title: `${row.type.name} ${row.field.name} `,
      value: `${row.condition.name} ${getRowValueName(row)}${idx === rows.length - 1 ? '' : ','} `
    })) : [];

    return [
      { title: 'Displaying: ', value: SearchTitleFromSearch[type] },
      ...validRows
    ]

  }, [rows, type])


  const handlePrint = (e: any): any => {
    // const validRows = areRowsValid() ? rows.map((row: any, idx: number) => ({
    //   title: `${row.type.name} ${row.field.name} `,
    //   value: `${row.condition.name} ${getRowValueName(row)}${idx === rows.length - 1 ? '' : ','} `
    // })) : [];


    // const printData: IPrintData = {
    //   description: [
    //     {title: 'Displaying: ', value: SearchTitleFromSearch[type]},
    //     ...validRows
    //   ],
    //   list: !!selected.length
    //     ? results?.filter((result: any) => selected.includes(result.id))
    //     : results
    // };


    // dispatch(setPrintData(getPrintType(), printData))
    // window.open(`/print/${props.typePath}`, '_blank')
  }

  const dispatchDeleteMultiple = (indexes: number[]): any => {
    return dispatch(deletePeople(indexes))
  }

  const dispatchCreateSearchHistoryItem = (body: any): any => {
    return dispatch(createSearchHistoryItem(body))
  }

  const dispatchGetSearchHistoryItem = (id: number): any => {
    return dispatch(getSearchHistoryItem(id))
  }

  

  const dispatchClearSearch = (tableType: TableType): any => {
    return dispatch(clearSelectedForList(tableType))
  }



  const handleAssignToProject = (): void => {
    history.push(`/search/contacts/assignToProject?items=${selected.join(',')}`)
  }



  const onSort = (sort: ISort[], search: string): Promise<any> => {
    const body = `${query}${query === '?' ? search : `&${search}`}`


    return dispatchOnSearch(sort, body)
      .then((res: any) => {
        if(!loadedPages.pagesLoaded[ROUTE_SEARCH]) {
          dispatchSetPageLoaded()
        }
      })
  }




  const isLoading = (): boolean => {
    return !loadedPages.pagesLoaded[ROUTE_SEARCH]
  }

  const onSearchHistoryItemOpen = (historyItem: any): void => {
    if(historyItem?.rows && historyItem?.type) {
      history.push(`/search/${SearchTypeName[historyItem.type]}/saved/${historyItem.id}`);
    }
  }

  const onNewSearchQuerySave = (searchTitle: string, e: any): void => {
    if(areRowsValid() && !!searchTitle) {
      const searchQuery = {
        title: searchTitle,
        person: user.id,
        columns: user.table_column_prefs?.[TableSelectorFromSearch[type]] ?? [],
        rows,
        type,
        query
      }


      dispatchCreateSearchHistoryItem(searchQuery)
        .then((res: any) => saveSearchVisibility.close())
    }
  }



  const getExtraCounterComponent = (): any => {
    if(type !== SearchType.People) return null;

    const filteredPeople = results?.filter((item: any) => selected.includes(item.id));
    return getCompaniesCounter(filteredPeople)
  }





  const dispatchSetPageLoaded = (): any => {
    return dispatch(setLoadedPage(ROUTE_SEARCH));
  }



  const dispatchOnNext = (): Promise<any> => {
    return dispatch(advancedSearchNext())
  }



  const dispatchRemoveFromResults = (ids: number[]): any => {
    return dispatch(removeFromResults(ids))
  }



  const dispatchClearResults = (): any => {
    return dispatch(clearResults())
  }



  const dispatchClearRows = (): any => {
    return dispatch(clearSearchRows())
  }



  const dispatchSetRows = (newRows: ISearchRow[]): any => {
    return dispatch(setSearchRows(newRows))
  }



  const dispatchSetTablePrefs = (prefs: ManageableTableColumn[]): any => {
    return dispatch(changeUserTablePrefs(ARCHIVED_SEARCH, prefs))
  }






  useEffect(() => {
    ReactGA.pageview(history.location.pathname)

    onInit();

    
    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])


  useEffect(() => {
    listControls.close()

    if(!type) {
      history.push('/search/contacts')
    }

    initiateRows();
  }, [type])



  useEffect(() => {
    if(!rows.length) {
      initiateRows()
    }

    const newQuery = constructQuery();
    setQuery(newQuery)
  }, [rows])


  useEffect(() => {
    if(searchId) {
      initiateRows();
    }
  }, [searchId])



  useEffect(() => {
    if(!type) return;
    
    const newSelected = list[TableTypeFromSearch[type]]?.selected;
    if(!newSelected) return;

    setSelected(list[TableTypeFromSearch[type]].selected)
  }, [list, type])



  useEffect(() => {
    if(!listControls.value) {
      dispatchClearResults()
    }
  }, [listControls.value])



  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classNames(classes.titleWrapper, "hide-on-print")}>
          <h1 className={classes.title}>Search</h1>
          <Button
              primary
              className={classes.savedSearchesButton}
              onClick={searchHistoryVisibility.open}
            >
              <BookmarkIcon className={classes.savedSearchesIcon} />
              <span>Archived Searches</span>
            </Button>
        </div>

        <Paper className={classNames(classes.paper, 'hide-on-print')}>
          <div className={classes.section}>
            <span className={classes.sectionHeader}>What are you searching for?</span>
            <div className={classes.sectionContent}>
              <SearchButtons
                active={type}
                onSelect={handleSearchTypeSelect}
              />
            </div>
          </div>

          <div className={classes.section}>
            <span className={classes.sectionHeader}>Narrow your search results:</span>
            <div className={classNames(classes.sectionContent, classes.rowsContent)}>
              



              <SearchPageRows type={type} />




              <div className={classes.search}>
                <div className={classes.saveSearchWrapper}>
                  <span
                    onClick={saveSearchVisibility.toggle}
                    className={classNames(
                      classes.saveSearch,
                      {[classes.saveSearchDisabled]: !areRowsValid()}
                    )}
                  >
                    Save this search
                  </span>

                  <SaveSearchDialog
                    visibilityControls={saveSearchVisibility}
                    onSave={onNewSearchQuerySave}
                  />

                  
                </div>

                <Button
                  primary
                  onClick={onSearch}
                  className={classes.searchButton}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>

        </Paper>

        {listControls.value && (
            <div className={classes.listWrapper}>

              <PrintDescription items={printDescription} />


              <ListHeader
                withEmail
                loading={isLoading()}
                title={results && !!count ? 'Results' : 'No results found'}
                count={count}
                fullAccess={hasFullAccess()}
                canManageAdmins={canManageAdmins()}
                hasDeleteAccess={hasDeleteAccess()}
                onExport={isAdmin(user) && exportResults}
                onDelete={deleteControls.open}
                actionsVisible={!!selected.length && (searchItem?.type ?? type) === SearchType.People}
                onEditMultiple={handleEditMultiple}
                onPrint={handlePrint}
                onAssignToProject={handleAssignToProject}
                selected={selected}
                selectedCounterLabel={SearchTitleFromSearch[searchItem?.type ?? type]}
                extraCounterComponent={getExtraCounterComponent()}
              />


              <List selectable
                initialLoading={isLoading()}
                loading={loading}
                rows={results}
                count={count}
                onOpen={onResultOpen}
                fullAccess={hasFullAccess()}
                tableType={TableTypeFromSearch[searchItem?.type ?? type]}
                onSort={onSort}
                onNext={dispatchOnNext}
                className={classes.list}
                searchItem={searchItem}
                triggerSearch={triggerSearch}
              />
            </div>
          )}



        <SearchHistory
          opened={searchHistoryVisibility.value}
          onClose={searchHistoryVisibility.close}
          onOpen={onSearchHistoryItemOpen}
        />


        <Route path={'/search/contacts/email'} component={EmailModal} />
        <Route path={'/search/contacts/assignToProject'} component={AssignPeopleToProjectModal} />
      </div>



      
      <ConfirmDialog
        title="Are you sure?"
        open={deleteControls.value}
        cancelLabel="Cancel"
        confirmLabel="YES"
        onCancel={deleteControls.close}
        onConfirm={handleDelete}
      >
        Are you sure you want to delete these {props.typePath}?
      </ConfirmDialog>
    </div>
  )
}


export default withStyles(styles)(
  SearchPage
)