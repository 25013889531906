import * as React from "react";
import { Theme, palette } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import Text from "./Text";
import classNames from 'classnames';


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  dataRoot: {
    minHeight: 40,
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    fontSize: 12,
    flex: 4,
    lineHeight: 1.67,
    color: '#657274',
    transform: 'translateX(8px)',
    paddingRight: 10,
    transition: theme.transitions.create(
      ['transform']
    ),
  },
  labelNoPadding: {
    transform: 'translateX(0px)'
  },
  children: {
    flex: 6.2,
  },
  error: {
    color: '#d52023',
    fontSize: 11,
    lineHeight: 1.22,
    fontFamily: 'Montserrat',
    margin: '5px 0 10px',
    transition: 'all .1s ease',
    opacity: 1,
    transformOrigin: 'top center',
    transform: 'scaleY(1)'
  },
  hidden: {
    opacity: 0
  }
});

export interface ILabelledListItemProps {
  label: string;
  children?: any;
  errors?: any[];
  readonly?: boolean;
  required?: boolean;
  className?: string;
  hidden?: boolean;
}

interface IProps extends ILabelledListItemProps, WithStyles<typeof styles> {

}

const LabelledItem = (props: IProps) => {
  const { classes, errors, readonly } = props;

  const getErrors = () => {
    return errors && errors.length && errors[0];
  }



  if(props.hidden) {
    return null
  }



  return (
    <div className={classNames(classes.root, props.className)}>
      <div className={classes.dataRoot}>
        <Text
          variant="data2"
          className={classNames(
            classes.label,
            {[classes.labelNoPadding]: !readonly}
          )}
        >
          {`${props.label}:${props.required ? '*' : ''}`}
        </Text>
        <div className={classes.children}>
          {props.children}
        </div>
      </div>


      {errors && errors.length && !readonly
        ? (
            <div className={classes.error}>
              {getErrors()}
            </div>
          )
        : null
      }

    </div>
  );
  
}

export default withStyles(styles)(LabelledItem)