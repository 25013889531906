import { IEmail, INote, IPhone, ITask } from "../interfaces";

export enum InviteStatus {
  NotSent = 0,
  Pending = 1,
  Accepted = 2
}



export interface IPerson {
  id?: any,
  user?: any,
  first_name: string,
  last_name: string,
  job_title?: string,
  company: string,
  website?: string,
  previous_company?: string,
  rating?: number,
  bio?: string,
  street?: string,
  // street2?: string,
  city?: string,
  postcode?: string,
  // county?: string,
  country?: string,
  errors: object,

  notes: INote[],
  emails: IEmail[],
  phones: IPhone[],
  files: any[],
  projects: any[],
  project_memberships: any[],
  provider_relations: any[],
  tasks: any[],
  tags: string[],
  
  title?: any,
  invite_status: any,
  profile_type?: any,
  client_class?: any
  categories?: any,
  use_classes?: any[],
  locations?: any[],
  capital_provider?: any,
  legal_status?: any,
  signature?: string|null
}


export const SelectablePersonFields = [
  'client_class',
  'categories',
  'subcategories',
  'title',
  'legal_status',
  'use_classes',
  'locations',
  'profile_type'
]

export const SelectablePersonTypes = {
  client_class: 'client_classes',
  categories: 'categories',
  subcategories: 'subcategories',
  title: 'title',
  legal_status: 'legal_status',
  use_classes: 'use_class_types',
  locations: 'location_types',
  profile_type: 'profile_types',
}

export const SelectableCapitalProviderFields = [
  'cp_subcategory_types',
  'investing_geographies',
  'investment_classes'
]

export const SelectableCapitalProviderTypes = {
  cp_subcategory_types: 'cp_subcategories',
  investing_geographies: 'investing_geography_types',
  investment_classes: 'investment_class_types',
}




export const CapitalProviderInfoNames: Record<string, string> = {
  type: 'Capital Provider Type',
  investment_classes: 'Investment Class',
  investing_geographies: 'Investing in',
  max_ltv: 'Max LTV',
  max_ltgdv: 'Max LTGDV',
  max_ltc: 'Max LTC',
  max_size: 'Max Size',
  max_term: 'Max Term',
  min_irr: 'Min IRR',
  min_roe: 'Min ROE',
  min_size: 'Min Size',
  min_term: 'Min Term',
  is_sharia: 'Sharia',
  is_development: 'Dev',
  is_land: 'Land',
  is_prs: 'PRS',
  is_debt: 'Debt',
  is_equity: 'Equity'
};



export const initialCapitalProvider = {
  cp_subcategory_types: null,
  investment_classes: [],
  investing_geographies: [],
  max_ltv: 0,
  max_ltgdv: 0,
  max_ltc: 0,
  min_irr: 0,
  min_roe: 0,
  min_term: 0,
  max_term: 0,
  min_size: 0,
  max_size: 0,
  is_sharia: false,
  is_development: false,
  is_land: false,
  is_prs: false,
  is_debt: true,
  is_equity: false,
  errors: {}
}


export const initialPerson: IPerson = {
  first_name: '',
  last_name: '',
  job_title: '',
  company: '',
  website: '',
  previous_company: '',
  rating: 0,
  bio: '',
  street: '',
  city: '',
  postcode: '',
  country: '',
  errors: {},

  notes: [],
  emails: [],
  phones: [],
  files: [],
  projects: [],
  project_memberships: [],
  provider_relations: [],
  tasks: [],
  tags: [],
  
  title: {},
  invite_status: {},
  profile_type: {},
  client_class: {},
  categories: [],
  use_classes: [],
  locations: [],
  capital_provider: initialCapitalProvider,
  legal_status: {},
  signature: null
}