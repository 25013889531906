import React from 'react';
import { SvgIcon } from "@material-ui/core";

interface IProps {
  color?: string;
  withBackground?: boolean;
  className?: any; 
}

export const ProjectsIcon = (props: IProps) => {
  const { withBackground, color, ...svgProps } = props;
  return withBackground
    ? (
        <SvgIcon {...svgProps} width="64" height="64" viewBox="0 0 64 64">
          <g fill="none" fillRule="evenodd">
            <circle cx="32" cy="32" r="32" fill="#F4F8F9" fillRule="nonzero"/>
            <path d="M16 16h32v32H16z"/>
            <path fill="#4192EC" fillRule="nonzero" d="M42.667 24H32l-2.667-2.667h-8A2.663 2.663 0 0 0 18.68 24l-.013 16c0 1.467 1.2 2.667 2.666 2.667h21.334c1.466 0 2.666-1.2 2.666-2.667V26.667c0-1.467-1.2-2.667-2.666-2.667zm0 16H21.333V26.667h21.334V40z"/>
          </g>
        </SvgIcon>
      )
    : (
        <SvgIcon {...withBackground} {...svgProps} width="28" height="22" viewBox="0 0 28 22">
          <g fill="none" fillRule="evenodd">
            <path d="M-2-5h32v32H-2z"/>
            <path fill="currentColor" fillRule="nonzero" d="M24.667 3H14L11.333.333h-8A2.663 2.663 0 0 0 .68 3L.667 19c0 1.467 1.2 2.667 2.666 2.667h21.334c1.466 0 2.666-1.2 2.666-2.667V5.667C27.333 4.2 26.133 3 24.667 3zm0 16H3.333V5.667h21.334V19z"/>
          </g>
        </SvgIcon>
      );
  
};
