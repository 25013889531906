import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const EmailSignatureIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="17" height="17" viewBox="0 0 17 17">
    <defs>
      <path id="email_signature_prefix__a" d="M0.34 0.113L16.66 0.113 16.66 2.833 0.34 2.833z"/>
      <path id="email_signature_prefix__c" d="M9.577 5.44l-2.55-2.55-6.8 6.8v2.55h2.55l6.8-6.8zm2.012-2.013c.266-.265.266-.693 0-.959L10 .878c-.266-.266-.694-.266-.96 0L7.707 2.21l2.55 2.55 1.332-1.333z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fillRule="nonzero" d="M0.34 0.68L16.66 0.68 16.66 17 0.34 17z"/>
      <g transform="translate(0 14.167)">
        <mask id="email_signature_prefix__b" fill="#fff">
          <use xlinkHref="#email_signature_prefix__a"/>
        </mask>
        <path fill="#4192EC" d="M0 -14.167H17V2.833H0z" mask="url(#email_signature_prefix__b)"/>
      </g>
      <g transform="translate(2.833)">
        <mask id="email_signature_prefix__d" fill="#fff">
          <use xlinkHref="#email_signature_prefix__c"/>
        </mask>
        <path fill="#4192EC" d="M-2.833 0H14.167V17H-2.833z" mask="url(#email_signature_prefix__d)"/>
      </g>
    </g>

    </SvgIcon>
  );
};