import * as actions from '../actions/files';
import { FilesType } from '../shared/types';

export const filesInitialState = {
  results: [] as any[],
  type: FilesType.People,
  loading: false
};

export default function(state = filesInitialState, action) {
  let idx;
  let newFiles;

  switch (action.type) {


    case actions.SET_FILES_TYPE:
      return {
        ...state,
        type: action.filesType,
        results: [] as any[]
      }



    case actions.SET_FILES:
      return {...state, results: action.files}
    


    case actions.ADD_FILES:
      if(!action.files.length) return state;
      const typeId = action.files[0].type;

      if(!state.results.length) {
        return {
          ...state,
          results: [{
            results: action.files,
            count: action.files.length,
            type: typeId
          }]
        }
      }

      idx = state.results.findIndex((i: any) => i.type === typeId);
      newFiles = [...state.results] as any;
      if(newFiles[idx]) {
        newFiles[idx] = {
          ...newFiles[idx],
          results: [...newFiles[idx].results, ...action.files],
          count: newFiles[idx].count + action.files.length
        };
      }
      
      return {...state, results: newFiles};
 


    case actions.DELETE_FILE:
      idx = state.results.findIndex((i: any) => i.type === action.typeId);
      newFiles = [...state.results] as any;
      newFiles[idx] = {
        ...newFiles[idx],
        results: newFiles[idx].results.filter((file: any) => file.id !== action.id),
        count: --newFiles[idx].count
      };
      return { ...state, results: newFiles };



    case actions.DELETE_FILE_BY_IDX:
      idx = state.results.findIndex((i: any) => i.type === action.typeId);
      newFiles = [...state.results] as any;
      newFiles[idx] = {
        ...newFiles[idx],
        results: newFiles[idx].results.filter((_, idx: number) => idx !== action.idx),
        count: --newFiles[idx].count
      };
      return { ...state, results: newFiles };



    case actions.CLEAR_FILES:
      return {...filesInitialState}
  


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }


    default:
      return state
  }
}