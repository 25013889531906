import { Editor } from '@tinymce/tinymce-react';
import React, { useState, useEffect, useRef } from 'react';
import { emailDistroPlugin } from "../../../../actions/common/tinymce/distro";
import service from '../../../../services/files';
import { TinymceType } from '../../../../shared/types';
import { createStyles, WithStyles, withStyles, CircularProgress } from '@material-ui/core';
import { Theme } from '../../../../theme';
import classNames from 'classnames';



const tinyMCEConfig = (type: TinymceType = TinymceType.DEFAULT, setAction: (editor: any) => any) => ({
  height: 360,
  width: '100%',
  menubar: false,

  setup: editor => {
    setAction(editor)
    emailDistroPlugin(editor, type);
  },


  file_picker_types: 'image',
  automatic_uploads: true,
  images_reuse_filename: true,
  images_upload_handler: (blobInfo: any, success: any, failure: any) => {
    const fd = new FormData();
    fd.append('file', blobInfo.blob(), blobInfo.filename());

    service.uploadPublicFile(fd)
      .then((res: any) => {
      if(res.file) {
        success(res.file, { alt: res?.name ?? '' })
      } else {
        console.log(res);
        failure(`File doesn't have 'file' field`);
      }
      })
      .catch((err: any) => {
      console.log(err);
      failure("Error while uploading the file");
      })
  },

  plugins: "fullpage image link importcss",
  toolbar: 'undo redo | forecolor backcolor | fontselect fontsizeselect | bold italic underline | link | outdent indent | distroVariables | image',
  
  content_css: '/css/fonts.css',

  fullpage_default_font_family: "'Calibri', Georgia, Serif;",
  fontsize_formats: "8pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt",
  font_formats : "Andale Mono=andale mono,times;"+
  "Arial=arial,helvetica,sans-serif;"+
  "Arial Black=arial black,avant garde;"+
  "Book Antiqua=book antiqua,palatino;"+
  "Calibri=calibri,sans-serif;"+
  "Comic Sans MS=comic sans ms,sans-serif;"+
  "Courier New=courier new,courier;"+
  "Georgia=georgia,palatino;"+
  "Helvetica=helvetica;"+
  "Impact=impact,chicago;"+
  "Symbol=symbol;"+
  "Tahoma=tahoma,arial,helvetica,sans-serif;"+
  "Terminal=terminal,monaco;"+
  "Times New Roman=times new roman,times;"+
  "Trebuchet MS=trebuchet ms,geneva;"+
  "Verdana=verdana,geneva;"+
  "Webdings=webdings;"+
  "Wingdings=wingdings,zapf dingbats",
});


const styles = (theme: Theme) => createStyles({
  root: {
    height: 360,
    minHeight: 360,
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    opacity: 1,
    visibility: 'visible'
  },
  loadingHidden: {
    visibility: 'hidden',
    opacity: 0
  }
})
interface IProps extends WithStyles<typeof styles> {
  value?: string;
  onChange: (content: any) => any;
  type?: TinymceType;
}


const TinyMCEField = (props: IProps) => {
  const { classes, value = '', type = TinymceType.DEFAULT } = props;
  const [editor, setEditor] = useState<any>(null);
  const [shouldRender, setShouldRender] = useState<boolean>(true)




  const onEditorChange = (content: any, editor: any): any => {
    const newContent = content.replace(
      /<head>(.*)\n<\/head>/,
      `<head><link rel="stylesheet" href="${window.location.origin}/css/fonts.css"></head>`
    )
    props.onChange(newContent)
  }




  useEffect(() => {
    setShouldRender(false)
  }, [type])




  useEffect(() => {
    if(!shouldRender) {
      setShouldRender(true)
    }
  }, [shouldRender])





  return (
    <div className={classes.root}>
      {shouldRender && (
        <Editor
          onEditorChange={onEditorChange}
          // initialValue={''}
          initialValue={value}
          apiKey={'8w934r9oanj9inzhezn7b7j4rapecb5sb1ejexig07x8r5dl'}
          init={tinyMCEConfig(type, setEditor)}
        />
      )}

      <div className={classNames(
        classes.loading,
        {[classes.loadingHidden]: shouldRender}
      )}>
        <CircularProgress size={40} />
      </div>
    </div>
  )
}


export default withStyles(styles)(TinyMCEField)