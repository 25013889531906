import { Paper } from '@material-ui/core'
import ArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { createStyles, WithStyles, withStyles } from '@material-ui/styles'
import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState, Fragment } from 'react'
import { searchPeoplePromise } from '../actions/people/people'
import { useOpen } from '../shared/hooks'
import { IDialogMenuItem } from '../shared/interfaces'
import { DateFormat, TableType } from '../shared/types'
import { Theme } from '../theme'
import { getUsername } from '../utils/user'
import { getDateFormat, getIds } from '../utils/utils'
import Button, { ButtonSize } from "./Button"
import CheckboxWithLabel from './CheckboxWithLabel'
import DateInput from './DateInput'
import InputAutocompletePersonField from './forms/meta/fields/InputAutocompletePersonField'
import TextInput from './TextInput'
import TypeIcon from '@material-ui/icons/Subject'
import PersonIcon from '@material-ui/icons/Person'
import CalendarIcon from '@material-ui/icons/CalendarToday'
import CloseIcon from '@material-ui/icons/Close'
import { FunnelIcon } from './icons/FunnelIcon'
import Select from './Select'
import { useSelector, useDispatch } from 'react-redux'
import { setOneFilter, setFilters } from '../actions/common/list'



const styles = (theme: Theme) => createStyles({
  root: {

  },
  option: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#657274',
    cursor: 'pointer',
    minHeight: 'initial',
    '&:hover': {
      color: '#4192ec',
      backgroundColor: 'transparent'
    },
  },
  optionSelected: {
    color: '#4192ec',
    backgroundColor: 'transparent !important'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
      color: theme.palette.primary.main
    }
  },
  icon: {
    // color: '#4192ec',
    width: 20,
    height: 20,
    marginLeft: 5,
    transition: 'transform .1s ease'
  },
  iconOpened: {
    transform: 'rotate(180deg)'
  },
  paper: {
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    borderRadius: 0,
    position: 'absolute',
    marginTop: 10,
    right: 133,
    zIndex: 100,
    transition: 'transform .1s ease',
    transformOrigin: 'top right',
    opacity: 0,
    transform: 'scaleY(0)',
    boxSizing: 'border-box'
  },
  paperOpened: {
    opacity: 1,
    transform: 'scaleY(1)'
  },
  section: {
    marginBottom: 11,
    display: 'flex',
    boxSizing: 'border-box',
    '&:last-child': {
      borderBottom: 0,
      marginBottom: 0
    }
  },
  sectionLabel: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.2,
    color: '#657274',
    alignSelf: 'center',
    flex: 1,
  },
  sectionItem: {
    width: '100%',
    flex: 8,
  },
  checkboxes: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
    padding: '4px 6px',
    '& > div': {
      marginRight: 25,
      '&:last-child': {
        margin: 0
      }
    }
  },
  sectionContentDateRange: {
    display: 'flex',
    flexDirection: 'row'
  },
  dateRange: {
    width: '100%',
    marginRight: 24,
    '&:first-child': {
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 5,
        height: 1,
        backgroundColor: '#657274',
        top: '50%',
        left: 'calc(100% + 9px)',
      },
      '&:last-child:after': {
        content: ''
      }
    },
    '&:last-child': {
      marginRight: 0,
    }
  },
  sectionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #d9e4e8',
    padding: '15px',
  },
  orDivider: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#657274',
    marginBottom: 10
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  action: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.67,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: 'color .1s ease',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  applyButton: {
    height: 'initial',
    padding: '0px 11px',
    minWidth: 'unset',
    fontWeight: 300,
    lineHeight: 2,
    marginLeft: 14
  },
  fullWidth: {
    width: '100%',
  },
  footer: {
    padding: '12px 15px'
  },
  sectionItemOverflow: {
    overflow: 'hidden'
  },
  filterButton: {
    marginRight: 10,
  },
  closeIcon: {
    flex: 1,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.2,
    color: '#657274',
    alignSelf: 'center',
    textAlign: 'end',
    transform: 'scale(0.75)',
  },
  activeCloseIcon: {
    color: '#4192ec',
    cursor: 'pointer',
  },
  scaleDown: {
    transform: 'scale(0.9)',
  }
})



interface IProps extends WithStyles<typeof styles> {
  tableType: TableType;
  label?: string;
  hidden?: boolean;
  onApply: () => any;
}


const CustomActivityFilters = (props: IProps) => {
  const { classes } = props
  const menuVisibility = useOpen();
  const dispatch = useDispatch();
  const filters = useSelector((state: any) => state.list[props.tableType].filters);
  const types = useSelector((state: any) => state.types.activity_types);
  let dateToRef: HTMLInputElement;





  const getTagLabel = (person: any): any => {
    return person.emails
      ? <span>{getUsername(person)} (<strong style={{ fontWeight: 600 }}>{(person.emails && person.emails[0]) || ''}</strong>)</span>
      : <span>{getUsername(person)}</span>
  }



  const getSortValue = (person: any): string => {
    return `${getUsername(person)}`
  }



  const onApply = (): void => {
    props.onApply();
    menuVisibility.close()
  }



  const onReset = (): void => {
    dispatchSetFilters({});
    menuVisibility.close()
  }



  const clearTypes = (): void => {
    dispatchSetFilters({
      ...filters,
      types: undefined,
      typesIds: undefined
    })
  }



  const clearUser = (): void => {
    dispatchSetOneFilter('user', undefined);
  }



  const clearDates = (): void => {
    dispatchSetFilters({
      ...filters,
      dateFrom: undefined,
      dateTo: undefined
    })
  }



  const onSetTypes = (newTypes: any[]): void => {
    dispatchSetFilters({
      ...filters,
      types: newTypes,
      typesIds: getIds(newTypes)
    })
  }



  const onSetUser = (user: IDialogMenuItem): void => {
    dispatchSetOneFilter('user', user)
  }



  const onSetDateFrom = (value: string): void => {
    dispatchSetOneFilter('dateFrom', value);
    dateToRef?.focus()
  }



  const onSetDateTo = (value: string): void => {
    dispatchSetOneFilter('dateTo', value)
  }





  const dispatchSetOneFilter = (name: string, value: any): any => {
    return dispatch(setOneFilter(props.tableType, name, value))
  }



  const dispatchSetFilters = (newFilters: any): any => {
    return dispatch(setFilters(props.tableType, newFilters))
  }






  if (props.hidden) return null





  return (
    <div className={classes.root}>

      <Button
        primary
        onClick={!menuVisibility.value ? menuVisibility.open : menuVisibility.close}
        size={ButtonSize.Small}
        classes={{
          root: classes.filterButton,
        }}
      >
        <FunnelIcon viewBox="0 -3.5 18 21" className={classes.scaleDown} />
        Filter Activity
        <ArrowDown className={classNames(
          classes.icon,
          { [classes.iconOpened]: menuVisibility.value }
        )} />
      </Button>


      <Paper className={classNames(
        classes.paper,
        { [classes.paperOpened]: menuVisibility.value }
      )}>

        <div className={classes.sectionsWrapper}>

          <div className={classes.section}>
            <span className={classes.sectionLabel}><TypeIcon /></span>
            <div className={classNames(
              classes.sectionItem,
              // { [classes.sectionItemOverflow]: !!filters.types }
            )}>
              <Select
                selectFirstAsDefault
                value={filters.types}
                onSelect={onSetTypes}
                valuesList={types}
                // grouped={'category'}
                // groupedValuesList={getSelectType('categories')}
                checkboxes
              />
            </div>

            <span
              className={classNames(
                classes.closeIcon,
                { [classes.activeCloseIcon]: !!filters.types }
              )}
              onClick={clearTypes}
            ><CloseIcon /></span>
          </div>

          <div className={classes.section}>
            <span className={classes.sectionLabel}><PersonIcon /></span>
            <div className={classNames(
              classes.sectionItem,
              { [classes.sectionItemOverflow]: !!filters.user }
            )}>
              <InputAutocompletePersonField fullWidth onlyOne personTheme
                placeholder="User"
                value={filters.user}
                onChange={onSetUser}
                searchAction={(search) => searchPeoplePromise(search, undefined, true)}
                getValue={getTagLabel}
                getSortValue={getSortValue}
                disabled={!!filters.user}
              />
            </div>

            <span
              className={classNames(
                classes.closeIcon,
                { [classes.activeCloseIcon]: !!filters.user }
              )}
              onClick={clearUser}
            ><CloseIcon /></span>
          </div>

          <div className={classes.section}>
            <span className={classes.sectionLabel}><CalendarIcon className={classes.scaleDown} /></span>
            <div className={classNames(classes.sectionItem, classes.sectionContentDateRange)}>
              <div className={classes.dateRange}>
                <DateInput
                  value={`${filters.dateFrom ? new Date(filters.dateFrom) : ''}`}
                  // className={classes.dateRange}
                  placeholder="Select Date"
                  formatToShow={DateFormat.DateShort}
                  onChange={onSetDateFrom}
                  maxDate={new Date()}
                />
              </div>

              <div className={classes.dateRange}>
                <DateInput
                  onRef={(ref: any) => dateToRef = ref}
                  value={`${filters.dateTo ? new Date(filters.dateTo) : ''}`}
                  // className={classes.dateRange}
                  placeholder="Select Date"
                  formatToShow={DateFormat.DateShort}
                  onChange={onSetDateTo}
                  minDate={filters.dateFrom ? new Date(filters.dateFrom) : undefined}
                  maxDate={new Date()}
                />
              </div>
            </div>

            <span
              className={classNames(
                classes.closeIcon,
                { [classes.activeCloseIcon]: !!filters.dateFrom || !!filters.dateTo }
              )}
              onClick={clearDates}
            ><CloseIcon /></span>
          </div>

        </div>


        <div className={classes.footer}>
          <div className={classes.actions}>
            <span className={classes.action} onClick={onReset}>Reset filters</span>
            <Button primary className={classes.applyButton} onClick={onApply}>Apply</Button>
          </div>
        </div>



      </Paper>
    </div >
  )
}


export default withStyles(styles)(CustomActivityFilters)