import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React from "react";
import { ITeamMember } from "../shared/models/Project";
import { MembershipType } from "../shared/types";
import { Theme } from "../theme";
import DataCard from "./DataCard";
import SearchInput from "./SearchInput";

const styles = (theme: Theme) => createStyles({
  cardRoot: {

  },
  card: {

  },
  team: {

  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subHeader: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.18,
    letterSpacing: 0.53,
    textTransform: 'uppercase'
  },
});

interface IProps extends WithStyles<typeof styles> {
  team: ITeamMember[];
  projectId: number;
}

const TeamCard = (props: IProps) => {
  const { classes } = props;

  return (
    <DataCard
      header={
        <div className={classes.header}>
          <span>Team</span>
        </div>
      }
      classes={{ root: classes.cardRoot, card: classes.card }}
    >
      <div className={classes.team}>
        <SearchInput noLink
          header="ASSIGN TO PROJECT"
          placeholder="Type Name"
          list={props.team}
          type={MembershipType.Team}
        />
      </div>

    </DataCard>
  );
}


export default withStyles(styles)(TeamCard)