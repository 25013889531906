import React, { useMemo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import PersonWithAvatar from '../../PersonWithAvatar';
import { useTaskData } from './hooks/tasks-hooks';
import { useSelector } from 'react-redux';


interface IProps {
  isNew?: boolean;
  className?: string;
  headerClassName?: string;
}

const TaskAddedBy = ({ isNew, className, headerClassName }: IProps) => {
  const { value } = useTaskData('created_by_details');
  const { value: createdBy } = useTaskData('created_by');
  const user = useSelector((state: any) => state.user.user);
  const person = useMemo(() => isNew ? user : value, [isNew, user, value]);




  return useMemo(() => {
    if (!isNew && !createdBy) return null;

    return (
      <div className={className}>
        <div className={headerClassName}>
          <h2>Added By</h2>
        </div>

        <PersonWithAvatar readonly person={person} />
      </div>
    )
  }, [person, createdBy, isNew])
}


export default TaskAddedBy