import { useMemo } from "react";
import { groupBy } from "../../../../utils/utils";
import { useSelector } from "react-redux";



export const useTrackerSummary = (fixed: boolean = false): any => {
  const { filteredProviders, providers: allProviders } = useSelector((state: any) => state.projectProviders)



  return useMemo(() => {
    const newProviders = fixed ? allProviders : filteredProviders;
    const providers = newProviders.map((item: any) => ({...item, company: item.person.company}))
    let grouped = groupBy(providers, 'company');
    let sortedGrouped = Object.values(grouped).map((values: any) => {
      return values.sort((a: any, b: any) => b.state - a.state)[0]
    })
  
    grouped = groupBy(sortedGrouped, 'state');
    return Object.fromEntries(Object.entries(grouped).map(([company, values]: any) => {
      return [company, values.length]
    }))
  }, [filteredProviders, allProviders, fixed]);
}