import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const EditIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h20v20H0z"/>
        <path fill="#4192EC" fillRule="nonzero" d="M4.167 13.403v2.43h2.43l7.167-7.167-2.43-2.43-7.167 7.167zm11.477-6.617a.645.645 0 0 0 0-.913l-1.517-1.517a.645.645 0 0 0-.913 0l-1.186 1.186 2.43 2.43 1.186-1.186z"/>
      </g>
    </SvgIcon>
  );
};