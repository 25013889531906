import React, { useMemo, useCallback, useEffect } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Select from '../../Select';
import { useDispatch, useSelector } from 'react-redux';
import { getConditions } from '../../../shared/models/Search';
import { changeSearchRow, changeSearchSubRow } from '../../../actions/advanced-search';

const styles = (theme: Theme) => createStyles({
  root: {
    minWidth: 155,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
}

const SearchSelectCondition = (props: IProps) => {
  const { classes, idx, subRowIdx } = props;
  const dispatch = useDispatch();
  const row = useSelector((state: any) => state.advancedSearch.rows[idx]);
  const subRow = useSelector((state: any) => subRowIdx !== undefined ? state.advancedSearch.rows[idx].subRows?.[subRowIdx] : undefined);
  const options = useMemo(() => getConditions(subRow?.field?.type ?? row.field?.type), [subRow?.field?.type, row.field?.type]);
  const currentRow = subRow ?? row;



  const value = useMemo(() => {
    if(!currentRow?.condition) return;

    const conditions = getConditions(currentRow.field.type);
    return conditions.find((type: any) => type.id === currentRow.condition.id);
  }, [options, currentRow])




  
  const onSelect = useCallback((option: any): any => {
    return subRowIdx === undefined
      ? dispatch(changeSearchRow(idx, { condition: option }))
      : dispatch(changeSearchSubRow(subRowIdx, idx, { condition: option }))
  }, [idx, subRowIdx])




  useEffect(() => {
    if(!!currentRow?.condition && !currentRow?.condition?.format) {
      onSelect(value)
    }
  }, [value, currentRow, onSelect])





  return useMemo(() => {
    if(!currentRow.field) return null;

    return (
      <Select
        className={classes.root}
        placeholder="Select Condition"
        value={value}
        valuesList={options}
        onSelect={onSelect}
      />
    )
  }, [value, currentRow])
}


export default withStyles(styles)(SearchSelectCondition)