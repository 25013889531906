import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useShowAll } from "../shared/hooks";
import { mockProjects } from "../shared/mocks";
import { IProject, ProjectStatus } from "../shared/models/Project";
import { Theme } from "../theme";
import { arrayRange } from "../utils/utils";
import DataCard from "./DataCard";
import EmptyMail, { EmptyIcon } from "./EmptyMail";
import Filters from "./Filters";
import Projects from "./Projects";
import ShowAll from "./ShowAll";

const styles = (theme: Theme) => createStyles({
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
});

interface IProps extends WithStyles<typeof styles> {
  memberships?: any[];
  providers?: any[];
}

const ProjectCard = (props: IProps) => {
  const { classes, providers, memberships } = props;
  const showAll = useShowAll();
  const types = useSelector((state: any) => state.types);
  const { project_memberships, provider_relations } = useSelector((state: any) => state.people.person);
  const isCP = !!providers?.length;
  const hasProjects = !!memberships?.length || !!providers?.length;
  const [projects, setProjects] = useState<any[] | undefined>(isCP ? providers : memberships);
  const [filter, setFilter] = useState<any>(ProjectStatus.Analysing);





  const filtersOptions = types.project_status_types.map((project_status) => {
    return {
      label: project_status.name,
      type: project_status.id,
      onClick: () => setFilter(project_status.id)
    }
  })



  const filterProjects = (projectRelArr?: any[]) => {
    if (!projectRelArr || !projectRelArr.length) return [];
    return projectRelArr
      .filter((rel: any) => rel.project.status === filter)
      .map((rel: any) => {
        return {
          ...rel.project,
          label: rel.state
        }
      })
  }



  const getChunkOfArray = (arr: any[]): any[] => {
    return showAll.value ? arr : arrayRange(0, 4, arr)
  }



  const setNewProjects = () => {
    if (projects) {

      const filteredProjects = filterProjects(isCP ? providers : memberships);
      return setProjects(getChunkOfArray(filteredProjects))
    }

    return setProjects(undefined)
  }



  const header = useMemo(() => (
    <div className={classes.header}>
      <span>Projects</span>
      {hasProjects && <Filters options={filtersOptions} selected={filter} />}
    </div>
  ), [hasProjects, filter, filtersOptions])





  useEffect(() => {
    setNewProjects();
  }, [showAll.value, filter])






  return (
    <DataCard header={header}>
      <div>
        {!!projects?.length
          ? <Projects projects={projects} is_cap_provider={isCP} />
          : <EmptyMail icon={EmptyIcon.Projects} text="No projects yet." />
        }

      </div>

      {!!projects?.length && projects.length > 4 && (
        <ShowAll showAll={showAll} count={projects.length} />
      )}
    </DataCard>
  );
}



export default withStyles(styles)(ProjectCard)