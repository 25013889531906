import membershipService from "../../services/people/membership";
import { setError } from "../common/notifications";
import { getErrorCode } from "../../services/helpers/helpers";
import { TeamMembershipType } from "../../shared/types";
import { listToMemberships } from "../helpers/helpers";

/**
 * Actions
 */

export const PEOPLE_SET_MEMBERS               = "PEOPLE_SET_MEMBERS";
export const PEOPLE_UPDATE_MEMBER             = "PEOPLE_UPDATE_MEMBER";
export const PEOPLE_ADD_MEMBER                = "PEOPLE_ADD_MEMBER";
export const PEOPLE_REMOVE_MEMBER             = "PEOPLE_REMOVE_MEMBER";
export const PEOPLE_CLEAR_MEMBERS             = "PEOPLE_CLEAR_MEMBERS";
export const PEOPLE_REMOVE_PREASSIGNED_MEMBER = "PEOPLE_REMOVE_PREASSIGNED_MEMBER";
export const PEOPLE_UPDATE_MEMBER_BY_PERSON   = "PEOPLE_UPDATE_MEMBER_BY_PERSON";
export const PEOPLE_PREASSIGN_MEMBER          = "PEOPLE_PREASSIGN_MEMBER";
export const PEOPLE_ASSIGN_MANDATORY_MEMBERS  = "PEOPLE_ASSIGN_MANDATORY_MEMBERS";


/**
 * Action creators
 */

export function setMembers(members: any[]): object {
  return {type: PEOPLE_SET_MEMBERS, members};
}

export function changeMember(id: number, state: number): object {
  return {type: PEOPLE_UPDATE_MEMBER, id, state};
}

export function changeMemberByPerson(personId: number, state: number): object {
  return {type: PEOPLE_UPDATE_MEMBER_BY_PERSON, personId, state};
}

export function addSingleMember(newMember: any): object {
  return {type: PEOPLE_ADD_MEMBER, newMember};
}

export function removeSingleMember(memberId: number) : object {
  return {type: PEOPLE_REMOVE_MEMBER, memberId}
}

export function clearMembers() : object {
  return {type: PEOPLE_CLEAR_MEMBERS}
}

export function removePreAssignedMember(personId: number) : object {
  return {type: PEOPLE_REMOVE_PREASSIGNED_MEMBER, personId}
}

export function preAssignMember(member: any) : object {
  return {type: PEOPLE_PREASSIGN_MEMBER, member}
}

export function assignMandatoryMembers(members: any[]): object {
  return {type: PEOPLE_ASSIGN_MANDATORY_MEMBERS, members}
}



/**
 * Async action creators
 */

export const changePersmissions = (relationId: number, state: TeamMembershipType) => {
  return (dispatch) => {
    return membershipService.updateMember(relationId, {state})
      .then((res: any) => {
        dispatch(changeMember(res.id, res.state))
      })
      .catch(err => {
        dispatch(setError("Couldn't update Team Member", err))
      })
  }
}

export const addMember = (body: object) => {
  return (dispatch) => {
    return membershipService.addMember(body)
      .then((res: any) => {
        dispatch(addSingleMember(res))
      })
      .catch(err => {
        dispatch(setError("Couldn't add Team Member", err))
      })
  }
}

export const removeMember = (memberId: number) => {
  return (dispatch) => {
    return membershipService.removeMember(memberId)
      .then((res: any) => {
        dispatch(removeSingleMember(memberId))
      })
      .catch(err => {
        dispatch(setError("Couldn't remove Team Member", err))
      })
  }
}


export const getMandatoryOwners = () => {
  return (dispatch) => {
    return membershipService.getMandatoryOwners()
      .then((res: any) => {
        const response = listToMemberships(res.results);
        dispatch(assignMandatoryMembers(response))
      })
  }
}