import { Paper } from '@material-ui/core';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { searchPeoplePromise } from '../actions/people/people';
import { useOpen } from '../shared/hooks';
import { IDialogMenuItem } from '../shared/interfaces';
import { DateFormat, EmailFilterOption } from '../shared/types';
import { Theme } from '../theme';
import { getUsername } from '../utils/user';
import { getDateFormat } from '../utils/utils';
import Button from './Button';
import CheckboxWithLabel from './CheckboxWithLabel';
import DateInput from './DateInput';
import InputAutocompletePersonField from './forms/meta/fields/InputAutocompletePersonField';
import TextInput from './TextInput';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../actions/email';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  option: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#657274',
    cursor: 'pointer',
    minHeight: 'initial',
    '&:hover': {
      color: '#4192ec',
      backgroundColor: 'transparent'
    },
  },
  optionSelected: {
    color: '#4192ec',
    backgroundColor: 'transparent !important'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
      color: theme.palette.primary.main
    }
  },
  icon: {
    color: '#4192ec',
    width: 20,
    height: 20,
    transition: 'transform .1s ease'
  },
  iconOpened: {
    transform: 'rotate(180deg)'
  },
  paper: {
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    borderRadius: 0,
    position: 'absolute',
    right: 0,
    top: 32,
    zIndex: 100,
    width: '100%',
    transition: 'transform .1s ease',
    transformOrigin: 'top center',
    opacity: 0,
    transform: 'scaleY(0)',
    boxSizing: 'border-box'
  },
  paperOpened: {
    opacity: 1,
    transform: 'scaleY(1)'
  },
  section: {
    marginBottom: 11,
    display: 'flex',
    boxSizing: 'border-box',
    '&:last-child': {
      borderBottom: 0,
      marginBottom: 0
    }
  },
  sectionLabel: {
    fontFamily: 'Montserrat',
    width: 200,
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.2,
    color: '#657274',
    alignSelf: 'center',
    flex: 3
  },
  sectionItem: {
    width: '100%',
    flex: 8,
  },
  checkboxes: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
    padding: '4px 6px',
    '& > div': {
      marginRight: 25,
      '&:last-child': {
        margin: 0
      }
    }
  },
  sectionContentDateRange: {
    display: 'flex',
    flexDirection: 'row'
  },
  dateRange: {
    width: '100%',
    marginRight: 24,
    '&:first-child': {
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 5,
        height: 1,
        backgroundColor: '#657274',
        top: '50%',
        left: 'calc(100% + 9px)',
      },
      '&:last-child:after': {
        content: ''
      }
    },
    '&:last-child': {
      marginRight: 0,
    }
  },
  sectionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #d9e4e8',
    padding: '15px 15px 38px',
  },
  orDivider: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#657274',
    marginBottom: 10
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  action: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.67,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: 'color .1s ease',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  applyButton: {
    height: 'initial',
    padding: '0px 11px',
    minWidth: 'unset',
    fontWeight: 300,
    lineHeight: 2,
    marginLeft: 14
  },
  fullWidth: {
    width: '100%',
  },
  footer: {
    padding: '12px 15px'
  },
  sectionItemOverflow: {
    overflow: 'hidden'
  }
})




interface IProps extends WithStyles<typeof styles> {
  label?: string;
  hidden?: boolean;
  onReset: () => any;
  onApply: (selectedOption?: EmailFilterOption) => any;
}

const CustomMailFilters = (props: IProps) => {
  const { classes } = props;
  const menuVisibility = useOpen();
  const dispatch = useDispatch();
  const { sender, receiver, search, date_from, date_to } = useSelector((state: any) => state.emails.filters);
  const [selectedOption, setSelectedOption] = useState<EmailFilterOption|undefined>(EmailFilterOption.ALL_TIME);
  let dateToRef: HTMLInputElement;


  const predefinedOptions = [
    { id: EmailFilterOption.ALL_TIME, label: 'All Time' },
    { id: EmailFilterOption.LAST_30_DAYS, label: '30 days' }
  ]





  const onSenderChange = (value: IDialogMenuItem): void => {
    changeFilters({ sender: value })
  }



  const onReceiverChange = (value: IDialogMenuItem): void => {
    changeFilters({ receiver: value })
  }



  const onKeywordsChange = (value: string): void => {
    changeFilters({ search: value })
  }



  const onDateFromChange = (value: string): void => {
    changeFilters({ date_from: value });
    dateToRef?.focus()
  }



  const onDateToChange = (value: string): void => {
    changeFilters({ date_to: value })
  }



  const onPredefinedOptionClick = (option: any): void => {
    clearDateFilters();
    setSelectedOption(option.id)
  }



  const clearFilters = (): void => {
    changeFilters({
      sender: undefined,
      receiver: undefined,
      search: undefined,
      date_from: undefined,
      date_to: undefined,
    })
  }



  const clearDateFilters = (): void => {
    changeFilters({
      date_from: undefined,
      date_to: undefined
    })
  }



  const getTagLabel = (person: any): any => {
    return person.emails
      ? <span>{getUsername(person)} (<strong style={{ fontWeight: 600 }}>{(person.emails && person.emails[0]) || ''}</strong>)</span>
      : <span>{getUsername(person)}</span>
  }



  const getSortValue = (person: any): string => {
    return `${getUsername(person)}`
  }



  const onApply = (): void => {
    props.onApply?.(selectedOption);

    menuVisibility.close()
  }



  const onReset = (): void => {
    clearFilters();
    props.onReset && props.onReset()
    menuVisibility.close();
  }



  const changeFilters = (data: any): void => {
    dispatch(setFilters(data))
  }







  useEffect(() => {
    if(!!date_from || !!date_to) {
      setSelectedOption(undefined)
    }


    if(!selectedOption && !date_from && !date_to) {
      setSelectedOption(EmailFilterOption.ALL_TIME)
      return;
    }


    if((!!date_to && (new Date(date_to))) < (!!date_from && (new Date(date_from)))) {
      changeFilters({ date_to: undefined })
    }

  }, [date_from, date_to, selectedOption])






  if(props.hidden) return null;




  

  return (
    <div className={classes.root}>
      <div className={classes.label} onClick={menuVisibility.toggle}>
        <span>{props.label}</span>
        <ArrowDown className={classNames(
          classes.icon,
          { [classes.iconOpened]: menuVisibility.value }
        )} />
      </div>


      <Paper className={classNames(
        classes.paper,
        { [classes.paperOpened]: menuVisibility.value }
      )}>

        <div className={classes.sectionsWrapper}>

          <div className={classes.section}>
            <span className={classes.sectionLabel}>From:</span>
            <div className={classNames(
              classes.sectionItem,
              {[classes.sectionItemOverflow]: !!sender}
            )}>
              <InputAutocompletePersonField fullWidth onlyOne personTheme
                placeholder="Type Name"
                value={sender}
                onChange={onSenderChange}
                searchAction={searchPeoplePromise}
                getValue={getTagLabel}
                getSortValue={getSortValue}
                disabled={!!receiver}
              />
            </div>
          </div>

          <div className={classes.section}>
            <span className={classes.sectionLabel}>To:</span>
            <div className={classNames(
              classes.sectionItem,
              {[classes.sectionItemOverflow]: !!receiver}
            )}>
              <InputAutocompletePersonField fullWidth onlyOne personTheme
                placeholder="Type Name"
                value={receiver}
                onChange={onReceiverChange}
                searchAction={searchPeoplePromise}
                getValue={getTagLabel}
                getSortValue={getSortValue}
                disabled={!!sender}
              />
            </div>
          </div>

          <div className={classes.section}>
            <span className={classes.sectionLabel}>Contains keyword(s):</span>
            <div className={classes.sectionItem}>
              <TextInput
                placeholder="Type Keyword"
                value={search}
                className={classes.fullWidth}
                onChange={onKeywordsChange}
              />
            </div>
            
          </div>

          <div className={classes.section}>
            <span className={classes.sectionLabel}>Time Period:</span>
            <div className={classes.sectionItem}>

              <div className={classes.checkboxes}>
                {predefinedOptions.map((option: any) => (
                  <CheckboxWithLabel key={option.id}
                    label={option.label}
                    checked={option?.id === selectedOption}
                    onChange={e => onPredefinedOptionClick(option)}
                  />
                ))}
              </div>

            </div>
            
          </div>

          <span className={classes.orDivider}>-OR-</span>


          <div className={classes.section}>
            <span className={classes.sectionLabel}>Custom Date Range:</span>
            <div className={classNames(classes.sectionItem, classes.sectionContentDateRange)}>
              <DateInput
                value={`${date_from ? new Date(date_from) : ''}`}
                className={classes.dateRange}
                placeholder="Select Date"
                formatToShow={DateFormat.DateShort}
                onChange={onDateFromChange}
                maxDate={new Date()}
              />
              <DateInput
                onRef={(ref: any) => dateToRef = ref}
                value={`${date_to ? new Date(date_to) : ''}`}
                className={classes.dateRange}
                placeholder="Select Date"
                formatToShow={DateFormat.DateShort}
                onChange={onDateToChange}
                minDate={date_from ? new Date(date_from) : undefined}
                maxDate={new Date()}
              />
            </div>
          </div>

        </div>


        <div className={classes.footer}>
          {(props.onReset && props.onApply) && (
            <div className={classes.actions}>
              <span className={classes.action} onClick={onReset}>Reset filters</span>
              <Button primary className={classes.applyButton} onClick={onApply}>Apply</Button>
            </div>
          )}
        </div>

        

      </Paper>
    </div >
  )
}


export default withStyles(styles)(CustomMailFilters)