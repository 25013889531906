import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { Theme } from '../theme';
import Button from './Button';
import PopoverDialog from './PopoverDialog';
import TextInput from './TextInput';

const styles = (theme: Theme) => createStyles({
  root: {
    width: 295
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    color: '#657274',
    marginBottom: 7,
    lineHeight: 1.5
  },
  button: {
    width: 50,
    height: 'initial',
    marginTop: 10,
    padding: '8px 0',
    minWidth: 'unset',
  },
})

interface IProps extends WithStyles<typeof styles> {
  visibilityControls: any;
  onSave: (value: string, e: any) => any;
}

const SaveSearchDialog = (props: IProps) => {
  const { classes, visibilityControls } = props;
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);


  const onSubmit = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    if(!value) return;

    props.onSave(value, e);
    setValue('')
  }



  useEffect(() => {
    setError(!value)
  }, [value])


  useEffect(() => {
    setError(false)
  }, [])




  return (
    <PopoverDialog className={classes.root}
      opened={visibilityControls.value}
      onClose={visibilityControls.close}
    >

      <form onSubmit={onSubmit}>
        <span className={classes.title}>
          Save search as:
        </span>

        <TextInput
          value={value}
          onChange={setValue}
          error={error}
        />

        <Button primary type="submit" className={classes.button}>
          Save
        </Button>
      </form>


    </PopoverDialog>
  )
}


export default withStyles(styles)(SaveSearchDialog)