import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { advancedSearchActivity } from '../../actions/activity';
import { setLoadedPage } from '../../actions/common/loading';
import { getTypes } from '../../actions/types';
import List from '../../components/list/List';
import PrintHeader from '../../components/print/PrintHeader';
import { ROUTE_PRINT } from '../../constants/routes';
import { IPrintDataDescription } from '../../shared/interfaces';
import { SearchTypeName } from '../../shared/models/Search';
import { SearchType, TableTypeFromPrint } from '../../shared/types';
import { Theme } from '../../theme';
import { isAdmin, isUser } from '../../utils/user';
import { advancedSearchNext, advancedSearch } from '../../actions/advanced-search';
import TrackerHeader from '../../components/project/tracker/TrackerHeader';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#fff',
  },

  content: {
    padding: 20,
  },
  listDescriptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat',
    fontSize: 11,
    lineHeight: 1.45,
    color: '#657274',
    '& span': {
      marginRight: 3,
      '& > strong': {
        fontWeight: 600
      }
    },
  },
  listDescription: {
    display: 'block'
  },
  summaryStatuses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    margin: '20px 0',
    border: '1px solid #d9e4e8',
    boxShadow: 'none',
    padding: '12px 0',
  },
  summaryStatusItem: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #d9e4e8',
    justifyContent: 'center',
    padding: '1px 0',
    '&:last-child': {
      borderRight: 0
    }
  },
  summaryStatusItemDisabled: {
    '& $summaryStatusItemNumber': {
      color: '#90a0a7 !important'
    },
    '& $summaryStatusItemLabel': {
      color: '#90a0a7 !important'
    }
  },
  summaryStatusItemNumber: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.11,
    color: '#657274',
    marginRight: 8,
    backgroundColor: 'transparent !important'
  },
  summaryStatusItemLabel: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 2.18,
    letterSpacing: 0.53,
    color: '#657274',
    textTransform: 'uppercase'
  },
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  type: SearchType;
  typePath: string;
}

const PrintContainer = (props: IProps) => {
  const { classes, type } = props;
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.loading);
  const printData = useSelector((state: any) => state.print);
  const user = useSelector((state: any) => state.user.user);
  const [rows, setRows] = useState<any[]>([]);
  const { title, description, descriptionCaption, stats, list, listGroupBy, requestURL, groupsCollapsed } = printData.data;





  const hasFullAccess = (): boolean => {
    return isAdmin(user) || isUser(user)
  }

  const getTitle = (): string => {
    return title || SearchTypeName[type]
  }

  const constructBody = (): string => {
    if (!props.location.search) return '?limit=10000';

    return props.location.search === '?'
      ? `?limit=10000`
      : `${props.location.search}&limit=10000`
  }


  const onInit = (): any => {
    dispatch(getTypes());


    const body = constructBody();
    switch (type) {
      case SearchType.People:
        return dispatchSearchPeople(body)
          .then((res: any) => {
            dispatch(setLoadedPage(ROUTE_PRINT));
            setRows(res.results)
          })
      case SearchType.Projects:
        return dispatchSearchProjects(body)
          .then((res: any) => {
            dispatch(setLoadedPage(ROUTE_PRINT));
            setRows(res.results)
          })
      case SearchType.Tasks:
        return dispatchSearchTasks(body)
          .then((res: any) => {
            dispatch(setLoadedPage(ROUTE_PRINT));
            setRows(res.results)
          })
      case SearchType.Activity:
        return dispatchSearchActivity(body)
          .then((res: any) => {
            dispatch(setLoadedPage(ROUTE_PRINT));
            setRows(res.results)
          })
    }
  }

  // TODO
  const dispatchSearchPeople = (body: string): any => {
    return dispatch(advancedSearch(SearchType.People, [], body))
  }

  const dispatchSearchProjects = (body: string): any => {
    return dispatch(advancedSearch(SearchType.Projects, [], body))
  }

  const dispatchSearchTasks = (body: string): any => {
    return dispatch(advancedSearch(SearchType.Tasks, [], body))
  }

  const dispatchSearchActivity = (body: string): any => {
    return dispatch(advancedSearchActivity(body))
  }

  const isLoading = (): boolean => {
    return !loading.pagesLoaded[ROUTE_PRINT]
  }


  useEffect(() => {
    onInit();
  }, [])


  return (
    <div className={classes.root}>
      <PrintHeader title={getTitle()} />



      <div className={classNames(classes.content, "print-no-padding")}>

        <div className={classes.listDescriptionWrapper}>
          <span className={classes.listDescription}>
            {description && description.map((item: IPrintDataDescription, idx: number) => (
              <span key={idx}><strong>{item.title}</strong>{item.value}</span>
            ))}
          </span>
          {descriptionCaption && (
            <span><strong>{descriptionCaption.title}</strong>{descriptionCaption.value}</span>
          )}
        </div>


        {!!stats && (
          <TrackerHeader summary={stats} />
        )}

        <List
          initialLoading={requestURL ? isLoading() : false}
          rows={requestURL ? rows : list}
          tableType={TableTypeFromPrint[printData.type]}
          groupBy={listGroupBy}
          groupsCollapsed={groupsCollapsed}
          fullAccess={hasFullAccess()}
        />


      </div>


    </div>
  )
}


export default withStyles(styles)(
  withRouter(PrintContainer)
)