import React, { createRef, Component, ChangeEvent } from "react";

interface IProps {
  onFilesSelected: Function;
  onInit: any;
}

const FileHandler = (props: IProps) => {

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (!target.files || !target.files || !target.files[0]) {
      console.log('Please choose a file.');
      return;
    }
    
    props.onFilesSelected(target.files);
    target.value = '';
  }

  
  return (
    <input
      multiple
      type="file"
      style={{display:"none"}}
      ref={props.onInit}
      onChange={handleChange}
    />
  );
}

export default FileHandler