import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Popover } from '@material-ui/core';
import { IPopoverTipAction } from '../shared/interfaces';

const styles = (theme: Theme) => createStyles({
  root: {

  },
  paper: {
    display: 'flex',
    boxShadow: '0 0 10px 0 rgba(189, 194, 196, 0.5)',
    zIndex: 1000,
    overflow: 'unset',
    '&:after': {
      width: 0,
      height: 0,
      content: '""',
      borderTop: '6px solid #fff',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      bottom: 0,
      left: '50%',
      top: 'calc(100% - 1px)',
      position: 'absolute',
      transform: 'translate(-50%, 0)',
    },
    '&:before': {
      content: '""',
      height: 10,
      top: '50%',
      left: '50%',
      width: 1,
      position: 'absolute',
      transform: 'translate(calc(-50% + 1px), -50%)',
      backgroundColor: '#dae4e8',
    }
  },
  option: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.82,
    color: theme.palette.primary.main,
    padding: '7px 15px',
    cursor: 'pointer',
    transition: 'background .1s ease',
    '&:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '&:hover': {
      background: 'linear-gradient(180deg, rgba(232,242,253,1) 0%, rgba(255,255,255,1) 100%)',
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  anchor: any;
  actions: IPopoverTipAction[];
}

const PopoverTooltip = (props: IProps) => {
  const { classes, anchor } = props;



  return (
    <Popover disableRestoreFocus
      className={classes.root}
      classes={{ paper: classes.paper }}
      open={!!anchor.value}
      anchorEl={anchor.value}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={anchor.close}
    >
      {props.actions.map((action: IPopoverTipAction, idx: number) => (
        <span key={idx} className={classes.option} onClick={action.onClick}>
          {action.label}
        </span>
      ))}
    </Popover>
  )
}


export default withStyles(styles)(PopoverTooltip)