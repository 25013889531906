// import './wdyr';

import "flexboxgrid/css/flexboxgrid.css";
import "font-awesome/css/font-awesome.css";
import React from "react";
import { render } from "react-dom";
import 'typeface-montserrat';
import "./assets/css/fonts.css";
import "./assets/css/styles.css";
import "./assets/css/print.scss";
import './assets/fonts/MaterialIcons-Regular.woff';
import App from './containers/common/App';
import "./favicon.ico";
import store from './middleware/store';


const MainApp = <App store={store} />;

render(MainApp, document.getElementById("root"));