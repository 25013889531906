import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchTasks } from '../actions/tasks';
import { TaskType, TaskTypeNames } from '../shared/types';
import { palette, Theme } from '../theme';
import { groupBy } from '../utils/utils';
import DataCard from './DataCard';
import EmptyMail, { EmptyIcon } from './EmptyMail';
import Filters from './Filters';
const styles = (theme: Theme) => createStyles({
  root: {

  },
  taskCard: {
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  groupContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  groupHeader: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.18,
    letterSpacing: 0.53,
    color: '#233539',
    textTransform: 'uppercase',
  },
  groupWrapper: {
    paddingBottom: 20,
    borderBottom: '1px solid #d9e4e8',
    marginBottom: 18,
    '&:last-child': {
      marginBottom: 0,
      borderBottom: 0,
      paddingBottom: 5
    }
  },
  taskContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0
    }
  },
  taskText: {
    ...theme.typography.body2,
    fontFamily: 'Montserrat',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: '#657274',
    fontSize: 12,
    fontWeight: 'normal',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: palette.skyblue300
    }
  },
  noTaskText: {
    ...theme.typography.body2,
    fontFamily: 'Montserrat',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: '#657274',
    fontSize: 12,
    marginBottom: 16,
    fontWeight: 'normal',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  scored: {
    textDecoration: 'line-through',
    color: '#90a0a7'
  },
  divider: {
    marginTop: '43px'
  }
})

const ALL_PROJECTS_OPTION_ID = 0;

interface IProps extends WithStyles<typeof styles> {
  tasks: any[];
  projects: any[];
  person: any;
  onTaskSearch: (search: string) => any;
}

const TaskCard = (props: IProps) => {
  const { classes } = props;
  const [filter, setFilter] = useState<any>(0);
  const [tasks, setTasks] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);


  const filterTasks = (): void => {
    setTasks({ 
      [ALL_PROJECTS_OPTION_ID]: props.tasks,
      ...groupBy(props.tasks, 'project')
    });
  }

  const filterOptions = (): void => {
    const groupedTasks = groupBy(props.tasks, 'project')
    if(!props.tasks.length) return;

    setOptions([
      {
        label: 'All Projects',
        type: ALL_PROJECTS_OPTION_ID,
        onClick: () => setFilter(ALL_PROJECTS_OPTION_ID)
      },
      ...Object.entries(groupedTasks).map(([projectId, tasks]: any) => ({
        label: tasks[0].project_details.name,
        type: projectId,
        onClick: () => setFilter(projectId)
      }))
    ])
  }


  const onInit = (): any => {
    if(props.person.id) {
      props.onTaskSearch(`?assignees=${props.person.id}`)
    }
  }

  useEffect(() => {
    onInit()
  }, [])

  useEffect(() => {
    filterTasks();
    filterOptions();
  }, [props.tasks])



  const renderGroup = (type: TaskType) => {
    const formatted = tasks && tasks[filter]
      .filter((task: any) => task.state === type)
      .map((task: any) => (
        <div key={task.id} className={classes.taskContainer}>
          <a
            className={classNames(
              classes.taskText,
              { [classes.scored]: type === TaskType.Approved }
            )}
            href={`/task/${task.id}`}
            target="_blank"
          >
            <span>{task.title}</span>
          </a>
        </div>
      ))


    return (
      <div className={classes.groupWrapper}>
        <div className={classes.groupContainer}>
          <div className={classes.groupHeader}>
            {TaskTypeNames[type]}
          </div>
        </div>
        {formatted.length > 0 ?
          formatted
          :
          <div className={classes.taskContainer}>
            <span className={classes.noTaskText}>No tasks currently in this state.</span>
          </div>
        }
      </div>
    )
  }


  const renderTasks = (): any => {
    if(!tasks || !tasks[filter] || !props.tasks.length) return null;

    return (
      <div className={classes.root}>
        <div className={classes.taskCard}>
          {renderGroup(TaskType.ToDo)}
          {renderGroup(TaskType.Submitted)}
          {renderGroup(TaskType.Approved)}
        </div>
      </div>
    )
  }

  const getHeader = (): any => {
    return (
      <div className={classes.header}>
        <div>
          <span>Tasks</span>
        </div>
        {!!tasks && !!props.tasks.length
          ? <Filters options={options} selected={filter}/>
          : null
        }
      </div>
    )
  }


  return (
    <DataCard header={getHeader()}>
      {renderTasks()}

      {!tasks || !tasks[filter].length
        ? (
            <EmptyMail 
              icon={EmptyIcon.Tasks}
              text="No tasks yet."
            />
          )
        : null
      }
    </DataCard>
  )
}


const mapStateToProps = state => {
  const { board, projects, people } = state;
  return {
    tasks: board.tasks.results,
    projects: projects.results,
    person: people.person
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTaskSearch: search => dispatch(searchTasks(search, true))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskCard))