import * as actions from '../actions/types';

export const typesInitialState = {
  title: [],
  legal_status: [],
  capital_providers: [],
  syndications: [],
  client_classes: [],
  categories: [],
  subcategories: [],
  use_class_types: [],
  location_types: [],
  cp_subcategories: [],
  user_file_types: [],
  warehouse_types: [],
  investing_geography_types: [],
  profile_types: [],
  project_types: [],
  transaction_sub_types: [],
  asset_types: [],
  project_file_types: [],
  provider_relation_types: [],
  project_status_types: [],
  transaction_types: [],
  task_states: [],
  default_columns: []
};




export default function(state = typesInitialState, action) {
  switch (action.type) {

    case actions.SET_TYPES:
      return action.types
  
    default:
      return state
  }
}