import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from "classnames";
import React, { Fragment, useEffect, useState, createRef } from "react";
import { Theme } from "../theme";
import { range } from "../utils/utils";
import AddButton from "./AddButton";
import LabelledListItem from "./LabelledItem";
import TextInput from "./TextInput";
import { EMAIL_REGEX } from "../constants/index";
import { ValidationError } from "../shared/types";



const itemStyles = (theme: Theme) => createStyles({
  '@keyframes slideInAnimation': {
    from: {
      opacity: 0,
      transform: 'scaleY(0)',
      maxHeight: 0,
    },
    to: {
      opacity: 1,
      transform: 'scaleY(1)',
      maxHeight: 40,
    }
  },

  slideIn: {
    backgroundColor: (props: any) => props.color,
    animation: theme.transitions.create(['$slideInAnimation']),
    transformOrigin: '50% 0%',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'all .1s ease',
    '&:hover': {
      color: theme.palette.primary.main,
      '& input': {
        color: theme.palette.primary.main,
        cursor: 'pointer',
      }
    }
  }
});

interface IItemProps extends WithStyles<typeof itemStyles> {
  idx: number;
  name: string;
  label: string,
  value: string,
  onChange: (event)=>void,
  readonly?: boolean,
  showAnimation?: boolean,
  errors?: any,
  required?: boolean,
  linkType?: string,
  type?: string;
  onValidationError?: (hasError: boolean) => any;
  onRef?: (ref: any) => any;
}

const Item = withStyles(itemStyles)((props: IItemProps) => {
  const { classes, errors, value, type } = props;



  const hasError = (): any => {
    if(!!value && type === 'email') {
      const regex = new RegExp(EMAIL_REGEX, 'g');
      return { [props.idx]: !regex.test(value) ? [ValidationError[props.name]] : undefined }
    }

    return { [props.idx]: undefined }
  }







  const renderTextInput = (): any => {
    return (
      <TextInput
        onRef={props.onRef}
        value={value}
        readonly={props.readonly}
        onChange={props.onChange}
        type={props.label && props.label.includes('Email') ? 'email' : undefined}
        error={!!errors?.length}
      />
    )
  }



  useEffect(() => {
    if(value === undefined) return;
    props.onValidationError?.(hasError())
  }, [value])

  

  return (
    <Fragment>
      <div className={classNames({[classes.slideIn]: props.showAnimation})}>
        <LabelledListItem label={props.label} required={props.required} readonly={props.readonly} errors={errors}>
          {props.linkType && props.readonly && !!value
            ? (
                <a href={`${props.linkType}${value}`} className={classes.link}>
                  {renderTextInput()}
                </a>
              )
            : renderTextInput()
          }
        </LabelledListItem>
      </div>
    </Fragment>
  )
});






const styles = (theme: Theme) => createStyles({
  '@keyframes slideInAnimation': {
    from: {
      opacity: 0,
      transform: 'scaleY(0)',
      maxHeight: 0,
    },
    to: {
      opacity: 1,
      transform: 'scaleY(1)',
      maxHeight: 20,
    }
  },
  add: {
    display: 'flex',
    animation: theme.transitions.create(['$slideInAnimation']),
  },
  addSpaceFix: {
    flex: 3.8,
    paddingRight: 8
  },
  addButtonWrapper: {
    flex: 6.2
  }
});

interface IProps extends WithStyles<typeof styles> {
  name: string;
  label?: string,
  labels?: string[],
  values: string[],
  readonly?: boolean,
  onChange: Function,
  errors: object;
  required?: boolean,
  linkType?: string,
  spaceAfterIncrementalValue?: boolean;
  alwaysVisibleCount?: number;
  type?: string;
  onValidationError?: (hasError: boolean) => any;
}


const MultiTextInput = (props: IProps) => {
  const { classes, name, values, errors, readonly, label, labels } = props;
  const [fieldCount, setFieldCount] = useState<number>(0);
  const [fieldCountWhenMounted, setFieldCountWhenMounted] = useState<number>(0);
  const REPLACEMENT_VALUE = '(?)';
  const refs: any[] = [];
  


  const reset = (initial?: boolean) => {
    const helper = values.slice()
      // .filter((item: any) => item === 0 || !!item)


    let newFieldCount = props.alwaysVisibleCount && props.alwaysVisibleCount > helper.length
      ? props.alwaysVisibleCount : helper.length;
    if (newFieldCount === 0) newFieldCount = 1;
    setFieldCount(newFieldCount);

    if (initial) {
      setFieldCountWhenMounted(newFieldCount);
    }
  }


  const handleAdd = () => {
    setFieldCount(fieldCount + 1);
  };


  const handleChange = (index: number, value: string) => {
    props.onChange(index, value)
  }


  const getErrors = (key: any) => {
    if(Array.isArray(errors) && !errors?.[0]?.person) {
      return errors
    }

    return errors?.[key]
  }


  const incrementify = (label: string, i: number): string => {
    return label.replace(REPLACEMENT_VALUE, `${i === 0 ? '' : `${i + 1}${(props.spaceAfterIncrementalValue && ' ') || ''}`}`);
  }


  const getLabel = (i: number): string => {
    if(labels) {
      return labels[i] || `${labels[labels.length - 1]} ${i - (labels.length - 2)}`
    }

    if(label) {
      return label.includes(REPLACEMENT_VALUE) ? incrementify(label, i) : label
    }

    return ''
  }




  

  useEffect(() => {
    reset(true)
  }, [])


  useEffect(() => {
    if(props.alwaysVisibleCount && props.alwaysVisibleCount === fieldCount) {
      return;
    }

    if(fieldCount > 1) {
      refs[refs.length - 1]?.focus?.()
    }

  }, [fieldCount])





  return (
    <Fragment>
      {range(fieldCount).map((i: number, idx: number) => !values[i] && readonly ? null : (
        <Item key={i}
          idx={i}
          name={props.name}
          onRef={(ref: any) => refs[idx] = ref}
          required={props.required && !i}
          label={getLabel(i)}
          value={values[i]}
          onChange={(value: string) => handleChange(i, value)}
          readonly={readonly}
          // animate only the newly added inputs
          showAnimation={i >= fieldCountWhenMounted}
          errors={getErrors(i)}
          linkType={props.linkType}
          type={props.type}
          onValidationError={props.onValidationError}
        />
      ))}

      {!readonly && (
        <div className={classes.add}>
          <span className={classes.addSpaceFix}/>
          <span className={classes.addButtonWrapper}>
            <AddButton onClick={handleAdd}/>
          </span>
        </div>
      )}
    </Fragment>
  );
}

export default withStyles(styles)(MultiTextInput)