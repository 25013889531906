import React, { useMemo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { SearchType } from '../../../shared/types';
import { SEARCH_OPTION_FIELDS } from '../../../shared/models/Search';
import { useSelector, useDispatch } from 'react-redux';
import Select from '../../Select';
import { changeSearchRow } from '../../../actions/advanced-search';
import { useSearchData } from './hooks/advanced-search-hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    minWidth: 155,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
  type: SearchType;
}

const SearchSelectType = (props: IProps) => {
  const { classes, idx, subRowIdx, type } = props;
  const { row, onChange } = useSearchData(idx, subRowIdx);
  const options = useMemo(() => SEARCH_OPTION_FIELDS[type], [type]);



  const value = useMemo(() => {
    if(row.type?.id === undefined || subRowIdx !== undefined) return;
    return options.find((type: any) => type.id === row.type.id);
  }, [options, row, subRowIdx])




  const onSelect = (option: any): any => {
    return onChange({ type: option, field: null, condition: null, value: null, subRows: [] })
  }




  return useMemo(() => subRowIdx !== undefined ? null : (
    <Select
      className={classes.root}
      placeholder="Select Type"
      value={value}
      valuesList={options}
      onSelect={onSelect}
    />
  ), [value, options, subRowIdx])
}


export default withStyles(styles)(SearchSelectType)