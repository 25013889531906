import * as actions from '../../actions/projects/email';

export const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_EMAIL_PROJECTS:
      return action.projects

    default:
      return state
  }
}