import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Checkbox from './Checkbox';
import { PersonIcon } from './icons/PersonIcon';
import { getUsername } from '../utils/user';
import { ProfileType } from '../shared/types';
import { useSelector } from 'react-redux';
import { getCategoryLabel } from '../utils/utils';



const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    padding: '10px 0',
    cursor: 'pointer',
    transition: 'all .1s ease',
    boxSizing: 'border-box',
    '&:hover': {
      padding: '10px 5px',
      backgroundColor: theme.palette.primary.light
    }
  },
  disabled: {
    cursor: 'default',
    pointerEvents: 'none',
    '& $checkbox': {
      opacity: 0.5
    },
    '& $icon': {
      color: '#90a0a7 !important',
    }, 
    '& $caption': {
      color: '#90a0a7 !important'
    },
    '& $name': {
      color: '#90a0a7 !important',
      fontWeight: 400
    },
    '&:hover': {
      padding: '10px 0',
      backgroundColor: 'inherit'
    }
  },
  checkbox: {
    marginRight: 10,
    transform: 'translateY(-4px)'
  },
  data: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%'
  },
  icon: {
    marginRight: 12,
    fontSize: 14,
  },
  info: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1,
    color: '#657274',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    transform: 'translateY(-1px)'
  },
  name: {
    fontWeight: 500,
    color: '#233539',
    marginBottom: 6
  },
  caption: {

  },
  noHover: {
    '&:hover': {
      padding: '10px 0',
      backgroundColor: 'inherit'
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  key?: string|number;
  person?: any;
  checked?: boolean;
  disabled?: boolean;
  onClick?: (item: any) => any;
  className?: string;
  name?: string;
  caption?: string;
  type?: ProfileType;
  noHover?: boolean;
}

const PersonListItem = (props: IProps) => {
  const { classes, person, disabled } = props;
  const types = useSelector((state: any) => state.types);


  const getName = (): string => {
    if(props.name) return props.name;

    if(!person) return '';
    return getUsername(person)
  }


  const getCaption = (): string => {
    if(props.caption) return props.caption;

    if(!person) return '';

    const category = getCategoryLabel(person);
    return `${person.company}${category ? `, ${category}` : ''}`
  }


  const getType = (): ProfileType|undefined => {
    if(disabled || (!props.type && !person)) return undefined;

    if(props.type) return props.type;

    if(!person) return undefined
    return person.profile_type
  }



  const onClick = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    if(disabled) return;

    props.onClick?.(person)
  }



  return (
    <div key={props.key}
      className={classNames(
        classes.root,
        props.className,
        {
          [classes.disabled]: props.disabled,
          [classes.noHover]: props.noHover
        }
      )}
      onClick={props.onClick && onClick}
    >

      {props.checked !== undefined && (
        <Checkbox checked={props.checked} className={classes.checkbox} />
      )}


      <div className={classes.data}>
        <PersonIcon className={classes.icon} type={getType()} />
        <div className={classes.info}>
          <span className={classes.name}>{getName()}</span>
          <span className={classes.caption}>{getCaption()}</span>
        </div>
      </div>


    </div>
  )
}


export default withStyles(styles)(PersonListItem)