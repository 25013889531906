import { TinymceType } from "../../../shared/types";



const generateMenuItem = (text, value, editor) => ({
    type: 'menuitem',
    text,
    onAction: () => editor.insertContent(value)
})




export const emailDistroPlugin = (editor, type: TinymceType = TinymceType.DEFAULT) => {

    switch(type) {


        case TinymceType.COMPANY_EMAIL:
            editor.ui.registry.addMenuButton('distroVariables', {

                text: 'Insert Options',

                onItemAction: (buttonApi, value) => {
                    editor.insertContent(value);
                },

                fetch: callback => {

                    const items = [
                        generateMenuItem('Multiple Full Names', ' {{multiple_full_names}} ', editor),
                        generateMenuItem('Multiple First Names', ' {{multiple_first_names}} ', editor),
                        generateMenuItem('Multiple Last Names', ' {{multiple_surnames}} ', editor),
                        generateMenuItem('Project Name', ' {{project_name}} ', editor),
                        generateMenuItem('Required Capital', ' {{capital_required}} ', editor),
                    ]

                    callback(items);
                }

            });
            break;


        case TinymceType.INDIVIDUAL_EMAIL:
            editor.ui.registry.addMenuButton('distroVariables', {

                text: 'Insert Options',

                onItemAction: (buttonApi, value) => {
                    editor.insertContent(value);
                },

                fetch: callback => {

                    const items = [
                        generateMenuItem('Full Name', '{{title}} {{first_name}} {{last_name}} ', editor),
                        generateMenuItem('First Name', ' {{first_name}} ', editor),
                        generateMenuItem('Last Name', ' {{last_name}} ', editor),
                        generateMenuItem('Title', ' {{title}} ', editor),
                        generateMenuItem('Project Name', ' {{project_name}} ', editor),
                        generateMenuItem('Required Capital', ' {{capital_required}} ', editor)
                    ];

                    callback(items);
                }

            });
            break;


        default:
            break;
    }






};
