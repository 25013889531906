import Request from "../components/request/Request";
import { API_URL } from "../constants/api";



function getEmails(id: number, query: string) {
  return Request.get(`${API_URL}/emails/?ordering=-date&person=${id}&${query}`)
}

function getEmailsByURL(url: string) {
  return Request.get(url)
}

function getEmail(id: number) {
  return Request.get(`${API_URL}/emails/${id}/`)
}

function getThread(threadId: string, personId: number) {
  return Request.get(`${API_URL}/emails/?conversation_id=${threadId}&person=${personId}&limit=10000&ordering=-date`)
}

function forwardEmail(id: number, data: any) {
  // return Request.post(`${API_URL}/emails/${id}/forward/`, data)
  const { files, ...mainData } = data;
  return Request.post(`${API_URL}/emails/contexts/${id}/send/`, mainData)
}

function forwardThread(data: any) {
  return Request.post(`${API_URL}/emails/forward_chain/`, data)
}




export default {
  getEmails,
  getEmailsByURL,
  getEmail,
  getThread,
  forwardEmail,
  forwardThread
}