import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useState, createRef, forwardRef, Fragment } from 'react';
import { IDialogMenuItem } from '../shared/interfaces';
import { Theme } from '../theme';
import { ClickAwayListener } from '@material-ui/core';
import PersonListItem from './PersonListItem';

const styles = (theme: Theme) => createStyles({
  root: {
    width: 285,
    backgroundColor: '#fff',
    padding: '5px 10px',
    border: '1px solid rgba(189, 194, 196, 0.24)',
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    position: 'relative',
    zIndex: 10,
    opacity: 0,
    transform: 'scaleY(0)',
  },
  rootOpened: {
    opacity: 1,
    transform: 'scaleY(1)'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px 5px',
    textDecoration: 'unset',
    transition: 'all .1s ease',
    cursor: 'pointer',
    '&:hover, &$activeItem': {
      backgroundColor: '#f7f9fa',
    },
  },
  icon: {
    fontSize: 14,
    margin: '4px 10px 0 0',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.5,
    color: '#233539',
    fontWeight: 500
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#657274'
  },
  activeItem: {},
  personThemeRoot: {
    padding: '10px 0',
  },
  personThemeItem: {
    padding: '0 15px',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0
    }
  }
})



interface IProps extends WithStyles<typeof styles> {
  items: IDialogMenuItem[];
  open?: boolean;
  className?: string;
  onSelect: (item: IDialogMenuItem) => any;
  onClose: (e: any) => any;
  activeItemIdx?: number;
  onActiveItemOffsetChange?: (offsetTop: number, offsetHeight: number) => any;
  personTheme?: boolean;
}

const DialogMenu = forwardRef((props: IProps, ref: any) => {
  const { classes, activeItemIdx, onActiveItemOffsetChange } = props;
  let [refs, setRefs] = useState<any[]>([]);


  const handleClick = (e: any, item: any): void => {
    e.preventDefault();
    props.onSelect(item)
  }



  useEffect(() => {

    const newRefs = Array(props.items.length).fill(0).map((_, i) => refs[i] || createRef())
    setRefs(newRefs)

  }, [props.items.length])


  useEffect(() => {

    if(activeItemIdx !== undefined && onActiveItemOffsetChange && refs?.[activeItemIdx]) {
      const activeRef = refs[activeItemIdx].current;
      if(!activeRef) return;
      onActiveItemOffsetChange(activeRef.offsetTop, activeRef.offsetHeight)
    }

  }, [activeItemIdx, refs])




  return (
    <ClickAwayListener onClickAway={props.onClose}>
      <div ref={ref} className={classNames(
        classes.root,
        props.className,
        {
          [classes.rootOpened]: props.open,
          [classes.personThemeRoot]: props.personTheme
        }
      )}>
        {props.items.map((item: any, index: any) => (
          <div
            ref={refs[index]}
            key={index}
            className={classNames(
              classes.item,
              {
                [classes.activeItem]: activeItemIdx === index,
                [classes.personThemeItem]: props.personTheme
              }
            )}
            onClick={(e: any) => handleClick(e, item)}
          >
            {props.personTheme
              ? <PersonListItem noHover key={item.id} person={item} />
              : <Fragment>
                  {item.icon || null}
                  <div className={classes.titleWrapper}>
                    <span className={classes.title}>{item.title}</span>
                    <span className={classes.subtitle}>{item.subtitle}</span>
                  </div>
                </Fragment>
            }
          </div>
        ))}
      </div>
    </ClickAwayListener>
  )
})


export default withStyles(styles)(DialogMenu)