import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const AttachmentIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="9" height="18" viewBox="0 0 9 18">
      <g fill="none" fillRule="evenodd">
        <path d="M-5 0h18v18H-5z"/>
        <path fill="#657274" fillRule="nonzero" d="M7.375 4.5v8.625a3 3 0 1 1-6 0V3.75a1.876 1.876 0 0 1 3.75 0v7.875c0 .412-.338.75-.75.75a.752.752 0 0 1-.75-.75V4.5H2.5v7.125a1.876 1.876 0 0 0 3.75 0V3.75a3 3 0 1 0-6 0v9.375a4.123 4.123 0 0 0 4.125 4.125A4.123 4.123 0 0 0 8.5 13.125V4.5H7.375z"/>
      </g>
    </SvgIcon>
  );
};