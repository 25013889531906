import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import AddButton from './AddButton';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  spaceFix: {
    flex: 3.8,
    paddingRight: 10
  },
  buttonWrapper: {
    flex: 6.2,
  }
})

interface IProps extends WithStyles<typeof styles> {
  onClick: (e: any) => any;
  label?: string;
  hidden?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const LabelledListAddButton = (props: IProps) => {
  const { classes } = props;


  if(props.hidden) return null;


  return (
    <div className={classNames(classes.root, props.className)}>
      {!props.fullWidth && <div className={classes.spaceFix} />}
      <div className={classes.buttonWrapper} onClick={props.onClick}>
        <AddButton text={props.label} />
      </div>
    </div>
  )
}


export default withStyles(styles)(LabelledListAddButton)