import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import React, { Fragment, useEffect } from "react"
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { setLoadedPage } from "../../actions/common/loading"
import { getProjects } from "../../actions/projects/projects"
import { getAllTasks } from "../../actions/tasks"
import Footer from "../../components/footer/Footer"
import List from "../../components/list/List"
import ListHeader from "../../components/list/ListHeader"
import MobileList from "../../components/list/mobile/MobileList"
import MobileWarning from "../../components/MobileWarning"
import { useWindowSize } from "../../shared/hooks"
import { ISort } from "../../shared/interfaces"
import { TableType } from "../../shared/types"
import { Theme } from "../../theme"
import { goTo } from "../../utils/utils"

const styles = (theme: Theme) => createStyles({
  root: {

  },
  mainHeading: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#233539',
    margin: '0 0 20px',
  },
  marginBottom: {
    marginBottom: 20
  },
  header: {
    marginTop: 40
  }
});

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {

}

const DASHBOARD_PROJECT_LIST  = "DASHBOARD_PROJECT_LIST";
const DASHBOARD_TASK_LIST     = "DASHBOARD_TASK_LIST";



const DashboardStaffPage = (props: IProps) => {
  const { classes, history } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const tasks = useSelector((state: any) => state.board.tasks);
  const projects = useSelector((state: any) => state.projects);
  const loading = useSelector((state: any) => state.loading);
  const windowSize = useWindowSize();



  const handleOpenTask = (e: any, id: number): void => {
    e.preventDefault();
    e.stopPropagation();
    
    const task = tasks.results.find((task: any) => task.id === id);

    if(task) {
      goTo(e, `/task/${id}`, history)
    }
  }


  const handleOpenProject = (e: any, id: number): void => {
    goTo(e, `/projects/${id}`, history)
  }



  const handleTaskSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchSortTasks(sort, `assignees=${user.id}${!!search ? `&${search}` : ''}`).then((res: any) => {
      if(isLoading(DASHBOARD_TASK_LIST)) {
        dispatchSetLoadedPage(DASHBOARD_TASK_LIST)
      }
    })
  }


  const handleProjectSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchSortProjects(sort, search).then((res: any) => {
      if(isLoading(DASHBOARD_PROJECT_LIST)) {
        dispatchSetLoadedPage(DASHBOARD_PROJECT_LIST)
      }
    })
  }


  const isLoading = (page: string): boolean => {
    return !loading.pagesLoaded[page]
  }



  const dispatchSortTasks = (sort: ISort[], search: string): Promise<any> => {
    return dispatch(getAllTasks(sort, search))
  }

  const dispatchSortProjects = (sort: ISort[], search: string): Promise<any> => {
    return dispatch(getProjects(sort, search))
  }



  const dispatchSetLoadedPage = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }




  useEffect(() => {
    ReactGA.pageview(props.location.pathname)
  }, [])




  return (
    <>
      <h1 className={classes.mainHeading}>My Dashboard</h1>

      {windowSize.isMobile
        ? (
            <Fragment>
              <MobileList
                smallHeader
                rows={projects.results}
                header={`Active Projects (${projects.count})`}
                type={TableType.PROJECTS}
                onSort={handleProjectSort}
              />

              <MobileList
                smallHeader
                rows={tasks.results}
                header={`My Tasks (${tasks.count})`}
                type={TableType.TASKS}
                onSort={handleTaskSort}
              />

              <Footer />

              <MobileWarning />
            </Fragment>
          )
        : (
            <Fragment>
              <ListHeader smallHeader
                loading={isLoading(DASHBOARD_PROJECT_LIST)}
                title="My Projects"
                count={projects.count}
                fullAccess={true}
                viewMore={{
                  path: '/projects',
                  text: 'View All Projects'
                }}
              />

              <List fullAccess
                loading={projects.loading}
                initialLoading={isLoading(DASHBOARD_PROJECT_LIST)}
                rows={projects.results}
                count={projects.count}
                onOpen={handleOpenProject}
                tableType={TableType.PROJECTS}
                onSort={handleProjectSort}
                className={classes.marginBottom}
                emptyLabel="You have no"
              />

              <ListHeader smallHeader
                className={classes.header}
                loading={isLoading(DASHBOARD_TASK_LIST)}
                title="My Tasks"
                count={tasks.count}
                fullAccess={true}
              />
      
              <List
                loading={tasks.loading}
                initialLoading={isLoading(DASHBOARD_TASK_LIST)}
                rows={tasks.results}
                count={tasks.count}
                onOpen={handleOpenTask}
                fullAccess={true}
                tableType={TableType.TASKS}
                onSort={handleTaskSort}
                emptyLabel="You have no"
              /> 
            </Fragment>
          )
      }

    </>
  );
}


export default withStyles(styles)(
  withRouter(DashboardStaffPage)
)