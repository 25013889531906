import { createStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { Theme } from '../../../../theme';
import TextInput from "../../../TextInput";

const styles = (theme: Theme) => createStyles({
    root: {
        margin: '2px 0',
        outline: 'none',
        '&:focus $startAdornment, &:focus-within $startAdornment': {
            outline: 'none',
            fontWeight: 500,
            '&:after': {
                content: '":"'
            }
        }
    },
    spacingBottom: {
        marginBottom: 10
    },
    inputWrapper: {
        color: 'red',
    }
});


const TextField = (props: any) => {
    const {classes, value="", onChange, spacingBottom, startAdornment, ...otherProps} = props;
    const startAdornmentRef = useRef<any>();



    return <div tabIndex={1} className={classes.root}>
        {/* {fixedLabel && (
            <span className={classes.fixedLabel}>{otherProps.placeholder}</span>
        )} */}
        <TextInput
            onChange={(value: string|number) => onChange(value, otherProps.name)}
            value={value}
            className={classNames(classes.inputWrapper, {[classes.spacingBottom]: spacingBottom})}
            startAdornment={startAdornment}
            {...otherProps}
        />
    </div>
}

export default withStyles(styles)(TextField)