import React, { useMemo } from 'react';
import Tags from '../../Tags';
import { usePersonData } from '../hooks/people-hooks';
import { EntityField } from '../../../shared/interfaces';


const ContactInfoTags = ({ name, ...otherProps }: EntityField) => {
  const { value, error, readonly, onChange } = usePersonData(name);




  const onAdd = (newTag: string): void => {
    if(!newTag) return;

    const tags = (value ?? []).map((tag: string) => tag.toLowerCase());
    if (tags.includes(newTag.toLowerCase())) {
      return;
    }

    onChange([...tags, newTag])
  }



  const onDelete = (oldTag: string): void => {
    const newTags = value
      .map((tag: string) => tag.toLowerCase())
      .filter((tag: string) => tag !== oldTag.toLowerCase());

    onChange(newTags)
  }



  
  return useMemo(() => (
    <Tags
      tags={value}
      readonly={readonly}
      placeholder="Type tags"
      onAdd={onAdd}
      onDelete={onDelete}
      {...otherProps}
    />
  ), [value, error, readonly])
}


export default ContactInfoTags