import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useMemo } from 'react';
import { Theme } from '../../../../theme';
import TextInput from '../../../TextInput';
import { useSearchData } from '../hooks/advanced-search-hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    width: 250
  },
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
  [otherProps: string]: any;
}

const SearchSelectValueInput = (props: IProps) => {
  const { classes, idx, subRowIdx, ...otherProps } = props;
  const { currentRow, onChange } = useSearchData(idx, subRowIdx);




  const handleChange = useCallback((value: string): void => {
    return onChange({ value })
  }, [])




  return useMemo(() => (
    <TextInput
      value={currentRow?.value ?? ''}
      className={classes.root}
      onChange={handleChange}
      {...otherProps}
    />
  ), [currentRow?.value, handleChange])
}


export default withStyles(styles)(SearchSelectValueInput)