import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import ReactGA from 'react-ga';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { GA_ID } from '../../constants/ga';
import { renderRoutesWithSwitch, routes } from '../../constants/routes';
import { CustomTheme as theme } from "../../theme";
import DialogContainer from "./DialogContainer";
import RequestInterceptor from './RequestInterceptor';



const initializeReactGA = () => {
  if (GA_ID) ReactGA.initialize(GA_ID)
}


const App = ({ store }) => {
  initializeReactGA()
  
  return (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <RequestInterceptor>
          <DialogContainer>
            <BrowserRouter>
              {renderRoutesWithSwitch(routes)}
            </BrowserRouter>
          </DialogContainer>
        </RequestInterceptor>
      </MuiPickersUtilsProvider>
    </Provider>
  </ThemeProvider>
)}

export default App