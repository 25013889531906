import React, { useMemo } from 'react';
import TextInput from '../../TextInput';
import { useProjectData } from '../hooks/projects-hooks';
import { EntityField } from '../../../shared/interfaces';


const ProjectInfoInput = ({ name, ...otherProps }: EntityField) => {
  const { value, error, readonly, onChange, project, updatedProject } = useProjectData(name);



  const memoValue = useMemo((): any => {
    let fees, cp, termFee;

    switch(name) {

      case 'success-fee':
        fees = updatedProject.fees ?? project.fees;
        cp = updatedProject.capital_required ?? project.capital_required;
        if(!fees || !cp) return 0;

        return (cp * (fees / 100)).toFixed(0)


      case 'total-fees':
        fees = updatedProject.fees ?? project.fees;
        cp = updatedProject.capital_required ?? project.capital_required;
        termFee = updatedProject.term_sheet_fee ?? project.term_sheet_fee ?? 0;
        if(!fees || !cp) return 0;

        return (cp * (parseFloat(fees) / 100) + parseFloat(termFee)).toFixed(0)
    }


    return value
  }, [value, project, updatedProject])




  return useMemo(() => (
    <TextInput
      value={memoValue}
      readonly={readonly}
      onChange={onChange}
      error={error}
      hidden={!memoValue && readonly}
      {...otherProps}
    />
  ), [readonly, memoValue, error])
}


export default ProjectInfoInput