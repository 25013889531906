import { CircularProgress, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreEmails, getEmails, setFilters } from "../actions/email";
import { useShowAll } from "../shared/hooks";
import { EmailFilters, IMessage } from "../shared/interfaces";
import { EmailFilterOption } from "../shared/types";
import { Theme } from "../theme";
import { arrayRange, formatDate } from "../utils/utils";
import CustomMailFilters from "./CustomMailFilters";
import DataCard from "./DataCard";
import EmptyMail, { EmptyIcon } from "./EmptyMail";
import Mail from "./Mail";
import ShowAll from "./ShowAll";



const styles = (theme: Theme) => createStyles({
  root: {
    padding: 0,
    position: 'relative'
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  listWrapper: {

  },
  list: {

  },
  listFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  msSubscription: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      lineHeight: 2,
      color: '#90a0a7'
    }
  },
  outlookIcon: {
    fontSize: 20,
    marginRight: 5
  },
  msSubscriptionLabel: {
    color: '#657274 !important',
    fontWeight: 500,
    marginRight: 4
  },
  msSubscriptionRefresh: {
    paddingLeft: 10,
    marginLeft: 5,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    '& > span': {
      transition: 'all .1s ease'
    },
    '&:before': {
      content: '""',
      width: 1,
      height: 13,
      backgroundColor: '#d9e4e8',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  refreshArrowIcon: {
    fontSize: 20,
    transition: 'all .1s ease'
  },
  showAll: {
    marginTop: 0,
    padding: '12px 24px',
    boxSizing: 'border-box',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'rgba(255, 255, 255, .7)',
    display: 'flex',
    justifyContent: 'center'
  },
  loadingHidden: {
    display: 'none'
  },
  loadingIcon: {
    position: 'absolute',
    top: 40
  }
});


interface IProps extends WithStyles<typeof styles> {
  newUser?: boolean;
}



const MailCard = (props: IProps) => {
  const { classes } = props;
  const showAll = useShowAll();
  const dispatch = useDispatch();
  const emails = useSelector((state: any) => state.emails);
  const filters = emails.filters;
  const person = useSelector((state: any) => state.people.person);
  const [messages, setMessages] = useState<any[] | undefined>(undefined)
  const [filterLabel, setFilterLabel] = useState<string>('All Time');
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>();
  const notConnected = !person.is_ms_authorized && !props.newUser;






  const onApply = (selectedOption?: EmailFilterOption): void => {
    let newFilters = {
      ...filters,
      date_from: selectedOption === EmailFilterOption.LAST_30_DAYS
        ? moment().subtract(30, 'days').startOf('day').format()
        : filters.date_from
    };

    setFilterLabel(formatLabel(newFilters))


    newFilters = {
      ...newFilters,
      sender: filters.sender?.id,
      receiver: filters.receiver?.id,
    }

    dispatchGetEmails(newFilters)
  }



  const changeFilters = (data: any): void => {
    dispatch(setFilters(data))
  } 




  const renderMessages = () => {
    if (messages) {
      const messagesToShow = showAll.value ? messages : arrayRange(0, 3, messages);
      if (messagesToShow && messagesToShow.length && person.id && person.is_ms_authorized) {
        return messagesToShow.map((message: IMessage, idx: number) => (
          <Mail key={idx}
            counter={idx + 1}
            message={message}
            personId={person.id}
          />
        ))
      }
    }


    const icon = notConnected ? EmptyIcon.Mail : EmptyIcon.MailEmpty
    const text = notConnected
      ? 'Connect your account to view email activity.'
      : 'No emails yet.'

    return (
      <EmptyMail icon={icon} text={text} showConnect={notConnected} />
    )
  }





  const formatLabel = (newFilters: any) => {
    const values: string[] = []
    if (newFilters.sender) values.push('From: ' + (newFilters.sender.sortValue ?? ''))
    if (newFilters.receiver) values.push('To: ' + (newFilters.receiver.sortValue ?? ''))
    if (newFilters.search) values.push('Search: ' + newFilters.search)
    if (newFilters.date_from && newFilters.date_to) values.push(`${newFilters.date_from ? formatDate(newFilters.date_from) : ''}${newFilters.date_from && newFilters.date_to ? ' - ' : ''}${newFilters.date_to ? formatDate(newFilters.date_to) : ''}`)
    return values.length ? values.join(', ') : 'All Time'
  }



  const clearFilters = (): void => {
    changeFilters({ date_from: undefined, date_to: undefined })
  }



  const onScroll = (e: any): void => {
    if (!containerRef || !containerRef.current || !showAll.value) return;

    const windowScroll = window.scrollY + window.innerHeight;
    const containerEnd = containerRef.current.scrollHeight + containerRef.current.offsetTop

    if (containerEnd - 500 <= windowScroll && emails.next) {
      setLoadingMore(true)
    }
  }





  const dispatchFetchMoreEmails = (): any => {
    return dispatch(fetchMoreEmails())
  }



  const dispatchGetEmails = (newFilters?: EmailFilters): any => {
    return dispatch(getEmails(person.id, newFilters))
  }

  






  useEffect(() => {
    if(!loadingMore) return;
    
    dispatchFetchMoreEmails()
      .then(() => setLoadingMore(false))
  }, [loadingMore])



  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [showAll.value, emails])



  useEffect(() => {
    setMessages(emails?.results ?? [])
  }, [showAll.value, filters.date_from, filters.date_to, emails.results])



  useEffect(() => {
    if(!person?.id) return;

    dispatchGetEmails()
  }, [])







  return (
    <DataCard
      id="scrollable"
      ref={containerRef}
      header={(
        <div className={classes.header}>
          <span>Email Activity {!notConnected && <>({emails.count})</>}</span>
          {emails.results 
            ? (
              <CustomMailFilters
                hidden={props.newUser}
                onApply={onApply}
                label={filterLabel}
                onReset={() => {
                  clearFilters();
                  setFilterLabel('All Time');
                  dispatchGetEmails()
                }}
              />
            )
            : null
          }
        </div>
      )}
      classes={{ card: classes.root }}
    >


      <div className={classes.listWrapper}>
        <div className={classes.list}>
          {renderMessages()}
        </div>

        {!notConnected && !!messages?.length && messages.length > 3 && (
          <ShowAll alignLeft
            className={classes.showAll}
            showAll={showAll}
            count={emails.count}
            isLoading={loadingMore}
          />
        )}
      </div>



      <div className={classNames(
        classes.loading,
        {[classes.loadingHidden]: !emails.loading}
      )}>
        <CircularProgress size={40} className={classes.loadingIcon} />
      </div>


    </DataCard>
  )
};


export default withStyles(styles)(MailCard)