import Request from '../components/request/Request';
import { API_URL } from "../constants/api";



function getEmailTemplates(body: string = '') {
  return Request.get(`${API_URL}/emails/templates/${body}`)
}

function getNextTemplates(url: string) {
  return Request.get(url)
}

function getTemplate(id: number) {
  return Request.get(`${API_URL}/emails/templates/${id}/`)
}

function deleteMultipleTemplates(ids: number[]) {
  return Request.delete(`${API_URL}/emails/templates/delete_multiple/`, { data: { ids } }, true)
}

function deleteTemplate(id: number) {
  return Request.delete(`${API_URL}/emails/templates/${id}/`)
}

function createTemplate() {
  return Request.post(`${API_URL}/emails/templates/`)
}

function saveTemplate(data: any) {
  const { files = [], id, ...mainData } = data;
  const formData = new FormData();

  for (const file of files){
    formData.append('files', file);
  }

  for (const field of Object.entries(mainData)) {
    formData.set(field[0], field[1] as any);
  }


  return Request.patch(`${API_URL}/emails/templates/${id}/`, formData, { 'Content-Type': 'multipart/form-data' })
}

function saveTemplateAsDraft(id: number, data: any) {
  const { files = [], ...mainData } = data;

  return Request.post(`${API_URL}/emails/templates/${id}/save_as_draft/`, mainData)
}


function uploadTemplateFiles(id: number, files: any) {
  const formData = new FormData();

  for (const file of files){
    formData.append('files', file);
  }

  return Request.post(`${API_URL}/emails/templates/${id}/add_files/`, formData)
}

function deleteTemplateFile(id: number) {
  return Request.delete(`${API_URL}/emails/templates/files/${id}/`)
}

function findEmailTemplates(searchStr: string) {
  return Request.get(`${API_URL}/emails/templates/?search=${searchStr}`)
}


export default {
  getEmailTemplates,
  getNextTemplates,
  getTemplate,
  deleteMultipleTemplates,
  saveTemplate,
  deleteTemplate,
  createTemplate,
  uploadTemplateFiles,
  deleteTemplateFile,
  saveTemplateAsDraft,
  findEmailTemplates
}
