import React, { useCallback } from 'react';
import { uploadFiles } from '../../../actions/files';
import { DEFAULT_TASK_CATEGORY } from '../../../shared/models/Task';
import FilesDropzone from '../../files/FilesDropzone';
import { useTaskData } from './hooks/tasks-hooks';
import TaskFilesList from './TaskFilesList';



interface IProps {
  id: number;
  isNew?: boolean;
  className?: string;
  headerClassName?: string;
}

const TaskFiles = ({ id, isNew, className, headerClassName }: IProps) => {
  const { value: files = [], dispatch, onChange } = useTaskData('files');



  const handleFiles = useCallback((newFiles: any[]): void => {
    return isNew
      ? onChange([...files, ...newFiles])
      : dispatchUploadTaskFiles(newFiles)
  }, [files])


  
  const dispatchUploadTaskFiles = useCallback((files: any[]): any => {
    return dispatch(uploadFiles(files, DEFAULT_TASK_CATEGORY, id))
  }, [id])





  return (
    <div className={className}>
      <div className={headerClassName}>
        <h2>Files</h2>
      </div>

      
      <TaskFilesList isNew={isNew} />

      <FilesDropzone onChange={handleFiles} />
    </div>
  )
}


export default TaskFiles