import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import React, { Fragment, useEffect } from "react"
import { connect, useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { setLoadedPage } from "../../actions/common/loading"
import { getAllTasks, searchTasks } from "../../actions/tasks"
import Footer from "../../components/footer/Footer"
import List from "../../components/list/List"
import ListHeader from "../../components/list/ListHeader"
import MobileWarning from "../../components/MobileWarning"
import TaskDialog from "../../components/project/task/TaskDialog"
import { ROUTE_DASHBOARD } from "../../constants/routes"
import { initialState } from "../../reducers/tasks"
import { useWindowSize } from "../../shared/hooks"
import { ISort } from "../../shared/interfaces"
import { TableType } from "../../shared/types"
import { Theme } from "../../theme"
import MobileDashboardGuest from "./mobile/MobileDashboardGuest"
import { goTo } from "../../utils/utils"

const styles = (theme: Theme) => createStyles({
  root: {

  },
  mainHeading: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.33,
    color: '#233539',
    margin: '12px 0 0'
  },
  paddingBottom: {
    paddingBottom: 40
  },
  header: {
    marginTop: 20
  }
});

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  user: any,
  tasks: any[],
  taskCount: number,
  onTaskFetch: (search?: string) => any,
  onTaskSort: (sort: ISort[], search?: string) => any,
  onPageLoaded: (page: string) => any
}

const DashboardGuestPage = (props: IProps) => {
  const { classes, history, user } = props;
  const loading = useSelector((state: any) => state.loading);
  const windowSize = useWindowSize();





  const handleOpenTask = (e: any, id: number): void => {
    e.preventDefault();
    e.stopPropagation();

    const task = props.tasks.find((task: any) => task.id === id);

    if(task) {
      goTo(e, `/task/${id}`, history)
    }

  }



  const handleTaskSort = (sort: ISort[], search: string): Promise<any> => {
    return props.onTaskSort(sort, `assignees=${user.id}${!!search ? `&${search}` : ''}`)
      .then((res: any) => {
        if(isLoading()) {
          props.onPageLoaded(ROUTE_DASHBOARD)
        }
      })
  }



  const isLoading = (): boolean => {
    return !loading.pagesLoaded[ROUTE_DASHBOARD]
  }







  return (
    <>

      {windowSize.isMobile
        ? (
          <Fragment>
            <MobileDashboardGuest
              rows={props.tasks}
              header="My Tasks"
              onOpen={handleOpenTask} 
              onSort={handleTaskSort}
            />

            <MobileWarning />
          </Fragment>
          )
        : (
            <Fragment>
              <h1 className={classes.mainHeading}>My Dashboard</h1>

              <ListHeader smallHeader
                className={classes.header}
                loading={isLoading()}
                title="My Tasks"
                count={props.taskCount}
              />

              <List
                initialLoading={isLoading()}
                rows={props.tasks}
                count={props.taskCount}
                onOpen={handleOpenTask}
                tableType={TableType.TASKS}
                onSort={handleTaskSort}
                emptyLabel="You have no"
              />
            </Fragment>
          )
      }

      <Footer />
    </>
  );
}

const mapStateToProps = state => {
  const { board: { tasks }, user: { user } } = state

  return {
    user,
    tasks: tasks && tasks.results || initialState.results,
    taskCount: tasks && tasks.count || 0
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTaskFetch: search => dispatch(searchTasks(search, true)),
    onTaskSort: (sort, search) => dispatch(getAllTasks(sort, search)),
    onPageLoaded: page => dispatch(setLoadedPage(page))
  }
}

export default
  connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(
      withRouter(DashboardGuestPage)
    )
  )