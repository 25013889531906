import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router';
import { searchPeoplePromise } from '../../../actions/people/people';
import { getEmailProjects } from '../../../actions/projects/email';

import StoreDropdown from '../../../components/forms/meta/fields/StoreDropdown';
import StoreDropzone from '../../../components/forms/meta/fields/StoreDropzone';
import TinyMCEField from "../../../components/forms/meta/fields/TinyMCEField";
import MetaBasedForm from "../../../components/forms/meta/MetaBasedForm";
import { emailPeopleActions } from "../../../reducers/people/email";
import { useOpen } from '../../../shared/hooks';
import { IPerson } from '../../../shared/models/Person';
import { getUsername } from '../../../utils/user';
import { getIds } from '../../../utils/utils';
import InputAutocompleteTagsField from '../../../components/forms/meta/fields/InputAutocompleteTagsField';
import { TinymceType } from '../../../shared/types';



const storeGetter = storeName => state => state[storeName]


const EmailForm = props => {

    const dispatch = useDispatch();
    const store = useSelector((state:any)=>state.emailPeople)
    const people = useSelector((state: any) => state.people.results);
    const [to, setTo] = useState<any[]>([]);
    const ccVisibility = useOpen();
    const bccVisibility = useOpen();


    const getTagLabel = (person: any): any => {
        return <span>{getUsername(person)} (<strong style={{fontWeight: 600}}>{(person.emails && person.emails[0]) || ''}</strong>)</span>
    }

    const getSortValue = (person: any): string => {
        return `${getUsername(person)}`
    }


    const onReceiverChange = (newReceivers: any[]): void => {
        setTo(newReceivers);
        const newIds = getIds(newReceivers).join(',');
        props.history.push(`/contacts/email?to=${newIds}`);
    }

    const getToActions = (): any[] => {
        if(ccVisibility.value || bccVisibility.value) return [];
        return [
            {id: 0, label: 'Cc', action: () => ccVisibility.open()},
            {id: 1, label: 'Bcc', action: () => bccVisibility.open()},
        ]
    }

    const getCcActions = (): any[] => {
        return bccVisibility.value
            ? []
            : [ {id: 0, label: 'Bcc', action: () => bccVisibility.open()} ]
    }

    const getBccActions = (): any[] => {
        return ccVisibility.value
            ? []
            : [ {id: 0, label: 'Cc', action: () => ccVisibility.open()} ]
    }

    const emailMeta = [
        {name: 'project', placeholder: 'Select a Project', as: StoreDropdown, getOptions: storeGetter('emailProjects'), isVisible: (options: any) => !!options.length, spacingBottom: true},
        {name: 'to', as: InputAutocompleteTagsField, fullWidth: true, value: to, onChange: onReceiverChange, searchAction: searchPeoplePromise, getValue: getTagLabel, getSortValue: getSortValue, spacingBottom: true, actions: getToActions()},
        {name: 'cc', as: InputAutocompleteTagsField, fullWidth: true, searchAction: searchPeoplePromise, getValue: getTagLabel, getSortValue: getSortValue, startAdornment: 'Cc', actions: getCcActions(), shouldRender: ccVisibility.value, spacingBottom: true},
        {name: 'bcc', as: InputAutocompleteTagsField, fullWidth: true, searchAction: searchPeoplePromise, getValue: getTagLabel, getSortValue: getSortValue, startAdornment: 'Bcc', actions: getBccActions(), shouldRender: bccVisibility.value, spacingBottom: true},
        {name: 'subject', fullWidth: true, spacingBottom: true, startAdornment: 'Subject'},
        {name: 'body', as: TinyMCEField, type: TinymceType.INDIVIDUAL_EMAIL},
        {name: 'files', as: StoreDropzone}
    ];


    const setPeople = (ids: string): void => {
        const idsArr = ids.split(',').map(i => +i);
        const formattedPeople = people
            .filter((person: IPerson) => idsArr.includes(person.id))
            .map((person: IPerson) => ({
                id: person.id,
                title: getTagLabel(person),
                sortValue: getSortValue(person)
            }))
            .sort((a: any, b: any) => a.sortValue.localeCompare(b.sortValue))
        setTo(formattedPeople)
    }


    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const ids = params.get('to');
        if(ids) {
            dispatch(getEmailProjects(ids))
            setPeople(ids)
        }

    }, [people])

    return <Fragment>

        <MetaBasedForm
            meta={emailMeta}
            actions={emailPeopleActions}
            store={store}
        />

    </Fragment>

};


export default withRouter(EmailForm)