import * as React from "react";
import { Theme, palette } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {Checkbox, FormControlLabel, WithStyles} from "@material-ui/core";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  formLabel: {
    // ...theme.typography.body2,
    fontSize: "12px !important",
    color: '#657274',
    textDecoration:
      (props: any) => props.strikeThrough ? 'line-through' : 'initial',
    '&$formDisabled': {
      ...theme.typography.body2,
      fontSize: 12,
    }
  },
  formRoot: {
    marginRight: 0,
  },
  formDisabled: {},
  checkboxRoot: {
    padding: 4,
    width: 19,
    height: 19,
    color: '#4192ec',
    transition: theme.transitions.create(
      ['color']
    ),
    '& svg': {
      color: '#657274',
      width: 19,
      height: 19,
    },
    '&$checkboxChecked': {
      color: '#4192ec',
      width: 19,
      height: 19,
      '& svg': {
        width: 19,
        height: 19,
        fill: '#4192ec'
      }
    },
    '&$checkboxDisabled': {
      color: palette.dark200
    },
    '&$checkboxChecked$checkboxDisabled': {
      color: '#4192ec',
    },
    '&$checkBoxSmall svg': {
      width: 19,
      height: 19
    },
    '&$checkboxChecked$checkBoxSmall svg': {
      width: 19,
      height: 19
    }
  },
  checkboxChecked: {},
  checkboxDisabled: {},
  checkBoxSmall: {},
  darkFont: {
    color: '#233539'
  }
});

interface IProps extends WithStyles<typeof styles> {
  label: string,
  checked?: boolean,
  strikeThrough?: boolean,
  onChange?: any,
  readonly?: boolean,
  small?: boolean,
  radioFor?: FunctionStringCallback,
  darkFont?: boolean
}

const CheckboxWithLabel = (props: IProps) => {
  const { classes } = props;


  return (
    <div>
      <FormControlLabel
        label={props.label}
        classes={{
          label: classNames(
            classes.formLabel,
            {[classes.darkFont]: props.darkFont}
          ),
          root: classes.formRoot,
          disabled: classes.formDisabled,
        }}
        control={
          <Checkbox
            checked={props.checked}
            onChange={props.onChange}
            color="default"
            disabled={props.readonly}
            classes={{
              'root': classNames(
                classes.checkboxRoot,
                {[classes.checkBoxSmall]: props.small}
              ),
              'checked': classes.checkboxChecked,
              'disabled': classes.checkboxDisabled,
            }}
          />
        }
      />
    </div>
  );
}

export default withStyles(styles)(CheckboxWithLabel)