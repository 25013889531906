import React from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#fff',
    position: 'fixed',
    height: 'calc(100vh - 56px)',
    top: 56,
    transition: 'all .2s ease-in',
    width: 300,
    zIndex: 1000,
    maxWidth: '90%',
    right: -500,
    boxShadow: '10px 20px 14px 6px lightgrey'
  },
  rootOpened: {
    maxHeight: 'calc(100vh - 56px)',
    overflowY: 'hidden',
    right: 0
  },
})

interface IProps extends WithStyles<typeof styles> {
  opened?: boolean;
  className?: string;
  children?: any;
}

const SlideInContainer = (props: IProps) => {
  const { classes } = props;

  return (
    <div className={classNames(
      classes.root,
      props.className,
      {[classes.rootOpened]: props.opened}
    )}>
      {props.children}
    </div>
  )
}


export default withStyles(styles)(SlideInContainer)