import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearSelectedForList } from '../../actions/common/list';
import { setLoadedPage } from '../../actions/common/loading';
import { clearImportedDuplicates, dismissImportedDuplicates, getImportedDuplicates, getNextImportedDuplicates } from '../../actions/contact-management/imported-duplicates';
import ConfirmDialog from '../../components/ConfirmDialog';
import { ImportedDuplicatesIcon } from '../../components/icons/ImportedDuplicatesIcon';
import List from '../../components/list/List';
import { ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { useOpen } from '../../shared/hooks';
import { ISort } from '../../shared/interfaces';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import ManageableTableCard from '../common/ManageableTableCard';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  list: {
    boxShadow: 'none',
    margin: 0
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  className?: string;  
}

const ImportedDuplicatesPage = (props: IProps) => {
  const { classes, history } = props;
  const dismissControls = useOpen();
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search.search);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const duplicates = useSelector((state: any) => state.contactManagement.importedDuplicates);
  const selected = useSelector((state: any) => state.list[TableType.IMPORTED_DUPLICATES].selected);
  



  const onMerge = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    if(!selected.length || selected.length > 1) return;

  
    dispatchClearImportedDuplicates()
    history.push(`${ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES}/merge/${selected[0]}`);
  }

  
  const onConfirmDismiss = (): void => {
    if(!selected.length) return;

    dispatchDismissDuplicates(selected)
      .finally(() => {
        dismissControls.close();
        dispatchClearSelected();
      })
  }


  const onSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchGetPossibleDuplicates(sort, search)
      .then((res: any) => {
        if(!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES]) {
          dispatchOnPageLoaded(ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES)
        }
      })
  }


  const onNext = (): Promise<any> => {
    return dispatchGetNextImportedDuplicates()
  }



  const dispatchGetPossibleDuplicates = (sort: ISort[], search: string): any => {
    return dispatch(getImportedDuplicates(sort, search))
  }


  const dispatchDismissDuplicates = (ids: number[]): any => {
    return dispatch(dismissImportedDuplicates(ids))
  }


  const dispatchGetNextImportedDuplicates = (): any => {
    return dispatch(getNextImportedDuplicates())
  }


  const dispatchClearImportedDuplicates = (): any => {
    return dispatch(clearImportedDuplicates())
  }


  const dispatchOnPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }


  const dispatchClearSelected = (): any => {
    return dispatch(clearSelectedForList(TableType.IMPORTED_DUPLICATES))
  }



  const renderDescription = (): any => (
    <span>Imported Duplicates are not active contacts because their primary email address already exists on FinTech8.<br />Please process them by using Merge or Delete function.</span>
  )
  



  useEffect(() => {
    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])






  return (
    <ManageableTableCard
      isEmpty={duplicates.initiated && !duplicates.results.length && search === undefined}
      description={renderDescription()}
      actions={[
        { label: 'Merge', onClick: onMerge, disabled: !selected.length || selected.length > 1, popover: !selected.length || selected.length > 1 && "Select a duplicate first" },
        { label: 'Delete', onClick: dismissControls.open, disabled: !selected.length, popover: !selected.length && "Select a duplicate first" }
      ]}
      itemsEmpty={{
        icon: ImportedDuplicatesIcon,
        title: 'No imported duplicates',
        description: 'Manage duplicate contacts that have been imported to FinTech8 here.'
      }}
    >


      <List absolutePagination fullAccess selectable
        loading={duplicates.loading}
        initialLoading={!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES]}
        className={classes.list}
        rows={duplicates.results}
        count={duplicates.count ?? duplicates.results.length}
        tableType={TableType.IMPORTED_DUPLICATES}
        onSort={onSort}
        onNext={onNext}
      />
      


      <ConfirmDialog
        title="Confirm Delete"
        open={dismissControls.value}
        cancelLabel="Cancel"
        confirmLabel="YES"
        onCancel={dismissControls.close}
        onConfirm={onConfirmDismiss}
      >
        Are you sure you want to permanently delete this contact?
      </ConfirmDialog>



    </ManageableTableCard>
  )
}


export default withStyles(styles)(
  withRouter(ImportedDuplicatesPage)
)