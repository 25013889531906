import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '../../theme';
import { EmptyListIcon } from '../icons/EmptyListIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '23px 0 26px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  icon: {
    fontSize: 50,
    color: theme.palette.primary.main,
    marginBottom: 2
  },
  content: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    lineHeight: 1.71,
    color: '#233539',
    '& > strong': {
      color: theme.palette.primary.main,
      fontWeight: 500
    }
  },
  hidden: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  hidden?: boolean;
  label?: string;
  emptyLabel?: string;
}

const EmptyListBody = (props: IProps) => {
  const { classes } = props;
  const search = useSelector((state: any) => state.search.search);



  return (
    <div className={classNames(
      classes.root,
      {[classes.hidden]: props.hidden}
    )}>
      <EmptyListIcon className={classes.icon} />
      <span className={classes.content}>
        {!!search
          ? <>No search results found for <strong>"{search}"</strong></>
          : <>{props.emptyLabel ?? 'No'} {props.label ?? ''}</>
        }
      </span>
    </div>
  )
}


export default withStyles(styles)(EmptyListBody)