
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { Theme } from "../../../../theme";
import CheckboxWithLabel from "../../../CheckboxWithLabel";
import classNames from 'classnames';


const styles = (theme: Theme) => createStyles({
    root: {
        marginBottom: 10
    },
    checkboxes: {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: 0,
      padding: '10px 6px',
      '& > div': {
        marginRight: 25,
        '&:last-child': {
          margin: 0
        }
      }
    },

});


const StoreCheckboxes = (props: any) => {
    const { classes, onChange, names = [], value, selectionMandatory, className } = props;




    const shouldKeepSelected = (name: string): boolean => {
      if(!selectionMandatory) return false;

      const othersNotSelected = names
        .filter((item: any) => name !== item.name)
        .every((item: any) => !value?.[item.name]);

      return value?.[name] && othersNotSelected
    }



    const getChecked = (name: string): boolean => {
      return !!value?.[name]?.value ?? !!value?.[name] ?? false
    }


    useEffect(() => {

      // const nothingSelected = names.every((item: any) => !value[item.name]);
      // if(selectionMandatory && nothingSelected && !!names.length) {
      //   onChange(true, names[0].name)
      // }

    }, [])


    if(!names.length) return null;



    

    return <div className={classNames(classes.checkboxes, className)}>
      {names.map((item: any, idx: number) => (
        <CheckboxWithLabel
          key={item.name}
          label={item.label}
          checked={getChecked(item.name)}
          onChange={e => {
            if(shouldKeepSelected(item.name)) return;
            onChange(e.target.checked, item.name)
          }}
        />
      ))}
    </div>
}

export default withStyles(styles)(StoreCheckboxes)