import * as React from "react";
import {RouteComponentProps, withRouter, Redirect} from "react-router";
import { useSelector } from "react-redux";
import ReactGA from 'react-ga'
import PasswordReset from "../../components/PasswordReset";
import { ROUTE_PASSWORD_RESET, ROUTE_ROOT, ROUTE_DASHBOARD, ROUTE_RESET } from "../../constants/routes";
import { isAuthenticated } from "../../utils/user";
import { useEffect } from "react";

interface IProps extends RouteComponentProps {
  invite?: boolean;
  match: any;
}

const PasswordResetContainer = (props: IProps) => {
  const {
    history,
    invite,
    match: {
      params: {
        id,
        token
      }
    }
  } = props;
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    ReactGA.pageview(props.location.pathname)
  }, [])
  
  if(!token) {
    history.push(ROUTE_PASSWORD_RESET)
    return null;
  }

  const handleGoToMainPage = () => {
    history.push(ROUTE_ROOT);
  }

  const handleResetSuccess = () => {
    history.push(ROUTE_DASHBOARD)
  }

  return isAuthenticated(user)
    ? <Redirect to={ROUTE_DASHBOARD} />
    : (
        <PasswordReset
          invite={invite}
          id={+id}
          token={token}
          onGoToMainPage={handleGoToMainPage}
          onResetSuccess={handleResetSuccess}
        />
      )
};

export default withRouter(PasswordResetContainer);