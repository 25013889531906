import React, { useState, useEffect } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import TinyMCEField from '../../components/forms/meta/fields/TinyMCEField';
import Button from '../../components/Button';
import { useOpen } from '../../shared/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmailSignature } from '../../actions/people/people';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '21px 20px 0',  
  },
  editorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 30
  },
  editorHeader: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    lineHeight: 1.46,
    color: '#233539',
    marginBottom: 11
  },
  editor: {
    marginBottom: 20
  },
  editorActions: {
    display: 'flex',
    width: '100%',
  },
  action: {
    fontWeight: 400,
  },
  previewWrapper: {

  },
  previewHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 2.18,
    marginBottom: 8
  },
  preview: {
    width: '100%',
    minHeight: 0,
    height: 0,
    border: '1px solid transparent',
    borderRadius: 4,
    transition: 'all .3s ease',
    opacity: 0,
    marginBottom: 0,
    overflow: 'hidden',
  },
  previewOpened: {
    minHeight: 200,
    opacity: 1,
    marginBottom: 62,
    height: 'initial',
    borderColor: '#d9e4e8'
  },
  previewHeaderTitle: {
    letterSpacing: 0.53,
    textTransform: 'uppercase',
  },
  previewToggle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
})

interface IProps extends WithStyles<typeof styles> {
  
}

const PreferencesEmailSignaturePage = (props: IProps) => {
  const { classes } = props;
  const previewControls = useOpen(true);
  const dispatch = useDispatch();
  const signature = useSelector((state: any) => state.user.user?.signature);
  const [content, setContent] = useState<any>('');



  const onSave = (): void => {
    dispatchSaveSignature(content)
  }


  const getContent = (): any => {
    return { __html: content }
  }



  const dispatchSaveSignature = (val: string = ''): any => {
    return dispatch(updateEmailSignature(val))
  }




  useEffect(() => {
    if(signature?.text) {
      setContent(signature.text)
    }
  }, [signature])




  return (
    <div className={classes.root}>
      
      <div className={classes.editorWrapper}>
        <span className={classes.editorHeader}>Create your email signature below</span>

        <div className={classes.editor}>
          <TinyMCEField value={content} onChange={setContent} />
        </div>

        <div className={classes.editorActions}>
          <Button primary
            onClick={onSave}
            className={classes.action}
            disabled={signature?.text === content}
          >
            Save Signature
          </Button>
        </div>
      </div>



      <div className={classes.previewWrapper}>

        <div className={classes.previewHeader}>
          <span className={classes.previewHeaderTitle}>Preview:</span>
          <span className={classes.previewToggle} onClick={previewControls.toggle}>
            {previewControls.value ? 'Close' : 'Open'} Preview
          </span>
        </div>

        <div dangerouslySetInnerHTML={getContent()}
          className={classNames(
            classes.preview,
            {[classes.previewOpened]: previewControls.value}
          )}
        />

      </div>


    </div>
  )
}


export default withStyles(styles)(PreferencesEmailSignaturePage)