import React, { MouseEvent } from 'react';
import { getUsernameWithCompany } from '../../utils/user';
import { useSelector } from 'react-redux';
import { highlight } from '../../utils/component-utils';



interface IProps {
  person: any;
  onGoToPerson: (id: number, e: MouseEvent<HTMLAnchorElement>) => any;
  className?: string;
}

const PersonToLink = (props: IProps) => {
  const { person } = props;
  const search = useSelector((state: any) => state.search);

  const onClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    if(!person.id) return;

    props.onGoToPerson(person.id, e)
  }

  const username = highlight(getUsernameWithCompany(person), search.search, search.highlight)

  try {
    return (
      <a
        href={`/contacts/${person.id}`}
        className={props.className}
        onClick={onClick}
      >
        {username}
      </a>
    )
  } catch (error) {
    return (
      <>
        {username}
      </>
    )
  }
}


export default PersonToLink