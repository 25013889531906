import { IconButton } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { useAnchor, useWindowSize } from '../../shared/hooks';
import { Theme } from '../../theme';
import { getUsername, isAdmin, isUser } from '../../utils/user';
import Menu from '../Menu';
import { goTo } from '../../utils/utils';
import { ROUTE_PREFERENCES } from '../../constants/routes';
import HeaderUserWidgetLink from './HeaderUserWidgetLink';



const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    padding: 4,
    marginTop: -2
  },
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  onLogoutClick: () => any;
}

const HeaderUserWidget = (props: IProps) => {
  const { classes, history } = props;
  const anchor = useAnchor();
  const windowSize = useWindowSize();
  const user = useSelector((state: any) => state.user.user);
  const hasFullAccess = isAdmin(user) || isUser(user)



  const goToPreferences = (e: MouseEvent<HTMLElement>): void => {
    goTo(e, ROUTE_PREFERENCES, history)
  }

  

  const handleSelect = (e: MouseEvent<HTMLElement>, option: any): void => {
    option.onClick(e, option)
  }


  const handleLogout = () => {
    props.onLogoutClick()
  }




  const getOptions = (): any[] => {
    const restrictedOptions = [] as any[];

    if(hasFullAccess) {
      restrictedOptions.push({ label: 'Preferences', onClick: goToPreferences })
    }

    return [
      ...restrictedOptions,
      { label: 'Logout', onClick: handleLogout }
    ]
  }





  if(windowSize.isMobile) {
    return (
      <div className={classes.root}>
        <span onClick={handleLogout}>Logout</span>
      </div>
    )
  }




  return (
    <div className={classes.root}>

      {hasFullAccess
        ? <HeaderUserWidgetLink id={user.id} name={getUsername(user)} />
        : <span>{getUsername(user)}</span>
      }


      <IconButton onClick={anchor.set} color="inherit" classes={{ root: classes.button }}>
        <KeyboardArrowDownIcon />
      </IconButton>


      <Menu optionValue="label"
        anchor={anchor}
        options={getOptions()}
        onSelect={handleSelect}
      />

    </div>
  )
}


export default withStyles(styles)(
  withRouter(HeaderUserWidget)
)