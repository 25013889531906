import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isAdmin } from '../../../utils/user';
import { getIds } from '../../../utils/utils';
import Select from '../../Select';
import { usePersonData } from '../hooks/people-hooks';
import { EntityField } from '../../../shared/interfaces';


interface IProps extends EntityField {
  items: string;
  groupedItems?: string;
}

const ContactInfoSelect = (props: IProps) => {
  const { name, items, groupedItems, ...otherProps } = props;
  const { value, error, readonly, onChange } = usePersonData(name);
  const user = useSelector((state: any) => state.user.user);
  const types = useSelector((state: any) => state.types[items] ?? []);
  const groupedValuesList = useSelector((state: any) => groupedItems ? state.types[groupedItems] : undefined);
  const { person, updatedPerson } = useSelector((state: any) => state.people);

  
  

  const getSubcategories = (): any[] => {
    const categories = updatedPerson.categories !== undefined ? updatedPerson.categories : person.categories;
    if(!Array.isArray(categories) || !categories?.length) return [];

    const ids = getIds(categories);
    return types
      .filter((item: any) => ids.includes(item.category))
      .sort((a: any, b: any) => a.category - b.category)
  }



  const getProfileTypes = (): any[] => {
    return isAdmin(user) ? types : types.filter((item: any) => item?.name !== 'Admin');
  }



  const memoType = useMemo(() => {
    switch(name) {
      case 'subcategories':
        return getSubcategories()


      case 'profile_type':
        return getProfileTypes()


      default:
        return types
    }
  }, [types, user.profile_type])
  




  return useMemo(() => (
    <Select
      value={value}
      readonly={readonly}
      onSelect={onChange}
      valuesList={memoType}
      error={error}
      groupedValuesList={groupedValuesList}
      {...otherProps}
    />
  ), [readonly, value, error, memoType])
}


export default ContactInfoSelect