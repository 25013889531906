import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";
import { clearSelectedForList } from "../../actions/common/list";
import { setLoadedPage } from "../../actions/common/loading";
import { setPrintData } from "../../actions/common/print";
import { deletePeople, exportPeople, getPeople, getPeopleWithUrl, importCsv, invitePerson } from "../../actions/people/people";
import ConfirmDialog from "../../components/ConfirmDialog";
import FileHandler from "../../components/handlers/FileHandler";
import List from "../../components/list/List";
import ListHeader from "../../components/list/ListHeader";
import { ROUTE_PEOPLE, ROUTE_PERSON_NEW } from "../../constants/routes";
import requestManager from "../../middleware/requestManager";
import { useOpen } from "../../shared/hooks";
import { IPrintData, ISort } from "../../shared/interfaces";
import { InviteStatus } from "../../shared/models/Person";
import { PrintType, TableType } from "../../shared/types";
import { Theme } from "../../theme";
import { isAdmin, isUser } from "../../utils/user";
import { getCompaniesCounter, getIds, goTo } from "../../utils/utils";
import AssignPeopleToProjectModal from "./assign-people-to-project/AssignPeopleToProjectModal";
import EmailModal from "./email/EmailModal";
import PrintDescription from "../../components/print/PrintDescription";



const styles = (theme: Theme) => createStyles({
  list: {
    marginTop: 0
  }
});

interface IProps extends WithStyles<typeof styles> {
}

const PeopleListPage = (props: IProps) => {
  let fileInputRef : HTMLInputElement;
  const { classes } = props;
  const deleteControls = useOpen();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: any) => state.user.user);
  const people = useSelector((state: any) => state.people);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const selected = useSelector((state: any) => state.list[TableType.PEOPLE].selected);





  const handleEditMultiple = () => {
      history.push(`/edit-multiple-contacts?items=${selected.join(',')}`, { backURL: window.location.pathname })
  };


  const handleImportClick = () => {
    fileInputRef && fileInputRef.click()
  };


  const handleExportClick = () => {
    dispatchExportPeople(selected)
  };


  const handleNext = (): Promise<any> => {
    return dispatchGetNextPeople()
  }


  const handleOpen = (e: any, id: number) : void => {
    goTo(e, `/contacts/${id}/`, history)
  }


  const handleDelete = (e: any) => {
    dispatchDeletePeopleByIds(selected)
      .then(() => dispatchClearSelected())
      .finally(deleteControls.close)
  };


  const handleInvite = (e: any, id: number) => {
    e.preventDefault();
    e.stopPropagation();

    dispatchInvitePerson(id)
  }


  const handleFileInputChange = (files: any[]) => {
    dispatchImportCSV(files![0])
  };


  const hasFullAccess = () : boolean => {
    return isAdmin(user) || isUser(user)
  }


  const canManageAdmins = (): boolean => {
    if(isAdmin(user)) return true;
    if(!isUser(user)) return false;

    const admins = people.results.filter(isAdmin);
    const adminsIds = getIds(admins);
    return !selected.some((id: number) => adminsIds.includes(id))
  }


  const hasDeleteAccess = (): boolean => {
    return isAdmin(user)
  }


  const canAssignToProject = (): boolean => {
    return !people.results
      .filter((person: any) => person.invite_status !== InviteStatus.Accepted)
      .filter((item: any) => selected.includes(item.id))
      .length
  }


  const handleSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchPeopleFetch(sort, search)
      .then((res: any) => {
        if(!pagesLoaded[ROUTE_PEOPLE]) {
          dispatchPageLoaded(ROUTE_PEOPLE)
        }
      })
  }


  const handleAssignToProject = (): void => {
    history.push(`/contacts/assignToProject?items=${selected.join(',')}`)
  }


  const handlePrint = (e: any): void => {
    // const printData: IPrintData = { title: 'Contacts', list: people.results }

    // if(!!selected.length) {
    //   printData.list = people.results.filter((person: any) => selected.includes(person.id))
    // }

    // dispatch(setPrintData(PrintType.PEOPLE, printData));
    // window.open(`/print/contacts`, '_blank')
  }


  const isInitialLoading = (): boolean => {
    return !pagesLoaded[ROUTE_PEOPLE]
  }



  const getExtraCounterComponent = (): any => {
    const filteredPeople = people.results.filter((item: any) => selected.includes(item.id));
    return getCompaniesCounter(filteredPeople)
  }



  const dispatchPeopleFetch = (sort: ISort[], search: string): any => {
    return dispatch(getPeople(sort, `${!!search ? `${search}` : ''}`))
  }


  const dispatchGetNextPeople = (): any => {
    return dispatch(getPeopleWithUrl())
  }


  const dispatchDeletePeopleByIds = (ids: number[]): any => {
    return dispatch(deletePeople(ids))
  }


  const dispatchInvitePerson = (id: number): any => {
    return dispatch(invitePerson(id))
  }


  const dispatchImportCSV = (file: File): any => {
    return dispatch(importCsv(file))
  }


  const dispatchPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }


  const dispatchExportPeople = (items: number[]): any => {
    return dispatch(exportPeople(items))
  }


  const dispatchClearSelected = (): any => {
    return dispatch(clearSelectedForList(TableType.PEOPLE))
  }



  useEffect(() => {
    ReactGA.pageview(history.location.pathname)

    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])






  return (
    <>
      <FileHandler
        onFilesSelected={handleFileInputChange}
        onInit={(ref: HTMLInputElement) => fileInputRef = ref}
      />



      <ListHeader withEmail
        loading={isInitialLoading()}
        addButtonRoute={ROUTE_PERSON_NEW}
        onImportClick={handleImportClick}
        onExport={handleExportClick}
        onDelete={deleteControls.open}
        onEditMultiple={handleEditMultiple}
        onAssignToProject={handleAssignToProject}
        assignToProjectDisabled={!canAssignToProject()}
        fullAccess={hasFullAccess()}
        canManageAdmins={canManageAdmins()}
        hasDeleteAccess={hasDeleteAccess()}
        actionsVisible={!!selected.length}
        title="Contacts"
        count={people?.count}
        onPrint={handlePrint}
        selected={selected}
        selectedCounterLabel={selected.length === 1 ? 'contact' : 'contacts'}
        extraCounterComponent={getExtraCounterComponent()}
      />
      

      <List selectable
        loading={people.loading}
        initialLoading={isInitialLoading()}
        className={classes.list}
        rows={people.results}
        count={people?.count}
        onOpen={handleOpen}
        onInvite={handleInvite}
        fullAccess={hasFullAccess()}
        tableType={TableType.PEOPLE}
        onSort={handleSort}
        onNext={handleNext}
      />


      <Route path={'/contacts/email'} component={EmailModal} />
      <Route path={'/contacts/assignToProject'} component={AssignPeopleToProjectModal} />


      


      <ConfirmDialog
        title="Are you sure?"
        open={deleteControls.value}
        cancelLabel="Cancel"
        confirmLabel="YES"
        onCancel={deleteControls.close}
        onConfirm={handleDelete}
      >
        Are you sure you want to delete these contacts?
      </ConfirmDialog>


    </>
  );

}


// PeopleListPage.whyDidYouRender = false

export default withStyles(styles)(PeopleListPage)
