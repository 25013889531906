import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const FunnelIcon = (props: any) => {
  return (
    <SvgIcon width="12" height="14" viewBox="0 0 12 14" {...props}>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M4.688 7.143a.39.39 0 0 1 .11.269v5.682l2.44-2.087V7.412a.39.39 0 0 1 .11-.269l4.13-4.499H.558l4.13 4.5zM.039 0h11.959v1.831H.039z"/>
      </g>
    </SvgIcon>
  );
};