import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { changePossibleDuplicates, clearPossibleDuplicates, getPossibleDuplicatesByIds, mergePossibleDuplicates } from '../../actions/contact-management/possible-duplicates';
import { ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES } from '../../constants/routes';
import { currentPossibleDuplicateActions } from '../../reducers/contact-management/current-possible-duplicate';
import { Theme } from '../../theme';
import DuplicatesMergeContainer from './DuplicatesMergeContainer';
import { getIds } from '../../utils/utils';



const styles = (theme: Theme) => createStyles({
  
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  ids?: string;
  className?: string;  
}

const PossibleDuplicatesMergePage = (props: IProps) => {
  const currentDuplicate = useSelector((state: any) => state.contactManagement.currentPossibleDuplicate.data);
  const duplicates = useSelector((state: any) => state.contactManagement.possibleDuplicates?.results ?? []);



  const onMerge = (withCP: boolean = false, dontIncludeCP?: boolean): any => {
    const id = duplicates[0].id;
    const mergeIds = getIds(duplicates).filter((i: number) => i !== id).join(',');
    const body = withCP ? currentDuplicate : { ...currentDuplicate, capital_provider: dontIncludeCP ? undefined : null };

    return mergePossibleDuplicates(id, mergeIds, body)
  }



  const getDuplicatesIds = (): string|undefined => {
    const query = new URLSearchParams(window.location.search);
    const items = query.get('items');

    if(!items) return;
    return items
      .split(',')
      .filter((id: string) => Number.isInteger(+id))
      .join(',')
  }



  
  return (
    <DuplicatesMergeContainer
      duplicateIds={getDuplicatesIds()}
      duplicates={duplicates}
      currentDuplicate={currentDuplicate}
      backURL={ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES}
      actions={currentPossibleDuplicateActions}
      actionMerge={onMerge}
      actionGetById={getPossibleDuplicatesByIds}
      actionChangeField={changePossibleDuplicates}
      actionClear={clearPossibleDuplicates}
      backLabel="Back to Possible Duplicates"
    />
  )
}


export default withStyles(styles)(
  withRouter(PossibleDuplicatesMergePage)
)