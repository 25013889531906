import { Popover } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Theme } from '../theme';
import Button from './Button';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  filterTasks: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    color: '#fff',
    marginLeft: 7
  },
  iconWrapper: {
    background: '#4192ec',
    height: 40,
    border: '1px solid transparent',
    '&:hover': {
      borderColor: '#4192ec',
      '& $filterTasks': {
        color: '#4192ec',
      },
      '& $buttonIcon': {
        color: '#4192ec',
      }
    }
  },
  icon: {
    fontSize: 14,
    color: '#fff'
  },
  popover: {
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
  }
})

interface IProps extends WithStyles<typeof styles> {
  label: string;
  anchor: any;
  icon?: any;
  className?: string;
  popoverClassName?: string;
  children?: any;
}

const ButtonWithDialog = (props: IProps) => {
  const { classes, icon: Icon, anchor: controls } = props;





  return (
    <div className={classNames(props.className, classes.root)}>
      <Button primary
        classes={{ root: classes.iconWrapper }}
        onClick={controls.toggle}
      >
        {Icon && <Icon className={classes.icon} />}
        <span className={classes.filterTasks}>{props.label}</span>
      </Button>


      <Popover 
        open={!!controls.value}
        anchorEl={controls.value}
        PaperProps={{ className: classNames(classes.popover, props.popoverClassName) }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={controls.close}
      >
        {props.children}
      </Popover>

    </div>
  )
}


export default withStyles(styles)(ButtonWithDialog)