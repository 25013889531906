import { Chip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useShowAll } from '../shared/hooks';
import { Theme } from '../theme';
import ShowAll from './ShowAll';
import TextInput from './TextInput';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 60,
    overflow: 'hidden',
  },
  tagsEditable: {
    borderRadius: 4,
    backgroundColor: 'transparent',
    border: '1px solid #4192ec',
    padding: 8,
    minHeight: 60

  },
  fullheight: {
    maxHeight: 'unset'
  },
  tagChip: {
    borderRadius: 14,
    backgroundColor: '#e8f2fd',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.67,
    color: '#4192ec',
    margin: '0 10px 10px 0',
    display: 'inline-flex',
    float: 'left',
  },
  chipX: {
    color: '#4192ec',
    fontSize: 12,
    width: '1em',
    height: '1em',
  },
  inputWrapper: {
    display: 'inline-flex',
    float: 'left',
    maxWidth: '100%',
    marginTop: 2,
    backgroundColor: 'transparent !important',
    border: 0,
  },
  input: {
    lineHeight: 1.67,
    padding: '0',
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  tags?: string[];
  onAdd: (tag: string) => any;
  onDelete: (tag: string) => any;
  readonly?: boolean;
  placeholder?: string;
  className?: string;
}


const Tags = (props: IProps) => {
  const { classes, readonly } = props
  const showAll = useShowAll()
  const [value, setValue] = useState<string>('')
  const [overflowing, setOverflowing] = useState<boolean>(false)
  let inputRef: HTMLInputElement





  const focusInput = (e: any) => {
    inputRef && inputRef.focus()
  }



  const onAdd = () => {
    props.onAdd(value)
    setValue('')
  }



  const tagsContainer = (
    <div
      className={classNames(
        classes.tags,
        {
          [classes.fullheight]: showAll.value || !readonly,
          [classes.tagsEditable]: !readonly,
        },
        props.className
      )}
      onClick={focusInput}
      ref={ref => {
        if (ref) setOverflowing(ref.offsetHeight < ref.scrollHeight || showAll.value)
      }}
    >


      {props.tags?.map((tag: string, idx: number) => (
        <Chip
          key={tag}
          size="small"
          label={tag}
          className={classes.tagChip}
          onDelete={readonly ? undefined : (() => props.onDelete(tag))}
          deleteIcon={readonly ? undefined : <ClearIcon className={classes.chipX} />}
        />
      ))}


      {!readonly
        ? (
          <TextInput transparent initialHeight
            value={value}
            onRef={(ref: HTMLInputElement) => inputRef = ref}
            className={classes.inputWrapper}
            onChange={(value: string) => setValue(value)}
            onEnter={onAdd}
            placeholder={props.placeholder}
            inputStyle={classes.input}
          />
        )
        : null
      }
    </div>
  )




  
  return (
    <div className={classes.root}>
      {tagsContainer}
      {readonly && <ShowAll showAll={showAll} label="Show more..." isOverflowing={overflowing} alignRight />}
    </div>
  )
}


export default withStyles(styles)(Tags)