import React, { Fragment, useEffect, useState } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import TaskStatusLabel from './TaskStatusLabel';
import { AttachmentIcon } from '../../icons/AttachmentIcon';
import { ProfileColorHex, ProfileType, TaskType, TaskAction } from '../../../shared/types';
import classNames from 'classnames';
import { acronym, arrayRange } from '../../../utils/utils';
import { getUsername, isAdmin, isOwner } from '../../../utils/user';
import { useSelector, useDispatch } from 'react-redux';
import PersonWithAvatar from '../../PersonWithAvatar';
import Avatar from '../../Avatar';
import { rejectTask, approveTask } from '../../../actions/tasks';
import Skeleton from '../../Skeleton';
import { highlight } from '../../../utils/component-utils';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '10px 12px',
    border: '1px solid #d9e4e8',
    marginBottom: 8,
    borderRadius: 4,
    boxShadow: '0 2px 4px 0 rgba(189, 194, 196, 0.16)',
    cursor: 'pointer',
    transition: 'box-shadow .1s ease',
    '&:hover': {
      boxShadow: '0 2px 8px 0 rgba(189, 194, 196, 0.6)',
    },
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#233539',
      maxWidth: '88%',
      display: 'block',
      minHeight: 54,
    }
  },
  done: {
    backgroundColor: '#f7f9fa',
    boxShadow: 'none',
    border: 0
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  status: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1.64,
    color: '#4caf50',
  },
  roundChips: {
    display: 'flex',
    alignItems: 'center'
  },
  attachment: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 11,
      fontWeight: 400,
      lineHeight: 1.64,
      color: '#657274',
    }
  },
  attachmentIcon: {
    fontSize: 19,
  },
  avatar: {
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'relative',
    '& > span': {
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.64,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)'
    }
  },
})

interface IProps extends WithStyles<typeof styles> {
  tasks: any[];
  onOpenTask: (id: number) => any;
}

const BoardTasks = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const team = useSelector((state: any) => state.teamMembers);
  const search = useSelector((state: any) => state.search);




  const isDone = (state: TaskType) : boolean => {
    return [TaskType.Approved, TaskType.Submitted].includes(state)
  }



  const showPopover = (task: any): boolean => {
    if(!isAdmin(user) && !isOwner(user, team)) return false;

    return task.state === TaskType.Submitted;
  }



  const getAssignees = (arr: any[]) : any[] => {
    return arr.length > 3 ? arrayRange(0, 3, arr) : arr
  }



  const onApproveOrReject = (action: TaskAction, taskId: number): void => {
    switch(action) {
      case TaskAction.Approve:
        return dispatchApproveTask(taskId)
          .then((res: any) => {})
          .catch((err: any) => {})
      case TaskAction.Reject:
        return dispatchRejectTask(taskId)
          .then((res: any) => {})
          .catch((err: any) => {})
    }
  }



  const onOpenTask = (e: any, taskId: number): void => {
    e.preventDefault();
    e.stopPropagation();

    props.onOpenTask(taskId)
  }



  const checkHighlight = (text: string): any => {
    if(!text || !search.highlight || !search.search) return text;
    return highlight(text, search.search)
  }







  const dispatchApproveTask = (taskId: number): any => {
    return dispatch(approveTask(taskId))
  }

  const dispatchRejectTask = (taskId: number): any => {
    return dispatch(rejectTask(taskId))
  }







  return (
    <Fragment>
      {props.tasks.map((task: any) => (
        <div
          key={task.id}
          onClick={(e: any) => onOpenTask(e, task.id)}
          className={classNames(
            classes.root,
            {[classes.done]: isDone(task.state)}          
          )}
        >
          <span>{checkHighlight(task.title)}</span>
          <div className={classes.info}>
            <TaskStatusLabel
              type={task.state}
              dueDate={task.due_date}
              onApproveOrReject={showPopover(task) ? (action: TaskAction) => onApproveOrReject(action, task.id) : undefined}
            />
            <div className={classes.roundChips}>
              {task.files && task.files.length
                ? (
                    <div className={classes.attachment}>
                      <AttachmentIcon className={classes.attachmentIcon} />
                      <span>{task.files.length}</span>
                    </div>
                  )
                : null
              }
              <div className={classes.avatar}>
                {getAssignees(task.assignees_details).map((assignee: any) => (
                  <Avatar key={assignee.id} person={assignee} />
                ))}

                {task.assignees_details.length > 3
                  ? <span>+{task.assignees_details.length - 3}</span>
                  : null
                }
                
              </div>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  )
}


export default withStyles(styles)(BoardTasks)