import { TaskType } from "../types";

export interface Task {
  assignees: number[];
  assignees_details?: any[];
  column?: number;
  created_by?: number;
  created_by_details?: any;
  description: string;
  due_date: string;
  files: any[];
  id?: number;
  project: number;
  project_details?: any;
  state: TaskType;
  title: string;
}

export const initialTask = {
  comments: [],
  assignees: [],
  column: undefined,
  created_by: undefined,
  description: '',
  due_date: new Date(),
  files: [],
  id: undefined,
  project: undefined,
  state: TaskType.ToDo,
  title: ''
}


export const TaskRequestFields = [
  'assignees',
  'column',
  "created_by",
  "description",
  "due_date",
  "files",
  "project",
  "state",
  "title",
  "comments"
];

export const DEFAULT_TASK_CATEGORY = 1;