import React, { memo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import TableRowCell from './TableRowCell';
import Checkbox from '../../Checkbox';

const styles = (theme: Theme) => createStyles({
  root: {
    paddingLeft: 16,
    paddingRight: 20,
    width: 20,
    cursor: 'pointer',
    padding: '6px 14px 6px 0 !important'
  },
  checkbox: {
    padding: 5
  },
})

interface IProps extends WithStyles<typeof styles> {
  isSelected: boolean;
  onChange: (e: any, id: number) => any;
  className?: string;
}

const TableRowCheckbox = ({ classes, className, isSelected, onChange }: IProps) => {
  return (
    <TableRowCell className={classNames(classes.root, className)}>
      <Checkbox
        checked={isSelected}
        onChange={onChange}
        className={classes.checkbox}
      />
    </TableRowCell>
  )
}

export default withStyles(styles)(
  memo(TableRowCheckbox, (prev: IProps, next: IProps) => {
    return prev.isSelected === next.isSelected && prev.className === next.className
  })
)