import React, { forwardRef } from "react";
import { Theme } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {Card, WithStyles} from "@material-ui/core";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  card: {
    boxShadow: '0 16px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    borderRadius: 0
  }
});

interface IProps extends WithStyles<typeof styles> {
  children?: any,
  extraClass?: any
}

const BaseCard = forwardRef((props: IProps, ref: any) => (
  <Card ref={ref} className={classNames(
    props.classes.card,
    {[props.extraClass]: !!props.extraClass}
  )}>
    {props.children}
  </Card>
))

export default withStyles(styles)(BaseCard)