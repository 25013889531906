import organizationsService from '../services/organizations';

/**
 * Actions
 */

export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';



/**
 * Action creators
 */

export function setAllOrganizations(organizations: any): object {
  return {type: SET_ORGANIZATIONS, organizations}
}



/**
 * Async action creators
 */

export const getOrganizations = () => {
  return (dispatch) => {
    return organizationsService.getOrganizations()
      .then((res: any) => dispatch(setAllOrganizations(res)))
      .catch(err => {
        console.log('An error occured while getting the contacts.')
      })
  }
}