import React, { useMemo } from 'react';
import { Theme } from '../../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import TaskInput from '../TaskInput';
import { EntityField } from '../../../../shared/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { createComment } from '../../../../actions/comments';
import { setAddingNewComment, changeTaskField } from '../../../../actions/tasks';


const styles = (theme: Theme) => createStyles({
  root: {
    minHeight: 58,
    paddingTop: '8px !important'
  },
  newComment: {
    paddingBottom: '20px !important',
  },
  newCommentWrapper: {
    position: 'relative',
    marginTop: 5
  },
  hidden: {
    display: 'none !important'
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 11,
    right: 8,
    '& > svg': {
      fontSize: 18,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:first-child': {
        marginRight: 5
      }
    }
  },
  iconsEdit: {
    bottom: 15
  },
  iconsNewComment: {
    bottom: 3
  }
})

interface IProps extends WithStyles<typeof styles> {
  isNew?: boolean;
  [otherProp: string]: any;
}

const TaskNewComment = ({ classes, isNew, ...otherProps }: IProps) => {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const { task, updatedTask } = useSelector((state: any) => state.board.tasks);
  const isAdding = useSelector((state: any) => state.board.tasks.taskView.newComment);
  const text = updatedTask.comments ?? task.comments ?? '';





  const handleNewCommentCancel = (): void => {
    dispatchSetIsAdding(false);
    dispatchChangeTaskField(undefined)
  }
  



  const onAddNewComment = (): void => {
    const body = { text, task: task.id, creator: user.id };
    
    dispatchCreateComment(body).then(handleNewCommentCancel)
  }



  const dispatchCreateComment = (body: any): Promise<any> => {
    return dispatch(createComment(body))
  }



  const dispatchSetIsAdding = (state: boolean): any => {
    return dispatch(setAddingNewComment(state))
  }


  
  const dispatchChangeTaskField = (value: string|undefined): any => {
    return dispatch(changeTaskField({ comments: value }))
  }





  return useMemo(() => (
    <div className={classNames({
      [classes.hidden]: !isNew && !isAdding,
      [classes.newCommentWrapper]: !isNew,
    })}>


      <TaskInput
        multiline collapsible
        name="comments"
        placeholder={isNew ? 'Start typing comment...' : 'Add a comment'}
        inputStyle={classNames(
          classes.root,
          {[classes.newComment]: !isNew}
        )}
        {...otherProps}
      />



      <div className={classNames(
        classes.icons,
        classes.iconsEdit,
        classes.iconsNewComment,
        {[classes.hidden]: isNew}
      )}>
        <CheckIcon onClick={onAddNewComment} />
        <ClearIcon onClick={handleNewCommentCancel} />
      </div>
    </div>
   
  ), [text, isAdding, user.id, isNew])
}


export default withStyles(styles)(TaskNewComment)