import * as React from "react";
import { Theme } from "../../theme";
import { createStyles, withStyles } from "@material-ui/styles";
import { WithStyles } from "@material-ui/core";
import TextInputForm, { IInput } from "./TextInputForm";
import ButtonForm, { IButton } from "./ButtonForm";
import { IBaseFormProps } from "./BaseForm";

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: 4,
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
    padding: '20px 50px',
    textAlign: 'start'
  },
  buttonsContainer: {
  },
  error: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 1.56,
    color: '#d52023',
    // width: '81%',
    display: 'flex',
    paddingTop: 10,
  }
});

interface IProps extends IBaseFormProps, WithStyles<typeof styles> {
  inputs: IInput[],
  buttons: IButton[],
  errors?: string[],
}

class InputsAndButtonsForm extends React.Component<IProps> {
  render() {
    const { classes, inputs, buttons, header, description } = this.props;
    const errors = this.props.errors || []
    return (
      <div className={classes.root}>
        <TextInputForm
          inputs={inputs}
          header={header}
          description={description}
        />
        {(errors.length > 0) ? <p className={classes.error}>
          {errors.join('\n')}
        </p> : <span />}

        <div className={classes.buttonsContainer}>
          <ButtonForm
            buttons={buttons}
          >
          </ButtonForm>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(InputsAndButtonsForm)