import React, { Fragment, useMemo, memo, MouseEvent } from "react";
import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { ROUTE_PRIVACY_POLICY, ROUTE_COOKIE_POLICY, ROUTE_TS_AND_CS, ROUTE_DASHBOARD } from "../../constants/routes";
import { goTo } from "../../utils/utils";
import { useHistory } from "react-router";


const styles = () => createStyles({
  footer: {
    width: '100%',
    height: 45,
    backgroundColor: '#f0f3f5',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderTop: '1px solid #d9e4e8'
  },
  logo: {
    padding: '12px 20px',
    cursor: 'pointer',
    '& > img': {
      height: 21
    }
  },
  rightSection: {
    display: 'flex'
  },
  copyright: {
    height: '100%',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 15
  },
  policies: {
    height: '100%',
    fontFamily: 'Montserrat',
    fontSize: 11,
    color: '#657274',
    display: 'flex',
    alignItems: 'center',
    paddingRight: 17
  },
  link: {
    padding: 3,
    textDecoration: 'none',
    color: '#657274',
    '&:visited': {
      color: '#657274'
    }
  },
  '@media (max-width: 479.98px)': {
    footer: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      padding: '14px 10px 13px',
      boxSizing: 'border-box',
      alignItems: 'flex-start',
    },
    logo: {
      padding: 0,
      display: 'flex',
      marginBottom: 8
    },
    rightSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    copyright: {
      lineHeight: 1.82,
      padding: 0
    },
    policies: {
      padding: 0,
      flexWrap: 'wrap'
    },
    link: {
      '&:first-child': {
        paddingLeft: 0
      }
    }
  }
});



interface IProps extends WithStyles<typeof styles> {
}


const Footer = (props: IProps) => {
  const { classes } = props;
  const history = useHistory();





  const goToUrl = (e: MouseEvent<HTMLAnchorElement>, path: string): void => {
    goTo(e, path, history)
  }



  const routes = useMemo(() => [
    { path: ROUTE_PRIVACY_POLICY, label: 'Privacy Policy',      onClick: (e: any) => goToUrl(e, ROUTE_PRIVACY_POLICY) },
    { path: ROUTE_COOKIE_POLICY,  label: 'Cookie Policy',       onClick: (e: any) => goToUrl(e, ROUTE_COOKIE_POLICY) },
    { path: ROUTE_TS_AND_CS,      label: 'Terms & Conditions',  onClick: (e: any) => goToUrl(e, ROUTE_TS_AND_CS) },
  ], [])




  return (
    <div className={classes.footer}>
      <a className={classes.logo} href={ROUTE_DASHBOARD}>
        <img src="/img/full_logo.svg" alt="" />
      </a>

      <div className={classes.rightSection}>

        <span className={classes.copyright}>
          FinTech8 ©2020
        </span>


        <div className={classes.policies}>
          {routes.map((item: any, idx: number) => (
            <Fragment key={idx}>
              <a
                href={item.path}
                className={classes.link}
                onClick={item.onClick}
              >
                {item.label}
              </a>
              {idx === routes.length - 1 ? '' : ' | '}
            </Fragment>
          ))}
        </div>

      </div>
    </div>
  );
}

export default withStyles(styles)(
  memo(Footer)
)