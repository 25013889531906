import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { ProviderLabel, MembershipStateLabel } from '../shared/models/Project';
import { ProviderColorHex } from '../shared/types';
import { Theme } from '../theme';

const styles = (theme: Theme) => createStyles({
  root: {
    borderRadius: 14,
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: 2,
    textAlign: 'center',
    padding: '2px 0',
    display: 'block',
    width: 'max-content',
    minWidth: 94,
    boxSizing: 'border-box',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
})

interface IProps extends WithStyles<typeof styles> {
  label: ProviderLabel;
}

const ProjectMembershipLabel = (props: IProps) => {
  const { classes } = props;

  return (
    <span className={classes.root}>
      {MembershipStateLabel[props.label]}
    </span>
  )
}


export default withStyles(styles)(ProjectMembershipLabel)