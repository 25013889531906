import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function getTypes() {
  return Request.get(`${API_URL}/utils/types/`)
}


export default {
  getTypes
}