import { Dialog, LinearProgress } from "@material-ui/core";
import Button from "../../../components/Button";
import BaseCard from "../../../components/BaseCard";
import { ModalContent } from "../../../components/modal/ModalContent";
import { ModalFooter } from "../../../components/modal/ModalFooter";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect, } from "react-redux";
import EmailForm from './EmailForm'
import { sendEmailsPromise, sendCompanyEmailsPromise } from "../../../actions/people/people";
import { NotificationTypes } from "../../../actions/common/notifications";
import { withStyles, WithStyles, createStyles } from "@material-ui/styles";
import { Theme } from "../../../theme";
import { ModalHeader } from "../../../components/modal/ModalHeader";
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import EmptyMail, { EmptyIcon } from "../../../components/EmptyMail";



const styles = (theme: Theme) => createStyles({
  modal: {
    position: 'relative',
    paddingBottom: 60,
    display: 'flex',
    flexDirection: 'column'
  },

  dialog: {
    maxWidth: '76.56%',
    width: '100%',
  },
  sendButtonsWrapper: {
    display: 'flex',
    '& > $button': {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  button: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    height: 40,
    padding: '8px 16px',
  },
  buttonSecondary: {
    border: `1px solid ${theme.palette.primary.main}`
  },

  closeIcon: {
    fontSize: 25,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  header: {
    justifyContent: 'flex-end',
    borderBottom: 0
  },
  content: {
    paddingTop: 0
  },
  outlookPrompt: {
    padding: '60px 0 19px 0'
  }

});

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  dispatch: Function,
  store: any,
  user: any,
}

const EmailModal = (props: IProps) => {

  const { classes, history, dispatch, store, user } = props;


  const { loading } = store;


  const goBack = () => {
    history.push('/contacts')
  };

  const onSend = () => {
    dispatch(sendEmailsPromise(store)).then((notification: any) => {

      if (notification.notificationType === NotificationTypes.SUCCESS) {

        goBack();
      }

    })
  };

  const onSendCompanyEmail = () => {
    dispatch(sendCompanyEmailsPromise(store)).then((notification: any) => {
      if (notification.notificationType === NotificationTypes.SUCCESS) {
        goBack();
      }
    })

  }
  
  return <Dialog disableEscapeKeyDown open={true} onClose={goBack} maxWidth={'xl'} PaperProps={{ classes: { root: classes.dialog } }}>

    <BaseCard extraClass={classes.modal}>
      {loading && <LinearProgress />}

      {user.is_ms_authorized
        ? (
          <React.Fragment>
            <ModalHeader className={classes.header}>
              <ClearIcon className={classes.closeIcon} onClick={goBack} />
            </ModalHeader>

            <ModalContent className={classes.content}>

              <EmailForm />

            </ModalContent>

            <ModalFooter>
              <div className={classes.sendButtonsWrapper}>
                <Button primary onClick={onSend} className={classes.button}>Send</Button>
                <Button primary onClick={onSendCompanyEmail} className={classes.button}>Send company email</Button>
              </div>
              <Button
                onClick={goBack}
                className={classNames(classes.button, classes.buttonSecondary)}
              >
                Cancel
                </Button>
            </ModalFooter>
          </React.Fragment>
        )
        : (
          <div className={classes.outlookPrompt}>
            <EmptyMail 
              icon={EmptyIcon.Mail}
              text='You need to be authenticated with Outlook to do that'
              showConnect={true}
            />
          </div>
        )
      }

    </BaseCard>


  </Dialog>

};

const mapStateToProps = state => ({
  store: state.emailPeople,
  user: state.user.user
});

export default connect(mapStateToProps)(
  withStyles(styles)(
    withRouter(EmailModal)
  )
)