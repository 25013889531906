import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearSelectedForList } from '../../actions/common/list';
import { setLoadedPage } from '../../actions/common/loading';
import { deleteMultipleTemplates, getEmailTemplates, getNextTemplates } from '../../actions/email-templates';
import ConfirmDialog from '../../components/ConfirmDialog';
import { EmptyTemplatesIcon } from '../../components/icons/EmptyTemplatesIcon';
import List from '../../components/list/List';
import { ROUTE_EMAIL_MANAGEMENT_TEMPLATES } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { useOpen } from '../../shared/hooks';
import { ISort } from '../../shared/interfaces';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import ManageableTableCard from '../common/ManageableTableCard';

const styles = (theme: Theme) => createStyles({
  list: {
    boxShadow: 'none',
    margin: 0
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  className?: string;
}

const EmailTemplatesPage = (props: IProps) => {
  const { classes, history } = props;
  const deleteControls = useOpen();
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search.search);
  const templates = useSelector((state: any) => state.emailManagement.templates);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const selected = useSelector((state: any) => state.list[TableType.TEMPLATES].selected);






  const onOpen = (e: MouseEvent<HTMLDivElement>, id: number): void => {
    e.preventDefault();
    e.stopPropagation();

    history.push(`${ROUTE_EMAIL_MANAGEMENT_TEMPLATES}/${id}`);
  }



  const onConfirmDelete = (): void => {
    dispatchDeleteMultipleTemplates(selected)
      .then(() => {
        deleteControls.close();
        dispatchClearSelected()
      })
  }


  

  const onSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchGetTemplates(sort, search)
      .then(() => {
        if(!pagesLoaded[ROUTE_EMAIL_MANAGEMENT_TEMPLATES]) {
          dispatchOnPageLoaded(ROUTE_EMAIL_MANAGEMENT_TEMPLATES)
        }
      })
  }




  const dispatchGetTemplates = (sort: ISort[], search: string): any => {
    return dispatch(getEmailTemplates(sort, search))
  }



  const dispatchDeleteMultipleTemplates = (ids: number[]): any => {
    return dispatch(deleteMultipleTemplates(ids))
  }


  
  const dispatchOnPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }


  
  const dispatchGetNextTemplates = (): Promise<any> => {
    return dispatch(getNextTemplates())
  }



  const dispatchClearSelected = (): any => {
    return dispatch(clearSelectedForList(TableType.TEMPLATES))
  }






  useEffect(() => {
    return () => {
      requestManager.cancelAllRequests();
    }
  }, [])





  const actions = [
    { label: 'Delete', onClick: deleteControls.open, disabled: !selected.length, popover: !selected.length && "Select a draft first" },
  ]


  const itemsEmpty = {
    icon: EmptyTemplatesIcon,
    title: 'No templates',
    description: search ? 'No templates found.' : 'You don’t have any templates yet.'
  }





  return (
    <ManageableTableCard
      isEmpty={templates.initiated && !templates.results.length && search === undefined}
      actions={actions}
      itemsEmpty={itemsEmpty}
    >


      
      <List absolutePagination fullAccess selectable
        loading={templates.loading}
        initialLoading={!pagesLoaded[ROUTE_EMAIL_MANAGEMENT_TEMPLATES]}
        className={classes.list}
        rows={templates.results}
        count={templates.count ?? templates.results.length}
        tableType={TableType.TEMPLATES}
        onSort={onSort}
        onNext={dispatchGetNextTemplates}
        onOpen={onOpen}
      />
      


      <ConfirmDialog
        title="Confirm Delete"
        open={deleteControls.value}
        confirmLabel="Delete"
        onCancel={deleteControls.close}
        onConfirm={onConfirmDelete}
      >
        Are you sure you want to delete {selected.length > 1 ? 'these templates' : 'this template'}?
      </ConfirmDialog>


    </ManageableTableCard>
  )
}


export default withStyles(styles)(
  withRouter(EmailTemplatesPage)
)