import React, { useMemo } from 'react';
import TextInput from '../../TextInput';
import { useTaskData } from './hooks/tasks-hooks';
import { EntityField } from '../../../shared/interfaces';

interface IProps extends EntityField {
  readonly?: boolean;
}


const TaskInput = ({ name, readonly, ...otherProps }: EntityField) => {
  const { value, error, onChange } = useTaskData(name);



  

  return useMemo(() => (
    <TextInput
      showError
      value={value}
      readonly={readonly}
      onChange={onChange}
      error={error}
      hidden={!value && readonly}
      {...otherProps}
    />
  ), [readonly, value, error])
}


export default TaskInput