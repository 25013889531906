import React, { useEffect } from 'react';


const OutlookConnect = (props: any) => {
  useEffect(() => {
      window.close()
  }, [])
  return null
}


export default OutlookConnect