import * as React from "react";
import {WithStyles} from "@material-ui/core";
import {createStyles, withStyles} from "@material-ui/styles";
import {Theme} from "../../theme";
import Text from "../Text";

const styles = (theme: Theme) => createStyles({
  header: {
    textAlign: 'center'},
  description: {
    textAlign: 'center',
    marginTop: 16,
  },
  children: {
    marginTop: 0,
    '$description + &, $header + &': {
      marginTop: 24,
    }
  },
});

export interface IBaseFormProps {
  header?: string,
  description?: string,
  children?: any
}

interface IProps extends IBaseFormProps, WithStyles<typeof styles> {

}

class BaseForm extends React.Component<IProps> {
  render() {
    const {header, description, children, classes} = this.props;

    return (
      <div>
        {header && (
          <Text 
            variant="h1"
            className={classes.header}
          >
          {header}
          </Text>
        )}
        {description && (
          <Text
            variant="data1"
            className={classes.description}
          >
            {description}
          </Text>
        )}
        {children && (
          <div className={classes.children}>
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(BaseForm)