import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import { login } from "../../actions/auth";
import NewLoginPage from "../../components/NewLoginPage";
import { ROUTE_DASHBOARD, ROUTE_LOGIN } from "../../constants/routes";
import { isAuthenticated } from "../../utils/user";




const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);



  useEffect(() => {
    ReactGA.pageview(ROUTE_LOGIN)
  }, [])



  const handleLoginClick = (email: string, password: string): void => {
    dispatchOnLogin(email, password)
      .then(() => history.push(ROUTE_DASHBOARD))
  };



  const handleResetPasswordClick = (): void => {
    history.push('/password-reset')
  };



  const dispatchOnLogin = (email: string, password: string): Promise<any> => {
    return dispatch(login({ email, password }))
  }



  
  return isAuthenticated(user)
    ? <Redirect to={ROUTE_DASHBOARD} />
    : (
        <NewLoginPage
          onLoginClick={handleLoginClick}
          onResetPasswordClick={handleResetPasswordClick}
        />
      )
}



export default LoginPage