import CloseIcon from '@material-ui/icons/Close';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPeopleLocal, searchPeopleLocal } from '../actions/people/people';
import { getSearchHistoryItem, shareSearch } from '../actions/search-history';
import { useTimeout } from '../shared/hooks';
import { Theme } from '../theme';
import { isAdmin, isUser } from '../utils/user';
import { getIds } from '../utils/utils';
import Button from './Button';
import PersonListItem from './PersonListItem';
import TextInput from './TextInput';



const styles = (theme: Theme) => createStyles({
  '@keyframes show': {
    '0%': {
      opacity: 0,
      height: 0,
    },
    '50%': {
      height: 0,
    },
    '100%': {
      opacity: 1,
      height: '100%'
    }
  },
  root: {
    width: '100%',
    opacity: 0,
    height: 0,
    animation: `1s 1 1.4s $show forwards`
  },
  search: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 2.18,
    letterSpacing: 0.53,
    color: '#233539',
    textTransform: 'uppercase',
  },
  searchTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 3
  },
  closeIcon: {
    color: theme.palette.primary.main,
    fontSize: 18,
    cursor: 'pointer',
  },
  owners: {
    margin: '15px 0 10px'
  },
  owner: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    padding: '10px 0',
    cursor: 'pointer',
    transition: 'all .1s ease',
    '&:hover': {
      padding: '10px 5px',
      backgroundColor: theme.palette.primary.light
    }
  },
  ownerCheckbox: {
    marginRight: 10,
    transform: 'translateY(-4px)'
  },
  ownerData: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%'
  },
  ownerIcon: {
    marginRight: 12,
    fontSize: 14,
  },
  ownerInfo: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1,
    color: '#657274',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    transform: 'translateY(-1px)'
  },
  ownerName: {
    fontWeight: 500,
    color: '#233539',
    marginBottom: 6
  },
  ownerCaption: {

  },
  shareButtonWrapper: {
    width: '100%',
    marginBottom: 14,
  },
  shareButton: {
    fontWeight: 400,
    width: '100%',
    height: 30,
  },
  nonOwners: {
    borderTop: '1px solid #d9e4e8',
    paddingTop: 11
  },
  nonMembersList: {

  },
})

interface IProps extends WithStyles<typeof styles> {
  id: number;
  onClose: (e?: any) => any;
}

const ShareWithPeople = (props: IProps) => {
  const { classes } = props;
  const timeout = useTimeout(700);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const searchHistory = useSelector((state: any) => state.searchHistory.history?.results ?? []);
  const people = useSelector((state: any) => state.people.componentPeople.results);
  const [autocompleteValue, setAutocompleteValue] = useState<string>('');
  const [newOwners, setNewOwners] = useState<any[]>([]);





  const getSearchItem = (): any => {
    return searchHistory.find((item: any) => item.id === props.id)
  }



  const getShareButtonLabel = (): string => {
    const len = newOwners.length;
    return `Share search with ${len ? `(${len}) ` : ''} ${len === 1 ? 'contact' : 'contacts'}`
  }



  const getNonOwners = (): any[] => {
    const item = getSearchItem();
    if(!people?.length || !item) return [];

    const ownersIds = [...getIds(newOwners), user.id];
    const oldOwnersIds = getIds(item.owners);

    return people
      .filter((person: any) => !ownersIds.includes(person.id))
      .filter((person: any) => isAdmin(person) || isUser(person))
      .sort((a: any, b: any) => {
        if(oldOwnersIds.includes(a.id) && oldOwnersIds.includes(b.id)) return 0;
        if(oldOwnersIds.includes(a.id) && !oldOwnersIds.includes(b.id)) return 1;
        if(!oldOwnersIds.includes(a.id) && oldOwnersIds.includes(b.id)) return -1;
      })
  }



  const isAlreadyOwner = (id: number): boolean => {
    const item = getSearchItem();
    return item?.owners && item.owners.some((person: any) => person.id === id)
  }



  const onShare = (e: any): void => {
    dispatchShareSearch(props.id, getIds(newOwners))
      .then((res: any) => props.onClose())
      .catch((err: any) => {})
  }



  const onChange = (value: string, e: any): void => {
    setAutocompleteValue(value)
  }



  const onOwnerRemove = (item: any): void => {
    if(!item) return;

    setNewOwners(newOwners.filter((owner: any) => owner.id !== item.id))
  }



  const onOwnerAdd = (item: any): void => {
    if(!item) return;

    setNewOwners([...newOwners, item])
  }



  const dispatchSearchPeople = (str: string): any => {
    return dispatch(searchPeopleLocal(str))
  }



  const dispatchShareSearch = (id: number, ids: number[]): any => {
    return dispatch(shareSearch(id, ids))
  }



  const dispatchClearPeople = (): any => {
    return dispatch(clearPeopleLocal())
  }





  useEffect(() => {
    return () => {
      dispatchClearPeople()
    }
  }, [])




  useEffect(() => {
    if(!!autocompleteValue) {
      timeout.reset(() => dispatchSearchPeople(autocompleteValue))
    }
  }, [autocompleteValue])








  return (
    <div className={classes.root}>
      <div className={classes.search}>

        <div className={classes.searchTitle}>
          <span className={classes.title}>Share with contacts</span>
          <CloseIcon className={classes.closeIcon} onClick={props.onClose} />
        </div>

        <TextInput
          value={autocompleteValue}
          onChange={onChange}
        />


        <div className={classes.owners}>
          {!!newOwners.length && newOwners.map((person: any) => (
            <PersonListItem checked key={person.id} person={person} onClick={onOwnerRemove} />
          ))}
        </div>


        <div className={classes.shareButtonWrapper}>
          <Button primary
            className={classes.shareButton}
            onClick={onShare}
          >
            {getShareButtonLabel()}
          </Button>
        </div>


      </div>


      <div className={classes.nonOwners}>
        <span className={classes.title}>Share with more contacts</span>


        <div className={classes.nonMembersList}>
          {getNonOwners().map((person: any) => (
            <PersonListItem key={person.id}
              checked={false}
              person={person}
              onClick={onOwnerAdd}
              disabled={isAlreadyOwner(person.id)}
            />
          ))}
        </div>
      </div>


    </div>
  )
}


export default withStyles(styles)(ShareWithPeople)