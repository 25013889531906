import { WithStyles } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { clearPrintData } from "../../actions/common/print";
import { createDraft } from "../../actions/email-drafts";
import { ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES } from "../../constants/routes";
import { Theme } from "../../theme";
import Button, { ButtonSize } from "../Button";
import ButtonWithMenu from "../ButtonWithMenu";
import Skeleton from "../Skeleton";
import { isAdmin } from "../../utils/user";



const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  },
  leftItems: {
    display: 'flex'
  },
  rightItems: {
    display: 'flex',
    alignItems: 'center'
  },
  addButtonRoot: {
    width: 75,
    padding: 0
  },
  icon: {
    width: 20,
    height: 18
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
    '&:last-child': {
      marginRight: 0
    }
  },

  smallerIcon: {
    fontSize: 20
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#233539',
  },
  smallTitle: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  deleteButton: {
    padding: '0 10px',
    color: "#d52023",
    marginLeft: 20,
    backgroundColor: 'transparent',
    transition: 'none',
    '&:hover': {
      backgroundColor: "#d52023",
      color: "#fff"
    },
  },

  editButton: {

    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#4192ec',
    borderColor: '#4192ec',
    border: 'solid 1px',
    borderRadius: 5,
    padding: '4px 10px',
    lineHeight: 'normal',
    height: 'auto',
    margin: '0 5px',
    minWidth: 'auto',
    cursor: 'pointer',
    transition: 'none',
  },


  deleteButtonContainer: {
    opacity: 0,
    display: 'none',
    transition: 'none',
    marginLeft: 15,
    width: 'auto',
    '&$opened': {
      opacity: 1,
      display: 'unset'
    },

  },
  opened: {},
  viewMore: {
    color: '#4192ec',
    fontSize: 12,
    textDecorationLine: 'unset',
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  printIcon: {
    fontSize: 22,
    marginRight: 20,
    color: theme.palette.primary.main,
  },
  actionsButton: {
    '& button': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
      height: 'initial',
      padding: '0 7px',
      textTransform: 'capitalize'
    }
  },
  arrowDownIcon: {
    fontSize: 20,
    color: '#fff',
    marginLeft: 3
  },
  skeleton: {
    width: 200,
    height: 30,
    display: 'flex'
  },
  skeletonButtonRound: {
    width: 26,
    height: 26,
    borderRadius: '50%',
    marginRight: 20,
    '&:last-child': {
      marginRight: 0
    }
  },
  skeletonButtonRect: {
    width: 75,
    height: 30,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    }
  },
  skeletonButtonLink: {
    width: 100,
    height: 20
  },
  manageActionsButton: {
    marginRight: 10,
    '& button': {
      padding: '3px 10px',
      textTransform: 'capitalize'
    }
  },
  selectedLabel: {
    display: 'flex',
    fontSize: 12,
    lineHeight: 2,
    fontFamily: 'Montserrat',
    color: '#919a9c',
    visibility: 'hidden',
    marginBottom: 5,
    width: '100%',
    textTransform: 'lowercase'
  },
  selectedLabelVisible: {
    visibility: 'visible'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  boldTitle: {
    fontWeight: 500
  }
});

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  title: string
  addButtonRoute?: string
  fullAccess?: boolean
  canManageAdmins?: boolean
  hasDeleteAccess?: boolean
  count?: number
  actionsVisible?: boolean
  onDelete?: any
  onImportClick?: any
  onExport?: any
  viewMore?: any
  onEditMultiple?: any
  smallHeader?: boolean,
  onAssignToProject?: any,
  assignToProjectDisabled?: boolean,
  onPrint?: (e: any) => any,
  loading?: boolean,
  selected?: any,
  withEmail?: boolean,
  selectedCounterLabel?: string,
  extraCounterComponent?: any,
  bold?: boolean,
  className?: string,
  customRightItem?: any,
}

const ListHeader = (props: IProps) => {
  const { classes, selected } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);


  const onPrint = (e: any): void => {
    // dispatch(clearPrintData());
    // props.onPrint && props.onPrint(e)

    window.print();
  }

  

  const onEmailClick = (is_company: boolean = false): void => {
    const body = { to: [...new Set(selected)], is_company };
    dispatchCreateDraft(body)
      .then((res: any) => {
        window.open(`/email/drafts/${res.id}`, 'Email', 'height=720,width=900');
      })

  }
  
  const dispatchCreateDraft = (body: any): any => {
    return dispatch(createDraft(body))
  }



  const actions = [
    {label: 'Edit', onClick: props.onEditMultiple, disabled: !props.canManageAdmins, popover: "There are Admins selected and you don’t have permissions to edit Admins" },
    {label: 'Individual Emails', onClick: onEmailClick, isVisible: props.withEmail, disabled: !user?.is_ms_authorized, popover: 'You need to connect your Outlook account to send emails via FinTech8' },
    {label: 'Company Emails', onClick: () => onEmailClick(true), isVisible: props.withEmail, disabled: !user?.is_ms_authorized, popover: 'You need to connect your Outlook account to send emails via FinTech8' },
    {label: 'Delete', onClick: props.onDelete, isVisible: props.hasDeleteAccess},
    {label: 'Assign To Project', onClick: props.onAssignToProject, disabled: props.assignToProjectDisabled, popover: 'Current selection includes contacts who are not invited members of FinTech8'},
  ]

  const manageActions = [
    {label: 'Import', onClick: props.onImportClick, isVisible: !!props.onImportClick},
    {label: 'Export', onClick: props.onExport, isVisible: !!props.fullAccess && !!props.onExport},
    {label: 'Print', onClick: onPrint, isVisible: !!props.onPrint},
  ] 


  const renderLeftItemsButtons = (): any => {
    if(!actions.length) return null;

    const options = actions
      .filter((item: any) => item.isVisible || item.isVisible === undefined)

    if(!options.length) return null;

    return (
      <ButtonWithMenu
        className={classes.actionsButton}
        options={options}
        onSelect={(option: any) => option.onClick()}
      >
        actions
      </ButtonWithMenu>
    )
  }


  const renderLeftItems = (): any => {
    if(!props.loading) {
      return (
        <div className={classes.leftItems}>
          <div className={classNames(
            classes.title,
            {
              [classes.smallTitle]: props.smallHeader,
              [classes.boldTitle]: props.bold
            }
          )}>
            <span>{props.title} {props.count && `(${props.count})` || ''}</span>
          </div>
          {props.fullAccess && props.onDelete && (
            <div className={classNames(
              classes.deleteButtonContainer,
              { [classes.opened]: props.actionsVisible }
            )}>
              {renderLeftItemsButtons()}
            </div>
          )}
        </div>
      )
    } else {
      return (
        <div className={classes.leftItems}>
          <Skeleton className={classes.skeleton} />
        </div>
      )
    }
  }

  const renderRightItems = (): any => {
    const options = manageActions
      .filter((action: any) => action.isVisible || action.isVisible === undefined);

    if(!options.length) return null;

    if(!props.loading) {
      return (
        <div className={classes.rightItems}>

          {props.customRightItem}

          <ButtonWithMenu
            className={classes.manageActionsButton}
            options={options}
            onSelect={(option: any) => option.onClick()}
          >
            Manage
          </ButtonWithMenu>


          {props.fullAccess && props.addButtonRoute &&
            <Link to={props.addButtonRoute}>
              <Button
                primary
                size={ButtonSize.Small}
                classes={{
                  root: classes.addButtonRoot
                }}
              >
                <AddIcon />
              </Button>
            </Link>
          }

          {props.fullAccess && props.viewMore &&
            <Link className={classes.viewMore} to={props.viewMore.path}>
              <span>{props.viewMore.text}</span>
            </Link>
          }
        </div>
      )
    } else {
      return (
        <div className={classes.rightItems}>
          {props.fullAccess && <Skeleton className={classes.skeletonButtonRect} />}
          {props.fullAccess && props.addButtonRoute && <Skeleton className={classes.skeletonButtonRect} />}
          {props.fullAccess && props.viewMore && <Skeleton className={classes.skeletonButtonLink} />}
        </div>
      )
    }
  }


  return (
    <div className={classNames(classes.root, props.className, 'hide-on-print')}>
      <div className={classes.actions}>
        {renderLeftItems()}
        {renderRightItems()}
      </div>

      {props.selectedCounterLabel && selected !== undefined && (
        <span className={classNames(
          classes.selectedLabel,
          {[classes.selectedLabelVisible]: !!selected?.length}
        )}>
          {selected.length} {props.selectedCounterLabel} selected
          {props.extraCounterComponent}
        </span>
      )}
    </div>
  );
}

export default withStyles(styles)(
  withRouter(ListHeader)
)
