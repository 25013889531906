
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import localStore from '../middleware/localstore';
import rootReducer from '../reducers';


const composeEnhancers = composeWithDevTools({trace: true, traceLimit: 25});

const store = createStore(
  rootReducer,  
  localStore.loadState(),
  composeEnhancers(
    applyMiddleware(thunk)
  ),
);



store.subscribe(() => {
  const state = store.getState();
  localStore.saveState({
    user: (state && state.user) || {},
    print: state.print,
    emailPeople: state.emailPeople
  });
});

export default store