import { FormControl, WithStyles, ClickAwayListener } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import moment from "moment";
import React, { useEffect } from "react";
import { useOpen } from "../shared/hooks";
import { DateFormat } from "../shared/types";
import { Theme } from "../theme";
import { formatDate, getDateFormat, clickFix } from "../utils/utils";
import Datepicker from "./Datepicker";
import { DatepickerIcon } from "./icons/DatepickerIcon";
import TextInput from "./TextInput";


const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  calendarIcon: {
    position: "absolute",
    right: '5px'
  },
  datePicker: {
    position: 'absolute',
    top: 40,
    left: 0,
    zIndex: 100,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 13,
    position: 'absolute',
    top: '50%',
    right: 7,
    transform: 'translateY(-50%)',
  },
  formControlOverlap: {
    zIndex: 1
  },
})

interface IProps extends WithStyles<typeof styles> {
  value: string;
  onChange: (date: string) => any;
  readonly?: boolean;
  placeholder?: string;
  error?: any;
  className?: any;
  format?: DateFormat;
  formatToShow?: DateFormat;
  onRef?: any;
  yearSelection?: boolean;
  [otherProps: string]: any;
}

const DateInput = (props: IProps) => {
  const {
    classes,
    readonly,
    format,
    onChange,
    value,
    formatToShow,
    error,
    placeholder,
    onRef,
    className,
    ...otherProps
  } = props;
  const open = useOpen();





  const handleChange = (date: any): void => {
    open.close();

    const dateFormat = getDateFormat(format);
    onChange(moment(date).format(dateFormat))
  }



  const getValueWithoutTimezone = (): number|undefined => {
    return value ? Date.parse(value.replace('Z', '')) : undefined
  }


  const getValue = (): string => {
    const val = getValueWithoutTimezone()
    if (val && formatToShow) {
      const dateFormat = getDateFormat(formatToShow);
      return moment(val).format(dateFormat)
    }

    return value ? formatDate(val, false, true) : ''
  }



  const handleKeyDown = (e: any): void => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        e.stopPropagation();
        return open.close()
    }
  }



  const onFocus = (): void => {
    if(readonly) return;

    if(!open.value) {
      open.open()
    }
  }






  return (
    <div
      className={classNames(
        classes.root,
        className,
      )}
      onKeyDown={handleKeyDown}
    >
      <FormControl
        fullWidth
        error={error && !readonly}
        className={classNames(
          { [classes.formControlOverlap]: !!open.value }
        )}
      >
        <TextInput
          onRef={onRef}
          value={getValue()}
          readonly={readonly}
          error={error}
          onFocus={onFocus}
          placeholder={placeholder}
        />

        {!readonly && (
          <DatepickerIcon
            className={classes.icon}
          />
        )}


        {open.value && (
          <Datepicker
            yearSelection={props.yearSelection}
            value={getValueWithoutTimezone()}
            onClose={open.close}
            className={classes.datePicker}
            onChange={handleChange}
            {...otherProps}
          />
        )}
        
        
      </FormControl>
    </div>
  );
}

export default withStyles(styles)(DateInput)