
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Theme } from "../../../../theme";
import Autocomplete from "../../../Autocomplete";
import { formatAutocompleteItems } from "../../../../utils/utils";
import classNames from 'classnames';


const styles = (theme: Theme) => createStyles({
  spacingBottom: {
    marginBottom: 10
  }
});


const AutocompleteField = (props) => {
  const { classes, value=null, onChange, options: propsOptions, className, type, spacingBottom, getOptions, isVisible, ...otherProps } = props;
  const optionsHook = useSelector(getOptions || ((state) => state));

  const options = getOptions ? optionsHook : propsOptions;

  if(isVisible !== undefined && !isVisible?.(options)) return null




  return <Autocomplete
    items={options}
    value={value}
    onChange={onChange}
    maxItems={20}
    type={type}
    className={classNames(
      className,
      {[classes.spacingBottom]: spacingBottom}
    )}
    {...otherProps}
  />
}

export default withStyles(styles)(AutocompleteField)