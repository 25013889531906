import CheckIcon from '@material-ui/icons/Check';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Theme } from '../../../theme';
import TextField from '../../forms/meta/fields/TextField';
import { EditIcon } from '../../icons/EditIcon';
import TextInput from '../../TextInput';
import TaskInput from './TaskInput';
import { useTaskAccess } from './hooks/tasks-hooks';

const styles = (theme: Theme) => createStyles({
  nameInput: {
    minHeight: '58px !important',
  },
  nameReadonly: {
    fontSize: '14px !important',
    fontWeight: 500,
    lineHeight: '1.57 !important',
    color: '#657274 !important',
    minHeight: 'initial !important',
    padding: '0 0 20px 8px !important',
  },
  descInput: {
    minHeight: '158px !important'
  },
  multiline: {
    paddingTop: '8px !important'
  },
})



interface IProps extends WithStyles<typeof styles> {
  isNew?: boolean
  className?: any
  headerClassName?: any
}

const TaskBasicInfo = (props: IProps) => {
  const { classes, isNew } = props;
  const access = useTaskAccess();
  const [edit, setEdit] = useState<string>('');

  


  const inputs = [
    {
      header: `Task Name${isNew ? '*' : ''}`,
      name: 'title',
      className: classes.nameInput,
      placeholder: isNew ? 'Start typing title...' : (!isNew ? 'No title given' : 'Start typing title...')
    },
    {
      header: `Description ${isNew ? '(optional)' : ''}`,
      headerReadonly: 'Description',
      name: 'description',
      className: classes.descInput,
      placeholder: isNew ? 'Start typing description...' : (!isNew ? 'No description given' : 'Start typing description...'),
    }
  ]




  const isEdit = (key: string) : boolean => {
    return edit === key
  }


  const getIcon = (key: string) => {
    if(access.hasAccessToEditOwnStuff && !isNew) {
      if(isEdit(key)) {
        return <CheckIcon onClick={(e) => setEdit('')} />
      } else {
        return <EditIcon onClick={(e) => setEdit(key)} />
      }
    }
    
    return null
  }


  const getTitle = (input: any) : string => {
    return !isNew
      ? input.headerReadonly || input.header
      : input.header
  }





  return (
    <Fragment>
      {inputs.map((input: any) => (
        <div key={input.name} className={props.className}>

          <div className={props.headerClassName}>
            <h2>{getTitle(input)}</h2>
            {getIcon(input.name)}
          </div>


          <TaskInput
            multiline collapsible
            name={input.name}
            readonly={!isNew && !isEdit(input.name)}
            placeholder={input.placeholder}
            inputStyle={classNames(
              input.className,
              {
                [classes.multiline]: isEdit(input.name) || isNew,
                [classes.nameReadonly]: !isNew && input.name === 'title' && !isEdit(input.name)
              }
            )}
          />

        </div>
      ))}
    </Fragment>
  )
}


export default withStyles(styles)(TaskBasicInfo)