import * as React from "react";
import {Theme} from "../../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import BaseForm, {IBaseFormProps} from "./BaseForm";
import {WithStyles} from "@material-ui/core";
import classNames from 'classnames'
import Button, { ButtonSize } from "../Button";

const styles = (theme: Theme) => createStyles({
  button: {
    // add space between elements:
    marginTop: 16,
    // '&:first-of-type': {
    //   marginTop: 0,
    // }
  },
  small: {
    marginTop: 0
  }
});

export interface IButton {
  name: string,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>)=>void,
  primary?: boolean,
  type?: string,
  small?: boolean,
}

interface IProps extends IBaseFormProps, WithStyles<typeof styles> {
  buttons: IButton[]
}

class ButtonForm extends React.Component<IProps> {

  createButtons() {
    const {buttons, classes} = this.props;
    return buttons.map((b: any, idx: number)=>(
      <Button
        key={b.name}
        className={classNames([
          classes.button,
          {[classes.small]: b.small}
        ])}
        primary={b.primary}
        onClick={(e: any) => b.onClick && b.onClick(e)}
        fullWidth
        size={b.small ? ButtonSize.Label : undefined}
        type={b.type}
      >
        {b.name}
      </Button>
    ))
  }

  render() {
    const {header, description} = this.props;
    return (
      <BaseForm
        header={header}
        description={description}
      >
        {this.createButtons()}
      </BaseForm>
    );
  }
}

export default withStyles(styles)(ButtonForm)