import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFile } from '../../../actions/files';
import { DEFAULT_TASK_CATEGORY } from '../../../shared/models/Task';
import { Theme } from '../../../theme';
import FileList from '../../files/FileList';
import { useTaskData } from './hooks/tasks-hooks';
import { removeFileFromTask } from '../../../actions/tasks';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    flexDirection: 'column'
  },
})

interface IProps extends WithStyles<typeof styles> {
  isNew?: boolean;
}

const TaskFilesList = ({ classes, isNew }: IProps) => {
  const { value, dispatch } = useTaskData('files');
  const filesRedux = useSelector((state: any) => state.files.results);
  const uploadPending = useSelector((state: any) => state.files.loading);
  const files = useMemo(() => (isNew ? value : filesRedux?.[0]?.results) ?? [] , [value, isNew, filesRedux])




  const dispatchDeleteFile = useCallback((fileId: number, type: number, idx?: number) : any => {
    return isNew && idx !== undefined
      ? dispatch(removeFileFromTask(idx))
      : dispatch(deleteFile(fileId, DEFAULT_TASK_CATEGORY, idx))
  }, [isNew])





  return useMemo(() => (
    <div className={classes.root}>

      <FileList asAttachment
        files={files}
        count={files.length}
        onDelete={dispatchDeleteFile}
        uploadPending={uploadPending}
      />


    </div>
  ), [files, dispatchDeleteFile, uploadPending])
}


export default withStyles(styles)(TaskFilesList)