import React, { MouseEvent } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getUsername } from '../../utils/user';
import { useHistory } from 'react-router';
import { goTo } from '../../utils/utils';

const styles = (theme: Theme) => createStyles({
  root: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  },
})

interface IProps extends WithStyles<typeof styles> {
  id: number;
  name: string;
}

const HeaderUserWidgetLink = (props: IProps) => {
  const history = useHistory();


  
  const onClick = (e: MouseEvent<HTMLAnchorElement>): void => {
    goTo(e, `/contacts/${props.id}`, history)
  }



  return (
    <a className={props.classes.root}
      href={`/contacts/${props.id}`}
      onClick={onClick}
    >
      {props.name}
    </a>
  )
}


export default withStyles(styles)(HeaderUserWidgetLink)