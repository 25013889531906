import { AppBar, Grid, Toolbar, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { Fragment, MouseEvent } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { useWindowSize } from "../../shared/hooks";
import { Theme } from "../../theme";
import { isClient, isGuest } from "../../utils/user";
import SearchBar from "../SearchBar";
import HeaderIcons from "./HeaderIcons";
import HeaderTabs from "./HeaderTabs";
import HeaderUserWidget from "./HeaderUserWidget";
import { ROUTE_DASHBOARD } from '../../constants/routes';
import { goTo } from '../../utils/utils';
import PrintHeader from '../print/PrintHeader';



const styles = (theme: Theme) => createStyles({
  headerWrap: {
    flexGrow: 1
  },
  appBarGrid: {
    height: '100%',
    margin: '0 !important',
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 !important',
    height: '100%',
    flex: 1
  },
  headerLeft: {},
  headerRight: {
    justifyContent: 'flex-end',
  },
  logo: {
    display: 'flex'
  },
  logoAdmin: {
    width: 32,
    height: 32
  },
  toolbarRoot: {
    height: 56,
    minHeight: 56,
    backgroundColor: 'white',
    padding: '0 20px'
  },
  appBar: {
    boxShadow: '0 2px 4px 0 rgba(189, 194, 196, 0.16)'
  },
  headerLogo: {
    display: 'flex'
  },
  '@media (max-width: 479.98px)': {
    appBarGrid: {
      width: '100%',
      margin: 0,
      justifyContent: 'space-between',
    },
    toolbarRoot: {
      padding: '0 15px',
    },
    headerLeft: {
      flex: 1,
    },
    headerRight: {
      flex: 1,
      padding: '0 !important'
    },
    logo: {
      margin: 0
    }
  }
})


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  onLogoutClick: () => any;
}

const Header = (props: IProps) => {
  const { classes } = props;
  const windowSize = useWindowSize();
  const user = useSelector((state: any) => state.user.user);





  
  const goToDashboard = (e: MouseEvent<HTMLAnchorElement>): void => {
    goTo(e, ROUTE_DASHBOARD, props.history)
  }





  const renderLeftMenu = () => (
    <Grid item className={classNames(classes.headerSection, classes.headerLeft)}>
      {isGuest(user) || isClient(user) || windowSize.isMobile
        ? (
            <a href={ROUTE_DASHBOARD} className={classes.logo}>
              <img src="/img/full_logo.svg" alt="Logo" />
            </a>
          )
        : (
            <Fragment>
              <a href={ROUTE_DASHBOARD} className={classes.headerLogo} onClick={goToDashboard}>
                <img className={classes.logoAdmin} src="/img/mark-brand.svg" alt="Logo" />
              </a>
              <HeaderTabs />
            </Fragment>
          )
      }
    </Grid>
  )



  return (
    <div className={classes.headerWrap}>
      
      <PrintHeader />

      <AppBar position="fixed"
        color="default"
        elevation={1}
        className={classNames(classes.appBar, 'hide-on-print')}
      >
        <Toolbar classes={{ root: classes.toolbarRoot }}>
          <Grid container
            spacing={10}
            justify="space-between"            
            className={classes.appBarGrid}
          >

          
            {renderLeftMenu()}



            <Grid item className={classNames(classes.headerSection, classes.headerRight)}>

              <SearchBar />

              <HeaderIcons />

              <HeaderUserWidget onLogoutClick={props.onLogoutClick} />

            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(
  withRouter(Header)
)
