import * as actions from '../actions/email';
import { IMessage, EmailFilters } from '../shared/interfaces';

export const emailsInitialState = {
  count: 0,
  results: [] as IMessage[],
  next: null,
  previous: null,
  currentEmail: null,
  loading: false,
  filters: {} as EmailFilters,
  thread: {
    count: 0,
    results: [] as IMessage[],
    next: null,
    previous: null,
  }
};

export default function(state = emailsInitialState, action) {
  const { type, ...data } = action;

  switch (type) {


    case actions.SET_EMAILS:
      return {
        ...state,
        ...action.emails
      }


    case actions.CLEAR_EMAILS:
      return {
        ...emailsInitialState
      }


    case actions.APPEND_EMAILS:
      return {
        ...state,
        next: action.next,
        previous: action.previous,
        results: [...state.results, ...action.results]
      }


    case actions.SET_CURRENT_EMAIL:
      return {
        ...state,
        currentEmail: data
      }


    case actions.SET_CURRENT_THREAD:
      return {
        ...state,
        thread: data
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }


    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      }


    default:
      return state
  }
}