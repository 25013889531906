import { currentImportedDuplicateActions } from '../../reducers/contact-management/current-imported-duplicate';
import duplicateService from '../../services/contact-management';
import { ISort } from '../../shared/interfaces';
import { setError, setSuccess } from '../common/notifications';
import { formatDuplicates, formatSortObject, prepareFields } from '../helpers/helpers';
import { PrepareObject } from '../../shared/types';

/**
 * Actions
 */

export const SET_IMPORTED_DUPLICATES            = 'SET_IMPORTED_DUPLICATES';
export const DELETE_IMPORTED_DUPLICATES         = 'DELETE_IMPORTED_DUPLICATES';
export const CHANGE_IMPORTED_DUPLICATES         = 'CHANGE_IMPORTED_DUPLICATES';
export const APPEND_IMPORTED_DUPLICATES         = 'APPEND_IMPORTED_DUPLICATES';
export const SET_INITIATED_IMPORTED_DUPLICATES  = 'SET_INITIATED_IMPORTED_DUPLICATES';
export const CLEAR_IMPORTED_DUPLICATES          = 'CLEAR_IMPORTED_DUPLICATES';
export const SET_LOADING                        = 'IMPORTED_DUPLICATES_SET_LOADING';




/**
 * Action creators
 */

export function setImportedDuplicates(data: any): object {
  return {type: SET_IMPORTED_DUPLICATES, data}
}

export function deleteImportedDuplicates(ids: number[]): object {
  return {type: DELETE_IMPORTED_DUPLICATES, ids}
}

export function changeImportedDuplicates(idx: number, fieldName: string, value: any): object {
  return {type: CHANGE_IMPORTED_DUPLICATES, idx, fieldName, value}
}

export function appendImportedDuplicates(data: any): object {
  return {type: APPEND_IMPORTED_DUPLICATES, ...data}
}

export function setInitiated(state: boolean): object {
  return {type: SET_INITIATED_IMPORTED_DUPLICATES, state}
}

export function clearImportedDuplicates(): object {
  return {type: CLEAR_IMPORTED_DUPLICATES}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}






/**
 * Async action creators
 */

export const getImportedDuplicates = (sort: ISort[], search: string) => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));

    
    return duplicateService.getImportedDuplicates(body)
      .then((res: any) => {
        dispatch(setImportedDuplicates(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting imported duplicates.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const getNextImportedDuplicates = (sort?: ISort[]) => {
  return (dispatch, getState) => {
    const nextURL = getState().contactManagement.importedDuplicates.next;
    dispatch(setLoading(true));

    
    return duplicateService.getNextImportedDuplicates(nextURL)
      .then((res: any) => {
        dispatch(appendImportedDuplicates(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting imported duplicates.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getImportedDuplicate = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return duplicateService.getImportedDuplicate(id)
      .then((res: any) => {

      
        if(res?.duplicate_of_details) {

          const duplicates = [res.duplicate_of_details, res];
          dispatch(setImportedDuplicates({
            results: formatDuplicates(duplicates)
          }));
        }
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting an imported duplicate.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const dismissImportedDuplicates = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return duplicateService.dismissImportedDuplicates(ids)
      .then((res: any) => {
        dispatch(deleteImportedDuplicates(ids));
        dispatch(setSuccess({
          message: 'Duplicates deleted.'
        }))
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while dismissing imported duplicates.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const mergeImportedDuplicate = (id: number, mergeId: number, body: any) => {
  return (dispatch, getState) => {
    const { people, capitalProvider } = getState();
    const newBody = prepareFields(PrepareObject.PersonRequest, body, { people, capitalProvider }, true);
    dispatch(setLoading(true));

    return duplicateService.mergeImportedDuplicates(id, mergeId, newBody)
      .then((res: any) => {
        dispatch(currentImportedDuplicateActions.actionResetForm());
        dispatch(setSuccess({
          message: 'Duplicate successfully merged.'
        }))
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while merging imported duplicate.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}