import React from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { IPrintDataDescription } from '../../shared/interfaces';


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 11,
    lineHeight: 1.45,
    color: '#657274',
    marginBottom: 20,
    '& span': {
      marginRight: 3,
      '& > strong': {
        fontWeight: 600
      }
    },
  },
  desc: {
    display: 'block'
  },
  hidden: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  items?: IPrintDataDescription[];
  caption?: any;
}

const PrintDescription = ({ classes, items, caption }: IProps) => (
  <div className={classNames(
    classes.root,
    'show-on-print-important',
    {[classes.hidden]: !items && !caption}
  )}>
    
    <span className={classes.desc}>
      {!!items?.length && items.map((item: IPrintDataDescription, idx: number) => (
        <span key={idx}><strong>{item.title}</strong>{item.value}</span>
      ))}
    </span>


    {!!caption && (
      <span><strong>{caption.title}</strong>{caption.value}</span>
    )}
  </div>
)


export default withStyles(styles)(PrintDescription)