import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Theme } from "../../../theme";
import TextField from "./fields/TextField";


interface IProps {

    meta: [any]
    onSubmit: Function,
    onFieldChange: Function,

}


const FormField = ({actions, store, meta}) => {

    const dispatch = useDispatch();

    const {as: Component = TextField, ...otherMeta } = meta;

    const onChange = (newValue, customMeta?: string) => {
        let metaName = meta.name;
        let value = newValue;

        if(customMeta) {

            if(meta.parent) {

                metaName = meta.parent;
                value = {
                    ...store.data[meta.parent],
                    [customMeta]: newValue
                }

            } else {
                metaName = customMeta;
            }

        }

        if(!metaName) return;


        dispatch(actions.actionUpdateField(metaName, value))
    };


    const getStoreData = (): any => {
        if(!meta.names) return undefined;

        const parentData = store.data[meta.parent] || {};
        return meta.parent ? parentData : store.data;
    }

    const getValue = (): any => {
        if(!meta.name) return undefined;

        const parentData = store.data[meta.parent] || {};
        return meta.parent ? parentData[meta.name] : store.data[meta.name];
    }

    const getDependingData = (): any => {
        if(!meta.dependsOn) return undefined;

        if(meta.dependsOn === 'cp_subcategories') {
            return meta.parent && !!Object.keys(store.data).length ? store.data[meta.parent] : store.data;
        }
    }

    getDependingData();


    return <Component
        value={getValue()}
        storeData={getStoreData()}
        dependingStoreData={getDependingData()}
        onChange={onChange}
        label={meta.label}
        {...otherMeta}
    />
};


const styles = (theme: Theme) => createStyles({
    form: {
        width: '100%'
    }
});


const MetaBasedForm = (props: any) => {

    const {classes, meta, onSubmit,  ...otherProps} = props;

    const fields = meta.map(
        (fieldMeta, key) => <FormField key={key} meta={fieldMeta} {...otherProps} />
    );

    return <form onSubmit={onSubmit} className={classes.form}>
        {fields}
    </form>

};




export default withStyles(styles)(MetaBasedForm)