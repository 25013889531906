import React from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { ModalFooter } from '../modal/ModalFooter';
import Button from '../Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SaveAsTemplateButton from "./SaveAsTemplateButton";
import LoadTemplate from './LoadTemplate';
import { useOpen } from '../../shared/hooks';
import store from '../../middleware/store';


const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
  },
  leftButtonsWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  sendButtonsWrapper: {
    display: 'flex',
    '& > $button': {
      marginRight: 10,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  button: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    height: 40,
    padding: '8px 16px',
    lineHeight: 1
  },
  buttonSecondary: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    }
  },
  savedDraftNotification: {
    marginLeft: 29,
    color: '#15908a',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.67,
    display: 'flex',
    alignItems: 'center',
    opacity: 0,
    transition: 'opacity .1s ease',
  },
  savedDraftNotificationShown: {
    opacity: 1
  },
  checkIcon: {
    fontSize: 20,
    marginRight: 9,
    color: 'inherit'
  },
  buttonDisabled: {
    pointerEvents: 'none'
  },
  secondaryActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
})

interface IProps extends WithStyles<typeof styles> {
  store: any;
  showNotification: boolean;
  notification: string;
  onSend: () => any;
  onGoBack: () => any;
  onSaveAsDraft: (e: any) => any;
  onSaveAsTemplate: (value: string) => any;
  onLoadTemplateSelect: (item: any) => Promise<boolean>;
}

const EmailComposeFooter = (props: IProps) => {
  const { classes } = props;
  const loadTemplateControls = useOpen();




  const onTemplateLoad = (item: any): void => {
    props.onLoadTemplateSelect(item)
      .then((res: boolean) => res && loadTemplateControls.close())
  }




  return <>
    <ModalFooter className={classes.root}>



      <div className={classes.leftButtonsWrapper}>
        <div className={classes.sendButtonsWrapper}>
          <Button primary
            onClick={props.onSend}
            disabled={!props.store?.to?.length}
            className={classes.button}
          >
            Send
          </Button>
        </div>

        <div className={classNames(
          classes.savedDraftNotification,
          {[classes.savedDraftNotificationShown]: props.showNotification}
        )}>
          <CheckCircleIcon className={classes.checkIcon} />
          <span>{props.notification}</span>
        </div>
      </div>




      <div className={classes.secondaryActions}>
        <Button onClick={loadTemplateControls.open}
          className={classNames(classes.button, classes.buttonSecondary)}
        >
          Load Template
        </Button>


        <SaveAsTemplateButton
          onSaveAsDraft={props.onSaveAsDraft}
          onSaveAsTemplate={props.onSaveAsTemplate}
        />

      
        <Button onClick={props.onGoBack}
          className={classNames(classes.button, classes.buttonSecondary)}
        >
          Cancel
        </Button>


      </div>
    </ModalFooter>



    {props.store.id !== undefined && (
      <LoadTemplate
        isCompany={props.store.is_company}
        onOpen={onTemplateLoad}
        onClose={loadTemplateControls.close}
        opened={loadTemplateControls.value}
      />
    )}
  </>
}


export default withStyles(styles)(EmailComposeFooter)