import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import { Theme } from '../../theme';
import { DragIcon } from '../icons/DragIcon';


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
    width: 11,
    height: 11,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    right: 19,
    transform: 'translateY(-50%) scale(1)',
  },
})

interface IProps extends WithStyles<typeof styles> {
  label: string;
}

const ManageColumnsText = ({ classes, label }: IProps) => (
  <div className={classes.root}>
    <span>{label}</span>
    <DragIcon className={classes.icon} />
  </div>
)


export default withStyles(styles)(
  memo(ManageColumnsText)
)