import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function getOutlookStatus(id: number) {
  return Request.get(`${API_URL}/people/${id}/outlook_status/`)
}


export default {
  getOutlookStatus
}