import PrintIcon from '@material-ui/icons/Print';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmail, getThread, forwardEmail, forwardThread } from '../../../actions/email';
import Button from '../../../components/Button';
import EmailContacts from '../../../components/email/EmailContacts';
import ForwardIcon from '../../../components/icons/ForwardIcon';
import { IMessage } from '../../../shared/interfaces';
import { Theme } from '../../../theme';
import { formatAgoTime, formatDateTime } from '../../../utils/utils';
import requestManager from '../../../middleware/requestManager';
import classNames from 'classnames';
import EmailCompose from './EmailCompose';
import { createDraft, deleteDraft, createForwardEmail } from '../../../actions/email-drafts';
import { useHistory } from 'react-router';
import PrintHeader from '../../../components/print/PrintHeader';
import { breakAndHighlight } from '../../../utils/component-utils';
import AuthorLabel from '../../../components/AuthorLabel';
import { getUsername } from '../../../utils/user';



const styles = (theme: Theme) => createStyles({
  root: {
    padding: '0 0 60px',
    boxSizing: 'border-box',
    height: 'auto !important'
  },
  rootReadonly: {
    backgroundColor: '#fff',
  },
  email: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '25px 20px 0',
    boxSizing: 'border-box',
    '&:first-child': {
      paddingTop: 18
    },
    '&:last-child': {
      paddingBottom: 36
    }
  },
  emailActive: {
    backgroundColor: theme.palette.primary.light
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.33
  },
  actions: {

  },
  actionsSecondary: {
    '& $action': {
      height: 25,
      border: `1px solid ${theme.palette.primary.main}`,
      maxWidth: 90
    },
    '& $forwardIcon': {
      fontSize: 13
    },
    '& $printIcon': {
      fontSize: 17
    }
  },
  action: {
    height: 30,
    marginRight: 10,
    fontWeight: 400,
    '&:last-child': {
      marginRight: 0
    }
  },
  forwardIcon: {
    marginRight: 8,
    fontSize: 14
  },
  printIcon: {
    fontSize: 18,
    marginRight: 8,
  },
  receiversWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingBottom: 15,
    marginBottom: 15,
    borderBottom: '1px solid #d9e4e8',
  },
  receivers: {

  },
  date: {
    fontSize: 10,
    color: '#657274'
  },
  content: {
    paddingBottom: 22,
    borderBottom: '1px solid #d9e4e8',
    color: '#657274',
    fontSize: 12,
    lineHeight: 1.67
  },
  composeEmail: {
    marginBottom: 20
  }
})

interface IProps extends WithStyles<typeof styles> {
  id: number;
  readonly?: boolean;
}

const EmailComponent = (props: IProps) => {
  const { classes, id, readonly } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state.user?.user?.id);
  const email = useSelector((state: any) => state.emails.currentEmail);
  const sendPending = useSelector((state: any) => state.emails.loading);
  const thread = useSelector((state: any) => state.emails.thread.results) as IMessage[];
  const [emails, setEmails] = useState<any[]>([]);
  const [refs, setRefs] = useState<any[]>([]);
  const [jumped, setJumped] = useState<boolean>(false);
  const [forwardId, setForwardId] = useState<number|undefined>(undefined);
  const [draftId, setDraftId] = useState<number|undefined>(undefined);






  const isEmailActive = (emailId: number): boolean => {
    return emailId === props.id && !readonly && emails.length > 1
  }




  const getQueryParams = (): any => {
    const query = new URLSearchParams(window.location.search);
    const thread = query.get('thread') ?? undefined;
    const person = query.get('person') ?? undefined;

    return { thread, person };
  }



  const onForward = (email: IMessage): void => {
    setDraftId(undefined);
    setForwardId(undefined);
    dispatchCreateDraft(email.id)
      .then((res: any) => {
        setDraftId(res.id);
        setForwardId(email.id)
      })
  }


  
  const onForwardCancel = (): void => {
    setForwardId(undefined);
    setDraftId(undefined);
  }



  const isForwarding = (emailId: number): boolean => {
    return forwardId !== undefined && forwardId === emailId && draftId !== undefined
  }


  

  const onPrintThread = (): void => {
    const { thread, person } = getQueryParams();
    const threadStr = thread ? `?thread=${thread}` : '';
    const personStr = person ? `${thread ? '&' : '?'}person=${person}` : '';

    history.push(`/email/${id}/print${threadStr}${personStr}`)
  }



  const onPrint = (emailId: number): void => {
    history.push(`/email/${emailId}/print`)
  }



  const onScroll = (): void => {
    const idx = thread.findIndex((item: IMessage) => item.id === id);

    if(!refs[idx]?.current) return;
    const { offsetTop, offsetHeight } = refs[idx]?.current;
    const windowCentre = window.innerHeight / 2 + window.pageYOffset;
    if(windowCentre < offsetTop && !jumped) {
      window.scroll(0, offsetTop + offsetHeight / 2);
      setJumped(true)
    }
  }



  const onForwardEmailSend = (data: any): void => {
    if(!forwardId) return;
  
    const { id, ...body } = data;

    dispatchForwardEmail(id, body)
      .then((res: any) => {
        setForwardId(undefined);
        setDraftId(undefined);
      })
  }



  const onForwardThreadSend = (data: any): void => {
    const { id, ...mainData } = data;
    const email = thread.find((email: IMessage) => email.id === forwardId);
    if(!forwardId || !email?.conversation_id) return;

    const body = { ...mainData, conversation_id: email.conversation_id}

    dispatchForwardThread(body)
      .then((res: any) => {
        setForwardId(undefined);
        setDraftId(undefined);
      })
  }



  const dispatchGetEmail = (id: number): any => {
    return dispatch(getEmail(id))
  }


  const dispatchGetThread = (threadId: string, personId: string): any => {
    return dispatch(getThread(threadId, +personId))
  }



  const dispatchCreateDraft = (emailId: number): any => {
    return dispatch(createForwardEmail(emailId))
  }



  const dispatchDeleteDraft = (deleteDraftId: number): any => {
    return dispatch(deleteDraft(deleteDraftId))
  }



  const dispatchForwardEmail = (emailId: number, body: any): any => {
    return dispatch(forwardEmail(emailId, body))
  }



  const dispatchForwardThread = (body: any): any => {
    return dispatch(forwardThread(body))
  }




  useEffect(() => {
    const { thread, person } = getQueryParams();


    if(thread && person) {
      dispatchGetThread(thread, person);
      return
    }


    if(id !== undefined) {
      dispatchGetEmail(id)
    }


    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])




  useEffect(() => {
    if(!thread.length) return;

    const newRefs = Array(thread.length).fill(0).map((_, i) => refs[i] || createRef())
    setRefs(newRefs)

  }, [thread, id])




  useEffect(() => {
    if(!refs.length || jumped) return;

    onScroll()

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll)
  }, [refs, jumped])





  useEffect(() => {
    let newEmails = [] as IMessage[];

    if(thread?.length) {
      newEmails = thread;
    } else if(email) {
      newEmails = [email];
    }


    if(readonly) {
      const { thread, person } = getQueryParams();

      if(!thread || !person) {
        newEmails = newEmails.filter((item: IMessage) => item.id === id)
      }
    }


    setEmails(newEmails)

  }, [thread, email, readonly, id])




  if(!emails.length) {
    return null
  }





  return (
    <div className={classNames(
      classes.root,
      {[classes.rootReadonly]: readonly}
    )}>

      {readonly && <PrintHeader />}

      {emails.map((email: IMessage, idx: number) => (
        <div key={email.id}
          ref={refs[idx]}
          className={classNames(
            classes.email,
            {[classes.emailActive]: isEmailActive(email.id)}
          )}
        >

          {forwardId !== undefined && draftId !== undefined && isForwarding(email.id) && (
            <EmailCompose inline forward
              id={draftId}
              className={classes.composeEmail}
              onSend={onForwardEmailSend}
              onCancel={onForwardCancel}
              onUnmount={dispatchDeleteDraft}
              pending={sendPending}
            />
          )}



          <div className={classes.header}>
            <span className={classes.title}>{email.subject}</span>

            {!isForwarding(email.id) && !readonly && (
              <div className={classNames(
                classes.actions,
                {[classes.actionsSecondary]: !!idx}
              )}>
                <Button primary={!idx} className={classes.action} onClick={() => onForward(email)}>
                  <ForwardIcon className={classes.forwardIcon} />
                  Forward
                </Button>

                <Button primary={!idx} className={classes.action} onClick={() => onPrint(email.id)}>
                  <PrintIcon className={classes.printIcon} />
                  Print
                </Button>

                {!idx && emails.length > 1 && (
                  <Button primary={!idx} className={classes.action} onClick={onPrintThread}>
                    <PrintIcon className={classes.printIcon} />
                    Print Thread
                  </Button>
                )}
                
              </div>
            )}
            

          </div>



          <div className={classes.receiversWrapper}>
            <div className={classes.receivers}>
              <AuthorLabel
                lightColor
                type="email"
                label="To:"
                authors={email.receiver ?? []}
                externalEmails={email.external_to ?? []}
              />
              <AuthorLabel
                lightColor
                type="email"
                label="From:"
                name={getUsername(email.sender)}
                id={email.sender?.id}
              />
              <AuthorLabel
                lightColor
                type="email"
                label="CC:"
                authors={email.cc ?? []}
                externalEmails={email.external_cc ?? []}
              />
            </div>


            {email.date && (
              <div className={classes.date}>
                {formatDateTime(email.date)} ({formatAgoTime(email.date)})
              </div>
            )}
          </div>



          <div className={classes.content}>
            {breakAndHighlight(email.content)}
          </div>



          </div>
      ))}

      


    </div>
  )
}


export default withStyles(styles)(EmailComponent)