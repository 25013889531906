import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { Fragment, useState, MouseEvent } from "react";
import { useShowAll } from "../shared/hooks";
import { IMessage, IPersonShort, EmailFilters } from "../shared/interfaces";
import { Theme } from "../theme";
import { highlight, breakAndHighlight } from "../utils/component-utils";
import { getUsername } from "../utils/user";
import { formatDateTime } from "../utils/utils";
import AuthorLabel from "./AuthorLabel";
import NoteBaseContainer from "./NoteBaseContainer";
import ShowAll from "./ShowAll";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MailForwardedChip from "./MailForwardedChip";
import { useSelector } from "react-redux";


const styles = (theme: Theme) => createStyles({
  root: {
    padding: '10px 10px 0',
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 5,
    '&:last-child': {
      marginBottom: 0,
      '&:first-child': {
        padding: '10px'
      }
    }
  },
  counter: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 10,
    lineHeight: 2.3,
    color: '#90a0a7',
    marginRight: 10,
    width: 4
  },
  container: {
    width: '100%',
    paddingBottom: 10,
    borderBottom: '1px solid #d9e4e8',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: 1.71,
    width: '100%',
    marginBottom: 5
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  dateWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    fontSize: 10,
    color: '#657274',
  },
  icon: {
    fontSize: 19,
    color: theme.palette.primary.main,
    marginLeft: 12,
    cursor: 'pointer',
    transformOrigin: 'center',
    transition: 'transform .1s ease-in',
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  receiversAndCollapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  receivers: {
    marginBottom: 6,
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    flexWrap: 'wrap',
    '& > *': {
      marginBottom: 3,
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  body: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.71,
    color: '#657274',
    maxHeight: 75,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  overflow: {
    maxHeight: 'none',
  },
  showAll: {
    margin: 0,
    width: 100
  },
  showAllBottom: {
    marginTop: 10
  },
  showAllBottomWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

interface IProps extends WithStyles<typeof styles> {
  counter?: number;
  message: IMessage;
  personId?: number;
}

const Mail = (props: IProps) => {
  const { classes, message } = props;
  const showAll = useShowAll();
  const filters = useSelector((state: any) => state.emails.filters) as EmailFilters;
  const [overflowing, setOverflowing] = useState<boolean>(false);




  const onRef = (ref: HTMLDivElement|null) => {
    if(!ref) return

    setOverflowing(ref.offsetHeight < ref.scrollHeight || showAll.value)
  }



  const openEmail = (e: MouseEvent<SVGSVGElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if(!message?.id) return;

    const threadId = message.conversation_id ? `?thread=${message.conversation_id}` : '';
    const personId = (threadId && props.personId) ? `&person=${props.personId}` : '';
    window.open(`/email/${message.id}${threadId}${personId}`, '_blank', 'height=720,width=900', false);
  }







  return (
    <div className={classes.root}>

      {props.counter && (
        <span className={classes.counter}>{props.counter}</span>
      )}



      <div className={classes.container}>
        <div className={classes.header}>
          <span className={classes.title}>
            {breakAndHighlight(message.subject, filters.search)}
          </span>

          <div className={classes.dateWrapper}>
            <span className={classes.date}>
              {formatDateTime(message.date) || ''}
            </span>
            <OpenInNewIcon className={classes.icon}
              onClick={openEmail}
            />
          </div>
        </div>


        {message.is_forwarded && <MailForwardedChip />}



        <div className={classes.receiversAndCollapse}>
          <div className={classes.receivers}>
            <AuthorLabel
              type="email"
              label="To:"
              authors={message.receiver ?? []}
              externalEmails={message.external_to ?? []}
              highlightText={filters.receiver}
            />

            <AuthorLabel
              type="email"
              label="From:"
              name={getUsername(message.sender)}
              id={message.sender?.id}
              highlightText={filters.sender}
            />

            
            <AuthorLabel
              type="email"
              label="CC:"
              authors={message.cc ?? []}
              externalEmails={message.external_cc ?? []}
              highlightText={filters.search}
            />
          </div>


          <ShowAll alignRight
            label="Show more..." 
            showAll={showAll}
            isOverflowing={overflowing}
            className={classes.showAll}
          />
        </div>

        


        <div ref={onRef}
          className={classNames(
            classes.body,
            {[classes.overflow]: showAll.value}
          )}
        >
          {breakAndHighlight(message.content, filters.search)}
        </div>



        <div className={classes.showAllBottomWrapper}>
          <ShowAll alignRight
            label="Show more..." 
            showAll={showAll}
            isOverflowing={overflowing}
            className={classNames(classes.showAll, classes.showAllBottom)}
          />
        </div>

      </div>


    </div>
  );
}

export default withStyles(styles)(Mail)