import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterAssignees } from '../../../../actions/board-filters';
import { Theme } from '../../../../theme';
import { getUsername } from '../../../../utils/user';
import { getWords } from '../../../../utils/utils';
import { PersonIcon } from '../../../icons/PersonIcon';
import TextInput from '../../../TextInput';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  assigneeList: {
    width: '100%',
    paddingLeft: 25,
    boxSizing: 'border-box',
    marginTop: 5
  },
  assignee: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.67,
    color: '#233539',
    padding: '10px 0',
    cursor: 'pointer',
    transition: 'all .1s ease',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      paddingLeft: 10,
      backgroundColor: '#f7f9fa',
      '& $clearIcon': {
        color: theme.palette.primary.main
      }
    }
  },
  filtersAssignees: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  clearIcon: {
    fontSize: 16,
    // color: '#657174',
    transition: 'all .1s ease',
    color: 'transparent',
    marginRight: 10
  },
  addIcon: {
    transform: 'rotate(45deg)'
  },
  filterListItemInput: {
    width: '100%'
  },
  activeIcon: {
    color: `${theme.palette.primary.main} !important`
  }
})

interface IProps extends WithStyles<typeof styles> {
  members: any[];
  className?: any;
  iconClassName?: any;
}

const FilterAssignees = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const [searchList, setSearchList] = useState<any[]>([]);
  const assignees = useSelector((state: any) => state.board.filters.assignees);


  const filterByFields = (searchStr: string) : any[] => {
    const words = getWords(searchStr);
    const assigned = getAssignees();
    const notAssigned = props.members.filter((member: any) => !assignees.includes(member.person.id));
    const foundMembers = notAssigned.filter((item: any) => (
      !!words.filter((word: string) => filterWord(word, item.person)).length
    ))

    return [...assigned, ...foundMembers]
  }

  const handleSearch = (search: string) : void => {
    setSearch(search);
    if(!search) {
      const savedAssignees = getAssignees();
      setSearchList(savedAssignees);
      return 
    }

    const newList = filterByFields(search);
    setSearchList(newList)
  }

  const getAssignees = () : any[] => {
    return props.members.filter((member: any) => assignees.includes(member.person.id));
  }

  const filterWord = (word: string, item: any) : boolean => {
    return item.first_name.toLowerCase().includes(word)
    || item.last_name.toLowerCase().includes(word)
    || item.company.toLowerCase().includes(word)
  }

  const addMemberToAssignees = (personId: any) : void => {
    const newAssignees = assignees.includes(personId)
      ? assignees.filter((item: number) => item !== personId)
      : [...assignees, personId]
    dispatch(setFilterAssignees(newAssignees))
  }

  const isAssignee = (member: any) : boolean => {
    return assignees.includes(member.person.id)
  }

  const isFiltered = () : boolean => {
    return !!assignees.length
  }

  return (
    <div className={classNames(props.className, classes.root)}>
      <div className={classes.filtersAssignees}>
        <PersonIcon className={classNames(
          props.iconClassName,
          {[classes.activeIcon]: isFiltered()}
        )} />
        <TextInput
          value={search}
          className={classes.filterListItemInput}
          placeholder="Type Assignee"
          onChange={(value: string) => handleSearch(value)}
        />
      </div>
      <div className={classes.assigneeList}>
        {searchList.map((member: any, index: number) => (
          <div
            key={member.person.id}
            className={classes.assignee}
            onClick={(e) => addMemberToAssignees(member.person.id)}
          >
            <span>{getUsername(member.person)}</span>
            <ClearIcon className={classNames(
              classes.clearIcon,
              {
                [classes.addIcon]: !isAssignee(member)
              }
            )} />
          </div>
        ))}
      </div>
    </div>
  )
}


export default withStyles(styles)(FilterAssignees)