import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const GearIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
        <defs>
          <path id="d" d="M12.953 8.653C12.98 8.44 13 8.227 13 8c0-.227-.02-.44-.047-.653l1.407-1.1c.127-.1.16-.28.08-.427l-1.333-2.307a.335.335 0 0 0-.407-.146l-1.66.666a4.87 4.87 0 0 0-1.127-.653L9.66 1.613a.325.325 0 0 0-.327-.28H6.667a.325.325 0 0 0-.327.28L6.087 3.38c-.407.167-.78.393-1.127.653L3.3 3.367a.325.325 0 0 0-.407.146L1.56 5.82a.329.329 0 0 0 .08.427l1.407 1.1A5.287 5.287 0 0 0 3 8c0 .22.02.44.047.653l-1.407 1.1c-.127.1-.16.28-.08.427l1.333 2.307c.08.146.26.2.407.146l1.66-.666c.347.266.72.486 1.127.653l.253 1.767c.02.16.16.28.327.28h2.666c.167 0 .307-.12.327-.28l.253-1.767c.407-.167.78-.393 1.127-.653l1.66.666c.153.06.327 0 .407-.146l1.333-2.307a.336.336 0 0 0-.08-.427l-1.407-1.1zM8 10.333A2.336 2.336 0 0 1 5.667 8 2.336 2.336 0 0 1 8 5.667 2.336 2.336 0 0 1 10.333 8 2.336 2.336 0 0 1 8 10.333z"/>
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
          <path d="M0 0h16v16H0z"/>
          <mask id="c" fill="#fff">
            <use xlinkHref="#d"/>
          </mask>
          <path fill="currentColor" d="M0 0h16v16H0z" mask="url(#c)"/>
        </g>
    </SvgIcon>
  );
};