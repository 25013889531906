
/**
 * Actions
 */

export const SET_LIMIT = 'SET_LIMIT';



/**
 * Action creators
 */

export function setLimit(limit: number) : object {
  return {type: SET_LIMIT, limit}
}



/**
 * Async action creators
 */