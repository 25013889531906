import React, { Fragment, useEffect } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import TaskStatusLabel from '../../project/board/TaskStatusLabel';
import classNames from 'classnames';
import { formatDate, getProgress, getProgressColor } from '../../../utils/utils';
import { Currencies, TableType, TableTypeNames, TableTypeSelector } from '../../../shared/types';
import ProgressLine from '../../ProgressLine';
import { ISort } from '../../../shared/interfaces';
import { useSelector } from 'react-redux';

const styles = (theme: Theme) => createStyles({
  header: {
    fontFamily: 'Montserrat',
    fontSize: 17,
    fontWeight: 500,
    lineHeight: 1.41,
    color: '#233539',
    marginBottom: 20
  },
  headerSmall: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: 10,
    lineHeight: 2
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 450,
    marginBottom: 40
  },
  row: {
    borderBottom: '1px solid #d9e4e8',
    boxSizing: 'border-box',
    padding: 15, 
    width: '100%',
    backgroundColor: '#fff',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.67,
    color: '#233539',
    margin: '0 0 5px',
    '&:first-child:last-child': {
      margin: 0
    }
  },
  projectTitle: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.43,
    color: '#233539',
    margin: '0 0 2px'
  },
  caption: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    color: '#233539',
    display: 'flex',
    marginBottom: 14
  },
  currencyValue: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.67,
    color: '#233539',
    marginBottom: 13,
    display: 'flex'
  },
  extraInfo: {
    display: 'flex',
  },
  closing: {
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 1.67,
  },
  closingLabel: {
    fontWeight: 400,
    marginRight: 4
  },
  closingDate: {
    
  },
  progress: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  progressLine: {
    width: 75,
    height: 10,
    marginRight: 12
  },
  progressNumber: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.67,
  },
})

interface IProps extends WithStyles<typeof styles> {
  rows: any[];
  header: any;
  smallHeader?: boolean;
  type: TableType;
  disableCaption?: boolean;
  onSort?: (sort: ISort[], search: string) => any;
}

const MobileList = (props: IProps) => {
  const { classes } = props;
  const search = useSelector((state: any) => state.search.search);
  const sort = useSelector((state: any) => state.user.user.sorting_prefs?.[TableTypeSelector[props.type]] ?? []);




  useEffect(() => {
    if (!!sort?.length && !!props.onSort) {
      props.onSort(sort, !!search ? `limit=10000&search=${search}` : `limit=10000`)
    }
  }, [sort, search])




  const renderEmptyList = (): any => {
    return (
      <div className={classes.row}>
        <h2 className={classes.title}>{`No ${TableTypeNames[props.type]} yet`}</h2>
      </div>
    )
  }





  const renderRow = (row: any, index: number) => {
    const progress = Math.round(row.progress_in_percent);
    switch(props.type) {
      case TableType.PROJECTS:
        return (
          <div key={row.id} className={classes.row}>
            <h2 className={classes.projectTitle}>{row.name}</h2>
            <span className={classes.currencyValue}>
              {`${Currencies[row.currency]} ${row.capital_required}`}
            </span>
            <div className={classes.extraInfo}>

              <div className={classes.closing}>
                <span className={classes.closingLabel}>Closing: </span>
                <span className={classes.closingDate}>
                  {formatDate(row.closing_date, false, true)}
                </span>
              </div>

              <div className={classes.progress}>
                <ProgressLine
                  progress={progress}
                  className={classes.progressLine}
                />
                <span className={classes.progressNumber} style={{color: getProgressColor(progress)}}>
                  {progress}%
                </span>
              </div>

            </div>
          </div>
        )
      case TableType.TASKS:
      case TableType.CLOSING_TASKS:
      default:
        return (
          <div key={row.id} className={classes.row}>
            <h2 className={classes.title}>{row.title}</h2>
            {!props.disableCaption
              ? <span className={classes.caption}>{row.project_details.name}</span>
              : null
            }
            <TaskStatusLabel type={row.state} dueDate={row.due_date} />
          </div>
        )
    }
  }




  return (
    <Fragment>
      <div className={classNames(
        classes.header,
        {[classes.headerSmall]: props.smallHeader}
      )}>
        {props.header}
      </div>

      <div className={classes.container}>
        {props.rows && !!props.rows.length
          ? props.rows.map(renderRow)
          : renderEmptyList()
        }
      </div>
    </Fragment>
  )
}


export default withStyles(styles)(MobileList)