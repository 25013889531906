import React from 'react';
import TextInput from "../../../TextInput";
import {Rating} from "@material-ui/lab";
import Tags from "../../../Tags";
import SectionWithHeader from "../../../SectionWithHeader";


export default ({value, onChange, label, placeholder, className, small}) => {

    if (value === undefined) value = [];


    const onAdd = (newTag) => {
        const tags = value;

        if (tags.filter(cand => `${cand}`.toLowerCase() === newTag.toLowerCase()).length) return;
        else onChange([...tags, newTag])
    };

    const onRemove = (tag) => {
        const filtered = value.filter(cand => `${cand}`.toLowerCase() !== tag.toLowerCase());

        onChange(filtered)
    };

    return <Tags
        tags={value}
        placeholder={placeholder}
        onAdd={onAdd}
        onDelete={onRemove}
        className={className}
    />
}