import React, { useState, useEffect } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useAnchor } from '../shared/hooks';
import Button from './Button';
import ArrowDownIcon from '@material-ui/icons/ExpandMore';
import Menu from "./Menu";

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  actionsButton: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    height: 'initial',
    padding: '0 7px'
  },
  arrowDownIcon: {
    fontSize: 20,
    color: '#fff',
    marginLeft: 3
  },
})

interface IProps extends WithStyles<typeof styles> {
  children: any;
  options: any[];
  onSelect: (option: any) => any;
  className?: string;
}

const ButtonWithMenu = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();
  const [option, setOption] = useState<any>();



  const onSelect = (e: any, option: any): void => {
    anchor.close();
    setOption(option)
  }



  useEffect(() => {
    if(!!option && !anchor.value) {
      props.onSelect(option)
      setOption(undefined);
    }
  }, [anchor.value, option])



  return (
    <div className={classNames(classes.root, props.className)}>
      <Button primary className={classes.actionsButton} onClick={anchor.set}>
        {props.children} <ArrowDownIcon className={classes.arrowDownIcon} />
      </Button>
      <Menu
        anchor={anchor}
        options={props.options}
        onSelect={onSelect}
        optionValue="label"
      />
    </div>
  )
}


export default withStyles(styles)(ButtonWithMenu)