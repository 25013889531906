let envApiEndpoint = ''

switch (window.location.hostname) {
  case 'ft8.com':
    envApiEndpoint = 'https://api.ft8.com/api'
    break;

  case 'dev.ft8.com':
    envApiEndpoint = 'https://dev-api.ft8.com/api'
    break;

  default:
    envApiEndpoint = '/api'
    break;
}


export const API_URL = envApiEndpoint;