import { REQUEST_CANCEL } from "../../shared/types";


export default class RequestManager {
  options: any;
  pendingRequests: any;

  constructor(options: object = {}) {
    this.options = options;
    this.pendingRequests = {};
  }


  

  addRequest(requestId: string, cancelFn: any, url: string) {
    this.log(`adding request \`${requestId}\``);

    if (this.has(requestId)) {
      this.cancelRequest(requestId, `\`cancelRequest(${requestId})\` from \`RequestManager.addRequest\`.
      Found duplicate pending request.`);
      this.pendingRequests[requestId] = { cancel: cancelFn, url };
    } else {
      this.pendingRequests[requestId] = { cancel: cancelFn, url };
    }
  }



  removeRequest(requestId: string) {
    this.log(`removing request \`${requestId}\``);

    delete this.pendingRequests[requestId];
  }



  cancelRequest(requestId: string, reason: any = `\`cancelRequest(${requestId})\` from \`RequestManager.cancelRequest\``) {
    this.log(`cancelling request \`${requestId}\``);

    if (this.has(requestId)
    && typeof this.pendingRequests[requestId]?.cancel === 'function') {
      this.pendingRequests[requestId].cancel(reason);
      this.removeRequest(requestId);
    
      this.log(`request \`${requestId}\` cancelled`);
    }
  }



  cancelAllRequests() {
    for (let requestId in this.pendingRequests) {
      let reason = {
        type: REQUEST_CANCEL,
        url: this.pendingRequests[requestId]?.url
      }
      this.cancelRequest(requestId, reason);
    }
  }



  has(requestId: string) {
    return !!this.pendingRequests[requestId];
  }



  log(message: any) {
    if (this.options.debug === true) {
      console.log(message);
    }
  }
}
