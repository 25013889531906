import React from "react"
import { WithStyles, Divider } from "@material-ui/core"
import { createStyles, withStyles } from "@material-ui/styles"
import BasicHeader from "../../components/BasicHeader"
import Footer from "../../components/footer/Footer"
import { styles } from './styles'

interface IProps extends WithStyles<typeof styles> {
}


const PrivacyPolicy = (props: IProps) => {
  const { classes } = props;

  return (
    <div className={classes.containerWrapper}>
      <BasicHeader />

      
      <div className={classes.container}>
        <div className={classes.document}>
          <div className={classes.title}>{"Privacy Policy"}</div>
          <div className={classes.header}>{"1. Section One Heading"}</div>
          <div className={classes.section}>
            <span className={classes.numbering}>1.1</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>1.2</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>1.3</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>1.4</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <Divider />
          <div className={classes.header}>{"2. Section Two Heading"}</div>
          <div className={classes.section}>
            <span className={classes.numbering}>2.1</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>2.2</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>2.3</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>2.4</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>2.5</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>2.6</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <Divider />
          <div className={classes.header}>{"3. Section Three Heading"}</div>
          <div className={classes.section}>
            <span className={classes.numbering}>3.1</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>3.2</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>3.3</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>3.4</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>3.5</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <Divider />
          <div className={classes.header}>{"4. Section Four Heading"}</div>
          <div className={classes.section}>
            <span className={classes.numbering}>4.1</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>4.2</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>4.3</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>4.4</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
          <div className={classes.section}>
            <span className={classes.numbering}>4.5</span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tristique risus risus, in dignissim est sodales nec. Curabitur sagittis, nunc eu pellentesque commodo, lectus tellus dapibus nibh, et auctor risus justo eu tortor.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withStyles(styles)(PrivacyPolicy)