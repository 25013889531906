import React from "react";
import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { ROUTE_PRIVACY_POLICY, ROUTE_COOKIE_POLICY, ROUTE_TS_AND_CS } from "../../constants/routes";


const styles = () => createStyles({
  footer: {
    paddingTop: 17,
    display: 'flex',
    color: '#657274',
    fontFamily: 'Montserrat',
    fontSize: 11,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  logo: {
    padding: '12px 20px',
    height: 21
  },
  right: {
    display: 'flex'
  },
  link: {
    padding: 3,
    textDecoration: 'none',
    color: '#657274',
    transition: 'color .1s ease',
    '&:hover': {
      color: '#4192ec !important'
    },
  }
});

interface IProps extends WithStyles<typeof styles> {
}


const Footer = (props: IProps) => {
  const { classes } = props;

  return (
    <div className={classes.footer}>
      FinTech8 ©2020 | 
      <a href={ROUTE_PRIVACY_POLICY} className={classes.link}>Privacy Policy</a> |
      <a href={ROUTE_COOKIE_POLICY} className={classes.link}>Cookie Policy</a> |
      <a href={ROUTE_TS_AND_CS} className={classes.link}>{"Terms & Conditions"}</a>
    </div>
  );
}

export default withStyles(styles)(Footer)