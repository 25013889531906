import React, { memo, useMemo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { TableColumn } from '../../../shared/interfaces';
import Rating from '../../Rating';
import { clickFix, groupBy } from '../../../utils/utils';
import PersonTypeChip from '../../PersonTypeChip';
import { useSelector } from 'react-redux';
import { TableCell } from '@material-ui/core';
import { highlight } from '../../../utils/component-utils';
import TaskStatusLabel from '../../project/board/TaskStatusLabel';
import ProgressLine from '../../ProgressLine';
import CapitalProviderLabel from '../../CapitalProviderLabel';
import NewContactsActionsButton from '../../NewContactsActionsButton';
import { TableType, TaskType } from '../../../shared/types';
import ActivityDescription from '../../activity/ActivityDescription';
import TableCellValue from '../TableCellValue';
import PhoneLink from '../../PhoneLink';
import TableRowProgress from './TableRowProgress';
import TableRowUserLink from './TableRowUserLink';



const TableRowCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '6px 20px 6px 0',
      '&:first-child': {
        padding: '14px 10px 14px 20px',
        textAlign: 'center',
        minWidth: 16
      }
    },
    body: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  })
)(TableCell);



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  groupedCellCounter: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    lineHeight: 2,
    color: '#90a0a7',
    marginRight: 13,
  },
  linkClass: {
    color: '#4192ec',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    }
  },
  rowCell: {
    cursor: 'pointer',
    maxWidth: 400
  },
  rowText: {
    // ...theme.typography.body2,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: '#233539',
    paddingLeft: 5
  },
  strike: {
    color: '#657274',
    textDecorationLine: 'line-through'
  },
  groupedCell: {
    padding: '14px 6px',
    fontWeight: 400
  },
  counterColumn: {
    display: 'flex'
  },
  wrapped: {
    whiteSpace: 'normal'
  }
})

interface IProps extends WithStyles<typeof styles> {
  columns: TableColumn[];
  row: any;
  rowIdx: number;
  onInvite?: (e: any, rowId: number) => any;
  groupBy?: string;
  fullAccess?: boolean;
  tableType: TableType;
  setNewContactsEmail: any;
  setNewContactsProps: any;
  wrapColumns?: boolean;
}

const TableSingleRowData = (props: IProps) => {
  const { classes, columns, row } = props;
  const types = useSelector((state: any) => state.types);
  const search = useSelector((state: any) => state.search);






  const checkHighlighted = (text: any) => {
    if (!search.search || !search.highlight || !text) return text
    return highlight(text, search.search)
  }




  const getColSpan = (colIdx: number): number => {
    return props.fullAccess && colIdx === columns.length - 1 && props.tableType !== TableType.PROJECTS ? 3 : 1
  }



  const onInvite = (e: any): void => {
    props.onInvite?.(e, row.id)
  }



  const getColumnValue = (column: TableColumn, row: any): any => {
    switch (column.type) {

      case 'rating':
        return <Rating rate={column.value(row)} />


      case 'phones':
        return (
          <PhoneLink
            tel={column.value(row)}
            search={search.search}
            defaultVal={'--'}
          />
        )


      case 'profile_type':
        return (
          <PersonTypeChip
            rowId={row.id}
            profileType={row.profile_type}
            inviteStatus={row.invite_status}
            onInvite={onInvite}
          />
        )


      case 'task_status':
        return (
          <TaskStatusLabel type={row.state} dueDate={row.dueDate || row.due_date} />
        )


      case 'progress':
        return <TableRowProgress progress={row.progress_in_percent || 0} />


      case 'cp_manage_state':
      case 'cp_manage_note':
        return null;


      case 'new_contacts_actions':
        return (
          <NewContactsActionsButton
            contact={column.value(row)}
            onAddToExisting={props.setNewContactsEmail}
            onInit={props.setNewContactsProps}
            tableType={props.tableType}
          />
        )


      case 'user':
        return (
          <TableRowUserLink
            person={column.value(row)}
            search={search.search}
            shouldHighlight={search.highlight}
          />
        )


      case 'activity_description':
        return <ActivityDescription activity={column.value(row)} />


      default:
        return column.value
          ? <TableCellValue>
              {checkHighlighted(column.value(row, column.typeField && types[column.typeField])) || '--'}
            </TableCellValue>
          : null
    }
  }



  const renderTableRow = (column: TableColumn, columnIdx: number): any => {
    return (
      <TableRowCell
        key={column.type}
        colSpan={getColSpan(columnIdx)}
        className={classNames(
          classes.rowCell,
          classes.rowText,
          'row-for-print',
          {
            [classes.strike]: [TableType.TASKS, TableType.CLOSING_TASKS].includes(props.tableType) && row.state === TaskType.Approved && column.type !== 'task_status',
            [classes.groupedCell]: !!props.groupBy,
            [classes.wrapped]: props.wrapColumns
          },
        )}
      >

        {!!props.groupBy && columnIdx === 0
          ? (
              <div className={classes.counterColumn}>
                <span className={classes.groupedCellCounter}>{props.rowIdx + 1}</span>
                <span>{getColumnValue(column, row)}</span>
              </div>
            )
          : <span>{getColumnValue(column, row)}</span>
        }
        
      </TableRowCell>
    )
  }



  return useMemo(() => (
    <>{columns.map(renderTableRow)}</>
  ), [columns, search.search, search.highlight, row, types])
}


export default withStyles(styles)(TableSingleRowData)