
import { createStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React from "react";
import { useSelector } from "react-redux";
import { Theme } from "../../../../theme";
import { getIds } from "../../../../utils/utils";
import Select from "../../../Select";


const styles = (theme: Theme) => createStyles({
    root: {
        outline: 'none'
    },
    spacingBottom: {
        marginBottom: 10
    }
});


const StoreDropdown = (props: any) => {
    const {classes, onChange, placeholder, getOptions, isVisible, checkboxes, spacingBottom, dependingStoreData, dependsOn} = props;
    let { value } = props;


    if (value === undefined) value = null;

    const options : any[] = useSelector(getOptions);

    if(isVisible !== undefined && !isVisible?.(options)) return null;
    if(!options) return null;


    const getFilteredOptions = (): any[] => {
        if(dependsOn === 'cp_subcategories') {

            if(dependingStoreData.is_debt && dependingStoreData.is_equity) {
                return options;
            }

            return dependingStoreData.is_debt
                ? options.filter((option: any) => option.is_debt)
                : options.filter((option: any) => option.is_equity)

        }


        return options
    }


    // Select operates with objects, and we expect actual IDs in state
    // to avoid any processing when sending.
    // This code will get that item value with a provided ID to avoid compat issues.

    let valueItem;

    if(checkboxes && value) {
        valueItem = options.filter((item: any) => value.includes(item.id));
    } else {
        const valueItems = options.filter(x => x.id === value);
        valueItem = valueItems.length ? valueItems[0] : null;
    }




    return <Select
        className={classNames(classes.root, {[classes.spacingBottom]: spacingBottom})}
        onSelect={(item: any) => onChange(checkboxes ? getIds(item) : item.id, props.name)}
        valuesList={getFilteredOptions()}
        value={valueItem}
        placeholder={placeholder}
        checkboxes={checkboxes}
    />
}

export default withStyles(styles)(StoreDropdown)