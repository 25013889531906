import * as actions from '../actions/advanced-search';
import { ISearchRow } from '../shared/models/Search';
import { INITIAL_SEARCH_ROW } from '../shared/types';

const initialList = {
  results: [] as any[],
  count: 0,
  next: null,
  previous: null
}


const initialRows = [INITIAL_SEARCH_ROW] as ISearchRow[];


export const searchInitialState = {
  list: initialList,
  rows: initialRows,
  loading: false,
};


export default function (state = searchInitialState, action) {
  let newRows;

  switch (action.type) {

    case actions.SET_RESULTS:
      return {
        ...state,
        list: action.data
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }

    
    case actions.APPEND_RESULTS:
      return {
        ...state,
        list: {
          ...state.list,
          results: [...state.list.results, ...action.results],
          next: action.next,
          previous: action.previous
        }
      }


    case actions.REMOVE_FROM_RESULTS:
      return {
        ...state,
        list: {
          ...state.list,
          results: state.list.results.filter((item: any) => !action.ids.includes(item.id)),
          count: state.list.count - action.ids.length
        }
      }

    
    case actions.CLEAR_RESULTS:
      return {
        ...state,
        list: initialList
      }


    case actions.SET_ROWS:
      return {
        ...state,
        rows: action.data
      }


    case actions.CLEAR_ROWS:
      return {
        ...state,
        rows: initialRows
      }


    case actions.ADD_ROW:
      return {
        ...state,
        rows: [...state.rows, action.row]
      }


    case actions.DELETE_ROW:
      return {
        ...state,
        rows: state.rows.filter((_, idx: number) => idx !== action.idx)
      }


    case actions.CHANGE_ROW:
      newRows = [...state.rows];
      newRows[action.idx] = {
        ...newRows[action.idx],
        ...action.data
      };

      return {
        ...state,
        rows: newRows
      }


    case actions.CHANGE_SUBROW:
      newRows = [...state.rows];
      newRows[action.parentIdx].subRows[action.idx] = {
        ...newRows[action.parentIdx].subRows[action.idx],
        ...action.data
      }


      return {
        ...state,
        rows: newRows
      }


    default:
      return state
  }
}