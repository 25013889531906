import { ClickAwayListener, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import memoize from "memoize-one";
import React, { KeyboardEvent, useEffect, useState, Fragment } from "react";
import { useOpen } from "../shared/hooks";
import { IPerson } from "../shared/models/Person";
import { AutocompleteType, SELECT_ITEM_NONE_ID } from "../shared/types";
import { Theme } from "../theme";
import { splitBySpace } from "../utils/autocomplete";
import { getUsername } from "../utils/user";
import SelectMenu from "./SelectMenu";
import TextInput from "./TextInput";
import projects from "../reducers/projects/projects";
import { SelectItem } from "../shared/interfaces";
import { formatAutocompleteItems } from "../utils/utils";

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    outline: 0,
  },
  menuContainer: {
    transformOrigin: 'top center',
    transform: 'scaleY(0)',
    opacity: 0,
    width: '100%',
    zIndex: 100,
    outline: 0,
    transition: theme.transitions.create(
      ['transform', 'opacity']
    ),
  },
  menuContainerOpened: {
    transform: 'scaleY(1)',
    opacity: 1,
  },
  menu: {
    position: 'absolute',
    border: '1px solid #7ab3f2',
    boxSizing: 'border-box',
    borderTop: 0,
    borderRadius: '0 0 4px 4px',
    backgroundColor: theme.palette.primary.light
  },
  inputOpened: {
    outline: '0',
    borderColor: '#7ab3f2',
    borderBottomColor: '#c6def9',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  }
});

interface IProps extends WithStyles<typeof styles> {
  items: any[],
  maxItems: number,
  type: AutocompleteType;
  value?: any,
  onChange: (item?: SelectItem|string) => any,
  readonly?: boolean,
  inputStyle?: any;
  className?: any;
  error?: boolean;
  doNotSort?: boolean;
  placeholder?: string;
  startAdornment?: string;
  endAdornment?: string;
}




const emptyItem = (val?: string): SelectItem => ({ id: SELECT_ITEM_NONE_ID, name: val ?? ''})



const Autocomplete = (props: IProps) => {
  const { classes, maxItems = 5, readonly, type } = props;
  const menuControls = useOpen();
  const [activeCandidateId, setActiveCandidateId] = useState<number>(-1);
  const [selectedItem, setSelectedItem] = useState<SelectItem|undefined>(undefined);
  const [value, setValue] = useState<SelectItem>(emptyItem());
  const [items, setItems] = useState<SelectItem[]>([]);






  const isOpened = (): boolean => {
    return !readonly && menuControls.value && !!items.length && selectedItem?.id === SELECT_ITEM_NONE_ID
  }



  const chooseItem = (item: SelectItem): void => {
    if (readonly) return;

    menuControls.close();
    setSelectedItem(item);
    setValue(item)
  };



  const handleInputChange = (newValue: any): void => {
    setValue(emptyItem(newValue))
  };



  const handleKey = (event: KeyboardEvent): void => {
    if(!menuControls.value) return;


    if(items.length === 0) {
      return setActiveCandidateId(-1)
    }

    switch(event.key) {

      case 'ArrowDown':
        return setActiveCandidateId(
          activeCandidateId + 1 >= items.length ? 0 : activeCandidateId + 1
        )
      
      case 'ArrowUp':
        return setActiveCandidateId(
          activeCandidateId - 1 < 0 ? items.length - 1 : activeCandidateId - 1
        )

      case 'Enter':
        if(!items?.[activeCandidateId]) return;
        return chooseItem(items[activeCandidateId])
    }
  }







  useEffect(() => {
    const formattedItems = formatAutocompleteItems(props.items, type);
    const newItems = formattedItems
      .filter((item: SelectItem) => item.name.toLowerCase().includes(value.name.toLowerCase()))
      .slice(0, maxItems)

    setItems(newItems)
  }, [value, props.items, maxItems, type])



  useEffect(() => {
    setActiveCandidateId(-1)
  }, [value])


  // This function is causing issues on prod - testing without
  // useEffect(() => {
  //   if(items.length === 1 && items[0]?.name === value.name) {
  //     return menuControls.close()
  //   }

  //   if(!!value.name && items.length) {
  //     menuControls.open()
  //   }
  // }, [value, items])



  useEffect(() => {
    switch(type) {
      case AutocompleteType.Companies:
      case AutocompleteType.Countries:
        props.onChange(selectedItem?.name)
        break;

      default:
        props.onChange(selectedItem);
    }
  }, [selectedItem, type])



  useEffect(() => {
    if(selectedItem === undefined && !value.name && !!props.value) {
      setValue(emptyItem(props.value))
      return;
    }


    if(selectedItem?.name !== value?.name) {
      setSelectedItem(emptyItem(value?.name))
      return
    }

    
  }, [value, selectedItem, props.value])







  return (
    <ClickAwayListener onClickAway={menuControls.close}>
      <div
        onKeyDown={handleKey}
        className={classNames(classes.container, props.className)}
      >
        <TextInput
          value={value.name}
          onChange={handleInputChange}
          readonly={readonly}
          inputStyle={props.inputStyle}
          error={props.error}
          placeholder={props.placeholder}
          onFocus={menuControls.open}
          // onBlur={menuControls.close}
          startAdornment={props.startAdornment}
          endAdornment={props.endAdornment}
          wrapperClass={classNames(
            {[classes.inputOpened]: isOpened()}
          )}
        >
        </TextInput>


        <div
          tabIndex={999999}
          className={classNames(
            classes.menuContainer,
            {[classes.menuContainerOpened]: isOpened()}
          )}
        >
          <SelectMenu
            classes={{root: classes.menu}}
            valuesList={items}
            activeId={activeCandidateId}
            onClick={chooseItem}
            highlightText={value.name}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
}



export default withStyles(styles)(Autocomplete)