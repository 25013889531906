
import * as actions from '../actions/email-templates';

export const initialState = {
  results: [] as any,
  count: 0,
  next: null,
  previous: null,
  initiated: false,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {


    case actions.SET_TEMPLATES:
      return {
        ...state,
        ...action.templates,
        initiated: true
      }


    case actions.APPEND_TEMPLATES:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }
    

    case actions.REMOVE_MULTIPLE_TEMPLATES:
      return {
        ...state,
        results: state.results.filter((item: any) => !action.ids.includes(item.id)),
        count: state.count - action.ids.length
      }


    case actions.REMOVE_TEMPLATE:
      return {
        ...state,
        results: state.results.filter((item: any) => item.id !== action.id),
        count: --state.count
      }
    

    case actions.APPEND_SINGLE_TEMPLATE:
      return {
        ...state,
        results: [...state.results, action.template],
        count: ++state.count
      }
  

    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }


    default:
      return state
  }
}