import * as actions from '../actions/activity';

export const activityInititalState = {
  results: [] as any[],
  count: 0,
  next: null,
  previous: null,
  loading: false,
};

export default function(state = activityInititalState, action) {

  switch (action.type) {


    case actions.SET_ACTIVITY:
      return {
        ...state,
        ...action.activity
      }


    case actions.UPDATE_ACTIVITY:
      return {
        ...action.activity, 
        results: [...state.results, ...action.activity.results],
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }
  

    default:
      return state
  }
}