import { TableCell } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserTablePrefs } from '../../../actions/auth';
import { ARCHIVED_SEARCH } from '../../../constants/index';
import { ISort, ManageableTableColumn, SortDirection, TableColumn } from '../../../shared/interfaces';
import { TableType, TableTypeSelector } from '../../../shared/types';
import { Theme } from '../../../theme';
import { formatISort, assignDefaultSort } from '../../../utils/utils';
import TableHeader from './TableHeader';
import TableHeadersCheckbox from './TableHeadersCheckbox';



const styles = (theme: Theme) => createStyles({
  tableCell: {
    boxSizing: 'border-box',
    padding: '9px 8px 7px 5px',
    minWidth: 200,
    '&:first-child': {
      paddingLeft: 25
    },
    '&:last-child': {
      paddingRight: 36
    }
  },
  lastHeadCell: {
    paddingRight: 40
  },
})


interface IProps extends WithStyles<typeof styles> {
  columns: TableColumn[];
  tableType: TableType;
  noSort?: boolean;
  fullAccess?: boolean;
  selectable?: boolean;
  searchItem?: any;
}

const TableHeaders = (props: IProps) => {
  const { classes, columns, tableType, searchItem } = props;
  const dispatch = useDispatch();
  const tablePrefs = useSelector((state: any) => state.user.user.table_column_prefs?.[!!searchItem ? ARCHIVED_SEARCH : TableTypeSelector[props.tableType]] ?? []);
  const tables = useSelector((state: any) => state.user.user.table_column_prefs);
  const [sort, setSort] = useState<ISort[]>([]);
  const tablePrefsSort = useMemo(() => formatISort(tablePrefs), [tablePrefs])
  const tableSelector = useMemo(() => !!searchItem ? ARCHIVED_SEARCH : TableTypeSelector[tableType], [tableType, searchItem])
  

  
  const existingSorting = useMemo(() => tablePrefs
    .filter((item: ManageableTableColumn) => item.active)
    .filter((item: ManageableTableColumn) => item.sortOrder !== undefined), [tablePrefs]
  );





  


  

  const getColumnWidth = (column: TableColumn): string => {
    const width = columns.find((item: TableColumn) => !!item.tableWidth);

    if(!width) {
      return `${100 / columns.length}%`
    }


    return width.type === column.type
      ? column.tableWidth ?? ''
      : `calc((100% - ${column.tableWidth}) / ${columns.length})`
  }



  const getSortColumn = (type: string): ManageableTableColumn | undefined => {
    return tablePrefs.find((item: ManageableTableColumn) => item.type === type)
  }



  const getSortColumnIndex = (type: string): number | undefined => {
    return tablePrefs.find((item: ManageableTableColumn) => item.type === type)?.sortOrder
  }



  const onSort = (column: ManageableTableColumn) => {
    if (!column.search) return;

    const newTablePrefs = tablePrefs.map((item: ManageableTableColumn) => item.type === column.type
      ? {
          ...item,
          sortOrder: item.sortOrder ?? existingSorting.length + 1,
          sortDirection: item.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
        }
      : item
    )

    dispatchSetTablePrefs(newTablePrefs)
  };



  const onSortRemove = (column: ManageableTableColumn): void => {
    const removedIdx = column.sortOrder;
    if(removedIdx === undefined) return;


    let newTablePrefs = tablePrefs
      .map((item: ManageableTableColumn) => item.type === column?.type
        ? { ...item, sortOrder: undefined, sortDirection: undefined }
        : item
      )
      .map((item: ManageableTableColumn) => item.sortOrder !== undefined && item.sortOrder > removedIdx
        ? { ...item, sortOrder: item.sortOrder - 1 }
        : item
      )


    if(!newTablePrefs.filter((item: ManageableTableColumn) => item.sortOrder !== undefined).length) {
      newTablePrefs = assignDefaultSort(columns, tablePrefs);
    }
    

    dispatchSetTablePrefs(newTablePrefs)
  }



  const refreshSort = (): void => {
    if(!existingSorting.length && !!columns.length) {
      const newTablePrefs = assignDefaultSort(columns, tablePrefs)
      return dispatchSetTablePrefs(newTablePrefs)
    }


    // const columnTypes = columns.map((item: TableColumn) => item.type);
    // let newSort = sort.filter((item: ISort) => columnTypes.includes(item.type));

  
    // if (sort.every((item: ISort) => !!newSort.find((subItem: ISort) => subItem.type === item.type))) {
    //   return
    // }


    // if(!newSort.length && !!columns.length) {
    //   newSort = assignDefaultSort(columns)
    //   return dispatchSortSet(newSort)
    // }


    // return dispatchSortSet(newSort)
  }





  const dispatchSetTablePrefs = (newPrefs: ManageableTableColumn[]): any => {
    return dispatch(changeUserTablePrefs(tableSelector, newPrefs))
  }





  useEffect(() => {
    if (searchItem?.sorting) {
      setSort(searchItem.sorting)
    }
  }, [])


  useEffect(() => {
    if(!!columns) {
      refreshSort()
    }
  }, [columns, sort])





  useEffect(() => {
    if(JSON.stringify(tablePrefsSort) === JSON.stringify(sort)) return;

    setSort(tablePrefsSort)
  }, [tablePrefsSort, sort])





  return <>
    <TableCell />



    {props.selectable && (
      <TableHeadersCheckbox
        className={classNames(classes.tableCell, 'hide-on-print')}
        tableType={tableType}
        fullAccess={props.fullAccess}
      />
    )}
    

    



    {columns.map((column: TableColumn, idx: number) => (
      <TableCell key={column.type}
        className={classNames(
          classes.tableCell,
          {[classes.lastHeadCell]: idx === columns.length - 1}
        )}
        style={{
          width: getColumnWidth(column),
          minWidth: column.tableWidth ?? undefined
        }}
      >
        <TableHeader
          onClick={onSort}
          onRemoveSort={onSortRemove}
          index={getSortColumnIndex(column.type)}
          activeSort={getSortColumn(column.type)}
          noSort={!column.search || props.noSort}
        >
          {column.labelOnTable || column.label}
        </TableHeader>
      </TableCell>
    ))}
  </>
}


export default withStyles(styles)(TableHeaders)