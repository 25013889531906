import { createStyles } from "@material-ui/styles"

export const styles = () => createStyles({
    containerWrapper: {
      height: 'auto !important',
      position: 'relative'
    },
    container: {
      marginTop: 56,
      width: '100%',
      minheight: '100%',
      backgroundColor: '#f0f3f5',
      display: 'flex',
      justifyContent: 'space-between',
      fontFamily: 'Montserrat',
      color: '#233539',
      lineHeight: '20px',
      paddingBottom: 100
    },
    document: {
      margin: '32px 30px',
      padding: 20,
      width: '100%',
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
    },
    title: {
      fontSize: 18,
      fontWeight: 500,
    },
    header: {
      fontSize: 14,
      fontWeight: 600,
      color: '#233539',
      marginTop: 20,
      marginBottom: 7.5,
    },
    section: {
      fontSize: 12,
      color: '#657274',
      paddingBottom: 20
    },
    numbering: {
      fontWeight: 500,
    },
  })