import * as React from "react";
import { Theme, palette } from "../theme";
import { createStyles, withStyles } from "@material-ui/styles";
import { WithStyles, Grid } from "@material-ui/core";
import FormCard from "./FormCard";

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: palette.palegrey100,
    height: '100vh',
    overflow: 'auto'
  },
  mainGrid: {
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '18vh'
  },
  logoContainer: {
    width: 200,
    marginBottom: 45
  },
  formContainer: {
    width: '425px',
    minWidth: 300
  }
});

interface IProps extends WithStyles<typeof styles> {
  children?: any
}

class LoginPageCard extends React.Component<IProps> {
  render() {
    const { classes, children } = this.props;
    return (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justify="center"
        className={classes.mainGrid}
      >
        <Grid item sm={6} className={classes.root}>
          <div className={classes.mainContainer}>
            <div className={classes.logoContainer}>
              <img src="/img/logo_black.svg" alt="" />
            </div>
            <div className={classes.formContainer}>
              {children}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(LoginPageCard)