import classes from '*.module.css';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Theme } from '../../theme';
import { getUsernameWithCompany } from '../../utils/user';
import { goTo } from '../../utils/utils';
import DownloadLink from './DownloadLink';
import PersonToExportLink from './PersonToExportLink';
import PersonToLink from './PersonToLink';
import { highlight } from '../../utils/component-utils';







const peopleToLinks = (people: any[], goToPerson: any, className?: string): any => {
  if(!people?.length) return null;


  return people.map((person: any, idx: number) => (
    <>
      <PersonToLink className={className} person={person} onGoToPerson={goToPerson} />
      {idx === people.length - 1 ? '' : ', '}
    </>
  ))
}







const styles = (theme: Theme) => createStyles({
  link: {
    color: '#4192ec',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  }
})

interface IProps extends WithStyles<typeof styles> {
  activity: any;
}

const ActivityDescription = (props: IProps) => {
  const { classes, activity } = props;
  const { type, args, document } = activity as any;
  const history = useHistory();
  const search = useSelector((state: any) => state.search);
  const types = useSelector((state: any) => state.types.activity_types);
  const activityType = types?.find((item: any) => item.id === type)?.name;




  const formatFields = (fields: any[]) => {
    if(!fields?.length) return '';
  
    const formattedFields = fields
      .filter((field: string) => field.toLowerCase() !== 'id')
      .map((field: String) => field.replace('_', ' '))
      .map((field: String) => field.charAt(0).toUpperCase() + field.slice(1))
      .join(', ')
  
    return checkHighlight(` (${formattedFields})`);
  }
  
  
  
  const peopleToText = (people: any[]) => {
    if(!people?.length) return '';
  
    return people.length > 1
      ? <>{checkHighlight(`${people.length} contacts`)}</>
      : checkHighlight(people.map(getUsernameWithCompany).join(', '))
  }



  const checkHighlight = (text: string): any => {
    if(!search.highlight || !search.search) return text;
    return highlight(text, search.search);
  }



  const goToPerson = (id: number, e: any): void => {
    if(!id) return;

    goTo(e, `/contacts/${id}`, history)
  }



  const onImportLinkClick = (file: string, e: MouseEvent<HTMLSpanElement>): void => {
    if(!file) return;

    goTo(e, file, history)
  }






  switch(activityType) {


    case 'Created Manually':
      return <>
        {checkHighlight(`Created `)}
        <PersonToLink
          className={classes.link}
          person={args.person}
          onGoToPerson={goToPerson}
        />
        {checkHighlight(` manually`)}
      </>



    case 'Created via CSV':
      return <>
        {checkHighlight(`Imported `)}
        <DownloadLink
          className={classes.link}
          document={document}
          file={args?.file}
          onImportClick={onImportLinkClick}
        />
        {checkHighlight(`. (${activity?.args?.created?.length ?? 0} Created, ${activity?.args?.failed_lines?.length ?? 0} Failed, ${activity?.args?.duplicate ?? 0} Duplicates)`)}
      </>



    case 'Modified':
      return <>
        {checkHighlight(`Modified `)}
        {peopleToLinks(args?.people, goToPerson, classes.link)}
        <span className={classes.capitalize}>
          {formatFields(args.fields)}
        </span>
      </>



    case 'Merged':
      return <>
        {checkHighlight(`Merged `)}
        <PersonToLink
          className={classes.link}
          person={args.person}
          onGoToPerson={goToPerson}
        />
        .
      </>



    case 'Deleted':
      return <>
        {checkHighlight('Deleted')} {peopleToText(args.people)}
      </>



    case 'Deleted Permanently':
      return <>
        {checkHighlight(`Permanently deleted ${args?.person}`)}
      </>



    case 'Reactivated':
      return <>
        {checkHighlight('Reactivated')} {peopleToLinks(args?.people, goToPerson, classes.link)}
      </>



    case 'Exported':
      return <>
        {checkHighlight(`Exported `)}
        <PersonToExportLink
          className={classes.link}
          people={args?.people}
        />
      </>



    default:
      return <>--</>
  }


  
}




export default withStyles(styles)(ActivityDescription)