import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import { Theme } from '../../../theme';
import ProgressLine from '../../ProgressLine';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    paddingLeft: 12,
  },
})


const areEqual = (prev: IProps, next: IProps): boolean => {
  return prev.progress === next.progress
}



interface IProps extends WithStyles<typeof styles> {
  progress: number;
}

const TableRowProgress = ({ classes, progress }: IProps) => (
  <div className={classes.root}>
    <ProgressLine progress={progress} small />
    <span className={classes.text}>{Math.round(progress)}%</span>
  </div>
)


export default withStyles(styles)(
  memo(TableRowProgress, areEqual)
)