
import { ClickAwayListener, Grow, MenuList, Paper, Popper, Menu as MenuMU, PopoverOrigin } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { MouseEvent, useMemo } from 'react';
import { Theme } from '../theme';
import MenuOption from './MenuOption';


interface IProps {
  anchor: any;
  options: any[];
  optionValue: string;
  onSelect: (e: any, option: any) => any;
  selected?: any;
  selectedField?: string;
  className?: any;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const Menu = (props: IProps) => {
  const { anchor } = props;




  const getProps = (option: any) : any => ({
    selected: option.selected || (props.selected && props.selectedField && option[props.selectedField] === props.selected) || undefined,
    disabled: option.disabled,
    popover: option.popover
  })




  const handleClick = (e: MouseEvent<HTMLElement>, item: any) : void => {
    e.preventDefault();
    e.stopPropagation();

    anchor.close();
    return props.onSelect(e, item)
  }


  const paperProps = useMemo(() => ({
    style: {
      boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
      borderRadius: 0,
      outline: 'none',
      minWidth: 92
    },
    className: props.className
  }), [props.className])




  const options = useMemo(() => props.options.filter((option: any) => !option.hidden), [props.options])



  
  return (
    <MenuMU
      keepMounted
      anchorEl={anchor.value}
      open={!!anchor.value}
      onClose={anchor.close}
      getContentAnchorEl={null}
      anchorOrigin={props.anchorOrigin || { vertical: "bottom", horizontal: "center" }}
      transformOrigin={props.transformOrigin || { vertical: "top", horizontal: "center" }}
      PaperProps={paperProps}
    >
      {options.map((item: any, index: number) => (
        <MenuOption
          key={item[props.optionValue] + index}
          onClick={(e: any) => handleClick(e, item)}
          {...getProps(item)}
        >
          {item[props.optionValue]}
        </MenuOption>
      ))}
      
    </MenuMU>
  )
}



export default Menu