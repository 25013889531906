import React, { useState, useEffect } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import FileListItem from './FileListItem';
import ShowAll from '../ShowAll';
import { useShowAll, useOpen } from '../../shared/hooks';
import { arrayRange } from '../../utils/utils';

const styles = (theme: Theme) => createStyles({
  '@keyframes loadingLine': {
    '0%': {
      width: 0,
      left: 0
    },
    '50%': {
      left: 0,
      width: '60%',
    },
    '75%': {
      left: '30%'
    },
    '100%': {
      left: '100%'
    }
  },
  root: {
    width: '100%'

  },
  loadingWrapper: {
    height: 7,
    marginTop: 10,
    borderRadius: 10,
    overflow: 'hidden'
  },
  loadingWrapperHidden: {
    display: 'none'
  },
  loadingLine: {
    position: 'relative',
    display: 'block',
    height: '100%',
    backgroundColor: '#34b7b2',
    borderRadius: 20,
    animation: '1s infinite $loadingLine ease-in',
  },
})

interface IProps extends WithStyles<typeof styles> {
  files: any[];
  count: number;
  onDelete: (fileId: number, fileType: number, idx?: number) => any;
  uploadPending?: boolean;
  asAttachment?: boolean;
  className?: string;
}

const FileList = (props: IProps) => {
  const { classes } = props;
  const showAll = useOpen();
  const [filesChunk, setFilesChunk] = useState<any[]>([]);




  useEffect(() => {
    if(!props.files.length) {
      setFilesChunk([]);
      return
    }

    const newChunk = showAll.value ? props.files : arrayRange(0, 3, props.files);
    setFilesChunk(newChunk)
  }, [showAll.value, props.files])



  return (
    <div className={classNames(classes.root, props.className)}>


      {filesChunk.map((file: any, idx: number) => (
        <FileListItem
          key={file.id ?? `file-idx-${idx}`}
          idx={idx}
          file={file}
          onDelete={props.onDelete}
          asAttachment={props.asAttachment}
        />
      ))}


      {props.count > 3 && (
        <ShowAll showAll={showAll} count={props.count} alignRight />
      )}


      <div className={classNames(
        classes.loadingWrapper,
        {[classes.loadingWrapperHidden]: !props.uploadPending}
      )}>
        <span className={classes.loadingLine}></span>
      </div>


    </div>
  )
}


export default withStyles(styles)(FileList)