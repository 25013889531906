import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const CalendarIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="24" height="27" viewBox="0 0 24 27">
      <g fill="none" fillRule="evenodd">
        <path d="M-4-1h32v32H-4z"/>
        <path fill="currentColor" fillRule="nonzero" d="M21.333 3H20V.333h-2.667V3H6.667V.333H4V3H2.667C1.187 3 .013 4.2.013 5.667L0 24.333A2.666 2.666 0 0 0 2.667 27h18.666C22.8 27 24 25.8 24 24.333V5.667C24 4.2 22.8 3 21.333 3zm0 21.333H2.667V9.667h18.666v14.666zm-16-12H12V19H5.333v-6.667z"/>
      </g>
    </SvgIcon>
  );
};