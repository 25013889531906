import Request from "../components/request/Request";
import { API_URL } from "../constants/api";

/**
 * People requests
 */

function getPersonFiles(id: number) {
  return Request.get(`${API_URL}/people/${id}/files/`)
  // return Request.get(`${API_URL}/people/public-files/`)
}

function uploadPersonFiles(formData: FormData, id: number) {
  return Request.post(`${API_URL}/people/${id}/files/`, formData, { 'Content-Type': 'multipart/form-data' })
  // return Request.post(`${API_URL}/people/public-files/`, formData, { 'Content-Type': 'multipart/form-data' })
}

function deletePersonFile(id: number) {
  return Request.delete(`${API_URL}/people/files/${id}/`)
}



/**
 * Project requests
 */

function getProjectFiles(id: number) {
  return Request.get(`${API_URL}/projects/${id}/files/`)
}

function uploadProjectFiles(formData: FormData, id: number) {
  return Request.post(`${API_URL}/projects/${id}/files/`, formData, { 'Content-Type': 'multipart/form-data' })
}

function deleteProjectFile(id: number) {
  return Request.delete(`${API_URL}/projects/files/${id}/`)
}



/**
 * Tasks requests
 */

function getTaskFiles(id: number) {
  return Request.get(`${API_URL}/tasks/${id}/files/`)
}

function uploadTaskFiles(formData: FormData, id: number) {
  return Request.post(`${API_URL}/tasks/${id}/files/`, formData, { 'Content-Type': 'multipart/form-data' })
}

function deleteTaskFile(id: number) {
  return Request.delete(`${API_URL}/tasks/files/${id}/`)
}




function uploadPublicFile(formData: FormData) {
  return Request.post(`${API_URL}/people/public-files/`, formData, { 'Content-Type': 'multipart/form-data' })
}



export default {
  getPersonFiles,
  uploadPersonFiles,
  deletePersonFile,
  
  getProjectFiles,
  uploadProjectFiles,
  deleteProjectFile,

  getTaskFiles,
  uploadTaskFiles,
  deleteTaskFile,

  uploadPublicFile
}