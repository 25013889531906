import React from "react";
import { Theme } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import { OutlookIcon } from "./icons/OutlookIcon";
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { NotesIcon } from "./icons/NotesIcon";
import { ProjectsIcon } from "./icons/ProjectsIcon";
import { TasksIcon } from "./icons/TasksIcon";
import classNames from 'classnames';
import { API_URL } from "../constants/api";
import { EmptyMailIcon } from "./icons/EmptyMailIcon";
import { withRouter, RouteComponentProps } from "react-router-dom";



const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 19,
  },
  icon: {
    fontSize: 64,
    color: theme.palette.primary.main
  },
  outlookIcon: {
    color: '#0070C9'
  },
  text: {
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.71,
    textAlign: 'center',
    color: '#657274',
    marginTop: 16,
    padding: '0 28px'
  },
  login: {
    fontWeight: 500,
    lineHeight: 2,
    fontSize: 12,
    textAlign: 'center',
    padding: '4px 0 13px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'transform .1s ease',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  arrowIcon: {
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
  },
  moreBottomSpace: {
    marginBottom: 13
  }
});

export enum EmptyIcon {
  Mail,
  Notes,
  Tasks,
  Projects,
  MailEmpty
}

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  icon: EmptyIcon
  text: string
  showConnect?: boolean
}

const EmptyMail = (props: IProps) => {
  const { classes, location: { pathname, search } } = props;





  const openOutlook = () => {
    const encodedPath = encodeURI(`${pathname}${search}`)
    window.open(`${API_URL}/auth/login_with_ms?state=${encodedPath}`)
  }



  const getIcon = () => {
    switch(props.icon) {
      case EmptyIcon.Mail:
        return <OutlookIcon className={classes.icon} />
      case EmptyIcon.MailEmpty:
        return <EmptyMailIcon className={classes.icon} />
      case EmptyIcon.Notes:
        return <NotesIcon className={classes.icon} />
      case EmptyIcon.Projects:
        return <ProjectsIcon className={classes.icon} withBackground />
      case EmptyIcon.Tasks:
        return <TasksIcon className={classes.icon} />
      default:
        return <OutlookIcon className={classNames(classes.icon, classes.outlookIcon)} />
    }
  }





  return (
    <div className={classes.root}>
      {getIcon()}
      
      <div className={classNames(
        classes.text,
        {[classes.moreBottomSpace]: props.icon !== EmptyIcon.Mail}
      )}>
        {props.text}
      </div>


      {props.icon === EmptyIcon.Mail && props.showConnect && (
        <div className={classes.login}>
          <span onClick={openOutlook}>Connect to Outlook</span>
          <ArrowRight className={classes.arrowIcon} />
        </div>
      )}

    </div>
  );
  
}

export default withStyles(styles)(
  withRouter(EmptyMail)
)