import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const TasksIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <circle cx="32" cy="32" r="32" fill="#F4F8F9" fillRule="nonzero"/>
        <path d="M16 16h32v32H16z"/>
        <path fill="#4192EC" fillRule="nonzero" d="M31.987 18.667c-7.36 0-13.32 5.973-13.32 13.333s5.96 13.333 13.32 13.333c7.373 0 13.346-5.973 13.346-13.333S39.36 18.667 31.987 18.667zm.013 24c-5.893 0-10.667-4.774-10.667-10.667S26.107 21.333 32 21.333 42.667 26.107 42.667 32 37.893 42.667 32 42.667z"/>
        <path fill="#4192EC" fillRule="nonzero" d="M32.667 25.333h-2v8l7 4.2 1-1.64-6-3.56z"/>
      </g>
    </SvgIcon>
  );
};