import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { Component, Fragment } from "react";
import { INote } from "../shared/interfaces";
import { Theme } from "../theme";
import { randomInt } from "../utils/math";
import AddButton from "./AddButton";
import ConfirmDialog from "./ConfirmDialog";
import DataCard from "./DataCard";
import EmptyMail, { EmptyIcon } from "./EmptyMail";
import NoteEditable from "./NoteEditable";
import SeparatedList from "./SeparatedList";

const styles = (theme: Theme) => createStyles({
  cardRoot: {

  },
  card: {
    // minHeight: 126
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
});

interface IProps extends WithStyles<typeof styles> {
  notes: INote[];
  onCreateNew: (note: INote) => void;
  onDelete: (key: any) => void;
  onEditComplete: Function;
  newUser?: boolean;
}

interface IState {
  elementsInEditMode: Set<any>;
  creatingNew: boolean;
  newNote?: INote;
  dialogOpened: boolean;
  deletableId?: number;
}

class NoteCard extends Component<IProps, IState> {

  state = {
    elementsInEditMode: new Set<any>(),
    creatingNew: false,
    newNote: undefined as INote | undefined,
    dialogOpened: false,
    deletableId: undefined
  };

  handleEnableEdit = (key: any) => {
    const elementsInEditMode = new Set<any>();
    elementsInEditMode.add(key);
    this.setState({elementsInEditMode});
  };

  handleEditComplete = (edited: INote) => {
    const { onEditComplete } = this.props;
    this.state.elementsInEditMode.delete(edited.id);
    this.setState({ elementsInEditMode: this.state.elementsInEditMode });
    onEditComplete(edited);
  };

  createNew = () => {
    const {onCreateNew} = this.props;
    if (this.state.newNote!.text.length>0)
      onCreateNew(this.state.newNote!);
    this.setState({
      creatingNew: false,
      newNote: undefined
    })
  };

  startCreatingNew = () => {
    this.setState({
      creatingNew: true,
      newNote: {
        text: "",
        person: 0,
        id: randomInt(100000000000)
      }
    })
  };

  renderHeader() {
    const {classes} = this.props;
    return (
      <div className={classes.header}>
        <span>Notes</span>
        {!this.props.newUser
          ? (
              <AddButton
                onClick={()=>this.startCreatingNew()}
              />
            )
          : null
        }
      </div>
    )
  }

  setDeletableId = (id: number) : void => {
    return this.setState({deletableId: id, dialogOpened: true})
  }

  toggleDialog = () : void => {
    return this.setState({dialogOpened: !this.state.dialogOpened})
  }

  handleDelete = () : void => {
    if(this.state.deletableId) {
      this.props.onDelete(this.state.deletableId);
      return this.setState({deletableId: undefined, dialogOpened: false})
    }
  }

  orderNotes = (a: INote, b: INote) => new Date(b.created_at || '2000-01-01T00:00:00.000000Z').getTime() - new Date(a.created_at || '2000-01-01T00:00:00.000000Z').getTime()

  render() {
    const { classes, notes } = this.props;

    if ((notes == null || notes.length===0) && !this.state.creatingNew)
      return (
        <DataCard
          header={this.renderHeader()}
          classes={{root: classes.cardRoot, card: classes.card}}
        >
          <EmptyMail
            icon={EmptyIcon.Notes}
            text="No notes yet."
          />
        </DataCard>
      );
    return (
      <Fragment>
        <DataCard
          header={this.renderHeader()}
          classes={{root: classes.cardRoot, card: classes.card}}
        >
          <SeparatedList>
            {this.state.creatingNew && (
              <NoteEditable newNote hideHeader
                key={this.state.newNote!.id}
                note={this.state.newNote!}
                onNoteChange={(newNote: any) => this.setState({newNote})}
                onEditComplete={this.createNew}
              />
            )}

            {notes.sort(this.orderNotes).map((note: any) => (
              <NoteEditable
                key={note.id}
                note={note}
                readonly={!this.state.elementsInEditMode.has(note.id)}
                onEnableEdit={() => this.handleEnableEdit(note.id)}
                onEditComplete={this.handleEditComplete}
                onDelete={() => this.setDeletableId(note.id)}
                username={note?.creator?.full_name ?? ''}
                creatorId={note?.creator?.id}
              />
            ))}
          </SeparatedList>
        </DataCard>
        <ConfirmDialog
          title="Confirm Delete"
          open={this.state.dialogOpened}
          confirmLabel="Delete"
          onCancel={this.toggleDialog}
          onConfirm={this.handleDelete}
        >
          Are you sure you want to delete this note?
        </ConfirmDialog>
      </Fragment>
    );
  }
}


export default withStyles(styles)(NoteCard)