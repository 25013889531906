import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function getSearchHistory() {
  return Request.get(`${API_URL}/search_history/`)
}

function createSearchHistoryItem(body: any) {
  return Request.post(`${API_URL}/search_history/`, body)
}

function deleteSearchHistoryItem(id: number) {
  return Request.delete(`${API_URL}/search_history/${id}/`)
}

function findSearchHistory(searchStr: string) {
  return Request.get(`${API_URL}/search_history/?search=${searchStr}`)
}

function getSearchHistoryItem(id: number) {
  return Request.get(`${API_URL}/search_history/${id}/`)
}

function shareSearch(id: number, body: any) {
  return Request.post(`${API_URL}/search_history/${id}/share/`, body)
}



export default {
  getSearchHistory,
  createSearchHistoryItem,
  deleteSearchHistoryItem,
  findSearchHistory,
  getSearchHistoryItem,
  shareSearch
}