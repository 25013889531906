import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Theme } from '../../../theme';
import Button, { ButtonSize } from '../../Button';
import { FunnelIcon } from '../../icons/FunnelIcon';
import FilterAssignees from './filters/FilterAssignees';
import FilterDueDate from './filters/FilterDueDate';
import FilterStatus from './filters/FilterStatus';
import FilterReset from "./filters/FilterReset";
import { ClickAwayListener } from '@material-ui/core';


const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative'
  },
  filterTasks: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    color: '#fff',
    marginLeft: 7
  },
  filtersContainerWrapper: {
    backgroundColor: '#fff',
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    minWidth: 248,
    position: 'absolute',
    left: -137,
    top: 45,
    boxSizing: 'border-box',
    transition: 'transform .1s ease-in',
    transform: 'scaleY(0)',
    opacity: 0,
    transformOrigin: 'top center'
  },
  filteresOpened: {
    transform: 'scaleY(1)',
    opacity: 1,
    zIndex: 1
  },
  filtersContainer: {
    padding: '5px 15px',
  },
  filterListItem: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
    padding: '2px 0',
    cursor: 'pointer',
    '&:hover': {
      '& > $filterListItemIcon': {
        color: theme.palette.primary.main,
      },
      '& > span': {
        color: theme.palette.primary.main,
      }
    },
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
      color: '#657274',
      transition: 'color .1s ease'
    }
  },
  filterListItemIcon: {
    fontSize: 13,
    color: '#657174',
    marginRight: 12,
    transition: 'color .1s ease'
  },
  buttonIconWrapper: {
    background: '#4192ec',
    height: 40,
    border: '1px solid transparent',
    '&:hover': {
      borderColor: '#4192ec',
      '& $filterTasks': {
        color: '#4192ec',
      },
      '& $buttonIcon': {
        color: '#4192ec',
      }
    }
  },
  buttonIcon: {
    fontSize: 14,
    color: '#fff'
  }
})


interface IProps extends WithStyles<typeof styles> {
  members: any[];
  className?: any;
}

const BoardTasksFilters = (props: IProps) => {
  const { classes } = props;
  const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
  
  const toggleFilters = (): void => {
    return setFiltersOpened(!filtersOpened)
  }

  const onClickAway = (e: any): void => {
    if(filtersOpened) {
      setFiltersOpened(false)
    }
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={classNames(props.className, classes.root)}>
        <Button
          primary
          size={ButtonSize.Normal}
          classes={{ root: classes.buttonIconWrapper }}
          onClick={toggleFilters}
        >
          <FunnelIcon className={classes.buttonIcon} />
          <span className={classes.filterTasks}>Filter Tasks</span>
        </Button>

        <div className={classNames(
          classes.filtersContainerWrapper,
          {[classes.filteresOpened]: filtersOpened}
        )}>

          <div className={classes.filtersContainer}>
            <FilterStatus
              className={classes.filterListItem}
              iconClassName={classes.filterListItemIcon}
            />

            <FilterAssignees 
              className={classes.filterListItem}
              iconClassName={classes.filterListItemIcon}
              members={props.members}
            />

            <FilterDueDate
              className={classes.filterListItem}
              iconClassName={classes.filterListItemIcon}
            />
          </div>

          <FilterReset className={classes.filterListItem} />
        </div>
      </div>
    </ClickAwayListener>
  )
}


export default withStyles(styles)(BoardTasksFilters)