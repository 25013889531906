import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React from 'react';
import { useSelector } from "react-redux";
import { TextInputType } from "../../../shared/types";
import { palette, Theme } from "../../../theme";
import { ClickFunction } from "../../../utils/typescriptUtils";
import AlternateColorList from "../../AlternateColorList";
import CreatedUpdatedFooter from "../../CreatedUpdatedFooter";
import DataCard from "../../DataCard";
import InfoPageHeader from "../../InfoPageHeader";
import LabelledListItem from "../../LabelledItem";
import NoteCard from "../../NoteCard";
import AdditionalCard from "../../ProjectAdditionalCard";
import ProjectSummaryCard from "../../ProjectSummaryCard";
import SectionWithHeader from "../../SectionWithHeader";
import SeparatedList from "../../SeparatedList";
import ProjecInfoDateInput from "./ProjectInfoDateInput";
import ProjectInfoInput from "./ProjectInfoInput";
import ProjectInfoSelect from "./ProjectInfoSelect";
import ProjectInfoTags from "./ProjectInfoTags";


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 0,
    backgroundColor: "#f7f9fa",
  },
  buttonWithoutPadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  cancelButton: {
    backgroundColor: palette.mist500,
    marginRight: 8
  },
  headerReadonly: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'normal'
  },
  smallCard: {
    flex: 1,
    minWidth: 296,
    maxWidth: 456,
    boxSizing: 'border-box'
  },
  largeCard: {
    flex: 2,
    minWidth: 560,
    boxSizing: 'border-box'
  },
  basicCard: {
    marginLeft: 22
  },
  noteCard: {
    marginTop: 30
  },
  cardContainer: {
    paddingBottom: 20,
    opacity: 1,
    maxHeight: 600,
    transition: theme.transitions.create(
      ['max-height', 'opacity', 'transform', 'padding']
    ),
    transform: 'scaleY(1)',
    transformOrigin: '50% 0%'
  },
  additionalCard: {
    marginLeft: 24
  },
  checkboxes: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
    padding: '10px 6px',
    '& > div': {
      marginRight: 25,
      '&:last-child': {
        margin: 0
      }
    }
  },
})


interface IProps extends WithStyles<typeof styles> {
  onGoToBoard: (e: any) => void,
  onSave: () => any,
  onCancel: () => any,
  onDelete: ClickFunction,
  onEnableEdit: ClickFunction,
  onNoteAdd: Function,
  onNoteUpdate: Function,
  onNoteDelete: Function,
  newProject?: boolean
}


const ProjectInfoPage = (props: IProps) => {
const { classes, newProject, onSave, onCancel } = props;
  const { project, updatedProject, projectView: { readonly } } = useSelector((state: any) => state.projects);
  const errors = project.errors;




  
  const getValue = (field: string): any => {
    return updatedProject?.[field] !== undefined
      ? updatedProject[field]
      : project?.[field]
  }

  
  
  const areFieldsEmpty = (fields: string[]): boolean => {
    return fields.every((field: string) => {
      if(project[field] && Array.isArray(project[field])) return !project[field].length;
      return !project[field]
    })
  }



  const getHeader = (): any => (
    <InfoPageHeader title="Project Information"
      isNew={newProject}
      readonly={readonly}
      onCancel={onCancel}
      onSave={onSave}
      onEdit={props.onEnableEdit}
      onDelete={props.onDelete}
    />
  )




  
  return (
    <div className={classes.root}>
      <div className={classes.smallCard}>
        <DataCard classes={{ root: classNames(classes.smallCard) }}
          header={getHeader()}
        >
          <SeparatedList>
            <AlternateColorList uniform={!readonly}>



              <LabelledListItem label="Name" required
                readonly={readonly}
                errors={errors && errors.name}
                hidden={!getValue('name') && readonly}
              >
                <ProjectInfoInput name="name" />
              </LabelledListItem>



              <LabelledListItem label="Type" required
                readonly={readonly}
                errors={errors && errors.project_types}
                hidden={!getValue('project_types')?.length && readonly}
              >
                <ProjectInfoSelect
                  checkboxes
                  name="project_types"
                  items="project_types"
                />
              </LabelledListItem>



              <LabelledListItem label="Client" required
                readonly={readonly}
                errors={errors && errors.client}
                hidden={!getValue('client') && readonly}
              >
                <ProjectInfoInput name="client" />
              </LabelledListItem>



              <LabelledListItem label="Status"
                readonly={readonly}
                errors={errors && errors.status}
                hidden={!getValue('status') && readonly}
              >
                <ProjectInfoSelect
                  name="status"
                  items="project_status_types"
                />
              </LabelledListItem>



              <LabelledListItem label="Asset Types"
                readonly={readonly}
                errors={errors && errors.asset_types}
                hidden={!getValue('asset_types')?.length && readonly}
              >
                <ProjectInfoSelect
                  checkboxes
                  name="asset_types"
                  items="asset_types"
                />
              </LabelledListItem>



              <LabelledListItem label="Closing Date" required
                readonly={readonly}
                errors={errors && errors.closing_date}
                hidden={!getValue('closing_date') && readonly}
              >
                <ProjecInfoDateInput name="closing_date" />
              </LabelledListItem>

            </AlternateColorList>





            <SectionWithHeader header="Capital Details"
              hidden={areFieldsEmpty(['transaction_types', 'transaction_sub_types', 'capital_required', 'recent_valuation', 'fees']) && readonly}
            >
              <AlternateColorList uniform={!readonly}>

                <LabelledListItem label="Transaction Type"
                  readonly={readonly}
                  errors={errors && errors.transaction_types}
                  hidden={!getValue('transaction_types')?.length && readonly}
                >
                  <ProjectInfoSelect
                    checkboxes
                    name="transaction_types"
                    items="transaction_types"
                  />
                </LabelledListItem>



                <LabelledListItem label="Transaction Sub Type"
                  readonly={readonly}
                  errors={errors && errors.transaction_sub_types}
                  hidden={!getValue('transaction_sub_types')?.length && readonly}
                >
                  <ProjectInfoSelect
                    checkboxes
                    name="transaction_sub_types"
                    items="transaction_sub_types"
                  />
                </LabelledListItem>



                <LabelledListItem label="Capital Required"
                  readonly={readonly}
                  errors={errors && errors.capital_required}
                  hidden={!getValue('capital_required') && readonly}
                >
                  <ProjectInfoInput
                    formattedNumber
                    name="capital_required"
                    type={TextInputType.CURRENCY}
                  />
                </LabelledListItem>



                <LabelledListItem label="Valuation"
                  readonly={readonly}
                  errors={errors && errors.recent_valuation}
                  hidden={!getValue('recent_valuation') && readonly}
                >
                  <ProjectInfoInput
                    formattedNumber
                    name="recent_valuation"
                    type={TextInputType.CURRENCY}
                  />
                </LabelledListItem>



                <LabelledListItem label="Term Sheet Fee"
                  readonly={readonly}
                  errors={errors && errors.fees}
                  hidden={!getValue('term_sheet_fee') && readonly}
                >
                  <ProjectInfoInput
                    formattedNumber
                    name="term_sheet_fee"
                    type={TextInputType.CURRENCY}
                  />
                </LabelledListItem>



                <LabelledListItem label="Success Fee %"
                  readonly={readonly}
                  errors={errors && errors.fees}
                  hidden={!getValue('fees') && readonly}
                >
                  <ProjectInfoInput
                    formattedNumber
                    name="fees"
                    type={TextInputType.PERCENTAGE}
                  />
                </LabelledListItem>



                <LabelledListItem label="Success Fee"
                  readonly={readonly}
                  hidden={(!getValue('fees') || !getValue('capital_required')) && readonly}
                >
                  <ProjectInfoInput
                    formattedNumber
                    name="success-fee"
                    type={TextInputType.CURRENCY}
                    readonly
                  />
                </LabelledListItem>



                <LabelledListItem label="Total Fees"
                  readonly={readonly}
                  hidden={(!getValue('capital_required') || !getValue('fees')) && readonly}
                >
                  <ProjectInfoInput
                    formattedNumber
                    name="total-fees"
                    type={TextInputType.CURRENCY}
                    readonly
                  />
                </LabelledListItem>

              </AlternateColorList>
            </SectionWithHeader>





            <SectionWithHeader header="Tags"
              hidden={!getValue('tags')?.length && readonly}
            >
              <ProjectInfoTags name="tags" />
            </SectionWithHeader>
          </SeparatedList>
        </DataCard>

        {!newProject &&
          <CreatedUpdatedFooter
            created_at={project.created_at}
            created_by={project.created_by}
            updated_at={project.updated_at}
            updated_by={project.updated_by}
          />
        }
      </div>


      <div className={classNames(classes.largeCard, classes.basicCard)}>
        <ProjectSummaryCard
          newProject={newProject}
          onGoToBoard={props.onGoToBoard}
        />

        <NoteCard
          newUser={newProject}
          notes={project.notes}
          onDelete={(noteKey) => props.onNoteDelete(noteKey)}
          onCreateNew={(newNote) => props.onNoteAdd(newNote)}
          onEditComplete={(edited) => props.onNoteUpdate(edited)}
          classes={{ cardRoot: classes.noteCard }}
        />
      </div>

      <AdditionalCard
        classes={{
          root: classNames(classes.smallCard, classes.additionalCard),
        }}
        capitalProviders={getValue('capital_providers')}
        newProject={newProject}
      />
    </div>
  )
}



export default withStyles(styles)(ProjectInfoPage)