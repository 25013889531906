import CloseIcon from '@material-ui/icons/Close';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import SlideInContainer from '../containers/common/SlideInContainer';
import { useTimeout } from '../shared/hooks';
import { Theme } from '../theme';
import { highlight } from '../utils/component-utils';
import TextInput from './TextInput';



const styles = (theme: Theme) => createStyles({
  root: {
    width: 400,
    top: 0,
    overflow: 'hidden'
  },
  row: {
    padding: '13px 15px',
    borderBottom: '1px solid #d9e4e8'
  },
  headerRow: {
    position: 'relative'
  },
  headerRowTitle: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    color: '#233539',
    marginBottom: 6,
    display: 'flex'
  },
  rowCloseIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 15,
    right: 15,
    cursor: 'pointer'
  },
  searchClearIcon: {
    fontSize: 18,
    position: 'absolute',
    top: '50%',
    right: 5,
    transform: 'translateY(-50%)',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  rowsWrapper: {
    ...theme.mixins.scrollbar(theme, true),
    overflow: 'scroll',
    height: 'calc(100% - 72px)',
    position: 'relative'
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '12px 35px 12px 15px',
    cursor: 'pointer',
    transition: 'all .1s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      paddingLeft: 22
    }
  },
  rowTitle: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.58,
    color: '#657274',
  },
  rowsEmpty: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.58,
    color: '#657274',
    padding: '13px 15px',
    display: 'flex',
    '& > strong': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      margin: '0 5px',
    }
  },
})


interface IProps extends WithStyles<typeof styles> {
  items: any[];
  itemField?: string;
  header?: any;
  opened?: boolean;
  className?: string;
  itemsName?: string;
  noDebounce?: boolean;
  untitledLabel?: string;
  onItemRender?: (item: any, idx: number) => any;
  onOpen?: (e: any, item: any) => any;
  onClose: (e: any) => any;
  onSearchChange?: (search: string) => Promise<any>;
}

const SlideInContainerList = (props: IProps) => {
  const { classes, items, onSearchChange } = props;
  const timeout = useTimeout(700);
  const [search, setSearch] = useState<string>('');
  const [shouldHighlight, setShouldHighlight] = useState<boolean>(false);





  const getNoSearchExcerpt = (): any => {
    if(!search) return null;

    return (
      <Fragment>
        containing <strong> {search} </strong>
      </Fragment>
    )
  }


  const highlightSearch = (str: string): any => {
    if(!search || !shouldHighlight) return str;
    return highlight(str, search)
  }




  useEffect(() => {
    if(!onSearchChange) return;

    if(props.noDebounce) {
      onSearchChange(search)
      return
    }

    setShouldHighlight(false);
    timeout.reset(() => {
      onSearchChange(search)
        .then((res: any) => setShouldHighlight(true))
        .catch((err: any) => {})
    })
  }, [search])




  return (
    <SlideInContainer
      opened={props.opened}
      className={classNames(classes.root, props.className)}
    >
      <div className={classNames(classes.row, classes.headerRow)}>
        <CloseIcon className={classes.rowCloseIcon} onClick={props.onClose} />
        

        {props.header && (
          <span className={classes.headerRowTitle}>{props.header}</span>
        )}


        <TextInput roundedGrey
          value={search}
          onChange={(value: string) => setSearch(value)}
          endAdornment={search.length > 0 && (
            <CloseIcon
              className={classes.searchClearIcon}
              onClick={(_) => setSearch('')}
            />
          )}
        />
      </div>
      

      <div className={classes.rowsWrapper}>
        {items && items.map((item: any, idx: number) => {

          if(props.onItemRender) return props.onItemRender(item, idx);

          return (
            <div
              key={item?.id ? `item-${item.id}` : `idx-${idx}`}
              className={classNames(classes.row, classes.itemRow)}
              onClick={(e: any) => props.onOpen && props.onOpen(e, item)}
            >
              <span className={classes.rowTitle}>
                {props.itemField && highlightSearch(item?.[props.itemField] || props.untitledLabel || 'Untitled')}
              </span>
            </div>
          )

        })}
      </div>
      

      {!items?.length && (
        <span className={classes.rowsEmpty}>
          No saved {props.itemsName ?? 'items'} {!search ? '' : getNoSearchExcerpt()} found
        </span>
      )}


    </SlideInContainer>
  )
}


export default withStyles(styles)(SlideInContainerList)