import FullCalendar from '@fullcalendar/react'
import { createStyles, withStyles, WithStyles } from '@material-ui/styles'
import moment from 'moment'
import React, { useEffect, useState } from "react"
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { getProjects } from "../../actions/projects/projects"
import { clearSearch } from '../../actions/search'
import { getNextTasks, getTasksByMonth, searchTasks } from "../../actions/tasks"
import Calendar from '../../components/calendar/Calendar'
import CalendarSearch from '../../components/calendar/CalendarSearch'
import Skeleton from '../../components/Skeleton'
import requestManager from '../../middleware/requestManager'
import { Theme } from '../../theme'
import { getIds, goTo } from "../../utils/utils"
import './main.scss'


const styles = (theme: Theme) => createStyles({
  root: {
    margin: '0 auto',
    padding: '21px 28px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  skeleton: {
    width: '100%',
    height: '80vh'
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {

}

const CalendarPage = (props: IProps) => {
  const { classes, history } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const tasks = useSelector((state: any) => state.board.tasks.results);
  const projects = useSelector((state: any) => state.projects.results);
  const search = useSelector((state: any) => state.search.search);
  const [selectedProjects, setSelectedProjects] = useState<any[]>(projects);
  const [projectsLoaded, setProjectsLoaded] = useState<boolean|undefined>(undefined);

  const now = new Date();
  const [month, setMonth] = useState(now.getMonth());
  const [year, setYear] = useState(now.getFullYear());

  const calendar = React.createRef<FullCalendar>();





  const handleClick = (e: any): void => {
    if ('due_date' in e.event.extendedProps) handleOpenTask(e.jsEvent, e.event.id) // Must be a task click.
    if ('closing_date' in e.event.extendedProps) handleOpenProject(e.jsEvent, e.event.id) // Must be a project click.
  }



  const handleOpenTask = (e: any, id: number): void => {
    return goTo(e,`/task/${id}`, history)
  }



  const handleOpenProject = (e: any, id: number): void => {
    history.push(`/projects/${id}`)
  }



  const onMonthPrevious = () => {
    if (calendar && calendar.current) {
      const api = calendar.current.getApi()
      api.prev()
      setMonth(api.getDate().getMonth())
      setYear(api.getDate().getFullYear())
    }
  }



  const onMonthNext = () => {
    if (calendar && calendar.current) {
      const api = calendar.current.getApi()
      api.next()
      setMonth(api.getDate().getMonth())
      setYear(api.getDate().getFullYear())
    }
  }



  const handleSelectedProjects = (newProjects: any[]): void => {
    if (!newProjects) return

    if (!newProjects.length) {
      setSelectedProjects([])
      return
    }

    setSelectedProjects(newProjects)
  }



  const getProjectIds = (): string => {
    return getIds(projects).join(',')
  }



  const getFilteredTasks = () => {
    return tasks
      .filter(task => getIds(selectedProjects).includes(task['project_details']['id']))
      .map((task: any) => ({
        ...task,
        date: task.due_date?.split?.('T')?.[0] || moment(new Date).format('YYYY-MM-DD')
      }))
  }



  const getMonthTasks = (initial: boolean = false): any => {
    if (!selectedProjects || !selectedProjects.length) return;
    dispatchGetTasksByMonth(year, month, getProjectIds(), initial)
  }



  const dispatchGetTasksByMonth = (year, month, projectIds, initial?): any => {
    return dispatch(getTasksByMonth(year, month, projectIds, initial))
  }



  const dispatchGetProjects = (): any => {
    return dispatch(getProjects())
  }



  const dispatchSearchTasks = (searchStr: string): any => {
    return dispatch(searchTasks(searchStr, true))
  }



  const dispatchGetNextTasks = (): any => {
    return dispatch(getNextTasks())
  }



  const dispatchClearSearch = (): any => {
    return dispatch(clearSearch())
  }




  

  useEffect(() => {
    ReactGA.pageview(props.location.pathname);

    dispatchGetProjects()
      .then((res: any) => setProjectsLoaded(!!res.results.length))
      .catch((err: any) => {})
    
    dispatchGetTasksByMonth(year, month, getProjectIds())
    

    return () => {
      requestManager.cancelAllRequests();
      dispatchClearSearch()
    }
  }, [])



  useEffect(() => {
    if (!selectedProjects.length) {
      const newProjects = projects.map((project: any) => ({
        ...project,
        date: project.closing_date?.split('T')?.[0],
        title: project.name,
      }))
      setSelectedProjects(newProjects)
    }
  }, [projects])



  useEffect(() => {
    if(!search) {
      getMonthTasks(true);
      return
    }

    dispatchSearchTasks(`?search=${search}&ordering=-due_date`)
  }, [search])



  useEffect(() => {
    getMonthTasks()
  }, [year, month])









  if(projectsLoaded === undefined) {
    return (
      <Skeleton className={classes.skeleton} />
    )
  }






  return (
    <div className={classes.root}>


      {!!search && (
        <CalendarSearch onNext={dispatchGetNextTasks} />
      )}


      {projects.length && !search && (
        <Calendar
          user={user}
          calendar={calendar}
          year={year}
          month={month}
          tasks={getFilteredTasks()}
          projects={projects}
          selectedProjects={selectedProjects}
          onSelectProjects={handleSelectedProjects}
          onMonthPrevious={onMonthPrevious}
          onMonthNext={onMonthNext}
          onCalEventClick={handleClick}
        />
      )}

    </div>
  );
}


export default withStyles(styles)(
  withRouter(CalendarPage)
)