import React, { ChangeEvent, memo } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Checkbox } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: 12,
    width: 20,
    height: 20,
    color: '#4192ec',
    transition: theme.transitions.create(['color']),
    '& svg': {
      fill: '#445357'
    },
    '&$checked': {
      color: '#4192ec',
      '& svg': {
        fill: '#4192ec'
      }
    },
  },
  checked: {},
  disabled: {},
})

interface IProps extends WithStyles<typeof styles> {
  checked: boolean;
  onToggle: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => any;
}

const ManageColumnsCheckbox = ({ classes, checked, onToggle }: IProps) => (
  <Checkbox
    color="default"
    checked={checked}
    onChange={onToggle}
    classes={{
      'root': classes.root,
      'checked': classes.checked,
      'disabled': classes.disabled,
    }}
  />
)


export default withStyles(styles)(
  // ManageColumnsCheckbox
  memo(ManageColumnsCheckbox)
)