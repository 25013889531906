import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import React, { Component, Fragment } from "react";
import ReactGA from 'react-ga';
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { changeProvider, clearProvider, createProvider, getProvider, updateProvider } from "../../actions/capital-provider";
import { clearEmails } from "../../actions/email";
import { getExternalContacts } from "../../actions/external-contacts";
import { clearFiles, getFiles, setFilesType } from "../../actions/files";
import { changePersonField, cancelPerson, clearPerson, clearTags, createNote, createPerson, deleteNote, deletePerson, getPersonDetails, revokePerson, updateNote, updatePerson, setReadonly } from "../../actions/people/people";
import { clearTasks } from "../../actions/tasks";
import ConfirmDialog from "../../components/ConfirmDialog";
import ContactInfo from "../../components/people/person/ContactInfoPage";
import Skeleton from "../../components/Skeleton";
import { FilesType } from "../../shared/types";
import { Theme } from "../../theme";
import { getUsername } from "../../utils/user";
import requestManager from "../../middleware/requestManager";
import { updateCurrentUser } from '../../actions/auth';

interface RouterProps {
  id?: string
}

const styles = (theme: Theme) => createStyles({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  span: {
    width: 'initial',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    textAlign: 'center',
    color: '#4192ec',
    '&:first-child': {
      color: '#657274',
      fontWeight: 'normal',
      '&:last-child': {
        color: '#657274',
        fontWeight: 'normal'
      }
    }
  },
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  skeletonColumnFirstWrapper: {
    maxWidth: 456,
    minWidth: 296,
  },
  skeletonColumnFirst: {
    flex: 1,
    '&:nth-child(1)': {
      height: 24,
      marginBottom: 8
    },
    '&:nth-child(2)': {
      height: '80vh',
    }

  },
  skeletonColumnSecondWrapper: {
    minWidth: 560,
  },
  skeletonColumnSecond: {
    marginLeft: 16,
    flex: 1,
    '&:nth-child(odd)': {
      marginBottom: 8,
      height: 24
    },
    '&:nth-child(even)': {
      height: '20vh',
      marginBottom: 30
    }
  },
  skeletonColumnThirdWrapper: {
    maxWidth: 456,
    minWidth: 296,
  },
  skeletonColumnThird: {
    flex: 1,
    marginLeft: 16,
    '&:nth-child(odd)': {
      marginBottom: 8,
      height: 24
    },
    '&:nth-child(even)': {
      height: '25vh',
      marginBottom: 30
    }
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<RouterProps> {
  createNew?: boolean,
  person: any,
  updatedPerson: any,
  readonly: boolean,
  capitalProvider: any,
  types: any,
  user: any,
  onClearPerson: Function,
  onPersonFetch: any,
  onPersonFieldChange: Function,
  onPersonCreate: Function,
  onPersonUpdate: Function,
  onPersonDelete: Function,
  onPersonRevoke: Function,
  onPersonChangeCancel: Function,
  onCancel: Function,
  onNoteCreate: Function,
  onNoteUpdate: Function,
  onNoteDelete: Function,
  onCapitalProviderFetch: Function,
  onCapitalProviderChange: Function,
  onCapitalProviderUpdate: any,
  onCapitalProviderCreate: any,
  fetchExternalContacts: any,
  onFilesGet: Function,
  clearFiles: Function,
  isLoading: boolean,
  updateMe: Function,
  setReadonly: (state: boolean) => any
}


interface IState {
  dialogOpened: boolean;
}


class PersonPage extends Component<IProps, IState> {

  state = {
    dialogOpened: false
  };



  async componentDidMount() {
    ReactGA.pageview(this.props.location.pathname)

    if(this.props.createNew) {
      this.props.setReadonly(false)
      this.props.onClearPerson();
      return;
    }

    if(!!this.getPreFilledEmail()) {
      this.props.setReadonly(false)
    }

    const personId = this.getPersonIdFromHistory();
    if(personId) {
      this.props.onPersonFetch(personId)
        .then((res: any) => {
          if(res.capital_provider && res.capital_provider.id) {
            this.props.onCapitalProviderFetch(res.capital_provider.id)
          }
          this.props.onFilesGet(personId)
        })
        .catch((err: any) => {
          if(err && err.status === 404) {
            this.props.history.push('/not-found')
          }
        });
    }
  }



  componentWillUnmount() {
    this.props.onClearPerson();
    this.props.onCancel();
    this.props.setReadonly(true)

    requestManager.cancelAllRequests()
  }



  getPersonIdFromHistory = () => {
    const { params } = this.props.match;
    return (params && params.id);
  }



  updatePerson = () => {
    this.props.onPersonUpdate(this.props.updatedPerson)
      .then((res: any) => {

        if(this.props.user.id === res.id) {
          this.props.updateMe()
        }

        this.props.fetchExternalContacts();
        this.props.setReadonly(true)
      })
      .catch(() => {})
  }



  createPerson = () => {
    this.props.onPersonCreate(this.props.updatedPerson)
      .then((id: number) => {
        this.props.fetchExternalContacts();
        this.props.history.push(`/contacts/${id}`);
      })
  }



  handleSave = () => {
    return this.props.createNew ? this.createPerson() : this.updatePerson()
  }

  

  handleDelete = async () => {
    this.props.onPersonDelete(this.props.person.id)
      .then(() => {
        this.props.history.push(`/contacts`)
      })
      .catch(() => {})
  };



  handleCancel = () => {
    if(this.props.createNew) {
      return this.props.history.push(`/contacts`)
    } 
    

    this.props.onPersonChangeCancel();
    this.props.setReadonly(true)
  };



  handleEnableEdit = () => {
    this.props.setReadonly(false);
    this.handleChange({id: this.props.person?.id});
  };



  handleNoteAdd = (note: any) => {
    const { person } = this.props;
    if(note && person?.id) {
      this.props.onNoteCreate(note, person.id);
    }
  }



  handleNoteUpdate = (note: any) => {
    this.props.onNoteUpdate(note);
  }



  handleNoteDelete = (id: number) => {
    this.props.onNoteDelete(id);
  }



  handleChange = (field: any) : void => {
    this.props.onPersonFieldChange(field);
  } 



  handleRevoke = () : any => {
    this.props.onPersonRevoke(this.props.person.id)
  }



  onCapitalProviderChange = (capitalProvider: any) => {
    this.props.onCapitalProviderChange(capitalProvider)
  }



  toggleDialog = () : void => {
    return this.setState({dialogOpened: !this.state.dialogOpened})
  }



  getPreFilledEmail = (): string|undefined => {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('email') || undefined;
  }



  renderSkeleton = (): any => {
    const { classes } = this.props;
    return (
      <div className={classes.skeletonWrapper}>
        <div className={classes.skeletonColumnFirstWrapper}>
          <Skeleton count={2} className={classes.skeletonColumnFirst} />  
        </div>
        <div className={classes.skeletonColumnSecondWrapper}>
          <Skeleton count={4} className={classes.skeletonColumnSecond} />
        </div>
        <div className={classes.skeletonColumnThirdWrapper}>
          <Skeleton count={6} className={classes.skeletonColumnThird} />
        </div>
      </div>
    )
  }





  render() {
    const { classes, person } = this.props;
    if(!this.props.createNew && !person?.id) return this.renderSkeleton();
    if(!person) return null;


    return (
      <Fragment>
        <ContactInfo
          capitalProvider={this.props.capitalProvider}
          selectTypes={this.props.types}
          user={this.props.user}
          onChange={this.handleChange}
          onSave={this.handleSave}
          onCancel={this.handleCancel}
          onEnableEdit={this.handleEnableEdit}
          onDelete={this.toggleDialog}
          onRevoke={this.handleRevoke}
          onNoteAdd={this.handleNoteAdd}
          onNoteUpdate={this.handleNoteUpdate}
          onNoteDelete={this.handleNoteDelete}
          onCapitalProviderChange={this.onCapitalProviderChange}
          newUser={this.props.createNew}
          preFilledEmail={this.getPreFilledEmail()}
        />


        <ConfirmDialog
          title="Confirm Delete"
          open={this.state.dialogOpened}
          confirmLabel="Delete"
          onCancel={this.toggleDialog}
          onConfirm={this.handleDelete}
        >
          <div className={classes.dialogContent}>
            <span className={classes.span}>Are you sure you want to delete the following user:</span>
            <span className={classes.span}>{getUsername(this.props.person)}</span>
          </div>
        </ConfirmDialog>
      </Fragment>
    )
  }
}


const mapStateToProps = state => {
  const { people, capitalProvider, types, user: { user }, loading: { isLoading } } = state;
  
  return {
    person: people.person,
    updatedPerson: people.updatedPerson,
    readonly: people.personView.readonly,
    capitalProvider,
    types,
    user,
    isLoading
  }
}

const mapDispatchToProps = dispatch => {
  return { 
    onClearPerson: () => {
      dispatch(clearPerson());
      dispatch(clearProvider());
      dispatch(clearFiles());
      dispatch(clearTasks());
      dispatch(clearTags());
      dispatch(clearEmails())
    },
    onPersonFetch:        id => dispatch(getPersonDetails(id)),
    onPersonFieldChange:  field => dispatch(changePersonField(field)),
    onPersonCreate:       person => dispatch(createPerson(person)),
    onPersonChangeCancel: () => dispatch(cancelPerson()),
    onPersonUpdate:       person => dispatch(updatePerson(person)),
    onPersonDelete:       id => dispatch(deletePerson(id)),
    onPersonRevoke:       id => dispatch(revokePerson(id)),
    
    onCancel: () => dispatch(clearPerson()),

    onNoteCreate: (note, personId) => dispatch(createNote(note, personId)),
    onNoteUpdate: note => dispatch(updateNote(note)),
    onNoteDelete: id => dispatch(deleteNote(id)),

    onCapitalProviderFetch:   id => dispatch(getProvider(id)) ,
    onCapitalProviderChange:  capitalProvider => dispatch(changeProvider(capitalProvider)),
    onCapitalProviderUpdate:  capitalProvider => dispatch(updateProvider(capitalProvider)),
    onCapitalProviderCreate:  capitalProvider => dispatch(createProvider(capitalProvider)),
    
    onFilesGet: id => {
      dispatch(setFilesType(FilesType.People))
      dispatch(getFiles(id))
    },

    clearFiles:             () => dispatch(clearFiles()),
    fetchExternalContacts:  () => dispatch(getExternalContacts([], '')),
    updateMe:               () => dispatch(updateCurrentUser()),

    setReadonly:            (state) => dispatch(setReadonly(state)),
  }
}

export default 
  connect(mapStateToProps, mapDispatchToProps)
  (
    withStyles(styles)
    (withRouter(PersonPage))
  );