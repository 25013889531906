import { ClickAwayListener } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getProjects } from '../../../actions/projects/projects';
import { useAnchor, useOpen, useWindowSize } from '../../../shared/hooks';
import { MembershipState } from '../../../shared/models/Project';
import { MembershipType } from '../../../shared/types';
import { Theme } from '../../../theme';
import { isClient } from '../../../utils/user';
import { pluralize } from '../../../utils/utils';
import { OpenFolderIcon } from '../../icons/OpenFolderIcon';
import { PersonIcon } from '../../icons/PersonIcon';
import Menu from '../../Menu';
import MembershipList from '../membership-list/MembershipList';
import BoardTasksFilters from './BoardTasksFilters';
import BoardDisplayColumns from './filters/BoardDisplayColumns';




const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  projectDropdown: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    cursor: 'pointer',
    '& h2': {
      fontFamily: 'Montserrat',
      fontSize: 22,
      fontWeight: 'normal',
      lineHeight: 1.09,
      color: '#233539',
      margin: '0 5px 0 0'
    }
  },
  button: {
    color: '#4192ec',
    boxSizing: 'unset',
    transition: theme.transitions.create('color')
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filterItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 43,
    position: 'relative',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: '0 !important'
    },
    '&:hover': {
      '& $iconAction': {
        backgroundColor: theme.palette.primary.light
      }
    }
  },
  filterItemActive: {
    '& $iconAction': {
      backgroundColor: theme.palette.primary.light
    }
  },
  filterIconWrapper: {
    transition: 'background-color .1s ease',
    backgroundColor: '#fff',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)'
  },
  filterIcon: {
    fontSize: 14,
    color: theme.palette.primary.main
  },
  filterInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 14,
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.67,
      '&:first-child': {
        color: '#233539'
      },
      '&:last-child': {
        color: '#90a0a7',
        fontWeight: 400
      }
    }
  },
  projectNameWrapper: {
    display: 'flex'
  },
  teamList: {
    width: 285,
    position: 'absolute',
    top: 'calc(100% + 10px)',
    left: 0,
    transition: 'transform .3s ease',
    transformOrigin: 'top center',
    opacity: 0,
    transform: 'scaleY(0)',
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  teamListOpened: {
    opacity: 1,
    transform: 'scaleY(1)',
    zIndex: 1
  },
  membershipList: {
    padding: '5px 10px'
  },
  teamWrapper: {
    display: 'flex'
  },
  menu: {
    minWidth: 200
  },
  headerBold: {
    color: '#233539',
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    paddingRight: 3
  },
  header: {
    color: '#233539',
    fontFamily: 'Montserrat',
    fontSize: 18,
  },
  projectName: {
    display: 'flex',
    flexDirection: 'column'
  },
  backToProject: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -5,
    cursor: 'pointer',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1.2,
      color: '#90a0a7'
    }
  },
  backIcon: {
    fontSize: 20,
    color: '#90a0a7'
  },
  filterIconOwners: {
    fontSize: 20,
    position: 'relative',
    left: 2
  },
  iconAction: {
    
  },
  filterItemButton: {
    marginRight: 20
  }
})


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  project: any
  members?: any[]
  className?: any
  onProjectChange: (projectId: number) => any
}

const BoardHeader = (props: IProps) => {
  const { classes, project, members } = props;
  const teamOpened = useOpen();
  const anchor = useAnchor();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const projects = useSelector((state: any) => state.projects.results);
  const isUserClient = isClient(user);

  const getOwners = (): string => {
    return members && members
      .filter((member: any) => member.state === MembershipState.Owner)
      .map((member: any) => `${member.person.first_name} ${member.person.last_name}`)
      .join(', ') || ""
  }

  const handleTeamClick = (e: any): void => {
    e.stopPropagation();
    teamOpened.toggle()
  }

  const selectProject = (e: any, project: any): void => {
    e.preventDefault();
    e.stopPropagation();
    props.onProjectChange(project.id);
  }

  const goBack = (): void => {
    props.history.push(`/projects/${props.project.id}`)
  }

  const onClickAway = (): void => {
    if(teamOpened.value) {
      teamOpened.close();
    }
  }

  const fetchProjects = (): void => {
    if(isUserClient) {
      return dispatchGetClientsProjects()
    }

    dispatchGetProjectsList()
  }

  const dispatchGetClientsProjects = (): void => {
    
  }

  const dispatchGetProjectsList = (): any => {
    return dispatch(getProjects())
  }

  useEffect(() => {
    fetchProjects()
  }, [])


  return (
    <div className={classNames(classes.root, props.className)}>
      <div className={classes.projectName}>
        {!isUserClient
          ? (
              <div className={classes.backToProject} onClick={goBack}>
                <ArrowLeftIcon className={classes.backIcon} />
                <span>Back to Project</span>
              </div>
            )
          : null
        }
        <div className={classes.projectDropdown}>
          <Fragment>
            <div className={classes.projectNameWrapper} onClick={anchor.set}>
              {isUserClient && !windowSize.isMobile
                ? <span className={classes.headerBold}>{"Project: "}</span>
                : null
              }
              <span className={classes.header}>{project.name}</span>
              {projects.length > 1 && <KeyboardArrowDownIcon className={classes.button} /> }
            </div>
            <Menu
              anchor={anchor}
              options={projects}
              onSelect={selectProject}
              optionValue="name"
              className={classes.menu}
            />
          </Fragment>
        </div>
      </div>
      {!isUserClient && members
        ? (
            <div className={classes.filters}>
              <ClickAwayListener onClickAway={onClickAway}>
                <div
                  className={classNames(
                    classes.filterItem,
                    { [classes.filterItemActive]: teamOpened.value }
                  )}
                >
                  <div className={classes.teamWrapper} onClick={handleTeamClick}>
                    <div className={classNames(classes.filterIconWrapper, classes.iconAction)}>
                      <PersonIcon className={classes.filterIcon} />
                    </div>
                    <div className={classes.filterInfo}>
                      <span>Team:</span>
                      <span>{`${members.length} ${pluralize(members.length, 'member')}`}</span>
                    </div>
                  </div>

                  <div className={classNames(
                    classes.teamList,
                    { [classes.teamListOpened]: teamOpened.value }
                  )}>
                    <MembershipList
                      list={members}
                      type={MembershipType.Team}
                      className={classes.membershipList}
                    />
                  </div>
                </div>
              </ClickAwayListener>

              <div className={classes.filterItem}>
                <div className={classes.filterIconWrapper}>
                  <OpenFolderIcon className={classNames(classes.filterIcon, classes.filterIconOwners)} />
                </div>
                <div className={classes.filterInfo}>
                  <span>Project Owners:</span>
                  <span>{getOwners()}</span>
                </div>
              </div>

              <BoardTasksFilters
                members={props.members || []}
                className={classNames(classes.filterItem, classes.filterItemButton)}
              />


              <BoardDisplayColumns className={classes.filterItem} />

            </div>
          )
        : null
      }
    </div>
  )
}


export default withStyles(styles)(
  withRouter(BoardHeader)
)