import { Close } from '@material-ui/icons'
import { createStyles, WithStyles, withStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { setSearch, setHighlight } from '../actions/search'
import { ROUTE_PEOPLE, ROUTE_PROJECTS, ROUTE_PROJECT_BOARD, ROUTE_PROJECT_CP, ROUTE_DASHBOARD, ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES, ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES, ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS, ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS, ROUTE_EMAIL_MANAGEMENT_DRAFTS, ROUTE_EMAIL_MANAGEMENT_TEMPLATES, ROUTE_CALENDAR, ROUTE_ACTIVITY } from '../constants/routes'
import { useTimeout, useWindowSize } from '../shared/hooks'
import { Theme } from '../theme'
import { isRoute } from '../utils/utils'
import { SearchIcon } from './icons/SearchIcon'
import TextInput from './TextInput'




const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flex: 1,
    maxWidth: 445,
    margin: '0 15px 0 0'
  },
  icon: {
    width: 20,
    height: 20,
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: 16,
    transform: 'translateY(-50%)',
    transition: 'transform .1s ease',
    // cursor: 'pointer',
    // '&:hover': {
    //   willChange: 'transform',
    //   transform: 'translateY(-50%) scale(1.2)'
    // }
  },
  iconClose: {
    fontSize: 20,
    color: '#4192ec',
    transition: 'transform .1s ease',
    cursor: 'pointer',
    '&:hover': {
      willChange: 'transform',
      transform: 'scale(1.2)'
    }
  },
  rootDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  inputWrapper: {
    width: '100%'
  }
});






interface SearchBarRoute {
  path: string;
  exact?: boolean;
  shouldHighlight?: boolean;
}


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  
}

const SearchBar = (props: IProps) => {
  const { classes } = props;
  const timeout = useTimeout(700);
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const search = useSelector((state: any) => state.search.search);
  const [value, setValue] = useState<string | undefined>();




  const searchBarRoutes = [
    { path: ROUTE_PROJECT_BOARD },
    { path: ROUTE_PEOPLE, exact: true },
    { path: ROUTE_PROJECTS, exact: true },
    { path: ROUTE_PROJECT_CP, exact: true, shouldHighlight: true },
    { path: ROUTE_DASHBOARD },
    { path: ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES, exact: true },
    { path: ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES, exact: true },
    { path: ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS, exact: true },
    { path: ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS, exact: true },
    { path: ROUTE_EMAIL_MANAGEMENT_DRAFTS, exact: true },
    { path: ROUTE_EMAIL_MANAGEMENT_TEMPLATES, exact: true },
    { path: ROUTE_CALENDAR, exact: true, shouldHighlight: true },
    { path: ROUTE_ACTIVITY, exact: true },
  ] as SearchBarRoute[];




  const onClose = (): void => {
    if(isLoading) return;

    setValue('')
  }



  const onChange = (val: string): void => {
    if(isLoading) return;

    setValue(val)
  }





  

  const hasSearchBar = (): boolean => {
    return searchBarRoutes.some((route: SearchBarRoute) => !!isRoute(route.path, route.exact)) && !windowSize.isMobile
  }




  const dispatchSetSearch = (val: string): any => {
    return dispatch(setSearch(val))
  }



  const dispatchSeHighlight = (state: boolean): any => {
    return dispatch(setHighlight(state))
  }




  useEffect(() => {
    if(value === undefined) return;

    dispatchSeHighlight(false)
    const shouldHighlight = searchBarRoutes.some((route: SearchBarRoute) => route.shouldHighlight && isRoute(route.path, route.exact))


    if(isRoute(ROUTE_PROJECT_CP, true) || !value) {


      timeout.clear()
      dispatchSetSearch(value);
      if(shouldHighlight) dispatchSeHighlight(true);


    } else {


      timeout.reset(() => {
        dispatchSetSearch(value);
        if(shouldHighlight) dispatchSeHighlight(true);
      })


    }
  }, [value])



  useEffect(() => {
      if(search === undefined) {
        setValue(undefined)
      }
  }, [search])



  

  if(!hasSearchBar()) {
    return null
  }





  return (
    <div className={classNames(
      classes.root,
      {[classes.rootDisabled]: isLoading}
    )}>
      <TextInput transparent roundedGrey
        className={classes.inputWrapper}
        value={value}
        placeholder="Page Search"
        onChange={onChange}
        startAdornment={<SearchIcon className={classes.icon} />}
        endAdornment={value && value.length > 0 &&
          <Close
            className={classes.iconClose}
            onClick={onClose}
          />
        }
      />
    </div>
  )
}



export default withStyles(styles)(withRouter(SearchBar))