import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { highlight } from '../../utils/component-utils';


interface IProps {
  document: any;
  file: any;
  onImportClick: (file: string, e: MouseEvent<HTMLSpanElement>) => any;
  className?: string;
}


const DownloadLink = (props: IProps) => {
  const { className, document, file } = props;
  const search = useSelector((state: any) => state.search);



  if(!document) {
    return file ?? null
  }


  return (
    <a className={className} href={document} target="_blank">
      {highlight(file, search.search, search.highlight)}
    </a>
  )
}


export default DownloadLink