import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { ContactListIcon } from './icons/ContactListIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 64
  },
  icon: {
    fontSize: 32,
    color: theme.palette.primary.main,
    marginBottom: 5
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#233539',
    marginBottom: 5
  },
  description: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.58,
    color: '#657274',
    maxWidth: 454,
    fontWeight: 300,
    display: 'flex',
    textAlign: 'center'
  }
})

interface IProps extends WithStyles<typeof styles> {
  hidden?: boolean;
  icon: any;
  title: string;
  description: any;
}

const EmptyNonTableList = (props: IProps) => {
  const { classes, icon: Icon } = props;

  if(props.hidden) return null;

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} />
      <span className={classes.title}>{props.title}</span>
      <span className={classes.description}>{props.description}</span>
    </div>
  )
}


export default withStyles(styles)(EmptyNonTableList)