import Request from '../../components/request/Request';
import { API_URL } from '../../constants/api';


function updateProvider(id: number, body: object) {
  return Request.patch(`${API_URL}/projects/providers/${id}/`, body)
}

function addProvider(body: object) {
  return Request.post(`${API_URL}/projects/providers/`, body)
}

function changeCompanyState(projectId: number, body: object) {
  return Request.post(`${API_URL}/projects/${projectId}/capital_provider/`, body)
}

function changeCompanyNote(projectId: number, relationId: number, body: object) {
  return Request.patch(`${API_URL}/projects/providers/${relationId}/`, body)
}




export default {
  updateProvider,
  addProvider,
  changeCompanyState,
  changeCompanyNote
}