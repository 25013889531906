import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { CapitalProviderInfoNames } from "../shared/models/Person";
import { TextInputType } from "../shared/types";
import { Theme } from "../theme";
import AlternateColorList from "./AlternateColorList";
import CheckboxWithLabel from "./CheckboxWithLabel";
import DataCard from "./DataCard";
import LabelledListItem from "./LabelledItem";
import Select from "./Select";
import SeparatedList from "./SeparatedList";
import Skeleton from "./Skeleton";
import TextInput from "./TextInput";

const styles = (theme: Theme) => createStyles({
  dataCard: {
    width: 400
  },
  checkboxes: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 0,
    padding: '10px 6px',
    '& > div': {
      marginRight: 25,
      '&:last-child': {
        margin: 0
      }
    }
  },
  doubleInput: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  stretch: {
    flex: 1
  },
  skeletonWrapper: {
    width: '100%',

  },
  skeleton: {
    '&:first-child': {
      height: 24,
      marginBottom: 8
    },
    '&:last-child': {
      height: 604
    }
  }
});

interface IProps extends WithStyles<typeof styles> {
  readonly?: boolean,
  capitalProvider: any,
  onChange: any,
  selectTypes?: any
}

const CapitalProviderInfoCard = (props: IProps) => {
  const { classes, selectTypes, capitalProvider, readonly, onChange, capitalProvider: { errors } } = props
  const isLoading = useSelector((state: any) => state.loading.isLoading)


  const getSelectType = (type: string): any[] => {
    return selectTypes?.[type] ?? []
  }

  if (isLoading || !capitalProvider) {
    return (
      <div className={classes.skeletonWrapper}>
        <Skeleton className={classes.skeleton} count={2} />
      </div>
    )
  }

  return (
    <DataCard
      classes={{
        root: classes.dataCard,
      }}
      header="Capital Provider Information"
    >
      <SeparatedList>

        <AlternateColorList uniform={!readonly}>


          <LabelledListItem
            required
            label="Capital Type"
            readonly={readonly}
            errors={errors && (errors.is_debt || errors.is_equity)}
          >
            <div className={classes.checkboxes}>
              <CheckboxWithLabel small darkFont
                label={CapitalProviderInfoNames.is_debt!}
                checked={capitalProvider.is_debt}
                onChange={e => {
                  return onChange({
                    ...capitalProvider,
                    is_debt: e.target.checked || (!e.target.checked && !capitalProvider.is_equity),
                  })
                }}
                readonly={readonly}
              />
              <CheckboxWithLabel small darkFont
                label={CapitalProviderInfoNames.is_equity!}
                checked={capitalProvider.is_equity}
                onChange={e => onChange({
                  ...capitalProvider,
                  is_equity: e.target.checked,
                  is_debt: (!e.target.checked && !capitalProvider.is_debt) || capitalProvider.is_debt
                })}
                readonly={readonly}
              />
            </div>
          </LabelledListItem>

          <LabelledListItem label="Investment Class" required readonly={readonly} errors={errors && errors.investment_classes}>
            <Select
              value={capitalProvider.investment_classes}
              readonly={readonly}
              onSelect={(item) => onChange({ ...capitalProvider, investment_classes: item })}
              valuesList={getSelectType('investment_class_types')}
              checkboxes
            />
          </LabelledListItem>


          <LabelledListItem label="Capital Provider Description" required readonly={readonly} errors={errors && errors.cp_subcategory_types}>
            <Select
              value={capitalProvider.cp_subcategory_types}
              readonly={readonly}
              onSelect={(item) => onChange({ ...capitalProvider, cp_subcategory_types: item })}
              valuesList={getSelectType('cp_subcategories')}
              checkboxes
            />
          </LabelledListItem>

          <LabelledListItem label="Investing in" readonly={readonly} errors={errors && errors.investing_geographies}>
            <Select
              value={capitalProvider.investing_geographies}
              readonly={readonly}
              onSelect={(item) => onChange({ ...capitalProvider, investing_geographies: item })}
              valuesList={getSelectType('investing_geography_types')}
              checkboxes
            />
          </LabelledListItem>

          <div className={classes.checkboxes}>
            <CheckboxWithLabel darkFont
              label={CapitalProviderInfoNames.is_sharia!}
              checked={capitalProvider.is_sharia}
              onChange={e => onChange({ ...capitalProvider, is_sharia: e.target.checked })}
              readonly={readonly}
            />
            <CheckboxWithLabel darkFont
              label={CapitalProviderInfoNames.is_development!}
              checked={capitalProvider.is_development}
              onChange={e => onChange({ ...capitalProvider, is_development: e.target.checked })}
              readonly={readonly}
            />
            <CheckboxWithLabel darkFont
              label={CapitalProviderInfoNames.is_land!}
              checked={capitalProvider.is_land}
              onChange={e => onChange({ ...capitalProvider, is_land: e.target.checked })}
              readonly={readonly}
            />
            <CheckboxWithLabel darkFont
              label={CapitalProviderInfoNames.is_prs!}
              checked={capitalProvider.is_prs}
              onChange={e => onChange({ ...capitalProvider, is_prs: e.target.checked })}
              readonly={readonly}
            />
          </div>

          <LabelledListItem label="Max LTV" readonly={readonly} errors={errors && errors.max_ltv}>
            <TextInput formattedNumber
              value={capitalProvider.max_ltv} readonly={readonly}
              onChange={(value: number) => onChange({ ...capitalProvider, max_ltv: value })}
              error={errors && errors.max_ltv}
              type={TextInputType.PERCENTAGE}
            />
          </LabelledListItem>

          <LabelledListItem label="Max LTGDV" readonly={readonly} errors={errors && errors.max_ltgdv}>
            <TextInput formattedNumber
              value={capitalProvider.max_ltgdv} readonly={readonly}
              onChange={(value: number) => onChange({ ...capitalProvider, max_ltgdv: value })}
              error={errors && errors.max_ltgdv}
              type={TextInputType.PERCENTAGE}
            />
          </LabelledListItem>

          <LabelledListItem label="Max LTC" readonly={readonly} errors={errors && errors.max_ltc}>
            <TextInput formattedNumber
              value={capitalProvider.max_ltc} readonly={readonly}
              onChange={(value: number) => onChange({ ...capitalProvider, max_ltc: value })}
              error={errors && errors.max_ltc}
              type={TextInputType.PERCENTAGE}
            />
          </LabelledListItem>

          <LabelledListItem label="Min IRR" readonly={readonly} errors={errors && errors.min_irr}>
            <TextInput formattedNumber
              value={capitalProvider.min_irr} readonly={readonly}
              onChange={(value: number) => onChange({ ...capitalProvider, min_irr: value })}
              error={errors && errors.min_irr}
              type={TextInputType.PERCENTAGE}
            />
          </LabelledListItem>

          <LabelledListItem label="Min ROE" readonly={readonly} errors={errors && errors.min_roe}>
            <TextInput formattedNumber
              endAdornment="x"
              value={capitalProvider.min_roe} readonly={readonly}
              onChange={(value: number) => onChange({ ...capitalProvider, min_roe: value })}
              error={errors && errors.min_roe}
              type={TextInputType.CUSTOM_ADORNMENT}
            />
          </LabelledListItem>

          <LabelledListItem label="Min/Max Term" readonly={readonly} errors={errors && (errors.min_term || errors.max_term)}>
            <div className={classes.doubleInput}>
              <TextInput formattedNumber
                endAdornment=" years"
                className={classes.stretch}
                value={capitalProvider.min_term} readonly={readonly}
                onChange={(value: number) => onChange({ ...capitalProvider, min_term: value })}
                error={errors && errors.min_term}
                type={TextInputType.CUSTOM_ADORNMENT}
              />
              <div style={{ width: 4 }} />
              <TextInput formattedNumber
                endAdornment=" years"
                className={classes.stretch}
                value={capitalProvider.max_term} readonly={readonly}
                onChange={(value: number) => onChange({ ...capitalProvider, max_term: value })}
                error={errors && errors.max_term}
                type={TextInputType.CUSTOM_ADORNMENT}
              />
            </div>
          </LabelledListItem>

          <LabelledListItem label="Min/Max Size" readonly={readonly} errors={errors && (errors.min_size || errors.max_size)}>
            <div className={classes.doubleInput}>
              <TextInput formattedNumber
                className={classes.stretch}
                value={capitalProvider.min_size} readonly={readonly}
                onChange={(value: number) => onChange({ ...capitalProvider, min_size: value })}
                error={errors && errors.min_size}
                type={TextInputType.CURRENCY}
              />
              <div style={{ width: 4 }} />
              <TextInput formattedNumber
                className={classes.stretch}
                value={capitalProvider.max_size} readonly={readonly}
                onChange={(value: number) => onChange({ ...capitalProvider, max_size: value })}
                error={errors && errors.max_size}
                type={TextInputType.CURRENCY}
              />
            </div>
          </LabelledListItem>

        </AlternateColorList>
      </SeparatedList>
    </DataCard>
  );
}

export default withStyles(styles)(CapitalProviderInfoCard)