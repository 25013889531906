import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { dismissContacts, getNextContacts, getExternalContacts } from '../../actions/external-contacts';
import ConfirmDialog from '../../components/ConfirmDialog';
import { ContactListIcon } from '../../components/icons/ContactListIcon';
import List from '../../components/list/List';
import { useOpen } from '../../shared/hooks';
import { ISort } from '../../shared/interfaces';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import ManageableTableCard from '../common/ManageableTableCard';
import { setLoadedPage } from '../../actions/common/loading';
import { ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { clearSelectedForList } from '../../actions/common/list';
import classNames from 'classnames';



const styles = (theme: Theme) => createStyles({
  confirmDialog: {
    minWidth: 350
  },
  confirmDialogDescription: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 300,
    lineHeight: 1.71,
    color: '#657274',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  rowCounter: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    lineHeight: 2,
    color: '#90a0a7',
    paddingRight: 18,
    width: '2%'
  },
  list: {
    boxShadow: 'none',
    margin: 0
  },
  header: {
    display: 'flex',
    fontSize: 12,
    lineHeight: 2,
    fontFamily: 'Montserrat',
    color: '#919a9c',
    padding: '0 19px',
    width: '100%',
    textTransform: 'lowercase',
    height: 0,
    opacity: 0,
    transform: 'scaleY(0)',
    transition: 'all .1s ease',
  },
  headerVisible: {
    opacity: 1,
    height: '100%',
    transform: 'scaleY(1)',
  }
})


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  className?: string;
}

const NewContactsPage = (props: IProps) => {
  const { classes } = props;
  const dismissControls = useOpen();
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search.search);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const contacts = useSelector((state: any) => state.externalContacts);
  const selected = useSelector((state: any) => state.list[TableType.NEW_CONTACTS].selected);




  const onNext = (): Promise<any> => {
    return dispatchGetNextContacts()
  }


  const onSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchGetContacts(sort, search)
      .finally(() => {
        if(!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS]) {
          dispatchOnPageLoaded(ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS)
        }
      })
  }


  const onDismiss = (): void => {
    if(!selected.length) return;
    dispatchDismissContacts(selected)
      .finally(() => {
        dismissControls.close();
        dispatchClearSelected()
      })
  }



  const dispatchDismissContacts = (ids: number[]): any => {
    return dispatch(dismissContacts(ids))
  }


  const dispatchGetNextContacts = (): any => {
    return dispatch(getNextContacts())
  }


  const dispatchGetContacts = (sort: ISort[], search: string): any => {
    return dispatch(getExternalContacts(sort, search));
  }


  const dispatchOnPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }


  const dispatchClearSelected = (): any => {
    return dispatch(clearSelectedForList(TableType.NEW_CONTACTS))
  }




  const header = useMemo(() => {
    const len = selected.length;

    return (
      <div className={classNames(
        classes.header,
        {[classes.headerVisible]: !!selected.length}
      )}>
        {`${len} ${len === 1 ? 'contact' : 'contacts'} selected`}
      </div>
    )
  }, [selected])






  useEffect(() => {
    return () => {
      requestManager.cancelAllRequests()
    }
  }, [])






  return (
    <ManageableTableCard
      isEmpty={contacts.initiated && !contacts.results.length && search === undefined}
      description="Would you like to add the following new contacts to Fintech8?"
      actions={[
        { label: 'Dismiss', onClick: dismissControls.open, disabled: !selected.length, popover: !selected.length && "Select a contact first" },
      ]}
      itemsEmpty={{
        icon: ContactListIcon,
        title: 'No new contacts',
        description: 'Email addresses that aren’t linked to contacts in FinTech8 will be displayed here for you to easily add as contacts.'
      }}
    >


  
    <List absolutePagination fullAccess selectable
      loading={contacts.loading}
      initialLoading={!pagesLoaded[ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS]}
      className={classes.list}
      rows={contacts.results}
      count={contacts.count ?? contacts.results.length}
      tableType={TableType.NEW_CONTACTS}
      onSort={onSort}
      onNext={onNext}
      header={header}
    />




      <ConfirmDialog
        title="Dismiss Email Address"
        open={dismissControls.value}
        cancelLabel="Cancel"
        confirmLabel="YES"
        onCancel={dismissControls.close}
        onConfirm={onDismiss}
      >
        Are you sure you want to permanently remove this email address?
      </ConfirmDialog>
    

  </ManageableTableCard>
  )
}


export default withStyles(styles)(
  withRouter(NewContactsPage)
)