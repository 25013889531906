import { InputAdornment, InputBase, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { KeyboardEvent, FocusEvent, ChangeEvent, useEffect } from "react";
import { palette, Theme } from "../theme";

const inputBorderSize = 1;
const inputBorderSizeSmall = 1;
const inputHeight = 48;
const inputHeightSmall = 40;
const inputPaddingLeft = 32;
const inputPaddingLeftSmall = 8;

const styles = (theme: Theme) => createStyles({
  root: (p: any) => ({
    width: '100%',
    backgroundColor: palette.skyblue050,
    borderColor: palette.skyblue050,
    border: `${inputBorderSize}px solid`,
    borderRadius: 3,
    padding: 0,
    boxSizing: 'border-box',
    paddingLeft: inputPaddingLeftSmall,
    height: inputHeightSmall,
    fontSize: 12,
    color: '#4192ec',
    borderWidth: `${inputBorderSizeSmall}px`,
    '&:not($readonly)': {
      paddingRight: inputPaddingLeftSmall,
    },
    transition: theme.transitions.create(
      ['border-color', 'box-shadow', 'background-color', 'transform']
    ),
    '&:focus-within, &:focus': {
      borderColor: theme.palette.primary.main,
      backgroundColor: palette.white,
    },
    '&$readonly': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      transform: `translateX(${-inputPaddingLeftSmall}px)`,
      '&:focus': {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
      '$error &:not(:focus)': {
        backgroundColor: 'transparent',
      },
      '$error &': {
        borderColor: 'transparent',
      },
      '&::placeholder': {
        color: '#233539',
      },
    },
    '&$error': {
      borderColor: palette.red500,
    },
    '&$error:not(:focus-within)': {
      backgroundColor: 'white',
    },
  }),
  input: (p: any) => ({
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: 12,
    color: '#4192ec',
    height: 'inherit',
    '&::placeholder': {
      color: '#4192ec',
      opacity: 1,
      fontSize: 12,
      fontWeight: 300,
      '$readonly &': {
        color: '#233539'
      }
    },
    '$readonly &': {
      color: '#233539',
    },
  }),
  adornment: {
    '& > p': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.67,
      color: theme.palette.primary.main,
    },
    '$root:focus-within & > p': {
      fontWeight: 600
    }
  },
  adornmentReadonly: {
    '& > p': {
      color: '#233539'
    }
  },
  multiline: () => ({
    color: '#657274',
    padding: '0 !important',
    height: 'initial !important',
    '$readonly &': {
      color: '#233539 !important'
    }
  }),
  inputMultiline: {
    color: '#657274',
    paddingTop: 0,
    paddingBottom: 0,
    resize: 'vertical' as const,
    minHeight: 40,
    lineHeight: 1.5,
    padding: '12px 10px !important',
    '$readonly &': {
      resize: 'none' as const,
      padding: '0 8px !important',
    }
  },
  error: () => ({}),
  small: () => ({}),
  readonly: () => ({}),
  endAdornment: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.67,
    color: '#233539'
  },
  transparent: {
    backgroundColor: 'transparent !important',
    border: '0 !important',
  },
  initialHeight: {
    height: 'initial !important',
    padding: '0 !important'
  },
  roundedGrey: (props: any) => ({
    width: '100% !important',
    padding: `2px 20px 2px ${!!props.startAdornment ? 40 : 20}px !important`,
    borderColor: 'transparent !important',
    borderRadius: '20px !important',
    backgroundColor: '#f3f6f8 !important',
    height: '32px !important',
    '& $input': {
      color: '#919a9c !important',
      '&::placeholder': {
        color: '#919a9c',
        fontWeight: 'normal'
      }
    }
  }),
  staticValue: {
    backgroundColor: '#f7f9fa !important',
    borderColor: 'transparent !important',
    transform: 'translateX(0) !important',
    '& $input': {
      color: '#233539',
      cursor: 'default',
    }
  }
});

interface IProps extends WithStyles<typeof styles> {
  value?: string | number,
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any,
  onFocus?: (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => any,
  onBlur?: (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => any,
  type?: string,
  small?: boolean,
  readonly?: boolean
  fullWidth?: boolean,
  multiline?: boolean,
  onEnter: (e: any) => any,
  onRef?: Function,
  placeholder?: string,
  inputStyle?: any,
  startAdornment?: any,
  endAdornment?: any,
  transparent?: boolean,
  initialHeight?: boolean
  roundedGrey?: boolean,
  wrapperClass?: any,
  name?: string,
  staticValue?: boolean
}

const BaseInput = (props: IProps) => {
  const { classes, staticValue } = props;


  const handleKeydown = (e: KeyboardEvent) => {
    if (props.staticValue) return;

    switch (e.key) {
      case 'Enter':
        if (e.shiftKey) return;
        props.onEnter && props.onEnter(e);
        e.preventDefault();
        break;
    }
  };


  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (!staticValue) {
      props.onChange?.(e)
    }
  }


  const onFocus = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (!staticValue) {
      props.onFocus?.(e)
    }
  }


  const onBlur = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    if (!staticValue) {
      props.onBlur?.(e)
    }
  }



  const renderStartAdornment = (): any => {
    if(!props.startAdornment) return null;

    return <>
      <InputAdornment position="start" className={classes.adornment}>
        {props.startAdornment}
      </InputAdornment>
    </>
  }



  const renderEndAdornment = (): any => {
    if(!props.endAdornment) return null;

    return <>
      <InputAdornment position="end" classes={{
        root: classNames(
          classes.adornment,
          {[classes.adornmentReadonly]: props.readonly}
        )
      }}>
        {props.endAdornment}
      </InputAdornment>
    </>
  }







  return (
    <InputBase inputRef={(ref) => props.onRef?.(ref) ?? ref}
      value={props.value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      type={props.type}
      readOnly={props.readonly}
      multiline={props.multiline}
      startAdornment={renderStartAdornment()}
      endAdornment={renderEndAdornment()}
      onKeyDown={handleKeydown}
      placeholder={props.placeholder}
      name={props.name}
      classes={{
        root: classNames(
          classes.root,
          {
            [classes.small]: props.small,
            [classes.readonly]: props.readonly,
            [classes.transparent]: props.transparent,
            [classes.initialHeight]: props.initialHeight,
            [classes.roundedGrey]: props.roundedGrey,
            [classes.staticValue]: staticValue,
            [props.wrapperClass]: !!props.wrapperClass,
          }),
        input: classNames(
          classes.input,
          { [props.inputStyle]: !!props.inputStyle && !props.multiline },
        ),
        multiline: classes.multiline,
        inputMultiline: classNames(
          classes.inputMultiline,
          { [props.inputStyle]: !!props.inputStyle && props.multiline },
        ),
        error: classes.error,
      }}
    />
  );
}

export default withStyles(styles)(BaseInput)