import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const EmailIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="17" height="12" viewBox="0 0 17 12">
      <g fill="currentColor">
        <path d="M11 6.083L17 10 17 2zM0 2L0 10 6 6.083zM15.965 0H1.035C.502 0 .08.427 0 .976L8.5 7 17 .976C16.92.426 16.498 0 15.965 0zM10.303 7.001l-1.51.968c-.09.057-.19.085-.293.085-.102 0-.204-.028-.293-.085L6.697 7 0 11.124c.082.493.502.876 1.033.876h14.934c.53 0 .95-.383 1.033-.876L10.303 7z"/>
      </g>
    </SvgIcon>
  );
};