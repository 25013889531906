
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import React, { useEffect } from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { Theme } from "../../theme"
import { ProfileType } from "../../shared/types"
import DashboardGuestPage from "./DashboardGuestPage"
import DashboardStaffPage from "./DashboardStaffPage"
import DashboardClientPage from "./DashboardClientPage"
import { clearTasks } from '../../actions/tasks';


const Dashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.user)





  const dispatchClear = (): any => {
    return dispatch(clearTasks())
  }



  useEffect(() => {
    return () => dispatchClear()
  }, [])




  switch (user.profile_type) {
    case ProfileType.Admin:
    case ProfileType.User:
      return (
        <DashboardStaffPage />
      )
    case ProfileType.Client:
      return (
        <DashboardClientPage />
      )
    case ProfileType.Guest:
      return (
        <DashboardGuestPage />
      )
    default:
      return null
  }
}

export default withRouter(Dashboard)