import * as actions from '../actions/capital-provider';
import { initialCapitalProvider } from '../shared/models/Person';

export const capitalProviderInitialState = initialCapitalProvider;

export default function(state = capitalProviderInitialState, action) {
  switch (action.type) {

    case actions.UPDATE_PROVIDER:
    case actions.CHANGE_PROVIDER:
    case actions.SET_PROVIDER:
      return action.capitalProvider
    
    case actions.CLEAR_PROVIDER:
      return capitalProviderInitialState

    case actions.SET_ERRORS:
      return {...state, errors: action.errors}
  
    default:
      return state
  }
}