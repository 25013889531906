import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { ROUTE_PEOPLE } from "../../../constants/routes";
import { useOpen } from "../../../shared/hooks";
import { AutocompleteType, MultiInputType, ProfileType, TextInputType } from "../../../shared/types";
import { palette, Theme } from "../../../theme";
import { ClickFunction } from "../../../utils/typescriptUtils";
import { getUsername } from "../../../utils/user";
import AlternateColorList from "../../AlternateColorList";
import ConfirmDialog from "../../ConfirmDialog";
import AdditionalCard from "../../ContactAdditionalCard";
import CreatedUpdatedFooter from "../../CreatedUpdatedFooter";
import DataCard from "../../DataCard";
import InfoPageHeader from "../../InfoPageHeader";
import LabelledListItem from "../../LabelledItem";
import LabelledListAddButton from "../../LabelledListAddButton";
import LabelledListItemWithAddButton from "../../LabelledListItemWithAddButton";
import MailCard from '../../MailCard';
import NoteCard from "../../NoteCard";
import SectionWithHeader from "../../SectionWithHeader";
import SeparatedList from "../../SeparatedList";
import ContactInfoAutocomplete from "./ContactInfoAutocomplete";
import ContactInfoDateInput from "./ContactInfoDateInput";
import ContactInfoInput from "./ContactInfoInput";
import ContactInfoMultiInput from "./ContactInfoMultiInput";
import ContactInfoRating from "./ContactInfoRating";
import ContactInfoSelect from "./ContactInfoSelect";
import ContactInfoTags from "./ContactInfoTags";



const styles = (theme: Theme) => createStyles({
  '@keyframes slideInAnimation': {
    from: {
      opacity: 0,
      transform: 'scaleY(0)',
      maxHeight: 0,
    },
    to: {
      opacity: 1,
      transform: 'scaleY(1)',
      maxHeight: 40,
    }
  },
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 0,
    marginBottom: 16,
    backgroundColor: "#f7f9fa",
  },
  smallCard: {
    flex: 1,
    minWidth: 296,
    maxWidth: 456,
    boxSizing: 'border-box',
  },
  largeCard: {
    flex: 2,
    minWidth: 560,
    boxSizing: 'border-box'
  },
  mailCard: {
    marginLeft: 22,
    position: 'relative'
  },
  noteCard: {
    marginTop: 30
  },
  capital_provider: {
    marginLeft: 24
  },
  buttonWithoutPadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  cancelButton: {
    backgroundColor: palette.mist500,
    marginRight: 8
  },
  section: {
    transition: theme.transitions.create(
      ['max-height', 'opacity', 'transform', 'padding']
    ),
  },
  headerReadonly: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'normal'
  },
  createdUpdated: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    color: '#90a0a7',

    '&:last-child': {
      marginBottom: 16
    }
  },
  bioWrapper: {
    marginBottom: 5
  },
  bioInput: {
    minHeight: 78,
  },
  bioAddButton: {
    marginTop: '5px !important'
  },
  addExtraBusinessInfoSpaceFix: {
    flex: 3.8,
    paddingRight: 8
  },
  addExtraBusinessInfo: {
    display: 'flex'
  },
  addButtonWrapper: {
    flex: 6.2,
  },
  slideIn: {
    animation: theme.transitions.create(['$slideInAnimation']),
  },
  emailConflict: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.71,
    color: '#657274',
    display: 'flex',
    flexDirection: 'column'
  },
  emailConflictPerson: {
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  divider: {
    margin: '15px 0',
    borderBottom: '1px solid #d9e4e8',
    width: '100%'
  },
});


enum ToggleSection {
  EXTRA_BUSINESS_INFO,
  MIDDLE_NAME,
  SUFFIX,
  DEPARTMENT,
  EXTRA_BIO_INFO
}


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  capitalProvider: any,
  selectTypes: any,
  user: any,
  onChange: (person: any, updateNotes?: boolean) => void,
  onCapitalProviderChange: any,
  onSave: Function,
  onCancel: Function,
  onDelete: ClickFunction,
  onEnableEdit: ClickFunction,
  onRevoke: ClickFunction,
  onNoteAdd: Function,
  onNoteUpdate: Function,
  onNoteDelete: Function,
  newUser?: boolean,
  preFilledEmail?: string
}

const ContactInfoPage = (props: IProps) => {
  const { classes, onChange, newUser, preFilledEmail, user, selectTypes, history } = props;
  const emailDialogControls = useOpen();
  const validationErrorsControls = useOpen();
  const { person, updatedPerson, validationErrors, personView: { readonly } } = useSelector((state: any) => state.people);
  const [toggledSections, setToggledSections] = useState<ToggleSection[]>([]);
  const errors = person.errors;




  useEffect(() => {
    if (preFilledEmail) {
      const emails = getValue('emails') ?? []
      onChange({ emails: [...emails, preFilledEmail] })
    }
  }, [])




  const renderHeader = (): any => {
    return (
      <InfoPageHeader isNew={newUser}
        readonly={readonly}
        onEdit={props.onEnableEdit}
        onDelete={user.id !== person.id ? props.onDelete : undefined}
        onRevoke={(person.invite_status && user.id !== person.id) ? props.onRevoke : undefined}
        onCancel={() => {
          setToggledSections([]);
          props.onCancel();
        }}
        onSave={() => {
          setToggledSections([]);

          if(Object.values(validationErrors).every((item: any) => !item || !Object.keys(item).length)) {
            validationErrorsControls.close();
            return props.onSave();
          }

          validationErrorsControls.open();
        }}
      />
    )
  }




  const isCapitalProvider = (): any => {
    const categories = getValue('categories');
    return categories?.map(cat => cat.is_cp)?.some(is_cp => is_cp)
  }


  const isClient = (): boolean => {
    return getValue('profile_type') === ProfileType.Client
  }


  const getSubcategories = (): any[] => {
    const catIds = getValue('categories')?.map(cat => cat.id);
    if (!catIds?.length || !selectTypes?.subcategories) return [];
    
    return selectTypes.subcategories
      .filter((subCat: any) => catIds.includes(subCat.category))
      .sort((a: any, b: any) => a.category - b.category)
  }

  const hasSubcategory = (): boolean => {
    const subCategories = getSubcategories();
    return !!subCategories.length
  }

  const toggleSection = (type: ToggleSection): void => {
    setToggledSections([...toggledSections, type]);
  }

  const isSectionToggled = (type: ToggleSection): boolean => {
    return toggledSections.includes(type)
  }


  const shouldShowField = (field: string, section: ToggleSection): boolean => {
    return readonly ? !!field : isSectionToggled(section) || !!field
  }

  const shouldHideAddButton = (field: string | string[], section: ToggleSection): boolean => {
    if (!!readonly) return true;

    return Array.isArray(field)
      ? field.every((item: string) => shouldShowField(item, section))
      : shouldShowField(field, section) || !!readonly
  }


  const getValue = (field: string): any => {
    return updatedPerson?.[field] !== undefined
      ? updatedPerson[field]
      : person?.[field]
  }



  const onGoToEmailContact = (): void => {
    emailDialogControls.close();

    if(errors?.emails?.[0]?.person?.id) {
      history.push(`${ROUTE_PEOPLE}/${errors.emails[0].person.id}`)
    }
  }

  
  const areFieldsEmpty = (fields: string[]): boolean => {
    return fields.every((field: string) => !getValue(field))
  }






  useEffect(() => {

    if(errors?.emails?.[0]?.person && !emailDialogControls.value) {
      emailDialogControls.open()
    }

  }, [errors])




  return (
    <Fragment>

      <div className={classes.root}>
        <div className={classes.smallCard}>
          <DataCard header={renderHeader()}>
            <SeparatedList>
              <AlternateColorList uniform={!readonly}>


                <LabelledListItemWithAddButton required
                  label="First Name"
                  addButtonLabel="Add Middle Name"
                  onAdd={() => toggleSection(ToggleSection.MIDDLE_NAME)}
                  addButtonHidden={shouldHideAddButton(getValue('middle_name'), ToggleSection.MIDDLE_NAME)}
                  readonly={readonly}
                  errors={errors && errors.first_name}
                  hidden={!getValue('first_name') && readonly}
                >
                  <ContactInfoInput name="first_name" />
                </LabelledListItemWithAddButton>


                {shouldShowField(getValue('middle_name'), ToggleSection.MIDDLE_NAME) && (
                  <LabelledListItem label="Middle Name"
                    className={classes.slideIn}
                    readonly={readonly}
                    errors={errors && errors.middle_name}
                    hidden={!getValue('middle_name') && readonly}
                  >
                    <ContactInfoInput name="middle_name" />
                  </LabelledListItem>
                )}




                <LabelledListItem required label="Last Name"
                  readonly={readonly}
                  errors={errors && errors.last_name}
                  hidden={!getValue('last_name') && readonly}
                >
                  <ContactInfoInput name="last_name" />
                </LabelledListItem>




                <LabelledListItem label="Title"
                  readonly={readonly}
                  errors={errors && errors.title}
                  hidden={!getValue('title') && readonly}
                >
                  <ContactInfoSelect
                    notSpecified
                    name="title"
                    items="title"
                  />
                </LabelledListItem>





                <LabelledListItem required label="User Type"
                  readonly={readonly}
                  errors={errors && errors.profile_type}
                  hidden={!getValue('profile_type') && readonly}
                >
                  <ContactInfoSelect
                    selectLastAsDefault
                    name="profile_type"
                    items="profile_types"
                  />
                </LabelledListItem>

                {!isClient() && (
                  <LabelledListItem label="Contact Category"
                    readonly={readonly}
                    errors={errors && errors.categories}
                    hidden={!getValue('categories')?.length && readonly}
                  >
                    <ContactInfoSelect
                      checkboxes
                      name="categories"
                      items="categories"
                    />
                  </LabelledListItem>
                )}

                {!isClient() && hasSubcategory() && (
                  <LabelledListItem label="Subcategory"
                    readonly={readonly}
                    errors={errors && errors.subcategories}
                    hidden={!getValue('subcategories')?.length && readonly}
                  >
                    
                    <ContactInfoSelect
                      checkboxes
                      name="subcategories"
                      items="subcategories"
                      grouped="category"
                      groupedItems="categories"
                    />
                  </LabelledListItem>
                )}

                <LabelledListItem label="Use Class"
                  readonly={readonly}
                  errors={errors && errors.use_class}
                  hidden={!getValue('use_classes')?.length && readonly}
                >
                  <ContactInfoSelect
                    checkboxes
                    name="use_classes"
                    items="use_class_types"
                  />
                </LabelledListItem>

                <LabelledListItem label="Location"
                  readonly={readonly}
                  errors={errors && errors.locations}
                  hidden={!getValue('locations')?.length && readonly}
                >
                  <ContactInfoSelect
                    checkboxes
                    name="locations"
                    items="location_types"
                  />
                </LabelledListItem>

                {isClient() &&
                  <LabelledListItem label="Client Class"
                    readonly={readonly}
                    errors={errors && errors.client_class}
                    hidden={!getValue('client_class') && readonly}
                  >
                    <ContactInfoSelect
                      checkboxes
                      name="client_class"
                      items="client_classes"
                    />
                  </LabelledListItem>
                }


                <LabelledListItem label="Job Title"
                  readonly={readonly}
                  errors={errors && errors.job_title}
                  hidden={!getValue('job_title') && readonly}
                >
                  <ContactInfoInput name="job_title" />
                </LabelledListItem>






                <LabelledListItemWithAddButton required
                  label="Company"
                  addButtonLabel="Add Department"
                  onAdd={() => toggleSection(ToggleSection.DEPARTMENT)}
                  addButtonHidden={shouldHideAddButton(getValue('department'), ToggleSection.DEPARTMENT)}
                  readonly={readonly}
                  errors={errors && errors.company}
                  hidden={!getValue('company') && readonly}
                >
                  <ContactInfoAutocomplete
                    doNotSort
                    name="company"
                    type={AutocompleteType.Companies}
                  />
                </LabelledListItemWithAddButton>


                {shouldShowField(getValue('department'), ToggleSection.DEPARTMENT) && (
                  <LabelledListItem label="Department"
                    readonly={readonly}
                    errors={errors && errors.department}
                    className={classes.slideIn}
                    hidden={!getValue('department') && readonly}
                  >
                    <ContactInfoInput name="department" />
                  </LabelledListItem>
                )}







                <LabelledListItem label="Web Page"
                  readonly={readonly}
                  errors={errors && errors.website}
                  hidden={!getValue('website') && readonly}
                >
                  <ContactInfoInput
                    name="website"
                    type={TextInputType.WEBSITE}
                  />
                </LabelledListItem>

                <LabelledListItem label="Previous Company"
                  readonly={readonly}
                  errors={errors && errors.previous_company}
                  hidden={!getValue('previous_company') && readonly}
                >
                  <ContactInfoInput name="previous_company" />
                </LabelledListItem>

                <LabelledListItem label="Rating"
                  readonly={readonly}
                  errors={errors && errors.rating}
                  hidden={!getValue('rating') && readonly}
                >
                  <ContactInfoRating name="rating" />
                </LabelledListItem>

                <LabelledListItem label="Legal Status"
                  readonly={readonly}
                  errors={errors && errors.legal_status}
                  hidden={!getValue('legal_status') && readonly}
                >
                  <ContactInfoSelect
                    name="legal_status"
                    items="legal_status"
                  />
                </LabelledListItem>

              </AlternateColorList>





              <SectionWithHeader header="Bio"
                hidden={areFieldsEmpty(['bio', 'spouse', 'birthday', 'nickname', 'suffix']) && readonly}
              >
                
                <ContactInfoInput multiline collapsible
                  name="bio"
                  className={classes.bioWrapper}
                  placeholder="Add background info"
                  inputStyle={!readonly && classes.bioInput}
                />


                <AlternateColorList uniform={!readonly}>

                  {shouldShowField(getValue('suffix'), ToggleSection.EXTRA_BIO_INFO) && (
                    <LabelledListItem label="Suffix"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.suffix}
                      hidden={!getValue('suffix') && readonly}
                    >
                      <ContactInfoInput name="suffix" />
                    </LabelledListItem>
                  )}

                  {shouldShowField(getValue('spouse'), ToggleSection.EXTRA_BIO_INFO) && (
                    <LabelledListItem label="Spouse"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.spouse}
                      hidden={!getValue('spouse') && readonly}
                    >
                      <ContactInfoInput name="spouse" />
                    </LabelledListItem>
                  )}

                  {shouldShowField(getValue('birthday'), ToggleSection.EXTRA_BIO_INFO) && (
                    <LabelledListItem label="Birthday"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.birthday}
                      hidden={!getValue('birthday') && readonly}
                    >
                      <ContactInfoDateInput name="birthday" />
                    </LabelledListItem>
                  )}

                  {shouldShowField(getValue('nickname'), ToggleSection.EXTRA_BIO_INFO) && (
                    <LabelledListItem label="Nickname"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.nickname}
                      hidden={!getValue('nickname') && readonly}
                    >
                      <ContactInfoInput name="nickname" />
                    </LabelledListItem>
                  )}
                </AlternateColorList>


                <LabelledListAddButton
                  fullWidth={areFieldsEmpty(['spouse', 'birthday', 'nickname', 'suffix'])}
                  className={classes.bioAddButton}
                  onClick={() => toggleSection(ToggleSection.EXTRA_BIO_INFO)}
                  label="Add Extra Bio Info"
                  hidden={shouldHideAddButton([getValue('spouse'), getValue('birthday'), getValue('nickname'), getValue('suffix')], ToggleSection.EXTRA_BIO_INFO)}
                />

              </SectionWithHeader>





              <SectionWithHeader header="Tags"
                hidden={!getValue('tags')?.length && readonly}
              >
                <ContactInfoTags name="tags" />
              </SectionWithHeader>





              <SectionWithHeader header="Contact Details">
                <AlternateColorList uniform={!readonly}>



                  <ContactInfoMultiInput
                    required spaceAfterIncrementalValue
                    name="emails"
                    inputType={MultiInputType.EMAILS}
                    linkType="mailto:"
                    type="email"
                  />

                  <ContactInfoMultiInput
                    alwaysVisibleCount={2}
                    name="phones"
                    inputType={MultiInputType.PHONES}
                    linkType="tel:"
                  />




                  <LabelledListItem label="Business Street"
                    readonly={readonly}
                    errors={errors && errors.street}
                    hidden={!getValue('street') && readonly}
                  >
                    <ContactInfoInput name="street" />
                  </LabelledListItem>

                  <LabelledListItem label="Business City" 
                    readonly={readonly}
                    errors={errors && errors.city}
                    hidden={!getValue('city') && readonly}
                  >
                    <ContactInfoInput name="city" />
                  </LabelledListItem>

                  <LabelledListItem label="Business Postal Code"
                    readonly={readonly}
                    errors={errors && errors.postcode}
                    hidden={!getValue('postcode') && readonly}
                  >
                    <ContactInfoInput name="postcode" />
                  </LabelledListItem>

                  <LabelledListItem label="Business Country/Region"
                    readonly={readonly}
                    errors={errors && errors.country}
                    hidden={!getValue('country') && readonly}
                  >
                    <ContactInfoAutocomplete
                      name="country"
                      type={AutocompleteType.Countries}
                    />
                  </LabelledListItem>
                </AlternateColorList>






                {((readonly && !areFieldsEmpty(['home_street', 'home_city', 'home_postcode', 'home_country'])) || !readonly ) && (
                  <div className={classes.divider} />
                )}






                <AlternateColorList uniform={!readonly}>
                  {shouldShowField(getValue('home_street'), ToggleSection.EXTRA_BUSINESS_INFO) && (
                    <LabelledListItem label="Home Street"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.home_street}
                      hidden={!getValue('home_street') && readonly}
                    >
                      <ContactInfoInput name="home_street" />
                    </LabelledListItem>
                  )}


                  {shouldShowField(getValue('home_city'), ToggleSection.EXTRA_BUSINESS_INFO) && (
                    <LabelledListItem label="Home City"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.home_city}
                      hidden={!getValue('home_city') && readonly}
                    >
                      <ContactInfoInput name="home_city" />
                    </LabelledListItem>
                  )}



                  {shouldShowField(getValue('home_postcode'), ToggleSection.EXTRA_BUSINESS_INFO) && (
                    <LabelledListItem label="Home Postal Code"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.home_postcode}
                      hidden={!getValue('home_postcode') && readonly}
                    >
                      <ContactInfoInput name="home_postcode" />
                    </LabelledListItem>
                  )}


                  {shouldShowField(getValue('home_country'), ToggleSection.EXTRA_BUSINESS_INFO) && (
                    <LabelledListItem label="Home Country/Region"
                      className={classes.slideIn}
                      readonly={readonly}
                      errors={errors && errors.home_country}
                      hidden={!getValue('home_country') && readonly}
                    >
                      <ContactInfoAutocomplete
                        name="home_country"
                        type={AutocompleteType.Countries}
                      />
                    </LabelledListItem>
                  )}



                  <LabelledListAddButton
                    className={classes.bioAddButton}
                    onClick={() => toggleSection(ToggleSection.EXTRA_BUSINESS_INFO)}
                    label="Add Home Address"
                    hidden={shouldHideAddButton([getValue('home_street'), getValue('home_city'), getValue('home_postcode'), getValue('home_country')], ToggleSection.EXTRA_BUSINESS_INFO)}
                  />


                </AlternateColorList>


              </SectionWithHeader>
            </SeparatedList>
          </DataCard>

          {!newUser &&
            <CreatedUpdatedFooter
              created_at={getValue('created_at')}
              created_by={getValue('created_by')}
              updated_at={getValue('updated_at')}
              updated_by={getValue('updated_by')}
              merged_at={getValue('merged_at')}
              merged_by={getValue('merged_by')}
              reactivated_at={getValue('reactivated_at')}
              reactivated_by={getValue('reactivated_by')}
            />
          }
        </div>


        <div className={classNames(classes.largeCard, classes.mailCard)}>
          <MailCard newUser={newUser} />
          <NoteCard
            newUser={newUser}
            notes={getValue('notes')}
            onDelete={(noteKey) => props.onNoteDelete(noteKey)}
            onCreateNew={(newNote) => props.onNoteAdd(newNote)}
            onEditComplete={(edited) => props.onNoteUpdate(edited)}
            classes={{ cardRoot: classes.noteCard }}
          />
        </div>

        <AdditionalCard
          classes={{
            root: classNames(classes.smallCard, classes.capital_provider),
          }}
          person={person}
          capitalProvider={props.capitalProvider}
          selectTypes={selectTypes}
          showCapitalProviderInfo={isCapitalProvider()}
          readonly={readonly}
          onCapitalProviderChange={props.onCapitalProviderChange}
          newUser={newUser}
        />
      </div>





      <ConfirmDialog
        title="Conflicting Email Address"
        open={emailDialogControls.value}
        cancelLabel="Close"
        confirmLabel="View Contact"
        onCancel={emailDialogControls.close}
        onConfirm={onGoToEmailContact}
      >
        <div className={classes.emailConflict}>
          <span>This work email address is already being used by:</span>
          <span className={classes.emailConflictPerson}>
            {getUsername(errors?.emails?.[0]?.person)}
          </span>
        </div>
      </ConfirmDialog>





    </Fragment>
  );
}


export default withStyles(styles)(
  withRouter(ContactInfoPage)
)