import React, { useState, useMemo, useCallback } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import Datepicker from '../../Datepicker';
import CheckIcon from '@material-ui/icons/Check';
import { EditIcon } from '../../icons/EditIcon';
import { Moment } from 'moment';
import { useTaskData, useTaskAccess } from './hooks/tasks-hooks';
import { useOpen } from '../../../shared/hooks';


interface IProps {
  isNew?: boolean
  className?: any
  headerClassName?: any
}

const TaskDatePicker = ({ isNew, className, headerClassName }: IProps) => {
  const editControls = useOpen();
  const { value, onChange } = useTaskData('due_date');
  const access = useTaskAccess();



  const getIcon = () => {
    if(isNew || !access.hasAccessToEditOwnStuff) return null;

    return editControls.value
      ? <CheckIcon onClick={editControls.close} />
      : <EditIcon onClick={editControls.open} />
  }



  const handleChange = useCallback((date: Moment): any => {
    return onChange(date.format())
  }, [])





  return useMemo(() => (
    <div className={className}>
      <div className={headerClassName}>
        <h2>Due Date</h2>
        {getIcon()}
      </div>


      <Datepicker
        value={value}
        readonly={!isNew && !editControls.value}
        onChange={handleChange}
      />
    </div>
  ), [value, isNew, handleChange, access.hasAccessToEditOwnStuff, editControls.value])
}


export default TaskDatePicker