import * as actions from '../../actions/people/membership';

export const initialState = [];

export default function(state = initialState, action) {
  let newState;
  switch (action.type) {


    case actions.PEOPLE_SET_MEMBERS:
      return action.members
    

    case actions.PEOPLE_UPDATE_MEMBER:
      newState = [...state].map((el: any) => {
        if(el.id === action.id) {
          el.state = action.state;
        }
        return el
      });
      return newState
  

    case actions.PEOPLE_ADD_MEMBER:
      return [...state, action.newMember]
    

    case actions.PEOPLE_REMOVE_MEMBER:
      return [...state].filter((item: any) => item.id !== action.memberId)
      

    case actions.PEOPLE_CLEAR_MEMBERS:
      return initialState


    case actions.PEOPLE_REMOVE_PREASSIGNED_MEMBER:
      return [...state].filter((item: any) => item.person.id !== action.personId)


    case actions.PEOPLE_PREASSIGN_MEMBER:
      if(state.some((item: any) => item.person.id === action.member.person.id)) {
        const filteredState = state.filter((item: any) => item.person.id !== action.member.person.id)
        return [...filteredState, action.member]
      }
      return [...state, action.member]
      

    case actions.PEOPLE_UPDATE_MEMBER_BY_PERSON:
      return [...state].map((item: any) => {
        if(item.person.id === action.personId) {
          return {
            ...item,
            state: action.state
          }
        } else {
          return item
        }
      })
    

    case actions.PEOPLE_ASSIGN_MANDATORY_MEMBERS:
      const newMembers = action.members.filter((member: any) => !state.some((i: any) => i.person.id === member.person.id));
      return [...state, ...newMembers]


      
    default:
      return state
  }
}