import React, { useState, useMemo, useCallback } from 'react';
import { Theme } from '../../../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Avatar from '../../../../Avatar';
import TextInput from '../../../../TextInput';
import { formatAgoTime, formatDateTime } from '../../../../../utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { EditIcon } from '../../../../icons/EditIcon';
import { updateComment, deleteComment } from '../../../../../actions/comments';
import { useOpen } from '../../../../../shared/hooks';
import TaskCommentDate from './TaskCommentDate';



const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    transition: 'all .1s ease',
    position: 'relative',
    cursor: 'unset',
    borderBottom: '1px solid #d9e4e8',
    backgroundColor: '#f7f9fa',
    padding: '10px 10px 13px',
  },
  avatar: {
    marginRight: 13
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  multiline: {
    minHeight: 58,
    paddingTop: '8px !important'
  },
  input: {
    fontWeight: 300,
    color: '#657274',
    padding: '10px 8px 20px !important'
  },
  inputReadonly: {
    minHeight: 'initial !important',
    padding: '0 !important',
    marginBottom: 5,
    border: 0,
    color: '#657274 !important',
    transform: 'unset !important'
  },
  icons: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 11,
    right: 8,
    '& > svg': {
      fontSize: 18,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:first-child': {
        marginRight: 5
      }
    }
  },
  iconsEdit: {
    bottom: 15
  },
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  readonly: boolean;
}

const TaskComment = (props: IProps) => {
  const { classes, idx } = props;
  const editControls = useOpen();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const comment = useSelector((state: any) => state.board.comments[idx]);
  const [editText, setEditText] = useState<string>(comment?.text ?? '');




  const handleChange = (value: string): void => {
    if(!editControls.value) return;
    setEditText(value)
  }



  const handleCancel = (): void => {
    editControls.close();
    setEditText(comment.text);
  }



  const handleSubmit = useCallback((): any => {
    if(!comment?.id) return;

    return dispatchEditComment(editText)
      .then((res: any) => editControls.close())
  }, [editText])


  
  const handleDelete = (): void => {
    if(!comment.id) return;

    return dispatchDeleteComment()
  }



  const dispatchEditComment = useCallback((newText: string): Promise<any> => {
    return dispatch(updateComment(comment.id, { text: newText }))
  }, [comment.id])



  const dispatchDeleteComment = useCallback((): any => {
    return dispatch(deleteComment(comment.id))
  }, [comment.id])






  const icons = useMemo(() => {

    if(comment?.creator_details?.id !== user.id || !props.readonly) {
      return null;
    }

    return editControls.value
      ? (
          <div className={classNames(classes.icons, classes.iconsEdit)}>
            <CheckIcon onClick={handleSubmit} />
            <ClearIcon onClick={handleCancel} />
          </div>
        )
      : (
          <div className={classes.icons}>
            <EditIcon onClick={editControls.open} />
            <ClearIcon onClick={handleDelete} />
          </div>
        )
  }, [editControls.value, handleSubmit, comment?.creator_details?.id, user?.id, dispatchEditComment, dispatchDeleteComment])







  return useMemo(() => (
    <div className={classes.root}>

        {!editControls.value && (
          <Avatar className={classes.avatar} person={comment.creator_details} />
        )}



        <div className={classes.content}>


          <TextInput multiline collapsible
            value={editControls.value ? editText : comment.text}
            readonly={props.readonly && !editControls.value}
            inputStyle={classNames(
              classes.multiline,
              classes.input,
              { [classes.inputReadonly]: !editControls.value }
            )}
            onChange={handleChange}
            onEnter={handleSubmit}
          />


          
          <TaskCommentDate
            createdAt={comment.created_at}
            updatedAt={comment.updated_at}
            hidden={editControls.value}
          />
         

          {icons}
        </div>
      </div>
  ), [comment, handleSubmit, editControls.value, editText, dispatchEditComment, dispatchDeleteComment])
}


export default withStyles(styles)(TaskComment)