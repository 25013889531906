import * as React from "react";
import {Theme} from "../../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import InputsAndButtonsForm from "../forms/InputsAndButtonsForm";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
  onPasswordReset: (email: string) => void
}

interface IState {
  email: string
}

class enterEmailForm extends React.Component<IProps, IState> {

  state = {
    email: ''
  };

  render() {
    const {onPasswordReset} = this.props;
    return (
      <InputsAndButtonsForm
        header="Reset your Password"
        description="Enter the email address you use to sign in to FinTech8 to reset your password."
        inputs={[
          {
            value: this.state.email,
            label: 'Email',
            onChange: (value: string) => {
              this.setState({ email: value });
            },
          },
        ]}
        buttons={[
          {
            name: 'Get reset link',
            onClick: () => onPasswordReset(this.state.email),
            primary: true,
          },
        ]}
      >
      </InputsAndButtonsForm>
    );
  }
}

export default withStyles(styles)(enterEmailForm)