import React, { ChangeEvent, memo } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { DuplicatablePersonField } from '../../shared/types';
import { RadioGroup, FormControlLabel } from '@material-ui/core';
import Radio from '../Radio';
import TextInput from '../TextInput';
import { getCategoryLabel, isCP } from '../../utils/utils';
import { Rating } from "@material-ui/lab";



const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 5

  },
  label: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.67,
    color: '#657274',
    paddingRight: 20,
    minWidth: 214,
    width: 214,
    boxSizing: 'border-box',
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
  groupLabelRoot: {
    paddingRight: 20,
    marginRight: 0,
    minWidth: 436,
    width: 436,
    boxSizing: 'border-box',
    marginLeft: 0,
    transform: 'translateX(-6px)',
    '&:last-child': {
      paddingRight: 0,
      minWidth: 386,
      maxWidth: 386
    }
  },
  groupLabel: {
    width: '100%'
  },
  input: {
    width: 334
  },
  rating: {
    fontSize: 20,
    paddingRight: 9,
    cursor: 'default'
  },
  ratingActive: {
    fontSize: 20,
    color: '#fbcc66',
    '&:hover': {
      transform: 'unset'
    }
  },
  emptyOption: {

  }
})





interface IProps extends WithStyles<typeof styles> {
  field: string;
  value: number|string;
  duplicates: any[];
  labels: Record<string, string>;
  isCP?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>, value: string) => any;
}




const DuplicateRadioGroup = (props: IProps) => {
  const { classes, field, value } = props;




  const getTextValue = (item: any): string => {
    const value = props.isCP ? item.capital_provider?.[field] : item[field];

    
    switch(typeof value) {
      case 'boolean':
        return value ? 'Yes' : 'No'

      case 'number':
        return `${value}`
    }


    
    if(Array.isArray(value)) {

      if(field === 'categories') {
        return getCategoryLabel(item)
      }

      if(value?.[0]?.name) {
        return value.map((item: any) => item.name ?? '').join((', '))
      }

      return value.join(', ');
    }


    return (value?.id && value?.name) ? value.name : value
  }




  const renderLabel = (item: any): any => {
    return field === 'rating'
      ? <>
          <Rating readOnly
            max={3}
            value={item.rating}
            classes={{
              icon: classes.rating,
              iconActive: classes.ratingActive
            }}
          />
        </>
      : <>
          <TextInput staticValue readonly
            className={classes.input}
            value={getTextValue(item)}
          />
        </>
  }






  return (
    <div className={classes.root}>
      <span className={classes.label}>
        {props.labels[field] ?? ''}:
      </span>



      <RadioGroup aria-label={field}
        name={field}
        value={value}
        onChange={props.onChange}
        className={classes.group}
      >


        {props.duplicates.map((item: any, dupIdx: number) => {

          if(props.isCP && !item.categories.some(isCP)) {
            return <div key={dupIdx} className={classes.groupLabelRoot} />
          }

          return (
            <FormControlLabel key={dupIdx}
              value={dupIdx}
              classes={{
                root: classes.groupLabelRoot,
                label: classes.groupLabel,
              }}
              control={<Radio />}
              label={renderLabel(item)}
            />
          )
        })}

      </RadioGroup>
    </div>
  )
}

export default withStyles(styles)(DuplicateRadioGroup)