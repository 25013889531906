import Rating from '@material-ui/lab/Rating';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { Theme } from '../../../../theme';
import { useSearchData } from '../hooks/advanced-search-hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    fontSize: 20,
    marginRight: 12,
    '&:last-child': {
      marginRight: 0
    }
  },
  active: {
    fontSize: 20,
    color: '#fbcc66'
  },
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
  [otherProps: string]: any;
}

const SearchSelectValueRating = (props: IProps) => {
  const { classes, idx, subRowIdx, ...otherProps } = props;
  const { currentRow, onChange } = useSearchData(idx, subRowIdx);
  const value = useMemo(() => currentRow.value || 0, [currentRow.value]);




  const handleClick = useCallback((e: MouseEvent<HTMLSpanElement>): void => {
    const val = (e.target as any).nextElementSibling?.value;
    if(value === undefined) return;
    
    return onChange({ value: val ?? '0' })
  }, [])

  


  return useMemo(() => (
    <Rating
      max={3}
      name={`rating-search-${idx}`}
      value={+value}
      onClick={handleClick}
      classes={{
        icon: classes.root,
        iconFilled: classes.active,
        iconActive: classes.active
      }}
      {...otherProps}
    />
  ), [value, handleClick])
}


export default withStyles(styles)(SearchSelectValueRating)