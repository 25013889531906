
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import MobileList from '../../../components/list/mobile/MobileList';
import { ISort } from '../../../shared/interfaces';
import { TableType } from '../../../shared/types';
import { Theme } from '../../../theme';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  
})

interface IProps extends WithStyles<typeof styles> {
  rows: any[];
  header: any;
  onOpen?: (e: any, id: number) => any;
  onSort?: (sort: ISort[], search: string) => any;
}

const MobileDashboardGuest = (props: IProps) => {
  const { classes } = props;


  return (
    <div className={classes.root}>
      <MobileList
        type={TableType.TASKS}
        rows={props.rows}
        header={props.header}
        onSort={props.onSort}
      />
    </div>
  )
}


export default withStyles(styles)(MobileDashboardGuest)