import tagsService from '../services/tags';

/**
 * Actions
 */

export const SET_TAGS = 'SET_TAGS';



/**
 * Action creators
 */

export function setAllTags(tags: any): object {
  return {type: SET_TAGS, tags}
}



/**
 * Async action creators
 */

export const getTags = () => {
  return (dispatch) => {
    return tagsService.getTags()
      .then((res: any) => dispatch(setAllTags(res)))
      .catch(err => {
        console.log('An error occured while getting the tags.')
      })
  }
}