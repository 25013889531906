import providerService from "../../services/projects/providers";
import { setError } from "../common/notifications";
import { changeSummary } from "./projects";

/**
 * Actions
 */

export const PROJECT_SET_PROVIDERS              = "PROJECT_SET_PROVIDERS";
export const PROJECT_UPDATE_PROVIDER            = "PROJECT_UPDATE_PROVIDER";
export const PROJECT_ADD_PROVIDER               = "PROJECT_ADD_PROVIDER";
export const PROJECT_CLEAR_PROVIDERS            = "PROJECT_CLEAR_PROVIDERS";
export const PROJECT_PREASSIGN_PROVIDER         = "PROJECT_PREASSIGN_PROVIDER";
export const PROJECT_UPDATE_MULTIPLE_PROVIDERS  = "PROJECT_UPDATE_MULTIPLE_PROVIDERS";
export const PROJECT_UPDATE_SINGLE_PROVIDER     = "PROJECT_UPDATE_SINGLE_PROVIDER";
export const PROJECT_SET_FILTERED_PROVIDERS     = "PROJECT_SET_FILTERED_PROVIDERS";
export const PROJECT_SET_FILTER_STATUSES        = "PROJECT_SET_FILTER_STATUSES";
export const PROJECT_SET_FILTER_COMPANIES       = "PROJECT_SET_FILTER_COMPANIES";
export const PROJECT_SET_COMPANIES              = "PROJECT_SET_COMPANIES";
export const PROJECT_SET_SUMMARY                = "PROJECT_SET_SUMMARY";



/**
 * Action creators
 */

export function setProviders(providers: any[]): object {
  return {type: PROJECT_SET_PROVIDERS, providers};
}

export function setFilteredProviders(providers: any[]): object {
  return {type: PROJECT_SET_FILTERED_PROVIDERS, providers};
}

export function changeProvider(id: number, state: number): object {
  return {type: PROJECT_UPDATE_PROVIDER, id, state};
}

export function addSingleProvider(newProvider: any): object {
  return {type: PROJECT_ADD_PROVIDER, newProvider};
}

export function clearProviders() : object {
  return {type: PROJECT_CLEAR_PROVIDERS};
}

export function preAssignProvider(provider: any) : object {
  return {type: PROJECT_PREASSIGN_PROVIDER, provider}
}

export function updateProviderRelations(relationIds: number[], state: number): object {
  return {type: PROJECT_UPDATE_MULTIPLE_PROVIDERS, relationIds, state}
}

export function updateSingleRelation(relationId: number, data: any): object {
  return {type: PROJECT_UPDATE_SINGLE_PROVIDER, relationId, data}
}

export function setFilterStatuses(statuses: any[]): object {
  return {type: PROJECT_SET_FILTER_STATUSES, statuses}
}

export function setFilterCompanies(companies: any[]): object {
  return {type: PROJECT_SET_FILTER_COMPANIES, companies}
}

export function setCompanies(companies: any[]): object {
  return {type: PROJECT_SET_COMPANIES, companies}
}

export function setSummary(summary: any[]): object {
  return {type: PROJECT_SET_SUMMARY, summary}
}




/**
 * Async action creators
 */

export const updateProvider = (relationId: number, stateId: number) => {
  return (dispatch) => {
    return providerService.updateProvider(relationId, {state: stateId})
      .then((res: any) => {
        dispatch(changeProvider(res.id, res.state));
        dispatch(changeSummary(res.cp_summary))
      })
      .catch(err => {
        dispatch(setError("Couldn't update Capital Provider", err))
      })
  }
}

export const addProvider = (data: any) => {
  return (dispatch) => {
    return providerService.addProvider(data)
      .then((res: any) => {
        dispatch(addSingleProvider(res));
        dispatch(changeSummary(res.cp_summary))
      })
      .catch(err => {
        dispatch(setError("Couldn't update Capital Provider", err))
      })
  }
}


export const changeCompanyState = (relations: number[], state: number) => {
  return (dispatch, getState) => {
    const projectId = getState().projects.project.id;
    const body = { ids: relations, state };
    return providerService.changeCompanyState(projectId, body)
      .then((res: any) => {
        dispatch(updateProviderRelations(res.ids, res.state));
        dispatch(changeSummary(res.cp_summary))
      })
      .catch(err => {
        dispatch(setError("Couldn't update Capital Providers", err))
      })
  }
}



export const changeCompanyNote = (relationId: number, body: any) => {
  return (dispatch, getState) => {
    const projectId = getState().projects.project.id;

    return providerService.changeCompanyNote(projectId, relationId, body)
      .then((res: any) => {
        dispatch(updateSingleRelation(res.id, {
          notes: res.notes,
          notes_created_at: res.notes_created_at ?? res.created_at ?? null,
          notes_updated_at: res.notes_updated_at ?? res.updated_at ?? null
        }));
        dispatch(changeSummary(res.cp_summary))
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError("Couldn't update Capital Providers", err))
        return Promise.reject(err)
      })
  }
}

