import React, { useState, useEffect } from 'react';
import { Theme } from '../../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import ButtonWithDialog from '../../../ButtonWithDialog';
import { DisplayColumnsIcon } from '../../../icons/DisplayColumnsIcon';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '../../../Checkbox';
import { useAnchor } from '../../../../shared/hooks';
import { toggleColumns } from '../../../../actions/board-columns';
import { getIds } from '../../../../utils/utils';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  popover: {
    width: 248,
    borderRadius: 0
  },
  content: {
    padding: 15,

  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    cursor: 'pointer',
    transition: 'background-color .1s ease',
    '&:last-child': {
      marginBottom: 0
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  rowTitle: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    marginLeft: 10,
    color: '#657274'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.67,
    color: '#657274',
    borderTop: '1px solid #d9e4e8'
  },
  action: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    borderRight: '1px solid #d9e4e8',
    cursor: 'pointer',
    transition: 'background-color .1s ease',
    '&:last-child': {
      borderRight: 0
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  className?: string;
}

const BoardDisplayColumns = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();
  const dispatch = useDispatch();
  const columns = useSelector((state: any) => state.board.columns)
  const [hidden, setHidden] = useState<number[]>([]);




  const formatBody = (): any => {
    const body = {};
    columns.map((item: any) => {
      body[item.id] = { hidden: hidden.includes(item.id) }
    })

    return body
  }


  const onToggle = (id: number): void => {
    const newHidden = hidden.includes(id)
      ? hidden.filter((item: number) => item !== id)
      : [...hidden, id]
    
    setHidden(newHidden)
  }


  const onApply = (): void => {
    const body = formatBody();
    dispatchToggleColumns(body)
      .then((res: any) => anchor.close())
  }


  const onDeselectAll = (): void => {
    setHidden(getIds(columns))
  }



  const dispatchToggleColumns = (body: any): any => {
    return dispatch(toggleColumns(body))
  }




  useEffect(() => {
    setHidden(getIds(columns.filter((item: any) => item.hidden)))
  }, [columns])




  return (
    <ButtonWithDialog label="Display Columns"
      anchor={anchor}
      icon={DisplayColumnsIcon}
      className={props.className}
      popoverClassName={classes.popover}
    >


      <div className={classes.content}>
        {columns.map((column: any) => (

          <div key={column.id} className={classes.row} onClick={(e: any) => onToggle(column.id)}>
            <Checkbox checked={!hidden.includes(column.id)} />
            <span className={classes.rowTitle}>{column.name}</span>
          </div>

        ))}
      </div>



      <div className={classes.actions}>
        <span className={classes.action} onClick={onApply}>
          Apply
        </span>
        <span className={classes.action} onClick={onDeselectAll}>
          Deselect All
        </span>
      </div>



    </ButtonWithDialog>
  )
}


export default withStyles(styles)(BoardDisplayColumns)