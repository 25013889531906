import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ROUTE_PERSON_NEW } from '../constants/routes';
import { Theme } from '../theme';
import ButtonWithMenu from './ButtonWithMenu';
import { useAnchor } from '../shared/hooks';
import { ButtonWithMenuProps } from '../shared/interfaces';
import Button from './Button';
import ArrowDownIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import { TableType } from '../shared/types';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  actionsButton: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    height: 'initial',
    padding: '0 7px'
  },
  arrowDownIcon: {
    fontSize: 20,
    color: '#fff',
    marginLeft: 3
  },
})


enum Actions {
  Existing = 1,
  New
}


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  contact: any;
  onAddToExisting: (email: string) => any;
  onInit: (props: ButtonWithMenuProps) => any;
  tableType: TableType;
}

const NewContactsActionsButton = (props: IProps) => {
  const { classes, contact, history } = props;
  const anchor = useAnchor();
  const selected = useSelector((state: any) => state.list[props.tableType].selected);



  const options = [
    { id: Actions.Existing, label: 'Add to existing contact' },
    { id: Actions.New,      label: 'Add as new contact' },
  ]



  const onSelect = (option: any, contact: any): void => {
    switch(option.id) {
      case Actions.Existing:
        return props.onAddToExisting(contact.email_address)
      case Actions.New:
        return history.push(`${ROUTE_PERSON_NEW}?email=${contact.email_address}`)
    }
  }



  useEffect(() => {

    props.onInit({
      anchor,
      options,
      onSelect: (e: any, option: any) => onSelect(option, contact)
    })

  }, [anchor.value])




  return (
    <Button 
      primary 
      className={classes.actionsButton} 
      onClick={anchor.set}
      disabled={selected.length > 1}
    >
      Add <ArrowDownIcon className={classes.arrowDownIcon} />
    </Button>
  )
}


export default withStyles(styles)(
  withRouter(NewContactsActionsButton)
)