import Request from '../../components/request/Request';
import { API_URL } from '../../constants/api';


function addMember(body: object) {
  return Request.post(`${API_URL}/projects/memberships/`, body)
}

function updateMember(id: number, body: object) {
  return Request.patch(`${API_URL}/projects/memberships/${id}/`, body)
}

function removeMember(id: number) {
  return Request.delete(`${API_URL}/projects/memberships/${id}/`)
}

function getMandatoryOwners() {
  return Request.get(`${API_URL}/people/?is_universal_owner=1`)
}


export default {
  addMember,
  updateMember,
  removeMember,
  getMandatoryOwners
}