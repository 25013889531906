import React, { MouseEvent } from "react";
import { AppBar, IconButton, Toolbar, Grid, WithStyles, MenuItem } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu'
import { Menu as MenuIcon, KeyboardArrowDown } from '@material-ui/icons';
import { Theme, palette } from "../theme";
import { createStyles, withStyles } from "@material-ui/styles";
import SearchBar from "./SearchBar";
import { withRouter, useHistory } from "react-router";
import { ROUTE_ROOT } from "../constants/routes";
import { goTo } from "../utils/utils";

const styles = (theme: Theme) => createStyles({
  logo: {
    height: 28
  },
  toolbarRoot: {
    height: 56,
    minHeight: 56,
    backgroundColor: 'white'
  },
  appBar: {
    boxShadow: '0 2px 4px 0 rgba(189, 194, 196, 0.16)'
  },
  link: {
    display: 'flex'
  }
})

interface IProps extends WithStyles<typeof styles> {
}

const BasicHeader = (props: IProps) => {
  const { classes } = props;
  const history = useHistory();




  const goToRoot = (e: MouseEvent<HTMLAnchorElement>): void => {
    goTo(e, ROUTE_ROOT, history)
  }





  return (
    <AppBar
      position="fixed"
      color="default"
      elevation={1}
      className={classes.appBar}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <a href={ROUTE_ROOT} className={classes.link} onClick={goToRoot}>
          <img src="/img/full_logo.svg" alt="FT8" className={classes.logo} />
        </a>
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(styles)(BasicHeader)
