import React, { useMemo } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import PrintIcon from '@material-ui/icons/Print';
import { formatDateTime, isRoute } from '../../utils/utils';
import { ROUTE_ACTIVITY, ROUTE_PEOPLE, ROUTE_PROJECT_CP, ROUTE_PROJECTS } from '../../constants/routes';
import { useHistory } from 'react-router';


const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    backgroundColor: '#f4f8f9',
    height: 56,
    padding: '14px 20px',
    boxSizing: 'border-box',
    // display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'none',
    marginBottom: 20
  },
  logoWrapper: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    fontSize: 28,
    marginRight: 13
  },
  title: {
    textTransform: 'capitalize'
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    marginRight: 6
  },
  date: {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    lineHeight: '1.67',
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  title?: string;
  className?: string;
}

const PrintHeader = (props: IProps) => {
  const { classes } = props;
  const history = useHistory();




  const routes = useMemo(() => [
    { active: isRoute(ROUTE_ACTIVITY), title: 'Contact Activity' },
    { active: isRoute(ROUTE_PEOPLE), title: 'Contacts' },
    { active: isRoute(ROUTE_PROJECT_CP), title: 'Capital Provider Tracker'},
    { active: isRoute(ROUTE_PROJECTS), title: 'Projects' },
    { active: isRoute('/search/contacts'), title: 'Contacts' },
    { active: isRoute('/search/projects'), title: 'Projects' },
    { active: isRoute('/search/tasks'), title: 'Tasks' },
  ], [history.location.pathname]);


  const title = useMemo((): string => (
    routes.find((item: any) => item.active)?.title ?? ''
  ), [routes])



  const onPrint = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    window.print()
  }





  return (
    <div className={classNames(classes.root, 'show-on-print')}>
      <div className={classes.logoWrapper}>
        <img className={classes.logo} src="/img/mark-brand.svg" alt="Logo" />
        {!!title && (
          <span className={classes.title}>{title}</span>
        )}
      </div>

      {/* <div className={classNames(classes.text, 'hide-on-print')}
        onClick={onPrint}
      >
        <PrintIcon className={classes.icon} />
        <span>Print</span>
      </div> */}

      <div className={classNames(classes.date, 'show-on-print')}>
        <span>{formatDateTime((new Date()).getTime())}</span>
      </div>
    </div>
  )
}


export default withStyles(styles)(PrintHeader)