import { Dialog } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateMultiplePeople, getPeopleByIds } from '../../../actions/people/people';
import Autocomplete from '../../../components/Autocomplete';
import Button from '../../../components/Button';
import ConfirmDialog from '../../../components/ConfirmDialog';
import DoubleTextField from '../../../components/forms/meta/fields/DoubleTextField';
import StoreCheckboxes from '../../../components/forms/meta/fields/StoreCheckboxes';
import TagsField from '../../../components/forms/meta/fields/TagsField';
import { ModalContent } from '../../../components/modal/ModalContent';
import { ModalFooter } from '../../../components/modal/ModalFooter';
import { ModalHeader } from '../../../components/modal/ModalHeader';
import BulkEditHeader from '../../../components/people/edit-multiple/BulkEditHeader';
import BulkEditRow from '../../../components/people/edit-multiple/BulkEditRow';
import BulkEditSelect from '../../../components/people/edit-multiple/BulkEditSelect';
import { countries } from '../../../constants/countries';
import { ROUTE_PEOPLE } from '../../../constants/routes';
import { multiplePeopleEditActions } from '../../../reducers/people/edit-multiple';
import { useOpen } from '../../../shared/hooks';
import { BulkEditGroupItem } from '../../../shared/interfaces';
import { AutocompleteType, DuplicatableCPField, TableType } from '../../../shared/types';
import { Theme } from '../../../theme';
import { getIds, isCP } from '../../../utils/utils';
import classNames from 'classnames';
import { clearSelectedForList } from '../../../actions/common/list';


const styles = (theme: Theme) => createStyles({
  root: {
    maxWidth: 600,
    width: '100%',
  },
  modalContent: {
    ...theme.mixins.scrollbar(theme, true),
    padding: '0 20px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 60
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: '20px 0',
    borderBottom: '1px solid #d9e4e8',
  },
  sectionHidden: {
    display: 'none'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  tags: {
    minHeight: 112,
    boxSizing: 'border-box'
  },
  cancelButton: {
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: 400,
    width: 75
  },
  applyButton: {
    fontWeight: 400,
    width: 125
  }
})

interface IProps extends WithStyles<typeof styles> {
  ids: number[];
}

const BulkEditDialog = (props: IProps) => {
  const { classes, ids } = props;
  const cancelControls = useOpen();
  const cpControls = useOpen();
  const history = useHistory();
  const dispatch = useDispatch();
  const types = useSelector((state: any) => state.types);
  const companies = useSelector((state: any) => state.organizations);
  const { data, error }  = useSelector((state: any) => state.multiplePeople);
  const [contacts, setContacts] = useState<any[]>([]);
  const { backURL } = (history.location.state as any) ?? {};








  const getSubcategories = (): any[] => {
    if(!types?.subcategories?.length || !data.categories?.value?.length) return [];

    const catIds = getIds(data.categories.value);
    if (!catIds.length) return [];
    
    return types.subcategories
      .filter((subCat: any) => catIds.includes(subCat.category))
      .sort((a: any, b: any) => a.category - b.category)
  }



  const getError = (field: any): any => {
    if(!error) return;

    if(!field.names?.length) {
      return error?.[field.name]
    }

    return Object.fromEntries(Object.entries(error).filter(([field, value]: any) => field.names?.some((item: BulkEditGroupItem) => field === item.name)))
  }



  const formatObject = (obj: any): any => {
    const newData = {};
    const cpData = {};
    const cpKeys = Object.keys(DuplicatableCPField);
    
     
    Object.entries(obj)
      .map(([field, values]: any) => {
        if(!values?.value) return;
        

        const relatedObj = cpKeys.includes(field) ? cpData : newData;

        
        if(typeof values.value === 'string' && !isNaN(+values.value)) {
          relatedObj[field] = { ...values, value: +values.value }
          return;
        }


        if(Array.isArray(values.value) && !!values.value?.[0]?.id) {
          relatedObj[field] = { ...values, value: getIds(values.value) }
          return;
        }

        relatedObj[field] = { ...values }
      })



    return {
      ...newData,
      cp_data: !!Object.keys(cpData).length ? cpData : undefined
    }
  }




  const onGoBack = (): void => {
    history.push(backURL ?? ROUTE_PEOPLE)
  }



  const onCancel = (): void => {
    onGoBack()
  }



  const onApply = (): void => {
    const body = formatObject(data)

    dispatchEdit(body)
      .then(() => onGoBack())
  }



  const dispatchEdit = (newData: any): any => {
    return dispatch(updateMultiplePeople(ids, newData))
  }



  const dispatchClear = (): any => {
    dispatch(clearSelectedForList(TableType.PEOPLE));
    dispatch(multiplePeopleEditActions.actionResetForm());
  }



  const dispatchGetPeopleByIds = (): any => {
    return dispatch(getPeopleByIds(ids))
  }




  useEffect(() => {
    dispatchGetPeopleByIds()
      .then((res: any) => setContacts(res))
      .catch((err: any) => {
        console.log(err)
      })

    return () => dispatchClear()
  }, [])



  useEffect(() => {
    let shouldOpen = false;
    const allCp = contacts.filter((person: any) => person.categories.some(isCP));



    if(allCp.length === contacts.length && !data.categories?.value?.length) {
      shouldOpen = true;
    }

    
    if(allCp.length === contacts.length && !!data.categories?.is_append) {
      shouldOpen = true;
    }
    

    if(allCp.length === contacts.length && !!data.categories?.value?.length && !data.categories?.is_append && !data.categories?.value?.some(isCP)) {
      shouldOpen = false;
    }


    if(!!data.categories?.value?.some(isCP)) {
      shouldOpen = true;
    }

    
     cpControls.toggle(shouldOpen)

  }, [data.categories, contacts])









  const transactionNames = [
    {name: 'is_debt',   label: 'Debt'},
    {name: 'is_equity', label: 'Equity'}
  ] as BulkEditGroupItem[];



  const cpCheckboxNames = [
      {name: 'is_sharia',      label: 'Sharia'},
      {name: 'is_development', label: 'Dev'},
      {name: 'is_land',        label: 'Land'},
      {name: 'is_prs',         label: 'PRS'},
  ] as BulkEditGroupItem[];
  


  const cpTermsNames = [
      {name: 'min_term', placeholder: 'Min term...'},
      {name: 'max_term', placeholder: 'Max term...'}
  ] as BulkEditGroupItem[];
  


  const cpSizeNames = [
      {name: 'min_size', placeholder: 'Min size...'},
      {name: 'max_size', placeholder: 'Max size...'}
  ] as BulkEditGroupItem[];


  const sections = [
    {
      title: 'Contact Information',
      fields: [
        { label: 'Company', name: 'company', component: Autocomplete, componentProps: { placeholder: 'Company...', items: companies, type: AutocompleteType.Companies }, emptySwitch: true },
        { label: 'Contact Category', name: 'categories', component: BulkEditSelect, componentProps: { placeholder: 'Select Categories', valuesList: types.categories, checkboxes: true, textOverflow: true } },
        { label: 'Subcategories', name: 'subcategories', component: BulkEditSelect, componentProps: { placeholder: 'Select Subcategories', valuesList: getSubcategories(), groupedValuesList: types.categories, grouped: 'category', checkboxes: true, textOverflow: true }, hidden: !getSubcategories().length },
        { label: 'Use Class', name: 'use_classes', component: BulkEditSelect, componentProps: { placeholder: 'Select Use Classes', valuesList: types.use_class_types, checkboxes: true, textOverflow: true } },
        { label: 'Locations', name: 'locations', component: BulkEditSelect, componentProps: { placeholder: 'Select Locations', valuesList: types.location_types,  checkboxes: true, textOverflow: true } }
      ]
    },
    {
      title: 'Contact Details',
      fields: [
        { label: 'Business Street', name: 'street', componentProps: { placeholder: 'Business Street...' }, emptySwitch: true },
        { label: 'Business City', name: 'city', componentProps: { placeholder: 'Business City...' }, emptySwitch: true },
        { label: 'Business Postal Code', name: 'postcode', componentProps: { placeholder: 'Business Postal Code...' }, emptySwitch: true },
        { label: 'Business Country/Region', name: 'country', component: Autocomplete, componentProps: { placeholder: 'Business Country/Region...', items: countries, type: AutocompleteType.Countries }, emptySwitch: true },
      ]
    },
    {
      title: 'Tags',
      headerFieldName: 'tags',
      fields: [
        { name: 'tags', component: TagsField, componentProps: { placeholder: 'Type tags', className: classes.tags }, noSwitch: true } 
      ]
    },
    {
      title: 'CAPITAL PROVIDER INFORMATION',
      hidden: !cpControls.value,
      fields: [
        { label: 'Capital Type', names: transactionNames, component: StoreCheckboxes, componentProps: { selectionMandatory: true } },
        { label: 'Investment Class', name: 'investment_classes', component: BulkEditSelect, componentProps: { placeholder: 'Select Investment Class', valuesList: types.investment_class_types, checkboxes: true, textOverflow: true } },
        { label: 'Capital Provider Description', name: 'cp_subcategory_types', component: BulkEditSelect, componentProps: { placeholder: 'Select Capital Provider Description', valuesList: types.cp_subcategories, checkboxes: true, textOverflow: true } },
        { label: 'Investing In', name: 'investing_geographies', component: BulkEditSelect, componentProps: { placeholder: 'Select Geographies', valuesList: types.investing_geography_types, checkboxes: true, textOverflow: true } },
        { label: '', names: cpCheckboxNames, component: StoreCheckboxes },
        { label: 'Max LTV', name: 'max_ltv', componentProps: { placeholder: 'Max LTV...', formattedNumber: true }, emptySwitch: true },
        { label: 'Max LTGDV', name: 'max_ltgdv', componentProps: { placeholder: 'Max LTGDV...', formattedNumber: true }, emptySwitch: true },
        { label: 'Max LTC', name: 'max_ltc', componentProps: { placeholder: 'Max LTC...', formattedNumber: true }, emptySwitch: true },
        { label: 'Min IRR', name: 'min_irr', componentProps: { placeholder: 'Max IRR...', formattedNumber: true }, emptySwitch: true },
        { label: 'Min ROE', name: 'min_roe', componentProps: { placeholder: 'Max ROE...', formattedNumber: true }, emptySwitch: true },
        { label: 'Min/Max Term', names: cpTermsNames, component: DoubleTextField, componentProps: { formattedNumber: true }, emptySwitch: true },
        { label: 'Min/Max Size', names: cpSizeNames, component: DoubleTextField, componentProps: { formattedNumber: true }, emptySwitch: true },
      ]
    }
  ]



  return (
    <Dialog open onClose={cancelControls.open} maxWidth="xl" PaperProps={{ classes: { root: classes.root } }} >
      
      <ModalHeader>Edit selected contacts</ModalHeader>

      <ModalContent className={classes.modalContent}>



        {sections.map((section: any, sectionIdx: number) => (
          <div
            key={sectionIdx}
            className={classNames(
              classes.section,
              {[classes.sectionHidden]: section.hidden}
            )}
          >


            <BulkEditHeader
              title={section.title}
              idx={sectionIdx}
              name={section.headerFieldName}
            />



            
            {section.fields.map((field: any, fieldIdx: number) => (
              <BulkEditRow
                key={fieldIdx}
                field={field}
                error={getError(field)}
                {...field}
              />
            ))}



          </div>
        ))}

      </ModalContent>



      <ModalFooter>
        <Button className={classes.cancelButton} onClick={cancelControls.open}>Close</Button>
        <Button primary className={classes.applyButton} onClick={onApply}>Apply Changes</Button>
      </ModalFooter>



      <ConfirmDialog
        title="Are you sure?"
        open={cancelControls.value}
        cancelLabel="Cancel"
        confirmLabel="YES"
        onCancel={cancelControls.close}
        onConfirm={onCancel}
      >
        Are you sure you want to discard your changes?
      </ConfirmDialog>

    </Dialog>
  )
}


export default withStyles(styles)(BulkEditDialog)