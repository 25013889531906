import searchHistoryService from '../services/search-history';
import { setSuccess, setError } from './common/notifications';

/**
 * Actions
 */

export const SEARCH_SET_HISTORY         = 'SEARCH_SET_HISTORY';
export const SEARCH_SET_QUERY           = 'SEARCH_SET_QUERY';
export const SEARCH_REMOVE_QUERY        = 'SEARCH_REMOVE_QUERY';
export const SEARCH_SET_HISTORY_ITEM    = 'SEARCH_SET_HISTORY_ITEM';
export const SEARCH_UPDATE_HISTORY_ITEM = 'SEARCH_UPDATE_HISTORY_ITEM';
export const CLEAR_HISTORY_ITEM         = 'CLEAR_HISTORY_ITEM';



/**
 * Action creators
 */

export function setSearchHistory(history: any): object {
  return {type: SEARCH_SET_HISTORY, history}
}

export function setSearchQuery(item: any): object {
  return {type: SEARCH_SET_QUERY, item}
}

export function removeSearchQuery(id: number): object {
  return {type: SEARCH_REMOVE_QUERY, id}
}

export function setSearchHistoryItem(item: any): object {
  return {type: SEARCH_SET_HISTORY_ITEM, item}
}

export function clearSearchHistoryItem(): object {
  return {type: CLEAR_HISTORY_ITEM}
}

export function updateSearchHistoryItem(item: any): object {
  return {type: SEARCH_UPDATE_HISTORY_ITEM, item}
}



/**
 * Async action creators
 */

export const getSearchHistory = (): any => {
  return (dispatch) => {
    return searchHistoryService.getSearchHistory()
      .then((res: any) => {
        dispatch(setSearchHistory(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while fetching the search history')
        return Promise.reject(err)
      })
  }
}

export const createSearchHistoryItem = (body: any): any => {
  return (dispatch) => {
    return searchHistoryService.createSearchHistoryItem(body)
      .then((res: any) => {
        dispatch(setSearchQuery(res))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while creating a search history item')
        return Promise.reject(err)
      })
  }
}

export const deleteSearchHistoryItem = (id: number): any => {
  return (dispatch) => {
    return searchHistoryService.deleteSearchHistoryItem(id)
      .then((res: any) => {
        dispatch(removeSearchQuery(id))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while deleting the search history item')
        return Promise.reject(err)
      })
  }
}

export const findSearchHistory = (searchStr: string = ''): any => {
  return (dispatch) => {
    return searchHistoryService.findSearchHistory(searchStr)
      .then((res: any) => {
        dispatch(setSearchHistory(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while searching the search history')
        return Promise.reject(err)
      })
  }
}


export const getSearchHistoryItem = (id: number): any => {
  return (dispatch) => {
    return searchHistoryService.getSearchHistoryItem(id)
      .then((res: any) => {
        dispatch(setSearchHistoryItem(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while searching the search history')
        return Promise.reject(err)
      })
  }
}


export const shareSearch = (id: number, people: number[]): any => {
  return (dispatch) => {
    return searchHistoryService.shareSearch(id, {people})
      .then((res: any) => {
        dispatch(setSuccess({
          message: 'Your search has been successfully shared'
        }))
        dispatch(updateSearchHistoryItem(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while sharing the search', err))
        console.log('An error occured while sharing the search')
        return Promise.reject(err)
      })
  }
}