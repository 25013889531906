import React from 'react';
import { SvgIcon } from "@material-ui/core";
import MailIcon from '@material-ui/icons/Mail';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '../../theme';


const styles = (theme: Theme) => createStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    height: 64,
    backgroundColor: '#f9fbfc',
    borderRadius: '50%',
    '& > svg': {
      fontSize: 32,
      color: theme.palette.primary.main
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  className?: any; 
}

export const EmptyMailIcon = withStyles(styles)((props: IProps) => {
  return (
    <span className={props.classes.wrapper}>
      <MailIcon className={props.className} />
    </span>
  )
});
