import * as actions from '../actions/outlook';

export const initialState = {
  status: {
    has_outlook_credentials: false,
    has_outlook_subscription: false,
    credentials_are_active: false,
    subscriptions_are_active: false
  },
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_OUTLOOK_STATUS:
      return { ...state, status: action.status }


    case actions.SET_LOADING:
      return { ...state, loading: action.state }


    default:
      return state

  }
}