import React, { Fragment } from 'react';
import { formatNumber } from './utils';

export const highlight = (str: string, search: string = '', highlight: boolean = true): any => {
  if(!str) return '';
  if(!search || !highlight) return str;

  const searchStrings = search.toLowerCase()
    .split(' ')
    .map((str: string) => {
      const num = str.replace(',', '');

      return isNaN(+num) 
        ? str
        : [
            num,
            formatNumber(num),
            `${num.substring(0, 1)},${num.substring(1).replace(/(\d{3})/g, '$1,')}`,
            `${num.substring(0, 2)},${num.substring(2).replace(/(\d{3})/g, '$1,')}`,
            `${num.substring(0, 3)},${num.substring(3).replace(/(\d{3})/g, '$1,')}`,
          ]
    })
    .flat()
    .filter((item: any) => !!item)
  

  const parts = `${str}`
    .split(new RegExp(`(${searchStrings.join('|')})`, 'gi'))
    .filter((item: string) => !!item);

  return (
    <span>
      {parts.map((part, i) => (
        <span key={`part-${i}`} style={searchStrings.includes(part.toLowerCase()) ? { fontWeight: 'bold', color: '#4192ec', } : {}}>
          {part}
        </span>
      ))}
    </span>
  )
}


export const breakAndHighlight = (str: string, search?: string): any => {
  const lineBreaks = `${str}`.split('\n');

  return (
    <>
      {lineBreaks.map((part: string, idx: number) => (
        <Fragment key={idx}>
          {!!idx ? <br /> : null}
          {highlight(part, search)}
        </Fragment>
      ))}
    </>
  )
}