
import { changeCompanyNote, changeCompanyState } from "../actions/projects/providers";
import { ITask, SortDirection, TableColumn, ManageableTableColumn } from "../shared/interfaces";
import { IPerson } from "../shared/models/Person";
import { IProject, MembershipState } from "../shared/models/Project";
import { InputType } from "../shared/types";
import { getUsername } from "../utils/user";
import { formatDate, formatDateTime, formatNumber, getCategoryLabel } from "../utils/utils";



const sortByBool = (arr: any[], field: string): any[] => {
  return arr.sort((a: any, b: any) =>
    (a[field] === b[field]) ? 0 : (a[field] ? -1 : 1)
  )
}


export const formatManageableTableColumns = (arr: TableColumn[]): ManageableTableColumn[] => {
  return sortByBool(arr, 'default')
    .map((column: TableColumn, idx: number) => ({
      type: column.type,
      label: column.label,
      hidden: column.manageHidden,
      active: !!column.default,
      group: column.group,
      order: idx,
      search: column.search,
      defaultSortOrder: column.defaultSortOrder,
      defaultSortDirection: column.defaultSortDirection
    }))
}




const isDefault = (type: string, defaults?: string[]): boolean => {
  if (defaults === undefined) return true;

  return defaults.includes(type)
}






const getCPColumns = (): TableColumn[] => [
  {
    label: 'CP Description',
    type: 'cp_subcategory_types',
    typeField: 'cp_subcategories',
    search: 'capital_provider__cp_subcategory_types__name',
    value: (person: IPerson, types: any[]) => {
      if(!types.length || !person.capital_provider?.cp_subcategory_types?.length) return '';

      return types
        .filter((type: any) => person.capital_provider?.cp_subcategory_types.includes(type.id))
        .map((type: any) => type.name)
        .join(', ')
    }
  },
  {
    label: 'CP Capital Type',
    type: 'capital_provider_transaction_type',
    // search: 'capital_provider__transaction_subtype',
    value: (person: IPerson) => 
      [
        person.capital_provider?.is_debt ? 'Debt' : undefined,
        person.capital_provider?.is_equity ? 'Equity' : undefined,
      ]
      .filter(i => !!i)
      .join(', ')
  },
  {
    label: 'CP Investment Class',
    type: 'capital_provider_investment_classes',
    typeField: 'investment_class_types',
    // search: 'capital_provider__investment_classes',
    value: (person: IPerson, types: any[]) => {
      if(!types.length || !person.capital_provider?.investment_classes?.length) return '';

      return types
        .filter((type: any) => person.capital_provider?.investment_classes.includes(type.id))
        .map((type: any) => type.name)
        .join(', ')
    }
  },
  {
    label: 'CP Investing In',
    type: 'capital_provider_investing_geography',
    typeField: 'investing_geography_types',
    search: 'capital_provider__investing_geography',
    value: (person: IPerson, types: any[]) => {
      if(!types.length || !person.capital_provider?.investing_geographies?.length) return '';

      return types
        .filter((type: any) => person.capital_provider.investing_geographies.includes(type.id))
        .map((type: any) => type.name)
        .join(', ')
    }
  },
  {
    label: 'CP Sharia Compliant',
    type: 'capital_provider_is_sharia',
    search: 'capital_provider__is_sharia',
    value: (person: IPerson) => !!person.capital_provider?.is_sharia ? 'Yes' : 'No'
  },
  {
    label: 'CP Land',
    type: 'capital_provider_is_land',
    search: 'capital_provider__is_land',
    value: (person: IPerson) => !!person.capital_provider?.is_land ? 'Yes' : 'No'
  },
  {
    label: 'CP Development',
    type: 'capital_provider_is_development',
    search: 'capital_provider__is_development',
    value: (person: IPerson) => !!person.capital_provider?.is_development ? 'Yes' : 'No'
  },
  {
    label: 'CP PRS',
    type: 'capital_provider_is_prs',
    search: 'capital_provider__is_prs',
    value: (person: IPerson) => !!person.capital_provider?.is_prs ? 'Yes' : 'No'
  },
  {
    label: 'CP Max LTV',
    type: 'capital_provider_max_ltv',
    search: 'capital_provider__max_ltv',
    value: (person: IPerson) => !!person.capital_provider?.max_ltv ? `${formatNumber(person.capital_provider?.max_ltv?.toFixed(2))}%` : '',
    numericValue: (person: IPerson) => person.capital_provider?.max_ltv ?? 0
  },
  {
    label: 'CP Max LTGDV',
    type: 'capital_provider_max_ltgdv',
    search: 'capital_provider__max_ltgdv',
    value: (person: IPerson) => !!person.capital_provider?.max_ltgdv ? `${formatNumber(person.capital_provider?.max_ltgdv?.toFixed(2))}%` : '',
    numericValue: (person: IPerson) => person.capital_provider?.max_ltgdv ?? 0
  },
  {
    label: 'CP Max LTC',
    type: 'capital_provider_max_ltc',
    search: 'capital_provider__max_ltc',
    value: (person: IPerson) => !!person.capital_provider?.max_ltc ? `${formatNumber(person.capital_provider?.max_ltc?.toFixed(2))}%` : '',
    numericValue: (person: IPerson) => person.capital_provider?.max_ltc ?? 0
  },
  {
    label: 'CP Min IRR',
    type: 'capital_provider_min_irr',
    search: 'capital_provider__min_irr',
    value: (person: IPerson) => !!person.capital_provider?.min_irr ? `${formatNumber(person.capital_provider?.min_irr?.toFixed(2))}%` : '',
    numericValue: (person: IPerson) => person.capital_provider?.min_irr ?? 0
  },
  {
    label: 'CP Min ROE',
    type: 'capital_provider_min_roe',
    search: 'capital_provider__min_roe',
    value: (person: IPerson) => !!person.capital_provider?.min_roe ? `${formatNumber(person.capital_provider?.min_roe)}x` : '',
    numericValue: (person: IPerson) => person.capital_provider?.min_roe ?? 0
  },
  {
    label: 'CP Min Term',
    type: 'capital_provider_min_term',
    search: 'capital_provider__min_term',
    value: (person: IPerson) => !!person.capital_provider?.min_term ? `${formatNumber(person.capital_provider?.min_term)} years` : '',
    numericValue: (person: IPerson) => person.capital_provider?.min_term ?? 0
  },
  {
    label: 'CP Max Term',
    type: 'capital_provider_max_term',
    search: 'capital_provider__max_term',
    value: (person: IPerson) => !!person.capital_provider?.max_term ? `${formatNumber(person.capital_provider?.max_term)} years` : '',
    numericValue: (person: IPerson) => person.capital_provider?.max_term ?? 0
  },
  {
    label: 'CP Min Size',
    type: 'capital_provider_min_size',
    search: 'capital_provider__min_size',
    value: (person: IPerson) => !!person.capital_provider?.min_size ? `£${formatNumber(person.capital_provider?.min_size)}` : '',
    numericValue: (person: IPerson) => person.capital_provider?.min_size ?? 0
  },
  {
    label: 'CP Max Size',
    type: 'capital_provider_max_size',
    search: 'capital_provider__max_size',
    value: (person: IPerson) => !!person.capital_provider?.max_size ? `£${formatNumber(person.capital_provider?.max_size)}` : '',
    numericValue: (person: IPerson) => person.capital_provider?.max_size ?? 0
  },
]








export const peopleColumns = (defaults?: string[], noCp: boolean = false, noSort: string[] = []): TableColumn[] => {
  return [
    {
      label: 'Name (First Name Last Name)',
      labelOnTable: 'Name',
      type: 'full_name',
      search: ['first_name', 'last_name'],
      default: isDefault('full_name', defaults),
      defaultSortOrder: 2,
      value: (person: IPerson) => getUsername(person),
      group: 1
    },
    {
      label: 'Name (Last Name, First Name)',
      labelOnTable: 'Name',
      type: 'full_name_separated',
      search: ['last_name', 'first_name'],
      value: (person: IPerson) => `${person.last_name}, ${person.first_name}`,
      group: 1
    },
    {
      label: 'Middle Name',
      type: 'middle_name',
      search: 'middle_name',
      value: (person: any) => person.middle_name
    },
    {
      label: 'Job Title',
      type: 'job_title',
      search: 'job_title',
      value: (person: IPerson) => person.job_title
    },
    {
      label: 'Company',
      type: 'company',
      search: 'company',
      default: isDefault('company', defaults),
      defaultSortOrder: 1,
      defaultSortDirection: SortDirection.DESC,
      value: (person: IPerson) => person.company
    },
    {
      label: 'Use Class',
      type: 'use_classes',
      search: 'use_classes__name',
      value: (person: IPerson) => (
        (person.use_classes ?? []).map((item: any) => item.name).join(', ')
      )
    },
    {
      label: 'Locations',
      type: 'location',
      search: 'location__name',
      value: (person: IPerson) => {
        return (person.locations ?? [])
          .map((location: any) => location.name)
          .join(', ')
      }
    },
    {
      label: 'Department',
      type: 'department',
      search: 'department',
      value: (person: any) => person.department
    },
    {
      label: 'Web Page',
      type: 'website',
      search: 'website',
      value: (person: IPerson) => person.website
    },
    {
      label: 'Work Email Address',
      type: 'emails',
      search: 'emails__0',
      default: isDefault('emails', defaults),
      value: (person: IPerson) => person.emails?.[0] ?? ''
    },
    {
      label: 'Rating',
      type: 'rating',
      search: 'rating',
      default: isDefault('rating', defaults),
      value: (person: IPerson) => person.rating
    },
    {
      label: 'User Type',
      type: 'profile_type',
      search: 'profile_type',
      default: isDefault('profile_type', defaults),
      tableWidth: '200px',
      value: (person: IPerson) => person.profile_type
    },
    {
      label: 'Title',
      type: 'title',
      search: 'title',
      typeField: 'title',
      value: (person: IPerson, types: any[]) => {
        if (!types) return '';

        return types.find((type: any) => type.id === (person.title?.id ?? person.title))?.name ?? '';
      }
    },
    {
      label: 'Suffix',
      type: 'suffix',
      search: 'suffix',
      value: (person: any) => person.suffix
    },
    {
      label: 'Mobile Number',
      type: 'phones',
      search: 'phones',
      value: (person: IPerson) => person.phones?.[0] ?? ''
    },
    {
      label: 'Contact Category',
      type: 'category',
      typeField: 'categories',
      search: 'categories__name',
      value: (person: IPerson, types: any[]) => getCategoryLabel(person)
    },
    /**
     * 
    {
      label: 'Contact Category',
      type: 'subcategories',
      typeField: 'categories',
      search: 'category__name',
      value: (person: IPerson, types: any[]) => person?.categories
        ? types.filter((type: any) => person.categories.includes(type.id))
            .map((type: any) => type.name)
            .join(', ')
        : ''
    },
     */
    {
      label: 'Previous Companies',
      type: 'previous_company',
      search: 'previous_company',
      value: (person: IPerson) => person.previous_company
    },
    {
      label: 'Legal Status',
      type: 'legal_status',
      typeField: 'legal_status',
      search: 'legal_status',
      value: (person: IPerson, types: any[]) => {
        if(!types?.length) return '';

        return types.find(t => t.id === (person.legal_status?.id ?? person.legal_status))?.name ?? ''
      }
    },
    {
      label: 'Bio',
      type: 'bio',
      search: 'bio',
      value: (person: IPerson) => person.bio
    },
    {
      label: 'Spouse',
      type: 'spouse',
      search: 'spouse',
      value: (person: any) => person.spouse
    },
    {
      label: 'Birthday',
      type: 'birthday',
      search: 'birthday',
      value: (person: any) => formatDate(person.birthday)
    },
    {
      label: 'Nickname',
      type: 'nickname',
      search: 'nickname',
      value: (person: any) => person.nickname
    },
    {
      label: 'Business Street',
      type: 'street',
      search: 'street',
      value: (person: IPerson) => person.street,
    },
    {
      label: 'Business City',
      type: 'city',
      search: 'city',
      value: (person: IPerson) => person.city
    },
    {
      label: 'Business Postal Code',
      type: 'postcode',
      search: 'postcode',
      value: (person: IPerson) => person.postcode
    },
    {
      label: 'Business Country/Region',
      type: 'country',
      search: 'country',
      value: (person: IPerson) => person.country
    },
    {
      label: 'Home Street',
      type: 'home_street',
      search: 'home_street',
      value: (person: any) => person.home_street
    },
    {
      label: 'Home City',
      type: 'home_city',
      search: 'home_city',
      value: (person: any) => person.home_city
    },
    {
      label: 'Home Postal Code',
      type: 'home_postcode',
      search: 'home_postcode',
      value: (person: any) => person.home_postcode
    },
    {
      label: 'Home Country/Region',
      type: 'home_country',
      search: 'home_country',
      value: (person: any) => person.home_country
    },
    {
      label: 'Date Modified',
      type: 'updated_at',
      search: 'updated_at',
      value: (person: any) => formatDateTime(person.updated_at)
    },
    {
      label: 'Tags',
      type: 'tags',
      // search: 'tags',
      value: (person: IPerson) => {
        return !!person.tags?.length && person.tags.join(', ')
      }
    },
    ...(noCp ? [] : getCPColumns()),
    {
      label: 'Home Email Address',
      type: 'home_email',
      search: 'emails__1',
      value: (person: IPerson) => person?.emails?.[1] ?? ''
    },
    {
      label: 'Other Email Address',
      type: 'other_email',
      search: 'emails__2',
      value: (person: IPerson) => person?.emails?.[2] ?? ''
    },
    {
      label: 'Other Email Address 2',
      type: 'other_email_2',
      search: 'emails__3',
      value: (person: IPerson) => person?.emails?.[3] ?? ''
    },
    {
      label: 'Work Number',
      type: 'work_phone',
      value: (person: IPerson) => person?.phones?.[1] ?? ''
    },
    {
      label: 'Work Number 2',
      type: 'work_phone_2',
      value: (person: IPerson) => person?.phones?.[2] ?? ''
    },
    {
      label: 'Home Number',
      type: 'home_phone',
      value: (person: IPerson) => person?.phones?.[3] ?? ''
    },
    {
      label: 'Home Number 2',
      type: 'home_phone_2',
      value: (person: IPerson) => person?.phones?.[4] ?? ''
    },
  ].map((item: TableColumn) => noSort.includes(item.type) ? { ...item, search: undefined } : item)
}





export const projectsColumns: TableColumn[] = [
  {
    label: 'Name',
    type: 'name',
    search: 'name',
    default: true,
    value: (project: IProject) => project.name
  },
  {
    label: 'Project Owners',
    type: 'owners',
    default: true,
    // search: 'team__first_name',
    value: (project: IProject) => {
      if(!project.members?.length) return '';

      const len = project.members.length;
      return project.members
        .filter(member => member.state === MembershipState.Owner)
        .map(member => len < 3 ? getUsername(member.person) : member.person.first_name)
        .join(', ')
    }
  },
  {
    label: 'Client',
    type: 'client',
    search: 'client',
    default: true,
    value: (project: IProject) => project.client
  },
  {
    label: 'Type',
    type: 'type',
    default: true,
    typeField: 'project_types',
    // search: 'project_types__name',
    value: (project: IProject, types: any[]) => {
      if(!types?.length || !project.project_types?.length) return '';

      return types
        .filter((type: any) => project.project_types.includes(type.id))
        .map((type: any) => type.name)
        .join(', ')
    }
  },
  {
    label: 'Transaction Type',
    type: 'transaction',
    default: true,
    typeField: 'transaction_types',
    // search: 'transaction',
    value: (project: IProject, types: any[]) => {
      if(!types?.length || !project.transaction_types?.length) return '';

      return types
        .filter((type: any) => project.transaction_types.includes(type.id))
        .map((type: any) => type.name)
        .join(', ')
    }

  },
  {
    label: 'Capital Required',
    type: 'capital',
    search: 'capital_required',
    default: true,
    value: (project: IProject) => project.capital_required ? `£${formatNumber(project.capital_required)}` : '',
    numericValue: (project: IProject) => project.capital_required ?? 0
  },
  {
    label: 'Closing Date',
    type: 'date',
    search: 'closing_date',
    value: (project: IProject) => project.closing_date ? formatDate(Date.parse(project.closing_date), false, true) : ''
  },
  {
    label: 'Valuation',
    type: 'valuation',
    search: 'recent_valuation',
    value: (project: IProject) => project.recent_valuation ? `£${formatNumber(project.recent_valuation)}` : '',
    numericValue: (project: IProject) => project.recent_valuation ?? 0
  },
  {
    label: 'Term Sheet Fee',
    type: 'term_sheet_fee',
    search: 'term_sheet_fee',
    value: (project: IProject) => project.term_sheet_fee ? `£${formatNumber(project.term_sheet_fee)}` : '',
    numericValue: (project: IProject) => project.term_sheet_fee ?? 0
  },
  {
    label: 'Success Fee %',
    type: 'fees',
    search: 'fees',
    value: (project: IProject) => project.fees ? `${formatNumber(project.fees.toFixed(2))}%` : '',
    numericValue: (project: IProject) => +project.fees.toFixed(2) ?? 0
  },
  {
    label: 'Success Fee',
    type: 'fee',
    // search: 'fees',
    value: (project: IProject) => project.fees && project.capital_required
      ? `£${formatNumber((project.capital_required * (project.fees / 100)).toFixed(0))}`
      : '',
    numericValue: (project: IProject) => +(project.capital_required * (project.fees / 100)).toFixed(0) ?? 0
  },
  {
    label: 'Total Fees',
    type: 'total_fees',
    // search: 'total_fees',
    value: (project: IProject) => {
      if(!project.fees || !project.capital_required) return 0;
      
      const totalFees = project.capital_required * (project.fees / 100) + (project.term_sheet_fee ?? 0);
      return totalFees ? `£${formatNumber(totalFees.toFixed(0))}` : ''
    },
    numericValue: (project: IProject) => +(project.capital_required * (project.fees / 100) + (project.term_sheet_fee ?? 0)).toFixed(0) ?? 0
  },
  {
    label: 'Tags',
    type: 'tags',
    // search: 'tags',
    value: (project: IProject) => project.tags?.join(', ') ?? ''
  },
  {
    label: 'Sub Type',
    type: 'sub_type',
    typeField: 'transaction_sub_types',
    // search: 'transaction_sub_types',
    value: (project: IProject, types: any[]) => {
      if(!types?.length || !project.transaction_sub_types?.length) return '';

      return types
        .filter((type: any) => project.transaction_sub_types.includes(type.id))
        .map((type: any) => type.name)
        .join(', ')
    }
  },
  {
    label: 'Asset Type',
    type: 'asset_types',
    typeField: 'asset_types',
    // search: 'asset_types',
    value: (project: IProject, types: any[]) =>{
      if(!types?.length || !project.asset_types?.length) return '';

      return types
        .filter((type: any) => project.asset_types.includes(type.id))
        .map((type: any) => type.name)
        .join(', ')
    }
  },
  {
    label: 'Status',
    type: 'status',
    typeField: 'project_status_types',
    search: 'status__order',
    value: (project: IProject, types: any[]) => (
      types.find(t => t.id === project.status)?.name ?? ''
    )
  },
  {
    label: 'Progress',
    type: 'progress',
    // search: 'progress',
    value: (project: IProject) => project.progress // TODO no progress field
  },
]





export const tasksColumns: TableColumn[] = [
  {
    label: 'Task',
    type: 'title',
    search: 'title',
    default: true,
    value: (task: ITask) => task.title
  },
  {
    label: 'Project',
    type: 'project',
    search: 'project__name',
    default: true,
    value: (task: ITask) => task.project_details?.name ?? ''
  },
  {
    label: 'Assigned By',
    type: 'created_by',
    search: 'created_by__first_name',
    default: true,
    value: (task: ITask) => (task.created_by_details && getUsername(task.created_by_details)) ?? ''
  },
  {
    label: 'Status',
    type: 'task_status',
    typeField: 'task_states',
    search: 'state',
    default: true,
    value: (task: ITask, types: any[]) => (
      types?.find(t => t.id === task.state)?.name ?? ''
    )
  },
  {
    label: 'Due Date',
    type: 'due_date',
    search: 'due_date',
    default: true,
    defaultSortOrder: 1,
    value: (task: ITask) => (task.due_date && formatDate(Date.parse(task.due_date))) ?? ''
  },
]




export const capitalProvidersColumns: TableColumn[] = [
  {
    label: 'Name',
    type: 'cp_manage_full_name',
    search: 'full_name',
    default: true,
    groupFor: 'company',
    value: (provider: any) => provider.person.full_name,
  },
  {
    label: 'Last Contacted',
    type: 'cp_manage_last_contacted',
    search: 'last_contacted',
    valueType: InputType.DATE,
    default: true,
    groupFor: 'last_contacted',
    value: (provider: any) => formatDate(provider.last_contacted)
  },
  {
    label: 'Status',
    type: 'cp_manage_state',
    search: 'state',
    default: true,
    value: (provider: any) => provider.state,
    isGroupProp: true,
    groupDetails: 'company_details',
    action: (relationIds: number[], state: number) => changeCompanyState(relationIds, state)
  },
  {
    label: 'Notes',
    type: 'cp_manage_note',
    default: true,
    editable: true,
    isGroupProp: true,
    groupDetails: 'company_details',
    tableWidth: '50%',
    value: (allProviders: any[]) => allProviders.filter((item: any) => !!item.notes)?.[0]?.notes ?? undefined,
    action: (relationId: number, notes: string | null) => changeCompanyNote(relationId, notes)
  }
]




export const importedDuplicatesColumns: TableColumn[] = peopleColumns(undefined, false, ['category', 'legal_status'])
export const possibleDuplicatesColumns: TableColumn[] = peopleColumns(undefined, false, ['category', 'legal_status'])




export const deletedContactsColumns: TableColumn[] = [
  ...peopleColumns(['full_name', 'company']),
  {
    label: 'Deleted By',
    type: 'deleted_by',
    search: 'deleted_by',
    default: true,
    value: (contact: any) => getUsername(contact.deleted_by)
  },
  {
    label: 'Date Deleted',
    type: 'deleted_at',
    search: 'deleted_at',
    default: true,
    value: (contact: any) => contact.deleted_at && formatDate(contact.deleted_at)
  },
]



export const newContactsColumns: TableColumn[] = [
  {
    label: 'Email Address',
    type: 'email_address',
    search: 'email_address',
    default: true,
    tableWidth: '70%',
    value: (contact: any) => contact.email_address
  },
  {
    label: 'Actions',
    type: 'new_contacts_actions',
    default: true,
    manageHidden: true,
    value: (contact: any) => contact
  },
]



export const activityColumns: TableColumn[] = [
  {
    label: 'Date and Time',
    type: 'datetime',
    search: 'date',
    default: true,
    defaultSortDirection: SortDirection.DESC,
    value: (activity: any) => formatDateTime(activity.date)
  },
  {
    label: 'User',
    type: 'user',
    search: ['person__first_name', 'person__last_name'],
    default: true,
    value: (activity: any) => activity.person
  },
  {
    label: 'Description',
    type: 'activity_description',
    default: true,
    tableWidth: '40%',
    value: (activity: any) => activity,
  },
  {
    label: 'Action',
    type: 'activity_action',
    typeField: 'activity_types',
    search: 'type__name',
    default: true,
    value: (activity: any, types: any[]) => types?.find((type) => type.id == activity.type)?.name ?? '',
  },
]


export const draftsColumns: TableColumn[] = [
  {
    label: 'Subject',
    type: 'draft_subject',
    search: 'subject',
    default: true,
    value: (draft: any) => draft.subject
  },
  // Removed by client request.
  // {
  //   label: 'Type',
  //   type: 'draft_type',
  //   default: true,
  //   value: (draft: any) => draft.is_company ? 'Company Draft' : 'Draft'
  // },
  {
    label: 'Created At',
    type: 'draft_created_at',
    search: 'created_at',
    defaultSortOrder: 2,
    defaultSortDirection: SortDirection.DESC,
    default: true,
    value: (draft: any) => formatDateTime(draft.created_at),
  },
  {
    label: 'Updated At',
    type: 'draft_updated_at',
    search: 'updated_at',
    defaultSortOrder: 1,
    defaultSortDirection: SortDirection.DESC,
    default: true,
    value: (draft: any) => formatDateTime(draft.updated_at),
  },
]




export const templatesColumns: TableColumn[] = [
  {
    label: 'Subject',
    type: 'template_subject',
    search: 'subject',
    default: true,
    value: (template: any) => template.subject
  },
  {
    label: 'Template Name',
    type: 'template_title',
    search: 'name',
    default: true,
    value: (template: any) => template.name
  },
  // Removed by client request.
  // {
  //   label: 'Type',
  //   type: 'template_type',
  //   default: true,
  //   value: (template: any) => template.is_company ? 'Company Template' : 'Template'
  // },
  {
    label: 'Created At',
    type: 'template_created_at',
    search: 'created_at',
    defaultSortOrder: 2,
    defaultSortDirection: SortDirection.DESC,
    default: true,
    value: (template: any) => formatDateTime(template.created_at),
  },
  {
    label: 'Updated At',
    type: 'template_updated_at',
    search: 'updated_at',
    defaultSortOrder: 1,
    defaultSortDirection: SortDirection.DESC,
    default: true,
    value: (template: any) => formatDateTime(template.updated_at),
  },
]







export const initialTableColumns = {
  people: formatManageableTableColumns(peopleColumns()),
  projects: formatManageableTableColumns(projectsColumns),
  tasks: formatManageableTableColumns(tasksColumns),
  capitalProviders: formatManageableTableColumns(capitalProvidersColumns),
  importedDuplicates: formatManageableTableColumns(importedDuplicatesColumns),
  possibleDuplicates: formatManageableTableColumns(possibleDuplicatesColumns),
  deletedContacts: formatManageableTableColumns(deletedContactsColumns),
  newContacts: formatManageableTableColumns(newContactsColumns),
  activity: formatManageableTableColumns(activityColumns),
  drafts: formatManageableTableColumns(draftsColumns),
  templates: formatManageableTableColumns(templatesColumns),
  closingTasks: formatManageableTableColumns(tasksColumns)
}





export default {
  people: peopleColumns(),
  projects: projectsColumns,
  tasks: tasksColumns,
  capitalProviders: capitalProvidersColumns,
  importedDuplicates: importedDuplicatesColumns,
  possibleDuplicates: possibleDuplicatesColumns,
  deletedContacts: deletedContactsColumns,
  newContacts: newContactsColumns,
  activity: activityColumns,
  drafts: draftsColumns,
  templates: templatesColumns,
  closingTasks: tasksColumns
}