import Axios, { AxiosResponse } from 'axios';
import { Component } from 'react';
import { REQUEST_CANCEL } from '../../shared/types';
import { randomId } from '../../utils/utils';



export const axiosInstance = Axios.create({
  timeout: 300000
})


class ResponseProcess {


  processResponse = (res: AxiosResponse): any => {
    return res.data
  };



  processError = (err: any): any => {
    if(Axios.isCancel(err)) {
      throw new Axios.Cancel(err);
    }

    return Promise.reject(err)
  }
  


  processFinally = (): any => {

  }

}



class Request extends Component {
  

  static action = (request: any): any => {
    const responseProcess = new ResponseProcess();
    return request
      .then(responseProcess.processResponse)
      .catch(responseProcess.processError)
      .finally(responseProcess.processFinally)
  }



  static get = (path: string, headers?: any, outsideHeaders: boolean = false, ...args: any[]): any => {
    return Request.action(axiosInstance.get(path, Request.baseHeaders(headers, outsideHeaders)))  
  }

  
  static post = (path: string, data?: any, headers?: any, outsideHeaders: boolean = false): any => {
    return Request.action(axiosInstance.post(path, data ?? null, Request.baseHeaders(headers, outsideHeaders)))
  }

  
  static put = (path: string, data?: any, headers?: any, outsideHeaders: boolean = false): any => {
    return Request.action(axiosInstance.put(path, data ?? null, Request.baseHeaders(headers, outsideHeaders)))
  }

  
  static patch = (path: string, data?: any, headers?: any, outsideHeaders: boolean = false): any => {
    return Request.action(axiosInstance.patch(path, data ?? null, Request.baseHeaders(headers, outsideHeaders)))
  }


  static delete = (path: string, headers?: any, outsideHeaders: boolean = false): any => {
    return Request.action(axiosInstance.delete(path, Request.baseHeaders(headers, outsideHeaders)))
  }


  static downloadFile = (path: string, data: any): any => {
    return Request.action(axiosInstance.post(path, data, Request.downloadHeaders()))
  }





  static baseHeaders = (extraHeaders: object = {}, outsideHeaders: boolean = false): any => {
    const state = JSON.parse(localStorage.getItem('state') || '{}')
    const token = state?.user?.token ?? '';

    return {
      requestId: randomId(),
      headers: {
        ...(outsideHeaders ? {} : extraHeaders),
        'Authorization': `Token ${token}`
      },
      ...(outsideHeaders ? extraHeaders : {})
    }
  }



  static downloadHeaders = (): any => {
    return Request.baseHeaders({ responseType: 'blob', timeout: 300000 }, true)
  }



}


export default Request