import React, { useMemo } from 'react';
import { setValidationError } from '../../../actions/people/people';
import { MultiInputType } from '../../../shared/types';
import MultiTextInput from '../../MultiTextInput';
import { usePersonData } from '../hooks/people-hooks';
import { EntityField } from '../../../shared/interfaces';


interface IProps extends EntityField {
  inputType: MultiInputType;
}

const ContactInfoMultiInput = ({ name, inputType, ...otherProps }: IProps) => {
  const { value = [], error, readonly, onChange, dispatch, validationErrors } = usePersonData(name);




  const getLabels = (): string[] => {
    switch(inputType) {
      case MultiInputType.EMAILS:
        return ['Work Email Address', 'Home Email Address', 'Other Email Address']
      case MultiInputType.PHONES:
        return ['Mobile Number', 'Work Number', 'Work Number 2', 'Home Number', 'Home Number 2', 'Business Fax', 'Other']
      default:
        return []
    }
  }



  const handleChange = (index: number, newEmail: string): void => {
    let emails = [...value];
    emails[index] = newEmail.toLowerCase();
    onChange(emails)
  }




  const dispatchSetValidationError = (data: any): any => {
    const newData = { ...validationErrors[name] };
    const [field, value] = Object.entries(data)[0];

    if(value === undefined) {
      delete newData[field];
    } else {
      newData[field] = value
    }

    return dispatch(setValidationError({ [name]: newData }))
  }



  
  return useMemo(() => (
    <MultiTextInput
      name={name}
      labels={getLabels()}
      readonly={readonly}
      values={value}
      errors={error}
      onValidationError={dispatchSetValidationError}
      onChange={handleChange}
      {...otherProps}
    />
  ), [value, error, readonly])
}


export default ContactInfoMultiInput