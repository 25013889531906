export const getQueryParamsMap = () => {

    const map = {};

    const {search} = window.location;

    if (!search.length) return map;

    // Split params into parts
    const paramsString = search.substr(1);
    const paramPairs = paramsString.split("&");

    // Process each
    for (let pair of paramPairs){
        const pairParts = pair.split("=");

        // If this is a param with no value, then it's just a boolean flag, kind of like
        // Reacts params
        if (pairParts.length === 1){
            map[pair] = true;

            // If not, well then it's not
        } else if (pairParts.length === 2){

            const [key, val] = pairParts;

            // If this is not the first time we are seeing this variable, make an array out of it
            if (map[key] !== undefined){

                const currentValue = map[key];
                if (Array.isArray(currentValue)){
                    currentValue.push(val)
                } else {
                    map[key] = [currentValue, val]
                }

                // If this is a first time, just assign it
            } else {
                map[key] = val;
            }


        }

    }

    return map
};
