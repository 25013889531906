import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const DragIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14">
        <line x1="0" x2="100%" y1="10%" y2="10%" stroke="#d8d8d8" strokeWidth="2" />
        <line x1="0" x2="100%" y1="50%" y2="50%" stroke="#d8d8d8" strokeWidth="2" />
        <line x1="0" x2="100%" y1="90%" y2="90%" stroke="#d8d8d8" strokeWidth="2" />
    </SvgIcon>
  );
};