import moment from 'moment';
import tasksService from '../services/tasks';
import { ISort } from '../shared/interfaces';
import { PrepareObject, TaskType } from '../shared/types';
import { setError } from './common/notifications';
import { forceDownloadFile, formatMonthFilter, formatSortObject, formatTaskSearch, prepareFields } from './helpers/helpers';




/**
 * Actions
 */

export const SET_TASKS                    = 'SET_TASKS';
export const ADD_TASKS                    = 'ADD_TASKS';
export const SET_CLOSING_PROCESS_TASKS    = 'SET_CLOSING_PROCESS_TASKS';
export const SET_TASK                     = 'SET_TASK';
export const SET_COLUMN                   = 'SET_COLUMN';
export const UPDATE_TASK                  = 'UPDATE_TASK';
export const UPDATE_TASK_STATE            = 'UPDATE_TASK_STATE';
export const CLEAR_TASK                   = 'CLEAR_TASK';
export const CLEAR_TASKS                  = 'CLEAR_TASKS';
export const DELETE_TASK                  = 'DELETE_TASK';
export const CHANGE_TASK_FIELD            = 'CHANGE_TASK_FIELD';
export const APPEND_TASK                  = 'APPEND_TASK';
export const SET_LOADING                  = 'TASKS_SET_LOADING';
export const APPEND_TASKS                 = 'APPEND_TASKS';
export const SET_TASK_VIEW_FIELD          = 'SET_TASK_VIEW_FIELD';
export const SET_ERRORS                   = "TASKS_SET_ERRORS";
export const REMOVE_FILE                  = "TASKS_REMOVE_FILE";



/**
 * Action creators
 */

export function setAllTasks(data: any): object {
  return { type: SET_TASKS, ...data }
}

export function addNewTasks(tasks: any): object {
  return { type: ADD_TASKS, tasks }
}

export function setClosingProcessTasks(tasks: any) : object {
  return {type: SET_CLOSING_PROCESS_TASKS, tasks}
}

export function setTask(task: any) : object {
  return {type: SET_TASK, task}
}

export function setColumnToTask(column: number): object {
  return { type: SET_COLUMN, column }
}

export function updateSingleTask(task): object {
  return { type: UPDATE_TASK, task }
}

export function updateTaskState(taskId: number, state: TaskType): object {
  return { type: UPDATE_TASK_STATE, taskId, state }
}

export function clearTask(): object {
  return { type: CLEAR_TASK }
}

export function clearTasks(): object {
  return { type: CLEAR_TASKS }
}

export function deleteSingleTask(taskId: number): object {
  return { type: DELETE_TASK, taskId }
}

export function changeTaskField(field: any): object {
  return { type: CHANGE_TASK_FIELD, field }
}

export function appendTask(task: any): object {
  return { type: APPEND_TASK, task }
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}

export function appendTasks(data: any): object {
  return {type: APPEND_TASKS, ...data}
}

export function setEditing(value: string[]): object {
  return { type: SET_TASK_VIEW_FIELD, name: 'editing', value }
}

export function setAddingNewComment(value: boolean): object {
  return { type: SET_TASK_VIEW_FIELD, name: 'newComment', value }
}

export function setCloseDialogState(value: boolean): object {
  return { type: SET_TASK_VIEW_FIELD, name: 'closeDialogOpened', value }
}

export function setErrors(errors: any): object {
  return { type: SET_ERRORS, errors }
}

export function removeFileFromTask(idx: number): object {
  return { type: REMOVE_FILE, idx }
}



/**
 * Async action creators
 */

export const getTask = (taskId: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return tasksService.getTask(taskId)
      .then((res: any) => {
        dispatch(setTask(res));
        return Promise.resolve(res);
      })
      .catch(err => {
        console.log('An error occured while getting a task.')
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getAllTasks = (sort?: ISort[], search: string = ''): any => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));

    return tasksService.getTasks(body)
      .then((res: any) => dispatch(setAllTasks(res)))
      .catch(err => {
        console.log('An error occured while getting tasks.')
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getClosingProcessTasks = (sort?: ISort[], search: string = '') => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));

    return tasksService.getTasks(body)
      .then((res: any) => dispatch(setClosingProcessTasks(res.results)))
      .catch(err => {
        console.log('An error occured while getting tasks.')
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getTasks = (projectId: number, search: string = '') => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return tasksService.getTasks(`?project=${projectId}${search ? `&${search}` : ''}`)
      .then((res: any) => {
        dispatch(setAllTasks(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting tasks.');
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getTasksByMonth = (year: number, month: number, projectIds?: string, initial?: boolean) => {
  return (dispatch) => {
    const body = formatMonthFilter(year, month, projectIds);
    dispatch(setLoading(true));
    
    return tasksService.getTasks(body)
      .then((res: any) => {
        if(initial) {
          dispatch(setAllTasks(res))
          return
        }

        dispatch(addNewTasks(res.results))
      })
      .catch(err => {
        console.log('An error occured while getting tasks.')
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const searchTasks = (search?: string, raw: boolean = false) => {
  return (dispatch, getState) => {
    const projectId = getState().projects.project.id;
    const body = raw ? search : formatTaskSearch(projectId, search);
    dispatch(setLoading(true));
    

    return tasksService.getTasks(body)
      .then((res: any) => {
        dispatch(setAllTasks(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError("No tasks found", err));
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const createTask = (task: any) => {
  return (dispatch) => {
    const body = prepareFields(PrepareObject.TaskRequest, task);
    dispatch(setLoading(true));
    
    return tasksService.createTask(body)
      .then((res: any) => {
        dispatch(setTask(res));
        dispatch(appendTask(res))
        return Promise.resolve(res);
      })
      .catch(err => {
        const errors = err?.response?.data;
        if(!!errors && !(errors instanceof Array) && typeof errors === 'object') {
          dispatch(setErrors(errors))
        }
        dispatch(setError("Please fill in all required fields before creating a task", err))
        return Promise.reject(err.response);
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const updateTask = (task: any) => {
  return (dispatch) => {
    const body = prepareFields(PrepareObject.TaskRequest, task);
    dispatch(setLoading(true));
    
    return tasksService.updateTask(task.id, body)
      .then((res: any) => {
        dispatch(updateSingleTask(res));
        return Promise.resolve(res);
      })
      .catch(err => {
        const errors = err?.response?.data;
        if(!!errors && !(errors instanceof Array) && typeof errors === 'object') {
          dispatch(setErrors(errors))
        }
        dispatch(setError("Fill in all required fields in order to update task", err))
        return Promise.reject(err.response);
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const submitTask = (taskId: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return tasksService.submitTask(taskId)
      .then((res: any) => {
        dispatch(setTask(res));
        return Promise.resolve(res);
      })
      .catch(err => {
        dispatch(setError("Couldn't submit task", err))
        return Promise.reject(err.response);
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const approveTask = (taskId: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return tasksService.approveTask(taskId)
      .then((res: any) => {
        dispatch(setTask(res));
        dispatch(updateTaskState(res.id, res.state));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError("Couldn't approve task", err))
        return Promise.reject(err.response)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const rejectTask = (taskId: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return tasksService.rejectTask(taskId)
      .then((res: any) => {
        dispatch(setTask(res));
        dispatch(updateTaskState(res.id, res.state));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError("Couldn't reject task", err))
        return Promise.reject(err.response)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const deleteTask = (taskId: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    return tasksService.deleteTask(taskId)
      .then((res: any) => {
        dispatch(deleteSingleTask(res))
        return Promise.resolve(true)
      })
      .catch(err => {
        dispatch(setError("Couldn't delete task", err))
        return Promise.reject(err.response)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getNextTasks = () => {
  return (dispatch, getState) => {
    const nextURL = getState().board.tasks.next;
    if(!nextURL) return;
    dispatch(setLoading(true));
    
    return tasksService.getNextTasks(nextURL)
      .then((res: any) => {
        dispatch(appendTasks(res))
      })
      .catch(err => {
        dispatch(setError("Couldn't delete task", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const exportTasksSearch = (body: string = '', ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return tasksService.exportTasksSearch(body, ids)
      .then((res: any) => {
        if(!res) return;
        forceDownloadFile(res, `ft8-tasks-${moment().format('YYYYMMDD')}.csv`)
      })
      .catch(err => {
        console.log(err, err.response)
        dispatch(setError("An error occured while exporting search", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


