import React, { useMemo, MouseEvent, useCallback } from 'react';
import { Theme } from '../../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useTaskData, useTaskAccess, useTaskBackUrl } from '../hooks/tasks-hooks';
import TaskControlButtons from './TaskControlButtons';
import { TaskType } from '../../../../shared/types';
import { createTask, updateTask, approveTask, rejectTask, submitTask, setCloseDialogState } from '../../../../actions/tasks';
import { uploadFiles } from '../../../../actions/files';
import { DEFAULT_TASK_CATEGORY } from '../../../../shared/models/Task';
import { useSelector } from 'react-redux';
import { createComment } from '../../../../actions/comments';
import { useHistory } from 'react-router';


interface IProps {
  id?: number;
  isNew?: boolean;
}

const TaskButtons = (props: IProps) => {
  const { id, isNew } = props;
  const history = useHistory(); 
  const access = useTaskAccess();
  const backURL = useTaskBackUrl();
  const { value: state, updatedTask, dispatch } = useTaskData('state');
  const creator = useSelector((state: any) => state.user.user?.id);




  

  const openDialog = (): void => dispatchSetCloseDialogOpened(true)
  const closeDialog = (): void => dispatchSetCloseDialogOpened(false)



  const forceCloseDialog = (): void => {
    closeDialog();

    history.push(backURL)
  }




  const onCreateTask = useCallback((e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.stopPropagation();


    const { files, comments: text, ...taskData }: any = updatedTask;
    const body = { ...taskData, files: [] };

    dispatchCreateTask(body)
      .then((res: any) => {
        if(!!files?.length) {
          dispatchUploadTaskFiles(files, res.id);
        }

        if(!!text?.length) {
          const comment = { task: res.id, text, creator };
          dispatchCreateComments(comment);
        }

        history.push(`/task/${res.id}`, { backURL })
      })
  }, [updatedTask, backURL])




  const onUpdateTask = useCallback((): any => {
    dispatchUpdateTask(updatedTask).then(forceCloseDialog)
  }, [updatedTask])




  const onApproveTask = useCallback((): void => {
    id !== undefined && dispatchApproveTask(id).then(closeDialog)
  }, [id])




  const onRejectTask = useCallback((): void => {
    id !== undefined && dispatchRejectTask(id).then(closeDialog)
  }, [id])


  

  const onSubmitTask = useCallback((): void => {
    id !== undefined && dispatchSubmitTask(id).then(closeDialog)
  }, [id])




  


  const saveAndCloseButtons = useMemo((): any[] => [
    {
        label: 'Close',
        type: 'secondary',
        onClick: openDialog
    },
    {
      label: isNew ? 'Create Task' : 'Save and Close',
      type: !isNew && state === TaskType.ToDo ? 'secondary' : 'primary',
      onClick: isNew ? onCreateTask : onUpdateTask
    }
  ], [isNew, state, onUpdateTask, onCreateTask])



  const stateButtons = useMemo((): any[] => {
    if(isNew) return [];

    switch(state) {

      case TaskType.Submitted:
        return access.hasFullAccess
          ? [
              { label: 'Approve', type: 'success', onClick: onApproveTask },
              { label: 'Reject', type: 'error', onClick: onRejectTask }
            ]
          : [
              { label: 'Waiting for Approval', type: 'secondary', wide: true }
            ]

  
      case TaskType.ToDo:
        return [
          { label: 'Submit for Approval', type: 'primary', onClick: onSubmitTask, wide: true }
        ]
    }
    
    return []
  }, [isNew, state, onApproveTask, onRejectTask, access.hasFullAccess, onSubmitTask])





  

  const dispatchCreateTask = useCallback((fields: any): any => {
    return dispatch(createTask(fields))
  }, [])


  
  const dispatchUploadTaskFiles = useCallback((files: any[], taskId: number): any => {
    return dispatch(uploadFiles(files, DEFAULT_TASK_CATEGORY, taskId))
  }, [])


  
  const dispatchCreateComments = useCallback((comment: any): any => {
    return dispatch(createComment(comment))
  }, [])


  
  const dispatchUpdateTask = useCallback((fields: any): any => {
    return dispatch(updateTask(fields))
  }, [])


  
  const dispatchApproveTask = useCallback((taskId: number): any => {
    return dispatch(approveTask(taskId))
  }, [])



  const dispatchRejectTask = useCallback((taskId: number): any => {
    return dispatch(rejectTask(taskId))
  }, [])



  const dispatchSubmitTask = useCallback((taskId: number): any => {
    return dispatch(submitTask(taskId))
  }, [])


  
  const dispatchSetCloseDialogOpened = useCallback((state: boolean): any => {
    return dispatch(setCloseDialogState(state))
  }, [])





  return <>
    <TaskControlButtons buttons={saveAndCloseButtons} />
    <TaskControlButtons buttons={stateButtons} />
  </>
}


export default TaskButtons