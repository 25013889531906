import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import { IconButton } from "@material-ui/core"
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import React, { RefObject, useState } from "react"
import Select from "../../components/Select"
import { Theme } from "../../theme"
import { formatMonthHeader } from "../../utils/utils"



const styles = (theme: Theme) => createStyles({
  '@keyframes showIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  },
  root: {
    animation: '.3s 1 $showIn ease-in',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerMonth: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  headerSelects: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end'
  },
  headerDate: {
    width: '70%',
    paddingLeft: 10,
  },
  headerDateMonth: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 500,
    color: '#233539',
  },
  headerDateYear: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 'normal',
    color: '#233539',
    paddingLeft: 7,
  },
  leftRight: {
    color: '#4192ec',
    height: 24,
    fontSize: 18,

    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  icon: {
    fontSize: 15
  },
  tasks: {
    backgroundColor: 'unset',
    borderColor: 'unset',
    border: 'unset',
    color: '#657274',
    padding: 2,
    marginLeft: 3,
    fontSize: 11,
    transition: 'color .1s ease',
    '&:hover': {
      '& > .fc-content': {
        color: '#4192ec',
      }
    },
    '& > .fc-content': {
      textOverflow: 'ellipsis',
      margin: '0 4px',
      '&:before': {
        content: '""',
        width: 6,
        height: 6,
        display: 'inline-block',
        borderRadius: '50%',
        backgroundColor: '#90a0a7',
        marginRight: 2,
      }
    }
  },
  projects: {
    backgroundColor: '#e8f2fd',
    border: '1px solid transparent',
    color: '#4192ec',
    padding: 2,
    fontSize: 10,
    margin: '0 4px 0 7px',
    transition: 'border-color .1s ease',
    '&:hover': {
      borderColor: theme.palette.primary.main
    },
    '& > .fc-content': {
      verticalAlign: 'middle',
      color: theme.palette.primary.main,
      padding: '1px 5px 0 5px',
      textOverflow: 'ellipsis',
      '&:before': {
        content: '""',
        background: 'url(/img/notification-bell.svg) no-repeat',
        display: 'inline-block',
        width: 9,
        height: 13,
        lineHeight: 1.45,
        verticalAlign: 'middle',
      }
    },
  },
  projectSelect: {
    width: '100%',
    maxWidth: 250
  },
  taskSelect: {
    paddingLeft: 10,
    maxWidth: 120,
    width: '100%'
  },
  viewDescriptor: {
    paddingTop: 20,
    color: '#657274',
    fontSize: 11
  },
  bold: {
    fontWeight: 600
  }
});

const taskViewOptions = [
  {
    id: 1,
    name: 'All Tasks',
    filter: (user, tasks) => tasks
  },
  {
    id: 2,
    name: 'My Tasks',
    filter: (user, tasks) => tasks.filter((task) => task.assignees.includes(user.id))
  },
]

interface IProps extends WithStyles<typeof styles> {
  user: any,
  calendar: RefObject<FullCalendar>,
  year: number,
  month: number,
  tasks: any[],
  projects: any[],
  selectedProjects: any[],
  onSelectProjects: (val) => void,
  onMonthPrevious: Function,
  onMonthNext: Function,
  onCalEventClick: (val) => void,
}

const Calendar = (props: IProps) => {
  const { classes, projects, selectedProjects, calendar } = props
  const [taskView, setTaskView] = useState(taskViewOptions[0])



  const getProjectNames = (): string => {
    if (!selectedProjects.length) return 'No Projects'
    return selectedProjects.map((i: any) => i.name).join(', ')
  }


  return (
    <div className={classes.root}>
      <div className={classes.header}>

        <div className={classes.headerMonth}>
          {calendar &&
            <React.Fragment>
              <IconButton
                onClick={() => props.onMonthPrevious()}
                className={classes.leftRight}
                size={'small'}
              >
                <ArrowBackIos className={classes.icon} />
              </IconButton>
              <IconButton
                onClick={() => props.onMonthNext()}
                className={classes.leftRight}
                size={'small'}
              >
                <ArrowForwardIos className={classes.icon} />
              </IconButton>
            </React.Fragment>
          }
          <div className={classes.headerDate}>
            <span className={classes.headerDateMonth}>
              {formatMonthHeader(props.year, props.month)}
            </span>
            <span className={classes.headerDateYear}>
              {props.year}
            </span>
          </div>
        </div>

        <div className={classes.headerSelects}>
          {projects && projects.length
            ? (
              <Select textOverflow checkboxes
                value={selectedProjects}
                onSelect={props.onSelectProjects}
                valuesList={projects}
                className={classes.projectSelect}
              />
            )
            : null
          }

          {props.tasks &&
            <Select
              value={taskView}
              onSelect={setTaskView}
              valuesList={taskViewOptions}
              className={classes.taskSelect}
            />
          }
        </div>
      </div>

      <div className={classes.viewDescriptor}>
        <span className={classes.bold}>Displaying: </span>
        <span>{taskView.name}</span>
        <span className={classes.bold}> from </span>
        <span>{getProjectNames()}</span>
      </div>

      <FullCalendar
        defaultView="dayGridMonth"
        plugins={[dayGridPlugin]}
        ref={calendar}
        header={{ left: '', center: '', right: '' }}
        columnHeaderFormat={{ weekday: 'long' }}
        eventSources={[
          {
            events: taskView.filter(props.user, props.tasks),
            className: classes.tasks,
          },
          {
            events: selectedProjects,
            className: classes.projects,
          }
        ]}
        eventClick={(e) => props.onCalEventClick(e)}
        eventLimit
        views={{
          timeGrid: {
            eventLimit: 3
          }
        }}
      />
    </div>
  );
}

export default withStyles(styles)(Calendar)