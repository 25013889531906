import React, { MouseEvent } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';


const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.2,
    color: '#90a0a7',
    display: 'flex',
    alignItems: 'center',
    transform: 'translateX(-6px)',
    cursor: 'pointer'
  },
  icon: {
    fontSize: 20
  },
})

interface IProps extends WithStyles<typeof styles> {
  onClick: (e: MouseEvent<HTMLDivElement>) => any;
  children?: any;
}

const BackButton = (props: IProps) => {
  const { classes } = props;


  return (
    <div className={classes.root} onClick={props.onClick}>
      <ArrowLeftIcon className={classes.icon} />
      <span>{props.children}</span>
    </div>
  )
}


export default withStyles(styles)(BackButton)