import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React from "react";
import { Theme } from "../../theme";
import AddButton from "../AddButton";
import FileHandler from "../handlers/FileHandler";
import FileList from "./FileList";


const styles = (theme: Theme) => createStyles({
  root: {
    maxWidth: 300,
    paddingBottom: 12,
    borderBottom: '1px solid #d9e4e8',
    marginBottom: 12,
    '&:last-child': {
      borderBottom: 0,
      paddingBottom: 0
    }
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  header: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 2.18,
    letterSpacing: 0.53,
    color: '#233539',
    textTransform: 'uppercase',
  },
});

interface IProps extends WithStyles<typeof styles> {
  header: string;
  files?: any[];
  onAdd: Function;
  onDelete: (fileId: number, fileType: number) => any;
  pending: boolean;
  count: number;
  readonly?: boolean;
}

const FileTypeList = (props: IProps) => {
  const { classes } = props;
  let fileInputRef: HTMLInputElement;




  const handleFilesSelect = (e: any) => {
    fileInputRef && fileInputRef.click()
  };



  const onAdd = (newFiles: any) => {
    return props.onAdd?.([...newFiles])
  }



  const onFileHandlerInit = (ref: HTMLInputElement): void => {
    fileInputRef = ref
  }







  return (
    <>
      <FileHandler onFilesSelected={onAdd} onInit={onFileHandlerInit} />

      <div className={classes.root}>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            {props.header}
          </div>
          {!props.readonly && <AddButton onClick={handleFilesSelect} />}
        </div>



        <FileList
          files={props.files ?? []}
          count={props.count ?? 0}
          onDelete={props.onDelete}
          uploadPending={props.pending}
        />

      
      </div>
    </>
  );
}

export default withStyles(styles)(FileTypeList)