import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteComment, updateComment } from '../../../../actions/comments';
import { useShowAll } from '../../../../shared/hooks';
import { arrayRange } from '../../../../utils/utils';
import ShowAll from '../../../ShowAll';
import TaskComment from './comment/TaskComment';
import TaskCommentsHeader from './TaskCommentsHeader';
import TaskNewComment from './TaskNewComment';



interface IProps {
  readonly: boolean;
  className?: any;
  headerClassName?: any;
  isNew?: boolean;
}

const TaskComments = (props: IProps) => {
  const dispatch = useDispatch();
  const showAll = useShowAll()
  const comments = useSelector((state: any) => state.board.comments);
  const [commentsChunk, setCommentsChunk] = useState<any[]>([])





  const dispatchEditComment = (commentId: number, newText: string): Promise<any> => {
    return dispatch(updateComment(commentId, { text: newText }))
  }



  const dispatchDeleteComment = (commentId: number): any => {
    return dispatch(deleteComment(commentId))
  }






  useEffect(() => {
    setCommentsChunk(showAll.value ? comments : arrayRange(0, 3, comments))
  }, [comments, showAll.value])






  const renderComments = (): any => {
    const renderedComments = comments.map((comment: any, idx: number) => (
      <TaskComment key={comment.id}
        idx={idx}
        readonly={props.readonly}
      />
    ))


    return (<div>
      {renderedComments}

      {!!commentsChunk.length && commentsChunk.length > 3 && (
        <ShowAll showAll={showAll} count={comments.length} />
      )}
    </div>)
  }





  return (
    <div className={props.className}>
    
      <TaskCommentsHeader
        className={props.headerClassName}
        count={comments.length}
        readonly={props.readonly}
      />


      {!props.isNew && renderComments()}

      <TaskNewComment isNew={props.isNew}/>

    </div>
  )
}


export default TaskComments