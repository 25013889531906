import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { ContactsIcon } from '../../components/icons/ContactsIcon';
import { DeletedContactsIcon } from '../../components/icons/DeletedContactsIcon';
import { ExistingDuplicatesIcon } from '../../components/icons/ExistingDuplicatesIcon';
import { ImportedDuplicatesIcon } from '../../components/icons/ImportedDuplicatesIcon';
import { ROUTE_CONTACT_MANAGEMENT, ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS, ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES, ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATE_MERGE, ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS, ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES, ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATE_MERGE, ROUTE_DASHBOARD } from '../../constants/routes';
import { Theme } from '../../theme';
import { isAdmin } from '../../utils/user';
import { isRoute } from '../../utils/utils';
import ManagementContainer from '../common/ManagementContainer';
import NewContactsPage from '../emails-management/NewContactsPage';
import DeletedContactsPage from './DeletedContactsPage';
import ImportedDuplicateMergePage from './ImportedDuplicateMergePage';
import ImportedDuplicatesPage from './ImportedDuplicatesPage';
import PossibleDuplicateMergePage from './PossibleDuplicatesMergePage';
import PossibleDuplicatesPage from './PossibleDuplicatesPage';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  content: {
    width: 'calc(100% - 232px)',
    marginLeft: 232
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  
}

const ContactManagementContainer = (props: IProps) => {
  const { classes, history } = props;
  const user = useSelector((state: any) => state.user.user);




  const onInit = (): void => {
    if(!isAdmin(user)) {
      history.push(ROUTE_DASHBOARD);
      return
    }

    if(isRoute(ROUTE_CONTACT_MANAGEMENT, true)) {
      history.push(ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES);
      return
    }
  }






  useEffect(() => {
    onInit();
  }, [])






  const routes = [
    { id: 0, label: 'Imported Duplicates',  icon: ImportedDuplicatesIcon, path: ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES },
    { id: 1, label: 'Possible Duplicates',  icon: ExistingDuplicatesIcon, path: ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES },
    { id: 2, label: 'New Contacts',         icon: ContactsIcon,           path: ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS },
    { id: 3, label: 'Deleted Contacts',     icon: DeletedContactsIcon,    path: ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS }
  ]


  return (
    <ManagementContainer title="Contact Management" tabs={routes}>
      <Switch>



        <Route path={ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATE_MERGE} render={(props: any) => (
          Number.isInteger(+props.match?.params?.id)
            ? <ImportedDuplicateMergePage className={classes.content} id={+props.match.params.id} {...props} />
            : <Redirect to={ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES} />
        )} />

        
        <Route path={ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES} render={(props: any) => <ImportedDuplicatesPage />} />


        <Route path={ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATE_MERGE} render={(props: any) => (
          <PossibleDuplicateMergePage className={classes.content} ids={props.match.params.ids} />
        )} />


        <Route path={ROUTE_CONTACT_MANAGEMENT_POSSIBLE_DUPLICATES} render={(props: any) => <PossibleDuplicatesPage />} />


        <Route path={ROUTE_CONTACT_MANAGEMENT_NEW_CONTACTS} render={() => (
          <NewContactsPage className={classes.content} />
        )} />



        <Route path={ROUTE_CONTACT_MANAGEMENT_DELETED_CONTACTS} render={(props: any) => <DeletedContactsPage />} />



      </Switch>
    </ManagementContainer>
  )
}


export default withStyles(styles)(
  withRouter(ContactManagementContainer)
)