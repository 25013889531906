import { ProviderLabel } from "./models/Project";
import { ISearchRow } from "./models/Search";

export enum EmailType {
  Work = 0,
  Personal = 1,
  Other = 2
}
export const EmailTypeNames: Record<EmailType, string> = {
  [EmailType.Work]: 'Work',
  [EmailType.Personal]: 'Personal',
  [EmailType.Other]: 'Other Email',
};



export enum PhoneType {
  Mobile = 0,
  Office = 1,
  Other = 2
}
export const PhoneTypeNames: Record<PhoneType, string> = {
  [PhoneType.Mobile]: 'Mobile',
  [PhoneType.Office]: 'Office',
  [PhoneType.Other]: 'Other Phone',
};



export enum TaskType {
  ToDo = 1,
  Submitted,
  Approved,
}

export const TaskTypeNames: Record<TaskType, string> = {
  [TaskType.ToDo]: 'To Do',
  [TaskType.Submitted]: 'Submitted',
  [TaskType.Approved]: 'Approved',
}

export enum TaskAction {
  Approve,
  Reject
}



export enum FilesType {
  People,
  Projects,
  Tasks
}

export enum MembershipType {
  Team,
  CapitalProvider
}

export enum TeamMembershipType {
  Owner = 1,
  Client,
  Member,
  Guest
}

export enum ProfileType {
  Admin = 1,
  User,
  Client,
  Guest
}

export enum ProfileTypeLimited {
  User = 2,
  Client,
  Guest
}


export const ProfileTypeNames: Record<ProfileType, string> = {
  [ProfileType.Admin]: 'Admin',
  [ProfileType.User]: 'User',
  [ProfileType.Client]: 'Client',
  [ProfileType.Guest]: 'Guest'
};

export const ProfileTypeNamesLimited: Record<ProfileTypeLimited, string> = {
  [ProfileType.User]: 'User',
  [ProfileType.Client]: 'Client',
  [ProfileType.Guest]: 'Guest'
};

export enum ProgressColor {
  Red,
  Yellow,
  Green,
  RedOpacity,
  YellowOpacity,
  GreenOpacity,
  None
}

export const ProgressColorHex: Record<ProgressColor, string> = {
  [ProgressColor.Red]: '#dd5a1b',
  [ProgressColor.Yellow]: '#ffc143',
  [ProgressColor.Green]: '#15908a',
  [ProgressColor.RedOpacity]: '#f8ded1',
  [ProgressColor.YellowOpacity]: '#fff3d9',
  [ProgressColor.GreenOpacity]: '#d0e9e8',
  [ProgressColor.None]: '#e9eced'
}

export enum ProgressColorText {
  Red,
  Yellow,
  Green,
  RedOpacity,
  YellowOpacity,
  GreenOpacity,
  None
}

export const ProgressColorTextHex: Record<ProgressColor, string> = {
  [ProgressColor.Red]: '#dd5a1b',
  [ProgressColor.Yellow]: '#ffc143',
  [ProgressColor.Green]: '#15908a',
  [ProgressColor.RedOpacity]: '#f8ded1',
  [ProgressColor.YellowOpacity]: '#fff3d9',
  [ProgressColor.GreenOpacity]: '#d0e9e8',
  [ProgressColor.None]: '#90a0a7'
}


export const ProviderColorHex: Record<ProviderLabel, {color: string, backgroundColor: string}> = {
  [ProviderLabel.Contacted]: {
    color: '#4192ec',
    backgroundColor: '#e8f2fd'
  },
  [ProviderLabel.Interested]: {
    color: '#ffc143',
    backgroundColor: '#fff3d9'
  },
  [ProviderLabel.Negotiating]: {
    color: '#dd5a1b',
    backgroundColor: '#f8ded1'
  },
  [ProviderLabel.TermsAgreed]: {
    color: '#15908a',
    backgroundColor: '#d0e9e8'
  },
  [ProviderLabel.Rejected]: {
    color: '#d52023',
    backgroundColor: 'rgba(213, 32, 35, 0.1)'
  },
}

export const ProfileColorHex: Record<ProfileType, {color: string, backgroundColor: string}> = {
  [ProfileType.Admin]: {
    color: '#4159d9',
    backgroundColor: '#dee1f5'
  },
  [ProfileType.User]: {
    color: '#4192ec',
    backgroundColor: '#e8f2fd'
  },
  [ProfileType.Client]: {
    color: '#18ccc4',
    backgroundColor: '#d8f3f1'
  },
  [ProfileType.Guest]: {
    color: '#40cdf4',
    backgroundColor: '#def3f9'
  },
}

export enum PrepareObject {
  ProjectRequest,
  ProjectResponse,
  PersonRequest,
  PersonResponse,
  TaskRequest,
  TaskResponse,
  CPRequest,
  CPResponse,
  PossibleDuplicatesRequest,
  PossibleDuplicatesResponse,
}

export enum CurrencyTypes {
  BririshPound = 1
}

export const Currencies: Record<CurrencyTypes, string> = {
  [CurrencyTypes.BririshPound]: '£'
}

export enum DateFormat {
  DateTime,
  Date,
  DateShort,
  Time
}

export const DateFormatFormula: Record<DateFormat, string> = {
  [DateFormat.DateTime]: 'YYYY-MM-DDT23:59:59',
  [DateFormat.Date]: 'YYYY-MM-DD',
  [DateFormat.DateShort]: 'DD MMM YYYY',
  [DateFormat.Time]: 'HH:mm:ss'
}

export enum SearchType {
  People = 1,
  Projects,
  Tasks,
  Company,
  Activity,
}

export enum TableType {
  PEOPLE = 1,
  PROJECTS,
  TASKS,
  CAPITAL_PROVIDERS,
  IMPORTED_DUPLICATES,
  POSSIBLE_DUPLICATES,
  DELETED_CONTACTS,
  NEW_CONTACTS,
  ACTIVITY,
  DRAFTS,
  TEMPLATES,
  CLOSING_TASKS,
}

export const TableTypeSelector = {
  [TableType.PEOPLE]: 'people',
  [TableType.PROJECTS]: 'projects',
  [TableType.TASKS]: 'tasks',
  [TableType.CAPITAL_PROVIDERS]: 'capitalProviders',
  [TableType.IMPORTED_DUPLICATES]: 'importedDuplicates',
  [TableType.POSSIBLE_DUPLICATES]: 'possibleDuplicates',
  [TableType.DELETED_CONTACTS]: 'deletedContacts',
  [TableType.NEW_CONTACTS]: 'newContacts',
  [TableType.ACTIVITY]: 'activity',
  [TableType.DRAFTS]: 'drafts',
  [TableType.TEMPLATES]: 'templates',
  [TableType.CLOSING_TASKS]: 'closingTasks',
}


export const TableTypeNames: Record<string, string> = {
  [TableType.PEOPLE]: 'Contacts',
  [TableType.PROJECTS]: 'Projects',
  [TableType.TASKS]: 'Tasks',
  [TableType.CAPITAL_PROVIDERS]: 'Capital Providers',
  [TableType.IMPORTED_DUPLICATES]: 'Imported Duplicates',
  [TableType.POSSIBLE_DUPLICATES]: 'Possible Duplicates',
  [TableType.DELETED_CONTACTS]: 'Deleted Contacts',
  [TableType.NEW_CONTACTS]: 'New Contacts',
  [TableType.ACTIVITY]: 'Activity',
  [TableType.DRAFTS]: 'Drafts',
  [TableType.TEMPLATES]: 'Templates',
  [TableType.CLOSING_TASKS]: 'Closing Tasks',
}


export enum PrintType {
  PEOPLE = 1,
  PROJECTS,
  TASKS,
  CAPITAL_PROVIDERS,
  ORGANIZATIONS,
  ACTIVITY,
}

export const TableTypeFromPrint = {
  [PrintType.PEOPLE]: TableType.PEOPLE,
  [PrintType.PROJECTS]: TableType.PROJECTS,
  [PrintType.TASKS]: TableType.TASKS,
  [PrintType.CAPITAL_PROVIDERS]: TableType.CAPITAL_PROVIDERS,
  [PrintType.ACTIVITY]: TableType.ACTIVITY,
}

export const TableTypeFromSearch = {
  [SearchType.People]: TableType.PEOPLE,
  [SearchType.Projects]: TableType.PROJECTS,
  [SearchType.Tasks]: TableType.TASKS
}


export const TableSelectorFromSearch = {
  [SearchType.People]: TableTypeSelector[TableType.PEOPLE],
  [SearchType.Projects]: TableTypeSelector[TableType.PROJECTS],
  [SearchType.Tasks]: TableTypeSelector[TableType.TASKS]
}


export const SearchTitleFromSearch = {
  [SearchType.People]: 'Contacts',
  [SearchType.Projects]: 'Projects',
  [SearchType.Tasks]: 'Tasks',
  [SearchType.Company]: 'Companies',
}


export enum FilterType {
  REGULAR,
  DATE_RANGE,
}

export enum DateRangeDestination {
  FROM,
  TO
}

export enum TextInputType {
  PERCENTAGE,
  WEBSITE,
  CURRENCY,
  EMAIL,
  CUSTOM_ADORNMENT
}



export const EditablePersonField: Record<string, string> = {
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  title: 'Title',
  suffix: 'Suffix',
  profile_type: 'User Type',
  categories: 'Contact Categories',
  subcategories: 'Subcategories',
  client_class: 'Client Class',
  job_title: 'Job Title',
  company: 'Company',
  department: 'Department',
  website: 'Web Page',
  previous_company: 'Previous Company',
  rating: 'Rating',
  legal_status: 'Legal Status',
  bio: 'Bio',
  spouse: 'Spouse',
  birthday: 'Birthday',
  nickname: 'Nickname',
  tags: 'Tags',
  emails: 'Email Addresses',
  phones: 'Phone Numbers',
  street: 'Business Street',
  // street2: 'Business Street 2',
  city: 'Business City',
  postcode: 'Business Postal Code',
  // county: 'Business State',
  country: 'Business Country/Region',
  home_street: 'Home Street',
  home_city: 'Home City',
  // home_state: 'Home State',
  home_postcode: 'Home Postal Code',
  home_country: 'Home Country/Region'
}


export const DuplicatablePersonField: Record<string, string> = {
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  title: 'Title',
  suffix: 'Suffix',
  categories: 'Contact Categories',
  subcategories: 'Subcategories',
  use_classes: 'Use Classes',
  locations: 'Locations',
  client_class: 'Client Class',
  job_title: 'Job Title',
  company: 'Company',
  department: 'Department',
  website: 'Web Page',
  previous_company: 'Previous Company',
  rating: 'Rating',
  legal_status: 'Legal Status',
  bio: 'Bio',
  spouse: 'Spouse',
  birthday: 'Birthday',
  nickname: 'Nickname',
  emails: 'Email Addresses',
  phones: 'Phone Numbers',
  street: 'Business Street',
  // street2: 'Business Street 2',
  city: 'Business City',
  postcode: 'Business Postal Code',
  // county: 'Business State',
  country: 'Business Country/Region',
  home_street: 'Home Street',
  home_city: 'Home City',
  // home_state: 'Ho\me State',
  home_postcode: 'Home Postal Code',
  home_country: 'Home Country/Region'
}


export const DuplicatableCPField: Record<string, string> = {
  cp_subcategory_types: 'Capital Provider Description',
  investment_classes: 'Investment Class',
  investing_geographies: 'Investing in',
  is_debt: 'Is Debt',
  is_development: 'Is Development',
  is_equity: 'Is Equity',
  is_land: 'Is Land',
  is_prs: 'Is PRS',
  is_sharia: 'Is Sharia',
  max_ltc: 'Max LTC',
  max_ltgdv: 'Max LTGDV',
  max_ltv: 'Max LTV',
  max_size: 'Max Size',
  max_term: 'Max Term',
  min_irr: 'Min IRR',
  min_roe: 'Min ROE',
  min_size: 'Min Size',
  min_term: 'Min Term',
}

export enum Email {
  WORK,
  HOME,
  OTHER
}


export const EmailTypes = [
  {id: Email.WORK, name: 'Work' },
  {id: Email.HOME, name: 'Home' },
  {id: Email.OTHER, name: 'Other' }
];




export enum Phone {
  MOBILE,
  WORK,
  WORK_2,
  HOME,
  HOME_2,
  BUSINESS_FAX,
  OTHER
}


export const PhoneTypes = [
  {id: Phone.MOBILE, name: 'Mobile' },
  {id: Phone.WORK, name: 'Work' },
  {id: Phone.WORK_2, name: 'Work 2' },
  {id: Phone.HOME, name: 'Home' },
  {id: Phone.HOME_2, name: 'Home 2' },
  {id: Phone.BUSINESS_FAX, name: 'Fax' },
  {id: Phone.OTHER, name: 'Other' }
];





export const ValidationError: Record<string, string> = {
  emails: 'Email address is not valid'
}


export enum InputType {
  DATE,
}


export const REQUEST_CANCEL = 'REQUEST_CANCEL';


export enum TinymceType {
  DEFAULT,
  INDIVIDUAL_EMAIL,
  COMPANY_EMAIL
}



export enum EmailFilterOption {
  ALL_TIME,
  LAST_30_DAYS
}


export const EmailFilterіOptionName: Record<EmailFilterOption, string> = {
  [EmailFilterOption.ALL_TIME]: 'All Time',
  [EmailFilterOption.LAST_30_DAYS]: '30 Days'
}



export const SELECT_ITEM_NONE_ID = -1;


export enum AutocompleteType {
  People = 1,
  Projects,
  Companies,
  Countries,
  Tags
}


export enum MultiInputType {
  EMAILS,
  PHONES
}


export const INITIAL_SEARCH_ROW = {
  type: null,
  field: null,
  condition: null,
  value: null
} as ISearchRow



export const TABLE_SUM_ROWS = [
  'capital', 'valuation', 'term_sheet_fee', 'fee', 'total_fees'
]