import { Menu } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { addMember, changeMemberByPerson, changePersmissions, preAssignMember, removeMember, removePreAssignedMember } from '../../../actions/people/membership';
import { useAnchor } from '../../../shared/hooks';
import { TeamMembershipType } from '../../../shared/types';
import { Theme } from '../../../theme';
import { isClient, isGuest } from '../../../utils/user';
import { PersonIcon } from '../../icons/PersonIcon';
import MenuOption from '../../MenuOption';
import { getCategoryLabel } from '../../../utils/utils';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 5px',
    textDecoration: 'unset',
    color: '#233539',
    transition: 'all .1s ease',
    '&:hover': {
      padding: 10,
      backgroundColor: '#f7f9fa',
      color: '#4192ec',
      cursor: 'pointer',
      '& $label': {
        display: 'none'
      },
      '& $edit': {
        display: 'flex',
      }
    },
  },
  label: {

  },
  readonly: {
    '&:hover': {
      padding: '10px 5px',
      backgroundColor: '#fff',
      '& $label': {
        display: 'none'
      },
      '& $edit': {
        display: 'none',
      }
    }
  },
  itemLabel: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: 1.5,
      '&:first-child': {
        color: 'inherit',
        fontWeight: 500
      },
      '&:last-child': {
        color: '#657274'
      }
    }
  },
  moreIcon: {
    fontSize: '26px',
    color: '#657274',
    cursor: 'pointer',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  icon: {
    fontSize: 14,
    margin: '4px 10px 0 0',
  },
  edit: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  key: any;
  id: number;
  person: any;
  showOwnerOptions: boolean;
  state?: TeamMembershipType;
  readonly?: boolean;
  noLink?: boolean;
}

const MembershipItemTeam = (props: IProps) => {
  const { classes, person } = props;
  const anchor = useAnchor();
  const dispatch = useDispatch();
  const types = useSelector((state: any) => state.types);
  const projectId = useSelector((state: any) => state.projects.project.id);



  const isOwner = (): boolean => {
    return props.state === TeamMembershipType.Owner
  }

  const isAdded = (): boolean => {
    return !!props.state
  }

  const getFirstOption = (): object => {
    return isAdded()
      ? {
        label: isOwner() ? 'Make Member' : 'Make Owner',
        onClick: (e: any) => {
          e.stopPropagation();
          anchor.close();
          isOwner() ? makeMember() : makeOwner()
        }
      }
      : {
        label: 'Make Owner',
        onClick: (e: any) => {
          e.stopPropagation();
          anchor.close();
          makeOwner()
        }
      }
  }

  const getSecondOption = (): object => {
    return isAdded()
      ? {
        label: 'Remove',
        onClick: (e: any) => {
          e.stopPropagation();
          anchor.close();
          deleteMember()
        }
      }
      : {
        label: 'Make Member',
        onClick: (e: any) => {
          e.stopPropagation();
          anchor.close();
          makeMember()
        }
      }
  }

  const ownerOptions = (isGuest(person) || isClient(person))
    ? [
      getSecondOption()
    ]
    : [
      getFirstOption(),
      getSecondOption()
    ]

  const getBody = (stateId: TeamMembershipType): object => ({
    project: projectId,
    person: person.id,
    state: stateId
  });

  const getPreAssignedBody = (stateId: TeamMembershipType): object => ({
    person: person,
    state: stateId
  })

  const makeMemberForNewProject = (): void => {
    isAdded()
      ? dispatch(changeMemberByPerson(person.id, TeamMembershipType.Member))
      : dispatch(preAssignMember(getPreAssignedBody(TeamMembershipType.Member)))
  }

  const makeMemberForProject = (): void => {
    props.id
      ? dispatch(changePersmissions(props.id, TeamMembershipType.Member))
      : dispatch(addMember(getBody(TeamMembershipType.Member)))
  }

  const makeMember = (): void => {
    projectId
      ? makeMemberForProject()
      : makeMemberForNewProject()
  }

  const makeOwnerForNewProject = (): void => {
    isAdded()
      ? dispatch(changeMemberByPerson(person.id, TeamMembershipType.Owner))
      : dispatch(preAssignMember(getPreAssignedBody(TeamMembershipType.Owner)))
  }

  const makeOwnerForProject = (): void => {
    props.id
      ? dispatch(changePersmissions(props.id, TeamMembershipType.Owner))
      : dispatch(addMember(getBody(TeamMembershipType.Owner)))
  }

  const makeOwner = (): void => {
    projectId
      ? makeOwnerForProject()
      : makeOwnerForNewProject()
  }

  const deleteMember = (): void => {
    props.id
      ? dispatch(removeMember(props.id))
      : dispatch(removePreAssignedMember(person.id))
  }

  const getTitle = (): string => {
    return `${person.first_name} ${person.last_name} ${isOwner() ? '(Owner)' : ''}`
  }

  const getCaption = (): string => {
    const contactType = getCategoryLabel(person);
    return `${person.company}${contactType ? `, ${contactType}` : ''}`
  }

  const onClickPerson = (e: any) => {
    e.stopPropagation()
    if (e.ctrlKey || e.metaKey) {
      window.open(`/contacts/${person.id}`)
      return
    }
    props.history.push(`/contacts/${person.id}`)
  }

  return (
    <div 
      key={props.key} 
      className={classNames(classes.root, { [classes.readonly]: props.readonly })}
    >
      <div className={classes.wrapper} onClick={!props.noLink ? onClickPerson : undefined}>
        <PersonIcon className={classes.icon} type={person.profile_type} />
        <div className={classes.itemLabel}>
          <span>{getTitle()}</span>
          <span>{getCaption()}</span>
        </div>
      </div>

      {props.showOwnerOptions
        ? (
          <div className={classes.edit}>
            <MoreHorizIcon className={classes.moreIcon} onClick={(e) => { e.stopPropagation(); anchor.set(e) }} />
            <Menu
              id="long-menu"
              anchorEl={anchor.value}
              keepMounted
              open={!!anchor.value}
              onClose={anchor.close}
              PaperProps={{
                style: {
                  boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
                  borderRadius: 0
                },
              }}
            >
              {ownerOptions.map((option: any, idx: number) => (
                <MenuOption key={option.label} onClick={option.onClick}>
                  {option.label}
                </MenuOption>
              ))}
            </Menu>
          </div>
        )
        : null
      }
    </div>
  )
}



export default withRouter(withStyles(styles)(MembershipItemTeam))