import * as React from "react";
import {Theme} from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import CapitalProviderInfoCard from "./CapitalProviderInfoCard";
import TaskCard from "./TaskCard";
import { IPerson } from "../shared/models/Person";
import classNames from 'classnames';
import FileCard from "./files/FileCard";
import ProjectCard from "./ProjectCard";
import { useSelector } from "react-redux";

const styles = (theme: Theme) => createStyles({
  root: {

  },
  dataCard: {
    width: 'unset'
  },
  cardContainer: {
    marginBottom: 30,
  },
  capitalProviderCard: {
    opacity: 1,
    transition: theme.transitions.create(
      ['max-height', 'opacity', 'transform', 'padding']
    ),
    transform: 'scaleY(1)',
    transformOrigin: '50% 0%'
  },
  capitalProviderCardHidden: {
    opacity: 0,
    maxHeight: 0,
    transform: 'scaleY(0)',
    marginBottom: 0
  }
});

interface IProps extends WithStyles<typeof styles> {
  showCapitalProviderInfo?: boolean;
  person: IPerson;
  capitalProvider: any;
  selectTypes?: any;
  onCapitalProviderChange: any;
  readonly?: boolean;
  newUser?: boolean;
}

const AdditionalCard = (props: IProps) => {
  const { classes } = props;
  const types = useSelector((state: any) => state.types.user_file_types);



  return (
    <div className={classes.root}>
      <div className={classNames(
        classes.cardContainer,
        classes.capitalProviderCard,
        {[classes.capitalProviderCardHidden]: !props.showCapitalProviderInfo}
      )}>
        {props.showCapitalProviderInfo
          ? (
              <CapitalProviderInfoCard
                capitalProvider={props.capitalProvider}
                selectTypes={props.selectTypes}
                onChange={props.onCapitalProviderChange}
                classes={{dataCard: classes.dataCard}}
                readonly={props.readonly}
              />
            )
          : null
        }
      </div>
      <div className={classes.cardContainer}>
        <ProjectCard memberships={props.person.project_memberships} providers={props.person.provider_relations} />
      </div>
      <div className={classes.cardContainer}>
        <TaskCard />
      </div>
      <div className={classes.cardContainer}>
        <FileCard
          readonly={props.newUser}
          relationId={props.person.id}
          fileTypes={types}
        />
      </div>
    </div>
  );
  
}

export default withStyles(styles)(AdditionalCard)