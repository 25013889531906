import { IPerson } from "./Person";
import { INote } from "../interfaces";

export enum ProviderLabel {
  Contacted = 1,
  Interested,
  Negotiating,
  TermsAgreed,
  Rejected
}

export const ProjectLabelNames = {
  [ProviderLabel.Contacted]: 'Contacted',
  [ProviderLabel.Interested]: 'Interested',
  [ProviderLabel.Negotiating]: 'Negotiating',
  [ProviderLabel.TermsAgreed]: 'Terms Agreed',
  [ProviderLabel.Rejected]: 'Rejected',
}

export enum ProjectStatus {
  Analysing = 1,
  Mandated = 2,
  CapitalProviderOutreach = 3,
  ClosingProcess = 4,
  AwaitingPayment = 5,
  Closed = 6,
}

export const ProjectStatusLabel = {
  [ProjectStatus.Analysing]: 'Analysing',
  [ProjectStatus.Mandated]: 'Mandated',
  [ProjectStatus.CapitalProviderOutreach]: 'Capital Provider Outreach',
  [ProjectStatus.ClosingProcess]: 'Closing Process',
  [ProjectStatus.AwaitingPayment]: 'Awaiting Payment',
  [ProjectStatus.Closed]: 'Closed',
}

export enum ProjectType {
  LandBridge = 1, 
  Repositioning = 2,
  Development = 3,
  Investment = 4
}

export const ProjectTypeLabel = {
  [ProjectType.LandBridge]: 'Land Bridge',
  [ProjectType.Repositioning]: 'Repositioning',
  [ProjectType.Development]: 'Development',
  [ProjectType.Investment]: 'Investment',
}

export enum MembershipState {
  All = 0,
  Owner = 1,
  Client = 2,
  Member = 3,
  Guest = 4
}

export const MembershipStateLabel = {
  [MembershipState.All]: 'All',
  [MembershipState.Owner]: 'Owner',
  [MembershipState.Client]: 'Client',
  [MembershipState.Member]: 'Member',
  [MembershipState.Guest]: 'Guest',
}

export interface ITeamMember {
  id: number;
  person: IPerson;
  state: MembershipState;
}

export enum TransactionType {
  Debt = 1,
  Equity = 2
}

export const TransactionTypeLabel = {
  [TransactionType.Debt]: 'Debt',
  [TransactionType.Equity]: 'Equity',
}

export enum TransactionSubType {
  DebtSyndication, 
  EquitySyndication, 
  FundSyndication, 
  AcquisitionDisposition, 
  Events
}

export const TransactionSubTypeLabel = {
  [TransactionSubType.DebtSyndication]: 'Debt Syndication',
  [TransactionSubType.EquitySyndication]: 'Equity Syndication',
  [TransactionSubType.FundSyndication]: 'Fund Syndication',
  [TransactionSubType.AcquisitionDisposition]: 'Acquisition Disposition',
  [TransactionSubType.Events]: 'Events',
}

export enum AssetType {
  Office, 
  Retail, 
  Industrial, 
  Storage, 
  HotelHostel, 
  Residential, 
  SeniorLiving, 
  Hospital, 
  Gaming, 
  CarPark
}

export const AssetTypeLabels = {
  [AssetType.Office]:       'Office',
  [AssetType.Retail]:       'Retail',
  [AssetType.Industrial]:   'Industrial',
  [AssetType.HotelHostel]:  'Hotel Hostel',
  [AssetType.Residential]:  'Residential',
  [AssetType.SeniorLiving]: 'Senior Living',
  [AssetType.Hospital]:     'Hospital',
  [AssetType.Gaming]:       'Gaming',
  [AssetType.CarPark]:      'Car Park'
}

export interface IProject {
  id?: number;
  name: string;
  client: string;
  closing_date: string;
  capital_required: number;
  recent_valuation: number;
  term_sheet_fee: number;
  fees: number;
  currency: number; // TODO create enum
  progress: any;

  status: ProjectStatus;
  asset_types: AssetType[];
  
  cp_summary: any[];
  columns: any[];
  tags: string[];
  team: number[];
  provider_relations: any[];
  notes: INote[];
  members: ITeamMember[];
  project_types: ProjectType[];
  transaction_types: TransactionType[];
  transaction_sub_types: TransactionSubType[];
  capital_providers: any[];
}

export const initialProject = {
  name: '',
  client: '',
  closing_date: '',
  capital_required: 0,
  recent_valuation: 0,
  term_sheet_fee: 0.0,
  fees: 0,
  currency: 1,
  progress: null,

  status: ProjectStatus.Analysing,
  asset_types: [AssetType.Office],

  cp_summary: [],
  columns: [],
  tags: [],
  team: [],
  provider_relations: [],
  notes: [],
  members: [],
  project_types: [],
  transaction_types: [],
  transaction_sub_types: [],
  capital_providers: []
} as IProject

export const SelectableProjectFields = [
  'project_types',
  'transaction_types',
  'transaction_sub_types',
  'currency',
  'asset_types',
  'status',
]

export const SelectableProjectTypes = {
  project_types: 'project_types',
  transaction_types: 'transaction_sub_types',
  transaction_sub_types: 'transaction_sub_types',
  asset_types: 'asset_types',
  status: 'project_status_types'
}

export interface IColSummary {
  column_name: string;
  num_tasks: number;
  progress: number;
}

export interface ISummary {
  id: number;
  total_progress: number;
  columns: IColSummary[];
}