import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import Button, { ButtonSize } from './Button';
import { useSelector, useDispatch } from 'react-redux';
import { isUser, isAdmin } from '../utils/user';
import { useAnchor } from '../shared/hooks';
import { Popover } from '@material-ui/core';
import { invitePerson } from '../actions/people/people';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  button: {
    background: '#90a0a7',
    color: '#fff',
    opacity: 0.7,
    cursor: 'pointer',
    border: '1px solid transparent',
    '&:hover, &$active': {
      borderColor: '#606c71',
      boxShadow: '0 0 5px 1px #606c71',
      background: '#90a0a7',
    }
  },
  active: {

  },
  popover: {

  },
  popoverPaper: {
    display: 'flex',
    boxShadow: '0 8px 16px 10px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    zIndex: 1000,
    overflow: 'unset',
    '&:after': {
      width: 0,
      height: 0,
      content: '""',
      borderTop: '6px solid #fff',
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      bottom: 0,
      left: '50%',
      top: 'calc(100% - 1px)',
      position: 'absolute',
      transform: 'translate(-50%, 0)',
    }
  },
  option: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.82,
    color: theme.palette.primary.main,
    padding: '7px 15px',
    cursor: 'pointer',
    transition: 'background .1s ease',
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
    '&:hover': {
      background: 'linear-gradient(180deg, rgba(232,242,253,1) 0%, rgba(255,255,255,1) 100%)',
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  personId: number;
  className?: string;
}

const PersonTypeChipPending = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const anchor = useAnchor();


  const togglePopover = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    anchor.set(e);
  }

  const onPopoverClose = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    anchor.close();
  }

  // TODO change invite request to proper resend one
  const resendInvite = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    dispatchInvitePerson().finally(() => anchor.close())
  }

  const dispatchInvitePerson = (): any => {
    return dispatch(invitePerson(props.personId))
  }

  const renderPopover = (): any => {
    if(!isAdmin(user) && !isUser(user)) return null;

    return (
      <Popover
        disableRestoreFocus
        className={classes.popover}
        classes={{ paper: classes.popoverPaper }}
        open={!!anchor.value}
        anchorEl={anchor.value}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={onPopoverClose}
      >
        <span className={classes.option} onClick={resendInvite}>Resend invite</span>
      </Popover>
    )
  }



  return (
    <div className={classNames(classes.root, props.className)}>
      <Button
        primary
        size={ButtonSize.Tiny}
        onClick={togglePopover}
        className={classNames(
          classes.button,
          {[classes.active]: !!anchor.value}
        )}
      >
        Pending
      </Button>
      {renderPopover()}
    </div>
  )
}


export default withStyles(styles)(PersonTypeChipPending)