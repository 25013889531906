import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useMemo } from 'react';
import { DateFormat } from '../../../../shared/types';
import { Theme } from '../../../../theme';
import DateInput from '../../../DateInput';
import { useSearchData } from '../hooks/advanced-search-hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    minWidth: 250,
    maxWidth: 250
  }
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
  [otherProps: string]: any;
}

const SearchSelectValueDateInput = (props: IProps) => {
 const { classes, idx, subRowIdx, ...otherProps } = props;
 const { currentRow, onChange } = useSearchData(idx, subRowIdx);




 const handleChange = useCallback((value: string|number) => {
   return onChange({ value })
 }, [])
 
 

  
  return useMemo(() => (
    <DateInput
      yearSelection
      value={currentRow.value}
      className={classes.root}
      placeholder="Select Date"
      format={DateFormat.Date}
      onChange={handleChange}
      {...otherProps}
    />
  ), [currentRow.value, handleChange])
}


export default withStyles(styles)(SearchSelectValueDateInput)