let gaId: string|null = ''

switch (window.location.hostname) {
  case 'ft8.com':
    gaId = 'UA-154683942-1'
    break;

  case 'dev.ft8.com':
    gaId = 'UA-154683942-2'
    break;

  default:
    gaId = 'UA-154683942-3'
    break;
}


export const GA_ID = gaId;