import { Dialog, LinearProgress, withStyles, WithStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import classNames from 'classnames'
import React from 'react';
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BaseCard from "../../../components/BaseCard";
import Button from "../../../components/Button";
import { ModalContent } from "../../../components/modal/ModalContent";
import { ModalFooter } from "../../../components/modal/ModalFooter";
import { ModalHeader } from "../../../components/modal/ModalHeader";
import { Theme } from "../../../theme";
import AssignPeopleToProjectForm from "./AssignPeopleToProjectForm";
import { NotificationTypes } from "../../../actions/common/notifications";
import { assignPeopleToProjectPromise } from "../../../actions/people/people";
import { isRoute } from "../../../utils/utils";
import { ROUTE_SEARCH_PEOPLE_ASSIGN_TO_PROJECT } from "../../../constants/routes";


const styles = (theme: Theme) => createStyles({
    dialog: {
        maxWidth: 450,
        width: '100%',
        position: 'relative',
        zIndex: 4,
        overflow: 'unset',
    },
    modal: {
        paddingBottom: 60,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 4,
        overflow: 'unset',
        borderRadius: 4
    },
    modalHeader: {
        borderBottom: 0
    },
    modalContent: {
        paddingTop: 0,
        justifyContent: 'center',
        overflow: 'initial',
        flexDirection: 'column'
    },
    modalFooter: {
        borderTop: 0,
        justifyContent: 'center',
        borderRadius: '0 0 4px 4px',
    },
    description: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        lineHeight: 1.71,
        fontWeight: 300,
        color: '#657274',
        marginBottom: 10,
        justifyContent: 'center',
        display: 'flex',
    },
    button: {
        fontFamily: 'Montserrat',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 2,
        height: 40,
        padding: '8px 16px',
    },
    buttonSecondary: {
        border: `1px solid ${theme.palette.primary.main}`,
        marginRight: 15
    },
});

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
    dispatch: Function,
    store: any,
}

const AssignPeopleToProjectModal = (props: IProps) => {

    const {history, dispatch, store, classes} = props;

    const {loading} = store;

    const goBack = () => {
        if(isRoute(ROUTE_SEARCH_PEOPLE_ASSIGN_TO_PROJECT)) {
            history.push('/search/contacts');
        } else {
            history.push('/contacts');
        }
    };

    const onApplyChanges = () => {
        if (loading) return;

        dispatch(assignPeopleToProjectPromise(store)).then((notification)=>{

            if (notification.notificationType === NotificationTypes.SUCCESS){

                goBack();
            }

        })
    };

    return <Dialog open={true} onClose={goBack} maxWidth={'xl'} PaperProps={{ classes: {root: classes.dialog } }} >

        <BaseCard extraClass={classes.modal}>
            {loading && <LinearProgress />}
            <ModalHeader className={classes.modalHeader}>
                Select a Project
            </ModalHeader>

            <ModalContent className={classes.modalContent}>
                <span className={classes.description}>Contacts will be added to the project you choose as Members</span>
                <AssignPeopleToProjectForm />
            </ModalContent>

            <ModalFooter className={classes.modalFooter}>
                <Button
                    onClick={goBack}
                    className={classNames(classes.button, classes.buttonSecondary)}
                >
                    Cancel
                </Button>
                <Button primary onClick={onApplyChanges} className={classes.button}>Continue</Button>
            </ModalFooter>

        </BaseCard>


    </Dialog>
};


const mapStateToProps = state => {

    return {
        store: state.assignPeopleToProject
    }
};


export default
connect(mapStateToProps)(
    withStyles(styles)(
        withRouter(AssignPeopleToProjectModal)
    )
);