import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { syncUserSettings } from '../../actions/people/people';
import { ARCHIVED_SEARCH } from '../../constants/index';








const UserConfig = () => {
  const dispatch = useDispatch();
  const tablePrefs = useSelector((state: any) => state.user.user.table_column_prefs);



  useEffect(() => {

    if(!!tablePrefs?.[ARCHIVED_SEARCH]) {
      return
    }

    console.log('Syncing...')
    dispatch(syncUserSettings())
  }, [tablePrefs])


  return null
}


export default UserConfig