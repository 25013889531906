import { CircularProgress } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { MouseEvent } from 'react';
import { IShowAll } from '../shared/interfaces';
import { Theme } from '../theme';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.6,
    textAlign: 'center',
    color: theme.palette.primary.main,
    margin: '10px 0 0px',
    '& > span': {
      cursor: 'pointer',
      borderBottom: '1px solid transparent',
      transition: 'border-color .1s ease',
      '&:hover': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  alignCenter: {
    justifyContent: 'center',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  alignLeft: {
    justifyContent: 'flex-start',
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
})

interface IProps extends WithStyles<typeof styles> {
  showAll: IShowAll;
  count?: number;
  alignRight?: boolean;
  alignLeft?: boolean;
  label?: string;
  isOverflowing?: boolean;
  isLoading?: boolean;
  className?: any;
}

const ShowAll = (props: IProps) => {
  const { classes, isOverflowing = true } = props;
  const label = props.label || `Show All${props.count ? ` (${props.count})` : ''}...`;




  const onToggle = (e: MouseEvent<HTMLDivElement>): void => {
    props.showAll.toggle()
  }




  if(props.isLoading) {
    return (
      <div className={classNames(
        classes.loadingWrapper,
        {[props.className]: !!props.className}
      )}>
        <CircularProgress size={20} />
      </div>
    )
  }



  if(!isOverflowing) {
    return null
  }




  return ( 
    <div onClick={onToggle}
      className={classNames(
        classes.root,
        {
          [classes.alignCenter]: !props.alignRight,
          [classes.alignRight]: props.alignRight,
          [classes.alignLeft]: props.alignLeft,
          [props.className]: !!props.className
        }
      )}
    >
      <span>
        {props.showAll.value ? 'Collapse' : label}
      </span>
    </div>
  )
}


export default withStyles(styles)(ShowAll)