import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { getProgressBgColor, getProgressColor } from '../utils/utils';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    borderRadius: 10,
    width: '100%',
    height: 20,
  },
  span: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 10,
  },
  spanMinWidth: {
    minWidth: 20
  },
  small: {
    height: 10,
    width: 75
  }
})

interface IProps extends WithStyles<typeof styles> {
  progress: number
  className?: any
  small?: boolean
}

const ProgressLine = (props: IProps) => {
  const { classes, progress } = props;

  return (
    <div
      className={classNames(
        classes.root, 
        props.className,
        {[classes.small]: props.small},
      )}
      style={{backgroundColor: getProgressBgColor(progress)}}
    >
      <span
        className={classNames(
          classes.span,
          {[classes.spanMinWidth]: !!progress}
        )}
        style={{width: `${progress}%`, backgroundColor: getProgressColor(progress)}}
      />
    </div>
  )
}


export default withStyles(styles)(ProgressLine)