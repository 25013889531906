import React, { useMemo } from 'react';
import TextInput from '../../TextInput';
import { usePersonData } from '../hooks/people-hooks';
import { EntityField } from '../../../shared/interfaces';


const ContactInfoInput = ({ name, ...otherProps }: EntityField) => {
  const { value, error, readonly, onChange } = usePersonData(name);



  return useMemo(() => (
    <TextInput
      value={value}
      readonly={readonly}
      onChange={onChange}
      error={error}
      hidden={!value && readonly}
      {...otherProps}
    />
  ), [readonly, value, error])
}


export default ContactInfoInput