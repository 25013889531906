import * as actions from '../actions/email-drafts';

export const initialState = {
  results: [] as any,
  count: 0,
  next: null,
  previous: null,
  currentDrafts: {},
  initiated: false,
  loading: false,
  loadingFiles: false,
};

export default function(state = initialState, action) {
  switch (action.type) {

    
    case actions.SET_DRAFTS:
      return {
        ...state,
        ...action.drafts,
        initiated: true
      }
  

    case actions.APPEND_DRAFTS:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }
    

    case actions.REMOVE_MULTIPLE_DRAFTS:
      return {
        ...state,
        results: state.results.filter((item: any) => !action.ids.includes(item.id)),
        count: state.count - action.ids.length
      }


    case actions.REMOVE_DRAFT:
      if(!action?.id) return state;
      return {
        ...state,
        results: state.results.filter((item: any) => item.id !== action.id),
        count: --state.count
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }


    case actions.SET_LOADING_FILES:
      return {
        ...state,
        loadingFiles: action.state
      }


    default:
      return state
  }
}