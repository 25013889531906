import {withStyles} from "@material-ui/core";
import {Theme} from "../../theme";
import {createStyles} from "@material-ui/styles";
import * as React from "react";
import classNames from 'classnames';



const styles = (theme: Theme) => createStyles({
    root: {

        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Montserrat',
        fontSize: 16,
        color: '#233539',
        margin: 0,
        padding: 12,
        lineHeight: 1.5,
        fontWeight: 500,
        borderBottom: '1px solid #d9e4e8',


    },
});


const ModalHeaderComponent = ({children, classes, className=''}) => {

    return <div className={classNames(classes.root, className)}>
        {children}
    </div>
};



export const ModalHeader = withStyles(styles)(ModalHeaderComponent);