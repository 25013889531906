import { useDispatch, useSelector } from "react-redux";
import { changeProjectField } from "../../../actions/projects/projects";

export const useProjectData = (name: string): any => {
  const dispatch = useDispatch();
  const { project, updatedProject, validationErrors = {}, projectView: { readonly } } = useSelector((state: any) => state.projects);
  const value = updatedProject[name] !== undefined ? updatedProject[name] : project[name];
  const error = (!!validationErrors[name] && !!Object.keys(validationErrors[name]).length && validationErrors[name]) || project.errors?.[name];



  const onChange = (val: any): any => {
    return dispatch(changeProjectField({ [name]: val }))
  }



  return { value, error, project, readonly, dispatch, updatedProject, onChange, validationErrors }
}