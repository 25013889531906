import { CircularProgress, Popover } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAnchor } from '../shared/hooks';
import { Theme } from '../theme';
import { goToOutlook } from '../utils/utils';
import { OutlookIcon } from './icons/OutlookIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  icon: {
    fontSize: 20,
    color: '#d52023',
    cursor: 'pointer'
  },
  iconActive: {
    color: '#0070C9'
  },
  loading: {
    
  },
  popover: {

  },
  popoverPaper: {
    padding: 15,
    borderRadius: 0,
    width: 159,
    minWidth: 'max-content',
    boxSizing: 'border-box',
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)'
  },
  refresh: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginTop: 10,
    display: 'flex'
  },
  status: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: 300,
    color: '#657274',
    '& > strong': {
      fontWeight: 500
    }
  },
})

interface IProps extends WithStyles<typeof styles> {
  
}

const OutlookWidget = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();
  const { status, loading } = useSelector((state: any) => state.outlook);




  const getOutlookStatus = (): string => {
    if(!status.has_outlook_credentials) return 'Not Connected';

    return status.credentials_are_active && status.has_outlook_subscription && status.subscriptions_are_active ? 'Connected' : 'Expired'
  }


  const onRefresh = (e: any): void => {
    goToOutlook()
  }


  const onConnect = (e: any): void => {
    goToOutlook()
  }


  const isOutlookSubscriptionActive = (): boolean => {
    return status.credentials_are_active && status.subscriptions_are_active
  }


  const isOutlookConnected = (): boolean => {
    return status.has_outlook_credentials 
  }




  if(loading) {
    return (
      <div className={classes.root}>
        <CircularProgress size={20} className={classes.loading} />
      </div>
    )
  }




  return (
    <div className={classes.root}>
      <OutlookIcon
        className={classNames(
          classes.icon,
          {[classes.iconActive]: isOutlookSubscriptionActive()}
        )}
        onClick={anchor.toggle}
      />
      <Popover disableRestoreFocus
        className={classes.popover}
        classes={{ paper: classes.popoverPaper }}
        open={!!anchor.value}
        anchorEl={anchor.value}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={anchor.close}
      >
        
        <div className={classes.status}>
          <strong>Status:</strong> {getOutlookStatus()}
        </div>


        {isOutlookConnected()
          ? (!isOutlookSubscriptionActive() && (
              <span className={classes.refresh} onClick={onRefresh}>Refresh</span>
            ))
          : <span className={classes.refresh} onClick={onConnect}>Connect</span>
        }


      </Popover>
    </div>
  )
}


export default withStyles(styles)(OutlookWidget)