import * as actions from '../../actions/common/loading';


export const initialState = {
  requests: [] as any,
  isLoading: false,
  pagesLoaded: {}
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_LOADING_REQUEST:
      return {
        ...state,
        requests: [...state.requests, action.request],
        isLoading: true,
      }


    case actions.CLEAR_LOADING_REQUEST:
      const newRequests = state.requests.filter((request: string) => request !== action.request);
      return {
        ...state,
        requests: newRequests,
        isLoading: !!newRequests.length
      }
    

    case actions.SET_LOADED_PAGE:
      return {
        ...state,
        pagesLoaded: {
          ...state.pagesLoaded,
          [action.page]: true
        }
      }


    default:
      return state
  }
}