import React from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import Button from '../Button';
import { ModalFooter } from '../modal/ModalFooter';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed'
  },
  cancelButton: {
    border: `1px solid ${theme.palette.primary.main}`
  }
})

interface IProps extends WithStyles<typeof styles> {
  store: any;
  onSend?: () => any;
  onCancel?: () => any;
}


const EmailComposeInlineFooter = (props: IProps) => {
  const { classes } = props;



  
  const isEmailValid = (): boolean => {
    return !!props.store?.to?.length
  }



  const onSend = (): void => {
    props.onSend?.()
  }


  const onCancel = (): void => {
    props.onCancel?.()
  }




  return (
    <ModalFooter className={classes.root}>
      <Button primary
        onClick={onSend}
        disabled={!isEmailValid()}
      >
        Send
      </Button>

      <Button
        onClick={onCancel}
        disabled={!isEmailValid()}
        className={classes.cancelButton}
      >
        Cancel
      </Button>
    </ModalFooter>
  )
}


export default withStyles(styles)(
EmailComposeInlineFooter
)