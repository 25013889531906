import React, { useMemo } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { RouteComponentProps, withRouter, useHistory } from 'react-router';
import { GearIcon } from '../icons/GearIcon';
import { useAnchor } from '../../shared/hooks';
import Menu from '../Menu';
import { ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES, ROUTE_EMAIL_MANAGEMENT_DRAFTS, ROUTE_CONTACT_MANAGEMENT, ROUTE_EMAIL_MANAGEMENT, ROUTE_ACTIVITY } from '../../constants/routes';
import { isRoute, goTo } from '../../utils/utils';
import { isAdmin } from '../../utils/user';
import { useSelector } from 'react-redux';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  icon: {
    fontSize: 18,
    color: '#b2bdc2',
    cursor: 'pointer',
    transition: 'color .1s ease',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  
}

const ManagementNavigationWidget = (props: IProps) => {
  const { classes } = props;
  const history = useHistory();
  const anchor = useAnchor();
  const user = useSelector((state: any) => state.user.user);



  const onClick = (e: any, option: any): void => {
    option.onClick?.(e);
  }



  const options = [
    { label: 'Contact Management', hidden: !isAdmin(user), onClick: (e) => goTo(e, ROUTE_CONTACT_MANAGEMENT_IMPORTED_DUPLICATES, history), selected: isRoute(ROUTE_CONTACT_MANAGEMENT) },
    { label: 'Email Management', onClick: (e) => goTo(e, ROUTE_EMAIL_MANAGEMENT_DRAFTS, history), selected: isRoute(ROUTE_EMAIL_MANAGEMENT) },
    { label: 'Contact Activity', hidden: !isAdmin(user), onClick: (e) => goTo(e, ROUTE_ACTIVITY, history), selected: isRoute(ROUTE_ACTIVITY) },
  ]



  return (
    <div className={classes.root}>
      <GearIcon className={classes.icon} onClick={anchor.set} />

      <Menu
        optionValue="label"
        anchor={anchor}
        options={options}
        onSelect={onClick}
      />
    </div>
  )
}


export default withStyles(styles)(
  ManagementNavigationWidget
)