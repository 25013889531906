import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { createStyles, ThemeProvider, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState, useEffect, forwardRef, MouseEvent } from 'react';
import { overridesNameToClassKey } from '../overrides-mui';
import { datepickerTheme, Theme } from '../theme';
import { formatDate } from '../utils/utils';
import { NotificationIcon } from './icons/NotificationIcon';
import TextInput from './TextInput';
import moment, { Moment } from 'moment';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { ClickAwayListener } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    width: 250,
    maxWidth: 250,
    backgroundColor: '#fff',
    padding: 10,
    borderRadius: 4,
    border: '1px solid #d9e4e8',
    boxSizing: 'border-box',
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  dpRoot: {
    marginTop: 3,
    '& > div, & > div > div, & > div > div > div': {
      minWidth: 228 ,
      minHeight: 'initial',
      padding: 0,
      boxSizing: 'border-box'
    }
  },
  readonlyInput: {
    backgroundColor: '#f7f9fa !important',
    padding: '0 8px 0 31px !important',
  },
  icon: {
    fontSize: 14,
    position: 'absolute',
    zIndex: 1,
    top: '50%',
    left: 11,
    transform: 'translateY(-50%)',
    color: '#657274',
  },
  inputWrapper: {
    position: 'relative',
  },
  toolbar: {
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 400,
    cursor: 'pointer',
    margin: '8px 0 5px',
    transition: 'color .1s ease',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
})


interface IProps extends WithStyles<typeof styles>, overridesNameToClassKey {
  value: any;
  onChange: any;
  onClose?: () => any;
  className?: any;
  readonly?: boolean;
  yearSelection?: boolean;
  [otherProps: string]: any;
}

const Datepicker = (props: IProps, ref: any) => {
  const { classes, value, onChange, className, readonly, yearSelection, ...otherProps } = props;
  const [localValue, setLocalValue] = useState<any>(value);
  const [isYear, setIsYear] = useState<boolean>(false);


  const handleChange = (date: MaterialUiPickersDate) => {
    setLocalValue(date)

    if(!readonly && !isYear) {
      onChange(date)
    }
  }


  const onToolbarToggle = (toolbarProps: ToolbarComponentProps): void => {
    setIsYear(toolbarProps.openView === 'date')
    toolbarProps.setOpenView(toolbarProps.openView === 'year' ? 'date' : 'year')
  }


  const onYearChange = (date: MaterialUiPickersDate): void => {
    setLocalValue(date)
    setIsYear(false)
  }



  const onClose = (): void => {
    props.onClose?.()
  }




  useEffect(() => {
    setLocalValue(value)
  }, [value])






  return (
    <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
      <div
        ref={ref}
        className={classNames( classes.root, className)}
      >
        <div className={classes.inputWrapper}>
            
            {readonly && <NotificationIcon className={classes.icon} />}
        
            <TextInput
              readonly={readonly}
              value={formatDate(localValue)}
              placeholder='Select Date'
              inputStyle={readonly ? classes.readonlyInput : null}
            />
          </div>

          <div className={classes.dpRoot}>
            <ThemeProvider theme={datepickerTheme(readonly)}>
              <DatePicker autoOk
                disableToolbar={!yearSelection}
                variant='static'
                openTo='date'
                value={localValue}
                onChange={handleChange}
                onYearChange={onYearChange}
                disablePast={readonly}
                disableFuture={readonly}
                ToolbarComponent={(props: ToolbarComponentProps) => (
                  <div className={classes.toolbar} onClick={() => onToolbarToggle(props)}>
                    Year Selected: {props?.date?.year() || moment().year()}
                  </div>
                )}
                {...otherProps}
              />
            </ThemeProvider>
          </div>
        
      </div>
    </ClickAwayListener>
  )
}


export default withStyles(styles)(
  forwardRef(Datepicker)
)