import { FormControl, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { FocusEvent, forwardRef, useState, ChangeEvent } from "react";
import { useShowAll } from "../shared/hooks";
import { TextInputType } from "../shared/types";
import { Theme } from "../theme";
import { formatNumber } from "../utils/utils";
import BaseInput from "./BaseInput";
import ShowAll from "./ShowAll";


const styles = (theme: Theme) => createStyles({
  formHelperTextRoot: {
    display: 'none',
    '&$formHelperTextError': {
      display: 'block'
    }
  },
  formHelperTextError: {},
  hidden: {
    display: 'none'
  },
  currency: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#4192ec',
    position: 'absolute',
    left: 8,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  withCurrency: {
    paddingLeft: '10px !important'
  },
  currencyLabel: {
    color: '#233539',
    left: 0
  },
  link: {
    textDecoration: 'unset',
    width: '100%',
    '&:hover, & input:hover': {
      color: '#4192ec',
      cursor: 'pointer !important',
    }
  },
  collapsible: {
    lineHeight: 1.5,
    maxHeight: '54px !important',
    overflow: 'hidden !important'
  },
  fullHeight: {
    maxHeight: 'unset !important'
  },
  error: {
    color: '#d52023',
    marginTop: 5,
    display: 'flex',
    fontSize: 12,
    paddingLeft: 5,
  }
});

interface IProps extends WithStyles<typeof styles> {
  type?: TextInputType | string,
  value?: any,
  onChange?: (value: any, e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any,
  onFocus?: () => any,
  onBlur?: (value: any, e?: any) => any,
  className?: string,
  error?: any,
  readonly?: boolean,
  multiline?: boolean,
  onEnter?: Function,
  onRef?: Function,
  placeholder?: string,
  inputStyle?: any,
  startAdornment?: any,
  endAdornment?: any,
  linkType?: string,
  style?: any,
  transparent?: boolean,
  initialHeight?: boolean,
  roundedGrey?: boolean,
  wrapperClass?: string,
  name?: string,
  staticValue?: boolean,
  hidden?: boolean,
  collapsible?: boolean,
  formattedNumber?: boolean,
  showError?: boolean,
}

const TextInput = forwardRef((props: IProps, ref: any) => {
  const { classes, value, error, readonly, type, formattedNumber, endAdornment } = props;
  const showAll = useShowAll();
  const [overflowing, setOverflowing] = useState<boolean>(false);






  const getValue = (): string | number => {
    if(value !== 0 && !value) return '';

    const toFixed = type === TextInputType.PERCENTAGE ? 2 : undefined;

    let val = formattedNumber ? formatNumber(value, toFixed) : value;


    if(readonly) {
      
      if(type === TextInputType.PERCENTAGE) {
        return `${val}%`
      }

      if(endAdornment) {
        return `${val}${endAdornment}`
      }

    }


    return val ?? ''
  }



  const getType = (): string => {
    switch(type) {
      
      case TextInputType.WEBSITE:
      case TextInputType.CUSTOM_ADORNMENT:
      case TextInputType.PERCENTAGE:
      case TextInputType.CURRENCY:
      case undefined:
        return 'text'


      case TextInputType.EMAIL:
        return 'email'


      default:
        return type
    }
  }



  const formatValue = (val: string|number): string|number => {
    switch(type) {
      case TextInputType.CURRENCY:
      case TextInputType.PERCENTAGE:
        if(!val) return 0;
        break;
      default:
        break;
    }

    if(!val) return '';

    return formattedNumber ? `${val}`.replace(/,/g, '') : val
  }



  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const val = formatValue(e.target.value);
    if(val && /\.\d{3}$/.test(`${val}`)) return;

    return props.onChange?.(val, e)
  }


  const handleBlur = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>): any => {
    const val = formatValue(e.target.value);
    return props.onBlur?.(val, e)
  }



  const getWebsiteValue = (): string => {
    return value && value.toString().includes('http') ? value : `https://${value}`
  }



  const renderCurrency = () => {
    return type === TextInputType.CURRENCY
      ? (
        <div className={classNames(
          classes.currency,
          { [classes.currencyLabel]: readonly }
        )}>
          £
          </div>
      )
      : null
  }



  const renderEndAdornment = () => {
    if (endAdornment && !readonly) return endAdornment;
    if (type == TextInputType.PERCENTAGE && !readonly) return '%';
  }



  const renderBaseInput = () => (
    <BaseInput
      value={getValue()}
      onChange={handleChange}
      onFocus={props.onFocus}
      onBlur={handleBlur}
      type={getType()}
      readonly={readonly}
      multiline={props.multiline}
      onEnter={props.onEnter}
      onRef={(ref: any) => {
        if(ref) {
          setOverflowing(ref.offsetHeight < ref.scrollHeight || showAll.value);
        }

        props.onRef?.(ref)
      }}
      placeholder={props.placeholder}
      inputStyle={classNames(
        props.inputStyle,
        {
          [classes.withCurrency]: type === TextInputType.CURRENCY,
          [classes.collapsible]: props.collapsible && readonly,
          [classes.fullHeight]: (props.multiline && props.collapsible && showAll.value) || !readonly
        }
      )}
      startAdornment={props.startAdornment}
      endAdornment={renderEndAdornment()}
      style={props.style}
      transparent={props.transparent}
      initialHeight={props.initialHeight}
      roundedGrey={props.roundedGrey}
      wrapperClass={props.wrapperClass}
      name={props.name}
      staticValue={props.staticValue}
    />
  )



  const renderInput = () => {
    if (readonly && !!value) {
      switch (props.type) {
        case TextInputType.EMAIL:
          return (
            <a href={`mailto:${value}`} className={classes.link}>
              {renderBaseInput()}
            </a>
          )
        case TextInputType.WEBSITE:
          return (
            <a href={getWebsiteValue()} target='_blank' className={classes.link}>
              {renderBaseInput()}
            </a>
          )
      }
    }

    return renderBaseInput()
  }


  



  if(props.hidden) {
    return null
  }





  return (
    <div className={props.className} ref={ref}>
      <FormControl fullWidth error={error && !readonly}>
        {renderCurrency()}
        {renderInput()}
      </FormControl>

      {readonly && props.collapsible && <ShowAll showAll={showAll} label="Show more..." isOverflowing={overflowing} alignRight />}

      {props.showError && !!error?.length && (
        <span className={classes.error}>{error[0]}</span>
      )}
    </div>
  );
})



export default withStyles(styles)(TextInput)