import externalContactsService from '../services/external-contacts';
import { ISort } from '../shared/interfaces';
import { formatSortObject } from './helpers/helpers';

/**
 * Actions
 */

export const SET_EXTERNAL_CONTACTS            = 'SET_EXTERNAL_CONTACTS';
export const REMOVE_EXTERNAL_CONTACT          = 'REMOVE_EXTERNAL_CONTACT';
export const APPEND_EXTERNAL_CONTACTS         = 'APPEND_EXTERNAL_CONTACTS';
export const SET_INITIATED_EXTERNAL_CONTACTS  = 'SET_INITIATED_EXTERNAL_CONTACTS';
export const SET_LOADING                      = 'EXTERNAL_CONTACTS_SET_LOADING';


/**
 * Action creators
 */

export function setExternalContacts(data: any): object {
  return {type: SET_EXTERNAL_CONTACTS, ...data}
}

export function removeContact(ids: number[]): object {
  return {type: REMOVE_EXTERNAL_CONTACT, ids}
}

export function appendContacts(data: any): object {
  return {type: APPEND_EXTERNAL_CONTACTS, ...data}
}

export function setInitiated(state: boolean): object {
  return {type: SET_INITIATED_EXTERNAL_CONTACTS, state}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}



/**
 * Async action creators
 */

export const getExternalContacts = (sort: ISort[], search: string) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const body = formatSortObject(sort, search)

    return externalContactsService.getExternalContacts(body)
      .then((res: any) => {
        dispatch(setExternalContacts(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting external contacts.');
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const getNextContacts = () => {
  return (dispatch, getState) => {
    const nextURL = getState().externalContacts.next;
    dispatch(setLoading(true));

    return externalContactsService.getNextContacts(nextURL)
      .then((res: any) => {
        dispatch(appendContacts({
          results: res.results,
          next: res.next,
          previous: res.previous
        }));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting external contacts.');
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const dismissContacts = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return externalContactsService.dismissContacts(ids)
      .then((res: any) => {
        dispatch(removeContact(ids));
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting external contacts.');
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}