import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { memo } from 'react';
import { Theme } from '../../../theme';
import { getUsername } from '../../../utils/user';
import TableCellValue from '../TableCellValue';
import { highlight } from '../../../utils/component-utils';

const styles = (theme: Theme) => createStyles({
  link: {
    color: '#4192ec',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    }
  },
})



interface IProps extends WithStyles<typeof styles> {
  person: any;
  search: string;
  shouldHighlight: boolean;
}

const TableRowUserLink = ({ classes, person, search, shouldHighlight }: IProps) => {

  const label = (): any => {
    const label = getUsername(person);
    if(!shouldHighlight || !search) {
      return label || '--';
    }

    return highlight(label, search)
  }


  return (
    <TableCellValue>
      <a href={`/contacts/${person.id}`} className={classes.link}>
        {label()}
      </a>
    </TableCellValue>
  )
}


export default withStyles(styles)(
 memo(TableRowUserLink)
)