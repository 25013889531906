import React, { memo } from "react";
import { Theme } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import { WithStyles } from "@material-ui/core";
import { InviteStatus } from "../shared/models/Person";
import { ProfileType, ProfileTypeNames } from "../shared/types";
import { PersonIcon } from "./icons/PersonIcon";
import Button, { ButtonSize } from "./Button";
import PersonTypeChipPending from "./PersonTypeChipPending";
import classNames from 'classnames';


const styles = (theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
  },
  icon: {
    width: 14,
    height: 14,
    marginRight: 10
  },
  label: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: '#233539'
  },
  inviteButton: {
    padding: '0 18px'
  },
  hidden: {
    display: 'none'
  }
});




const areEqual = (prev: IProps, next: IProps): boolean => {
  return prev.inviteStatus === next.inviteStatus
    && prev.rowId === next.rowId
}

interface IProps extends WithStyles<typeof styles> {
  profileType?: ProfileType;
  inviteStatus: InviteStatus;
  onInvite: (e: any) => any;
  rowId: number;
  readonly?: boolean;
}

const PersonTypeChip = (props: IProps) => {
  const { classes } = props;


  switch(props.inviteStatus) {

    case InviteStatus.NotSent:
      return <>
       <span className={classNames(
         'show-on-print',
          {[classes.hidden]: !props.readonly}
        )}>
          Not invited
        </span>

        <Button
            primary
            size={ButtonSize.Tiny}
            onClick={props.onInvite}
            className={classNames(classes.inviteButton, 'hide-on-print')}
          >
            Invite
          </Button>
      </>


    case InviteStatus.Pending:
      return <>
        <span className={classNames(
          'show-on-print',
          {[classes.hidden]: !props.readonly}
        )}>
          Pending
        </span>

        <PersonTypeChipPending personId={props.rowId} className="hide-on-print" />
      </>
  }



  return (
    <div className={classes.root}>
      <PersonIcon className={classes.icon} type={props.profileType} />
      <span className={classes.label}>
        {!!props.profileType && ProfileTypeNames[props.profileType]}
      </span>
    </div>
  )
}


export default withStyles(styles)(
  memo(PersonTypeChip, areEqual)
)