import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PrintIcon from '@material-ui/icons/Print';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { setLoadedPage } from '../../actions/common/loading';
import { getProjectDetails } from '../../actions/projects/projects';
import { setCompanies, setFilterCompanies, setFilteredProviders, setFilterStatuses } from '../../actions/projects/providers';
import Button from '../../components/Button';
import List from '../../components/list/List';
import PrintDescription from '../../components/print/PrintDescription';
import TrackerHeader from '../../components/project/tracker/TrackerHeader';
import Select from '../../components/Select';
import { ROUTE_PROJECTS, ROUTE_PROJECT_PRINT_CP } from '../../constants/routes';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import { isAdmin, isUser } from '../../utils/user';
import { getIds, groupBy } from '../../utils/utils';


const styles = (theme: Theme) => createStyles({
  root: {

  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  headerProjectName: {
    display: 'flex',
    flexDirection: 'column',
  },
  projectName: {
    color: '#233539',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 400
  },
  backToProject: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -5,
    cursor: 'pointer',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1.2,
      color: '#90a0a7'
    }
  },
  backIcon: {
    fontSize: 20,
    color: '#90a0a7'
  },
  headerFilters: {
    display: 'flex',
    alignItems: 'center'
  },
  headerFilterCompanies: {
    width: 250,
    marginRight: 10
  },
  headerFilterStatuses: {
    width: 180,
    marginRight: 10
  },
  printButton: {
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 2,
    width: 98
  },
  printIcon: {
    fontSize: 19,
    marginRight: 9
  },
  list: {

  },
  listDescription: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: 11,
    lineHeight: 1.45,
    color: '#657274',
    marginBottom: 10,
    '& > span': {
      marginRight: 3,
      '& > strong': {
        fontWeight: 600
      }
    },
  },
  collapseAll: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: 11,
    lineHeight: 1.45,
    color: '#657274',
    float: 'right',
    marginBottom: 10,
    '& > span': {
      marginRight: 3,
      '& > strong': {
        fontWeight: 600
      }
    },
  },
  listTable: {
    marginTop: 0
  },
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  projectId: number;
}

const ProjectCapitalProvidersPage = (props: IProps) => {
  const { classes, projectId } = props;
  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.loading);
  const user = useSelector((state: any) => state.user.user);
  const project = useSelector((state: any) => state.projects.project);
  const { providers, filteredProviders, companies: allCompanies, filters: { statuses, companies } } = useSelector((state: any) => state.projectProviders);
  const search = useSelector((state: any) => state.search.search);
  const statusTypes = useSelector((state: any) => state.types.provider_relation_types);








  const searchProviders = (provider: any): boolean => {
    if (search === '') return true;

    const searchStr = search.toLowerCase();
    if (provider.person.full_name.toLowerCase().includes(searchStr) || provider.company.toLowerCase().includes(searchStr)) {
      return true;
    }
      
    return false;
  }




  const filterProviders = (): void => {



    let newProviders = providers.map((provider: any) => ({
      ...provider,
      company: provider.person.company,
      sorting_priority: statusTypes.find((item: any) => item.id === provider.state)?.sorting_priority
    }))


    const grouped = groupBy(newProviders, 'company')

    newProviders = newProviders.map((provider: any) => {
      const state = grouped[provider.person.company].sort((a: any, b: any) => b.state - a.state)[0]?.state;
      const note = grouped[provider.person.company].find((item: any) => !!item.notes);
      return {
        ...provider,
        company_details: {
          name: provider.person.company,
          state: state,
          notes: note?.notes,
          notes_created_at: note?.notes_created_at,
          notes_updated_at: note?.notes_updated_at
        }
      }
    })
  


    if (companies.length) {
      const organizationNames = companies.map((org: any) => org.name);
      newProviders = newProviders.filter((provider: any) => organizationNames.includes(provider.company))
    }


    if (statuses.length) {
      const statusesIds = getIds(statuses);
      newProviders = newProviders.filter((provider: any) => statusesIds.includes(provider.company_details.state));
    }


    if(!!search) {
      newProviders = newProviders.filter(searchProviders)
    }


    dispatchSetFilteredProviders(newProviders)
  }





  const goBack = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    props.history.push(`/projects/${projectId}`)
  }



  const onInit = (): void => {
    if(+projectId === project.id) {
      dispatch(setLoadedPage(ROUTE_PROJECT_PRINT_CP))
      return
    }


    dispatchGetProject(projectId)
      .then((res: any) => {
        dispatch(setLoadedPage(ROUTE_PROJECT_PRINT_CP))
      })
      
      .catch((err: any) => {
        if(err?.response?.status === 404) {
          props.history.push(ROUTE_PROJECTS)
        }
      })
  }



  const getStatusesNames = (): string => {
    return !!statuses.length
      ? statuses.map((status: any) => status.name).join(', ')
      : 'All Statuses'
  }



  const getOrganizationsNames = (): string => {
    return !!companies.length
      ? companies.map((org: any) => org.name).join(', ')
      : 'All Companies'
  }


  

  const printDescription = useMemo((): any => [
    { title: 'Project:', value: ` ${project.name} •` },
    { title: 'Displaying:', value: ' all Capital Providers:' },
    { title: 'Status: ', value: ` is ${getStatusesNames()},` },
    { title: 'Company: ', value: ` is ${getOrganizationsNames()}` }
  ], [project.name, getStatusesNames, getOrganizationsNames])



  const printCaption = useMemo((): any => (
    { title: 'CP Contacted: ', value: providers.length }
  ), [providers.length]);


  const hasFullAccess = (): boolean => {
    return isAdmin(user) || isUser(user)
  }



  const dispatchGetProject = (projectId: number): any => {
    return dispatch(getProjectDetails(projectId))
  }



  const dispatchSetFilteredProviders = (newProviders: any[]): any => {
    return dispatch(setFilteredProviders(newProviders))
  }



  const dispatchSetFilterStatuses = (newStatuses: any[]): any => {
    return dispatch(setFilterStatuses(newStatuses))
  }



  const dispatchSetFilterCompanies = (newCompanies: any[]): any => {
    return dispatch(setFilterCompanies(newCompanies))
  }



  const dispatchSetCompanies = (newCompanies: any[]): any => {
    return dispatch(setCompanies(newCompanies))
  }



  const isLoading = (): boolean => {
    return !loading.pagesLoaded[ROUTE_PROJECT_PRINT_CP]
  }






  useEffect(() => {
    onInit()
  }, [])




  useEffect(() => {
    filterProviders()
  }, [providers, statusTypes, statuses, companies, search])




  

  useEffect(() => {
    const newCompanies: any[] = [];
    providers.map((provider: any, idx: number) => {
      if (!newCompanies.find((org: any) => org.name === provider.person.company)) {
        newCompanies.push({ id: newCompanies.length, name: provider.person.company })
      }
    })

    dispatchSetCompanies(newCompanies)
  }, [providers])






  return (
    <div className={classes.root}>



      <div className={classNames(classes.header, 'hide-on-print')}>

        <div className={classes.headerProjectName}>
          <div className={classes.backToProject} onClick={goBack}>
            <ArrowLeftIcon className={classes.backIcon} />
            <span>Back to Project</span>
          </div>
          <span className={classes.projectName}>Email Tracker</span>
        </div>


        <div className={classes.headerFilters}>
          <Select checkboxes textOverflow
            value={companies}
            onSelect={dispatchSetFilterCompanies}
            valuesList={allCompanies}
            className={classes.headerFilterCompanies}
            placeholder="Not Specified"
          />
          <Select checkboxes textOverflow
            value={statuses}
            onSelect={dispatchSetFilterStatuses}
            valuesList={statusTypes}
            className={classes.headerFilterStatuses}
            placeholder="Not Specified"
          />
          <Button primary className={classes.printButton} onClick={window.print}>
            <PrintIcon className={classes.printIcon} />
            Print
          </Button>
        </div>

      </div>



      <TrackerHeader />




      <div className={classes.list}>


        <PrintDescription items={printDescription} caption={printCaption} />

        <List
          initialLoading={isLoading()}
          rows={filteredProviders}
          count={providers.length}
          tableType={TableType.CAPITAL_PROVIDERS}
          className={classes.listTable}
          groupBy="company"
          fullAccess={hasFullAccess()}
          header={
            <span className={classes.listDescription}>
              <span><strong>Project:</strong> {project.name} • </span>
              <span><strong>Displaying</strong> all Capital Providers:</span>
              <span><strong>Status</strong> is {getStatusesNames()},</span>
              <span><strong>Company</strong> is {getOrganizationsNames()}</span>
            </span>
          }
        />
      </div>

    </div>
  )
}


export default withStyles(styles)(
  withRouter(ProjectCapitalProvidersPage)
)