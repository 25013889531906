import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const SearchIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="28" height="28" viewBox="0 0 28 28">
      <g fill="none" fillRule="evenodd">
        <path d="M-1-1h32v32H-1z"/>
        <path fill="currentColor" fillRule="nonzero" d="M19.667 17.667h-1.054l-.373-.36a8.628 8.628 0 0 0 2.093-5.64A8.666 8.666 0 0 0 11.667 3 8.666 8.666 0 0 0 3 11.667a8.666 8.666 0 0 0 8.667 8.666c2.146 0 4.12-.786 5.64-2.093l.36.373v1.054l6.666 6.653 1.987-1.987-6.653-6.666zm-8 0c-3.32 0-6-2.68-6-6s2.68-6 6-6 6 2.68 6 6-2.68 6-6 6z"/>
      </g>
    </SvgIcon>
  );
};