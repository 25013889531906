import React, { useState, useEffect } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import StoreDropdown from '../../../components/forms/meta/fields/StoreDropdown';
import InputAutocompleteTagsField from '../../../components/forms/meta/fields/InputAutocompleteTagsField';
import TinyMCEField from '../../../components/forms/meta/fields/TinyMCEField';
import StoreDropzone from '../../../components/forms/meta/fields/StoreDropzone';
import { useDispatch } from 'react-redux';
import { useOpen } from '../../../shared/hooks';
import { getUsername } from '../../../utils/user';
import { getIds } from '../../../utils/utils';
import { withRouter, RouteComponentProps } from 'react-router';
import { searchPeoplePromise } from '../../../actions/people/people';
import TextField from '../../../components/forms/meta/fields/TextField';
import { currentEmailDraftsActions } from '../../../reducers/email/current-drafts';
import { getEmailProjects } from '../../../actions/projects/email';
import { IPerson } from '../../../shared/models/Person';
import { TinymceType, AutocompleteType, SELECT_ITEM_NONE_ID } from '../../../shared/types';
import StoreAutocomplete from '../../../components/forms/meta/fields/StoreAutocomplete';

const styles = (theme: Theme) => createStyles({
  root: {
    marginBottom: 15,
  },
  form: {
    width: '100%'
  },
  summary: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.71,
    textAlign: 'center',
    color: '#657274',
    paddingBottom: 15,
  }
})


const storeGetter = storeName => state => state[storeName];
const emailManagementStoreGetter = storeName => state => state.emailManagement[storeName];


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  store: any;
  forward?: boolean;
  onChange: (obj: any) => any;
  onFilesUpload: (files: any) => any;
  onFileDelete: (id: number) => any;
}

const EmailDraftForm = (props: IProps) => {
  const { classes, store } = props;
  const dispatch = useDispatch();
  const [toSummary, setToSummary] = useState<string>('');
  const ccVisibility = useOpen();
  const bccVisibility = useOpen();



  const getTagLabel = (person: any): any => {
    return person.emails
      ? <span>{getUsername(person)} (<strong style={{fontWeight: 600}}>{(person.emails && person.emails[0]) || ''}</strong>)</span>
      : <span>{getUsername(person)}</span>
  }

  const getSortValue = (person: any): string => {
      return `${getUsername(person)}`
  }

  const getToActions = (): any[] => {
      if(ccVisibility.value || bccVisibility.value) return [];
      return [
          {id: 0, label: 'Cc', action: () => ccVisibility.open()},
          {id: 1, label: 'Bcc', action: () => bccVisibility.open()},
      ]
  }

  const getCcActions = (): any[] => {
      return bccVisibility.value
          ? []
          : [ {id: 0, label: 'Bcc', action: () => bccVisibility.open()} ]
  }

  const getBccActions = (): any[] => {
      return ccVisibility.value
          ? []
          : [ {id: 0, label: 'Cc', action: () => ccVisibility.open()} ]
  }

  const formatValue = (arr: any[]): any => {
    if(!arr?.length) return [];

    return arr.map((item: any) => item.id && item.title && item.sortValue
      ? item
      : ({
          id: item.id,
          title: getTagLabel(item),
          sortValue: getSortValue(item)
        })
    )
    .sort((a: any, b: any) => a.sortValue.localeCompare(b.sortValue))
  }



  const emailMeta = [
    // {name: 'project', value: store?.project, placeholder: 'Select a Project', as: StoreDropdown, getOptions: storeGetter('emailProjects'), isVisible: (options: any) => !!options.length && !props.forward, spacingBottom: true},
    {name: 'project', value: store?.project, placeholder: 'Start typing a project name', as: StoreAutocomplete, getOptions: storeGetter('emailProjects'), type: AutocompleteType.Projects, isVisible: (options: any) => !!options.length && !props.forward, spacingBottom: true, startAdornment: 'Project:', endAdornment: store?.project?.id === SELECT_ITEM_NONE_ID ? 'No project selected' : ''},
    {name: 'to', value: formatValue(store?.to_people), as: InputAutocompleteTagsField, fullWidth: true, searchAction: searchPeoplePromise, getValue: getTagLabel, getSortValue: getSortValue, startAdornment: 'To:', spacingBottom: true, actions: getToActions()},
    {name: 'cc', value: formatValue(store?.cc_people), as: InputAutocompleteTagsField, fullWidth: true, searchAction: searchPeoplePromise, getValue: getTagLabel, getSortValue: getSortValue, startAdornment: 'Cc:', actions: getCcActions(), shouldRender: ccVisibility.value, spacingBottom: true},
    {name: 'bcc', value: formatValue(store?.bcc_people), as: InputAutocompleteTagsField, fullWidth: true, searchAction: searchPeoplePromise, getValue: getTagLabel, getSortValue: getSortValue, startAdornment: 'Bcc:', actions: getBccActions(), shouldRender: bccVisibility.value, spacingBottom: true},
    {name: 'subject', value: store?.subject, fullWidth: true, spacingBottom: true, startAdornment: props.forward ? 'FWD:' : 'Subject:'},
    {name: 'body', value: store?.body, as: TinyMCEField, type: store?.is_company ? TinymceType.COMPANY_EMAIL : TinymceType.INDIVIDUAL_EMAIL},
    {name: 'files', value: store?.uploaded_files, as: StoreDropzone, onRemove: props.onFileDelete, getOptions: emailManagementStoreGetter('drafts')},
  ];


  const onChange = (name: string, newValue: any) => {
    switch(name) {

      case 'to':
      case 'cc':
      case 'bcc':
        props.onChange({
          [name]: newValue.map((item: any) => item.id),
          [`${name}_people`]: newValue
        })
        return;

      case 'files':
        const files = newValue.filter((file: any) => file.id === undefined);
        if(!files?.length) return;
        return props.onFilesUpload(files)

      default:
        return props.onChange({[name]: newValue})
    }
  };



  useEffect(() => {

    if (store?.to_people?.length) {
      const people = store.to_people;
      const peopleSummary = (people.length == 1) ? `1 contact` : `${people.length} contacts`;
      const companies = [...new Set(people.map((person: IPerson) => person.company))].length;
      const orgSummary = (companies == 1) ? `1 company` : `${companies} companies`;



      setToSummary(`${peopleSummary}, ${orgSummary} being emailed`);

      if(!props.forward) {
        dispatch(getEmailProjects(store.to_people.map(person => person.id).join(',')))
      }
    }
    
    if(store?.cc?.length && !ccVisibility.value) {
      ccVisibility.open()
    }

    if(store?.bcc?.length && !bccVisibility.value) {
      bccVisibility.open()
    }

  }, [store?.to_people])



  return (
    <div className={classes.root}>
      


      {emailMeta.map((meta: any, idx: number) => {

        const { as: Component = TextField, ...otherMeta } = meta;
        return (
          <Component
            key={idx}
            onChange={(value: any) => onChange(meta.name, value)}
            {...otherMeta}
          />
        )

      })}

      <span className={classes.summary}>{toSummary}</span>

    </div>
  )
}


export default withStyles(styles)(
  withRouter(EmailDraftForm)
) 