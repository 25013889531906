import { createStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Theme } from '../../../../theme';
import TextInput from "../../../TextInput";

const styles = (theme: Theme) => createStyles({
    root: {
        margin: '2px 0',
        outline: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        '&:focus $startAdornment, &:focus-within $startAdornment': {
            outline: 'none',
            fontWeight: 500,
            '&:after': {
                content: '":"'
            }
        }
    },
    spacingBottom: {
        marginBottom: 10
    },
    inputWrapper: {
        marginRight: 4,
        width: '100%',
        '&:last-child': {
          marginRight: 0
        }
    },
});


const DoubleTextField = (props: any) => {
    const {classes, onChange, spacingBottom, startAdornment, names, value, className, error, ...otherProps} = props;


    if(!names?.length) return null;



    return (
      <div
        tabIndex={1}
        className={classNames(
          classes.root,
          className
        )}
      >
        {names.map((item: any) => (
          <TextInput
            {...otherProps}
            key={item.name}
            placeholder={item.placeholder}
            onChange={(value: string|number) => onChange(value, item.name)}
            value={value?.[item.name]?.value ?? value?.[item.name] ?? ''}
            className={classNames(
              classes.inputWrapper,
              {[classes.spacingBottom]: spacingBottom}
            )}
            startAdornment={startAdornment}
            error={error?.[item.name]}
          />
        ))}
      </div>
    )
}

export default withStyles(styles)(DoubleTextField)