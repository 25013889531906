import {withStyles} from "@material-ui/core";
import {Theme} from "../../theme";
import {createStyles} from "@material-ui/styles";
import classNames from 'classnames';
import * as React from "react";



const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 8px',
        borderTop: '1px solid #d9e4e8',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        zIndex: 2,
    },
});


const ModalFooterComponent = ({children, classes, className = ''}) => {

    return <div className={classNames(classes.root, className)}>
        {children}
    </div>
};



export const ModalFooter = withStyles(styles)(ModalFooterComponent);