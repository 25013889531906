import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { addSearchRow, changeSearchRow, deleteSearchRow } from '../../actions/advanced-search';
import { clearSearchHistoryItem } from '../../actions/search-history';
import { SearchTypeName, SEARCH_OPTION_FIELDS } from '../../shared/models/Search';
import { INITIAL_SEARCH_ROW, SearchType } from '../../shared/types';
import { isRoute } from '../../utils/utils';
import SearchRows from './SearchRows';
import { ROUTE_SEARCH_SAVED } from '../../constants/routes';

interface IProps {
  type: SearchType;
}

const SearchPageRows = ({ type }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const rows = useSelector((state: any) => state.advancedSearch.rows);
  const hasSearchItem = useSelector((state: any) => !!state.searchHistory.current);




  const redirect = (): void => {
    history.push(`/search/${SearchTypeName[type]}`)
  }



  const onAdd = (): void => {
    if(hasSearchItem) redirect();

    dispatchAddRow()
  }



  const onDelete = (idx: number): void => {
    if(hasSearchItem) redirect();

    dispatchDeleteRow(idx)
  }



  const onChange = (field: any, rowIndex: number): void => {
    if(hasSearchItem) redirect();

    dispatchChangeRow(field, rowIndex)
  }



  const dispatchChangeRow = (field: any, rowIndex: number): any => {
    return dispatch(changeSearchRow(rowIndex, field))
  }



  const dispatchAddRow = (): any => {
    return dispatch(addSearchRow(INITIAL_SEARCH_ROW))
  }



  const dispatchDeleteRow = (idx: number): any => {
    return dispatch(deleteSearchRow(idx))
  }



  const dispatchClearSearchHistoryItem = (): any => {
    return dispatch(clearSearchHistoryItem())
  }




  useEffect(() => {
    if(hasSearchItem && !isRoute(ROUTE_SEARCH_SAVED)) {
      dispatchClearSearchHistoryItem()
    }
  }, [hasSearchItem, history.location.pathname])





  
  return useMemo(() => (
    <SearchRows
      showAddButton
      rows={rows}
      type={type}
      onAdd={onAdd}
      onDelete={onDelete}
      onChange={onChange}
    />  
  ), [type, rows])
}


export default SearchPageRows