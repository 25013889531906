import boardColumnsService from '../services/board-columns';

/**
 * Actions
 */

export const SET_BOARD_COLUMNS = 'SET_BOARD_COLUMNS';
export const CHANGE_COLUMN     = 'CHANGE_COLUMN';


/**
 * Action creators
 */

export function setColumns(columns: any) : object {
  return {type: SET_BOARD_COLUMNS, columns}
}

export function changeColumn(column: any) : object {
  return {type: CHANGE_COLUMN, column}
}



/**
 * Async action creators
 */

export const getBoardColumns = (projectId: number) => {
  return (dispatch) => {
    return boardColumnsService.getColumns(projectId)
      .then((res: any) => dispatch(setColumns(res.results)))
      .catch(err => {
        console.log('An error occured while getting columns.')
      })
  }
}



export const changeColumnName = (columnId: number, name: string) => {
  return (dispatch) => {
    const body = {name}
    return boardColumnsService.changeColumnTitle(columnId, body)
    .then((res: any) => {
      dispatch(changeColumn(res))
      return Promise.resolve(res);
    })
    .catch(err => {
      return Promise.reject(err)
    })
  }
}



export const toggleColumns = (body: any) => {
  return (dispatch) => {

    return boardColumnsService.toggleHiddenState(body)
    .then((res: any) => {
      dispatch(setColumns(res))
      return Promise.resolve(res);
    })
    .catch(err => {
      return Promise.reject(err)
    })
  }
}