import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { multiplePeopleEditActions } from '../../../reducers/people/edit-multiple';
import { BulkEditGroupItem } from '../../../shared/interfaces';
import { Theme } from '../../../theme';


const styles = (theme: Theme) => createStyles({
  root: {
    width: 300,
    maxWidth: 300,
    minWidth: 300,
  },
  rootFullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  component: {
    width: '100%',
    boxSizing: 'border-box'
  },
  error: {
    fontSize: 10,
    color: '#d52023'
  },
  errorHidden: {
    display: 'none'
  },
})

interface IProps extends WithStyles<typeof styles> {
  component: any;
  componentProps?: any;
  fullwidth?: boolean;
  error?: string[];
  name?: string;
  names?: BulkEditGroupItem[];
}

const BulkEditInput = (props: IProps) => {
  const { classes, name, names, component: Component, componentProps } = props;
  const { className, ...otherComponentProps } = componentProps ?? {};
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.multiplePeople.data);
  const [value, setValue] = useState<any>(null);




  const getValue = (): any => {
    if(!value) return;

    if(!!names?.length && names.every((item: BulkEditGroupItem) => !!value[item.name])) {
      return value
    }

    return value.value
  }


  const onChange = (value: string, extra?: ChangeEvent<HTMLInputElement>|string): any => {
    const fieldName = name ?? (typeof extra === 'string' && extra);
    if(!fieldName) return;

    return dispatchOnChange(fieldName, { ...data[fieldName], value })
  }




  
  const dispatchOnChange = (fieldName: string, fieldValue: any): any => {
    return dispatch(multiplePeopleEditActions.actionUpdateField(fieldName, fieldValue))
  }



  useEffect(() => {
    if(!name && !names?.length) return;

    let newData;

    if(!!names?.length) {
      newData = names.reduce((acc: object, item: BulkEditGroupItem) => {
        acc[item.name] = { ...data[item.name] };
        return acc
      }, {})
    }

    if(!!name) {
      newData = data[name]
    }
    
    if(JSON.stringify(value) !== JSON.stringify(newData)) {
      setValue(newData)
    }

  }, [data, name, names, value])






  return useMemo(() => (
    <div className={classNames(
      classes.root,
      {[classes.rootFullWidth]: props.fullwidth}
    )}>
      <Component
        className={classNames(classes.component, className)}
        value={getValue()}
        name={name}
        names={names}
        error={props.error}
        onChange={onChange}
        {...(otherComponentProps ?? {})}
      />

      <span className={classNames(
        classes.error,
        {[classes.errorHidden]: !props.error}
      )}>
        {props.error?.join(',') ?? ''}
      </span>
    </div>
  ), [value, otherComponentProps?.items, otherComponentProps?.valuesList])
}


export default withStyles(styles)(BulkEditInput)