import filesService from '../services/files';
import { setSuccess, setError } from './common/notifications';
import { getErrorCode } from '../services/helpers/helpers';
import { FilesType } from '../shared/types';

/**]
 * Types
 */

interface UploadObject {
  files: File[];
  category?: string;
  id: number;
}



/**
 * Actions
 */

export const SET_FILES            = 'SET_FILES';
export const ADD_FILES            = "ADD_FILES";
export const DELETE_FILE          = "DELETE_FILE";
export const DELETE_FILE_BY_IDX   = "DELETE_FILE_BY_IDX";
export const CLEAR_FILES          = "CLEAR_FILES";
export const SET_FILES_TYPE       = "SET_FILES_TYPE";
export const SET_LOADING          = "FILES_SET_LOADING";



/**
 * Action creators
 */

export function setAllFiles(files: any): object {
  return {type: SET_FILES, files}
}

export function addFiles(files: any): object {
  return {type: ADD_FILES, files}
}

export function deleteSingleFile(id: number, typeId: number): object {
  return {type: DELETE_FILE, id, typeId}
}

export function deleteSingleFileByIdx(idx: number, typeId: number): object {
  return {type: DELETE_FILE_BY_IDX, idx, typeId}
}

export function clearFiles() : object {
  return {type: CLEAR_FILES}
}

export function setFilesType(filesType: FilesType) : object {
  return {type: SET_FILES_TYPE, filesType}
}

export function setLoading(state: boolean) : object {
  return {type: SET_LOADING, state}
}



/**
 * Async action creators
 */

export const getFiles = (id: number) => {
  return (dispatch, getState) => {
    const { type } = getState().files;
    switch(type) {
      case FilesType.People:
        return getPersonFiles(id, dispatch)
      case FilesType.Projects:
        return getProjectFiles(id, dispatch)
      case FilesType.Tasks:
        return getTaskFiles(id, dispatch)
    }
  }
}

export const getPersonFiles = (id: number, dispatch: any) => {
  return filesService.getPersonFiles(id)
    .then((res: any) => dispatch(setAllFiles(res)))
}

export const getProjectFiles = (id: number, dispatch: any) => {
  return filesService.getProjectFiles(id)
    .then((res: any) => dispatch(setAllFiles(res)))
}

export const getTaskFiles = (id: number, dispatch: any) => {
  return filesService.getTaskFiles(id)
    .then((res: any) => dispatch(setAllFiles(res)))
}


export const uploadFiles = (files: File[], category: string|number, id: number) => {
  return (dispatch, getState) => {
    const { type } = getState().files;
    const fd = multipleFilesInFormData(files, category);
    dispatch(setLoading(true))


    switch(type) {
      case FilesType.People:
        return uploadPersonFiles(fd, id, dispatch)
      case FilesType.Projects:
        return uploadProjectFiles(fd, id, dispatch)
      case FilesType.Tasks:
        return uploadTaskFiles(fd, id, dispatch)
    }
  }
}

export const uploadPersonFiles = (fd: FormData, id: number, dispatch: any) => {
  return filesService.uploadPersonFiles(fd, id)
    .then((res: any) => uploadSuccess(res, dispatch))
    .catch(err => uploadError(err, dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const uploadProjectFiles = (fd: FormData, id: number, dispatch: any) => {
  return filesService.uploadProjectFiles(fd, id)
    .then((res: any) => uploadSuccess(res, dispatch))
    .catch(err => uploadError(err, dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const uploadTaskFiles = (fd: FormData, id: number, dispatch: any) => {
  return filesService.uploadTaskFiles(fd, id)
    .then((res: any) => uploadSuccess(res, dispatch))
    .catch(err => uploadError(err, dispatch))
    .finally(() => dispatch(setLoading(false)))
}



export const deleteFile = (id: number, typeId: number, idx?: number) => {
  return (dispatch, getState) => {

    if(id === undefined && idx !== undefined) {
      dispatch(deleteSingleFileByIdx(idx, typeId))
      return Promise.resolve()
    }



    const { type } = getState().files;
    dispatch(setLoading(true))


    switch(type) {
      case FilesType.People:
        if(!typeId) return;
        return deletePersonFile(id, typeId, dispatch)
      case FilesType.Projects:
          if(!typeId) return;
        return deleteProjectFile(id, typeId, dispatch)
      case FilesType.Tasks:
        return deleteTaskFile(id, typeId, dispatch)
    }

    return Promise.reject(null)
  }
}

export const deletePersonFile = (id: number, typeId: number, dispatch: any) => {
  return filesService.deletePersonFile(id)
    .then((res: any) => deleteSuccess(id, typeId, dispatch))
    .catch(err => deleteError(err, dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const deleteProjectFile = (id: number, typeId: number, dispatch: any) => {
  return filesService.deleteProjectFile(id)
    .then((res: any) => deleteSuccess(id, typeId, dispatch))
    .catch(err => deleteError(err, dispatch))
    .finally(() => dispatch(setLoading(false)))
}

export const deleteTaskFile = (id: number, typeId: number, dispatch: any) => {
  return filesService.deleteTaskFile(id)
    .then((res: any) => deleteSuccess(id, typeId, dispatch))
    .catch(err => deleteError(err, dispatch))
    .finally(() => dispatch(setLoading(false)))
}


/**
 * Helpers
 */

const multipleFilesInFormData = (files : File[], type?: string|number) : FormData => {
  const fd = new FormData();
  files.map(file => fd.append('files', file))
  if(type) {
    fd.append('type', `${type}`);
  }
  return fd;
}

const uploadSuccess = (res: any, dispatch: any) : Promise<any> => {
  dispatch(addFiles(res))
  dispatch(setSuccess({ message: "Files successfully uploaded" }))
  return Promise.resolve(res);
}

const uploadError = (err: any, dispatch: any) : Promise<any> => {
  dispatch(setError("An error occured while uploading your files", err))
  return Promise.reject(false);
}

const deleteSuccess = (id: number, typeId: number, dispatch: any) : any => {
  dispatch(deleteSingleFile(id, typeId))
  dispatch(setSuccess({ message: "File was deleted." }))
}

const deleteError = (err: any, dispatch: any) : any => {
  dispatch(setError("An error occured while deleting the file", err))
}