import { ClickAwayListener, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Theme } from '../theme';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    position: 'absolute',
    left: '50%',
    top: '100%',
    zIndex: 100,
    transition: 'transform .1s ease',
    transformOrigin: 'top center',
    transform: 'translate(-50%, 0) scaleY(0)',
    opacity: 0
  },
  rootOpened: {
    opacity: 1,
    transform: 'translate(-50%, 0) scaleY(1)',
  },
  paper: {
    borderRadius: 0,
    boxShadow: '0 16px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
    padding: '15px 15px 17px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  closeIcon: {
    fontSize: 18,
    position: 'absolute',
    top: 11,
    right: 10,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  hasChildren: {
    minWidth: 250,
    minHeight: 40
  }
})

interface IProps extends WithStyles<typeof styles> {
  onClose: () => any;
  children?: any;
  opened?: boolean;
  disableBackdropClick?: boolean;
  className?: string;
}

const PopoverDialog = (props: IProps) => {
  const { classes, children } = props;


  const onClickAway = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    // if(!props.disableBackdropClick && props.opened) {
    //   props.onClose()
    // }
  }


  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={classNames(
        classes.root,
        props.className,
        {
          [classes.rootOpened]: props.opened,
          [classes.hasChildren]: !!children
        }
      )}>
        <Paper className={classes.paper}>

          {!!children && <CloseIcon className={classes.closeIcon} onClick={props.onClose} />}

          <div className={classes.content}>
            {children}
          </div>
        </Paper>
      </div>
    </ClickAwayListener>
  )
}


export default withStyles(styles)(PopoverDialog)