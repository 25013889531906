import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router';
import StoreDropdown from '../../../components/forms/meta/fields/StoreDropdown';
import MetaBasedForm from "../../../components/forms/meta/MetaBasedForm";
import { assignPeopleToProjectActions } from "../../../reducers/people/assignPeopleToProject";
import { IPerson } from '../../../shared/models/Person';
import { getProjects, getProjectsAssignables, clearProjectsLocal } from '../../../actions/projects/projects';


const projectsGetter = () => state => state.projects.componentProjects.results;

const AssignPeopleToProjectForm = props => {

  const dispatch = useDispatch();
  const store = useSelector((state:any) => state.assignPeopleToProject)
  const people = useSelector((state: any) => state.people.results);




  const assignPeopleToProjectMeta = [
    {name: 'project', placeholder: 'Select a Project', as: StoreDropdown, getOptions: projectsGetter()},
  ];



  const dispatchGetProjectsAssignables = (): any => {
    return dispatch(getProjectsAssignables())
  }


  const dispatchClearProjects = (): any => {
    return dispatch(clearProjectsLocal())
  }




  useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const ids = params.get('items');
      if(ids) {
        dispatchGetProjectsAssignables()
      }

  }, [people])



  useEffect(() => {
    return () => {
      dispatchClearProjects()
    }
  }, [])


    


  return (
    <MetaBasedForm
      meta={assignPeopleToProjectMeta}
      actions={assignPeopleToProjectActions}
      store={store}
    />
  )
};


export default withRouter(AssignPeopleToProjectForm)