import { useDispatch, useSelector } from "react-redux";
import { changeTaskField } from "../../../../actions/tasks";
import { isAdmin, isOwner, isMember } from "../../../../utils/user";
import { useMemo, useCallback } from "react";
import { useHistory } from "react-router";
import { ROUTE_DASHBOARD } from "../../../../constants/routes";

export const useTaskData = (name?: string): any => {
  const dispatch = useDispatch();
  const { task, updatedTask, errors, taskView } = useSelector((state: any) => state.board.tasks);
  const value = !!name ? (updatedTask[name] !== undefined ? updatedTask[name] : task[name]) : undefined;
  const error = !!name ? errors?.[name] : undefined;



  const onChange = useCallback((val: any): any => {
    return !!name && dispatch(changeTaskField({ [name]: val }))
  }, [name])



  const onChangeMultiple = useCallback((val: any): any => {
    return dispatch(changeTaskField(val))
  }, [])




  return { value, error, task, updatedTask, dispatch, onChange, onChangeMultiple, taskView }
}



export const useTaskAccess = (): any => {
  const user = useSelector((state: any) => state.user.user);
  const members = useSelector((state: any) => state.teamMembers);
  const { task, updatedTask } = useSelector((state: any) => state.board.tasks);

  

  const hasAccessToEditOwnStuff = useMemo((): boolean => {
    const createdBy = updatedTask.created_by ?? task.created_by;
    return isAdmin(user) || isOwner(user, members) || createdBy === user.id
  }, [user, members, updatedTask.created_by, task.created_by]);



  const hasFullAccess = useMemo((): boolean => (
    isAdmin(user) || isOwner(user, members)
  ), [user, members])



  const hasAccessToBoard = useMemo((): boolean => (
    isAdmin(user) || isMember(user, members) 
  ), [user, members])




  return {
    hasAccessToEditOwnStuff,
    hasFullAccess,
    hasAccessToBoard
  }
}



export const useTaskBackUrl = (): string => {
  const history = useHistory();
  const access = useTaskAccess();
  const projectId = useSelector((state: any) => state.board.tasks.task.project);
  const { backURL } = (history.location.state as any) ?? {};

  
  const alternativeBackURL = access.hasAccessToBoard
      ? `/projects/${projectId}/tasks`
      : ROUTE_DASHBOARD


  return backURL ?? alternativeBackURL
}