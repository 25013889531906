import { createStyles, WithStyles, withStyles } from "@material-ui/styles";
import React, { useEffect, Fragment, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { TableType, PrintType } from '../../shared/types';
import { Theme } from "../../theme";
import requestManager from "../../middleware/requestManager";
import { getActivity, getNextActivity, exportActivity } from '../../actions/activity';
import List from '../../components/list/List';
import { ISort, IDialogMenuItem, IPrintData } from "../../shared/interfaces";
import { ROUTE_ACTIVITY } from "../../constants/routes";
import { setLoadedPage } from "../../actions/common/loading";
import ListHeader from "../../components/list/ListHeader";
import CustomActivityFilters from "../../components/CustomActivityFilters";
import { setPrintData } from "../../actions/common/print";
import { setSearch } from "../../actions/search";
import { getIds } from "../../utils/utils";

interface RouterProps {
  id?: string
}

const styles = (theme: Theme) => createStyles({
  
})

interface IProps extends WithStyles<typeof styles> {
}


const ActivityPage = (props: IProps) => {
  const dispatch = useDispatch();
  const activity = useSelector((state: any) => state.activity);
  const { selected, filters } = useSelector((state: any) => state.list[TableType.ACTIVITY]);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const [sort, setSort] = useState<ISort[]>([]);
  const [search, setSearch] = useState<string>('');




  const onSort = (newSort: ISort[], newSearch: string): Promise<any> => {
    setSort(newSort);
    setSearch(newSearch)
    return dispatchGetActivity(newSort, newSearch, filters)
  }



  const onNext = (): Promise<any> => {
    return dispatchNextActivity()
  }



  const handleExportClick = (): void => {
    const newSelected = !!selected.length ? selected : getIds(activity.results);
    dispatchExportActivity(newSelected)
  }



  const handlePrint = (): void => {
    // const printData: IPrintData = { title: 'Contact Activity', list: activity.results }

    // dispatch(setPrintData(PrintType.ACTIVITY, printData));
    // window.open(`/print/activity`, '_blank')
  }



  const handleApplyFilters = () => {
    return dispatchGetActivity(sort, search, filters)
  }



  const dispatchGetActivity = (newSort: ISort[], newSearch: string, filters: any): Promise<any> => {
    return dispatch(getActivity(newSort, newSearch, filters))
      .then(() => {
        if (!pagesLoaded[ROUTE_ACTIVITY]) {
          dispatchOnPageLoaded(ROUTE_ACTIVITY)
        }
      })
  }



  const dispatchNextActivity = (): any => {
    return dispatch(getNextActivity())
  }



  const dispatchOnPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }



  const dispatchExportActivity = (items: number[]): any => {
    return dispatch(exportActivity(items))
  }





  useEffect(() => {
    return () => requestManager.cancelAllRequests()
  }, [])




  const filtersComponent = (
    <CustomActivityFilters
      onApply={handleApplyFilters}
      tableType={TableType.ACTIVITY}
    />
  )




  return (
    <Fragment>

      <ListHeader
        title="Contact Activity"
        count={activity?.count ?? activity.results.length}
        loading={activity.loading}
        fullAccess={true}
        selected={selected}
        selectedCounterLabel={selected.length === 1 ? 'action' : 'actions'}
        onExport={handleExportClick}
        actionsVisible={true}
        onPrint={handlePrint}
        customRightItem={filtersComponent}
      />

      <List wrapColumns selectable fullAccess
        tableType={TableType.ACTIVITY}
        initialLoading={!pagesLoaded[ROUTE_ACTIVITY]}
        loading={activity.loading}
        rows={activity.results}
        count={activity.count ?? activity.results.length}
        onSort={onSort}
        onNext={onNext}
      />
    </Fragment>
  )
}

export default withStyles(styles)(ActivityPage)
