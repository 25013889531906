import axios from 'axios';
import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/common/loading';
import requestManager from '../../middleware/requestManager';
import { REQUEST_CANCEL } from '../../shared/types';
import { axiosInstance } from '../../components/request/Request';



const RequestInterceptor = (props: any) => {
  const dispatch = useDispatch();


  
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const { requestId } = config;
      if(requestId) {
        const source = axios.CancelToken.source();
        config.cancelToken = source.token;
        requestManager.addRequest(requestId, source.cancel, config.url)
      }

      dispatch(setLoading(config.url));
      return config
    },
  
    (error: any) =>  {
      console.log(error)
      return Promise.reject(error);
    }
  )

  axiosInstance.interceptors.response.use(
    (response: any) => {
      const { requestId } = response.config;
      if (requestId) {
        requestManager.removeRequest(requestId);
      }

      dispatch(clearLoading(response.config.url));
      return response;
    },
    
    (error: any) => {
      const url = error?.message?.type === REQUEST_CANCEL
        ? error?.message?.url
        : error?.response?.config?.url

      if(!!url) {
        dispatch(clearLoading(url));
      }


      return Promise.reject(error);
    }
  );




  return props.children
}


export default RequestInterceptor