import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Switch } from "react-router-dom";
import { authenticatedRoutes, guestRoutes, overlayRoutes, renderRoutes, ROUTE_TASK } from '../../constants/routes';
import { isAdmin, isUser } from '../../utils/user';
import { isRoute } from '../../utils/utils';
import { RouteInterface } from '../../shared/interfaces';


interface IProps {
  
}

const MainRoutes = (props: IProps) => {
  const location = useLocation();
  const user = useSelector((state: any) => state.user.user);
  const [currentLocation, setCurrentLocation] = useState<any>(location);
  const [isModal, setIsModal] = useState<boolean>(false);
  const routes = isAdmin(user) || isUser(user) ? authenticatedRoutes : guestRoutes;




  
  useEffect(() => {
    const isModalRoute = overlayRoutes.some((route: RouteInterface) => isRoute(route.path));

    if(!isModalRoute) {
      setCurrentLocation(location);
    }

    setIsModal(isModalRoute)
  }, [location])




  return (
    <>
      <Switch location={currentLocation}>
        {renderRoutes(routes)}
      </Switch>

      {isModal ? renderRoutes(overlayRoutes) : null}
    </>
  )
}


export default MainRoutes