import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function getComments(taskId: number) {
  return Request.get(`${API_URL}/tasks/comments/?task=${taskId}`)
}

function createComment(body: any) {
  return Request.post(`${API_URL}/tasks/comments/`, body)
}

function updateComment(commentId: number, body: any) {
  return Request.patch(`${API_URL}/tasks/comments/${commentId}/`, body)
}

function deleteComment(commentId: number) {
  return Request.delete(`${API_URL}/tasks/comments/${commentId}/`)
}


export default {
  getComments,
  createComment,
  updateComment,
  deleteComment
}