import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function getExternalContacts(body: string = '') {
  return Request.get(`${API_URL}/external_contacts/${body}`)
}

function dismissContacts(ids: number[]) {
  return Request.delete(`${API_URL}/external_contacts/delete_multiple/`, { data: { ids } }, true)
}

function getNextContacts(url: string) {
  return Request.get(url)
}





export default {
  getExternalContacts,
  dismissContacts,
  getNextContacts
}