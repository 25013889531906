import { TableRow, TableRowProps } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { forwardRef, memo, MouseEvent, Ref, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { selectedAdd, selectedRemove } from '../../../actions/common/list';
import { ISort, TableColumn } from '../../../shared/interfaces';
import { TableType } from '../../../shared/types';
import { Theme } from '../../../theme';
import { BoardIcon } from '../../icons/BoardIcon';
import TableRowCell from './TableRowCell';
import TableRowCheckbox from './TableRowCheckbox';
import TableRowIndex from './TableRowIndex';
import TableSingleRowData from './TableSingleRowData';
import TableRowBoard from './TableRowBoard';
import { goTo } from '../../../utils/utils';
import { useHistory } from 'react-router';




const styles = (theme: Theme) => createStyles({
  root: {
    height: 48
  },
})

interface IProps extends WithStyles<typeof styles> {
  row: any;
  idx: number;
  currentPage: number;
  columns: TableColumn[];
  isSelected?: boolean;

  onOpen?: (e: MouseEvent<HTMLTableRowElement>, rowId: number) => any;
  className?: any;
  groupBy?: string
  fullAccess?: boolean;
  onInvite?: (e: any, rowId: number) => any;
  tableType: TableType;
  wrapColumns?: boolean;
  hasRef?: boolean;
  onRef?: (ref: Ref<TableRowProps>|null) => any;
  selectable?: boolean;

  setNewContactsEmail: any;
  setNewContactsProps: any;
}

const TableSingleRow = (props: IProps, ref: Ref<any>) => {
  const { classes, row, columns } = props;
  const dispatch = useDispatch();
  const history = useHistory();



  const onOpen = (e: MouseEvent<HTMLTableRowElement>): void => {
    props.onOpen?.(e, row.id)
  }



  const onToggle = useCallback((e: any): void => {
    props.isSelected
      ? dispatchSelectedRemove()
      : dispatchSelectedAdd()
  }, [props.isSelected])


  const onViewBoard = (e: any) => {
    goTo(e, `/projects/${row.id}/tasks`, history)
  }



  const getRef = (r: Ref<TableRowProps>|null): any => {
    if(!props.hasRef) return;

    props.onRef?.(r)
  }



  const dispatchSelectedRemove = (): any => {
    return dispatch(selectedRemove(props.tableType, row.id))
  }



  const dispatchSelectedAdd = (): any => {
    return dispatch(selectedAdd(props.tableType, row.id))
  }








  return (
    <TableRow
      ref={getRef}
      onClick={onOpen}
      className={classNames(
        classes.root,
        'print-root',
        props.className
      )}
    >


      
          {/* <TableRowCell className={classes.cellForCount}>
            {!props.groupBy && props.currentPage !== undefined ? props.currentPage * 100 + (props.idx + 1) : null}
          </TableRowCell> */}

          <TableRowIndex idx={props.idx} hidden={!!props.groupBy} />


          {props.fullAccess && props.selectable && (
            <TableRowCheckbox
              isSelected={!!props.isSelected}
              onChange={onToggle}
              className="hide-on-print"
            />
          )}



          <TableSingleRowData
            wrapColumns={props.wrapColumns}
            columns={columns}
            row={row}
            rowIdx={props.idx}
            onInvite={props.onInvite}
            groupBy={props.groupBy}
            fullAccess={props.fullAccess}
            tableType={props.tableType}
            setNewContactsEmail={props.setNewContactsEmail}
            setNewContactsProps={props.setNewContactsProps}
           />



          {props.fullAccess && props.tableType === TableType.PROJECTS && (
            <TableRowBoard onClick={onViewBoard} className="hide-on-print" />
          )}


        </TableRow>
  )
}


// TableSingleRow.whyDidYouRender = true

export default withStyles(styles)(
  memo(forwardRef(TableSingleRow), (prev: IProps, next: IProps) => {
    return prev.isSelected === next.isSelected
      && prev.idx === next.idx
      && JSON.stringify(prev.columns) === JSON.stringify(next.columns)
      && JSON.stringify(prev.row) === JSON.stringify(next.row)
      && JSON.stringify(prev.className) === JSON.stringify(next.className)
  })
  
)
