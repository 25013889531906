import * as actions from '../actions/board-filters';

export const initialState = {
  status: 0,
  assignees: [],
  dueDate: null
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_BOARD_FILTERS_STATUS:
      return {
        ...state,
        status: action.status
      };

    case actions.SET_BOARD_FILTERS_ASSIGNEES:
      return {
        ...state,
        assignees: action.assignees
      };
    
    case actions.SET_BOARD_FILTERS_DUE_DATE:
      return {
        ...state,
        dueDate: action.dueDate
      };

    case actions.RESET_BOARD_FILTERS:
      return {
        ...state,
        ...initialState
      };
  
    default:
      return state
  }
}