import { Grid, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import * as React from "react";
import { Theme } from "../theme";
import { ClickFunction } from "../utils/typescriptUtils";
import Footer from "./footer/FooterSmall";
import InputsAndButtonsForm from "./forms/InputsAndButtonsForm";


const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#f7f9fa'
  },
  mainGrid: {
    height: 'calc(100vh - 45px)'
  },
  logoGrid: {
    width: '100%',
    margin: '0 5%',
    padding: 0,
    maxWidth: 450,
    textAlign: 'center'
  },
  logoImg: {
    width: "65%",
    paddingBottom: 39,
  },
  formGrid: {
    padding: '0 10%'
  },
  form: {
    minWidth: 300
  }
});

interface IProps extends WithStyles<typeof styles> {
  onLoginClick: (email: string, password: string) => void,
  onResetPasswordClick: ClickFunction
}

interface IState {
  email: string
  password: string
}

class NewLoginPage extends React.Component<IProps, IState> {

  readonly state = {
    email: "",
    password: ""
  };

  submitLogin = (e): void => {
    e.preventDefault();
    e.stopPropagation();

    const { email, password } = this.state;
    if (!email || !password) return;

    this.props.onLoginClick(email, password);
  }

  handleInputEnter = (e: any): void => {
    const { email, password } = this.state;
    this.props.onLoginClick(email, password);
  }



  render() {
    const { classes, onResetPasswordClick } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          alignContent="center"
          alignItems="center"
          justify="center"
          className={classes.mainGrid}
        >
          <Grid item sm={6} className={classes.logoGrid}>
            <img className={classes.logoImg} src="/img/full_logo.svg" alt="FinTech8 Logo" />
            <form className={classes.form} onSubmit={this.submitLogin}>
              <InputsAndButtonsForm
                inputs={[
                  {
                    value: this.state.email,
                    label: 'Email',
                    onChange: (value: string) => this.setState({ email: value.toLowerCase() }),
                    onEnter: this.handleInputEnter
                  },
                  {
                    value: this.state.password,
                    label: 'Password',
                    onChange: (value: string) => this.setState({ password: value }),
                    onEnter: this.handleInputEnter,
                    type: 'password'
                  }
                ]}
                buttons={[
                  {
                    name: 'Forgot your password?',
                    onClick: onResetPasswordClick,
                    primary: false,
                    small: true,
                    type: 'button'
                  },
                  {
                    name: 'Log In',
                    primary: true,
                    type: "submit"
                  },
                ]}
              >
              </InputsAndButtonsForm>

            </form>
            <Footer />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NewLoginPage)