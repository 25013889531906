import * as actions from '../../actions/common/print';
import { PrintType } from '../../shared/types';
import { IPrintData } from '../../shared/interfaces';

interface IState {
  type?: PrintType;
  data?: IPrintData;
}

const initialState: IState = {
  type: undefined,
  data: {
    title: '',
    description: [],
    descriptionCaption: undefined,
    stats: undefined,
    list: undefined,
    listGroupBy: undefined,
    requestURL: undefined
  },
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_PRINT_DATA:
      return {
        type: action.printType,
        data: action.data
      }

    case actions.CLEAR_PRINT:
      return initialState
  
    default:
      return state
  }
}