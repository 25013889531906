import { combineReducers } from 'redux';
import authReducer from './auth';
import boardColumnsReducer from './board-columns';
import boardFiltersReducer from './board-filters';
import capitalProviderReducer from './capital-provider';
import commentsReducer from './comments';
import loadingReducer from './common/loading';
import notificationsReducer from "./common/notifications";
import paginationReducer from './common/pagination';
import printReducer from './common/print';
import emailReducer from './email';
import emailDraftsReducer from './email-drafts';
import emailTemplatesReducer from './email-templates';
import { currentEmailTemplateReducer } from './email/current-template';
import { currentEmailDraftsReducer } from './email/current-drafts';
import externalContactsReducer from './external-contacts';
import filesReducer from './files';
import organizationsReducer from './organizations';
import outlookReducer from './outlook';
import { assignPeopleToProjectReducer } from './people/assignPeopleToProject';
import { emailPeopleReducer } from "./people/email";
import membershipReducer from './people/membership';
import { multiplePeopleReducer } from "./people/edit-multiple";
import peopleReducer from './people/people';
import emailProjectsReducer from "./projects/email";
import projectsReducer from './projects/projects';
import projectProvidersReducer from './projects/providers';
import searchReducer from './search';
import searchHistoryReducer from './search-history';
import tagsReducer from './tags';
import tasksReducer from './tasks';
import typesReducer from './types';
import activityReducer from './activity';
import importedDuplicatesReducer from './contact-management/imported-duplicates';
import { currentImportedDuplicateReducer } from './contact-management/current-imported-duplicate';
import possibleDuplicatesReducer from './contact-management/possible-duplicates';
import { currentPossibleDuplicateReducer } from './contact-management/current-possible-duplicate';
import deletedContactsReducer from './contact-management/deleted-contacts';
import listReducer from './common/list';
import advancedSearchReducer from './advanced-search';




const rootReducer = combineReducers({
  people: peopleReducer,
  multiplePeople: multiplePeopleReducer,
  emailPeople: emailPeopleReducer,
  teamMembers: membershipReducer,
  projects: projectsReducer,
  emailProjects: emailProjectsReducer,
  projectProviders: projectProvidersReducer,
  user: authReducer,
  capitalProvider: capitalProviderReducer,
  types: typesReducer,
  organizations: organizationsReducer,
  tags: tagsReducer,
  files: filesReducer,
  notifications: notificationsReducer,
  emails: emailReducer,
  search: searchReducer,
  pagination: paginationReducer,
  board: combineReducers({
    tasks: tasksReducer,
    columns: boardColumnsReducer,
    filters: boardFiltersReducer,
    comments: commentsReducer
  }),
  assignPeopleToProject: assignPeopleToProjectReducer,
  print: printReducer,
  loading: loadingReducer,
  externalContacts: externalContactsReducer,
  searchHistory: searchHistoryReducer,
  outlook: outlookReducer,
  emailManagement: combineReducers({
    drafts: emailDraftsReducer,
    templates: emailTemplatesReducer,
    currentTemplate: currentEmailTemplateReducer,
    currentDrafts: currentEmailDraftsReducer
  }),
  contactManagement: combineReducers({
    importedDuplicates: importedDuplicatesReducer,
    currentImportedDuplicate: currentImportedDuplicateReducer,
    possibleDuplicates: possibleDuplicatesReducer,
    currentPossibleDuplicate: currentPossibleDuplicateReducer,
    deletedContacts: deletedContactsReducer
  }),
  activity: activityReducer,
  list: listReducer,
  advancedSearch: advancedSearchReducer
})

export default rootReducer;