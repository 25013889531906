import { Paper } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Route, RouteComponentProps, Switch, withRouter, RouteProps } from 'react-router';
import { IManagementTab } from '../../shared/interfaces';
import { Theme } from '../../theme';
import { isRoute, goTo } from '../../utils/utils';


const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  paper: {
    width: '100%',
    minHeight: 547,
    borderRadius: 0,
    padding: 0,
    boxSizing: 'border-box',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  content: {
    width: 'calc(100% - 232px)',
    marginLeft: 232,
    position: 'relative',
    minHeight: 547,
    borderRadius: 0,
    padding: 0,
    margin: '0 0 55px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 16px',
    height: 30
  },

  headerTitle: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#233539',
    margin: 0
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  sidebar: {
    minWidth: 210,
    marginRight: 22,
    width: 210,
    minHeight: 'calc(100vh - 144px)',
    position: 'absolute',
  },
  sidebarFixed: {
    top: 76,
    width: 210,
    position: 'fixed',
    height: '100%'
  },
  tabs: {
    
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '13px 10px 13px 22px',
    cursor: 'pointer',
    transition: 'all .1s ease',
    color: '#657274',
    '&:hover, &$tabActive': {
      backgroundColor: '#e8f2fd',
      color: theme.palette.primary.main,
      '& > $tabIcon': {
        color: theme.palette.primary.main,
      }
    }
  },
  tabIcon: {
    margin: '0 17px 0 0 !important',
    fontSize: 17,
    color: '#90a0a7'
  },
  tabLabel: {
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.54,
    color: 'inherit',
  },
  tabActive: {}
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  tabs: IManagementTab[];
  title: string;
  actions?: any;
  children?: any;
  withPaper?: boolean;
}

const ManagementContainer = (props: IProps) => {
  const { classes, history } = props;
  const [sidebarFixed, setSidebarFixed] = useState<boolean>(false);



  const onScroll = (): void => {
    return setSidebarFixed(window.scrollY > 68)
  }



  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll)
  }, [])



  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1 className={classes.headerTitle}>{props.title}</h1>
        
        {props.actions}

      </div>

      <div className={classes.container}>
      
        <Paper
          className={classNames(
            classes.paper,
            classes.sidebar,
            {[classes.sidebarFixed]: sidebarFixed}
          )}
        >
          {props.tabs.map((route: IManagementTab) => {
            const { icon: Icon } = route;

            return (
              <div key={route.id}
                className={classNames(
                  classes.tab,
                  {[classes.tabActive]: isRoute(route.path)}
                )}
                onClick={(e: any) => goTo(e, route.path, history)}
              >
                {route.icon && <Icon className={classes.tabIcon} />}
                <span className={classes.tabLabel}>{route.label}</span>
              </div>
            )
          })}
        </Paper>



        {props.withPaper
          ? <Paper className={classes.content}>{props.children}</Paper>
          : props.children
        }
        


      </div>

      
    </div>
  )
}


export default withStyles(styles)(
  withRouter(ManagementContainer)
)