import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterDueDate } from '../../../../actions/board-filters';
import { overridesNameToClassKey } from '../../../../overrides-mui';
import { useOpen } from '../../../../shared/hooks';
import { Theme } from '../../../../theme';
import { formatDate } from '../../../../utils/utils';
import Datepicker from '../../../Datepicker';
import { DatepickerIcon } from '../../../icons/DatepickerIcon';

const styles = (theme: Theme) => createStyles({
  datePicker: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: -272,
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  active: {
    '& > span': {
      color: '#233539 !important'
    }
  },
  activeIcon: {
    color: `${theme.palette.primary.main} !important`
  },
  dpOpened: {
    display: 'block'
  }
})

interface IProps extends WithStyles<typeof styles>, overridesNameToClassKey {
  className?: any;
  iconClassName?: any;
}

const FilterDueDate = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const dueDate = useSelector((state: any) => state.board.filters.dueDate);
  const dateOpened = useOpen();
  
  
  const handleDueDate = (date: any) : void => {
    dateOpened.close();
    dispatch(setFilterDueDate(date.format()))
  }

  const getDueDate = () : string => {
    return dueDate ? formatDate(dueDate) : 'Due Date'
  }

  useEffect(() => {
    dateOpened.close()
  }, [dueDate])

  return (
    <Fragment>
      <div
        onClick={dateOpened.toggle}
        className={classNames(
          props.className,
          {[classes.active]: !!dueDate}
        )}
      >
        <DatepickerIcon className={classNames(
          props.iconClassName,
          {[classes.activeIcon]: !!dueDate}
        )} />
        <span>{getDueDate()}</span>
      </div>
      <Datepicker 
        value={Date.parse(dueDate)}
        className={classNames(
          classes.datePicker,
          {[classes.dpOpened]: dateOpened.value}
        )}
        onChange={handleDueDate}
      />  
    </Fragment>
  )
}


export default withStyles(styles)(FilterDueDate)