import React, { useMemo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { TableType } from '../../../shared/types';
import { TableRow, TableCell } from '@material-ui/core';
import { TableColumn } from '../../../shared/interfaces';
import TableSumRowColumn from './TableSumRowColumn';

const styles = (theme: Theme) => createStyles({
  cell: {
    borderTop: '1px double #233539'
  },
  topCell: {
    borderBottom: '1px solid #233539'
  },
  hidden: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  type: TableType;
  hidden?: boolean;
  columns: TableColumn[];
  rows: any[];
  isTop?: boolean;
}


const TableSumRow = (props: IProps) => {
  const { classes } = props;




  const hidden = useMemo((): boolean => (
    props.type !== TableType.PROJECTS || !!props.hidden
  ), [props.type, props.hidden])







  return (
    <TableRow className={classNames(
      {[classes.hidden]: hidden}
    )}>

      
      <TableCell className={classNames({
        [classes.cell]: !props.isTop,
        [classes.topCell]: props.isTop
      })} />

      {props.columns.map((column: TableColumn, idx: number) => (
        <TableSumRowColumn
          key={column.type} 
          idx={idx}
          column={column}
          rows={props.rows}
          lastCol={idx === props.columns.length - 1}
          className={classNames(
            'table-sum-row-print',
            {
              [classes.cell]: !props.isTop,
              [classes.topCell]: props.isTop
            }
          )}
        />
      ))}

    </TableRow>
  )
}


export default withStyles(styles)(TableSumRow)