import React, { memo } from "react";
import { Theme, palette } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import {StarRate} from "@material-ui/icons";
import { range } from "../utils/utils";

const styles = (theme: Theme) => createStyles({
  root: {
    ...theme.typography.body2,
    minWidth: 120,
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.67,
    display: 'flex'
  },
  star: {
    color: palette.yellow500,
    width: 22,
    height: 22
  }
});


const areEqual = (prev: IProps, next: IProps): boolean => {
  return prev.rate === next.rate
}

interface IProps extends WithStyles<typeof styles> {
  rate?: number
}

const Rating = ({ classes, rate }: IProps) => (
  <div className={classes.root}>
    {!!rate
      ? range(rate).map((i: number) => (<StarRate key={i} className={classes.star}/>))
      : 'Not Rated'
    }
  </div>
)

export default withStyles(styles)(
  memo(Rating, areEqual)
)