import React from "react"
import { WithStyles, createStyles, withStyles } from "@material-ui/styles"
import { IPerson } from "../shared/models/Person"
import { Theme } from "../theme"
import { formatDateTime } from "../utils/utils"
import { getUsername } from "../utils/user"


const styles = (theme: Theme) => createStyles({
  root: {
  },
  bold: {
    fontWeight: 500
  },
  createdUpdated: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    color: '#90a0a7',

    '&:last-child': {
      marginBottom: 16
    }
  },
  createdText: {
    lineHeight: 1.8,
    marginTop: 13
  }
})

interface IProps extends WithStyles<typeof styles> {
  created_by?: IPerson;
  created_at?: number;
  updated_by?: IPerson;
  updated_at?: number;
  merged_by?: IPerson;
  merged_at?: number;
  reactivated_by?: IPerson;
  reactivated_at?: number;
}

const CreatedUpdatedFooter = (props: IProps) => {
  const { classes, created_at, created_by, updated_at, updated_by, merged_at, merged_by, reactivated_by, reactivated_at } = props

  return (
    <div className={classes.createdText}>
      {
        created_by
          ? <div className={classes.createdUpdated}>Created by: <span className={classes.bold}>{getUsername(created_by)}, {formatDateTime(created_at)}</span></div>
          : <div className={classes.createdUpdated}>Created at: <span className={classes.bold}>{formatDateTime(created_at)}</span></div>
      }

      
      {
        updated_by
          ? <div className={classes.createdUpdated}>Updated by: <span className={classes.bold}>{getUsername(updated_by)}, {formatDateTime(updated_at)}</span></div>
          : <div className={classes.createdUpdated}>Updated at: <span className={classes.bold}>{formatDateTime(updated_at)}</span></div>
      }


      {merged_at && merged_by && (
        <div className={classes.createdUpdated}>Merged by: <span className={classes.bold}>{getUsername(merged_by)}, {formatDateTime(merged_at)}</span></div>
      )}


      {reactivated_at && reactivated_by && (
        <div className={classes.createdUpdated}>Reactivated by: <span className={classes.bold}>{getUsername(reactivated_by)}, {formatDateTime(reactivated_at)}</span></div>
      )}


    </div>
  )

}


export default withStyles(styles)(CreatedUpdatedFooter)