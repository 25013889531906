import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const DatepickerIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="13" height="13" viewBox="0 0 13 13">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M5.958 0h1.084v1.083H5.958zM9.208 0h1.084v1.083H9.208zM2.708 0h1.084v1.083H2.708zM12.458 1.083h-2.166v1.625H9.208V1.083H7.042v1.625H5.958V1.083H3.792v1.625H2.708V1.083H.542c-.3 0-.542.243-.542.542v2.167h13V1.625c0-.3-.243-.542-.542-.542zM0 12.458c0 .3.243.542.542.542h11.916c.3 0 .542-.243.542-.542V4.333H0v8.125z"/>
      </g>
    </SvgIcon>
  );
};