import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const PeopleIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="30" height="20" viewBox="0 0 30 20">
        <g fill="none" fillRule="evenodd">
          <path d="M-1-6h32v32H-1z"/>
          <path fill="currentColor" fillRule="nonzero" d="M20.333 8.667a3.983 3.983 0 0 0 3.987-4c0-2.214-1.773-4-3.987-4-2.213 0-4 1.786-4 4 0 2.213 1.787 4 4 4zm-10.666 0a3.983 3.983 0 0 0 3.986-4c0-2.214-1.773-4-3.986-4-2.214 0-4 1.786-4 4 0 2.213 1.786 4 4 4zm0 2.666C6.56 11.333.333 12.893.333 16v3.333H19V16c0-3.107-6.227-4.667-9.333-4.667zm10.666 0c-.386 0-.826.027-1.293.067 1.547 1.12 2.627 2.627 2.627 4.6v3.333h8V16c0-3.107-6.227-4.667-9.334-4.667z"/>
        </g>
    </SvgIcon>
  );
}

