import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import { useAnchor } from '../shared/hooks';
import Menu from './Menu';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  option: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#657274',
    cursor: 'pointer',
    minHeight: 'initial',
    '&:hover': {
      color: '#4192ec',
      backgroundColor: 'transparent'
    },
  },
  optionSelected: {
    color: '#4192ec',
    backgroundColor: 'transparent !important'
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
      color: theme.palette.primary.main
    }
  },
  icon: {
    color: '#4192ec',
    width: 20,
    height: 20,
    transition: 'transform .1s ease'
  },
  iconOpened: {
    transform: 'rotate(180deg)'
  }
})

interface Filter {
  type: any;
  label: string;
  onClick: Function;
}

interface IProps extends WithStyles<typeof styles> {
  options: Filter[];
  selected: Filter;
}

const Filters = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();
  const activeOption = props.options.find(option => option.type === props.selected);
  const activeOptionLabel = (activeOption && activeOption.label) || '';

  const handleOptionClick = (e: any, option: Filter) : void => {
    anchor.close();
    option.onClick();
  }


  return (
    <div className={classes.root}>
      <div className={classes.label} onClick={anchor.set}>
        <span>{activeOptionLabel}</span>
        <ArrowDown className={classNames(
          classes.icon,
          {[classes.iconOpened]: !!anchor.value}
        )} />
      </div>
      <Menu
        anchor={anchor}
        options={props.options}
        onSelect={handleOptionClick}
        optionValue="label"
        selected={props.selected}
        selectedField="type"
      />
    </div>
  )
}


export default withStyles(styles)(Filters)