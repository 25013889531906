import { EmailType, PhoneType, SearchType, InputType } from "./types";
import { ISearchRow } from "./models/Search";
import { MouseEvent } from "react";

export interface IPhone {
  phone: string;
  type: PhoneType;
}

export interface IEmail {
  email: string;
  type: EmailType;
}

export interface IMessageAuthor {
  id?: any;
  first_name: string;
  last_name: string;
}

export interface IMessage {
  id: number;
  sender: IPersonShort;
  receiver: IPersonShort[];
  cc: IPersonShort[];
  bcc: IPersonShort[];
  subject: string;
  content: string;
  html_content: string;
  date: string;
  conversation_id: string|null;
  is_forwarded?: boolean;
  external_bcc?: string[];
  external_cc?: string[];
  external_to?: string[];
}


export interface IPersonShort {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  company: string;
  emails: string[];
  phones: string[];
  profile_type: number;
  categories: number[];
  subcategories: number[];
}

export interface ITask {
  id: number;
  title: string;
  description: string;
  due_date: string;

  project: any;
  project_details: any;
  column: number;

  created_by?: string;
  created_by_details?: any;

  state: number;
}

export interface INote {
  id: number;
  text: string;
  person: number;
  created_at?: number;
  updated_at?: number;
}

export interface TableColumn {
  label: string;
  labelOnTable?: string;
  value: (...args: any[]) => any;
  valueType?: InputType;
  numericValue?: (...args: any[]) => number;
  type: string;
  typeField?: string;
  group?: number;
  groupFor?: string;
  groupDetails?: any;
  isGroupProp?: boolean;
  default?: boolean;
  defaultSortOrder?: number;
  defaultSortDirection?: SortDirection;
  search?: string | string[];
  action?: any;
  tableWidth?: string;
  editable?: boolean;
  manageHidden?: boolean;
}

export interface IShowAll {
  value: boolean;
  toggle: () => any;
}

export interface Pagination {
  limit: number,
  offset?: number,
  page?: number
}

export interface IAutocompleteTag {
  id: number,
  title: string
}

export interface IDialogMenuItem {
  id: number;
  title: string;
  subtitle?: string;
  icon?: any;
  sortValue?: string;
}


export enum SortDirection {
  ASC = 1,
  DESC
}

export interface IPrintDataDescription {
  title: string;
  value: string|number;
}

export interface IPrintData {
  title?: string;
  description?: IPrintDataDescription[];
  descriptionCaption?: IPrintDataDescription;
  stats?: any;
  list?: any;
  listGroupBy?: string;
  requestURL?: string;
  groupsCollapsed?: string[];
}

export interface ISearchHistoryItem {
  id: string;
  title: string;
  type: SearchType;
  query: string;
  rows: ISearchRow[];
}

export interface ManageableTableColumn {
  type: string;
  label: string
  active: boolean;
  order: number;
  hidden?: boolean;
  group?: number;
  search?: string|string[];
  defaultSortOrder?: number;
  defaultSortDirection?: SortDirection;
  sortOrder?: number;
  sortDirection?: SortDirection;
}

export interface IManagementTab {
  id: number;
  label: string;
  icon: any;
  path: string;
}


export interface ISort {
  type: string;
  column?: string | string[];
  direction: SortDirection;
}


export interface IPopoverTipAction {
  label: string;
  onClick: (...args: any[]) => any
}


export interface ButtonWithMenuProps {
  anchor: any;
  options: any[];
  onSelect: (option: any, ...args: any[]) => any;
}

export interface IAnchor {
  value: null | HTMLElement;
  set: (el: MouseEvent<HTMLElement|SVGSVGElement>) => any;
  toggle: (el: MouseEvent<HTMLElement|SVGSVGElement>) => any;
  close: () => any;
}


export interface LSNewTaskData {
  columnId: string|number;
  projectId: string|number;
}


export interface EmailFilters {
  sender?: any;
  receiver?: any;
  search?: string;
  date_from?: string;
  date_to?: string;
}



export interface SelectItem {
  id?: number;
  name: string;
  [args: string]: any;
}




export interface RouteInterface {
  path?: string | string[],
  exact?: boolean,
  component?: any,
  render?: any
}


export interface BulkEditRowItem {
  label?: string;
  name: string;
  onChange: (...args: any[]) => any;
  as?: any;
  [otherProps: string]: any;
}



export interface BulkEditRowItemData {
  value: any;
  is_append?: boolean;
}



export interface BulkEditGroupItem {
  name: string;
  label?: string;
  placeholder?: string;
}



export interface EntityField {
  name: string;
  [otherProp: string]: any;
}