import React, { Fragment } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import Button from '../Button';
import { PeopleIcon } from '../icons/PeopleIcon';
import { ProjectsIcon } from '../icons/ProjectsIcon';
import { TaskIcon } from '../icons/TaskIcon';
import classNames from 'classnames';
import { SearchType } from '../../shared/types';

const styles = (theme: Theme) => createStyles({
  button: {
    width: 105,
    height: 53,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: 10,
    '&:last-child': {
      marginRight: 0
    },
  },
  buttonActive: {
    backgroundColor: '#e8f2fd'
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.67,
    color: '#4192ec',
  },
  icon: {
    fontSize: 24,
    color: theme.palette.primary.main,
    marginRight: 8
  }
})

interface IProps extends WithStyles<typeof styles> {
  active?: SearchType;
  onSelect: (searchType: SearchType) => any;
}

const SearchButtons = (props: IProps) => {
  const { classes, onSelect, active } = props;

  return (
    <Fragment>
      <Button
        onClick={(e) => onSelect(SearchType.People)}
        className={classNames(
          classes.button,
          {[classes.buttonActive]: active === SearchType.People}
        )}
      >
        <PeopleIcon className={classes.icon} />
        <span className={classes.buttonText}>Contacts</span>
      </Button>
      <Button
        onClick={(e) => onSelect(SearchType.Projects)}
        className={classNames(
          classes.button,
          {[classes.buttonActive]: active === SearchType.Projects}
        )}
      >
        <ProjectsIcon className={classes.icon} />
        <span className={classes.buttonText}>Projects</span>
      </Button>
      <Button
        onClick={(e) => onSelect(SearchType.Tasks)}
        className={classNames(
          classes.button,
          {[classes.buttonActive]: active === SearchType.Tasks}
        )}
      >
        <TaskIcon className={classes.icon} />
        <span className={classes.buttonText}>Tasks</span>
      </Button>
    </Fragment>
  )
}


export default withStyles(styles)(SearchButtons)