import * as React from "react";
import {Theme} from "../../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import InputsAndButtonsForm from "../forms/InputsAndButtonsForm";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
  onPasswordReset: (password: string) => void,
  header: string,
  description: string,
  responseErrors?: string[],
}

interface IState {
  password: string,
  confirmPassword: string,
  passwordError?: string,
  confirmPasswordError?: string,
}

class Reset extends React.Component<IProps, IState> {
  
  state = {
    password: '',
    confirmPassword: '',
    passwordError: undefined,
    confirmPasswordError: undefined,
  };
  
  handleClick = () => {
    const {onPasswordReset} = this.props;
    if (this.state.password.length === 0) {
      this.setState({
        passwordError: "Password can't be empty",
        confirmPasswordError: undefined,
      });
      return;
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        passwordError: undefined,
        confirmPasswordError: "Passwords do not match",
      });
      return;
    }
    this.setState({
      passwordError: undefined,
      confirmPasswordError: undefined,
    });
    onPasswordReset(this.state.password);
  };
  
  render() {
    return (
      <InputsAndButtonsForm
        header={this.props.header}
        description={this.props.description}
        inputs={[
          {
            value: this.state.password,
            label: 'Password',
            onChange: (value: string) => {
              this.setState({ password: value });
            },
            type: 'password',
            errorMessage: this.state.passwordError
          },
          {
            value: this.state.confirmPassword,
            label: 'Confirm Password',
            onChange: (value: string) => {
              this.setState({ confirmPassword: value });
            },
            type: 'password',
            errorMessage: this.state.confirmPasswordError
          },
        ]}
        errors={this.props.responseErrors}
        buttons={[
          {
            name: 'Save Password',
            onClick: this.handleClick,
            primary: true,
          },
        ]}
      >
      </InputsAndButtonsForm>
    );
  }
}

export default withStyles(styles)(Reset)