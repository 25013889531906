import searchService from '../services/advanced-search';
import { ISort } from "../shared/interfaces";
import { formatSortObject } from './helpers/helpers';
import { setError } from './common/notifications';
import { SearchType } from '../shared/types';
import { ISearchRow, ISearchSubRow } from '../shared/models/Search';

/**
 * Actions
 */

export const SET_RESULTS          = 'ADVANCED_SEARCH_SET_RESULTS';
export const SET_LOADING          = 'ADVANCED_SEARCH_SET_LOADING';
export const APPEND_RESULTS       = 'ADVANCED_SEARCH_APPEND_RESULTS';
export const REMOVE_FROM_RESULTS  = 'ADVANCED_SEARCH_REMOVE_FROM_RESULTS'
export const CLEAR_RESULTS        = 'ADVANCED_SEARCH_CLEAR_RESULTS';
export const SET_ROWS             = 'ADVANCED_SEARCH_SET_ROWS';
export const CLEAR_ROWS           = 'ADVANCED_SEARCH_CLEAR_ROWS';
export const ADD_ROW              = 'ADVANCED_SEARCH_ADD_ROW';
export const DELETE_ROW           = 'ADVANCED_SEARCH_DELETE_ROW';
export const CHANGE_ROW           = 'ADVANCED_SEARCH_CHANGE_ROW';
export const CHANGE_SUBROW        = 'ADVANCED_SEARCH_CHANGE_SUBROW';



/**
 * Action creators
 */

export function setResults(data: any): object {
  return {type: SET_RESULTS, data}
}

export function appendResults(data: any): object {
  return {type: APPEND_RESULTS, ...data}
}

export function removeFromResults(ids: number[]): object {
  return {type: REMOVE_FROM_RESULTS, ids}
}

export function clearResults(): object {
  return {type: CLEAR_RESULTS}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}

export function setSearchRows(data: ISearchRow[]): object {
  return {type: SET_ROWS, data}
}

export function clearSearchRows(): object {
  return {type: CLEAR_ROWS}
}

export function addSearchRow(row: ISearchRow): object {
  return {type: ADD_ROW, row}
}

export function deleteSearchRow(idx: number): object {
  return {type: DELETE_ROW, idx}
}

export function changeSearchRow(idx: number, data: any): object {
  return {type: CHANGE_ROW, idx, data}
}

export function changeSearchSubRow(idx: number, parentIdx: number, data: any): object {
  return {type: CHANGE_SUBROW, idx, parentIdx, data}
}





/**
 * Async action creators
 */

export const advancedSearch = (type: SearchType, sort: ISort[], search: string): any => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);


    const action = getActionByType(type);
    if(!action) return;

    dispatch(setLoading(true));


    return action(body)
      .then((res: any) => {
        dispatch(setResults(res));

        return Promise.resolve(res)
      })
      .catch(err => {
        const message = err?.response?.status === 404 ? 'No search results' : 'An error occured while searching';
        dispatch(setError(message, err))

        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const advancedSearchNext = (): any => {
  return (dispatch, getState) => {
    const nextURL = getState().advancedSearch.list.next;
    if(!nextURL) return Promise.reject(null);


    return searchService.searchNext(nextURL)
      .then((res: any) => {
        dispatch(appendResults(res));

        return Promise.resolve(res)
      })
      .catch(err => {
        const message = err?.response?.status === 404 ? 'No search results' : 'An error occured while searching';
        dispatch(setError(message, err))

        return Promise.reject(err)
      })
  }
}



/**
 * Helpers
 */

const getActionByType = (type: SearchType): Function|undefined => {
  switch(type) {
    case SearchType.People:
      return searchService.searchPeople
    case SearchType.Projects:
      return searchService.searchProjects
    case SearchType.Tasks:
      return searchService.searchTasks
  }
}