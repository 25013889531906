import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { IconButton, MenuItem } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { ClickFunction } from '../utils/typescriptUtils';
import MenuOption from './MenuOption';
import { useAnchor } from '../shared/hooks';
import Menu from './Menu';


const ITEM_HEIGHT = 48;

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    cursor: 'pointer'
  },
  option: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#657274',
    cursor: 'pointer',
    minHeight: 'initial',
    '&:hover': {
      color: '#4192ec',
      backgroundColor: 'transparent'
    }
  },
  icon: {
    padding: 0
  }
})

interface IProps extends WithStyles<typeof styles> {
  onEdit?: ClickFunction;
  onDelete?: ClickFunction;
  onRevoke?: ClickFunction;
}

const CardBasicsHeader = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();

  let options: any[] = []
  const onEditOption = { onClick: props.onEdit, label: 'Edit' }
  const onDeleteOption = { onClick: props.onDelete, label: 'Delete' }
  const onRevokeOption = { onClick: props.onRevoke, label: 'Revoke' }
  if (props.onEdit) options.push(onEditOption)
  if (props.onDelete) options.push(onDeleteOption)
  if (props.onRevoke) options.push(onRevokeOption)

  const onSelect = (e: any, option: any) : void => {
    return option.onClick()
  }


  return (
    <div className={classes.root}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={anchor.set}
        className={classes.icon}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        anchor={anchor}
        options={options}
        onSelect={onSelect}
        optionValue="label"
      />
    </div>
  );  
}


export default withStyles(styles)(CardBasicsHeader)