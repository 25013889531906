import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeColumnName } from '../../../actions/board-columns';
import { useAnchor } from '../../../shared/hooks';
import { LSNewTaskData } from '../../../shared/interfaces';
import { Theme } from '../../../theme';
import { isAdmin, isMember, isOwner, isUser } from '../../../utils/user';
import { acronym, getProgress, groupBy } from '../../../utils/utils';
import Menu from '../../Menu';
import ProgressLine from '../../ProgressLine';
import Skeleton from '../../Skeleton';
import TextInput from '../../TextInput';
import BoardTasks from './BoardTasks';


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    overflowX: 'scroll',
    position: 'relative',
    paddingBottom: 50,
  },
  column: {
    maxWidth: 260,
    minWidth: 260,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '16px 10px',
    height: '100%',
    borderRadius: 4,
    position: 'relative',
    marginRight: 20,
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
    position: 'relative',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
      color: '#233539',
    }
  },
  progress: {
    height: 4,
    position: 'absolute',
    top: 0,
    left: 0
  },
  editIcon: {
    fontSize: 27,
    color: '#657274',
    cursor: 'pointer'
  },
  addTask: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
      color: '#90a0a7',
      transition: 'color .1s ease'
    },
    '&:hover': {
      '& > span': {
        color: theme.palette.primary.main
      },
      '& > $addIcon': {
        color: theme.palette.primary.main
      }
    }
  },
  addIcon: {
    fontSize: 21,
    marginRight: 3,
    color: '#90a0a7',
    transition: 'color .1s ease'
  },
  columnName: {
    width: '100%',
    position: 'relative'
  },
  columnNameInputWrapper: {
    width: '100%'
  },
  columnNameIcon: {
    fontSize: 18,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    right: 5,
    transform: 'translateY(-50%)',
  },
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  skeletonColumn: {
    maxWidth: 260,
    width: '100%',
    height: 320,
    marginRight: 20,
    '&:last-child': {
      marginRight: 0
    }
  },
  skeletonTask: {
    width: '100%',
    height: 100,
    marginBottom: 8
  }
})


interface IProps extends WithStyles<typeof styles> {
  tasks: any[];
  members: any[];
  columns: any[];
  projectId?: number;
  onAddTask: (columnId: number) => any; 
  onEditTask: (taskId: number) => any;
  className?: any;
  tasksLoaded?: boolean;
}

const BoardColumns = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const { status, assignees, dueDate } = useSelector((state: any) => state.board.filters);
  const [tasks, setTasks] = useState<any[]>([]);
  const [column, setColumn] = useState<any>(null);
  const [readonly, setReadonly] = useState<boolean>(true);
  const [columnName, setColumnName] = useState<string>('');
  




  const getColumnOptions = (): any[] => {
    if(isAdmin(user) || isOwner(user, props.members)) {
      return [
        { id: 0, label: 'Edit Title' },
        { id: 1, label: 'Add Task' }
      ];
    }

    if(isUser(user) && isMember(user, props.members)) {
      return [
        { id: 1, label: 'Add Task' }
      ]
    }
    
    return []
  }

  

  const getTitle = (name: string) : string => {
    const words = name.split(' ');
    if(words.length > 2) {
      const lastWord = words.splice(-1);
      const letters = acronym(words);
      return `${letters} ${lastWord}`
    }

    return name
  }



  const getCount = (id: number) : number => {
    return (tasks[id] && tasks[id].length) || 0
  }



  const filterTasks = () : any[] => {
    let newTasks = [...props.tasks];
    
    if(status !== 0) {
      newTasks = newTasks.filter((task: any) => task.state === status);
    }

    if(dueDate) {
      newTasks = newTasks.filter((task: any) => {
        return moment(dueDate.split('T')[0]).diff(task.due_date.split('T')[0]) >= 0
      })
    }

    if(assignees && assignees.length) {
      newTasks = newTasks.filter(
        (task: any) => task.assignees.some(
          (assignee: number) => assignees.includes(assignee)
        )
      )
    }

    return newTasks
  }



  const openTask = (columnId: number) : void => {
    if(!props.projectId) return;

    const newTaskData = { columnId, projectId: props.projectId } as LSNewTaskData;
    localStorage.setItem('newTask', JSON.stringify(newTaskData))
    props.onAddTask(columnId)
  }



  const onEditTask = (taskId: number) : void => {
    props.onEditTask(taskId)
  }



  const onOptionSelect = (optionId: number) : void => {
    anchor.close();
    switch(optionId) {
      case 0:
        setReadonly(false);
        setColumnName(column ? column.name : '')
        break;
      case 1:
        column && column.id && openTask(column.id);
        break;
    }
  }



  const setAnchor = (e: any, column: any) : void => {
    anchor.set(e);
    setColumn(column);
    setColumnName('');
    setReadonly(true);
  }



  const updateColumnName = (e: any): void => {
    dispatchChangeColumnName()
      .then((res: any) => {
        setReadonly(true);
        setColumn(null);
        setColumnName('');
      })
      .catch((err: any) => {})
  }



  const dispatchChangeColumnName = (): any => {
    return dispatch(changeColumnName(column.id, columnName))
  }



  const getColumnName = (col: any): any => {
    if(column && column.id && column.id === col.id && !readonly) {
      return (
        <div className={classes.columnName}>
          <TextInput
            value={columnName}
            onChange={(value: string) => setColumnName(value)}
            className={classes.columnNameInputWrapper}
          />
          <CheckIcon className={classes.columnNameIcon} onClick={updateColumnName} />
        </div>
      )
    }

    return <span>{col.name} ({getCount(col.id)})</span>
  }



  const renderSkeleton = (): any => {
    return (
      <div className={classes.skeletonWrapper}>
        <Skeleton count={4} className={classes.skeletonColumn}></Skeleton>
      </div>
    )
  }







  useEffect(() => {
    const filteredTasks = filterTasks();
    setTasks(groupBy(filteredTasks, 'column'))
  }, [props.tasks, status, dueDate, assignees, props.columns])







  if(props.columns && !props.columns.length) {
    return renderSkeleton();
  }






  return (
    <div className={classNames(classes.root, props.className)}>
      {props.columns.map((column: any) => column.hidden ? null : (
        <div key={column.id} className={classes.column}>
          <ProgressLine progress={getProgress(column)} className={classes.progress} />

          <div className={classes.header}>

            {getColumnName(column)}

            <MoreHorizIcon className={classes.editIcon} onClick={(e) => setAnchor(e, column)} />

          </div>

          {tasks[column.id]
            ? <BoardTasks tasks={tasks[column.id]} onOpenTask={onEditTask} />
            : null
          }

          {props.tasksLoaded === undefined
            ? <Skeleton count={2} className={classes.skeletonTask} />
            : null
          }

          <div className={classes.addTask} onClick={(e) => openTask(column.id)}>
            <AddIcon className={classes.addIcon} />
            <span>Add Task</span>
          </div>
        </div>
      ))}

      <Menu
        anchor={anchor}
        options={getColumnOptions()}
        onSelect={(e: any, option: any) => onOptionSelect(option.id)}
        optionValue="label"
      />


    </div>
  )
}


export default withStyles(styles)(BoardColumns)