import * as actions from '../../actions/common/pagination';
import { PAGINATION_LIMIT } from '../../constants/index';
import { Pagination } from '../../shared/interfaces';


export const initialState: Pagination = {
  limit: PAGINATION_LIMIT,
  page: 1
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_LIMIT:
      return {
        ...state,
        limit: action.limit
      }

    default:
      return state
  }
}