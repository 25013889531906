import React from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { TableCell, Checkbox } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { TableType } from '../../../shared/types';
import { setSelectedForList } from '../../../actions/common/list';


const styles = (theme: Theme) => createStyles({
  checkboxCell: {
    paddingLeft: 16,
    paddingRight: 16,
    width: 20,
    cursor: 'pointer',
    padding: '6px 14px 6px 0 !important',
    minWidth: 'unset !important'
  },
  checkboxRoot: {
    padding: 4,
    minWidth: 20,
    '&$checkboxChecked': {
      color: '#4192ec'
    },
    '& svg': {
      width: 19,
      height: 19,
    }
  },
  checkboxChecked: {
    color: '#4192ec'
  },
})


interface IProps extends WithStyles<typeof styles> {
  tableType: TableType;
  fullAccess?: boolean;
  className?: string;
}

const TableHeadersCheckbox = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { rows, selected } = useSelector((state: any) => state.list[props.tableType])





  const onClick = (): void => {
    dispatchSetSelected(areAllItemsChecked() ? [] : rows)
  }


  
  const areAllItemsChecked = (): boolean => {
    return !!selected.length ? rows.length === selected.length : false
  };



  const isIndeterminate = (): boolean => {
    return !!selected.length && rows.length !== selected.length
  }




  const dispatchSetSelected = (newSelected: number[]): any => {
    return dispatch(setSelectedForList(props.tableType, newSelected))
  }






  if(!props.fullAccess) return null;




  return (
    <TableCell className={classNames(
      classes.checkboxCell,
      props.className
    )}>

      <Checkbox readOnly={!rows.length}
        checked={areAllItemsChecked()}
        indeterminate={isIndeterminate()}
        onClick={onClick}
        classes={{
          root: classes.checkboxRoot,
          checked: classes.checkboxChecked
        }}
      />
      
    </TableCell>
  )
}


export default withStyles(styles)(TableHeadersCheckbox)