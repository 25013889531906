import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { MembershipType, ProviderColorHex } from '../shared/types';
import { Theme } from '../theme';
import DataCard from './DataCard';
import SearchInput from './SearchInput';
import { Link } from '@material-ui/core';
import { withRouter, RouteComponentProps, useHistory } from 'react-router';
import classNames from 'classnames';
import { useTrackerSummary } from './project/tracker/hooks/tracker-hooks';


const styles = (theme: Theme) => createStyles({
  root: {},
  card: {},
  cardRoot: {},
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cpStatus: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '1px solid #d9e4e8',
    marginBottom: 12,
    '&:empty': {
      border: 0,
      margin: 0
    }
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(50% - 5px)',
    marginBottom: 12,
    marginRight: 5,
    '&:last-child': {
      marginBottom: 16
    }
  },
  label: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 'normal',
    lineHeight: 1.82,
    color: '#233539',
    textTransform: 'uppercase'
  },
  labelCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.43,
    marginRight: 8,
    minWidth: 30,
    minHeight: 28,
    borderRadius: 4
  },
  providers: {

  },
  cpManage: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.82,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  cpManageDisabled: {
    opacity: 0.5,
    color: '#90a0a7',
    pointerEvents: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  providers: any[];
  projectId: number;
}

const ProjectCapitalProviders = (props: IProps) => {
  const { classes } = props;
  const history = useHistory();
  const cpSummary = useTrackerSummary();
  const types = useSelector((state: any) => state.types.provider_relation_types);




  const goToManageCPs = (): void => {
    if(isManageDisabled()) return;

    history.push(`/projects/${props.projectId}/email-tracker`)
  }


  const getLabel = (typeId: number): string => {
    return types.find((i: any) => i.id === +typeId)?.name ?? '';
  }



  const isManageDisabled = (): boolean => {
    if(!cpSummary) return true;

    return !Object.keys(cpSummary).length
  }



  return (
    <DataCard
      header={
        <div className={classes.header}>
          <span>Email Tracker</span>
          
          {props.projectId !== undefined && <>
            <span onClick={goToManageCPs}
              className={classNames(
                classes.cpManage,
                {[classes.cpManageDisabled]: isManageDisabled()}
              )}
            >
              Manage
            </span>
          </>}

        </div>
      }
      classes={{
        root: classes.cardRoot,
        card: classes.card
      }}
    >


      <div className={classes.root}>
        <div className={classes.cpStatus}>
          {types.map((type: any) => (
            <div key={type.id} className={classes.labelWrapper}>
              <span className={classes.labelCount} style={ProviderColorHex[type.id]}>
                {cpSummary?.[type.id] ?? 0}
              </span>
              <span className={classes.label}>{getLabel(type.id)}</span>
            </div>
          ))}
        </div>


        <div className={classes.providers}>
          <SearchInput
            header="FIND CONTACTS"
            placeholder="Type Name"
            list={props.providers}
            type={MembershipType.CapitalProvider}
          />
        </div>
      </div>

    </DataCard>
  )
}


export default withStyles(styles)(ProjectCapitalProviders)