/**
 * Actions
 */

export const SET_BOARD_FILTERS_STATUS     = 'SET_BOARD_FILTERS_STATUS';
export const SET_BOARD_FILTERS_ASSIGNEES  = 'SET_BOARD_FILTERS_ASSIGNEES';
export const SET_BOARD_FILTERS_DUE_DATE   = 'SET_BOARD_FILTERS_DUE_DATE';
export const RESET_BOARD_FILTERS   = 'RESET_BOARD_FILTERS';



/**
 * Action creators
 */

export function setFilterStatus(status: number) : object {
  return {type: SET_BOARD_FILTERS_STATUS, status}
}

export function setFilterAssignees(assignees: any[]) : object {
  return {type: SET_BOARD_FILTERS_ASSIGNEES, assignees}
}

export function setFilterDueDate(dueDate: string) : object {
  return {type: SET_BOARD_FILTERS_DUE_DATE, dueDate}
}

export function resetBorderFilters() : object {
  return {type: RESET_BOARD_FILTERS}
}