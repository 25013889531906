import Request from '../../components/request/Request';
import { API_URL } from '../../constants/api';


function getEmailProjects(emailTo: string) {
  return Request.get(`${API_URL}/projects/?email_to=${emailTo}`)
}



export default {
  getEmailProjects,
}