import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { clearComments, getComments } from '../../../actions/comments';
import { clearFiles, getFiles, setFilesType } from '../../../actions/files';
import { getProjectDetails } from '../../../actions/projects/projects';
import { changeTaskField, clearTask, deleteTask, getTask, setCloseDialogState } from '../../../actions/tasks';
import { ROUTE_DASHBOARD, ROUTE_NOT_FOUND } from '../../../constants/routes';
import { useAnchor, useOpen } from '../../../shared/hooks';
import { LSNewTaskData } from '../../../shared/interfaces';
import { FilesType } from '../../../shared/types';
import { Theme } from '../../../theme';
import { isAdmin, isMember, isOwner } from '../../../utils/user';
import ConfirmDialog from '../../ConfirmDialog';
import Menu from '../../Menu';
import TaskButtons from './buttons/TaskButtons';
import TaskComments from './comments/TaskComments';
import TaskAddedBy from './TaskAddedBy';
import TaskAssignees from './TaskAssignees';
import TaskBasicInfo from './TaskBasicInfo';
import TaskDatePicker from './TaskDatePicker';
import TaskFiles from './TaskFiles';
import TaskStatus from './TaskStatus';
import { useTaskData, useTaskAccess, useTaskBackUrl } from './hooks/tasks-hooks';


const styles = (theme: Theme) => createStyles({
  root: {
    width: '59%',
    minWidth: 750,
    position: 'relative'
  },
  content: {
    display: 'flex',
    padding: '0 !important',
    overflow: 'hidden'
  },
  sectionWrapper: {
    ...theme.mixins.scrollbar(theme, true),
    overflow: 'auto',
    '&:first-child': {
      flex: 3,
      backgroundColor: '#fff',
    },
    '&:last-child': {
      flex: 2,
      backgroundColor: '#f7f9fa'
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    padding: '41px 20px 0',
    position: 'relative',
    justifyContent: 'space-between',
    height: '100%',
  },
  sectionReadonly: {
    paddingBottom: 15,
    borderBottom: '1px solid #d9e4e8',
    maringBottom: 10,
    '&:first-child': {
      marginBottom: 7
    }
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h2': {
      fontFamily: 'Montserrat',
      fontSize: 11,
      fontWeight: 500,
      lineHeight: 2,
      letterSpacing: 0.53,
      color: '#233539',
      textTransform: 'uppercase',
      marginBottom: 5
    },
    '& > svg': {
      color: theme.palette.primary.main,
      fontSize: 18,
      cursor: 'pointer'
    }
  },
  sectionBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15,
    '&:first-of-type': {
      marginBottom: 10
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  actions: {
    borderTopColor: '#000',
    borderTopWidth: 3,
    padding: 0,
  },
  dialogIconActions: {
    position: 'absolute',
    top: 10,
    right: 13,
    zIndex: 1
  },
  dialogIcon: {
    fontSize: 22,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 10
  },
  projectName: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.67,
    color: '#657274', 
  }
})


interface IProps extends WithStyles<typeof styles> {
  id?: number;
  isNew?: boolean;
}

const TaskDialog = (props: IProps) => {
  const { classes, id, isNew } = props;
  const moreAnchor = useAnchor();
  const backURL = useTaskBackUrl();
  const { taskView: { closeDialogOpened } } = useTaskData();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const members = useSelector((state: any) => state.teamMembers);
  const project = useSelector((state: any) => state.projects.project);
  const { task } = useSelector((state: any) => state.board.tasks);
  const [actionsVisible, setActionsVisible] = useState<boolean>(false);





  const handleChange = (field: any): void => {
    dispatchChangeTaskField(field)
  }



  const openCancelDialog = (): void => dispatchSetCloseDialogOpened(true)
  const closeCancelDialog = (): void => dispatchSetCloseDialogOpened(false)



  const closeDialog = (): void => {
    closeCancelDialog()
    history.push(backURL)
  }






  const handleMoreSelect = (e: any, option: any): void => {
    option.onClick()
  }







  const handleDeleteTask = (): void => {
    return dispatchDeleteTask(task.id)
      .then((res: any) => closeDialog())
      .finally(() => setActionsVisible(false))
  }





  const dispatchGetFiles = (taskId: number): any => {
    return dispatch(getFiles(taskId))
  }

  const dispatchGetComments = (taskId: number): any => {
    return dispatch(getComments(taskId))
  }

  const dispatchGetTask = (taskId: number): any => {
    return dispatch(getTask(taskId))
  }

  const dispatchDeleteTask = (taskId: number): any => {
    return dispatch(deleteTask(taskId))
  }

  const dispatchGetProject = (projectId: number): any => {
    const getProjectDispatch = dispatch(getProjectDetails(projectId)) as any;
    return getProjectDispatch
      .catch((err: any) => {
        if(err.response?.status === 404) {
          history.push(ROUTE_NOT_FOUND)
        }
      })
  }

  const dispatchChangeTaskField = (field: any): any => {
    return dispatch(changeTaskField(field))
  }

  const dispatchSetFilesType = (): any => {
    return dispatch(setFilesType(FilesType.Tasks));
  }

  const dispatchClear = (): any => {
    localStorage.removeItem('newTask');

    dispatch(clearTask())
    dispatch(clearFiles());
    dispatch(clearComments())
  }

  
  
  const dispatchSetCloseDialogOpened = useCallback((state: boolean): any => {
    return dispatch(setCloseDialogState(state))
  }, [])










  useEffect(() => {
    dispatchSetFilesType()

    return dispatchClear
  }, [])





  useEffect(() => {
    if(id === undefined) return;
    

    if(task?.id === undefined || task.id !== id) {
      dispatchGetTask(id)
        .then((res: any) => {
          dispatchGetComments(id);
          dispatchGetFiles(id);
        })
        .catch((err: any) => {
          if(err.response?.status === 404) {
            closeDialog()
          }
        })
      return
    }


    if(project?.id === undefined || task.project !== project.id) {
      dispatchGetProject(task.project)
      return
    }
  }, [project?.id, task, id])



  


  useEffect(() => {
    if(!isNew) return;
  
    const lsData = localStorage.getItem('newTask');
    const { columnId, projectId } = (lsData ? JSON.parse(lsData) : {}) as LSNewTaskData;

    if(!columnId || !projectId) {
      closeDialog();
      return;
    }

    if(project?.id === undefined) {
      dispatchGetProject(+projectId)
    }
    

    handleChange({
      created_by: user.id,
      project: +projectId,
      column: +columnId
    })
  }, [isNew, project?.id])









  return (
    <>
      <Dialog keepMounted open
        PaperProps={{ className: classes.root }}
        onClose={openCancelDialog}
      >
        <DialogContent dividers className={classes.content}>
          <div className={classes.sectionWrapper}>
            <div className={classes.section}>
              <div className={classes.contentWrapper}>


                <div className={classNames(
                  classes.sectionBlock,
                  classes.projectName
                )}>
                  {project?.name ?? ''}
                </div>


                <TaskBasicInfo
                  isNew={isNew}
                  className={classNames(
                    classes.sectionBlock,
                    { [classes.sectionReadonly]: !isNew }
                  )}
                  headerClassName={classes.sectionHeader}
                />



                <TaskComments
                  isNew={isNew}
                  readonly={!isNew}
                  className={classNames(
                    classes.sectionBlock,
                    { [classes.sectionReadonly]: !isNew }
                  )}
                  headerClassName={classes.sectionHeader}
                />



                <TaskFiles
                  id={task.id}
                  isNew={isNew}
                  className={classes.sectionBlock}
                  headerClassName={classes.sectionHeader}
                />


              </div>
            </div>
          </div>




          <div className={classes.sectionWrapper}>
            <div className={classes.section}>

              <div className={classes.dialogIconActions}>
                {isAdmin(user) || isOwner(user, members)
                  ? (
                    <Fragment>
                      <MoreHorizIcon className={classes.dialogIcon} onClick={(e: any) => moreAnchor.set(e)} />
                      <Menu
                        anchor={moreAnchor}
                        options={[{
                          label: 'Delete',
                          onClick: () => setActionsVisible(true)
                        }]}
                        onSelect={handleMoreSelect}
                        optionValue="label"
                      />
                    </Fragment>
                  )
                  : null
                }
                <ClearIcon className={classes.dialogIcon} onClick={openCancelDialog} />
              </div>



              <div className={classes.contentWrapper}>


                <TaskAssignees
                  className={classes.sectionBlock}
                  headerClassName={classes.sectionHeader}
                />


                <TaskAddedBy
                  isNew={isNew}
                  className={classes.sectionBlock}
                  headerClassName={classes.sectionHeader}
                />


                <TaskDatePicker
                  isNew={isNew}
                  className={classes.sectionBlock}
                  headerClassName={classes.sectionHeader}
                />


                <TaskStatus
                  isNew={isNew}
                  className={classes.sectionBlock}
                  headerClassName={classes.sectionHeader}
                />
              </div>
            </div>
          </div>



        </DialogContent>

        <DialogActions className={classes.actions}>
          <TaskButtons id={id} isNew={isNew} />
        </DialogActions>

      </Dialog>



      <ConfirmDialog
        title="Confirm Delete"
        open={actionsVisible}
        confirmLabel="Delete"
        onCancel={() => setActionsVisible(false)}
        onConfirm={handleDeleteTask}
      >
        Are you sure you want to delete this task?
      </ConfirmDialog>



      <ConfirmDialog
        title="Are you sure?"
        open={closeDialogOpened}
        confirmLabel="YES"
        onCancel={closeCancelDialog}
        onConfirm={closeDialog}
      >
        {isNew
          ? 'Are you sure you want to discard your changes?'
          : 'Are you sure you want to close?'
        }
      </ConfirmDialog>




    </>
  )
}


export default withStyles(styles)(TaskDialog)