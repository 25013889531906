import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const PossibleDuplicatesIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="34" height="28" viewBox="0 0 34 28">
      <g fill="currentColor">
        <path d="M17 10.957c-2.197 0-3.984 1.787-3.984 3.984h7.968c0-2.197-1.787-3.984-3.984-3.984zM17 8.965c1.098 0 1.992-.894 1.992-1.992 0-1.099-.894-1.993-1.992-1.993-1.098 0-1.992.894-1.992 1.993 0 1.098.894 1.992 1.992 1.992z"/>
        <path d="M1.992 19.922h9.444l1.992 1.992h7.144l1.992-1.992h9.444V0H1.992v19.922zm9.031-4.98c0-2.253 1.267-4.194 3.112-5.213-.69-.716-1.12-1.685-1.12-2.756 0-2.198 1.788-3.985 3.985-3.985s3.984 1.787 3.984 3.985c0 1.07-.429 2.04-1.118 2.756 1.844 1.019 3.11 2.96 3.11 5.212v1.993H11.024V14.94z"/>
        <path d="M21.397 23.906h-8.794l-1.992-1.992H0v.996c0 2.75 2.23 4.98 4.98 4.98h24.04c2.75 0 4.98-2.23 4.98-4.98v-.996H23.389l-1.992 1.992z"/>
      </g>
    </SvgIcon>
  );
};