import React, { useMemo } from 'react';
import { SelectOptionType } from '../../../shared/models/Search';
import { useSearchData } from './hooks/advanced-search-hooks';
import SearchSelectValueAutocomplete from './select-values/SearchSelectValueAutocomplete';
import SearchSelectValueDateInput from './select-values/SearchSelectValueDateInput';
import SearchSelectValueInput from './select-values/SearchSelectValueInput';
import SearchSelectValueRating from './select-values/SearchSelectValueRating';
import SearchSelectValueSelect from './select-values/SearchSelectValueSelect';


interface IProps {
  idx: number;
  subRowIdx?: number;
}

const SearchSelectValue = (props: IProps) => {
  const { idx, subRowIdx } = props;
  const { currentRow } = useSearchData(idx, subRowIdx);




  const componentProps = useMemo((): any => {

    const newProps = currentRow.field?.componentProps ?? {};
    if(currentRow?.field?.type === SelectOptionType.Number) {
      newProps.formattedNumber = true;
    }

    return newProps
  }, [currentRow]);
  





  const Component = useMemo((): any => {
    switch(currentRow.field?.type) {
      case SelectOptionType.Select:
      case SelectOptionType.SelectMultiple:
      case SelectOptionType.SubcategoriesSelect:
      case SelectOptionType.SelectMultipleFKey:
        return SearchSelectValueSelect
  
  
      case SelectOptionType.Rating:
        return SearchSelectValueRating
  
  
      case SelectOptionType.Boolean:
        return null
      
  
      case SelectOptionType.Date:
        return SearchSelectValueDateInput
  
  
      case SelectOptionType.Autocomplete:
      case SelectOptionType.AutocompleteArray:
      case SelectOptionType.AutocompleteRequest:
        return SearchSelectValueAutocomplete
  
  
      case SelectOptionType.Number:
      default:
        return SearchSelectValueInput
    }
  }, [currentRow?.field?.type])

  


  if(!currentRow.condition || !currentRow.field || Component === null) return null;



  return (
    <Component
      idx={idx}
      subRowIdx={subRowIdx}
      {...componentProps}
    />
  )
  

}


export default SearchSelectValue