/**
 * Types
 */

import Axios from "axios";
import { getErrorCode } from "../../services/helpers/helpers";

export const NotificationTypes = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO'
}


/**
 * Actions
 */

export const SET_ERROR          = 'SET_ERROR';
export const SET_SUCCESS        = 'SET_SUCCESS';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'



/**
 * Action creators
 */

export function setSingleError(data: any): object {
  return {type: SET_ERROR, data, notificationType: NotificationTypes.ERROR}
}

export function setSuccess(data: any): object {
  return {type: SET_SUCCESS, data, notificationType: NotificationTypes.SUCCESS}
}

export function clearNotification(): object {
  return {type: CLEAR_NOTIFICATION}
}



/**
 * Async action creators
 */

export const setError = (message: string, error?: any): any => {
  return (dispatch) => {

    if(Axios.isCancel(error)) return;

    // if(error?.response?.status === 404) return;

    dispatch(setSingleError({
      message,
      statusCode: getErrorCode(error)
    }))
  }
  
}