import { Rating } from '@material-ui/lab';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { ChangeEvent, useMemo } from 'react';
import { Theme } from '../../../theme';
import { usePersonData } from '../hooks/people-hooks';
import { EntityField } from '../../../shared/interfaces';

const styles = (theme: Theme) => createStyles({
  root: {
    fontSize: 20,
    '& > span, & > label': {
      paddingRight: 9,
      '&:last-child': {
        paddingRight: 0
      }
    }
  }
})

interface IProps extends WithStyles<typeof styles>, EntityField {

}

const ContactInfoRating = ({ classes, name, ...otherProps }: IProps) => {
  const { value = 0, error, readonly, onChange } = usePersonData(name);




  const handleChange = (e: ChangeEvent<any>, val: number|null): any => {
    return onChange(val)
  }




  return useMemo(() => (
    <Rating
      max={3}
      value={value}
      onChange={handleChange}
      readOnly={readonly}
      name={name}
      className={classes.root}
      {...otherProps}
    />
  ), [value, error, readonly])
}


export default withStyles(styles)(ContactInfoRating)