import React, { Fragment } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { ProviderLabel } from '../shared/models/Project';
import classNames from 'classnames';
import CapitalProviderLabel from './CapitalProviderLabel';
import ProjectMembershipLabel from './ProjectMembershipLabel';
import { withRouter, RouteComponentProps } from 'react-router';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '10px 0 ',
    borderBottom: '1px solid #d9e4e8',
    transition: 'padding .1s ease',
    '&:first-child': {
      paddingTop: 0
    },
    '&:last-child': {
      borderBottom: 'unset',
    }
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.92,
    color: '#233539',
    display: 'block',
    marginBottom: 5,
    cursor: 'pointer',
    transition: 'color .1s ease',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  is_cap_provider: boolean
  projects: any[]
}

const Projects = (props: IProps) => {
  const { classes } = props;


  const handleClick = (e: any, projectId: number) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.ctrlKey || e.metaKey) {
      window.open(`/projects/${projectId}/`)
      return
    }
    props.history.push(`/projects/${projectId}/`)
  }


  return (
    <Fragment>
      {props.projects.map((project: any, idx: number) => (
        <div key={project.id} className={classes.root}>
          <span className={classes.title} onClick={(e: any) => handleClick(e, project.id)}>
            {project.name}
          </span>
          {props.is_cap_provider
            ? <CapitalProviderLabel label={project.label} />
            : <ProjectMembershipLabel label={project.label} />
          }
        </div>
      ))}
    </Fragment>
  )
}


export default withStyles(styles)(withRouter(Projects))