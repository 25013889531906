import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { ROUTE_PERSON_GET, ROUTE_PERSON_NEW, ROUTE_PROJECT_GET, ROUTE_PROJECT_NEW } from '../constants/routes';
import { useOpen } from '../shared/hooks';
import { isRoute } from '../utils/utils';
import ConfirmDialog from './ConfirmDialog';



const PageLeaveDialog = () => {
  const history = useHistory();
  const controls = useOpen();
  const unblockHandle = useRef<any>();
  const [targetLocation, setTargetLocation] = useState<any|undefined>(undefined);


  const unblockedRoutes = [
    { from: ROUTE_PERSON_NEW, to: ROUTE_PERSON_GET },
    { from: ROUTE_PROJECT_NEW, to: ROUTE_PROJECT_GET },
  ]


  
  const isUnblockedRoute = (route: any, target: string): boolean => {
    return isRoute(route.from) && isRoute(route.to, true, target)
  }



  const onConfirm = (): void => {
    if (unblockHandle) {
      unblockHandle.current()
    }

    history.push(targetLocation)
  }




  useEffect(() => {
    unblockHandle.current = history.block((target: any) => {
      if(unblockedRoutes.some((item: any) => isUnblockedRoute(item, target.pathname))) return;

      controls.open();
      setTargetLocation(target);
      return false;
    });

    return () => {
      unblockHandle?.current?.()
    }
  }, [])






  return (
    <ConfirmDialog
      title="Are you sure?"
      open={controls.value}
      cancelLabel="Cancel"
      confirmLabel="YES"
      onCancel={controls.close}
      onConfirm={onConfirm}
    >
      Are you sure you want to discard your changes?
    </ConfirmDialog>
  )
}


export default PageLeaveDialog