import React, { forwardRef, useRef } from "react";
import { Theme } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import BaseCard from "./BaseCard";

const styles = (theme: Theme) => createStyles({
  header: {
    ...theme.typography.body2,
    fontSize: 16,
    marginBottom: 8,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539'
  },
  card: {
    padding: 12,
    overflow: 'visible'
  },
  root: {
    display: 'flex',
  },
  vertical: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }
});

interface IProps extends WithStyles<typeof styles> {
  children?: any;
  header?: any;
  id?: string;
}

const DataCard = forwardRef((props: IProps, ref: any) => {
  const { classes } = props;
  // const innerRef = useRef(ref);

  
  return (
    <div id={props.id} ref={ref} className={classes.root}>
      <div className={classes.vertical}>
        <div className={classes.header}>
          {props.header}
        </div>
        <BaseCard classes={{card: classes.card}}>
          {props.children}
        </BaseCard>
      </div>
    </div>
  );
})


export default withStyles(styles)(DataCard)