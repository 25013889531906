import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { Fragment, useEffect, useState } from "react";
import { ISummary } from "../shared/models/Project";
import { Theme } from "../theme";
import DataCard from "./DataCard";
import EmptyCard, { EmptyIcon } from "./EmptyCard";
import ProgressLine from "./ProgressLine";
import { BoardIcon } from "./icons/BoardIcon";
import { useSelector } from "react-redux";
import { TaskType, ProgressColor, ProgressColorHex } from "../shared/types";
import { getProgressColor, getProgressBgColor, getProgress } from "../utils/utils";

const styles = (theme: Theme) => createStyles({
  cardRoot: {},
  card: {
    minHeight: 126
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  progressWrapper: {
    marginBottom: 22,
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 10,
      fontWeight: '500',
      lineHeight: 2.4,
      letterSpacing: 0.48,
      color: '#233539',
      marginBottom: '5px',
      display: 'block',
    }
  },
  progress: {
    display: 'flex',
    fontFamily: 'Montserrat',
    fontSize: 25,
    fontWeight: 'normal',
    lineHeight: 0.8,
    color: '#ffc143',
    '& > span': {
      marginRight: 10
    }
  },
  cardList: {
    width: '100%',
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(162px, max-content))',
    gridGap: 12,
    justifyContent: 'space-between',
  },
  littleCard: {
    height: 80,
    padding: 10,
    border: '1px solid #d9e4e8',
    borderRadius: 4,
    boxSizing: "border-box",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 1.5,
    position: 'relative',
    transition: 'background-color .1s ease',
    '&:hover': {
      backgroundColor: '#f7f9fa'
    }
  },
  littleCardHeader: {
    fontWeight: 500,
    maxWidth: '80%',
    color: '#233539'
  },
  littleCardTasks: {
    color: '#657274',
    '& > strong': {
      fontWeight: 500
    }
  },
  colorRow: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 3,
    width: '100%'
  },
  cardFooterWrapper: {

  },
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0',
    cursor: 'pointer',
    marginTop: 12,
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1,
      color: '#4192ec',
      transition: 'border-bottom .1s ease',
      borderBottom: '1px solid transparent'
    },
    '&:hover': {
      '& > span': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  boardIcon: {
    fontSize: 16,
    marginRight: 10
  },
  progressColumn: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 3,
  }
});

interface IProps extends WithStyles<typeof styles> {
  newProject?: boolean;
  onGoToBoard: (e: any) => void;
}

const ProjectSummaryCard = (props: IProps) => {
  const { classes } = props;
  const project = useSelector((state: any) => state.projects.project);
  const selectTypes = useSelector((state: any) => state.types);
  const progress = props.newProject ? 0 : getProgress(project);
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    if(!project.id) {
      setColumns(selectTypes.default_columns)
    } else {
      setColumns(project.columns)
    }
  }, [project.columns, selectTypes.default_columns])

  return (
    <Fragment>
      <DataCard
        header={
          <div className={classes.header}>
            <span>Project Board</span>
          </div>
        }
        classes={{
          root: classes.cardRoot,
          card: classes.card
        }}
      >
        <div className={classes.progressWrapper}>
          <span>TOTAL PROGRESS</span>
          <div className={classes.progress}>
            <span style={{color: getProgressColor(progress)}}>{Math.round(progress)}%</span>
            <ProgressLine progress={progress} />
          </div>
        </div>

        {columns ? <div className={classes.cardList}>
          {columns.map((column: any, index: number) => (
              <div key={column.name} className={classes.littleCard}>
                <ProgressLine progress={getProgress(column)} className={classes.progressColumn} />
                <span className={classes.littleCardHeader}>{column.name}</span>
                <span className={classes.littleCardTasks}>
                  <strong>
                    {column.progress ? column.progress.total : column.tasks_count}
                  </strong> tasks
                </span>
              </div>
          ))}
        </div> : null}

        {!!project.id
          ? (
              <div className={classes.cardFooterWrapper}>
                <div className={classes.cardFooter} onClick={props.onGoToBoard}>
                  <BoardIcon className={classes.boardIcon} />
                  <span>Go to Project Board</span>
                </div>
              </div>
            )
          : null
        }
      </DataCard>
    </Fragment>
  );
}


export default withStyles(styles)(ProjectSummaryCard)