import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getBoardColumns } from '../../../actions/board-columns';
import { getProjectDetails } from '../../../actions/projects/projects';
import { setHighlight, setSearch } from '../../../actions/search';
import { getTasks, searchTasks, setColumnToTask } from '../../../actions/tasks';
import { ROUTE_PROJECTS } from '../../../constants/routes';
import { Theme } from '../../../theme';
import Skeleton from '../../Skeleton';
import BoardColumns from './BoardColumns';
import BoardHeader from './BoardHeader';
import BoardProgress from './BoardProgress';


const styles = (theme: Theme) => createStyles({
  header: {
    marginBottom: 20
  },
  progress: {
    marginBottom: 42
  },
  skeletonWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  skeletonHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
    paddingBottom: 20
  },
  skeletonHeaderWrapperLeft: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
    width: '100%'
  },
  skeletonHeaderLeft: {
    '&:first-child': {
      height: 20,
      marginBottom: 5
    },
    '&:last-child': {
      height: 24
    }
  },
  skeletonHeaderWrapperRight: {
    display: 'flex'
  },
  skeletonHeaderRightFilterItem: {
    marginRight: 40,
    display: 'flex',
  },
  skeletonHeaderFilterItem: {
    '&:first-child': {
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginRight: 14
    },
    '&:last-child': {
      height: 40,
      width: 140
    }
  },
  skeletonHeaderFilterTasks: {
    width: 115,
    height: 40
  },
  skeletonProgress: {
    width: '100%',
    height: 80,
    marginBottom: 42
  },
  skeletonBoard: {
    display: 'flex',
    overflowX: 'hidden',
    justifyContent: 'space-between'
  },
  skeletonColumn: {
    maxWidth: 260,
    width: '100%',
    height: 320,
    marginRight: 20,
    '&:last-child': {
      marginRight: 0
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  id?: number;
}


const ProjectBoard = (props: IProps) => {
  const { classes, id } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const tasks = useSelector((state: any) => state.board.tasks.results);
  const columns = useSelector((state: any) => state.board.columns);
  const project = useSelector((state: any) => state.projects.project);
  const members = useSelector((state: any) => state.teamMembers);
  const search = useSelector((state: any) => state.search.search);
  const [projectLoaded, setProjectLoaded] = useState<boolean|undefined>(undefined);
  const [tasksLoaded, setTasksLoaded] = useState<boolean|undefined>(undefined);




  

  const onAddTask = (columnId: number) : void => {
    dispatch(setColumnToTask(columnId));
    history.push(`/task/new`, { backURL: history.location.pathname })
  }



  const goToTask = (taskId: number) : void => {
    history.push(`/task/${taskId}`, { backURL: history.location.pathname })
  }



  const onProjectChange = (id: number) : void => {
    history.push(`/projects/${id}/tasks`);
  }



  const getProjectsRelations = (id: number): void => {
    dispatchGetColumns(id);
    dispatchGetTasks(id)
      .then((res: any) => {
        setTasksLoaded(true);
        setProjectLoaded(true);
      })
      .catch((res: any) => {
        setTasksLoaded(false)
      })
  }




  const onProjectRefetch = (): void => {
    if(columns?.[0]?.project === project.id || !project.id) {
      return
    }

    return getProjectsRelations(project.id)
  }




  const onProjectIdChange = (id: number) => {
    if(projectLoaded !== undefined) {
      setProjectLoaded(false)
    }


    if(id === project.id) {
      return onProjectRefetch()
    }


    dispatchGetProject(id)
      .then(() => getProjectsRelations(id))
      .catch((err: any) => {
        if(err.response?.status === 404) {
          history.push(ROUTE_PROJECTS)
        }
      })
  }



  const dispatchGetColumns = (id: number): any => {
    return dispatch(getBoardColumns(id))
  }



  const dispatchGetTasks = (id: number): any => {
    return dispatch(getTasks(id, 'limit=10000'))
  }



  const dispatchGetProject = (id: number): any => {
    return dispatch(getProjectDetails(id))
  }



  const dispatchSearchTasks = (val: string): any => {
    return dispatch(searchTasks(val))
  }



  const dispatchSetHighlight = (state: boolean): any => {
    return dispatch(setHighlight(state))
  }



  const dispatchClearSearch = (): any => {
    return dispatch(setSearch(undefined))
  }





  const renderSkeleton = (): any => {
    return (
      <div className={classes.skeletonWrapper}>

        <div className={classes.skeletonHeaderWrapper}>
          <div className={classes.skeletonHeaderWrapperLeft}>
            <Skeleton count={2} className={classes.skeletonHeaderLeft} />
          </div>

          <div className={classes.skeletonHeaderWrapperRight}>
            <div className={classes.skeletonHeaderRightFilterItem}>
              <Skeleton count={2} className={classes.skeletonHeaderFilterItem} />
            </div>
            <div className={classes.skeletonHeaderRightFilterItem}>
              <Skeleton count={2} className={classes.skeletonHeaderFilterItem} />
            </div>

            <Skeleton className={classes.skeletonHeaderFilterTasks} />
          </div>
        </div>


        <Skeleton className={classes.skeletonProgress} />


        <div className={classes.skeletonBoard}>
          <Skeleton count={4} className={classes.skeletonColumn} />
        </div>

      </div>
    )
  }

  


  useEffect(() => {
    ReactGA.pageview(history.location.pathname);
    onProjectRefetch();

    return () => {
      dispatchClearSearch()
    }
  }, [])



  useEffect(() => {
    if(!id && project?.id === undefined) return;


    if(!id && project?.id !== undefined) {
      onProjectIdChange(project.id);
      dispatchClearSearch();
      return
    }


    if(!!id && id !== project?.id) {
      onProjectIdChange(id);
      dispatchClearSearch()
      return;
    }

    
  }, [id, project?.id]) 



  useEffect(() => {
    if(search === undefined) return;

    dispatchSearchTasks(search).then(() => dispatchSetHighlight(true))
  }, [search])






  if(projectLoaded === undefined && project?.id === undefined) {
    return renderSkeleton()
  }

  




  return (
    <div>
      
      <BoardHeader
        className={classes.header}
        project={project}
        members={members}
        onProjectChange={onProjectChange}
      />


      <BoardProgress
        className={classes.progress}
        project={project}
      />
      
      
      <BoardColumns
        tasks={tasks}
        members={members}
        columns={columns}
        projectId={id}
        onAddTask={onAddTask}
        onEditTask={goToTask}
        tasksLoaded={tasksLoaded}
      />

    </div>
  )
}


export default withStyles(styles)(ProjectBoard)