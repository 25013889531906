import * as React from "react";
import { Theme, palette } from "../theme";
import {createStyles, withStyles} from "@material-ui/styles";
import {WithStyles} from "@material-ui/core";
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  root: {
    '&>*': {
      marginTop: 4,
    },
    '&>:first-child': {
      marginTop: 0,
    },
    transition: theme.transitions.create(
      ['background-color']
    ),
    '&>:nth-child(odd)': {
      backgroundColor: palette.mist500,
    },
    '&>:nth-child(even)': {
      backgroundColor: palette.mist050
    },

  },
  lighterFirst: {
    '&>:nth-child(odd)': {
      backgroundColor: palette.mist050,
    },
    '&>:nth-child(even)': {
      backgroundColor: palette.mist500
    },
  },
  uniform: {
    '&>:nth-child(odd)': {
      backgroundColor: palette.mist050,
    },
    '&>:nth-child(even)': {
      backgroundColor: palette.mist050
    }
  },
});

interface IProps extends WithStyles<typeof styles> {
  children?: any,
  uniform?: boolean,
  lighterFirst?: boolean,
}

class AlternateColorList extends React.Component<IProps> {
  render() {
    const {classes, children, uniform, lighterFirst} = this.props;
    return (
      <div
        className={classNames(
          classes.root,
          {[classes.lighterFirst]: lighterFirst},
          {[classes.uniform]: uniform}
        )}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(AlternateColorList)