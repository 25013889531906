import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { FormControl } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import classNames from 'classnames';
import { useOpen } from '../shared/hooks';
import { MembershipType } from '../shared/types';
import PersonWithAvatar from './PersonWithAvatar';

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    outline: 0,
  },
  form: {
    ...theme.typography.body1,
    fontSize: 12,
    color: theme.palette.primary.main,
    borderRadius: 3,
    backgroundColor: '#e8f2fd',
    border: '1px solid transparent',
    paddingLeft: 8,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: theme.transitions.create(
      ['border-color', 'background-color', 'transform']
    ),
    '&:hover': {
      backgroundColor: '#c6def9'
    },
  },
  button: {
    paddingRight: 2,
    boxSizing: 'unset',
    transition: theme.transitions.create('color'),
  },
  menu: {
    width: '100%',
    zIndex: 100,
    backgroundColor: '#fff',
    padding: '15px 10px',
    boxSizing: 'border-box',
    borderRadius: '0 0 4px 4px',
    transformOrigin: 'center top',
    transform: 'scaleY(0)',
    transition: 'all .1s ease',
    display: 'none',
    '&:empty': {
      display: 'none',
    }
  },
  menuOpen: {
    transform: 'scaleY(1)',
    display: 'block'
  },
  error: {
    color: '#d52023',
    backgroundColor: 'rgba(213, 32, 35, 0.1)',
    borderColor: '#d52023',
    fontSize: 12,
    lineHeight: 1.22,
    fontFamily: 'Montserrat',
  },
  value: {
    
  },
})

interface IProps extends WithStyles<typeof styles> {
  placeholder: string;
  options: any[];
  type: MembershipType;
  onSelect: (option: any) => any;
  error?: boolean;
  className?: any;
  children?: any;
}

const Dropdown = (props: IProps) => {
  const { classes } = props;
  const open = useOpen();



  
  const getChildren = () : any => {
    switch(props.type) {


      case MembershipType.Team:
        return props.options.map((member: any) => (
          <PersonWithAvatar
            key={member.person.id}
            person={member.person}
            onClick={(person: any) => {
              if(props.options.length === 1) {
                open.close()
              }
              props.onSelect(member)
            }}
          />
        ))


      default:
        return null
    }
  }




  return (
    <div className={props.className}>
      <FormControl fullWidth>
        <div className={classes.container}>
          <div onClick={open.toggle} className={classNames(
            classes.form,
            {[classes.error]: props.error}
          )}>
            <span>{props.placeholder}</span>
            <KeyboardArrowDownIcon className={classes.button}/>
          </div>
          <div className={classNames(
            classes.menu,
            {[classes.menuOpen]: open.value}
          )}>
            {getChildren()}
          </div>
        </div>
      </FormControl>
    </div>
  )
}


export default withStyles(styles)(Dropdown)