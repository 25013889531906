import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { requestResetPassword } from "../actions/auth";
import { ROUTE_PASSWORD_RESET, ROUTE_DASHBOARD } from "../constants/routes";
import { Theme } from "../theme";
import { isAuthenticated } from "../utils/user";
import LoginPageFormContainer from "./LoginPageCard";
import EmailSent from "./passwordReset/EmailSent";
import EnterEmailForm from "./passwordReset/EnterEmailForm";

const styles = (theme: Theme) => createStyles({});

interface IProps extends WithStyles<typeof styles> {
  onResetRequested: any,
  user: any
}


const PasswordResetRequest = (props: IProps) => {
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    ReactGA.pageview(ROUTE_PASSWORD_RESET)
  }, [])

  const handleSubmit = (userEmail: string) : void => {
    props.onResetRequested(userEmail)
      .then((res) => setEmail(userEmail))
      .catch((err) => setEmail(userEmail))
  }

  let content = email
    ? <EmailSent email={email} />
    : <EnterEmailForm onPasswordReset={handleSubmit}/>

  return isAuthenticated(props.user)
      ? <Redirect to={ROUTE_DASHBOARD} />
      : (
          <LoginPageFormContainer>
            {content}
          </LoginPageFormContainer>
        )
};


const mapStateToProps = state => {
  const { user } = state.user;
  return {
    user
  }
}

const mapDispatchToProps = dispatch => {
  return { 
    onResetRequested: email => dispatch(requestResetPassword(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PasswordResetRequest))