import React, { forwardRef, Fragment } from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { MenuItem, Popover, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useAnchor } from '../shared/hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    letterSpacing: 'normal',
    color: '#657274',
    cursor: 'pointer',
    minHeight: 'initial',
    '&:hover': {
      color: '#4192ec',
      backgroundColor: 'transparent'
    }
  },
  selected: {
    color: '#4192ec',
    backgroundColor: 'transparent !important'
  },
  disabled: {
    color: '#b8bdbe',
    cursor: 'not-allowed !important',
    '&:hover': {
      color: '#b8bdbe',
      backgroundColor: 'transparent'
    }
  },
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: '5px 10px',
    maxWidth: 250,
    '& > p': {
      color: '#757575',
      lineHeight: 1.5,
      fontSize: 12
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  key: any;
  children: any;
  onClick?: (...args: any[]) => any;
  selected?: boolean;
  disabled?: boolean;
  popover?: string;
}

const MenuOption = forwardRef((props: IProps, ref: any) => {
  const { classes } = props;
  const anchor = useAnchor();


  const onClick = (e: any): void => {
    if(!props.disabled && props.onClick) {
      props.onClick(e)
    }
  }


  return props.onClick
    ? (
        <Fragment>
          <MenuItem
            key={props.key}
            ref={ref}
            onClick={onClick}
            selected={props.selected}
            onMouseEnter={(e: any) => {
              if(props.popover && props.disabled) {
                anchor.set(e);
              }
            }}
            onMouseLeave={() => {
              if(props.popover && props.disabled && !!anchor.value) {
                anchor.close();
              }
            }}
            className={classNames({[classes.disabled]: props.disabled})}
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
          >
            {props.children}
          </MenuItem>
          {props.popover && (
            <Popover
              className={classes.popover}
              classes={{ paper: classes.paper }}
              open={!!anchor.value}
              anchorEl={anchor.value}
              anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
              transformOrigin={{ vertical: 'center', horizontal: 'left' }}
              onClose={anchor.close}
              disableRestoreFocus
            >
              <Typography>{props.popover}</Typography>
            </Popover>
          )}
        </Fragment>
      )
    : null
})


export default withStyles(styles)(MenuOption)