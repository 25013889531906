import React, { useMemo } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { formatDateTime } from '../../utils/utils';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useSelector } from 'react-redux';
import { AttachmentIcon } from '../icons/AttachmentIcon';



const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 4,
    cursor: 'pointer',
    padding: '10px 6px 10px 6px',
    '&:hover': {
      backgroundColor: '#f7f9fa',
      transition: 'all .1s ease',
      '& $fileName': {
        color: '#4192ec'
      },
      '& $iconButton': {
        transform: 'scale(1)',
      }
    }
  },
  fileName: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.67,
    color: '#657274',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  iconButton: {
    color: theme.palette.primary.main,
    padding: 0,
    marginLeft: 12,
    transition: 'transform .1s ease',
    transform: 'scale(0)',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  },
  updated: {
    fontSize: 10,
    fontWeight: 500,
    color: '#90a0a7'
  },
  deleteIcon: {
    fontSize: 17
  },
  attachmentIcon: {
    color: '#657274',
    fontSize: 24
  },
  hidden: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  idx?: number;
  file: any;
  onDelete: (fileId: number, fileType: number, idx?: number) => any;
  asAttachment?: boolean;
}

const FileListItem = (props: IProps) => {
  const { classes, file } = props;
  const userId = useSelector((state: any) => state.user.user?.id);





  const date = useMemo((): string => formatDateTime(file.created_at ?? new Date()), [file.created_at]);



  const onDelete = (): void => {
    return props.onDelete(file.id, file.type, props.idx)
  }





  return (
    <div className={classes.root}>
      
      <a className={classes.fileName} href={file.document} target="_blank">
        
        {props.asAttachment && (
          <AttachmentIcon className={classes.attachmentIcon} />
        )}

        <div className={classes.content}>
          {file.name}

          <span className={classNames(
            classes.updated,
            {[classes.hidden]: !date}
          )}>
            Uploaded at: {date}
          </span>
        </div>
      </a>
    

      {(file.user === userId || props.asAttachment) && (
        <IconButton className={classes.iconButton} onClick={onDelete}>
          <ClearIcon classes={{ root: classes.deleteIcon }} />
        </IconButton>
      )}
      
    </div>
  )
}


export default withStyles(styles)(FileListItem)