import React, { createRef, useState, useEffect, MouseEvent, useRef } from "react";
import { Theme, palette } from "../theme";
import { createStyles, withStyles } from "@material-ui/styles";
import { FormControl, WithStyles, ClickAwayListener, MenuList, Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SelectMenu from "./SelectMenu";
import classNames from 'classnames';
import { getIds } from "../utils/utils";
import { useOpen, useAnchor } from "../shared/hooks";
import CheckIcon from "@material-ui/icons/Check";


const valuePadding = 36 // Calculated from the padding, border and margins on the select value element

const styles = (theme: Theme) => createStyles({
  root: {
    outline: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    outline: 0,
  },
  form: {
    ...theme.typography.body1,
    fontSize: 12,
    color: theme.palette.primary.main,
    borderRadius: 3,
    backgroundColor: palette.skyblue050,
    borderColor: palette.skyblue050,
    border: '1px solid',
    paddingLeft: 8,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transition: theme.transitions.create(
      ['border-color', 'background-color', 'transform']
    ),
    '&:hover': {
      backgroundColor: '#c6def9'
    },
    '$readonly &': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: palette.dark500,
      transform: 'translateX(-8px)',
      cursor: 'default'
    }
  },
  formOpened: {
    borderColor: palette.skyblue300,
    borderBottomColor: "#c6def9",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    outline: 0,
    '&:hover': {
      backgroundColor: palette.skyblue050,
    }
  },
  button: {
    paddingRight: 2,
    boxSizing: 'unset',
    transition: 'all .1s ease',
    '$readonly &': {
      color: 'transparent'
    }
  },
  buttonReversed: {
    transform: 'rotateZ(180deg)'
  },
  menuContainer: {
    transform: 'scaleY(0)',
    opacity: 0,
    width: '100%',
    zIndex: 100,
    transition: theme.transitions.create(
      ['transform', 'opacity']
    ),
  },
  menuContainerOpened: {
    transform: 'scaleY(1)',
    opacity: 1,
  },
  menu: {
    position: 'absolute',
    border: '1px solid #7ab3f2',
    boxSizing: 'border-box',
    borderTop: 0,
    borderRadius: '0 0 4px 4px',
    backgroundColor: theme.palette.primary.light
  },
  value: {
    // whiteSpace: 'nowrap',
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // maxWidth: '100%',
    transition: theme.transitions.create(
      ['transform']
    ),
  },
  readonly: {},
  error: {
    color: '#d52023',
    backgroundColor: palette.red050,
    borderColor: palette.red500,
    fontSize: 12,
    lineHeight: 1.22,
    fontFamily: 'Montserrat',
  },
  formControlOverlap: {
    zIndex: 10
  },
  withoutPosition: {
    position: 'unset'
  },
  valueOverflow: {
    whiteSpace: 'nowrap'
  },
  popoverMenu: {
    border: '1px solid #7ab3f2',
    boxSizing: 'border-box',
    borderTop: 0,
    borderRadius: '0 0 4px 4px',
    backgroundColor: theme.palette.primary.light
  },
  popoverMenuItem: {
    position: 'relative',
    fontSize: 12,
    color: theme.palette.primary.main,
    backgroundColor: palette.skyblue050,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
    wordBreak: 'break-word',
    transition: theme.transitions.create(
      ['background-color']
    ),
    '&:hover': {
      backgroundColor: '#d7e9fb'
    }
  },
  checkIcon: {
    fontSize: 16,
    position: 'absolute',
    right: 9,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  checkIconHidden: {
    display: 'none'
  }
});

interface IProps extends WithStyles<typeof styles> {
  value: any,
  menu?: any[],
  nameMap?: any,
  onSelect: (value: any) => void,
  placeholder?: string,
  readonly?: boolean,
  fullWidth?: boolean,
  className?: string,
  valuesList?: any[],
  groupedValuesList?: any[],
  error?: boolean|string,
  checkboxes?: boolean,
  grouped?: string,
  selectFirstAsDefault?: boolean,
  selectLastAsDefault?: boolean,
  notSpecified?: boolean,
  onApply?: () => any,
  onClose?: () => any,
  withCheck?: boolean,
  withoutPosition?: boolean,
  textOverflow?: boolean,
  isPopover?: boolean,
  name?: string,
}



const EMPTY_OPTION = { id: -1, name: 'Not Specified' };



const Select = (props: IProps) => {
  const { classes, readonly, isPopover, valuesList, nameMap, menu, value, selectFirstAsDefault, selectLastAsDefault } = props;
  const opened = useOpen();
  const menuAnchor = useAnchor();
  const controlsValue = isPopover ? menuAnchor.value : opened.value;
  const [activeCandidateId, setActiveCandidateId] = useState<number>(-1);
  const [maxWidth, setMaxWidth] = useState<string | number>('unset');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  let containerRef = createRef<HTMLDivElement>();



  const handleKey = (event: any): void => {

    let len;

    if (valuesList && !!valuesList.length) {
      len = getValuesList()?.length;
    }

    if (menu && !!menu.length) {
      len = menu.length;
    }

    if (!len) {
      return setActiveCandidateId(-1)
    }


    switch (event.key) {

      case 'ArrowDown':
        event.preventDefault()
        event.stopPropagation();

        return setActiveCandidateId(
          activeCandidateId + 1 >= len ? 0 : activeCandidateId + 1
        )

      case 'ArrowUp':
        event.preventDefault()
        event.stopPropagation();

        return setActiveCandidateId(
          activeCandidateId - 1 < 0 ? len - 1 : activeCandidateId - 1
        )

      case 'Enter':
        const values = getValuesList();
        const val = !!values?.length ? values?.[activeCandidateId] : menu?.[activeCandidateId]

        if (!val) return;

        return handleClick(val);

      case 'Escape':
        return opened.close()

      case 'Tab':
        return opened.close()

    }
  }



  const handleClick = (item: any, idx?: number): void => {
    // if(idx) {
    //   setActiveCandidateId(idx)
    // }


    if (props.checkboxes) {
      return props.onSelect(getCheckboxValues(item));
    }

    opened.close();

    if (item?.id === EMPTY_OPTION.id) {
      return props.onSelect(null)
    }


    props.onSelect(item);
  };



  const handleSelectAll = (e: MouseEvent<HTMLSpanElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if (
      value && Array.isArray(value) &&
      valuesList && Array.isArray(valuesList) &&
      value.length === valuesList.length
    ) props.onSelect([])
    else props.onSelect(valuesList);
  }



  const getValue = (): any => {
    if (!value || (Array.isArray(value) && !value.length) || (typeof value === 'object' && !Object.keys(value).length)) {
      return props.placeholder || "Not Specified";
    }

    if (props.placeholder && Array.isArray(value) && !value.length) {
      return props.placeholder
    }
    
    if (!!valuesList?.length) {
      if (Array.isArray(value)) {
        return value
          .filter((item) => !!item)
          .map((item: any) => item.name).join(', ')
      } else {
        const val = valuesList.find((i: any) => i.id === value.id);
        return (val && val.name) || "Not Specified";
      }
    } 
  


    if (value.name) {
      return value.name
    }


    if(Array.isArray(value)) {
      return value.filter((item: any) => !!item).map((item: any) => item.name).join(',')
    }
    
    
    return nameMap != null ? nameMap[value || 0] : value;
  }



  const getCheckboxValues = (item: any): any[] => {
    let newTypes = Array.isArray(value) ? [...value] : [value];
    newTypes = newTypes.filter((item: any) => !!item)

    return getIds(newTypes).includes(item.id ?? item.name)
      ? newTypes.filter((i: any) => (i.id ?? i.name) !== (item.id ?? item.name))
      : [...newTypes, item]
  }



  const getSelectedCheckboxes = (): any[] | undefined => {
    if (props.checkboxes) {
      return Array.isArray(value) ? value : [value]
    }

    return undefined
  }



  const getSelectAllLabel = () => {
    if (
      value && Array.isArray(value) &&
      valuesList && Array.isArray(valuesList) &&
      value.length === valuesList.length
    ) return 'Deselect All'
    return 'Select All'
  }



  const handleFocus = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    if (!readonly) {
      setIsFocused(true)
    }
  }



  const handleBlur = (e: any): void => {
    setIsFocused(false);
    setActiveCandidateId(-1)
  }



  const handleToggle = (e: any): void => {
    if(readonly) return;

    const controls = isPopover ? menuAnchor : opened;

    if (controls.value && props.onClose) {
      props.onClose()
    }

    if (controls.value && isFocused) {
      setIsFocused(false)
      return;
    }

    controls.toggle(e)
  }



  const onClickAway = (e: any): void => {
    opened.close();
    props.onClose && props.onClose()
  }



  const selectFirstOption = (): void => {
    if (!props.checkboxes && !!valuesList?.length) {
      props.onSelect(valuesList[0])
    }
  }



  const selectLastOption = (): void => {
    if (!props.checkboxes && !!valuesList?.length) {
      props.onSelect(valuesList[valuesList.length - 1])
    }
  }



  const getValuesList = (): any => {
    return props.valuesList
      ? props.notSpecified ? [EMPTY_OPTION, ...props.valuesList] : props.valuesList
      : undefined
  }



  const shouldCheck = (option: any): boolean => {
    return !!(value?.id !== undefined && value?.id === option?.id && props.withCheck)
  }








  useEffect(() => {
    setMaxWidth(containerRef.current ? containerRef.current.offsetWidth - valuePadding : 'unset');
  }, [containerRef])



  useEffect(() => {
    if (selectFirstAsDefault && ((typeof value === 'object' && !Object.keys(value).length) || !value)) {
      selectFirstOption()
      return;
    }

    if (selectLastAsDefault && ((typeof value === 'object' && !Object.keys(value).length) || !value)) {
      selectLastOption()
      return
    }
  }, [valuesList, selectFirstAsDefault, selectLastAsDefault, value, props.checkboxes])



  useEffect(() => {
    if (isFocused) {
      opened.open();
    }
  }, [isFocused])



  useEffect(() => {
    if (readonly && controlsValue) {
      opened.close()
    }
  }, [readonly])






  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div
        tabIndex={0}
        onKeyDown={handleKey}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleToggle}
        className={classNames(
          classes.root,
          props.className,
          { [classes.readonly]: readonly }
        )}
      >
        <FormControl fullWidth
          disabled={readonly}
          className={classNames({[classes.formControlOverlap]: controlsValue})}
        >
          <div ref={containerRef} className={classes.container}>
            <div className={classNames(
              classes.form,
              {
                [classes.formOpened]: controlsValue,
                [classes.error]: !!props.error
              }
            )}>
              <div className={classNames(classes.value, {[classes.valueOverflow]: props.textOverflow})} style={{ maxWidth }}>
                {getValue()}
              </div>
              <KeyboardArrowDownIcon className={classNames(
                classes.button,
                { [classes.buttonReversed]: controlsValue }
              )} />
            </div>




            {!isPopover && (
              <div className={classNames(
                classes.menuContainer,
                { [classes.menuContainerOpened]: controlsValue }
              )}>
                <SelectMenu
                  menu={props.menu}
                  nameMap={props.nameMap}
                  classes={{ root: classes.menu }}
                  onClick={handleClick}
                  valuesList={getValuesList()}
                  checkboxes={getSelectedCheckboxes()}
                  onSubmit={opened.close}
                  selectAllLabel={getSelectAllLabel()}
                  onSelectAll={handleSelectAll}
                  activeId={activeCandidateId}
                  withCheck={props.withCheck}
                  value={value}
                  grouped={props.grouped}
                  groupedValuesList={props.groupedValuesList}
                />
              </div>
            )}



            {isPopover && (
              <Menu keepMounted
                id="customized-menu"
                classes={{
                  paper: classes.popoverMenu
                }}
                anchorEl={menuAnchor.value}
                elevation={0}
                open={!!menuAnchor.value}
                onClose={menuAnchor.close}
                getContentAnchorEl={null}
                PaperProps={{
                  style: { width: menuAnchor.value?.offsetWidth ?? 'initial' }
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                {getValuesList().map((option: any, idx: number) => (
                  <MenuItem
                    key={option.id}
                    className={classes.popoverMenuItem}
                    onClick={(e: any) => {
                      handleClick(option, idx);
                      menuAnchor.close()
                    }}
                  >
                    {option.name}
                    <CheckIcon className={classNames(
                      classes.checkIcon,
                      {[classes.checkIconHidden]: !shouldCheck(option)}
                    )} />
                  </MenuItem>
                ))}
              </Menu>
            )}
            


          </div>
        </FormControl>
      </div>
    </ClickAwayListener>
  );
}

export default withStyles(styles)(Select)