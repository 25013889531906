import React, { useMemo } from 'react';
import { Theme } from '../../../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { formatDateTime, formatAgoTime } from '../../../../../utils/utils';

const styles = (theme: Theme) => createStyles({
  date: {
    display: 'flex',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 1.2,
    color: '#657274',
  },
  dateHidden: {
    display: 'none'
  },
  dateItem: {
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  },
  updatedAt: {
    marginLeft: 5
  },
  updatedAtHidden: {
    display: 'none'
  }
})

interface IProps extends WithStyles<typeof styles> {
  createdAt: string;
  updatedAt: string;
  hidden?: boolean;
}


const TaskCommentDate = ({ classes, createdAt, updatedAt, hidden }: IProps) => useMemo(() => (
  <div className={classNames(
    classes.date,
    {[classes.dateHidden]: hidden}
  )}>
    <span className={classes.dateItem}>
      {formatAgoTime(createdAt)}
    </span>


    <span className={classNames(
      classes.dateItem,
      classes.updatedAt,
      {[classes.updatedAtHidden]: createdAt === updatedAt}
    )}>
      (Edited {formatDateTime(updatedAt)})
    </span>

  </div>
), [createdAt, updatedAt, hidden])


export default withStyles(styles)(TaskCommentDate)