import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const ImportedDuplicatesIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="15" height="16" viewBox="0 0 15 16">
      <g fill="currentColor">
        <path d="M11.299 7.602L8.43 5.396c-.143-.109-.341-.133-.509-.06-.168.072-.275.228-.275.4v1.323H.477C.215 7.059 0 7.256 0 7.5v.882c0 .244.214.442.478.442h7.17v1.323c0 .171.106.327.274.4.064.027.134.041.203.041.11 0 .219-.034.306-.102l2.868-2.206c.11-.084.172-.208.172-.339 0-.13-.063-.255-.172-.339z"/>
        <path d="M14.063 0H.937C.42 0 0 .448 0 1v5h1.875V2h11.25v12H1.875v-4H0v5c0 .553.42 1 .938 1h13.124c.519 0 .938-.447.938-1V1c0-.552-.42-1-.938-1z"/>
      </g>
    </SvgIcon>
  );
};