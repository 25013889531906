import * as actions from '../actions/auth';
import { ManageableTableColumn } from '../shared/interfaces';
import { syncTableColumns } from '../actions/helpers/helpers';

export const authInitialState = {
  token: null,
  user: {} as any,
  errors: []
};

export default function (state = authInitialState, action) {
  switch (action.type) {
    
    case actions.AUTH_LOGIN:
      return {
        token: action.token,
        user: action.user
      }


    case actions.UPDATE_USER:
      const { table_column_prefs, ...user } = action.user;
      const tablePrefs = syncTableColumns(!!Object.keys(table_column_prefs).length ? table_column_prefs : [])
      return {
        ...state,
        user: {
          ...user,
          table_column_prefs: tablePrefs
        }
      }


    case actions.AUTH_RESET_PASSWORD:
      return {
        ...state,
        user: action.user,
        token: action.token,
        errors: [],
      }


    case actions.AUTH_RESET_PASSWORD_FAILED:
      return {
        ...state,
        errors: action.errors,
      }


    case actions.AUTH_LOGOUT:
      return authInitialState


    case actions.SET_TABLE_PREFS:
      return {
        ...state,
        user: {
          ...state.user,
          table_column_prefs: action.tableColumns
        }
      }

    
    case actions.CHANGE_TABLE_PREFS:
      return {
        ...state,
        user: {
          ...state.user,
          table_column_prefs: {
            ...state.user.table_column_prefs,
            [action.tableType]: action.columns.sort(
              (a: ManageableTableColumn, b: ManageableTableColumn) => a.order - b.order
            )
          }
        }
      }


    case actions.SET_SETTINGS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data
        }
      }


    case actions.AUTH_REQUEST_RESET_PASSWORD:
    default:
      return state
  }
}