import React from 'react';
import { SvgIcon } from "@material-ui/core";

interface IProps {
  color?: string;
  className?: any;
}

export const PaperPlane = (props: IProps) => {
  const { color, ...svgProps } = props;
  return <SvgIcon {...svgProps} style={{'transform': 'scale(1.5)'}} width="100px" height="100px" viewBox="0 0 40 39">
    <g fill="#4192EC" fillRule="nonzero">
    <path d="M14.604 29.317v7.7a1.247 1.247 0 0 0 1.246 1.245c.388 0 .763-.183 1.002-.508l4.504-6.13-6.752-2.307zM39.4.309A1.246 1.246 0 0 0 38.1.219L.747 19.726A1.246 1.246 0 1 0 .92 22.01l10.384 3.55 22.115-18.91-17.113 20.618 17.404 5.948a1.25 1.25 0 0 0 1.05-.115c.314-.192.529-.516.583-.88L39.91 1.508a1.247 1.247 0 0 0-.51-1.198z"/>
    </g>
  </SvgIcon>
};
