import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const NotesIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="64" height="64" viewBox="0 0 64 64">
      <g fill="none" fillRule="evenodd">
        <circle cx="32" cy="32" r="32" fill="#F4F8F9" fillRule="nonzero"/>
        <path d="M16 16h32v32H16z"/>
        <path fill="#4192EC" fillRule="nonzero" d="M45.333 29.333l-8-8h-16A2.675 2.675 0 0 0 18.667 24v16.013c0 1.467 1.2 2.654 2.666 2.654l21.334-.014A2.663 2.663 0 0 0 45.333 40V29.333zm-9.333-6l7.333 7.334H36v-7.334z"/>
      </g>
    </SvgIcon>
  );
};