import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const NotificationIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="13" height="15" viewBox="0 0 13 15">
      <defs>
        <path id="z" d="M8.5 16.583a1.42 1.42 0 0 0 1.417-1.416H7.083c0 .779.638 1.416 1.417 1.416zm4.25-4.25V8.792c0-2.175-1.155-3.995-3.188-4.477v-.482c0-.588-.474-1.062-1.062-1.062-.588 0-1.063.474-1.063 1.062v.482C5.412 4.797 4.25 6.61 4.25 8.792v3.541L2.833 13.75v.708h11.334v-.708l-1.417-1.417zm-1.417.709H5.667v-4.25c0-1.757 1.07-3.188 2.833-3.188 1.764 0 2.833 1.431 2.833 3.188v4.25z"/>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-2 -2)">
        <path d="M0 0h17v17H0z"/>
        <mask id="e" fill="#fff">
          <use xlinkHref="#z"/>
        </mask>
        <path fill="currentColor" d="M0 1h17v17H0z" mask="url(#e)"/>
      </g>
    </SvgIcon>
  );
};
