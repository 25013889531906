import { PrintType } from '../../shared/types';

/**
 * Actions
 */

export const SET_PRINT_DATA = 'SET_PRINT_DATA';
export const CLEAR_PRINT    = 'CLEAR_PRINT';


/**
 * Action creators
 */

export function setPrintData(printType: PrintType, data: any, ): object {
  return {type: SET_PRINT_DATA, printType, data}
}

export function clearPrintData(): object {
  return {type: CLEAR_PRINT}
}