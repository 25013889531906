import axios from 'axios';
import { API_URL } from "../constants/api";
import { baseHeaders, processResponse } from "./helpers/helpers";
import Request from '../components/request/Request';


function getEmailDrafts(body: string = '') {
  return Request.get(`${API_URL}/emails/contexts/${body}&is_sent=0`)
}

function getNextDrafts(url: string) {
  return Request.get(url)
}

function deleteMultipleDrafts(ids: number[]) {
  return Request.delete(`${API_URL}/emails/contexts/delete_multiple/`, { data: { ids } }, true)
}

function createDraft(body: any = {}) {
  return Request.post(`${API_URL}/emails/contexts/`, body)
}

function deleteDraft(id: number) {
  return Request.delete(`${API_URL}/emails/contexts/${id}/`)
}

function getDraft(id: number) {
  return Request.get(`${API_URL}/emails/contexts/${id}/`)
}

function saveDraft(data: any, asTemplate?: boolean) {
  const { files = [], ...mainData } = data;

  if(asTemplate) {
    return Request.post(`${API_URL}/emails/contexts/${data.id}/save_as_template/`, mainData)
  }

  return Request.patch(`${API_URL}/emails/contexts/${data.id}/`, mainData)
}


function uploadDraftFiles(id: number, files: any) {
  const formData = new FormData();

  for (const file of files){
    formData.append('files', file);
  }

  return Request.post(`${API_URL}/emails/contexts/${id}/add_files/`, formData)
}

function deleteDraftFile(id: number) {
  return Request.delete(`${API_URL}/emails/contexts/files/${id}/`)
}


function sendDraft(data: any) {
  const { files, ...mainData } = data;

  return Request.post(`${API_URL}/emails/contexts/${data.id}/send/`, mainData)

}

function sendDraftAsCompany(data: any) {
  data.is_company_email = 1;
  return sendDraft(data)
}

function createForwardEmail(forward_email: number) {
  return Request.post(`${API_URL}/emails/contexts/`, { forward_email })
}



export default {
  getEmailDrafts,
  getNextDrafts,
  deleteMultipleDrafts,
  createDraft,
  deleteDraft,
  getDraft,
  saveDraft,
  uploadDraftFiles,
  deleteDraftFile,
  sendDraft,
  sendDraftAsCompany,
  createForwardEmail
}
