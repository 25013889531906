import { IconButton, WithStyles } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { useState } from "react";
import { INote } from "../shared/interfaces";
import { Theme } from "../theme";
import { formatDateTime } from "../utils/utils";
import AuthorLabel from "./AuthorLabel";
import NoteBaseContainer from "./NoteBaseContainer";
import TextInput from "./TextInput";


const styles = (theme: Theme) => createStyles({
  iconButton: {
    color: theme.palette.primary.main,
    padding: 0,
    marginLeft: 4
  },
  saveIcon: {
    fontSize: 15,
    padding: 2.5
  },
  deleteIcon: {
    fontSize: 18
  },
  horizontal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  actions: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)'
  },
  input: {
    width: '90%',
  },
  inputReadonly: {
    height: 'initial !important',
    overflow: 'hidden',
    minHeight: 'initial',
    paddingBottom: '0 !important',
    fontSize: '13px !important',
    lineHeight: 1.54
  },
  inputNew: {
    width: "95%"
  },
  smallPadding: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  inputStyle: {
    padding: '10px 16px !important',
    color: `${theme.palette.primary.main}`
  },
  inputStyleReadonly: {
    padding: '0 !important',
    transform: 'translateX(0)',
    minHeight: 'initial',
    fontSize: '13px !important',
    color: '#657274 !important',
    fontWeight: 300
  },
  checkIcon: {
    fontSize: 20
  }
});

interface IProps extends WithStyles<typeof styles> {
  note: INote;
  readonly?: boolean;
  hideHeader?: boolean;
  onNoteChange?: (note: INote) => void;
  onDelete?: Function;
  onEnableEdit?: Function;
  onEditComplete: (note: INote) => void;
  username?: string;
  creatorId?: number;
  newNote?: boolean;
}

const NoteEditable = (props: IProps) => {
  const { classes, note, readonly, newNote } = props;
  const [text, setText] = useState<string>(props.note.text);

  const handleChange = (value: string): void => {
    setText(value);
    props.onNoteChange && props.onNoteChange({...note, text: value});
  };

  const editComplete = (e: any): void => {

    e.preventDefault();
    e.stopPropagation();
    
    if(!readonly) {
      props.onEditComplete({...note, text: text})
    }
  };

  const handleEnableEdit = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    props.onEnableEdit && props.onEnableEdit();
  };

  const renderHeader = (): any => {
    if(!props.creatorId || !props.username) return null;
    return (
      <div className={classes.horizontal}>
        <AuthorLabel label={'Note added by'} name={props.username} id={props.creatorId} />
      </div>
    );
  }

  return (
    <NoteBaseContainer
      date={formatDateTime(note.created_at)}
      header={renderHeader()}
      showOnlyContent={props.hideHeader}
    >

      <TextInput multiline
        value={readonly ? note.text : text}
        readonly={readonly}
        onChange={handleChange}
        onEnter={editComplete}
        inputStyle={classNames(
          classes.inputStyle,
          {[classes.inputStyleReadonly]: readonly}
        )}
        className={classNames(
          classes.input,
          {
            [classes.inputReadonly]: readonly,
            [classes.inputNew]: props.newNote
          },
        )}
      />



      <div className={classes.actions}>
        {readonly && !newNote && (
          <IconButton className={classes.iconButton} onClick={handleEnableEdit}>
            <EditIcon className={classes.saveIcon} />
          </IconButton>  
        )}

        {!readonly && (
          <IconButton className={classes.iconButton} onClick={editComplete}>
            <CheckIcon className={classes.checkIcon} />
          </IconButton>
        )}

        {!newNote && (
          <IconButton
            className={classes.iconButton}
            onClick={(e: any) => props.onDelete && props.onDelete()}
          >
            <ClearIcon className={classes.deleteIcon} />
          </IconButton>
        )}
      </div>

    </NoteBaseContainer>
  );
}

export default withStyles(styles)(NoteEditable)