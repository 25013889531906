import * as actions from '../../actions/people/people';
import { InviteStatus, IPerson, initialPerson } from '../../shared/models/Person';
import { getIds } from '../../utils/utils';

export const peopleInitialState = {
  results: [] as any[],
  count: 0,
  next: null,
  previous: null,
  person: initialPerson,
  updatedPerson: {} as any,
  loading: false,
  componentPeople: {
    results: [] as any[],
    count: 0,
    next: null,
    previous: null,
  },
  validationErrors: {},
  personView: {
    readonly: true
  }
};

export default function(state = peopleInitialState, action) {
  switch (action.type) {

    case actions.GET_PEOPLE_LIST:
      return {
        ...state,
        ...action.data,
      }


    case actions.UPDATE_PEOPLE_LIST:
    case actions.GET_PEOPLE_LIST_DETAILS:
      return {
        ...state,
        results: action.people,
        count: action.people.length
      }


    case actions.UPDATE_PEOPLE:
      const newPeopleIds = getIds(action.people);
      return {
        ...state,
        results: state.results.map((person: any) => {
          if(newPeopleIds.includes(person.id)) {
            const newPerson = action.people.find((item: any) => item.id === person.id)
            return newPerson ?? person
          }

          return person
        })
      }


    case actions.DELETE_PEOPLE:
      return {
        ...state,
        results: state.results.filter((person: any) => !action.ids.includes(person.id)),
        count: state.count - action.ids.length
      }


    case actions.APPEND_PEOPLE:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }


    case actions.GET_PERSON:
    case actions.GET_PERSON_DETAILS:
    case actions.CHANGE_PERSON:
    case actions.CREATE_PERSON:
    case actions.UPDATE_PERSON:
      return {
        ...state,
        person: action.person,
        updatedPerson: {
          id: action.person?.id
        }
      }

    case actions.CHANGE_PERSON_FIELD:
      return {
        ...state,
        updatedPerson: {
          ...state.updatedPerson,
          ...action.field
        }
      }

    case actions.CLEAR_PERSON:
    case actions.DELETE_PERSON:
      return {
        ...state,
        person: {},
        updatedPerson: {},
        count: --state.count
      }

    case actions.PEOPLE_CREATE_NOTE:
      return {
        ...state,
        person: {
          ...state.person,
          notes: [
            ...(state.person?.notes || []),
            action.note
          ]
        }
      }

    case actions.PEOPLE_UPDATE_NOTE:
        return {
          ...state,
          person: {
            ...state.person,
            notes: state.person.notes 
            ? state.person.notes.map(note => note.id === action.note.id ? action.note : note)
            : []
          }
        }


    case actions.PEOPLE_DELETE_NOTE:
      return {
        ...state,
        person: {
          ...state.person,
          notes: state.person.notes 
            ? state.person.notes.filter(note => note.id !== action.id)
            : []
        }
      }

    case actions.INVITE_PERSON:
      return {
        ...state,
        person: {
          ...state.person,
          invite_status: InviteStatus.Pending
        }
      }

    case actions.SET_ERRORS:
      return {
        ...state,
        person: {
          ...state.person,
          errors: action.errors
        }
      }

  
    case actions.SET_VALIDATION_ERROR:
      const newError = {
        ...state.validationErrors,
        ...action.error
      };
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          ...action.error
        }
      }

    
    case actions.CLEAR_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: {}
      }


    case actions.CLEAR_TASKS:
      return {
        ...state,
        person: {
          ...state.person,
          tasks: []
        }
      }

      case actions.CLEAR_TAGS:
        return {
          ...state,
          person: {
            ...state.person,
            tags: []
          }
        }

    case actions.CLEAR_PEOPLE:
      return {
        ...peopleInitialState
      }

    case actions.CANCEL_PERSON:
      return {
        ...state,
        updatedPerson: {}
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }


    case actions.SET_COMPONENT_PEOPLE:
      return {
        ...state,
        componentPeople: action.data
      }


    case actions.CLEAR_PEOPLE_LOCAL:
      return {
        ...state,
        componentPeople: {
          results: [],
          count: 0,
          next: null,
          previous: null
        }
      }

    
    case actions.SET_READONLY:
      return {
        ...state,
        personView: {
          ...state.personView,
          readonly: action.state
        }
      }

    

    default:
      return state
  }
}