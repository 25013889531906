import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Theme } from '../theme';
import CustomButton from './Button';


const styles = (theme: Theme) => createStyles({
  root: {
    padding: '15px 35px 20px',
    overflow: 'unset'
  },
  title: {
    marginBottom: 13,
    padding: 0
  },
  titleTypography: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    textAlign: 'center',
    color: '#233539',
  },
  content: {
    marginBottom: 20,
    padding: 0,
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.71,
    textAlign: 'center',
    color: '#657274',
    margin: 0,
    overflow: 'unset'
  },
  actions: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cancel: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    textAlign: 'center',
    color: '#4192ec',
    padding: '8px 16px',
    backgroundColor: 'transparent',
    border: '1px solid #4192ec',
    borderRadius: 4,
    height: 'initial',
    minWidth: 115
  },
  confirm: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    textAlign: 'center',
    color: '#fff',
    padding: '8px 16px',
    backgroundColor: '#4192ec',
    border: '1px solid #4192ec',
    borderRadius: 4,
    height: 'initial',
    minWidth: 115,
    '&:hover': {
      backgroundColor: '#4192ec'
    }
  },
  closeIcon: {
    color: '#4192ec',
    fontSize: 25
  },
  closeIconWrapper: {
    position: 'absolute',
    top: 15,
    right: 10,
    cursor: 'pointer'
  }
})

interface IProps extends WithStyles<typeof styles> {
  title: string;
  open: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  onCancel: Function;
  onConfirm: Function;
  children?: any;
  className?: string;
  confirmDisabled?: boolean;
}

const ConfirmDialog = (props: IProps) => {
  const { classes } = props;


  const handleCancel = () : void => {
    return props.onCancel()
  }

  const handleConfirm = () : void => {
    return props.onConfirm()
  }


  return (
    <Dialog
      open={props.open}
      PaperProps={{
        className: classNames(classes.root, props.className)
      }}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      onClose={handleCancel}
    >
      <DialogTitle
        className={classes.title}
        id="alert-dialog-slide-title"
      >
        <Typography className={classes.titleTypography}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {props.children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <CustomButton onClick={handleCancel} className={classes.cancel}>
          {props.cancelLabel || "Cancel"}
        </CustomButton>
        <CustomButton onClick={handleConfirm} className={classes.confirm} disabled={props.confirmDisabled}>
          {props.confirmLabel || "Confirm"}
        </CustomButton>
      </DialogActions>
      <div onClick={handleCancel} className={classes.closeIconWrapper}>
        <ClearIcon className={classes.closeIcon} />
      </div>
    </Dialog>
  );
}


export default withStyles(styles)(ConfirmDialog)