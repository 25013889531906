import * as actions from '../actions/search-history';

export const initialState = {
  history: {
    results: [] as any[],
    next: null,
    previous: null,
    count: 0
  },
  current: null
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SEARCH_SET_HISTORY:
      return {
        ...state,
        history: action.history
      }

    case actions.SEARCH_SET_QUERY:
      return {
        ...state,
        history: {
          ...state.history,
          results: [...state.history.results, action.item],
          count: ++state.history.count
        }
      }
  
    case actions.SEARCH_REMOVE_QUERY:
      return {
        ...state,
        history: {
          ...state.history,
          results: [...state.history.results].filter((item: any) => 
            item.id !== action.id
          ),
          count: --state.history.count
        }
      }

    case actions.SEARCH_SET_HISTORY_ITEM:
      return {
        ...state,
        current: action.item
      }


    case actions.CLEAR_HISTORY_ITEM:
      return {
        ...state,
        current: null
      }


    case actions.SEARCH_UPDATE_HISTORY_ITEM:
      return {
        ...state,
        history: {
          ...state.history,
          results: state.history.results.map((item: any) => 
            item.id === action.item?.id ? action.item : item
          )
        }
      }

    default:
      return state
  }
}