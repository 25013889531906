import * as actions from '../../actions/contact-management/possible-duplicates';

export const initialState = {
  results: [] as any[],
  count: 0,
  // next: null,
  // previous: null,
  initiated: false,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_POSSIBLE_DUPLICATES:
      return {
        ...state,
        ...action.data,
        initiated: true
      }

    
    case actions.CHANGE_POSSIBLE_DUPLICATES:
      const newResults = [...state.results];
      if(newResults[action.idx]?.[action.fieldName]) {
        newResults[action.idx][action.fieldName] = action.value
      }

      return {
        ...state,
        results: newResults,
      }

    
    case actions.DELETE_POSSIBLE_DUPLICATES:
      return {
        ...state,
        results: state.results.filter((item: any) => !action.ids.includes(item.id)),
        count: state.count - action.ids.length
      }


    case actions.APPEND_POSSIBLE_DUPLICATES:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }


    case actions.SET_INITIATED_POSSIBLE_DUPLICATES:
      return {
        ...state,
        initiated: action.state
      }

    
    case actions.CLEAR_POSSIBLE_DUPLICATES:
      return {
        ...initialState
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }
  
    
    default:
      return state
  }
}