import React from 'react';
import { Theme } from '../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { acronym } from '../utils/utils';
import { ProfileColorHex } from '../shared/types';
import { Avatar as AvatarMU } from '@material-ui/core';
import { getUsername } from '../utils/user';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  icon: {
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 500,
    width: 25,
    height: 25,
    border: '1px solid #fff',
    position: 'relative',
    left: 0,
    '&:first-child': {
      left: 0,
      '&:last-child': {
        borderColor: 'transparent',
        left: 0
      }
    },
    '&:nth-child(2)': {
      left: 10
    },
    '&:nth-child(3)': {
      left: 20
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  person: any;
  className?: any;
}

const Avatar = (props: IProps) => {
  const { classes, person } = props;

  return (
    <AvatarMU
      style={ProfileColorHex[person.profile_type]}
      className={classNames(classes.icon, props.className)}
    >
      {acronym(getUsername(person))}
    </AvatarMU>
  )
}


export default withStyles(styles)(Avatar)