import commentsService from '../services/comments';

/**
 * Actions
 */

export const SET_COMMENTS   = 'SET_COMMENTS';
export const ADD_COMMENT    = 'ADD_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';



/**
 * Action creators
 */

export function setAllComments(comments: any[]): object {
  return {type: SET_COMMENTS, comments}
}

export function addSingleComment(comment: any): object {
  return {type: ADD_COMMENT, comment}
}

export function updateSingleComment(comment: any): object {
  return {type: UPDATE_COMMENT, comment}
}

export function deleteSingleComment(commentId: number): object {
  return {type: DELETE_COMMENT, commentId}
}

export function clearComments(): object {
  return {type: CLEAR_COMMENTS}
}


/**
 * Async action creators
 */

export const getComments = (taskId: number) => {
  return (dispatch) => {
    return commentsService.getComments(taskId)
      .then((res: any) => dispatch(setAllComments(res.results)))
      .catch(err => {
        console.log('An error occured while getting the contacts.')
      })
  }
}

export const createComment = (comment: any): any => {
  return (dispatch) => {
    return commentsService.createComment(comment)
      .then((res: any) => {
        dispatch(addSingleComment(res))
      })
      .catch(err => {
        console.log('An error occured while getting the contacts.')
      })
  }
}

export const updateComment = (commentId: number, body: any): any => {
  return (dispatch) => {
    return commentsService.updateComment(commentId, body)
      .then((res: any) => {
        dispatch(updateSingleComment(res))
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log('An error occured while getting the contacts.');
        return Promise.reject(err)
      })
  }
}

export const deleteComment = (commentId: number) => {
  return (dispatch) => {
    return commentsService.deleteComment(commentId)
      .then((res: any) => {
        dispatch(deleteSingleComment(commentId))
      })
      .catch(err => {
        console.log('An error occured while getting the contacts.')
      })
  }
}





