import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useOpen } from '../shared/hooks';
import { palette, Theme } from '../theme';
import Button, { ButtonSize } from './Button';
import CardBasicsHeader from './CardBasicsHeader';
import ConfirmDialog from './ConfirmDialog';
import PageLeaveDialog from './PageLeaveDialog';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  readonly: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#233539'
  },
  actions: {
    position: 'absolute',
    display: 'flex'
  },
  cancelButton: {
    backgroundColor: palette.mist500,
    marginRight: 8,
    paddingTop: 0,
    paddingBottom: 0
  },
  saveButton: {
    paddingTop: 0,
    paddingBottom: 0
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  readonly?: boolean;
  title?: string;
  onCancel: () => any;
  onSave: () => any;
  onEdit?: (...args: any[]) => any;
  onDelete?: (...args: any[]) => any;
  onRevoke?: (...args: any[]) => any;
  isNew?: boolean;
}


const InfoPageHeader = (props: IProps) => {
  const { classes, readonly, isNew, title = "Contact Information" } = props;
  const cancelControls = useOpen();



  const onCancel = (): void => {
    cancelControls.close();
    props.onCancel()
  }






  return (
    <div className={classNames(classes.root, {[classes.readonly]: readonly})}>
      <span className={classes.title}>{title}</span>
      <div className={classes.container}>
        <div className={classes.actions}>


          {!readonly && (
            <Fragment>
              <Button size={ButtonSize.Tiny}
                classes={{ root: classes.cancelButton }}
                onClick={isNew ? onCancel : cancelControls.open}
              >
                Cancel
              </Button>


              <Button primary
                size={ButtonSize.Tiny}
                classes={{ root: classes.saveButton }}
                onClick={props.onSave}
              >
                Save
              </Button>
            </Fragment>
          )}



          {readonly && (
            <CardBasicsHeader onEdit={props.onEdit}
              onDelete={props.onDelete}
              onRevoke={props.onRevoke}
            />
          )}


        </div>
      </div>


      {!readonly && <PageLeaveDialog />}

      {!isNew && <>
        <ConfirmDialog
          title="Are you sure?"
          open={cancelControls.value}
          cancelLabel="Cancel"
          confirmLabel="YES"
          onCancel={cancelControls.close}
          onConfirm={onCancel}
        >
          Are you sure you want to discard your changes?
        </ConfirmDialog>
      </>}




    </div>
  )
}


export default withStyles(styles)(
  withRouter(InfoPageHeader)
)