import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { Theme } from '../../../theme';
import BulkEditSwitch from './BulkEditSwitch';

const styles = (theme: Theme) => createStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  header: {
    color: '#233539',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    textTransform: 'capitalize'
  },
  headerRow: {
    marginBottom: 13,
    '& > $colLeft': {
      textTransform: 'uppercase'
    }
  },
  colLeft: {
    flex: 7.9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  colRight: {
    flex: 2.1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
})


interface IProps extends WithStyles<typeof styles> {
  title: string;
  idx: number;
  name?: string;
}

const BulkEditHeader = (props: IProps) => {
  const { classes } = props;





  return (
    <div className={classNames(classes.row, classes.headerRow)}>
      <span className={classNames(classes.colLeft, classes.header)}>
        {props.title}
      </span>

      {!props.idx && (
        <span className={classNames(classes.colRight, classes.header)}>
          Edit Settings
        </span>
      )}

      {!!props.name && (
        <BulkEditSwitch
          name={props.name}
          labelChecked="add data"
          labelUnchecked="overwrite"
        />
      )}
    </div>
  )
}


export default withStyles(styles)(BulkEditHeader)