import { SvgIcon } from "@material-ui/core";
import React from 'react';

export const TemplatesIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="17" height="17" viewBox="0 0 17 17">
      <g fill="currentColor">
        <path d="M0 3.938v12.366h17V3.938H0zm7.016 1.995h7.725v3.475H7.016V5.933zm0 4.891h7.725v3.475H7.016v-3.475zM4.03 5.899c.976 0 1.771.795 1.771 1.771 0 .977-.795 1.771-1.771 1.771-.977 0-1.771-.794-1.771-1.77 0-.977.794-1.772 1.77-1.772zm0 4.892c.976 0 1.771.794 1.771 1.77 0 .977-.795 1.772-1.771 1.772-.977 0-1.771-.795-1.771-1.771 0-.977.794-1.771 1.77-1.771zM0 .032L2.911.032 2.911 2.941 0 2.941zM3.907.032L17 .032 17 2.941 3.907 2.941z"/>
        <path d="M4.03 8.445c.427 0 .775-.347.775-.775 0-.427-.348-.775-.775-.775-.427 0-.775.348-.775.775 0 .428.348.775.775.775zM8.012 6.929L13.745 6.929 13.745 8.412 8.012 8.412zM4.03 13.337c.427 0 .775-.348.775-.775 0-.428-.348-.775-.775-.775-.427 0-.775.347-.775.775 0 .427.348.775.775.775zM8.012 11.82L13.745 11.82 13.745 13.303 8.012 13.303z"/>
      </g>
    </SvgIcon>

  );
};