import React, { MouseEvent } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import OutlookWidget from '../OutlookWidget';
import { isAdmin, isUser } from '../../utils/user';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';
import { EmailManagementIcon } from '../icons/EmailManagementIcon';
import { ROUTE_EMAIL_MANAGEMENT } from '../../constants/routes';
import ManagementNavigationWidget from './ManagementNavigationWidget';
import EmailManagementWidget from './EmailManagementWidget';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    margin: '0 15px 0 0',
    alignItems: 'center',
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      marginRight: 17,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  
}

const HeaderIcons = (props: IProps) => {
  const { classes } = props;
  const user = useSelector((state: any) => state.user.user);



  if(!(isAdmin(user) || isUser(user))) {
    return null
  }



  return (
    <div className={classes.root}>

      <ManagementNavigationWidget />
      <OutlookWidget />
      {/* <EmailManagementWidget /> */}

    </div>
  )
}


export default withStyles(styles)(
  withRouter(HeaderIcons)
)