import { IconButton, WithStyles } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearNotification, NotificationTypes } from "../../actions/common/notifications";
import { WarningIcon } from "../../components/icons/WarningIcon";
import { Theme } from "../../theme";



const AUTO_HIDE_DURATION = 7000;

const variantIcon = {
  [NotificationTypes.SUCCESS]: CheckCircleIcon,
  [NotificationTypes.WARNING]: WarningIcon,
  [NotificationTypes.ERROR]: ErrorIcon,
  [NotificationTypes.INFO]: InfoIcon,
}

const WrapperStyles = (theme: Theme) => createStyles({
  root: {
    padding: 0,
    backgroundColor: '#fff',
    color: 'red',
    minWidth: 350,
    minHeight: 40,
    position: 'relative',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.15)',
    maxWidth: 500
  },
  snackbarContentHidden: {
    display: 'none'
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: '50%',
    right: 5,
    transform: 'translate(0, -50%)'
  },
  message: {
    padding: 0,
    display: 'flex',
    height: '100%',
    color: '#657274',
    width: '100%',
    boxSizing: 'border-box'
  },
  closeIconWrapper: {
    padding: 5
  },
  closeIcon: {
    color: '#657274',
    fontSize: 17
  },
  notificationWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  notificationText: {
    padding: '10px 37px 10px 12px',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.67,
    width: '100%'
  },
  notificationIconWrapper: {
    display: 'flex',
    width: 38,
    minWidth: 38,
    backgroundColor: '#657274',
    borderRadius: '4px 0 0 4px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationIcon: {
    fontSize: 20,
    color: '#fff'
  },
  [NotificationTypes.SUCCESS]: {
    color: '#15908a',
    '& > $notificationIconWrapper': {
      backgroundColor: '#15908a'
    }
  },
  [NotificationTypes.WARNING]: {
    color: '#dd5a1b',
    '& > $notificationIconWrapper': {
      backgroundColor: '#dd5a1b',
      '& > $notificationIcon': {
        fontSize: 18
      }
    }
  },
  [NotificationTypes.ERROR]: {
    color: '#d52023',
    '& > $notificationIconWrapper': {
      backgroundColor: '#d52023'
    }
  },
  [NotificationTypes.INFO]: {
    color: theme.palette.primary.main,
    '& > $notificationIconWrapper': {
      backgroundColor: theme.palette.primary.main
    }
  }
});

export interface WrapperProps extends WithStyles<typeof WrapperStyles>  {
  type: any;
  message?: string;
  onClose?: (event: any, reason?: string) => void;
}

const SnackbarContentWrapper = withStyles(WrapperStyles)((props: WrapperProps) => {
  const { classes, ...otherProps } = props;
  const Icon = variantIcon[props.type];


  

  const messageElement = (
    <span className={classNames(
      classes.notificationWrapper,
      classes[props.type]
    )}>
      <div className={classes.notificationIconWrapper}>
        <Icon className={classes.notificationIcon} />
      </div>
      <span className={classes.notificationText}>
        {props.message}
      </span>
    </span>
  )



  const action = [
    <IconButton key="action-1"
      className={classes.closeIconWrapper}
      onClick={props.onClose}
    >
      <CloseIcon className={classes.closeIcon} />
    </IconButton>,
  ]



  return (
    <SnackbarContent {...otherProps}
      classes={{
        root: classes.root,
        action: classes.action,
        message: classes.message
      }}
      message={messageElement}
      action={action}
    />
  );
});



const styles = (theme: Theme) => createStyles({
  snackBarRoot: {
    alignItems: 'flex-start',
    top: 65
  }
});

interface IProps extends WithStyles<typeof styles> {
  children: any
}

const DialogContainer = (props: IProps) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const notification = useSelector((state: any) => state.notifications);
  


  const handleHide = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearNotification())
  }





  return (
    <div>
      <Snackbar disableWindowBlurListener
        classes={{ root: classes.snackBarRoot }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!notification.type}
        autoHideDuration={AUTO_HIDE_DURATION}
        onClose={handleHide}
      >
        {!!notification.type ? (
          <SnackbarContentWrapper onClose={handleHide}
            type={notification.type}
            message={notification.message}
          />
        ) : null}
      </Snackbar>

      {props.children}
    </div>
  )
}

export default withStyles(styles)(DialogContainer)