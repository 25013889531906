import React, { useMemo, useCallback } from "react"
import { Theme } from "../../../theme"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import {
  SearchType,
  ProfileTypeNames,
  ProfileType,
} from "../../../shared/types"
import { useDispatch, useSelector } from "react-redux"
import {
  changeSearchRow,
  changeSearchSubRow,
} from "../../../actions/advanced-search"
import Select from "../../Select"
import {
  SearchPerson,
  SEARCH_OPTIONS_FIELDS,
  SearchPeopleType,
  SearchCP,
} from "../../../shared/models/Search"
import { isCP, getIds } from "../../../utils/utils"
import { useSearchData } from "./hooks/advanced-search-hooks"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
      maxWidth: 250,
      marginRight: 10,
      "&:last-child": {
        marginRight: 0,
      },
    },
    label: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 2,
      color: "#657274",
      marginRight: 10,
      width: 250,
    },
  })

interface IProps extends WithStyles<typeof styles> {
  idx: number
  subRowIdx?: number
}

const SearchSelectField = (props: IProps) => {
  const { classes, idx, subRowIdx } = props
  const { row, subRow, onChange } = useSearchData(idx, subRowIdx)
  const types = useSelector((state: any) => state.types)
  const id = subRow?.field?.id ?? row?.field?.id

  const categoriesIds = useMemo((): number[] => {
    if (!types.subcategories) return []

    const categoriesIds = types.subcategories.map((item: any) => item.category)
    const distinctValues = new Set(categoriesIds) as Set<number>
    return [...distinctValues]
  }, [types.subcategories])

  const isFieldCP = useMemo((): boolean => {
    return (
      subRowIdx !== undefined &&
      row.field?.id === SearchPerson.ContactType &&
      row.value?.some(isCP)
    )
  }, [row.field, row.value, subRowIdx])

  const isFieldSubcategory = useMemo((): boolean => {
    if (
      row.field?.id !== SearchPerson.ContactType ||
      !row.value ||
      subRowIdx === undefined
    )
      return false

    const ids = getIds(row.value)
    return (
      !!ids.length &&
      row.field?.id === SearchPerson.ContactType &&
      categoriesIds.some((i: number) => ids.includes(i))
    )
  }, [row.value, row.field, subRowIdx, categoriesIds])

  const options = useMemo(() => {
    if (isFieldCP) {
      return SEARCH_OPTIONS_FIELDS[SearchPeopleType.People_People_CP]
    }

    if (isFieldSubcategory) {
      return [SEARCH_OPTIONS_FIELDS[SearchPeopleType.People_People_Subcategory]]
    }

    return (
      SEARCH_OPTIONS_FIELDS[
        subRowIdx === undefined ? row.type?.id : subRow?.type?.id
      ] ?? []
    )
  }, [row, subRow, subRowIdx])

  const getSubcategoryLabel = useCallback(
    (categoryId: number): string => {
      return (
        types.categories.find((item: any) => item.id === categoryId)?.name ?? ""
      )
    },
    [types.categories]
  )

  const label = useMemo((): string | undefined => {
    switch (subRow?.field?.id) {
      case SearchPerson.ClientClass:
        return ProfileTypeNames[ProfileType.Client]

      case SearchPerson.Subcategory:
        if (subRow.args?.categoryId !== undefined) {
          return getSubcategoryLabel(subRow.args.categoryId)
        }

        return options[0]?.name
    }
  }, [subRow, options])

  const placeholder = useMemo(
    (): string =>
      options[0]?.id === SearchCP.Type
        ? "Select Capital Provider Field"
        : "Select Field",
    [options]
  )

  const value = useMemo(
    (): any =>
      id !== undefined
        ? options.find((type: any) => type.id === id)
        : undefined,
    [id, options]
  )

  const onSelect = useCallback((option: any): any => {
    return onChange({ field: option, condition: null, value: null })
  }, [])

  return useMemo(() => {
    if (label) {
      return <span className={classes.label}>{label}:</span>
    }

    if (!options.length) return null

    options.sort((a, b) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    )


    return (
      <Select
        className={classes.root}
        placeholder={placeholder}
        value={value}
        valuesList={options}
        onSelect={onSelect}
      />
    )
  }, [value, options, label])
}

export default withStyles(styles)(SearchSelectField)
