import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import { changeUserTablePrefs } from '../../actions/auth';
import { ARCHIVED_SEARCH } from '../../constants/index';
import tableColumns from '../../constants/table-columns';
import SlideInContainer from '../../containers/common/SlideInContainer';
import { ManageableTableColumn, SortDirection } from '../../shared/interfaces';
import { TableType, TableTypeSelector } from '../../shared/types';
import { Theme } from '../../theme';
import ManageColumnsDraggable from './ManageColumnsDraggable';
import MannageColumnsHeader from './MannageColumnsHeader';



const styles = (theme: Theme) => createStyles({
  row: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 2,
    color: '#657274',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #d9e4e8',
    outline: 'none'
  },
  container: {
    ...theme.mixins.scrollbar(theme, true),
    overflowY: 'auto',
    height: 'calc(100% - 42px)'
  }
})



interface IProps extends WithStyles<typeof styles> {
  tableType: TableType;
  opened: boolean;
  onClose: any;
}

const ManageColumns = (props: IProps) => {
  const { classes, tableType } = props;
  const dispatch = useDispatch();
  const searchItem = useSelector((state: any) => state.searchHistory.current);
  const columns = useSelector((state: any) => state.user.user.table_column_prefs?.[!!searchItem ? ARCHIVED_SEARCH : TableTypeSelector[tableType]] ?? []);
  const tableSelector = useMemo(() => !!searchItem ? ARCHIVED_SEARCH : TableTypeSelector[tableType], [tableType, searchItem])


  

  const order = (arr: ManageableTableColumn[]): ManageableTableColumn[] => {
    return arr.map((item: ManageableTableColumn, index: number) => ({ ...item, order: index }))
  }




  const move = (arr: ManageableTableColumn[], from: number, to: number) => {
    const elm = arr.splice(from, 1)[0];
    arr.splice(to, 0, elm);
    return order(arr)
  }


  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    if([source?.index, destination?.index].includes(undefined) || columns[destination.index]?.order === undefined) return;
    if(!columns[destination.index]?.active) return;

    const newColumns = move([...columns], source.index, destination.index)

    dispatchSetColumns(newColumns)
  }


  const onToggle = useCallback((column: ManageableTableColumn, checked: boolean): void => {
    const activeColumns = columns.filter((item: ManageableTableColumn) => !item.hidden && item.active);
    const activeLen = activeColumns.length;

    if(activeLen === 1 && !checked) return;


    let newColumns = [...columns];
    const idx = newColumns.findIndex((item: ManageableTableColumn) => item.type === column.type);

    if(column.group && checked) {
      const activeGroupColumnIdx = newColumns.findIndex((item: ManageableTableColumn) => item.group === column.group);
      newColumns[idx] = {
        ...newColumns[activeGroupColumnIdx],
        active: false,
        sortOrder: undefined,
        sortDirection: undefined
      }
      newColumns[activeGroupColumnIdx] = { ...column, active: true }
      newColumns = order(newColumns)
    } else {
      newColumns[idx] = {
        ...newColumns[idx],
        active: checked,
        sortOrder: checked ? newColumns[idx].sortOrder : undefined,
        sortDirection: checked ? newColumns[idx].sortDirection : undefined,
      }
      newColumns = move(newColumns, idx, checked ? activeLen : activeLen - 1)
    }


    newColumns = newColumns.map((item: ManageableTableColumn) => ({
      ...item,
      sortOrder: !!(item.sortOrder && column.sortOrder) ? (item.sortOrder > column.sortOrder ? item.sortOrder - 1 : item.sortOrder) : item.sortOrder
    }));

    const sortLen = newColumns.filter((item: ManageableTableColumn) => item.sortOrder !== undefined).length;

    if(!sortLen) {
      const idx = newColumns.findIndex((item: ManageableTableColumn) => !!item.search);
      newColumns[idx] = {
        ...newColumns[idx],
        sortOrder: 1,
        sortDirection: SortDirection.ASC
      }
    }

    
    dispatchSetColumns(newColumns)
  }, [columns])








  const dispatchSetColumns = (newColumns: ManageableTableColumn[]): any => {
    return dispatch(changeUserTablePrefs(tableSelector, newColumns))
  }






  if(!columns?.length) return null;





  return (
    <SlideInContainer opened={props.opened}>

      <MannageColumnsHeader
        className={classes.row}
        onClose={props.onClose}
        tableType={tableType}
      />


      <div className={classes.container}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, _) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {columns.map((item: ManageableTableColumn, idx: number) => item.hidden ? null : (
                  <ManageColumnsDraggable
                    key={item.type}
                    column={item}
                    tableType={tableType}
                    className={classes.row}
                    onToggle={onToggle}
                  />
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      

    </SlideInContainer>
  )
}





export default withStyles(styles)(
  ManageColumns
)