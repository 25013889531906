import outlookService from '../services/outlook';

/**
 * Actions
 */

export const SET_OUTLOOK_STATUS = 'SET_OUTLOOK_STATUS';
export const SET_LOADING        = 'SET_LOADING';



/**
 * Action creators
 */

export function setStatus(status: any): object {
  return {type: SET_OUTLOOK_STATUS, status}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}



/**
 * Async action creators
 */

export const getOutlookStatus = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return outlookService.getOutlookStatus(id)
      .then((res: any) => {
        if(!res) return;
        dispatch(setStatus(res));
      })
      .catch(err => {
        console.log('An error occured while getting Outlook status.')
      })
      .finally(() => {
        dispatch(setLoading(false));
      })
  }
}