import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const EmptyListIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="50" height="50" viewBox="0 0 50 50">
      <defs>
        <path id="empty_list_prefix__a" d="M37.05 15.783H25.917L23.133 13h-8.35c-1.544 0-2.77 1.239-2.77 2.783L12 32.483c0 1.545 1.239 2.784 2.783 2.784H37.05c1.545 0 2.783-1.239 2.783-2.784V18.567c0-1.545-1.238-2.784-2.783-2.784zm-5.567 11.134h-6.958v-2.784h11.133v2.784h-4.175z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx="25" cy="25" r="25" fill="#E8F2FD"/>
        <use fill="currentColor" fillRule="nonzero" xlinkHref="#empty_list_prefix__a"/>
      </g>
    </SvgIcon>
  );
};