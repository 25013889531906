import React, { MouseEvent } from "react";
import { Theme } from "../../../theme";
import { createStyles, withStyles } from "@material-ui/styles";
import { WithStyles } from "@material-ui/core";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import classNames from 'classnames';
import { TableColumn, ISort, SortDirection, ManageableTableColumn } from "../../../shared/interfaces";

const styles = (theme: Theme) => createStyles({
  root: {
    // ...theme.typography.body2,
    // textTransform: 'uppercase',
    // color: palette.dark200,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 0.58,
    textTransform: 'uppercase',
    color: '#919a9c',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    minHeight: 38,
    '&:hover': {
      '&:not($rootNoSort)': {
        '& $notVisible, & $removeSort': {
          opacity: 1,
          display: 'flex'
        },

        '& $notVisible $arrowUp': {
          display: 'flex'
        }

      }
    }
  },
  rootNoSort: {
    cursor: 'default',
    // minHeight: 38,
    '& $arrowRoot': {
      display: 'none'
    }
  },
  children: {
  },
  arrowRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  arrow: {
    display: 'none',
    fontSize: 18,
    transition: theme.transitions.create(
      ['transform', 'opacity'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      }),
    color: '#233439',
  },
  notVisible: {
    opacity: 0
  },
  active: {
    display: 'flex'
  },
  index: {
    fontSize: 10
  },
  removeSort: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    transition: 'opacity .1s ease',
    position: 'relative',
    '& $arrow': {
      color: '#657274',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      bottom: 0,
      left: '50%',
      right: 0,
      height: '40%',
      width: 1,
      transform: 'translate(-50%, -50%) rotateZ(-25deg)',
      transformOrigin: 'center',
      backgroundColor: '#657274',
    },
    '&:hover': {
      '& $arrow': {
        color: '#233439',
      },
      '&:after': {
        backgroundColor: '#233439',
      }
    }
  },
  arrowUp: {

  },
});

interface IProps extends WithStyles<typeof styles> {
  onClick: (column: ManageableTableColumn) => any;
  index?: number;
  activeSort?: ManageableTableColumn;
  children?: any;
  noSort?: boolean;
  onRemoveSort?: (column: ManageableTableColumn) => any;
  disabledSort?: boolean;
}

const TableHeader = (props: IProps) => {
  const { classes, activeSort, index } = props;





  const showIndex = (): boolean => {
    return index !== undefined && index > -1 && !!activeSort?.type
  }



  const onSort = (e: MouseEvent<HTMLDivElement>): void => {
    if(props.noSort || props.disabledSort || !activeSort) return;
    e.preventDefault();
    e.stopPropagation();

    props.onClick(activeSort)
  }


  const onRemoveSort = (e: MouseEvent<HTMLDivElement>): void => {
    if(!props.onRemoveSort || index === undefined || !activeSort) return;
    e.preventDefault();
    e.stopPropagation();

    props.onRemoveSort(activeSort)
  }



  return (
    <div onClick={onSort}
      className={classNames(
        classes.root,
        'table-header-print',
        {[classes.rootNoSort]: props.noSort}
      )}
    >

      <div className={classes.children}>{props.children}</div>


      <div className={classNames(
        classes.arrowRoot,
        {[classes.notVisible]: !activeSort || activeSort?.sortDirection === undefined}
      )}>

        <ArrowDropUp fontSize="small"
          viewBox="0 0 24 24"
          className={classNames(
            classes.arrow,
            classes.arrowUp,
            {[classes.active]: !!activeSort?.sortOrder && activeSort?.sortDirection === SortDirection.ASC}
          )}
        />


        
        <ArrowDropDown fontSize="small"
          viewBox="0 0 24 24"
          className={classNames(
            classes.arrow,
            {[classes.active]: !!activeSort?.sortOrder && activeSort?.sortDirection === SortDirection.DESC}
          )}
        />
        
      </div>


      {index !== undefined && showIndex() && !props.noSort && (
        <span className={classNames(classes.index, 'hide-on-print')}>{index}</span>
      )}



      {!!activeSort && index !== undefined && (
        <div className={classNames(classes.removeSort, 'remove-sort')} onClick={onRemoveSort}>

          <ArrowDropUp fontSize="small"
            viewBox="0 -9 24 24"
            className={classNames(classes.arrow, classes.active)}
          />
          <ArrowDropDown fontSize="small"
            viewBox="0 9 24 24"
            className={classNames(classes.arrow, classes.active)}
          />

        </div>
      )}

      
      


    </div>
  );
}

export default withStyles(styles)(TableHeader)