import { Menu } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { addProvider, preAssignProvider, updateProvider } from '../../../actions/projects/providers';
import { useAnchor } from '../../../shared/hooks';
import { ProviderLabel } from '../../../shared/models/Project';
import { Theme } from '../../../theme';
import CapitalProviderLabel from '../../CapitalProviderLabel';
import MenuOption from '../../MenuOption';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
    textDecoration: 'unset',
    color: '#233539',
    transition: 'all .1s ease',
    '&:hover': {
      padding: 10,
      backgroundColor: '#f7f9fa',
      color: '#4192ec',
      cursor: 'pointer',
      '&:not($hiddenForEdit)': {
        '& $label': {
          display: 'none'
        },
        '& $edit': {
          display: 'flex',
        }
      }
    },
  },
  readonly: {
    '&:hover': {
      padding: '10px 5px',
      backgroundColor: '#fff',
      '& $label': {
        display: 'none'
      },
      '& $edit': {
        display: 'none',
      }
    }
  },
  itemLabel: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 12,
      fontWeight: 'normal',
      lineHeight: 1.5,
      '&:first-child': {
        color: 'inherit',
        fontWeight: 500
      },
      '&:last-child': {
        color: '#657274'
      }
    }
  },
  moreIcon: {
    fontSize: '26px',
    color: '#657274',
    cursor: 'pointer',
    zIndex: 1,
  },
  label: {},
  edit: {
    display: 'none'
  },
  hiddenForEdit: {
    
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  key: any;
  id: number;
  person: any;
  state?: ProviderLabel;
  readonly?: boolean;
}

const MembershipItemProvider = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();
  const types = useSelector((state: any) => state.types);
  const projectId = useSelector((state: any) => state.projects.project.id);
  const dispatch = useDispatch();

  const changeMembership = (relationId: number, stateId: number): void => {
    dispatch(updateProvider(relationId, stateId));
    anchor.close();
  }

  const addMembership = (data: any): void => {
    anchor.close()

    projectId
      ? dispatch(addProvider(data))
      : dispatch(preAssignProvider(data))
  }

  const newProviderData = (stateId: number) => ({
    project: projectId,
    person: props.person.id,
    state: stateId
  })

  const newPreAssignedProviderData = (stateId: number) => ({
    person: props.person,
    state: stateId
  })

  const getOption = (item: any, index: number): object => {
    return {
      label: item.name,
      onClick: (e: any) => {
        if (!projectId) {
          return addMembership(newPreAssignedProviderData(item.id))
        } else {
          return props.id
            ? changeMembership(props.id, item.id)
            : addMembership(newProviderData(item.id))
        }
      }
    }
  }

  const options = types.provider_relation_types
    .filter((filter: any) => filter.id !== props.state)
    .map(getOption)

  const getTitle = (): string => {
    return `${props.person.first_name} ${props.person.last_name}`
  }

  const onClickPerson = (e: any) => {
    e.stopPropagation()
    if (e.ctrlKey || e.metaKey) {
      window.open(`/contacts/${props.person.id}`)
      return
    }
    props.history.push(`/contacts/${props.person.id}`)
  }

  return (
    <div
      key={props.key}
      className={classNames(
        classes.root,
        {
          [classes.readonly]: props.readonly,
          [classes.hiddenForEdit]: !!props.id
        }
      )}
    >
      <div className={classes.itemLabel} onClick={onClickPerson}>
        <span>{getTitle()}</span>
        <span>{props.person.company}</span>
      </div>
      <div className={classes.label}>
        {props.state
          ? <CapitalProviderLabel label={props.state} />
          : null
        }
      </div>

      <div className={classes.edit}>
        <MoreHorizIcon className={classes.moreIcon} onClick={(e) => { e.stopPropagation(); anchor.set(e) }} />
        <Menu
          id="long-menu"
          anchorEl={anchor.value}
          keepMounted
          open={!!anchor.value}
          onClose={anchor.close}
          PaperProps={{
            style: {
              boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
              borderRadius: 0
            },
          }}
        >
          {options.map((option: any, idx: number) => (
            <MenuOption key={option.label} onClick={option.onClick}>
              {option.label}
            </MenuOption>
          ))}
        </Menu>
      </div>
    </div>
  )
}



export default withRouter(withStyles(styles)(MembershipItemProvider))