import React, { useState, useEffect, createRef, Ref, RefObject } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import TextInput from '../TextInput';
import Select from '../Select';
import CloseIcon from '@material-ui/icons/Close';
import { EmailTypes } from '../../shared/types';
import { getIds } from '../../utils/utils';
import { useRefs } from '../../shared/hooks';



const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 19,
    borderBottom: '1px solid #d9e4e8',
    marginBottom: 18
  },
  labelWrapper: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 300,
    lineHeight: 1.45,
    color: '#657274',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
    width: 214,
    minWidth: 214,
    boxSizing: 'border-box'
  },
  label: {
    fontSize: 12,
    lineHeight: 1.67,
    marginBottom: 6,
    fontWeight: 400
  },
  description: {
    '& strong': {
      fontWeight: 400
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'row'
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 50,
    minWidth: 436,
    width: 436,
    boxSizing: 'border-box',
    '&:last-child': {
      minWidth: 386,
      maxWidth: 386
    }
  },
  contentColumnItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5,
    // position: 'relative',
    '&:last-child': {
      marginBottom: 0
    }
  },
  input: {
    width: 265,
    minWidth: 265,
    marginRight: 5
  },
  select: {
    width: 94,
    minWidth: 94,
    // position: 'absolute',
    // right: 22,
    // top: 0
  },
  closeIcon: {
    fontSize: 16,
    color: theme.palette.primary.main,
    marginLeft: 6,
    cursor: 'pointer'
  },
})

interface IProps extends WithStyles<typeof styles> {
  title: any;
  description?: any;
  items: any[];
  field: string;
  activeItems: any[];
  onChange: (newValue: any[]) => any;
  onRemove: (itemIdx: number, value: any, fieldName: string, oldValue: string) => any;
  errors: boolean;
  valuesList: any[];
  onErrorRefs?: (field: string, refs: Ref<HTMLDivElement>[]) => any;
}

const DuplicateItems = (props: IProps) => {
  const { classes, items, activeItems, valuesList, field, errors } = props;
  const refs = useRefs(items.length)





  const hasError = (value: any): boolean => {
    return errors && !activeItems.some((item: any) => item === value)
  }



  const onSelect = (item: any, newValue: any): void => {
    if(item?.id === undefined) return;
    

    const newActiveItems = [...activeItems].filter((item: any) => item !== newValue);
    let idx = item.id;
    if(!!newActiveItems[idx] && idx === valuesList[valuesList.length - 1].id) {
      idx = newActiveItems.length;
    }

    newActiveItems[idx] = newValue;

    props.onChange(newActiveItems)
  }


  
  const onRemove = (itemIdx: number, subItem: any): void => {
    const newValue = items[itemIdx].filter((item: any) => item !== subItem);
    props.onRemove(itemIdx, newValue, field, subItem)
  }



  const getSelectValue = (idx: number, subIdx: number): any => {
    const selectValueIdx = activeItems.findIndex((item: any) => item === items[idx][subIdx]);
    
    if(selectValueIdx >= valuesList.length) {
      return valuesList[valuesList.length - 1]
    }

    return selectValueIdx !== undefined && valuesList[selectValueIdx]
  }



  const getRef = (idx: number): Ref<HTMLDivElement>|undefined => {
    return items[idx].some(hasError) ? refs[idx] : undefined
  }





  




  useEffect(() => {
    const refsToPass = refs.filter((ref: any, idx: number) => !!ref?.current)
    props.onErrorRefs?.(field, refsToPass);
  }, [field, refs, errors, activeItems])


  
  
  if(!items.length) {
    return null
  }





  return (
    <div className={classes.root}>
      
      <div className={classes.labelWrapper}>
        <span className={classes.label}>{props.title}:</span>
        {props.description && (
          <span className={classes.description}>{props.description}</span>
        )}
      </div>



      <div className={classes.content}>
        {items.map((item: any, idx: number) => (

          <div key={idx} ref={getRef(idx)} className={classes.contentColumn}>
            {item.map((subItem: string, subIdx: number) => !!subItem && (
              
              <div key={`${idx}-${subIdx}`} className={classes.contentColumnItem}>
                <TextInput staticValue readonly
                  key={`${idx}-${subIdx}`}
                  value={subItem}
                  className={classes.input}
                />


                <Select withCheck isPopover
                  className={classes.select}
                  placeholder="Select..."
                  value={getSelectValue(idx, subIdx)}
                  onSelect={(i: any) => onSelect(i, subItem)}
                  error={hasError(subItem)}
                  valuesList={valuesList}
                />


                <CloseIcon className={classes.closeIcon}
                  onClick={(e: any) => onRemove(idx, subItem)}
                />

              </div>
                
            ))}
          </div>

        ))}

      </div>



    </div>
  )
}


export default withStyles(styles)(DuplicateItems)