import * as actions from '../../actions/contact-management/deleted-contacts';

export const initialState = {
  results: [] as any[],
  count: 0,
  next: null,
  previous: null,
  initiated: false,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {

    case actions.SET_DELETED_CONTACTS:
      return {
        ...action.data,
        initiated: true
      }
  
    
    case actions.APPEND_DELETED_CONTACTS:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }

    
    case actions.REMOVE_DELETED_CONTACTS:
      return {
        ...state,
        results: state.results.filter((item: any) => !action.ids.includes(item.id)),
        count: state.count - action.ids.length
      }


    case actions.SET_INITIATED_DELETED_CONTACTS:
      return {
        ...state,
        initiated: action.state
      }


    case actions.SET_LOADING:
      return {
        ...state,
        loading: action.state
      }

  
  
    default:
      return state
  }
}