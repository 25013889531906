import { prefixify, getIds } from "../../utils/utils";
import { ProfileType, SearchType, AutocompleteType } from "../types";
import { SelectItem } from '../interfaces';





/**
 * Interfaces
 */

export interface ISelectOptionBasic {
  id: number;
  name: string;
}

export enum SelectOptionType {
  Text,
  Number,
  Select,
  SelectMultiple,
  Rating,
  Boolean,
  Date,
  Autocomplete,
  AutocompleteRequest,
  AutocompleteArray,
  Array,
  SubcategoriesSelect,
  SelectMultipleFKey
}



export interface ISelectOptionField extends ISelectOptionBasic {
  type: SelectOptionType;
  value: any;
  componentProps?: any;
}

export interface ISelectOptionValue extends ISelectOptionBasic {
  value: any;
}

export interface ISelectOptionCondition extends ISelectOptionBasic {
  format: Function;
}

export interface ISearchRow {
  type?: any;
  field: any;
  condition: any;
  value: any;
  subRows?: any[];
  args?: any;
}

export interface ISearchSubRow {
  field?: any;
  condition: any;
  value: any;
  args?: any;
}





/**
 * Enums
 */

export enum SearchPeopleType {
  People_People = 1,
  People_People_CP,
  People_People_ClientClass,
  People_MemberOfProject,
  People_CPOfProject,
  People_AssignedToTask,
  People_CreatorOfTask,

  Projects_Projects,
  Projects_CPOfProject,
  Projects_OwnerOfProject,
  Projects_MemberOfProject,
  Projects_Tasks,

  Tasks_Tasks,
  Tasks_AsssignedTo,
  Tasks_CreatedBy,
  Tasks_Project,

  People_People_Subcategory
}

export enum SearchPerson {
  FirstName = 100,
  LastName,
  UserType,
  ContactType,
  Company,
  Rating,
  LegalStatus,
  Tags,
  City,
  Postcode,
  Country,
  ClientClass,
  MiddleName,
  UseClass,
  Locations,
  JobTitle,
  Department,
  WebPage,
  PreviousCompany,
  Suffix,
  Spouse,
  Birthday,
  Nickname,
  HomeCity,
  HomePostcode,
  HomeCountry,
  Title,
  Subcategory,
  DateModified
}


export enum SearchProject {
  Name = 200,
  ProjectType,
  ClientName,
  Status,
  ProjectOwner,
  AssetType,
  ClosingDate,
  CapitalDetailsType,
  CapitalDetailsSubType,
  Capital,
  Debt,
  Valuation,
  Tags,
  TermSheetFee,
  SuccessFee,
  CPStatus,
}

export enum SearchTask {
  Name = 300,
  AddedBy,
  AssignedTo,
  DueDate,
  Status,
  Column
}

export enum SearchCP {
  Type = 400,
  MaxLTV,
  MaxLTGDV,
  MaxLTC,
  MinReturn,
  MinTerm,
  MaxTerm,
  MinSize,
  MaxSize,
  InvestingIn,
  Sharia,
  Development,
  Land,
  PRS,
  TransactionType,
  Debt,
  Equity,
  MinRoe,
  InvestmentClasses
}



export enum SearchCondition {
  Is,
  IsNot,
  Contains,
  DoesNotContain,
  Yes,
  No,
  IsMoreThan,
  IsLessThan,
  IsEqualTo,
  IsNotEqualTo,
  ListIs,
  ListIsNot,

  SelectIs,
  SelectIsNot,
  SelectIsOneOf,
  SelectIsNotOneOf
}



export interface NestedRelation {
  relation: string;
  exclude?: [SearchPerson | SearchProject | SearchTask | SearchCP];
  include?: [SearchPerson | SearchProject | SearchTask | SearchCP];
}





const getNestedRelation = (rel?: NestedRelation, id?: SearchPerson | SearchProject | SearchTask | SearchCP): string => {
  if(!rel || !rel.relation || !id) return '';


  if(!!rel.exclude?.length) {
    return rel.exclude.includes(id) ? '' : rel.relation
  }

  if(!!rel.include?.length) {
    return rel.include.includes(id) ? rel.relation : ''
  }

  
  return rel.relation
}


/**
 * Options
 */

export const optionsPeople = (relation?: string): ISelectOptionField[] => ([
  { id: SearchPerson.FirstName,       name: 'First Name',               type: SelectOptionType.Text,                value: prefixify('first_name', relation) },
  { id: SearchPerson.MiddleName,      name: 'Middle Name',              type: SelectOptionType.Text,                value: prefixify('middle_name', relation) },
  { id: SearchPerson.LastName,        name: 'Last Name',                type: SelectOptionType.Text,                value: prefixify('last_name', relation) },
  { id: SearchPerson.Title,           name: 'Title',                    type: SelectOptionType.SelectMultipleFKey,  value: prefixify('title', relation) },
  { id: SearchPerson.UserType,        name: 'User Type',                type: SelectOptionType.SelectMultipleFKey,  value: prefixify('profile_type', relation) },
  { id: SearchPerson.ContactType,     name: 'Contact Category',         type: SelectOptionType.SelectMultipleFKey,  value: prefixify('categories', relation) },
  { id: SearchPerson.UseClass,        name: 'Use Class',                type: SelectOptionType.SelectMultipleFKey,  value: prefixify('use_classes', relation) },
  { id: SearchPerson.Locations,       name: 'Locations',                type: SelectOptionType.SelectMultipleFKey,  value: prefixify('locations', relation) },
  // { id: SearchPerson.Subcategory,     name: 'Subcategory',              type: SelectOptionType.Select,          value: prefixify('subcategories', relation) },
  { id: SearchPerson.JobTitle,        name: 'Job Title',                type: SelectOptionType.Text,                value: prefixify('job_title', relation) },
  { id: SearchPerson.Company,         name: 'Company',                  type: SelectOptionType.Autocomplete,        value: prefixify('company', relation), componentProps: { type: AutocompleteType.Companies } },
  { id: SearchPerson.Department,      name: 'Department',               type: SelectOptionType.Text,                value: prefixify('department', relation) },
  { id: SearchPerson.WebPage,         name: 'Web Page',                 type: SelectOptionType.Text,                value: prefixify('website', relation) },
  { id: SearchPerson.PreviousCompany, name: 'Previous Company',         type: SelectOptionType.Text,                value: prefixify('previous_company', relation) },
  { id: SearchPerson.Rating,          name: 'Rating',                   type: SelectOptionType.Rating,              value: prefixify('rating', relation)  },
  { id: SearchPerson.LegalStatus,     name: 'Legal Status',             type: SelectOptionType.SelectMultiple,      value: prefixify('legal_status', relation) },
  { id: SearchPerson.Suffix,          name: 'Suffix',                   type: SelectOptionType.Text,                value: prefixify('suffix', relation) },
  { id: SearchPerson.Spouse,          name: 'Spouse',                   type: SelectOptionType.Text,                value: prefixify('spouse', relation) },
  { id: SearchPerson.Birthday,        name: 'Birthday',                 type: SelectOptionType.Date,                value: prefixify('birthday', relation) },
  { id: SearchPerson.Nickname,        name: 'Nickname',                 type: SelectOptionType.Text,                value: prefixify('nickname', relation) },
  { id: SearchPerson.Tags,            name: 'Tags',                     type: SelectOptionType.AutocompleteArray,   value: prefixify('tags', relation), componentProps: { type: AutocompleteType.Tags } },
  { id: SearchPerson.City,            name: 'Business City',            type: SelectOptionType.Text,                value: prefixify('city', relation) },
  { id: SearchPerson.Postcode,        name: 'Business Postal Code',     type: SelectOptionType.Text,                value: prefixify('postcode', relation) }, // TODO startwith
  { id: SearchPerson.Country,         name: 'Business Country/Region',  type: SelectOptionType.Autocomplete,        value: prefixify('country', relation), componentProps: { type: AutocompleteType.Countries } },
  { id: SearchPerson.HomeCity,        name: 'Home City',                type: SelectOptionType.Text,                value: prefixify('home_city', relation) },
  { id: SearchPerson.HomePostcode,    name: 'Home Postal Code',         type: SelectOptionType.Text,                value: prefixify('home_postcode', relation) }, // TODO startwith
  { id: SearchPerson.HomeCountry,     name: 'Home Country/Region',      type: SelectOptionType.Autocomplete,        value: prefixify('home_country', relation), componentProps: { type: AutocompleteType.Countries } },
  { id: SearchPerson.DateModified,    name: 'Date Modified',            type: SelectOptionType.Date,                value: prefixify('updated_at', relation) },
])


export const optionsProjects = (relation?: string, nestedRelation?: NestedRelation): ISelectOptionField[] => ([
  { id: SearchProject.Name,                   name: 'Name',                 type: SelectOptionType.Text,                  value: prefixify('name', relation, getNestedRelation(nestedRelation, SearchProject.Name)) },
  { id: SearchProject.ProjectType,            name: 'Type',                 type: SelectOptionType.SelectMultipleFKey,    value: prefixify('project_types', relation, getNestedRelation(nestedRelation, SearchProject.ProjectType)) },
  { id: SearchProject.ClientName,             name: 'Client',               type: SelectOptionType.Text,                  value: prefixify('client', relation, getNestedRelation(nestedRelation, SearchProject.ClientName)) },
  { id: SearchProject.Status,                 name: 'Status',               type: SelectOptionType.SelectMultipleFKey,    value: prefixify('status', relation, getNestedRelation(nestedRelation, SearchProject.Status)) },
  // { id: SearchProject.ProjectOwner,           name: 'Project Owner',        type: SelectOptionType.AutocompleteRequest, value: prefixify('team', relation) }, // TODO user selection
  { id: SearchProject.AssetType,              name: 'Asset Type',           type: SelectOptionType.SelectMultipleFKey,    value: prefixify('asset_types', relation, getNestedRelation(nestedRelation, SearchProject.AssetType)) },
  { id: SearchProject.ClosingDate,            name: 'Closing Date',         type: SelectOptionType.Date,                  value: prefixify('closing_date__date', relation, getNestedRelation(nestedRelation, SearchProject.ClosingDate)) },
  { id: SearchProject.CapitalDetailsType,     name: 'Transaction Type',     type: SelectOptionType.SelectMultipleFKey,    value: prefixify('transaction_types', relation, getNestedRelation(nestedRelation, SearchProject.CapitalDetailsType)) },
  { id: SearchProject.CapitalDetailsSubType,  name: 'Transaction Sub Type', type: SelectOptionType.SelectMultipleFKey,    value: prefixify('transaction_sub_types', relation, getNestedRelation(nestedRelation, SearchProject.CapitalDetailsSubType)) },
  { id: SearchProject.Capital,                name: 'Capital',              type: SelectOptionType.Number,                value: prefixify('capital_required', relation, getNestedRelation(nestedRelation, SearchProject.Capital)), componentProps: { startAdornment: '£' } },
  { id: SearchProject.Valuation,              name: 'Valuation',            type: SelectOptionType.Number,                value: prefixify('recent_valuation', relation, getNestedRelation(nestedRelation, SearchProject.Valuation)), componentProps: { startAdornment: '£' } },
  { id: SearchProject.TermSheetFee,           name: 'Term Sheet Fee',       type: SelectOptionType.Number,                value: prefixify('term_sheet_fee', relation, getNestedRelation(nestedRelation, SearchProject.TermSheetFee)), componentProps: { startAdornment: '£' } },
  { id: SearchProject.SuccessFee,             name: 'Success Fee',          type: SelectOptionType.Number,                value: prefixify('fees', relation, getNestedRelation(nestedRelation, SearchProject.SuccessFee)), componentProps: { startAdornment: '£' } },
  { id: SearchProject.CPStatus,               name: 'CP Project Status',    type: SelectOptionType.SelectMultipleFKey,    value: prefixify('state', relation, getNestedRelation(nestedRelation, SearchProject.CPStatus)) },
  { id: SearchProject.Tags,                   name: 'Tags',                 type: SelectOptionType.AutocompleteArray,     value: prefixify('tags', relation, getNestedRelation(nestedRelation, SearchProject.Tags)), componentProps: { type: AutocompleteType.Tags } },
  { id: SearchPerson.DateModified,            name: 'Date Modified',        type: SelectOptionType.Date,                  value: prefixify('updated_at', relation) },
])


export const optionsTasks = (relation?: string): ISelectOptionField[] => ([
  { id: SearchTask.Name,              name: 'Title',              type: SelectOptionType.Text,                value: prefixify('title', relation) },
  { id: SearchTask.AddedBy,           name: 'Added By',           type: SelectOptionType.AutocompleteRequest, value: prefixify('created_by', relation), componentProps: { type: AutocompleteType.People } },
  { id: SearchTask.AssignedTo,        name: 'Assigned To',        type: SelectOptionType.AutocompleteRequest, value: prefixify('assignees', relation),  componentProps: { type: AutocompleteType.People } },
  { id: SearchTask.DueDate,           name: 'Due Date',           type: SelectOptionType.Date,                value: prefixify('due_date__date', relation) },
  { id: SearchTask.Status,            name: 'Status',             type: SelectOptionType.SelectMultipleFKey,  value: prefixify('state', relation) },
  { id: SearchTask.Column,            name: 'Column',             type: SelectOptionType.SelectMultipleFKey,  value: prefixify('column__name', relation) },
  { id: SearchPerson.DateModified,    name: 'Date Modified',      type: SelectOptionType.Date,                value: prefixify('updated_at', relation) },
])


export const optionsCP = (relation?: string): ISelectOptionField[] => ([
  { id: SearchCP.Type,              name: 'Capital Provider Description', type: SelectOptionType.SelectMultipleFKey, value: prefixify('cp_subcategory_types', relation) },
  { id: SearchCP.InvestmentClasses, name: 'Investment Class',             type: SelectOptionType.SelectMultipleFKey, value: prefixify('investment_classes', relation) },
  { id: SearchCP.MaxLTV,            name: 'Max LTV',                      type: SelectOptionType.Number,         value: prefixify('max_ltv', relation), componentProps: { endAdornment: '%' } },
  { id: SearchCP.MaxLTGDV,          name: 'Max LTGDV',                    type: SelectOptionType.Number,         value: prefixify('max_ltgdv', relation), componentProps: { endAdornment: '%' } },
  { id: SearchCP.MaxLTC,            name: 'Max LTC',                      type: SelectOptionType.Number,         value: prefixify('max_ltc', relation), componentProps: { endAdornment: '%' } },
  { id: SearchCP.MinReturn,         name: 'Min IRR',                      type: SelectOptionType.Number,         value: prefixify('min_irr', relation), componentProps: { endAdornment: '%' } },
  { id: SearchCP.MinRoe,            name: 'Min ROE',                      type: SelectOptionType.Number,         value: prefixify('min_roe', relation), componentProps: { endAdornment: 'x' } },
  { id: SearchCP.MinTerm,           name: 'Min Term',                     type: SelectOptionType.Number,         value: prefixify('min_term', relation), componentProps: { endAdornment: 'years' } },
  { id: SearchCP.MaxTerm,           name: 'Max Term',                     type: SelectOptionType.Number,         value: prefixify('max_term', relation), componentProps: { endAdornment: 'years' } },
  { id: SearchCP.MinSize,           name: 'Min Size',                     type: SelectOptionType.Number,         value: prefixify('min_size', relation), componentProps: { startAdornment: '£' } },
  { id: SearchCP.MaxSize,           name: 'Max Size',                     type: SelectOptionType.Number,         value: prefixify('max_size', relation), componentProps: { startAdornment: '£' } },
  { id: SearchCP.InvestingIn,       name: 'Investing In',                 type: SelectOptionType.SelectMultipleFKey, value: prefixify('investing_geographies', relation) },
  { id: SearchCP.Sharia,            name: 'Sharia',                       type: SelectOptionType.Boolean,        value: prefixify('is_sharia', relation) },
  { id: SearchCP.Development,       name: 'Development',                  type: SelectOptionType.Boolean,        value: prefixify('is_development', relation) },
  { id: SearchCP.Land,              name: 'Land',                         type: SelectOptionType.Boolean,        value: prefixify('is_land', relation) },
  { id: SearchCP.PRS,               name: 'PRS',                          type: SelectOptionType.Boolean,        value: prefixify('is_prs', relation) },
  // { id: SearchCP.TransactionType, name: 'Transaction Type', type: SelectOptionType.SelectMultiple,  value: prefixify('', relation) }, // TODO
  { id: SearchCP.Debt,              name: 'Debt',                         type: SelectOptionType.Boolean,        value: prefixify('is_debt', relation) },
  { id: SearchCP.Equity,            name: 'Equity',                       type: SelectOptionType.Boolean,        value: prefixify('is_equity', relation) },
])

export const optionClientClass = (relation?: string): ISelectOptionField => ({
  id: SearchPerson.ClientClass,   name: 'Client Class',     type: SelectOptionType.SelectMultiple,         value: prefixify('client_class', relation)
})


export const optionSubcategory = (relation?: string): ISelectOptionField => ({
  id: SearchPerson.Subcategory,   name: 'Subcategory',      type: SelectOptionType.SubcategoriesSelect,         value: prefixify('subcategories', relation)
})

/**
 * Types and consts
 */

export const SearchTypeName: Record<any, string> = {
  [SearchType.People]: 'contacts',
  [SearchType.Projects]: 'projects',
  [SearchType.Tasks]: 'tasks',
}


export const SearchTypeByPath = {
  'contacts': SearchType.People,
  'projects': SearchType.Projects,
  'tasks': SearchType.Tasks
}


export const SEARCH_OPTIONS_TYPES_PEOPLE = [
  { id: SearchPeopleType.People_People,          name: 'Contacts' },
  { id: SearchPeopleType.People_MemberOfProject, name: 'Member of Project' },
  { id: SearchPeopleType.People_CPOfProject,     name: 'CP of Project' },
  { id: SearchPeopleType.People_AssignedToTask,  name: 'Assigned to Task' },
  { id: SearchPeopleType.People_CreatorOfTask,   name: 'Creator of Task' }
] as ISelectOptionBasic[];

export const SEARCH_OPTIONS_TYPES_PROJECTS = [
  { id: SearchPeopleType.Projects_Projects, name: 'Projects' },
  { id: SearchPeopleType.Projects_CPOfProject, name: 'CP of Project' },
  { id: SearchPeopleType.Projects_MemberOfProject, name: 'Member of Project' },
  { id: SearchPeopleType.Projects_OwnerOfProject, name: 'Owner of Project' },
  { id: SearchPeopleType.Projects_Tasks, name: 'Tasks' },
] as ISelectOptionBasic[];

export const SEARCH_OPTIONS_TYPES_TASKS = [
  { id: SearchPeopleType.Tasks_Tasks, name: 'Tasks' },
  { id: SearchPeopleType.Tasks_AsssignedTo, name: 'Assigned To' },
  { id: SearchPeopleType.Tasks_CreatedBy, name: 'Created By' },
  { id: SearchPeopleType.Tasks_Project, name: 'Projects' },
] as ISelectOptionBasic[];


export const SEARCH_OPTIONS_FIELDS: Record<SearchPeopleType, any> = {
  [SearchPeopleType.People_People]:             optionsPeople(),
  [SearchPeopleType.People_People_CP]:          optionsCP('capital_provider__'),
  [SearchPeopleType.People_People_ClientClass]: optionClientClass(),
  [SearchPeopleType.People_MemberOfProject]:    optionsProjects('projects__', { relation: 'provider_relations__', include: [SearchProject.CPStatus] }),
  [SearchPeopleType.People_CPOfProject]:        optionsProjects('provider_relations__', { relation: 'project__', exclude: [SearchProject.CPStatus] }),
  [SearchPeopleType.People_People_Subcategory]: optionSubcategory(),
  [SearchPeopleType.People_AssignedToTask]:     optionsTasks('assigned_tasks__'),
  [SearchPeopleType.People_CreatorOfTask]:      optionsTasks('created_tasks__'),


  [SearchPeopleType.Projects_Projects]:         optionsProjects('', { relation: 'provider_relations__', include: [SearchProject.CPStatus] }),
  [SearchPeopleType.Projects_CPOfProject]:      optionsCP('capital_providers__'),
  [SearchPeopleType.Projects_MemberOfProject]:  optionsPeople('team__'),
  [SearchPeopleType.Projects_OwnerOfProject]:   optionsPeople('members__state=1&team__'),
  [SearchPeopleType.Projects_Tasks]:            optionsTasks('tasks__'),

  [SearchPeopleType.Tasks_Tasks]:               optionsTasks(),
  [SearchPeopleType.Tasks_AsssignedTo]:         optionsPeople('assignees__'),
  [SearchPeopleType.Tasks_CreatedBy]:           optionsPeople('created_by__'),
  [SearchPeopleType.Tasks_Project]:             optionsProjects('project__', { relation: 'provider_relations__', include: [SearchProject.CPStatus] }),

}



export const SEARCH_OPTION_FIELDS = {
  [SearchType.People]: SEARCH_OPTIONS_TYPES_PEOPLE,
  [SearchType.Projects]: SEARCH_OPTIONS_TYPES_PROJECTS,
  [SearchType.Tasks]: SEARCH_OPTIONS_TYPES_TASKS,
}



export const OPTIONS_PROFILE_TYPE: ISelectOptionValue[] = [
  {
    id: ProfileType.Admin,
    name: 'Admin',
    value: `${ProfileType.Admin}`
  },
  {
    id: ProfileType.User,
    name: 'User',
    value: `${ProfileType.User}`,
  },
  {
    id: ProfileType.Client,
    name: 'Client',
    value: `${ProfileType.Client}`
  },
  {
    id: ProfileType.Guest,
    name: 'Guest',
    value: `${ProfileType.Guest}`,
  },
]


export const initialSubRow = (fields?: any): any => ({
  field: null,
  condition: null,
  value: null,
  args: {},
  ...fields
})





/**
 * Conditions
 */

export const CONDITIONS_BASIC = [
  { id: SearchCondition.Is,             name: 'is',               format: (field: string, value: string) => `${field}=${value}` },
  { id: SearchCondition.IsNot,          name: 'is not',           format: (field: string, value: string) => `~${field}=${value}` }
] as ISelectOptionCondition[];

export const CONDITIONS_AUTOCOMPLETE = [
  { id: SearchCondition.Is,             name: 'is',               format: (field: string, value: string) => `${field}__iexact=${value}` },
  { id: SearchCondition.IsNot,          name: 'is not',           format: (field: string, value: string) => `~${field}__iexact=${value}` }
] as ISelectOptionCondition[];

export const CONDITIONS_AUTOCOMPLETE_ARRAY = [
  { id: SearchCondition.Contains,       name: 'is',               format: (field: string, value: string) => `${field}__icontains=${value}` },
  { id: SearchCondition.DoesNotContain, name: 'is not',           format: (field: string, value: string) => `~${field}__icontains=${value}` },
] as ISelectOptionCondition[];

export const CONDITIONS_STRING = [
  { id: SearchCondition.Is,             name: 'is',               format: (field: string, value: string) => `${field}__iexact=${value}` },
  { id: SearchCondition.IsNot,          name: 'is not',           format: (field: string, value: string) => `~${field}__iexact=${value}` },
  { id: SearchCondition.Contains,       name: 'contains',         format: (field: string, value: string) => `${field}__icontains=${value}` },
  { id: SearchCondition.DoesNotContain, name: 'does not contain', format: (field: string, value: string) => `~${field}__icontains=${value}` }
] as ISelectOptionCondition[];

export const CONDITIONS_ARRAY = [
  { id: SearchCondition.Contains,       name: 'contains',         format: (field: string, value: string) => `${field}__icontains=${value}` },
  { id: SearchCondition.DoesNotContain, name: 'does not contain', format: (field: string, value: string) => `~${field}__icontains=${value}` }
] as ISelectOptionCondition[];

export const CONDITIONS_BOOLEAN = [
  { id: SearchCondition.Yes,            name: 'Yes',              format: (field: string, value: string) => `${field}=1` },
  { id: SearchCondition.No,             name: 'No',               format: (field: string, value: string) => `${field}=0` }
] as ISelectOptionCondition[];


export const CONDITIONS_NUMBER = [
  { id: SearchCondition.IsMoreThan,     name: 'is more than',     format: (field: string, value: string) => `${field}__gt=${value}` },
  { id: SearchCondition.IsLessThan,     name: 'is less than',     format: (field: string, value: string) => `${field}__lt=${value}` },
  { id: SearchCondition.IsEqualTo,      name: 'is equal to',      format: (field: string, value: string) => `${field}=${value}` },
  { id: SearchCondition.IsNotEqualTo,   name: 'is not equal to',  format: (field: string, value: string) => `~${field}=${value}` }
]

export const CONDITIONS_DATE = [
  ...CONDITIONS_BASIC,
  { id: SearchCondition.IsMoreThan,     name: 'is after',         format: (field: string, value: string) => `${field}__gt=${value}` },
  { id: SearchCondition.IsLessThan,     name: 'is before',        format: (field: string, value: string) => `${field}__lt=${value}` },
] as ISelectOptionCondition[];

export const CONDITIONS_LIST = [
  { id: SearchCondition.ListIs,         name: 'is',               format: (field: string, value: string) => `${field}=${value}` },
  { id: SearchCondition.ListIsNot,      name: 'is not',           format: (field: string, value: string) => `~${field}=${value}` },
] as ISelectOptionCondition[];


export const CONDITIONS_LIST_REQUEST = [
  { id: SearchCondition.IsMoreThan,     name: 'is',               format: (field: string, value: string) => `${field}=${value}` },
  { id: SearchCondition.IsLessThan,     name: 'is not',           format: (field: string, value: string) => `~${field}=${value}` },
] as ISelectOptionCondition[];


export const CONDITIONS_SELECT_MULTIPLE = [
  { id: SearchCondition.SelectIs,         name: 'is',             format: (field: string, value: SelectItem[]|string) => `${field}__contained_by=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
  { id: SearchCondition.SelectIsNot,      name: 'is not',         format: (field: string, value: SelectItem[]|string) => `~${field}__contained_by=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
  { id: SearchCondition.SelectIsOneOf,    name: 'is one of',      format: (field: string, value: SelectItem[]|string) => `${field}__overlaps=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
  { id: SearchCondition.SelectIsNotOneOf, name: 'is not one of',  format: (field: string, value: SelectItem[]|string) => `~${field}__overlaps=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
] as ISelectOptionCondition[];


export const CONDITION_SUBCATEGORIES_SELECT = [
  { id: SearchCondition.SelectIs,         name: 'is',             format: (field: string, value: SelectItem[]) => `${value?.[0]?.category ?? ''}(AND:${getIds(value ?? []).join('|')})` },
  { id: SearchCondition.SelectIsNot,      name: 'is not',         format: (field: string, value: SelectItem[]) => `${value?.[0]?.category ?? ''}(NOTAND:${getIds(value ?? []).join('|')})` },
  { id: SearchCondition.SelectIsOneOf,    name: 'is one of',      format: (field: string, value: SelectItem[]) => `${value?.[0]?.category ?? ''}(OR:${getIds(value ?? []).join('|')})` },
  { id: SearchCondition.SelectIsNotOneOf, name: 'is not one of',  format: (field: string, value: SelectItem[]) => `${value?.[0]?.category ?? ''}(NOTOR:${getIds(value ?? []).join('|')})` },
] as ISelectOptionCondition[];


export const CONDITIONS_SELECT_MULTIPLE_FKEY = [
  { id: SearchCondition.SelectIs,         name: 'is',             format: (field: string, value: SelectItem[]|string) => `${field}__andfkey=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
  { id: SearchCondition.SelectIsNot,      name: 'is not',         format: (field: string, value: SelectItem[]|string) => `~${field}__andfkey=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
  { id: SearchCondition.SelectIsOneOf,    name: 'is one of',      format: (field: string, value: SelectItem[]|string) => `${field}__orfkey=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
  { id: SearchCondition.SelectIsNotOneOf, name: 'is not one of',  format: (field: string, value: SelectItem[]|string) => `~${field}__orfkey=${Array.isArray(value) ? value.map((item: SelectItem) => (item.id ?? item.name)).join(',') : value}` },
] as ISelectOptionCondition[];




export const getConditions = (type: SelectOptionType): ISelectOptionCondition[] => {
  switch (type) {
    case SelectOptionType.Select:
      return CONDITIONS_BASIC

    case SelectOptionType.Autocomplete:
      return CONDITIONS_AUTOCOMPLETE

    case SelectOptionType.AutocompleteRequest:
      return CONDITIONS_LIST_REQUEST

    case SelectOptionType.AutocompleteArray:
      return CONDITIONS_AUTOCOMPLETE_ARRAY

    case SelectOptionType.SelectMultiple:
      return CONDITIONS_SELECT_MULTIPLE

    case SelectOptionType.SubcategoriesSelect:
      return CONDITION_SUBCATEGORIES_SELECT

    case SelectOptionType.Number:
    case SelectOptionType.Rating:
      return CONDITIONS_NUMBER

    case SelectOptionType.Boolean:
      return CONDITIONS_BOOLEAN

    case SelectOptionType.Date:
      return CONDITIONS_DATE

    case SelectOptionType.Array:
      return CONDITIONS_ARRAY

    case SelectOptionType.SelectMultipleFKey:
      return CONDITIONS_SELECT_MULTIPLE_FKEY

    default:
      return CONDITIONS_STRING
  }
}


export const getValues = (fieldId: SearchPerson | SearchProject | SearchTask | SearchCP, types: any, extra?: any): any[] => {
  switch (fieldId) {
    case SearchPerson.UserType:
      return OPTIONS_PROFILE_TYPE
    case SearchPerson.ClientClass:
      return types.client_classes
    case SearchPerson.ContactType:
      return types.categories
    case SearchPerson.LegalStatus:
      return types.legal_status
    case SearchPerson.Title:
      return types.title
    case SearchPerson.UseClass:
      return types.use_class_types
    case SearchPerson.Locations:
      return types.location_types
    case SearchPerson.Subcategory:
      if (extra?.categoryId) {
        return types.subcategories?.filter((item: any) => item.category === extra?.categoryId)
      }
      return types.subcategories


    case SearchProject.ProjectType:
      return types.project_types
    case SearchProject.Status:
      return types.project_status_types
    case SearchProject.AssetType:
      return types.asset_types
    case SearchProject.CapitalDetailsType:
      return types.transaction_types
    case SearchProject.CapitalDetailsSubType:
      return types.transaction_sub_types
    case SearchProject.CPStatus:
      return types.provider_relation_types


    case SearchTask.Status:
      return types.task_states
    case SearchTask.Column:
      return types.default_columns


    case SearchCP.Type:
      return types.cp_subcategories
    case SearchCP.InvestingIn:
      return types.investing_geography_types
    case SearchCP.InvestmentClasses:
      return types.investment_class_types


    default:
      return []
  }
}

export enum SearchElement {
  TYPE = 1,
  FIELD,
  CONDITION,
  VALUE
}