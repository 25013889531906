import typesService from '../services/types';

/**
 * Actions
 */

export const SET_TYPES = 'SET_TYPES';



/**
 * Action creators
 */

export function setAllTypes(types: any): object {
  return {type: SET_TYPES, types}
}



/**
 * Async action creators
 */

export const getTypes = () => {
  return (dispatch) => {
    return typesService.getTypes()
      .then((res: any) => {
        // const types = sortTypes(res);
        dispatch(setAllTypes(res))
      })
      .catch(err => {
        console.log('An error occured while getting the types.')
      })
  }
}



/**
 * Helpers
 */


// No longer used.
const sortTypes = (types: any): any => {
  const sortedTypes = Object.entries(types).map(([key, value]: any) => {
    if(!!value?.length && value?.[0]?.order !== undefined) {
        return [key, value.sort((a, b) => a.order - b.order)];
    }
    return [key, value]
  })

  return Object.fromEntries(sortedTypes) || types
}