import React from 'react';
import { SvgIcon } from "@material-ui/core";
import { ProfileType } from '../../shared/types';

export const PersonIcon = (props: any) => {
  const { type, ...svgProps } = props;

  const getColor = () : string => {    
    switch(props.type) {
      case ProfileType.Admin:
        return "#4159d9"
      case ProfileType.User:
        return "#4192ec"
      case ProfileType.Client:
        return "#18cbc4"
      case ProfileType.Guest:
        return "#40cdf4";
      default:
        return "#40cdf4"
    }
  }

  return (
    <SvgIcon {...svgProps} width="14" height="14" viewBox="0 0 14 14">
      <g fill="none" fillRule="evenodd">
        <path d="M-5-6h24v24H-5z"/>
        <path fill={props.type ? getColor() : 'currentColor'} fillRule="nonzero" d="M7 6c1.66 0 2.99-1.34 2.99-3S8.66 0 7 0C5.34 0 4 1.34 4 3s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V14h14v-2.5C14 9.17 9.33 8 7 8z"/>
      </g>
    </SvgIcon>
  );
}