import { Paper } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from '../../components/Button';
import EmptyNonTableList from '../../components/EmptyNonTableList';
import { Theme } from '../../theme';



const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  paper: {
    width: 'calc(100% - 232px)',
    marginLeft: 232,
    position: 'relative',
    minHeight: 547,
    borderRadius: 0,
    padding: 0,
    margin: '0 0 55px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  header: {
    padding: '17px 19px 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4
  },
  headerDescription: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Montserrat',
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 1.46,
    color: '#233539',
    marginRight: 20
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  headerAction: {
    marginRight: 12,
    minWidth: 86,
    fontWeight: 400,
    '&:last-child': {
      marginRight: 0
    }
  },
  list: {
    boxShadow: 'none',
    margin: 0
  }
})


interface IEmptyData {
  icon: any;
  title: string;
  description: string;
}


interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  className?: string;
  description?: any;
  actions?: any[];
  isEmpty?: boolean;
  itemsEmpty: IEmptyData;
  children?: any;
}

const ManageableTableCard = (props: IProps) => {
  const { classes, itemsEmpty } = props;



  const isActionVisible = (isVisible?: boolean): boolean => {
    return isVisible === undefined || isVisible
  }




  if(props.isEmpty) {
    return (
      <Paper className={classNames(classes.paper, props.className)}>
        <EmptyNonTableList icon={itemsEmpty.icon} title={itemsEmpty.title} description={itemsEmpty.description} />
      </Paper>
    )
  }




  return (
    <Paper className={classNames(classes.paper, props.className)}>

      <div className={classes.header}>
        <span className={classes.headerDescription}>
          {props.description}
        </span>


        <div className={classes.headerActions}>
          {props.actions?.map((action: any, idx: number) => isActionVisible(action.isVisible) ? (
            <Button primary
              key={idx}
              className={classes.headerAction}
              onClick={action.onClick}
              disabled={action.disabled}
              popover={action.popover}
            >
              {action.label}
            </Button>
          ) : null)}
        </div>
      </div>


      {props.children}

  
    </Paper>
  )
}


export default withStyles(styles)(
  withRouter(ManageableTableCard)
)