import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { Fragment, useCallback } from 'react';
import { initialSubRow, ISearchRow, ISelectOptionBasic } from '../../shared/models/Search';
import { Theme } from '../../theme';
import { isCP } from '../../utils/utils';
import Button from '../Button';
import SearchRow from './SearchRow';
import { SearchType } from '../../shared/types';

const styles = (theme: Theme) => createStyles({
  addRow: {
    display: 'flex',
    // marginTop: 10
  },
  addRowButton: {
    width: 79,
    height: 40,
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: 0
  },
  subRowAddButton: {
    '& + $addRow': {
      marginTop: 10
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  rows: any[];
  type: SearchType;
  onAdd?: () => any;
  onDelete: (index: number) => any;
  onChange: (field: any, index: number) => any;
  children?: any;
  showAddButton?: boolean;
  parentRow?: any;
  parentIdx?: number;
  className?: any;
  prefilledRows?: any[];
}

const SearchRows = (props: IProps) => {
  const { classes, rows } = props;




  const hasCP = (row: ISearchRow): boolean => {    
    return Array.isArray(row.value) && row.value.some(isCP)
  }



  const onSubrowChange = useCallback((field: any, subRowIdx: number, row: any, rowIndex: number) => {
    const subRows = [...row.subRows];
    subRows[subRowIdx] = {
      ...subRows[subRowIdx],
      ...field
    }

    props.onChange({subRows}, rowIndex)
  }, [rows])




  const renderSubRows = (row: ISearchRow, rowIndex: number): any => {
    if(!row.subRows || !row.subRows.length) return null;



    
    return (
      <SearchRows
        type={props.type}
        classes={classes}
        className={classes.subRowAddButton}
        rows={row.subRows}
        parentRow={row}
        parentIdx={rowIndex}
        showAddButton={hasCP(row)}
        onAdd={() => {
          props.onChange({subRows: [...row.subRows, initialSubRow()]}, rowIndex)
        }}
        onDelete={(idx: number) => {
          let subRows = [...row.subRows]
            .filter((row: any, subRowIdx: number) => subRowIdx !== idx);

          if(!subRows.length) {
            subRows = [initialSubRow()]
          }

          props.onChange({subRows}, rowIndex)
        }}
        onChange={(field: any, subRowIdx: number) => onSubrowChange(field, subRowIdx, row, rowIndex)}
      />
    )
  }





  return (
    <Fragment>
      <div className={props.className}>
        {rows.map((row: ISearchRow, index: number) => (
          <SearchRow
            key={index}
            idx={props.parentIdx ?? index}
            subRowIdx={props.parentRow ? index : undefined}
            type={props.type}
            onDelete={() => props.onDelete(index)}
          >
            {renderSubRows(row, index)}
          </SearchRow>
        ))}
      </div>
      {props.showAddButton && (
        <div className={classes.addRow}>
          <Button onClick={props.onAdd} className={classes.addRowButton}>
            Add Row
          </Button>
        </div>
      )}
    </Fragment>
  )
}


export default withStyles(styles)(SearchRows)