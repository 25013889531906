import emailService from '../services/email';
import { setSuccess } from './common/notifications';
import { EmailFilters } from '../shared/interfaces';


/**
 * Actions
 */

export const SET_EMAILS         = 'SET_EMAILS';
export const CLEAR_EMAILS       = 'CLEAR_EMAILS';
export const APPEND_EMAILS      = 'APPEND_EMAILS';
export const SET_CURRENT_EMAIL  = 'SET_CURRENT_EMAIL';
export const SET_CURRENT_THREAD = 'SET_CURRENT_THREAD';
export const SET_LOADING        = 'EMAILS_SET_LOADING';
export const SET_FILTERS        = 'EMAILS_SET_FILTERS';




/**
 * Action creators
 */

export function setAllEmails(emails: any): object {
  return {type: SET_EMAILS, emails}
}

export function clearEmails(): object {
  return {type: CLEAR_EMAILS}
}

export function appendEmails(emails: any): object {
  return {type: APPEND_EMAILS, ...emails}
}

export function setCurrentEmail(data: any): object {
  return {type: SET_CURRENT_EMAIL, ...data}
}

export function setCurrentThread(data: any): object {
  return {type: SET_CURRENT_THREAD, ...data}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}

export function setFilters(filters: any): object {
  return {type: SET_FILTERS, filters}
}


/**
 * 
 * Async action creators
 */

const formatEmailQueryString = (filters?: EmailFilters): string => {
  if(!filters) return '';

  return Object.entries(filters)
    .filter(([field, value]: any) => !!value)
    .map(([field, value]: any) => `${field}=${value}`)
    .join('&')
}



export const getEmails = (id: number, filters?: EmailFilters) => {
  return (dispatch) => {
    const query: string = formatEmailQueryString(filters);
    dispatch(setLoading(true));

    return emailService.getEmails(id, query)
      .then((res: any) => {
        dispatch(setAllEmails(res));
        return Promise.resolve(res)
      })
      .catch((err: any) => {
        console.log('An error occured while getting emails.');
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const fetchMoreEmails = () => {
  return (dispatch, getState) => {
    const nextURL = getState().emails.next;

    if(!nextURL) Promise.reject(null);

    return emailService.getEmailsByURL(nextURL)
      .then((res: any) => {
        dispatch(appendEmails(res));
        return Promise.resolve(res)
      })
      .catch((err: any) => {
        console.log(err)
        console.log('An error occured while getting more emails.');
        return Promise.reject(false)
      })
  }
}



export const getEmail = (id: number) => {
  return (dispatch) => {

    return emailService.getEmail(id)
      .then((res: any) => {
        dispatch(setCurrentEmail(res))
        return Promise.resolve(res)
      })
      .catch((err: any) => {
        console.log(err)
        console.log('An error occured while getting an email.');
        return Promise.reject(err)
      })
  }
}



export const getThread = (threadId: string, personId: number) => {
  return (dispatch) => {

    return emailService.getThread(threadId, personId)
      .then((res: any) => {
        dispatch(setCurrentThread(res))
        return Promise.resolve(res)
      })
      .catch((err: any) => {
        console.log(err)
        console.log('An error occured while getting an email.');
        return Promise.reject(err)
      })
  }
}



export const forwardEmail = (id: number, body: any) => {
  return (dispatch) => {
    dispatch(setLoading(true));


    return emailService.forwardEmail(id, body)
      .then((res: any) => {
        dispatch(setSuccess({
          message: "An email forwarded successfully"
        }))
        return Promise.resolve(res)
      })
      .catch((err: any) => {
        console.log(err)
        console.log('An error occured while forwarding an email.');
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const forwardThread = (body: any) => {
  return (dispatch) => {
    dispatch(setLoading(true));


    return emailService.forwardThread(body)
      .then((res: any) => {
        dispatch(setSuccess({
          message: "An email thread forwarded successfully"
        }))
        return Promise.resolve(res)
      })
      .catch((err: any) => {
        console.log(err)
        console.log('An error occured while forwarding a thread.');
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch(setLoading(false));
        dispatch(setSuccess({
          message: "An email thread forwarded successfully"
        }))
      })
  }
}