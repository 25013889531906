import React, { useState, useEffect, MouseEvent, useRef } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { groupBy, goTo } from '../../utils/utils';
import { highlight } from '../../utils/component-utils';
import { CircularProgress } from '@material-ui/core';
import EmptyListBody from '../list/EmptyListBody';

const styles = (theme: Theme) => createStyles({
  '@keyframes showIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    }
  },
  root: {
    animation: '.3s 1 $showIn ease-in',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    lineHeight: 1.5,
    margin: '0 0 11px',
    fontWeight: 'normal',
    color: '#233539'
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#657274',
    fontWeight: 'normal',
    fontSize: 11,
    lineHeight: 2.18,
    marginBottom: 12
  },
  captionSearch: {
    fontWeight: 600,
  },
  captionSearchText: {
    color: theme.palette.primary.main
  },
  captionCount: {
    '& strong': {
      fontWeight: 600
    }
  },
  list: {
    display: 'flex',
    flexDirection: 'column'
  },
  listGroup: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '&:last-child': {
      borderBottom: '1px solid #d9e4e8',
    }
  },
  listGroupTitle: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '1.67',
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #d9e4e8',
    padding: '10px 20px',
    boxSizing: 'border-box',
    borderLeft: 0,
    position: 'relative',
    margin: 0,
    '&:before': {
      content: '""',
      backgroundColor: theme.palette.primary.main,
      width: 3,
      position: 'absolute',
      top: -1,
      bottom: -1,
      left: 0,
      zIndex: 1
    }
  },
  listGroupTitleText: {

  },
  listGroupTitleCount: {
    fontSize: 10,
    lineHeight: 2,
    color: '#90a0a7',
    marginRight: 20,
    textAlign: 'center',
    width: 10
  },
  listGroupValues: {
    display: 'flex',
    flexDirection: 'column',
  },
  listGroupValue: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 50,
    border: '1px solid #d9e4e8',
    borderTop: 0,
    padding: '0 50px',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 1.67,
    '&:first-child': {
      borderTop: 0
    },
    '&:last-child': {
      borderBottom: 0
    }
  },
  listGroupValueCount: {
    fontSize: 10,
    color: '#90a0a7',
    marginRight: 10,
    textAlign: 'center',
    width: 10
  },
  listGroupValueText: {
    padding: '0 10px',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      height: 11,
      width: 1,
      backgroundColor: '#d8d8d8',
    },
    '&:first-child': {
      paddingLeft: 0
    },
    '&:last-child': {
      paddingRight: 0,
      '&:after': {
        content: 'unset'
      }
    }
  },
  listGroupValueTask: {
    color: '#4192ec',
    cursor: 'pointer',
  },
  listGroupValueData: {
    display: 'flex',
    alignItems: 'center'
  },
  loadingWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
  },
  loadingSpinner: {

  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  onNext: () => any;
}

const CalendarSearch = (props: IProps) => {
  const { classes } = props;
  const search = useSelector((state: any) => state.search.search);
  const tasks = useSelector((state: any) => state.board.tasks);
  const [groupedTasks, setGroupedTasks] = useState<any>({});
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);





  const onTaskClick = (e: MouseEvent<HTMLSpanElement>, id: number): void => {
    return goTo(e, `/task/${id}`, props.history)
  }




  const groupTasks = (arr: any): any => {
    const newTasks = arr
      .filter((task: any) => !!task.due_date)
      .sort((a: any, b: any) => moment(a.due_date).unix() - moment(b.due_date).unix())
      .map((task: any) => ({
        ...task,
        group: moment(task.due_date).startOf('month').format('MMMM YYYY')
      }))

    const undatedTasks = arr
      .filter((task: any) => !task.due_date)
      .map((task: any) => ({ ...task, group: 'undated' }))

    return groupBy([...newTasks, ...undatedTasks], 'group')
  }



  const onScroll = (e: any): void => {
    if (!containerRef?.current) return;

    const windowScroll = window.scrollY + window.innerHeight;
    const containerEnd = containerRef.current.scrollHeight + containerRef.current.offsetTop

    if (containerEnd - 500 <= windowScroll && !loadingMore && tasks.next) {
      setLoadingMore(true);
    }
  }




  useEffect(() => {
    if(!loadingMore) return;

    props.onNext().then((res: any) => setLoadingMore(false))
  }, [loadingMore])

  

  useEffect(() => {
    const newGroupedTasks = groupTasks(tasks.results)
    setGroupedTasks(newGroupedTasks);
  }, [tasks])



  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [tasks])





  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Search Results</h1>

      <div className={classes.caption}>
        <div className={classes.captionSearch}>
          <span>Displaying: </span>
          <span className={classes.captionSearchText}>{search}</span>
        </div>
        <div className={classes.captionCount}>
          Showing <strong>{tasks.results.length}</strong> of {tasks.count}
        </div>
      </div>


      <div className={classes.list} ref={containerRef}>
        {Object.entries(groupedTasks).map(([dateTitle, values]: any, idx: number) => (
          <div key={idx} className={classes.listGroup}>
            <h2 className={classes.listGroupTitle}>
              <span className={classes.listGroupTitleCount}>{idx + 1}</span>
              <span className={classes.listGroupTitleText}>{dateTitle}</span>
            </h2>
            <div className={classes.listGroupValues}>
              {values.map((item: any, subIdx: number) => (
                <div key={item.id} className={classes.listGroupValue}>
                  
                  <span className={classes.listGroupValueCount}>{subIdx + 1}</span>
                  <div className={classes.listGroupValueData}>
                    <span className={classes.listGroupValueText}>
                      {moment(item.due_date).format('ddd DD MMMM')}
                    </span>
                    <span className={classes.listGroupValueText}>
                      {item.project_details?.name ?? ''}
                    </span>
                    <span className={classNames(classes.listGroupValueText, classes.listGroupValueTask)}
                      onClick={(e: any) => onTaskClick(e, item.id)}
                    >
                      {highlight(item.title, search)}
                    </span>
                  </div>
                
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      


      
      <EmptyListBody hidden={!!tasks.results?.length} />


      
      {loadingMore && (
        <div className={classes.loadingWrapper}>
          <CircularProgress className={classes.loadingSpinner} />
        </div>
      )}
      


    </div>
  )
}


export default withStyles(styles)(
  withRouter(CalendarSearch)
)