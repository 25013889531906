import React, { memo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import TableRowCell from './TableRowCell';

const styles = (theme: Theme) => createStyles({
  root: {
    fontSize: 10,
    lineHeight: 2,
    color: '#90a0a7',
    width: 14
  },
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  hidden?: boolean;
}

const TableRowIndex = ({ classes, idx, hidden }: IProps) => (
  <TableRowCell className={classes.root}>{hidden ? '' : idx + 1}</TableRowCell>
)


export default withStyles(styles)(
  memo(TableRowIndex)
)