import * as actions from '../actions/tasks';

export const initialState = {
  results: [] as any[],
  task: {} as any,
  updatedTask: {} as any,
  count: 0,
  next: null,
  previous: null,
  errors: {} as any,
  taskView: {
    editing: [],
    newComment: false,
    closeDialogOpened: false
  }
};

export default function (state = initialState, action) {
  switch (action.type) {


    case actions.SET_TASKS:
      return {
        ...state,
        results: action.results,
        count: action.count,
        next: action.next,
        previous: action.previous
      }


    case actions.APPEND_TASKS:
      return {
        ...state,
        results: [...state.results, ...action.results],
        next: action.next,
        previous: action.previous
      }


    case actions.ADD_TASKS:
      let newTasks = state.results.filter(
        (task: any) => !action.tasks.find(newTask => task.id === newTask.id)
      ).concat(action.tasks)

      return {
        ...state,
        results: newTasks,
        count: newTasks.length
      }


    case actions.SET_CLOSING_PROCESS_TASKS:
      return {
        ...state,
        closingProcessTasks: action.tasks,
        closingProcessTasksCount: action.tasks.length
      }


    case actions.SET_TASK:
      return {
        ...state,
        task: action.task,
        updatedTask: {
          id: action.task?.id
        }
      } 


    case actions.SET_COLUMN:
      return {
        ...state,
        task: {
          ...state.task,
          column: action.column
        },
        updatedTask: {
          ...state.updatedTask,
          column: action.column
        }
      }


    case actions.UPDATE_TASK:
      return {
        ...state,
        results: [...state.results].map((task: any) => {
          return task.id === action.task.id
            ? action.task
            : task
        })
      }


    case actions.UPDATE_TASK_STATE:
      return {
        ...state,
        results: [...state.results].map((task: any) => {
          if (task.id === action.taskId) {
            task.state = action.state;
            return task
          }

          return task
        })
      }


    case actions.CHANGE_TASK_FIELD:
      return {
        ...state,
        updatedTask: {
          ...state.updatedTask,
          ...action.field
        }
      }


    case actions.CLEAR_TASK:
      return {
        ...state,
        task: {},
        updatedTask: {}
      }


    case actions.CLEAR_TASKS:
      return {
        ...state,
        results: [] as any[]
      }


    case actions.DELETE_TASK:
      return {
        ...state,
        results: [...state.results].filter((task: any) => task.id !== action.taskId),
        count: --state.count
      }

  
    case actions.APPEND_TASK:
      return {
        ...state,
        results: [...state.results, action.task],
        count: ++state.count
      }


    case actions.SET_TASK_VIEW_FIELD:
      return {
        ...state,
        taskView: {
          ...state.taskView,
          [action.name]: action.value
        }
      }


    case actions.SET_ERRORS:
      return {
        ...state,
        errors: action.errors
      }

    
    case actions.REMOVE_FILE:
      return {
        ...state,
        updatedTask: {
          ...state.updatedTask,
          files: state.updatedTask.files.filter((_, idx: number) => idx !== action.idx)
        }
      }


    default:
      return state
  }
}