import React, { useState, Fragment, useMemo } from 'react';
import { Theme } from '../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import ProgressLine from '../../ProgressLine';
import classNames from 'classnames';
import { getProgress, formatNumber, formatDate } from '../../../utils/utils';
import moment from 'moment';
import { ClickAwayListener } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ProviderColorHex } from '../../../shared/types';
import { useWindowSize } from '../../../shared/hooks';
import { useTrackerSummary } from '../tracker/hooks/tracker-hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '20px 18px',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)'
  },
  totalProgress: {
    width: '23%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 26,
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 11,
      fontWeight: 400,
      lineHeight: 2.18,
      letterSpacing: 0.53,
      color: '#657274',
      textTransform: 'uppercase',
      marginBottom: 3
    }
  },
  progressLine: {
    display: 'flex',
    '& > span': {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontWeight: 'normal',
      lineHeight: 1,
      color: '#233539',
      marginRight: 11
    }
  },
  progressColumns: {
    width: '77%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  progressColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '0 30px',
    borderRight: '1px solid #d9e4e8',
    position: 'relative',
    '&:first-child': {
      borderLeft: '1px solid #d9e4e8',
    },
    '&:last-child': {
      borderRight: 0
    },
    '& > span': {
      fontFamily: 'Montserrat',
      '&:first-child': {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1,
        letterSpacing: 0.53,
        color: '#657274',
        marginBottom: 10,
        textTransform: 'uppercase',
      },
      '&:last-child': {
        fontSize: 18,
        fontWeight: 'normal',
        lineHeight: 1.11,
        color: '#233539',
      }
    }
  },
  cpList: {
    opacity: 0,
    transform: 'scaleY(0)',
    transformOrigin: 'top center',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    padding: '5px 10px 5px 5px',
    position: 'absolute',
    top: 60,
    left: 0,
    borderRadius: 4,
    boxShadow: '0 0 6px 0 rgba(189, 194, 196, 0.5)',
    transition: 'all .1s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1
  },
  cpListOpened: {
    opacity: 1,
    transform: 'scaleY(1)'
  },
  cpItem: {
    display: 'flex',
    alignItems: 'center'
  },
  cpCounter: {
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 2,
    display: 'flex',
    width: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
  cpLabel: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 400,
    lineHeight: 2,
    color: '#657274',
  },
  columnCP: {
    cursor: 'pointer'
  },
  mobileRoot: {
    flexDirection: 'column',
    padding: '15px 15px 23px',
    '& $totalProgress': {
      width: '100%',
      padding: '0 0 13px',
      borderBottom: '1px solid #d9e4e8',
      marginBottom: 13,
    },
    '& $progressColumns': {
      width: '100%',
      flexWrap: 'wrap'
    },
    '& $progressColumn': {
      flex: '1 1 50%',
      padding: 0,
      boxSizing: 'border-box',
      borderRight: 0,
      marginBottom: 21,
      '& > span:first-child': {
        lineHeight: 2.18,
        marginBottom: 0
      },
      '& > span:last-child': {
        lineHeight: 1.25,
        fontSize: 16,
        fontWeight: 400
      },
      '&:first-child': {
        borderLeft: 0
      },
      '&:nth-child(even)': {
        borderLeft: '1px solid #d9e4e8',
        paddingLeft: 22
      },
      '&:last-child, &:nth-last-child(2):nth-child(odd)': {
        marginBottom: 0
      }
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  project: any;
  className?: any;
  noCP?: boolean;
}

const BoardProgress = (props: IProps) => {
  const { classes, project, project: { currency } } = props;
  const windowSize = useWindowSize();
  const companiesSummary = useTrackerSummary();
  const types = useSelector((state: any) => state.types.provider_relation_types);
  const [openedCP, setOpenedCP] = useState<boolean>(false);
  const progress = (project.progress && getProgress(project)) || 0;
  const companyCount = useMemo(() => (Object.values(companiesSummary) ?? []).reduce((a: any, b: any) => a + b, 0) ?? 0, [companiesSummary]) as number;
  const cpData = useMemo(() => {

    const newTypes = {};
    types.map((item: any) => {
      newTypes[item.id] = companiesSummary[item.id] ?? 0
    })

    return Object.entries(newTypes)

  }, [companiesSummary, types])






  const formatAmount = (amount: number) : string => {
    return `${currency?.sign ?? ''} ${formatNumber(amount)}`
  }



  const getCPName = (typeId: any): string => {
    return types.find((i: any) => i.id === +typeId)?.name ?? ''
  }



  const getCPColor = (typeId: any): string => {
    return ProviderColorHex[typeId]?.color ?? '#657274'
  }



  const columns = useMemo(() => [
    {
      label: 'Closing date',
      value: formatDate(project.closing_date, false, true)
    },
    {
      label: 'Capital Required',
      value: formatAmount(project.capital_required)
    },
    {
      label: 'Valuation',
      value: formatAmount(project.recent_valuation)
    },
  ], [project.closing_date, project.capital_required, project.recent_valuation, currency?.sign])




  return (
    <div className={classNames(
      classes.root,
      props.className,
      {[classes.mobileRoot]: windowSize.isMobile}
    )}>
      <div className={classes.totalProgress}>
        <span>Total Progress</span>
        <div className={classes.progressLine}>
          <span>{Math.round(progress)}%</span>
          <ProgressLine progress={progress} />
        </div>
      </div>

      <div className={classes.progressColumns}>
        {columns.map((column: any, index: number) => (
          <div key={column.label} className={classes.progressColumn}>
            <span>{column.label}</span>
            <span>{column.value}</span>
          </div>
        ))}

        {!props.noCP
          ? (
              <ClickAwayListener onClickAway={() => setOpenedCP(false)}>
                <div className={classNames(classes.progressColumn, classes.columnCP)} onClick={() => setOpenedCP(!openedCP)}>
                  <span>Capital Providers</span>
                  <span>{companyCount}</span>
      
                  <div className={classNames(
                    classes.cpList,
                    {[classes.cpListOpened]: openedCP}
                  )}>
                    {cpData.map(([typeId, count]: any) => (
                      <div key={typeId} className={classes.cpItem}>
                        <span className={classes.cpCounter} style={{color: getCPColor(typeId)}}>{count}</span>
                        <span className={classes.cpLabel}>{getCPName(typeId)}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </ClickAwayListener>
            )
          : null
        }
      </div>
    </div>
  )
}


export default withStyles(styles)(BoardProgress)