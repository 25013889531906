

const CHANGE_FORM_FIELD = 'CHANGE_FORM_FIELD';
const SET_FORM_DATA = 'SET_FORM_DATA';
const SET_ERRORS = 'SET_FORM_ERRORS';
const SET_LOADING = 'SET_FORM_LOADING';
const CLEAR_FORM = 'CLEAR_FORM';
const CHANGE_MULTIPLE_FIELDS = 'CHANGE_MULTIPLE_FIELDS';


export const buildDefaultFormData = (formId, options) => ({

});



export const buildDefaultFormState = (formId, options) => ({

    error: null,
    validation: null,

    changed: false,
    loading: false,

    data: options.buildDefaultFormData(formId, options),

});

const buildFormReducer = (formId, options) => (state=options.buildDefaultFormState(formId, options), action) => {

    // If this action doesn't belong to our form, skip id
    if (action.formId !== formId) return state;

    switch (action.type) {

        case CHANGE_FORM_FIELD:
            const {name, value} = action;
            return {...state, changed: true, data: {...state.data, [name]: value}};
        case CHANGE_MULTIPLE_FIELDS:
            return {...state, changed: true, data: {...state.data, ...action.updates}};

        case CLEAR_FORM:
            return (options.buildDefaultFormState || buildDefaultFormState)(formId, options);

        case SET_FORM_DATA:
            return {...state, data: action.data};

        case SET_ERRORS:
            return {...state,
                validation: action.validation === undefined ? state.validation : action.validation,
                error: action.error === undefined ? state.error : action.error
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };

        default:
            return state;
    }

};

const buildFieldUpdateAction = (formId, options) => (name, value) => {
    return {
        type: CHANGE_FORM_FIELD,
        name,
        value,
        formId
    }
};


const buildFieldMultipleUpdateAction = (formId, options) => (updates) => {
    return {
        type: CHANGE_MULTIPLE_FIELDS,
        updates,
        formId
    }
};
const buildSetFormDataAction = (formId, options) => (data) => {
    return {
        type: SET_FORM_DATA,
        data,
        formId
    }
};
const buildSetErrorsFunction = (formId, options) => ({error, validation}) => {
    return {
        type: SET_ERRORS,
        error,
        validation,
        formId
    }
};


const buildResetForm = (formId, options) => () => {
    return {
        type: CLEAR_FORM,
        formId
    }
};

const buildSetLoadingFunction = (formId, options) => (loading) => {
    return {
        type: SET_LOADING,
        loading,
        formId
    }
};

export const getFormReducerWithActions = (formId, customOptions={}) => {
    
    const options = {
        buildFormReducer, buildFieldUpdateAction, buildSetErrorsFunction,buildSetFormDataAction,
        buildSetLoadingFunction, buildResetForm, buildDefaultFormState,
        buildDefaultFormData, buildFieldMultipleUpdateAction,
        ...customOptions
    };


    return {
        reducer: options.buildFormReducer(formId, options),
        actionUpdateField: options.buildFieldUpdateAction(formId, options),
        actionSetErrors: options.buildSetErrorsFunction(formId, options),
        actionSetFormData: options.buildSetFormDataAction(formId, options),
        actionSetLoading: options.buildSetLoadingFunction(formId, options),
        actionResetForm: options.buildResetForm(formId, options),
        actionUpdateMultiple: options.buildFieldMultipleUpdateAction(formId, options),
    }

};
