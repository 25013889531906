import React, { useState, FormEvent, useEffect } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useAnchor, useOpen } from '../../shared/hooks';
import Button from '../Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Popover } from '@material-ui/core';
import TextInput from '../TextInput';


const styles = (theme: Theme) => createStyles({
  root: {
    marginRight: 10,
    position: 'relative',
  },
  paper: {
    padding: '20px 15px',
    backgroundColor: '#fff',
    width: 295,
    boxSizing: 'border-box',
    borderRadius: 0,
    boxShadow: '0 8px 16px -1px rgba(189, 194, 196, 0.24), 0 4px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  paperFormOpened: {
    // transform: 'translateY(-40px) !important'
  },
  button: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 2,
    height: 40,
    padding: '8px 11px 8px 16px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  icon: {
    fontSize: 20,
    marginLeft: 2
  },
  actionWrapper: {

  },
  action: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#657274',
    marginBottom: 7,
    display: 'flex',
    cursor: 'pointer',
    transition: 'color .1s ease',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  actionDraft: {
    marginBottom: 17
  },
  actionForClosedForm: {
    marginBottom: 0
  },
  form: {
    transformOrigin: 'top center',
    transition: 'all .1s ease',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // overflow: 'hidden',
    // height: 0
  },
  formOpened: {
    // height: 40
  },
  formInput: {
    borderRadius: '4px 0 0 4px',
  },
  formButton: {
    borderRadius: '0 4px 4px 0',
  },
  formInputRoot: {
    width: '100%'
  },
  actionNoClick: {
    cursor: 'default',
    '&:hover': {
      color: '#657274',
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  onSaveAsDraft: (e: any) => any;
  onSaveAsTemplate: (value: string) => any;
}

const SaveAsTemplateButton = (props: IProps) => {
  const { classes } = props;
  const saveAnchor = useAnchor();
  const saveAsTemplateControls = useOpen();
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<boolean>(false);



  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    if(!value) {
      return setError(true)
    }

    setError(false);
    props.onSaveAsTemplate(value);
    saveAnchor.close();
  }

  const onSaveAsDraft = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    props.onSaveAsDraft(e)
    saveAnchor.close();
  }



  useEffect(() => {
    if(value && error) {
      setError(false)
    }
  }, [value])



  return (
    <div className={classes.root}>
      <Button onClick={saveAnchor.set} className={classes.button}>
        <span>Save</span>
        <KeyboardArrowDownIcon className={classes.icon} />
      </Button>

      <Popover
        open={!!saveAnchor.value}
        anchorEl={saveAnchor.value}
        onClose={saveAnchor.close}
        classes={{
          paper: classNames(
            classes.paper,
            {[classes.paperFormOpened]: saveAsTemplateControls.value}
          )
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <span className={classNames(classes.action, classes.actionDraft)} onClick={onSaveAsDraft}>
          Save as Draft
        </span>

        <div className={classes.actionWrapper}>
          <span
            className={classNames(
              classes.action,
              classes.actionNoClick
              // {[classes.actionForClosedForm]: !saveAsTemplateControls.value}
            )}
            // onClick={saveAsTemplateControls.toggle}
          >
            Save as Template
          </span>
        
          <form
            onSubmit={onSubmit}
            className={classNames(
              classes.form,
              {[classes.formOpened]: saveAsTemplateControls.value}
            )}
          >
            <TextInput
              value={value}
              onChange={setValue}
              error={error}
              className={classes.formInputRoot}
              wrapperClass={classes.formInput}
            />
            <Button primary type="submit" className={classes.formButton}>Save</Button>
          </form>
          
        </div>

      </Popover>
    </div>
  )
}


export default withStyles(styles)(SaveAsTemplateButton)