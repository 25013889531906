import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const DeletedContactsIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="14" height="17" viewBox="0 0 14 17">
      <defs>
        <path id="prefix__a" d="M0 0H14V18H0z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a"/>
        </mask>
        <g fill="currentColor" fillRule="nonzero" mask="url(#prefix__b)">
          <g>
              <path d="M5 4c0 1.636.786 3.089 2 4.001L0 8V2.834C0 1.283 1.258.024 2.81.024h4.159C5.772.936 5 2.377 5 4z" transform="translate(0 9)"/>
              <path d="M10 0C7.79 0 6 1.79 6 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm2 5.2l-.8.8L10 4.8 8.8 6 8 5.2 9.2 4 8 2.8l.801-.8L10 3.2 11.2 2l.8.8L10.8 4 12 5.2z" transform="translate(0 9)"/>
          </g>
        </g>
        <path fill="currentColor" fillRule="nonzero" d="M6.31.62c-1.933 0-3.5 1.566-3.5 3.5 0 1.932 1.567 3.5 3.5 3.5s3.5-1.568 3.5-3.5c0-1.934-1.567-3.5-3.5-3.5z" mask="url(#prefix__b)"/>
      </g>
    </SvgIcon>
  );
};