import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { multiplePeopleEditActions } from '../../../reducers/people/edit-multiple';
import { BulkEditGroupItem } from '../../../shared/interfaces';
import Switch from '../../Switch';


interface IProps {
  [otherProps: string]: any;
}



const BulkEditSwitch = (props: IProps) => {
  const { name, names, ...otherProps } = props;
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.multiplePeople.data);
  const [toggleData, setToggleData] = useState<any>(undefined);




  
  const onToggle = (e: ChangeEvent<HTMLInputElement>) => {
    if(!name && !names?.length) return;

    let value = { [name]: { ...data[name], is_append: !data[name]?.is_append } };
    
    if(!!names?.length) {
      value = names.reduce((acc: object, item: BulkEditGroupItem) => {
        acc[item.name] = { ...data[item.name], is_append: !data[item.name]?.is_append };
        return acc
      }, {})
    }


    dispatchToggle(value)
  }




  const isChecked = (): boolean => {
    return !!names?.[0]?.name
      ? !!data[names[0].name]?.is_append
      : !!data[name]?.is_append;
  }
  


  const dispatchToggle = (newData: any): any => {
    return dispatch(multiplePeopleEditActions.actionUpdateMultiple(newData))
  }




  useEffect(() => {
    if(!name && !names?.length) return;

    const newData = !!names?.length
      ? names
        .map((item: BulkEditGroupItem) => data[item.name])
        .filter((item: BulkEditGroupItem) => !!item)
      : data[name];

    
    if(JSON.stringify(toggleData) !== JSON.stringify(newData)) {
      setToggleData(newData)
    }
  }, [data, name, names, toggleData])




  return useMemo(() => (
    <Switch
      checked={isChecked()}
      onChange={onToggle}
      {...otherProps}
    />
  ), [isChecked(), toggleData])
}


export default BulkEditSwitch