import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const EmptyTemplatesIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="27" height="26" viewBox="0 0 27 26">
      <g fill="currentColor">
        <path d="M0 6.254v19.641h27V6.254H0zm11.143 3.168h12.27v5.52h-12.27v-5.52zm0 7.769h12.27v5.52h-12.27v-5.52zM6.4 9.369c1.551 0 2.813 1.262 2.813 2.813 0 1.551-1.261 2.813-2.813 2.813-1.55 0-2.812-1.262-2.812-2.813 0-1.551 1.261-2.813 2.812-2.813zm0 7.769c1.551 0 2.813 1.262 2.813 2.813 0 1.55-1.261 2.813-2.813 2.813-1.55 0-2.812-1.262-2.812-2.813 0-1.551 1.261-2.813 2.812-2.813zM0 .05L4.623.05 4.623 4.672 0 4.672zM6.205.05L27 .05 27 4.672 6.205 4.672z"/>
        <path d="M6.4 13.413c.68 0 1.231-.552 1.231-1.23 0-.68-.552-1.232-1.23-1.232-.68 0-1.231.552-1.231 1.231s.552 1.23 1.23 1.23zM12.725 11.004L21.83 11.004 21.83 13.36 12.725 13.36zM6.4 21.182c.68 0 1.231-.553 1.231-1.231 0-.679-.552-1.231-1.23-1.231-.68 0-1.231.552-1.231 1.23 0 .68.552 1.232 1.23 1.232zM12.725 18.773L21.83 18.773 21.83 21.129 12.725 21.129z"/>
      </g>
    </SvgIcon>
  );
};