import Request from '../../components/request/Request';
import { API_URL } from '../../constants/api';
import { IPerson } from '../../shared/models/Person';
import { getQueryParamsMap } from "../../utils/query";
import { baseHeaders } from '../helpers/helpers';




function getPeople(body: string = '') {
  return Request.get(`${API_URL}/people/${body}`)
}


function getPeopleByUrl(url: string) {
  if(window.location.host.includes('ft8.snivik')) {
    url = url.replace('http', 'https')
  }
  return Request.get(`${url}`)
}

function getPerson(id: number) {
  return Request.get(`${API_URL}/people/${id}/`)
}

function getPersonDetails(id: number) {
  return Request.get(`${API_URL}/people/${id}/`)
}

function createPerson(person: IPerson) {
  return Request.post(`${API_URL}/people/`, person)
}

function updatePerson(id: number, body: any) {
  return Request.patch(`${API_URL}/people/${id}/`, body)
}

function updatePeople(ids: string, data: any) {
  return Request.patch(`${API_URL}/people/update_multiple/?items=${ids}`, data)
}


function emailPeople(data: any) {
  const params : any = getQueryParamsMap();
  data.to = params.to.split(',')

  const {files=[], ...mainData} = data;
  const formData = new FormData();

  for (const file of files){
    formData.append('files', file);
  }

  for (const field of Object.entries(mainData)){

    const fieldVal : any = field[1];

    formData.set(field[0], fieldVal);
  }

  return Request.post(`${API_URL}/emails/send/`, formData)

}

function emailPeopleAsCompany(data: any) {
  data.is_company_email = 1;
  return emailPeople(data)
}

function replacePerson(person: IPerson) {
  return Request.put(`${API_URL}/people/${person.id}/`, person)
}

function deletePerson(id: number,) {
  return Request.delete(`${API_URL}/people/${id}/`)
}

function deletePeople(ids: number[]) {
  return Request.delete(`${API_URL}/people/multiple/`, { data: { ids } }, true)
}

function importCsv(formData: FormData) {
  return Request.post(`${API_URL}/people/importCsv/`, formData, { 'Content-Type': 'multipart/form-data' })
}

function getFiles(id: number) {
  return Request.get(`${API_URL}/people/${id}/files/`)
}

function createNote(note: any) {
  return Request.post(`${API_URL}/notes/people/`, note)
}

function updateNote(note: any) {
  return Request.patch(`${API_URL}/notes/people/${note.id}/`, note)
}

function deleteNote(id: number) {
  return Request.delete(`${API_URL}/notes/people/${id}/`)
}

function invitePerson(id: number) {
  return Request.post(`${API_URL}/people/${id}/invite/`)
}

function revokePerson(id: number) {
  return Request.delete(`${API_URL}/people/${id}/revoke/`)
}

function assignPeopleToProject(data: any) {
  const params : any = getQueryParamsMap();
  const items = params.items.split(',')

  if(!data || !data.project || !items) return Promise.reject(false);

  return Request.post(`${API_URL}/projects/${data.project}/assign/?people=${items}`)
}

function getPeopleByIds(ids: string) {
  return Request.get(`${API_URL}/people?id=${ids}`)
}

function exportPeople(people: number[]) {
  const body = !!people.length ? { people } : null;
  return Request.downloadFile(`${API_URL}/people/exportAll/`, body)
}

function exportPeopleSearch(body: string, selected: number[]) {
  const data = !!selected.length ? { selected } : null;
  return Request.downloadFile(`${API_URL}/search/people/export/${body}`, data )
}






export default {
  getPeople,
  getPeopleByUrl,
  getPerson,
  getPersonDetails,
  createPerson,
  updatePerson,
  updatePeople,
  emailPeople,
  emailPeopleAsCompany,
  replacePerson,
  deletePerson,
  deletePeople,
  assignPeopleToProject,
  getPeopleByIds,
  
  importCsv,
  getFiles,

  invitePerson,
  revokePerson,

  createNote,
  updateNote,
  deleteNote,

  exportPeople,
  exportPeopleSearch,
}