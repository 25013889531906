import ClearIcon from '@material-ui/icons/Clear';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserTablePrefs } from '../../actions/auth';
import { ARCHIVED_SEARCH } from '../../constants/index';
import { ManageableTableColumn } from '../../shared/interfaces';
import { TableType, TableTypeSelector } from '../../shared/types';
import { Theme } from '../../theme';


const styles = (theme: Theme) => createStyles({
  root: {
    color: '#233539',
    padding: '8px 16px',
    borderTop: '1px solid #d9e4e8'
  },
  closeIcon: {
    width: 18,
    height: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    right: 16,
    transform: 'translateY(-50%) scale(1)',
    cursor: 'pointer',
    transition: 'transform .1s ease',
    '&:hover': {
      transform: 'translateY(-50%) scale(1.3)'
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  collapse: {
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: 10
  },
})

interface IProps extends WithStyles<typeof styles> {
  className?: string;
  onClose: () => any;
  tableType: TableType;
}


const MannageColumnsHeader = (props: IProps) => {
  const { classes, tableType } = props;
  const dispatch = useDispatch();
  const searchItem = useSelector((state: any) => state.searchHistory.current);
  const columns = useSelector((state: any) => state.user.user.table_column_prefs?.[!!searchItem ? ARCHIVED_SEARCH : TableTypeSelector[tableType]] ?? []);
  const tableSelector = useMemo(() => !!searchItem ? ARCHIVED_SEARCH : TableTypeSelector[tableType], [tableType, searchItem])





  
  const toggleAll = (): void => {
    const active = isAllSelected();
    const activeGroups = [] as number[];

    const newColumns = columns.map((item: ManageableTableColumn, idx: number) => {
      if(!!item.group && !active) {
        if(activeGroups.includes(item.group)) {
          return ({...item, active: false})
        } else {
          activeGroups.push(item.group)
          return ({...item, active: true}) 
        }
      }


      return ({...item, active: !idx || !active})
    })

    dispatchSetColumns(newColumns)
  }



  const isAllSelected = (): boolean => {
    const groups = [] as number[];
    return columns
      .map((item: ManageableTableColumn) => {
        if(item.group) {
          if(!groups.includes(item.group)) {
            groups.push(item.group);
            return item
          } else {
            return undefined
          }
        }

        return item

      })
      .filter((item: ManageableTableColumn) => !!item)
      .every((item: ManageableTableColumn) => item.active);
  }





  const selectAllLabel = () => {
    return isAllSelected() ? 'Deselect All' : 'Select All';
  }



  

  const dispatchSetColumns = (newColumns: ManageableTableColumn[]): any => {
    return dispatch(changeUserTablePrefs(tableSelector, newColumns))
  }





  return (
    <div className={classNames(props.className, classes.root)}>
      <div className={classes.header}>
        <span>Manage columns</span>
        <span className={classes.collapse} onClick={toggleAll}>
          {selectAllLabel()}
        </span>
      </div>
      
      <ClearIcon onClick={props.onClose} className={classes.closeIcon} />
    </div>
  )
}


export default withStyles(styles)(MannageColumnsHeader)