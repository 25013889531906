import * as actions from '../../actions/projects/providers';

export const initialState = { 
  providers: [] as any[],
  filteredProviders: [] as any[],
  companies: [] as any[],
  filters: {
    statuses: [] as any[],
    companies: [] as any[]
  },
  summary: [] as any[]
};

let providers;

export default function (state = initialState, action) {
  switch (action.type) {


    case actions.PROJECT_SET_PROVIDERS:
      return {
        ...state,
        providers: action.providers,
        filteredProviders: action.providers
      }


    case actions.PROJECT_UPDATE_PROVIDER:
      providers = [...state.providers].map((el: any) => {
        if (el.id === action.id) {
          el.state = action.state;
        }
        return el
      });
      return {...state, providers}


    case actions.PROJECT_ADD_PROVIDER:
      action.newProvider.person = action.newProvider.person_details
      return {...state, providers: [...state.providers, action.newProvider]}


    case actions.PROJECT_CLEAR_PROVIDERS:
      return initialState

      
    case actions.PROJECT_PREASSIGN_PROVIDER:
      if (state.providers.some((item: any) => item.person.id === action.provider.person.id)) {
        const filteredState = state.providers.filter((item: any) => item.person.id !== action.provider.person.id)
        return [...filteredState, action.provider]
      }
      return {...state, providers: [...state.providers, action.provider]}


    case actions.PROJECT_UPDATE_MULTIPLE_PROVIDERS:
      providers = [...state.providers].map((el: any) => {
        if (action.relationIds.includes(el.id)) {
          el.state = action.state;
        }
        return el
      })

      return {...state, providers}



    case actions.PROJECT_UPDATE_SINGLE_PROVIDER:
      return {
        ...state,
        providers: state.providers.map((item: any) => item.id === action.relationId ? {...item, ...action.data} : item)
      }

    
    case actions.PROJECT_SET_FILTERED_PROVIDERS:
      return {
        ...state,
        filteredProviders: action.providers
      }

    
    case actions.PROJECT_SET_FILTER_STATUSES:
      return {
        ...state,
        filters: {
          ...state.filters,
          statuses: action.statuses
        }
      }

    
    case actions.PROJECT_SET_FILTER_COMPANIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          companies: action.companies
        }
      }


    case actions.PROJECT_SET_COMPANIES:
      return {
        ...state,
        companies: action.companies
      }


    case actions.PROJECT_SET_SUMMARY:
      return {
        ...state,
        summary: action.summary
      }



    default:
      return state
  }
}