import moment from 'moment';
import duplicateService from '../../services/contact-management';
import { ISort } from '../../shared/interfaces';
import { setError } from '../common/notifications';
import { formatSortObject } from '../helpers/helpers';

/**
 * Actions
 */

export const SET_DELETED_CONTACTS           = 'SET_DELETED_CONTACTS';
export const REMOVE_DELETED_CONTACTS        = 'REMOVE_DELETED_CONTACTS';
export const APPEND_DELETED_CONTACTS        = 'APPEND_DELETED_CONTACTS';
export const SET_INITIATED_DELETED_CONTACTS = 'SET_INITIATED_DELETED_CONTACTS';
export const SET_LOADING                    = 'DELETED_CONTACTS_SET_LOADING';



/**
 * Action creators
 */

export function setDeletedContacts(data: any): object {
  return {type: SET_DELETED_CONTACTS, data}
}

export function removeDeletedContacts(ids: number[]): object {
  return {type: REMOVE_DELETED_CONTACTS, ids}
}

export function appendDeletedContacts(data: any): object {
  return {type: APPEND_DELETED_CONTACTS, ...data}
}

export function setInitiated(state: boolean): object {
  return {type: SET_INITIATED_DELETED_CONTACTS, state}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}






/**
 * Async action creators
 */

export const getDeletedContacts = (sort: ISort[], search: string) => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));

    
    return duplicateService.getDeletedContacts(body)
      .then((res: any) => {
        dispatch(setDeletedContacts(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting deleted contacts.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const getNextDeletedContacts = (sort?: ISort[]) => {
  return (dispatch, getState) => {
    const nextURL = getState().contactManagement.deletedContacts.next;
    dispatch(setLoading(true));

    
    return duplicateService.getNextDeletedContacts(nextURL)
      .then((res: any) => {
        dispatch(appendDeletedContacts(res));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while getting deleted contacts.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const reactivateDeletedContacts = (ids: number[]) => {
  return (dispatch, getState) => {
    const body = {
      is_deleted: false,
      reactivated_by: getState().user?.user?.id ?? null,
      reactivated_at: moment.utc().format()
    };
    dispatch(setLoading(true));


    return duplicateService.reactivateDeletedContacts(ids.join(','), body)
      .then((res: any) => {
        dispatch(removeDeletedContacts(ids));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while reactivating deleted contacts.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const deletePermanently = (ids: number[]) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const body = { ids }

    return duplicateService.deletePermanently(body)
      .then((res: any) => {
        dispatch(removeDeletedContacts(ids));
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError('An error occured while reactivating deleted contacts.', err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

