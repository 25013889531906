import Axios from "axios";
import { initialTableColumns } from "../../constants/table-columns";
import { ISort, ManageableTableColumn, SortDirection } from "../../shared/interfaces";
import { SelectableCapitalProviderFields, SelectableCapitalProviderTypes, SelectablePersonFields, SelectablePersonTypes } from "../../shared/models/Person";
import { MembershipState, SelectableProjectFields, SelectableProjectTypes } from "../../shared/models/Project";
import { PrepareObject } from "../../shared/types";
import { getIds } from "../../utils/utils";



export const formatSortObject = (sortFields?: ISort[], search: string = ''): string => {
  if (!sortFields?.length && !search) return '';


  let searchStr;
  if(!!search) {
    searchStr = search[0] === '?' ? search.slice(1, search.length) : search;
  }


  if(!sortFields?.length) return `${searchStr ? `?${searchStr}` : ''}`;
  

  return sortFields.map((sort: ISort, idx: number) => {

    const isAsc = sort.direction === SortDirection.ASC;
    const field = Array.isArray(sort?.column)
      ? sort.column.join(`,${isAsc ? '' : '-'}`)
      : sort.column;

    return `${idx === 0 ? '?ordering=' : ''}${isAsc ? '' : '-'}${field}${idx === sortFields.length - 1 && searchStr ? `&${searchStr}` : ''}`;
  }).join(',')
}




export const formatSearch = (search?: string): string => {
  if (!search) return '';

  return `?search=${search}`
}




export const formatTaskSearch = (projectId: number, search?: string): string => {
  if (!projectId) {
    if (!search) return '';

    return `?search=${search}`;
  }

  if (!search) return `?project=${projectId}`;

  return `?project=${projectId}&search=${search}`
}




export const formatMonthFilter = (year: number, month: number, projectIds?: string): string => {
  const start = new Date(year, month, 1)
  const end = new Date(year, month + 1, 1)
  let body = `?due_date__gte=${start.toISOString().split('T')[0]}&due_date__lte=${end.toISOString().split('T')[0]}`
  if (projectIds) return `${body}&projects=${projectIds}`;

  return body
}




export const prepareFields = (type: PrepareObject, item: any, extraReducer?: any, ignoreCP?: boolean): any => {
  let newItem = { ...item };
  switch (type) {


    /**
     * extraReducer is:
     * {
     *    members: reducers.teamMembers,
     *    providers: reducers.projectProviders,
     *    notes: reducers.notes
     * }
     */
    case PrepareObject.ProjectRequest:
      const { members, providers, notes } = extraReducer;
      SelectableProjectFields.map((field: any) => {
        if (newItem[field]) {
          if (Array.isArray(newItem[field])) {
            newItem[field] = getIds(newItem[field])
          } else {
            newItem[field] = newItem[field].id;
          }
        }
      })

      if (extraReducer) {
        if (members && members.length && !newItem.id) {
          newItem.add_members = members;
        }

        if (providers && providers.length && !newItem.id) {
          newItem.add_cp = providers;
        }

        if (notes && notes.length && !newItem.id) {
          newItem.add_notes = notes;
        }
      }
      

      break;



    // extraReducer is types
    case PrepareObject.ProjectResponse:
      SelectableProjectFields.map((field: any) => {
        if(!newItem[field] || !SelectableProjectTypes[field]) return;


        if(Array.isArray(newItem[field]) && newItem[field]?.[0]?.id === undefined) {
          newItem[field] = newItem[field].map((idx: number) => extraReducer[SelectableProjectTypes[field]].find(i => i.id === idx));
        }


        if(!Array.isArray(newItem[field]) && newItem[field]?.id === undefined) {
          newItem[field] = extraReducer[SelectableProjectTypes[field]].find(i => i.id === newItem[field]);
        }
      });
      break;



    /**
     * extraReducer is:
     * {
     *    people: reducers.people,
     *    capitalProvider: reducers.capitalProvider,
     * }
     */
    case PrepareObject.PersonRequest:
      const { people, capitalProvider } = extraReducer;
      SelectablePersonFields.map((field: any) => {
        if (newItem[field]) {
          if (Array.isArray(newItem[field])) {
            newItem[field] = getIds(newItem[field])
          } else {
            newItem[field] = newItem[field].id;
          }
        }
      })

      if (item.emails) {
        item.emails = item.emails.filter((email) => email != null)
      }
      
      if(ignoreCP) {
        if(!!newItem.capital_provider) {
          newItem.capital_provider = {
            ...newItem.capital_provider,
            currency: newItem.capital_provider?.currency?.id,
            investing_geographies: getIds(newItem.capital_provider?.investing_geographies),
            investment_classes: getIds(newItem.capital_provider?.investment_classes),
            cp_subcategory_types: getIds(newItem.capital_provider?.cp_subcategory_types)
          }
        }

        break;
      }
      
      newItem.capital_provider = {
        ...capitalProvider,
        investing_geographies: getIds(capitalProvider.investing_geographies),
        investment_classes: getIds(capitalProvider.investment_classes),
        cp_subcategory_types: getIds(capitalProvider.cp_subcategory_types)
      }



      Object.keys(newItem).map((key: string) => {
        if(key === 'id') return;

        if(JSON.stringify(newItem[key]) === JSON.stringify(people.person[key]) || (!newItem[key] && !people.person[key])) {
          newItem[key] = undefined;
        }

      })
      
      break;



    case PrepareObject.PersonResponse:
      SelectablePersonFields.map((field: any) => {
        if(!newItem[field] || !SelectablePersonTypes[field]) return;


        if(Array.isArray(newItem[field]) && newItem[field]?.[0]?.id === undefined) {
          newItem[field] = newItem[field].map((idx: number) => extraReducer[SelectablePersonTypes[field]].find(i => i.id === idx));
        }


        if(!Array.isArray(newItem[field]) && newItem[field]?.id === undefined) {
          newItem[field] = extraReducer[SelectablePersonTypes[field]].find(i => i.id === newItem[field]);
        }
      })
      break;



    case PrepareObject.TaskRequest:
      break;



    case PrepareObject.CPRequest:
      SelectableCapitalProviderFields.map((field: any) => {
        if (newItem[field]) {
          if (Array.isArray(newItem[field])) {
            newItem[field] = getIds(newItem[field])
          } else {
            newItem[field] = newItem[field].id;
          }
        }
      })
      break



    
    // extraReducer = getState().types
    case PrepareObject.CPResponse:
      SelectableCapitalProviderFields.map((field: any) => {
        if(!newItem[field] || !SelectableCapitalProviderTypes[field]) return;


        if(Array.isArray(newItem[field]) && newItem[field]?.[0]?.id === undefined) {
          newItem[field] = newItem[field].map((idx: number) => extraReducer[SelectableCapitalProviderTypes[field]].find(i => i.id === idx));
        }


        if(!Array.isArray(newItem[field]) && newItem[field]?.id === undefined) {
          newItem[field] = extraReducer[SelectableCapitalProviderTypes[field]].find(i => i.id === newItem[field]);
        }
      });
      break;


    case PrepareObject.PossibleDuplicatesRequest:
      break;

    
    case PrepareObject.PossibleDuplicatesResponse:

      break;
  }

  return newItem
}




export const listToMemberships = (list: any[]): any[] => {
  if (!list || !list.length) return [];
  return list.map((item: any) => ({ person: item, state: MembershipState.Owner }))
}







const getArrayItems = (duplicates: any[], field: string): any[] => {
  if(!field || !duplicates?.length) return [];

  const items: any[] = [];
  return duplicates.map((duplicate: any) => {

    if(!duplicate[field]) return [];

    return duplicate[field]
      .map((item: any) => {
        if(!items.includes(item)) {
          items.push(item);
          return item
        }

        return undefined
      })
      // .filter((item: any) => item === 0 || !!item)

  })
}


export const formatDuplicates = (duplicates: any): any => {
  if(!duplicates?.length) return [];
  const emails = getArrayItems(duplicates, 'emails');
  const phones = getArrayItems(duplicates, 'phones');


  return duplicates.map((duplicate: any, idx: number) => {
    duplicate.emails = emails[idx];
    duplicate.phones = phones[idx];

    return duplicate
  });
}


export const isCanceled = (err: any): boolean => {
  return Axios.isCancel(err)
}



export const forceDownloadFile = (data: string, fileName: string = ''): void => {
  const url = URL.createObjectURL(new Blob([data], { type: 'text/csv' }))

  const a = document.createElement('a') as HTMLAnchorElement;
  a.href = url;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(url);
  a.remove();
}




export const syncTableColumns = (columns: any): any => {
  const newColumns = Object.entries(initialTableColumns).map(([key, values]: any) => {
    if(!columns[key]) return [key, values];

    let nonExistingValues = [] as ManageableTableColumn[];


    const existingValues = values
      .filter((item: ManageableTableColumn) => {
    
        const existing = columns[key].find((itm: ManageableTableColumn) => itm.type === item.type);
        if(existing) return true;

        nonExistingValues.push(item)
        return false
      })
      .map((item: ManageableTableColumn) => {
        const existing = columns[key].find((itm: ManageableTableColumn) => itm.type === item.type);
        if(existing) {
          return {
            ...item,
            ...existing,
            label: item.label,
            search: item.search,
          }
        }
      })


    nonExistingValues = nonExistingValues.map((item: ManageableTableColumn, idx: number) => ({
      ...item,
      order: existingValues.length + idx
    }))


    const newValues = [...existingValues, ...nonExistingValues]
      .sort((a: ManageableTableColumn, b: ManageableTableColumn) => a.order - b.order);
    return [key, newValues]
  })
  
  return Object.fromEntries(newColumns)
}