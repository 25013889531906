import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../constants/api';
import { baseHeaders, processResponse } from './helpers/helpers';

function login(data: object) : Promise<AxiosResponse> {
  return axios
    .post(`${API_URL}/auth/token`, data)
    .then(processResponse);
}

function logout() : Promise<AxiosResponse<any>> {
  return axios
    .post(`${API_URL}/auth/logout`, null, baseHeaders())
    .then(processResponse)
}

function getMe(id: number) : Promise<AxiosResponse<any>> {
  return axios
    .get(`${API_URL}/people/${id}/`, baseHeaders())
    .then(processResponse)
}

function resetPassword(data: object) : Promise<AxiosResponse<any>> {
  return axios
    .post(`${API_URL}/auth/reset-password`, data)
    .then(processResponse)
}

function requestResetPassword(data: object) : Promise<AxiosResponse<any>> {
  return axios
    .post(`${API_URL}/auth/request-reset-password`, data)
    .then(processResponse)
}

function updateUser(id: number, data: any): Promise<AxiosResponse<any>> {
  return axios
    .patch(`${API_URL}/people/${id}/`, data, baseHeaders())
    .then(processResponse)
}




export default {
  login,
  logout,
  getMe,
  resetPassword,
  requestResetPassword,
  updateUser
}