import emailProjectsService from '../../services/projects/email';
import { setError } from '../common/notifications';
import { getErrorCode } from '../../services/helpers/helpers';



export const SET_EMAIL_PROJECTS = 'SET_EMAIL_PROJECTS';



export function setEmailProjects(projects: any[]) {
  return {type: SET_EMAIL_PROJECTS, projects}
}



export const getEmailProjects = (emailTo: string) => {
  return (dispatch) => {
    return emailProjectsService.getEmailProjects(emailTo)
      .then((res: any) => {
        dispatch(setEmailProjects(res.results))
      })
      .catch(err => {
        if(err.response && err.response.status === 400) {
          dispatch(setError('Please, connect Outlook in order to send emails.', err))
        }
        
      })
  }
}