import React, { useEffect } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { RouteComponentProps, withRouter, Switch, Route } from 'react-router';
import ManagementContainer from '../common/ManagementContainer';
import { ROUTE_PREFERENCES_EMAIL_SIGNATURE, ROUTE_PREFERENCES, ROUTE_DASHBOARD } from '../../constants/routes';
import PreferencesEmailSignaturePage from './PreferencesEmailSignaturePage';
import { useSelector } from 'react-redux';
import { isAdmin, isUser } from '../../utils/user';
import { isRoute } from '../../utils/utils';
import { EmailSignatureIcon } from '../../components/icons/EmailSignatureIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  
}

const UserPreferencesContainer = (props: IProps) => {
  const { classes, history } = props;
  const user = useSelector((state: any) => state.user.user);




  const onInit = (): void => {
    if(!isAdmin(user) && !isUser(user)) {
      history.push(ROUTE_DASHBOARD);
      return
    }

    if(isRoute(ROUTE_PREFERENCES, true)) {
      history.push(ROUTE_PREFERENCES_EMAIL_SIGNATURE);
      return
    }
  }






  useEffect(() => {
    onInit();
  }, [])






  const routes = [
    { id: 0, label: 'Email Signature',  icon: EmailSignatureIcon, path: ROUTE_PREFERENCES_EMAIL_SIGNATURE },
  ]


  return (
    <ManagementContainer withPaper title="Preferences" tabs={routes}>
      <Switch>
        
        <Route path={ROUTE_PREFERENCES_EMAIL_SIGNATURE} component={PreferencesEmailSignaturePage} />


      </Switch>
    </ManagementContainer>
  )
}


export default withStyles(styles)(
  withRouter(UserPreferencesContainer)
)