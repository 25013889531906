import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { Component, Fragment, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { setError } from "../../actions/common/notifications";
import { deleteFile, uploadFiles } from "../../actions/files";
import { Theme } from "../../theme";
import ConfirmDialog from "../ConfirmDialog";
import DataCard from "../DataCard";
import FileTypeList from "./FileTypeList";
import { useOpen } from "../../shared/hooks";

const styles = (theme: Theme) => createStyles({
  cardRoot: {
  },
  card: {
  },
  header: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    color: '#233539',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fileListRoot: {
    maxWidth: 'unset',
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0
    }
  },
  separator: {
    height: 16
  }
});

interface IProps extends WithStyles<typeof styles> {
  relationId?: number;
  readonly?: boolean;
  fileTypes: any[];
}


const FileCard = (props: IProps) => {
  const { classes } = props;
  const dialogControls = useOpen();
  const dispatch = useDispatch();
  const files = useSelector((state: any) => state.files.results);
  const [uploading, setUploading] = useState<number|null>(null);
  const [deletableId, setDeletableId] = useState<number|null>(null);
  const [deletableTypeId, setDeletableTypeId] = useState<number|null>(null);
  





  const getFilesByType = (typeId: number) : any => {
    return files?.find(i => i.type === typeId);
  }



  const getFiles = (typeId: number) : any[] => {
    return getFilesByType(typeId)?.results ?? []
  }



  const getCount = (typeId: number) : number => {
    return getFilesByType(typeId)?.count ?? 0
  }



  const onAddFile = (files: any[], type: number) => {
    setUploading(type);

    let filesValid = true;
    files.map(file => {
      if(file.size/1024/1024 > 150) { // 150MB
        dispatchOnError(`File ${file.name} is more than 150MB. Upload failed.`);
        filesValid = false;
      }
    })

    if(filesValid && props.relationId) {
      dispatchFileUpload(files, type, props.relationId)
        .finally(() => setUploading(null))
    } else {
      setUploading(null)
    }
  }



  const onDeleteFile = () => {
    if(!deletableId || !deletableTypeId) return;
    
    dialogControls.toggle();
    return dispatchFileDelete(deletableId, deletableTypeId)
  }



  const onDelete = (id: number, typeId: number) : void => {
    setDeletableId(id);
    setDeletableTypeId(typeId);
    dialogControls.open()
  }



  const dispatchFileUpload = (files: any[], type: number, id: number): any => {
    return dispatch(uploadFiles(files, type, id))
  }



  const dispatchFileDelete = (id: number, typeId: number): any => {
    return dispatch(deleteFile(id, typeId))
  }



  const dispatchOnError = (error: string): any => {
    return dispatch(setError(error))
  }






  const header = (
    <div className={classes.header}>
      <span>Files</span>
    </div>
  )







  return (
    <Fragment>
      <DataCard
        header={header}
        classes={{ root: classes.cardRoot, card: classes.card }}
      >
        {props.fileTypes.map((type: any) => (
          <FileTypeList
            key={type.id}
            classes={{root: classes.fileListRoot}}
            header={type.name}
            files={getFiles(type.id)}
            onAdd={(newFiles: any[]) => onAddFile(newFiles, type.id)}
            onDelete={onDelete}
            pending={uploading === type.id}
            count={getCount(type.id)}
            readonly={props.readonly}
          />
        ))}
      </DataCard>



      <ConfirmDialog
        title="Confirm Delete"
        open={dialogControls.value}
        confirmLabel="Delete"
        onCancel={dialogControls.close}
        onConfirm={onDeleteFile}
      >
        Are you sure you want to delete this file?
      </ConfirmDialog>
    </Fragment>
  );
}


export default withStyles(styles)(FileCard)