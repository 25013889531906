import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSearchHistoryItem, findSearchHistory, getSearchHistory } from '../actions/search-history';
import { useOpen, useAnchor } from '../shared/hooks';
import { Theme } from '../theme';
import ConfirmDialog from './ConfirmDialog';
import SlideInContainerList from './SlideInContainerList';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { highlight } from '../utils/component-utils';
import EditIcon from '@material-ui/icons/MoreHoriz';
import { Popover } from '@material-ui/core';
import Menu from './Menu';
import { clickFix } from '../utils/utils';
import classNames from 'classnames';
import ShareWithPeople from './ShareWithPeople';



const ANIMATION_DURATION = 0.7;


const styles = (theme: Theme) => createStyles({
  '@keyframes slideLeft': {
    '0%': {
      left: 0,
      opacity: 1,
    },
    '100%': {
      left: '-100%',
      opacity: 0,
      width: 0,
    }
  },
  '@keyframes slideRight': {
    '0%': {
      left: 0,
      opacity: 1,
    },
    '100%': {
      left: '100%',
      opacity: 0,
      width: 0,
    }
  },
  '@keyframes rowWrapperExpand': {
    '99%': {
      minHeight: 'unset'
    },
    '100%': {
      minHeight: 45
    }
  },
  '@keyframes expand': {
    '0%': {
      left: 0,
      opacity: 1,
    },
    '50%': {
      left: '-100%',
      opacity: 0
    },
    '100%': {
      padding: '0 15px',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 1,
      height: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column',
    }
  },
  root: {
    top: 56,
    zIndex: 1300,
    height: 'calc(100vh - 56px)',
    maxHeight: 'calc(100vh - 56px)',
    borderTop: '1px solid #d9e4e8'
  },
  rowTitle: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.58,
    color: '#657274',
  },
  rowCloseIcon: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  rowSearchIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginRight: 10
  },
  editWrapper: {
    position: 'absolute',
    top: 10,
    right: 15,
    display: 'flex'
  },
  editIcon: {
    color: '#657274',
    cursor: 'pointer'
  },

  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '12px 35px 12px 15px',
    cursor: 'pointer',
    // transition: 'all 1s ease',
    borderBottom: '1px solid #d9e4e8',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      transition: 'all .1s ease',
      paddingLeft: 22
    }
  },
  rowForShare: {
    transition: 'all 1s ease',
    // padding: '0 15px',
    // position: 'absolute',
    // top: 0,
    left: 0,
    // height: '100%',
    zIndex: 10,
    // border: 0,
    backgroundColor: '#fff',
    // alignItems: 'flex-start',
    // flexDirection: 'column',
    cursor: 'default',
    animation: `${ANIMATION_DURATION * 2}s 1 $expand ease forwards`,
    '&:hover': {
      backgroundColor: '#fff',
      // padding: '0 15px'
    },
    '& $rowWrapper': {
      // minHeight: 45
      animation: `0s 1 ${ANIMATION_DURATION}s $rowWrapperExpand ease forwards`,
      
    }
  },
  rowClosed: {
    transition: 'all 1s ease',
    display: 'none',
    '&:nth-child(even)': {
      animation: `${ANIMATION_DURATION}s 1 $slideLeft ease forwards`,
    },
    '&:nth-child(odd)': {
      animation: `${ANIMATION_DURATION}s 1 $slideRight ease forwards`,
    }
  },
  rowWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
})

interface IProps extends WithStyles<typeof styles> {
  onClose: () => any;
  onOpen: (item: any) => any;
  opened?: boolean;
}

const SearchHistory = (props: IProps) => {
  const { classes } = props;
  const deletedDialogControls = useOpen();
  const shareControls = useOpen();
  const editAnchor = useAnchor();
  const dispatch = useDispatch();
  const [activeItemId, setActiveItemId] = useState<number | undefined>(undefined);
  const [shareItemId, setShareItemId] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const searchHistory = useSelector((state: any) => state.searchHistory.history?.results ?? []);



  const onOpen = (e: any, item: any): void => {
    e.preventDefault();
    e.stopPropagation();

    props.onOpen(item)
  }

  const onAnchorSet = (e: any, item: any): void => {
    if(!item?.id) return;

    setActiveItemId(item.id);
    editAnchor.set(e)
  }


  const onAnchorRemove = (e: any, item: any): void => {
    if(!item?.id) return;

    setActiveItemId(undefined);
    setShareItemId(undefined);
    shareControls.close()
  }


  const onShare = (): void => {
    setShareItemId(activeItemId);
    shareControls.open()
  }
  


  const onConfirmHistoryItemDelete = (): void => {
    if(!activeItemId) return;

    dispatchHistoryItemDelete(activeItemId)
      .then((res: any) => {
        deletedDialogControls.close();
        setActiveItemId(undefined);
      })
  }


  const onSearchChange = (search: string): Promise<any> => {
    setSearch(search);

    if(search) {
      return dispatchFindSearchHistory(search)
    }

    return dispatchGetSearchHistory()
  }



  const dispatchHistoryItemDelete = (id: number): any => {
    return dispatch(deleteSearchHistoryItem(id))
  }

  const dispatchFindSearchHistory = (searchStr: string): any => {
    return dispatch(findSearchHistory(searchStr))
  }

  const dispatchGetSearchHistory = (): any => {
    return dispatch(getSearchHistory())
  }



  return (
    <Fragment>
      <SlideInContainerList
        items={searchHistory}
        header="Archived Searches"
        opened={props.opened}
        itemsName="saved searches"
        onClose={props.onClose}
        onSearchChange={onSearchChange}
        className={classes.root}
        onItemRender={(item: any, idx: number) => (

          <div key={item?.id ? `item-${item.id}` : `idx-${idx}`}
            className={classNames(
              classes.row,
              {[classes.rowForShare]: shareControls.value && item.id === shareItemId},
              {[classes.rowClosed]: shareControls.value && item.id !== shareItemId}
            )}
            onClick={(e: any) => !shareControls.value && onOpen(e, item)}
          >
            <div className={classes.rowWrapper}>
              <SearchIcon className={classes.rowSearchIcon} />

              <span className={classes.rowTitle}>
                {highlight(item.title, search)}
              </span>

              {!shareControls.value && (
                <div onClick={clickFix} className={classes.editWrapper}>
                  <EditIcon className={classes.editIcon} onClick={(e: any) => onAnchorSet(e, item)} />
                </div>
              )}
            </div>

            {shareItemId && shareItemId === item.id && (
              <ShareWithPeople
                id={shareItemId}
                onClose={(e: any) => onAnchorRemove(e, item)}
              />
            )}

          </div>

        )}
      />
      
      <ConfirmDialog
        title="Confirm Delete"
        open={deletedDialogControls.value}
        confirmLabel="Delete"
        onCancel={deletedDialogControls.close}
        onConfirm={onConfirmHistoryItemDelete}
      >
        Are you sure you want to delete this search history item?
      </ConfirmDialog>

      <Menu anchor={editAnchor}
        options={[
          { label: 'Share Search', onClick: onShare},
          { label: 'Remove', onClick: deletedDialogControls.open}
        ]}
        onSelect={(e: any, option: any) => option.onClick()}
        optionValue="label"
      />
    </Fragment>
  )
}


export default withStyles(styles)(SearchHistory)