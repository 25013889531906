import React from 'react';
import { SvgIcon } from "@material-ui/core";

interface IProps {
  color?: string;
  className?: any;
}

export const BlueDanger = (props: IProps) => {
  const { color, ...svgProps } = props;
  return <SvgIcon {...svgProps} width="70" height="70" viewBox="0 0 70 70">
    <g fill="none" fillRule="evenodd">
        <circle cx="35" cy="35" r="35" fill="#E8F2FD"/>
        <g fill="#4192EC" fillRule="nonzero">
            <path d="M51.916 36.925L41.593 20.382A7.142 7.142 0 0 0 35.5 17a7.142 7.142 0 0 0-6.093 3.382L19.084 36.925A7.16 7.16 0 0 0 18 40.721c0 3.958 3.22 7.177 7.178 7.177h20.644c3.958 0 7.178-3.22 7.178-7.177a7.16 7.16 0 0 0-1.084-3.796zm-6.094 8.923H25.178a5.133 5.133 0 0 1-5.127-5.127c0-.962.267-1.899.773-2.71l10.323-16.543a5.102 5.102 0 0 1 4.353-2.417c1.782 0 3.409.903 4.353 2.417l10.323 16.543a5.133 5.133 0 0 1-4.353 7.836z"/>
            <path d="M38.114 22.554a3.064 3.064 0 0 0-2.614-1.452c-1.07 0-2.046.542-2.614 1.452L22.564 39.097a3.08 3.08 0 0 0 2.613 4.7h20.645a3.08 3.08 0 0 0 2.614-4.7L38.114 22.554zm-1.589 17.073h-2.05v-2.05h2.05v2.05zm0-4.102h-2.05V28.28h2.05v7.246z"/>
        </g>
    </g>
  </SvgIcon>
};
