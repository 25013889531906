import authService from '../services/auth';
import { setError } from './common/notifications';
import { ManageableTableColumn, ISort } from '../shared/interfaces';


/**
 * Actions
 */

export const AUTH_LOGIN                   = "AUTH_LOGIN"
export const AUTH_LOGOUT                  = "AUTH_LOGOUT"
export const UPDATE_USER                  = "UPDATE_USER"
export const AUTH_RESET_PASSWORD          = "AUTH_RESET_PASSWORD"
export const AUTH_RESET_PASSWORD_FAILED   = "AUTH_RESET_PASSWORD_FAILED"
export const AUTH_REQUEST_RESET_PASSWORD  = "AUTH_REQUEST_RESET_PASSWORD"

export const SET_SETTINGS                 = "USER_SET_SETTINGS";
export const SET_TABLE_PREFS              = "USER_SET_TABLE_PREFS";
export const CHANGE_TABLE_PREFS           = "CHANGE_TABLE_PREFS";


/**
 * Action creators
 */

export function authLogin(res: any): object {
  const { token, user } = res
  return {type: AUTH_LOGIN, token, user};
}

export function authLogout(): object {
  return {type: AUTH_LOGOUT};
}

export function updateUser(user: any): object {
  return {type: UPDATE_USER, user}
}

export function authResetPassword(user: any): object {
  return {type: AUTH_RESET_PASSWORD, user}
}

export function authResetPasswordFailed(errors: string[]): object {
  return {type: AUTH_RESET_PASSWORD_FAILED, errors}
}

export function authRequestResetPassword(email: string): object {
  return {type: AUTH_REQUEST_RESET_PASSWORD, email}
}

export function setUserSettings(data: any): object {
  return {type: SET_SETTINGS, data}
}

export function setUserTablePrefs(tableColumns: any): object {
  return {type: SET_TABLE_PREFS, tableColumns}
}

export function changeUserTablePrefs(tableType: string, columns: ManageableTableColumn[]): object {
  return {type: CHANGE_TABLE_PREFS, tableType, columns}
}



/**
 * Async action creators
 */

export const login = (data: object): any => {
  return (dispatch) => {
    return authService.login(data)
      .then((res: any) => {
        dispatch(authLogin(res));
        return Promise.resolve(true);
      })
      .catch(err => {
        dispatch(setError("Login failed. Please, check your email and password.", err))
        return Promise.reject(false);
      })
  }
}



export const logout = () => {
  return (dispatch) => {
    return authService.logout()
      .then((res: any) => {
        dispatch(authLogout());
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        dispatch(setError("Logout failed.", err))
        return Promise.reject(err);
      })
  }
}


export const updateCurrentUser = () => {
  return (dispatch, getState) => {
    const id = getState().user?.user?.id;
    if(id === undefined) return;

    return authService.getMe(id)
      .then((res: any) => {
        dispatch(updateUser(res))
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError("Failed to refresh user profile.", err));
        return Promise.reject(err)
      })
  }
}

export const resetPassword = (data: object) => {
  return (dispatch) => {
    return authService.resetPassword(data)
      .then((res: any) => {
        dispatch(authResetPassword(res));
        return Promise.resolve(true);
      })
      .catch(err => {
        dispatch(authResetPasswordFailed(err.response.data));
        dispatch(setError("Error occured creating password", err))
        return Promise.reject(false);
      })
  }
}

export const requestResetPassword = (email: string) => {
  return (dispatch) => {
    return authService.requestResetPassword({email})
      .then((res: any) => {
        dispatch(authRequestResetPassword(email));
        return Promise.resolve(true);
      })
      .catch(err => {
        // dispatch(setError("Email was sent to you mailbox.", err))
        return Promise.reject(false);
      })
  }
}