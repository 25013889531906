import { getIds } from "../../utils/utils";
import { setError, setSuccess } from "./notifications";


const onSuccessBuilder = (dispatch, actions, options) => () => {
    dispatch(actions.actionResetForm());
    return dispatch(setSuccess({message: options.successMessage || 'Success'}))
};


const onErrorBuilder = (dispatch, actions, options) => () => {
    return dispatch(setError(options.failedMessage || 'Failed'))
};


export const sanitizeData = (data: any): any => {
    const sanitizedData = {};
    

    Object.entries(data).map(([key, value]: any) => {
        if(Array.isArray(value)) {

            // if it's an array of object with ids then extract an array of ids
            if(!!value?.[0]?.id) {
                sanitizedData[key] = getIds(value);
            } else {
                sanitizedData[key] = value;
            }

        }

        if(typeof value === 'string' && !!value) {
            sanitizedData[key] = value;
        }

        if(typeof value === 'number') {
            sanitizedData[key] = value;
        }

        if(typeof value === 'boolean') {
            sanitizedData[key] = value;
        }


        
        // if value is specifically an object (cause typeof arrayVar === "object")
        if(value instanceof Object && !(value instanceof Array)) {

            if(value?.id !== undefined) {

                sanitizedData[key] = value.id === -1 ? undefined : value.id

            } else {
                sanitizedData[key] = value;
            }
        }

    })
    
    return sanitizedData
}


export const updateFromFormGenerator = (form, actions, serviceAction, options={}) => dispatch => {

    const sanitizedData = sanitizeData(form.data);
    const handlerCreators = {
            onSuccessBuilder, onErrorBuilder, ...options
    };

    dispatch(actions.actionSetLoading(true));


    return serviceAction(sanitizedData)
        .then(handlerCreators.onSuccessBuilder(dispatch, actions, options))
        .catch(handlerCreators.onErrorBuilder(dispatch, actions, options))
        .finally(()=>dispatch(actions.actionSetLoading(false)));


};