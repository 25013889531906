import Request from "../components/request/Request";
import { API_URL } from "../constants/api";
import { baseHeaders } from "./helpers/helpers";


function getActivity(body: string) {
  return Request.get(`${API_URL}/activity/${body}`)
}

function getNextActivity(url: string) {
  return Request.get(url)
}

function exportActivity(actions: number[]) {
  const data = !!actions.length ? { actions } : null;
  return Request.downloadFile(`${API_URL}/activity/exportAll/`, data);
}


export default {
  getActivity,
  getNextActivity,

  exportActivity,
}
