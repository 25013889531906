import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getValues, initialSubRow, SearchPeopleType, SearchPerson, SEARCH_OPTIONS_FIELDS } from '../../../../shared/models/Search';
import { ProfileType } from '../../../../shared/types';
import { Theme } from '../../../../theme';
import { getIds, isCP } from '../../../../utils/utils';
import Select from '../../../Select';
import { useSearchData } from '../hooks/advanced-search-hooks';

const styles = (theme: Theme) => createStyles({
  root: {
    minWidth: 250,
    maxWidth: 250
  },
})

interface IProps extends WithStyles<typeof styles> {
  idx: number;
  subRowIdx?: number;
  [otherProps: string]: any;
}

const SearchSelectValueSelect = (props: IProps) => {
  const { classes, idx, subRowIdx, ...otherProps } = props;
  const { currentRow, onChange } = useSearchData(idx, subRowIdx);
  const types = useSelector((state: any) => state.types);


  

  const categoriesIds = useMemo((): number[] => {
    if(!types?.subcategories || currentRow.field?.id !== SearchPerson.ContactType) return [];

    const categoriesIds = types.subcategories.map((item: any) => item.category);
    const distinctValues = new Set(categoriesIds) as Set<number>;
    return [...distinctValues]
  }, [types?.subcategories, currentRow.field?.id])




  const options = useMemo((): any => (
    getValues(currentRow.field?.id, types, { categoryId: currentRow.args?.categoryId })
  ), [currentRow, types])




  const value = useMemo((): any => {
    if(!currentRow?.value) return;

    if(Array.isArray(currentRow.value)) {
      return currentRow.value
    }

    return options.find((value: any) => value.id === currentRow.value.id)
  }, [options, types, currentRow.value])





  const handleChange = useCallback((option: any): void => {
    switch(currentRow.field.id) {


      case SearchPerson.UserType:
        if(getIds(option).includes(ProfileType.Client)) {
          return onChange({
            value: option,
            subRows: [initialSubRow({ field: SEARCH_OPTIONS_FIELDS[SearchPeopleType.People_People_ClientClass] })]
          })
        }
        break;


      case SearchPerson.ContactType:
        let subRows = [] as any[];
        const ids = getIds(option);

        if(!!ids.length && !!categoriesIds.length) {
          categoriesIds.map((categoryId: number) => {
            if(ids.includes(categoryId)) {
              const existing = currentRow.subRows?.find((item: any) => item.args.categoryId === categoryId);
              const initial = initialSubRow({
                field: SEARCH_OPTIONS_FIELDS[SearchPeopleType.People_People_Subcategory],
                args: { categoryId }
              })

              subRows.push(existing ?? initial)
              
            }

            return categoryId
          }) 
        }

        
        if(option.some(isCP)) {
          const existingCPRows = currentRow.subRows.filter((item: any) => item.args.is_cp);
          const initial = [initialSubRow({ args: { is_cp: true } })];
          subRows = [
            ...subRows,
            ...(!!existingCPRows.length ? existingCPRows : initial)
          ]
        }

        return onChange({ value: option, subRows })
    }


    return onChange({ value: option, subRows: [] })
  }, [categoriesIds, currentRow])





  return useMemo(() => (
    <Select
      checkboxes textOverflow
      placeholder="Select Value"
      value={value}
      valuesList={options}
      className={classes.root}
      onSelect={handleChange}
      {...otherProps}
    />
  ), [value, options, handleChange])
}


export default withStyles(styles)(SearchSelectValueSelect)