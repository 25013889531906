import React, { useMemo } from 'react';
import { DateFormat } from '../../../shared/types';
import DateInput from '../../DateInput';
import { useProjectData } from '../hooks/projects-hooks';
import { EntityField } from '../../../shared/interfaces';


const ProjecInfoDateInput = ({ name, ...otherProps }: EntityField) => {
  const { value, readonly, error, onChange } = useProjectData(name);




  return useMemo(() => (
    <DateInput
      value={value}
      readonly={readonly}
      placeholder="Select Date..."
      onChange={onChange}
      error={error}
      {...otherProps}
    />
  ), [value, error, readonly])
}


export default ProjecInfoDateInput