import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { clearSelectedForList } from '../../actions/common/list';
import { setLoadedPage } from '../../actions/common/loading';
import { deleteMultipleDrafts, getEmailDrafts, getNextDrafts } from '../../actions/email-drafts';
import ConfirmDialog from '../../components/ConfirmDialog';
import { EmptyDraftsIcon } from '../../components/icons/EmptyDraftsIcon';
import List from '../../components/list/List';
import { ROUTE_EMAIL_MANAGEMENT_DRAFTS } from '../../constants/routes';
import requestManager from '../../middleware/requestManager';
import { useOpen } from '../../shared/hooks';
import { ISort } from '../../shared/interfaces';
import { TableType } from '../../shared/types';
import { Theme } from '../../theme';
import ManageableTableCard from '../common/ManageableTableCard';


const styles = (theme: Theme) => createStyles({
  list: {
    boxShadow: 'none',
    margin: 0
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  className?: string;
}

const EmailDraftsPage = (props: IProps) => {
  const { classes } = props;
  const deleteControls = useOpen();
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.search.search);
  const drafts = useSelector((state: any) => state.emailManagement.drafts);
  const pagesLoaded = useSelector((state: any) => state.loading.pagesLoaded);
  const selected = useSelector((state: any) => state.list[TableType.DRAFTS].selected);




  const onDraftOpen = (e: MouseEvent<HTMLDivElement>, id: number): void => {
    e.preventDefault();
    e.stopPropagation();

    window.open(`/email/drafts/${id}`, '_blank', 'height=720,width=900', false);
  }



  const onConfirmDelete = (): void => {
    dispatchDeleteMultipleDrafts(selected)
      .then(() => {
        deleteControls.close();
        dispatchClearSelected()
      })
  }




  const onSort = (sort: ISort[], search: string): Promise<any> => {
    return dispatchGetDrafts(sort, search)
      .then(() => {
        if(!pagesLoaded[ROUTE_EMAIL_MANAGEMENT_DRAFTS]) {
          dispatchOnPageLoaded(ROUTE_EMAIL_MANAGEMENT_DRAFTS)
        }
      })
  }




  const dispatchGetDrafts = (sort: ISort[], search: string): any => {
    return dispatch(getEmailDrafts(sort, search))
  }



  const dispatchDeleteMultipleDrafts = (ids: number[]): any => {
    return dispatch(deleteMultipleDrafts(ids))
  }


  
  const dispatchOnPageLoaded = (page: string): any => {
    return dispatch(setLoadedPage(page))
  }


  
  const dispatchGetNextDrafts = (): Promise<any> => {
    return dispatch(getNextDrafts())
  }



  const dispatchClearSelected = (): any => {
    return dispatch(clearSelectedForList(TableType.DRAFTS))
  }






  useEffect(() => {
    return () => {
      requestManager.cancelAllRequests();
    }
  }, [])




  

  const actions = [
    { label: 'Delete', onClick: deleteControls.open, disabled: !selected.length, popover: !selected.length && "Select a draft first" },
  ]


  const itemsEmpty = {
    icon: EmptyDraftsIcon,
    title: 'No email drafts',
    description: search ? 'No drafts found.' : 'Email drafts will be displayed here.'
  }





  return (
    <ManageableTableCard
      isEmpty={drafts.initiated && !drafts.results.length && search === undefined}
      actions={actions}
      itemsEmpty={itemsEmpty}
    >


      
      <List absolutePagination fullAccess selectable
        loading={drafts.loading}
        initialLoading={!pagesLoaded[ROUTE_EMAIL_MANAGEMENT_DRAFTS]}
        className={classes.list}
        rows={drafts.results}
        count={drafts.count ?? drafts.results.length}
        tableType={TableType.DRAFTS}
        onSort={onSort}
        onNext={dispatchGetNextDrafts}
        onOpen={onDraftOpen}
      />
      


      <ConfirmDialog
        title="Confirm Delete"
        open={deleteControls.value}
        confirmLabel="Delete"
        onCancel={deleteControls.close}
        onConfirm={onConfirmDelete}
      >
        Are you sure you want to delete {selected.length > 1 ? 'these drafts' : 'this draft'}?
      </ConfirmDialog>


    </ManageableTableCard>
  )
}


export default withStyles(styles)(
  withRouter(EmailDraftsPage)
)