import { useDispatch, useSelector } from "react-redux";
import { changePersonField } from "../../../actions/people/people";

export const usePersonData = (name: string): any => {
  const dispatch = useDispatch();
  const { person, updatedPerson, validationErrors, personView: { readonly } } = useSelector((state: any) => state.people);
  const value = updatedPerson[name] !== undefined ? updatedPerson[name] : person[name];
  const error = (!!validationErrors[name] && !!Object.keys(validationErrors[name]).length && validationErrors[name]) || person.errors?.[name];



  const onChange = (val: any): any => {
    return dispatch(changePersonField({ [name]: val }))
  }



  return { value, error, person, readonly, dispatch, updatedPerson, onChange, validationErrors }
}