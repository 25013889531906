import moment from 'moment';
import { assignPeopleToProjectActions } from '../../reducers/people/assignPeopleToProject';
import { emailPeopleActions } from "../../reducers/people/email";
import { multiplePeopleEditActions } from "../../reducers/people/edit-multiple";
import peopleService from '../../services/people/people';
import { INote, ISort } from '../../shared/interfaces';
import { IPerson } from '../../shared/models/Person';
import { MembershipType, PrepareObject } from '../../shared/types';
import { updateFromFormGenerator } from "../common/form";
import { setError, setSuccess } from '../common/notifications';
import { formatSearch, formatSortObject, prepareFields, forceDownloadFile } from '../helpers/helpers';


/**
 * Actions
 */

export const GET_PEOPLE_LIST          = "GET_PEOPLE_LIST";
export const GET_PEOPLE_LIST_DETAILS  = "GET_PEOPLE_LIST_DETAILS";
export const APPEND_PEOPLE            = "APPEND_PEOPLE";
export const UPDATE_PEOPLE_LIST       = "UPDATE_PEOPLE_LIST";
export const UPDATE_PEOPLE            = "UPDATE_PEOPLE";
export const DELETE_PEOPLE            = "DELETE_PEOPLE";

export const GET_PERSON               = "GET_PERSON";
export const GET_PERSON_DETAILS       = "GET_PERSON_DETAILS";
export const CREATE_PERSON            = "CREATE_PERSON";
export const CHANGE_PERSON_FIELD      = "CHANGE_PERSON_FIELD";
export const CHANGE_PERSON            = "CHANGE_PERSON";
export const UPDATE_PERSON            = "UPDATE_PERSON";
export const DELETE_PERSON            = "DELETE_PERSON";
export const REPLACE_PERSON           = "REPLACE_PERSON";
export const CLEAR_PERSON             = "CLEAR_PERSON";
export const INVITE_PERSON            = "INVITE_PERSON";
export const REVOKE_PERSON            = "REVOKE_PERSON";
export const CANCEL_PERSON            = "CANCEL_PERSON";


export const SET_ERRORS               = "SET_ERRORS";
export const SET_VALIDATION_ERROR     = "SET_VALIDATION_ERROR";
export const CLEAR_VALIDATION_ERRORS  = "CLEAR_VALIDATION_ERRORS";

export const PEOPLE_CREATE_NOTE       = "PEOPLE_CREATE_NOTE";
export const PEOPLE_UPDATE_NOTE       = "PEOPLE_UPDATE_NOTE";
export const PEOPLE_DELETE_NOTE       = "PEOPLE_DELETE_NOTE";

export const CLEAR_PEOPLE             = "CLEAR_PEOPLE";
export const CLEAR_TASKS              = "CLEAR_TASKS";
export const CLEAR_TAGS               = "CLEAR_TAGS";

export const SET_LOADING              = "PEOPLE_SET_LOADING";
export const SET_COMPONENT_PEOPLE     = "SET_COMPONENT_PEOPLE";
export const CLEAR_PEOPLE_LOCAL       = "CLEAR_PEOPLE_LOCAL";

export const SET_READONLY             = "PEOPLE_SET_READONLY";




/**
 * Action creators
 */

export function getPeopleList(data: object): object {
  return {type: GET_PEOPLE_LIST, data};
}

export function getSinglePerson(person: IPerson): object {
  return {type: GET_PERSON, person};
}

export function getSinglePersonDetails(person: IPerson): object {
  return {type: GET_PERSON_DETAILS, person};
}

export function appendToPeopleList(data: any): object {
  return {type: APPEND_PEOPLE, ...data};
}

export function updatePeopleList(people: IPerson[]): object {
  return {type: UPDATE_PEOPLE_LIST, people};
}

export function updatePeople(people: any[]): object {
  return {type: UPDATE_PEOPLE, people}
}

export function clearPerson(): object {
  return {type: CLEAR_PERSON};
}

export function changePerson(person: IPerson): object {
  return {type: CHANGE_PERSON, person};
}

export function changePersonField(field: any): object {
  return {type: CHANGE_PERSON_FIELD, field};
}

export function createSinglePerson(person: IPerson): object {
  return {type: CREATE_PERSON, person};
}

export function updateSinglePerson(person: any): object {
  return {type: UPDATE_PERSON, person};
}

export function replaceSinglePerson(person: IPerson): object {
  return {type: REPLACE_PERSON, person};
}

export function deleteSinglePerson(): object {
  return {type: DELETE_PERSON};
}

export function deletePeopleBunch(ids: number[]): object {
  return {type: DELETE_PEOPLE, ids};
}

export function createSingleNote(note: any): object {
  return {type: PEOPLE_CREATE_NOTE, note}
}

export function updateSingleNote(note: any): object {
  return {type: PEOPLE_UPDATE_NOTE, note}
}

export function deleteSingleNote(id: number): object {
  return {type: PEOPLE_DELETE_NOTE, id}
}

export function inviteSinglePerson(id: number): object {
  return {type: INVITE_PERSON, id}
}

export function setPersonErrors(errors: object): object {
  return {type: SET_ERRORS, errors}
}

export function setValidationError(error: object): object {
  return {type: SET_VALIDATION_ERROR, error}
}

export function clearValidationErrors(): object {
  return {type: CLEAR_VALIDATION_ERRORS}
}

export function clearTags(): object {
  return {type: CLEAR_TAGS}
}

export function revokeSinglePerson(id: number): object {
  return {type: REVOKE_PERSON, id}
}

export function setLoading(state: boolean): object {
  return {type: SET_LOADING, state}
}

export function clearPeople(): object {
  return {type: CLEAR_PEOPLE}
}

export function cancelPerson(): object {
  return {type: CANCEL_PERSON}
}

export function setPeopleForComponent(data: any): object {
  return {type: SET_COMPONENT_PEOPLE, data}
}

export function clearPeopleLocal(): object {
  return {type: CLEAR_PEOPLE_LOCAL}
}

export function setReadonly(state: boolean): object {
  return {type: SET_READONLY, state}
}





/**
 * Async action creators
 */

export const searchPeople = (search: string) => {
  return (dispatch) => {
    const body = formatSearch(search);
    dispatch(setLoading(true));


    return peopleService.getPeople(body)
      .then((res: any) => {
        dispatch(getPeopleList(res))
      })
      .catch(err => {
        dispatch(setError("No contacts found", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const searchPeopleLocal = (search: string) => {
  return (dispatch) => {
    const body = formatSearch(search);
    dispatch(setLoading(true));


    return peopleService.getPeople(body)
      .then((res: any) => {
        dispatch(setPeopleForComponent(res))
      })
      .catch(err => {
        
      })
      .finally(() => dispatch(setLoading(false)))
  }
}




export const getPeople = (sort?: ISort[], search: string = '') => {
  return (dispatch) => {
    const body = formatSortObject(sort, search);
    dispatch(setLoading(true));



    return peopleService.getPeople(body)
      .then((res: any) => {
        dispatch(getPeopleList({
          results: res.results, 
          count: res.count,
          next: res.next,
          previous: res.previous,
        }))
      })
      .catch(err => {
        dispatch(setError("No contacts found", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const getPeopleWithUrl = () => {
  return (dispatch, getState) => {
    // dispatch(setLoading(true));
    const nextURL = getState().people.next;

    return peopleService.getPeopleByUrl(nextURL)
      .then((res: any) => {
        dispatch(appendToPeopleList(res))
      })
      .catch(err => {
        dispatch(setError("No contacts found", err))
      })
      // .finally(() => dispatch(setLoading(false)))
  }
}



export const getPersonDetails = (id: number) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));

    return peopleService.getPersonDetails(id)
      .then((res: any) => {
        const { user, ...person } = res;
        const newPerson = prepareFields(PrepareObject.PersonResponse, person, getState().types);
        dispatch(getSinglePersonDetails(newPerson));
        return Promise.resolve(person);
      })
      .catch(err => {
        dispatch(setError("User not found", err))
        return Promise.reject(err.response);
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const createPerson = (person: IPerson) => {
  return (dispatch, getState) => {
    const { people, capitalProvider } = getState();
    const body = prepareFields(PrepareObject.PersonRequest, person, { people, capitalProvider });
    dispatch(setLoading(true));

    return peopleService.createPerson(body)
      .then((res: IPerson) => {
        dispatch(createSinglePerson(res));
        dispatch(appendToPeopleList({
          results: [res]
        }));
        dispatch(setSuccess({
          message: "User successfully created"
        }))
        return Promise.resolve(res.id);
      })
      .catch(err => {
        const errors = err && err.response && err.response.data;
        if(errors) {
          dispatch(setPersonErrors(errors))
        }
        dispatch(setError("Couldn't create a user", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const updatePerson = (person: IPerson) => {
  return (dispatch, getState) => {
    const { people, capitalProvider } = getState();
    const { id, ...body } = prepareFields(PrepareObject.PersonRequest, person, { people, capitalProvider });
    dispatch(setLoading(true));

    return peopleService.updatePerson(id, body)
      .then((res: IPerson) => {
        const response = prepareFields(PrepareObject.PersonResponse, res, getState().types);
        dispatch(updateSinglePerson(response));
        dispatch(setSuccess({
          message: "User successfully updated"
        }))
        return Promise.resolve(res)
      })
      .catch(err => {
        const errors = err && err.response && err.response.data;
        if(errors) {
          dispatch(setPersonErrors(errors))
        }
        dispatch(setError("Couldn't update a user", err))
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const deletePerson = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return peopleService.deletePerson(id)
      .then(() => {
        dispatch(deleteSinglePerson());
        dispatch(setSuccess({
          message: "User successfully deleted"
        }))
        return Promise.resolve(true)
      })
      .catch(err => {
        dispatch(setError("Couldn't delete a user", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const deletePeople = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return peopleService.deletePeople(ids)
      .then((res: IPerson[]) => {
        dispatch(deletePeopleBunch(ids));
        dispatch(setSuccess({
          message: "Users successfully deleted"
        }))
        return Promise.resolve(res)
      })
      .catch(err => {
        dispatch(setError("Couldn't delete users", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const importCsv = (file: File) => {
  return (dispatch) => {
    const fd = fileInFormData(file);
    dispatch(setLoading(true));

    return peopleService.importCsv(fd)
      .then((res: any[]) => {
        dispatch(getPeople());
        dispatch(setSuccess({
          message: res.map((file: any) => 
            `Created: ${file?.created?.length ?? 0} | Duplicated: ${file?.duplicate ?? 0} | Failed: ${file?.failed_lines?.length ?? 0}`
          ).join('  ')
        }));
      })
      .catch(err => {
        dispatch(setError("An error occured while importing your CSV", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const createNote = (note: any, id: number) => {
  return (dispatch) => {
    const body = {
      text: note.text,
      person: id,
      created_at: moment.utc(new Date).format('YYYY-MM-DDTHH:mm:ss')
    };
    dispatch(setLoading(true));

    return peopleService.createNote(body)
      .then((res: INote) => {
        dispatch(createSingleNote(res));
      })
      .catch(err => {
        dispatch(setError("Couldn't create note", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const updateNote = (note: any) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    
    return peopleService.updateNote(note)
      .then((res: INote) => dispatch(updateSingleNote(res)))
      .catch(err => {
        dispatch(setError("Couldn't update note", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const deleteNote = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    
    return peopleService.deleteNote(id)
      .then((res: IPerson[]) => dispatch(deleteSingleNote(id)))
      .catch(err => {
        dispatch(setError("Couldn't delete note", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const invitePerson = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    
    return peopleService.invitePerson(id)
      .then((res: any) => {
        dispatch(inviteSinglePerson(res));
        dispatch(setSuccess({
          message: "Invite sent"
        }))
        return Promise.resolve(true)
      })
      .catch(err => {
        dispatch(setError("Couldn't invite user", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const revokePerson = (id: number) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    
    return peopleService.revokePerson(id)
      .then((res: any) => {
        dispatch(revokeSinglePerson(id));
        dispatch(setSuccess({
          message: "User was revoked"
        }))
        return Promise.resolve(true)
      })
      .catch(err => {
        dispatch(setError("Couldn't revoke user", err))
        return Promise.reject(false)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const searchPeoplePromise = (search: string = '', type?: MembershipType, staffOnly?: boolean) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    
    const body = formatMembershipBody(search, type, staffOnly);
    return peopleService.getPeople(body)
      .then((res: any) => {
        return Promise.resolve(res.results)
      })
      .catch(err => {
        return Promise.reject(err.response)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const getPeopleByIds = (ids: string|number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    
    
    const body = Array.isArray(ids) ? ids.join(',') : ids;
    return peopleService.getPeopleByIds(body)
      .then((res: any) => {
        return Promise.resolve(res.results)
      })
      .catch(err => {
        return Promise.reject(err.response)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const updateEmailSignature = (signature: string = '') => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const id = getState().user?.user?.id;
    if(!id) return;

    const body = { signature };


    return peopleService.updatePerson(id, body)
      .then((res: any) => {
        return Promise.resolve(res)
      })
      .catch(err => {
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const exportPeople = (ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return peopleService.exportPeople(ids)
      .then((res: any) => {
        if(!res) return;
        forceDownloadFile(res, `ft8-contacts-${moment().format('YYYYMMDD')}.csv`)
      })
      .catch(err => {
        console.log(err, err.response)
        dispatch(setError("An error occured while exporting people", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}


export const exportPeopleSearch = (body: string = '', ids: number[]) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return peopleService.exportPeopleSearch(body, ids)
      .then((res: any) => {
        if(!res) return;
        forceDownloadFile(res, `ft8-contacts-${moment().format('YYYYMMDD')}.csv`)
      })
      .catch(err => {
        console.log(err, err.response)
        dispatch(setError("An error occured while exporting search", err))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const syncUserSettings = () => {
  return (dispatch, getState) => {
    const { people, capitalProvider: cp } = getState();
    const user = getState().user.user;
    const { id, table_column_prefs } = prepareFields(PrepareObject.PersonRequest, user, { people, capitalProvider: cp });
    const body = { table_column_prefs };


    return peopleService.updatePerson(id, body)
      .then((res: any) => {
        return Promise.resolve(res)
      })
      .catch(err => {
        console.log("An error occured while synchronizing user's settings", err, err?.response)
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const updateMultiplePeople = (ids: number[], data: any) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
      
    return peopleService.updatePeople(ids.join(','), data)
      .then((res: any) => {
        const types = getState().types;
        const newPeople = res.map((person: any) => prepareFields(PrepareObject.PersonResponse, person, types))
        dispatch(updatePeople(newPeople))

        return Promise.resolve(res)
      })
      .catch(err => {
        if(err?.response?.status !== 500) {
          dispatch(multiplePeopleEditActions.actionSetErrors({ error: err?.response?.data, validation: {} }))
        }

        dispatch(setError("An error occured while updating contacts", err))
        console.log("An error occured while updating contacts", err, err?.response)
        return Promise.reject(err)
      })
      .finally(() => dispatch(setLoading(false)))
  }
}



export const updatePeoplePromise = (form) =>
  updateFromFormGenerator(form, multiplePeopleEditActions, peopleService.updatePeople, {
    successMessage: 'Updated contacts',
    errorMessage: 'Failed to update contacts'
  });


export const sendEmailsPromise = (form) =>
  updateFromFormGenerator(form, emailPeopleActions, peopleService.emailPeople, {
    successMessage: 'Sent emails to contacts',
    errorMessage: 'Failed to send emails to contacts'
  });

export const sendCompanyEmailsPromise = (form) =>
  updateFromFormGenerator(form, emailPeopleActions, peopleService.emailPeopleAsCompany, {
    successMessage: 'Sent company emails to contacts',
    errorMessage: 'Failed to send company emails to contacts'
  });

    

export const assignPeopleToProjectPromise = (form) =>
  updateFromFormGenerator(form, assignPeopleToProjectActions, peopleService.assignPeopleToProject, {
    successMessage: 'Assigned contacts',
    errorMessage: 'Failed to assign contacts'
});


/**
 * Private helping functions
 */

const fileInFormData = (file : File): FormData => {
  const fd = new FormData();
  fd.append('file', file);
  return fd;
}


const formatMembershipBody = (search: string, type?: MembershipType, staffOnly?: boolean): string => {
  if (staffOnly) return `?is_staff=true&search=${search}`
  return `?search=${search}`
}