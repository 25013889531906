import { WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMandatoryOwners } from "../actions/people/membership";
import { IProject } from "../shared/models/Project";
import { Theme } from "../theme";
import FileCard from "./files/FileCard";
import ProjectCapitalProviders from "./ProjectCapitalProviders";
import TeamCard from "./TeamCard";

const styles = (theme: Theme) => createStyles({
  root: {

  },
  dataCard: {
    width: 'unset'
  },
  cardContainer: {
    marginBottom: 30,
  },
  capitalProviderCard: {
    opacity: 1,
    transition: theme.transitions.create(
      ['max-height', 'opacity', 'transform', 'padding']
    ),
    transform: 'scaleY(1)',
    transformOrigin: '50% 0%'
  },
  capitalProviderCardHidden: {
    opacity: 0,
    maxHeight: 0,
    transform: 'scaleY(0)',
    marginBottom: 0
  }
});

interface IProps extends WithStyles<typeof styles> {
  capitalProviders: any[];
  newProject?: boolean;
}

const AdditionalCard = (props: IProps) => {
  const { classes } = props;
  const providers = useSelector((state: any) => state.projectProviders.providers);
  const project = useSelector((state: any) => state.projects.project);
  const team = useSelector((state: any) => state.teamMembers);
  const types = useSelector((state: any) => state.types.project_file_types);
  const dispatch = useDispatch();

  const setDefaultTeam = () : void => {
    dispatch(getMandatoryOwners())
  }

  useEffect(() => {
    if(props.newProject && (!team || !team.length)) {
      setDefaultTeam();
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.cardContainer}>
        <TeamCard 
          team={team}
          projectId={project.id}
        />
      </div>
      
      <div className={classes.cardContainer}>
        <ProjectCapitalProviders
          projectId={project.id}
          providers={providers}
        />
      </div>

      <div className={classes.cardContainer}>
        <FileCard
          readonly={props.newProject}
          relationId={project.id}
          fileTypes={types}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(AdditionalCard)