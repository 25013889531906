import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { deleteTemplate, deleteTemplateFile, getTemplatePromise, saveTemplate, uploadTemplateFiles } from '../../actions/email-templates';
import Button from '../../components/Button';
import StoreDropzone from '../../components/forms/meta/fields/StoreDropzone';
import TinyMCEField from '../../components/forms/meta/fields/TinyMCEField';
import MetaBasedForm from '../../components/forms/meta/MetaBasedForm';
import { ROUTE_EMAIL_MANAGEMENT_TEMPLATES } from '../../constants/routes';
import { currentEmailTemplateActions } from '../../reducers/email/current-template';
import { TinymceType } from '../../shared/types';
import { Theme } from '../../theme';
import Checkbox from '../../components/Checkbox';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '16px 20px 21px',
    background: '#fff',
    boxShadow: '0 4px 8px -1px rgba(189, 194, 196, 0.24), 0 2px 4px 0 rgba(189, 194, 196, 0.16)',
  },
  emailCard: {
    boxShadow: 'none'
  },
  backToTemplates: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.2,
    color: '#90a0a7',
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: 14,
    transform: 'translateX(-6px)',
    cursor: 'pointer'
  },
  backIcon: {
    fontSize: 20
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    color: '#233539',
    display: 'flex',
    marginRight: 20
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  actionSave: {
    fontWeight: 400
  },
  companyCheckbox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  checkboxLabel: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginLeft: 5,
    display: 'flex'
  }
})

interface IProps extends WithStyles<typeof styles>, RouteComponentProps {
  id?: number;
  className?: string;
}

const EmailTemplatePage = (props: IProps) => {
  const { classes, history } = props;
  const dispatch = useDispatch();
  const template = useSelector((state: any) => state.emailManagement.currentTemplate);



  const onFilesUpload = (files: any): void => {
    const newFiles = files.filter((file: any) => file.id === undefined);
    if(!newFiles?.length || !props.id) return;
    dispatchUploadFiles(props.id, newFiles)
  }


  const onFileDelete = (fileId: number): void => {
    if(props.id !== undefined) {
      dispatchDeleteTemplateFile(props.id, fileId)
    }
  }

  const hasError = (name: string): boolean => {
    if(!template.data.id) return false;
    return template.data[name] === ''
  }

  const isFormValid = (): boolean => {
    return !!template.data.name
  }





  const templateMeta = [
    {name: 'name', fullWidth: true, placeholder: 'Template Name*', spacingBottom: true, error: hasError('name')},
    {name: 'subject', fullWidth: true, placeholder: 'Subject', spacingBottom: true},
    {name: 'body', as: TinyMCEField, type: template.data.is_company ? TinymceType.COMPANY_EMAIL : TinymceType.INDIVIDUAL_EMAIL},
    {name: 'files', value: template.data.uploaded_files, onChange: onFilesUpload, as: StoreDropzone, onRemove: onFileDelete}
  ];


  const onGoBack = (): void => {
    return history.push(ROUTE_EMAIL_MANAGEMENT_TEMPLATES)
  }





  const onSaveTemplate = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    if(!template.data.id) return;
  
    if(isFormValid()) {
      dispatch(saveTemplate(template.data))
    }
  
  }

  const onDeleteTemplate = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    if(template.data.id) {
      return dispatchDeleteTemplate(template.data.id)
        .then((res: any) => onGoBack())
        .catch((err: any) => console.log(err))
    }

  }




  const toggleCompanyCheckbox = (): void => {
    if(template.data.is_company === undefined) return;
    dispatch(currentEmailTemplateActions.actionUpdateField('is_company', !template.data.is_company))
  }




  const dispatchDeleteTemplate = (id: number): any => {
    return dispatch(deleteTemplate(id))
  }

  const dispatchGetTemplate = (id: number): any => {
    return dispatch(getTemplatePromise(id))
  }

  const dispatchDeleteTemplateFile = (id: number, fileId: number): any => {
    return dispatch(deleteTemplateFile(id, fileId))
  }

  const dispatchUploadFiles = (id: number, files: any): any => {
    return dispatch(uploadTemplateFiles(id, files))
  }



  useEffect(() => {
    if(props.id !== undefined) {

      dispatchGetTemplate(props.id)
        .then((res: any) => {
          dispatch(currentEmailTemplateActions.actionSetFormData(res))
        })
        .catch((err: any) => onGoBack())
      
      return
    }
  }, [])





  return (
    <div className={classNames(classes.root, props.className)}>

      <div className={classes.backToTemplates} onClick={onGoBack}>
        <KeyboardArrowLeftIcon className={classes.backIcon} />
        <span>Back to Templates</span>
      </div>

      <div className={classes.titleWrapper}>
        <span className={classes.title}>New Template</span>

        <div className={classes.companyCheckbox} onClick={toggleCompanyCheckbox}>
          <Checkbox checked={!!template.data.is_company} />
          <span className={classes.checkboxLabel}>Company email</span>
        </div>
      </div>
      


      <MetaBasedForm
        meta={templateMeta}
        actions={currentEmailTemplateActions}
        store={template}
      />


      <div className={classes.actions}>
        <Button primary className={classes.actionSave} onClick={onSaveTemplate}>
          Save Template
        </Button>

        
        <Button onClick={onDeleteTemplate}>
          Delete Template
        </Button>
      </div>


    </div>
  )
}


export default withStyles(styles)(
  withRouter(EmailTemplatePage)
)