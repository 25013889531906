
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React from "react";
import { useSelector } from "react-redux";
import { Theme } from "../../../../theme";
import FileList from "../../../files/FileList";
import FilesDropzone from "../../../files/FilesDropzone";


const styles = (theme: Theme) => createStyles({
  files: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '11px 0 20px',
  },
  filesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > h2': {
      fontFamily: 'Montserrat',
      fontSize: 11,
      fontWeight: 500,
      lineHeight: 2,
      letterSpacing: 0.53,
      color: '#233539',
      textTransform: 'uppercase',
      marginBottom: 5
    },
    '& > svg': {
      color: theme.palette.primary.main,
      fontSize: 18,
      cursor: 'pointer'
    }
  },
  fileList: {
    marginBottom: 20,
  },
  fileListEmpty: {
    marginBottom: 0
  }
});

const StoreDropzone = (props: any) => {
  const { classes, onChange, onRemove, label="Files", value, getOptions } = props;
  const options = useSelector(getOptions || ((state) => state)) as any;
  const files: any[] = value || [];



  const onRemoveFile = (fileId: number) => {
    onRemove?.(fileId)
  };



  const onAddFiles = (newFiles: any[]): void => {
    onChange([...files, ...newFiles])
  }




  return (
    <div className={classes.files}>
      <div className={classes.filesHeader}>
        <h2>{label}</h2>
      </div>

      <FileList asAttachment
        className={classNames(
            classes.fileList,
            {[classes.fileListEmpty]: !files.length && !options?.loadingFiles}
        )}
        files={files}
        count={files.length}
        onDelete={onRemoveFile}
        uploadPending={options?.loadingFiles}
      />

      <FilesDropzone onChange={onAddFiles} />
    </div>
  )
};

export default withStyles(styles)(StoreDropzone)