import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function getTags() {
  return Request.get(`${API_URL}/utils/tags/`)
}


export default {
  getTags
}