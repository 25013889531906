import Request from "../components/request/Request";
import { API_URL } from "../constants/api";


function searchNext(url: string) {
  return Request.get(url)
}


function searchPeople(body: string) {
  return Request.get(`${API_URL}/search/people/${body}`)
}


function searchProjects(body: string) {
  return Request.get(`${API_URL}/search/projects/${body}`)
}


function searchTasks(body: string) {
  return Request.get(`${API_URL}/search/tasks/${body}`)
}





export default {
  searchNext,
  searchPeople,
  searchProjects,
  searchTasks
}