import { Checkbox, WithStyles } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { createStyles, withStyles } from "@material-ui/styles";
import classNames from 'classnames';
import React, { createRef, MouseEvent, useEffect, useRef, useState, Fragment } from "react";
import { palette, Theme } from "../theme";
import { getIds } from "../utils/utils";
import { highlight } from "../utils/component-utils";



const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    // overflow: 'auto',
  },
  listRoot: {
    maxHeight: 200,
    overflow: 'auto',
    padding: '8px 0',
    transition: 'all .5s ease',
    ...theme.mixins.scrollbar(theme)
  },
  item: {
    ...theme.typography.body1,
    position: 'relative',
    fontSize: 12,
    color: theme.palette.primary.main,
    backgroundColor: palette.skyblue050,
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flex: 1,
    wordBreak: 'break-word',
    transition: theme.transitions.create(
      ['background-color']
    ),
    '&:hover': {
      backgroundColor: '#d7e9fb'
    }
  },
  activeItem: {
    backgroundColor: palette.skyblue100,
  },
  noneItem: {
    fontStyle: 'italic'
  },
  checkboxRoot: {
    '&$checkboxChecked': {
      color: '#4192ec'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& svg': {
      width: 19,
      height: 19,
    }
  },
  checkboxChecked: {},
  checkbox: {

  },
  checkboxIcon: {
    padding: 0,
    marginRight: 8,
    color: '#4192ec',
  },
  withCheckbox: {
    display: 'flex',
    padding: '5px 16px',
  },
  actions: {
    backgroundColor: palette.skyblue050,
    padding: 0,
    borderRadius: '0 0 4px 4px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderTop: '1px solid #d7e9fb',
    position: 'relative'
  },
  action: {
    ...theme.typography.body1,
    flex: 1,
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 500,
    cursor: 'pointer',
    padding: 8,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    transition: 'background-color .1s ease',
    borderLeft: `1px solid #d7e9fb`,
    '&:hover': {
      backgroundColor: '#d7e9fb'
    },
    '&:first-child': {
      borderLeft: 0
    }
  },
  withCheck: {
    backgroundColor: '#d7e9fb'
  },
  checkIcon: {
    fontSize: 16,
    position: 'absolute',
    right: 9,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  header: {
    ...theme.typography.body1,
    fontSize: 12,
    color: theme.palette.primary.main,
    padding: '11px 16px 3px',
    fontWeight: 500,
  },
});

interface IProps extends WithStyles<typeof styles> {
  menu?: any[],
  nameMap?: any,
  onClick: (item: any, idx: number) => void,
  activeId?: number,
  valuesList?: any[],
  checkboxes?: any[],
  onSubmit?: (e: any) => any,
  selectAllLabel?: string,
  onSelectAll?: (e: MouseEvent<HTMLSpanElement>) => any,
  withCheck?: boolean,
  value?: any,
  grouped?: string,
  groupedValuesList?: any[],
  highlightText?: string
}

const SelectMenu = (props: IProps) => {
  const { classes, checkboxes, valuesList, menu, activeId } = props;
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const [offsetHeight, setOffsetHeight] = useState<number>(0);
  const [refs, setRefs] = useState<any[]>([]);
  let listRef = useRef<any>(null);




  const shouldCheck = (value: any): boolean => {
    return !!(props.value?.id !== undefined && props.value?.id === value?.id && props.withCheck)
  }



  const onClick = (e: MouseEvent<HTMLDivElement>, value: any, idx: number): void => {
    e.preventDefault()
    e.stopPropagation()
    props.onClick(value, idx)
  }



  const onActiveItemOffsetChange = (offsetTop: number, offsetHeight: number) => {
    setOffsetTop(offsetTop);
    setOffsetHeight(offsetHeight);
  }



  const getSelectedValues = (): any[] => {
    if(!checkboxes?.length) return [];
    
    const tempCheckboxes = checkboxes.filter((i: any) => !!i);
    return tempCheckboxes[0]?.id !== undefined
      ? getIds(tempCheckboxes)
      : tempCheckboxes.map((item: any) => item.name)
  }




  useEffect(() => {

    if(listRef?.current) {
      const offset = offsetTop + offsetHeight;
      const top = listRef.current.scrollTop;
      const bottom = listRef.current.scrollTop + listRef.current.offsetHeight;

      if(offset > bottom) {
        listRef.current.scrollTop = offset - listRef.current.offsetHeight
      }

      if(offsetTop < top) {
        const ref = activeId && refs?.[activeId - 1];
        listRef.current.scrollTop = offset - (!!ref?.current?.offsetHeight ? ref.current.offsetHeight : listRef.current.offsetHeight)
      }

    }

  }, [offsetTop, activeId])



  useEffect(() => {
    const items = valuesList ?? menu ?? [];
    const newRefs = Array(items.length).fill(0).map((_, i) => refs[i] || createRef())
    setRefs(newRefs)

  }, [valuesList, menu])



  useEffect(() => {

    if(activeId !== undefined && onActiveItemOffsetChange && refs?.[activeId]) {
      const activeRef = refs[activeId].current;
      if(!activeRef) return;
      onActiveItemOffsetChange(activeRef.offsetTop, activeRef.offsetHeight)
    }

  }, [activeId, refs])







  const renderList = () => {
    let content: any = null;


    if (!!valuesList?.length) {
      let lastGroup;
      const selected = getSelectedValues();

      content = valuesList.map((value: any, index: number) => {
        let header: any = null;

        if (!lastGroup || +value.category !== +lastGroup.id) {
          lastGroup = props.groupedValuesList?.find(groupValue => groupValue.id == value.category)
          header = lastGroup?.name ? <div key={`${index}-header`} className={classes.header}>{lastGroup.name}</div> : null
        }
        
        return (
          <Fragment key={index}>
            {header}
            <div ref={refs[index]}
              onClick={(e) => onClick(e, value, index)}
              className={classNames(
                classes.item,
                {
                  [classes.activeItem]: index === activeId,
                  [classes.withCheckbox]: checkboxes
                }
              )}
            >
              {checkboxes
                ? (
                  <div className={classes.checkbox}>
                    <Checkbox
                      checked={selected.includes(value.id ?? value.name)}
                      color="default"
                      className={classes.checkboxIcon}
                      classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checkboxChecked
                      }}
                    />
                  </div>
                )
                : null
              }
              <span>{highlight(value.name, props.highlightText)}</span>


              {shouldCheck(value) && (
                <CheckIcon className={classes.checkIcon} />
              )}
            </div>
          </Fragment>
        )
      })
    }


    if(menu) {
      content = menu.map((item: any, index: number) => (
        <div key={index}
          ref={refs[index]}
          onClick={(e: any) => onClick(e, item, index)}
          className={classNames(
            classes.item,
            { [classes.activeItem]: index === activeId }
          )}
        >
          {props.nameMap != null ? props.nameMap[item.name || item] : item}
        </div>
      ))
    }



    return <>
      <div className={classes.listRoot} ref={listRef}>
        {content}
      </div>
    </>
  }




  return (
    <div className={classes.root}>
      {renderList()}
      {checkboxes
        ? (
          <div className={classes.actions}>
            <span className={classes.action} onClick={props.onSubmit}>
              Apply
              </span>
            <span className={classes.action} onClick={props.onSelectAll}>
              {props.selectAllLabel || 'Select all'}
            </span>
          </div>
        )
        : null
      }
    </div>
  );
}

export default withStyles(styles)(SelectMenu)