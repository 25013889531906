import * as actions from '../actions/search';

export const searchInitialState = {
  search: undefined,
  highlight: false
};

export default function (state = searchInitialState, action) {
  switch (action.type) {

    case actions.SET_SEARCH:
      return {
        ...state,
        search: action.search
      }


    case actions.CLEAR_SEARCH:
      return searchInitialState


    case actions.SET_HIGHLIGHT:
      return {
        ...state,
        highlight: action.state
      }


    default:
      return state
  }
}