import { createStyles, withStyles, WithStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { clearFiles, getFiles, setFilesType } from "../../actions/files";
import { clearMembers } from "../../actions/people/membership";
import { clearProject, clearTags, createNote, createProject, deleteNote, deleteProject, getProjectDetails, setReadonly, updateNote, updateProject } from "../../actions/projects/projects";
import { clearProviders } from "../../actions/projects/providers";
import ConfirmDialog from "../../components/ConfirmDialog";
import ProjectInfo from "../../components/project/project-info/ProjectInfoPage";
import Skeleton from "../../components/Skeleton";
import { ROUTE_PROJECTS } from "../../constants/routes";
import requestManager from "../../middleware/requestManager";
import { useOpen } from "../../shared/hooks";
import { FilesType } from "../../shared/types";
import { Theme } from "../../theme";



const styles = (theme: Theme) => createStyles({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  span: {
    width: 'initial',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    textAlign: 'center',
    color: '#4192ec',
    '&:first-child': {
      color: '#657274',
      fontWeight: 'normal',
      '&:last-child': {
        color: '#657274',
        fontWeight: 'normal'
      }
    }
  },
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  skeletonColumnFirstWrapper: {
    maxWidth: 456,
    minWidth: 296,
  },
  skeletonColumnFirst: {
    flex: 1,
    '&:nth-child(1)': {
      height: 24,
      marginBottom: 8
    },
    '&:nth-child(2)': {
      height: '80vh',
    }

  },
  skeletonColumnSecondWrapper: {
    minWidth: 560,
  },
  skeletonColumnSecond: {
    marginLeft: 16,
    flex: 1,
    '&:nth-child(odd)': {
      marginBottom: 8,
      height: 24
    },
    '&:nth-child(even)': {
      height: '20vh',
      marginBottom: 30
    }
  },
  skeletonColumnThirdWrapper: {
    maxWidth: 456,
    minWidth: 296,
  },
  skeletonColumnThird: {
    flex: 1,
    marginLeft: 16,
    '&:nth-child(odd)': {
      marginBottom: 8,
      height: 24
    },
    '&:nth-child(even)': {
      height: '25vh',
      marginBottom: 30
    }
  }
})

interface IProps extends WithStyles<typeof styles> {
  id?: number;
  createNew?: boolean,
}



const ProjectPage = (props: IProps) => {
  const { classes, id, createNew } = props;
  const dialogControls = useOpen();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { project, updatedProject } = useSelector((state: any) => state.projects);




  useEffect(() => {
    ReactGA.pageview(location.pathname)


    if (createNew) {
      dispatchSetReadonly(false);
      onNewProject();
      return
    }


    if (!!id && id !== project.id) {
      dispatchGetProject(id)
        .then(() => dispatchGetFiles(id))
        .catch((err: any) => {
          if(err.response?.status === 404) {
            history.push(ROUTE_PROJECTS)
          }
        });
    }


    return () => {
      dispatchSetReadonly(true);
  
      requestManager.cancelAllRequests()
    }
  }, [])


  
  
  const saveProject = () => {
    if(createNew) {
      return dispatchCreateProject(updatedProject)
        .then((id: number) => {
          history.push(`/projects/${id}`);
          dispatchSetReadonly(true);
        })
    }

    return dispatchUpdateProject(updatedProject)
      .then(() => dispatchSetReadonly(true))
  }



  const handleSave = () => {
    return saveProject()
  }



  const handleDelete = async () => {
    dispatchDeleteProject(project.id).then(() => history.push(`/projects`))
  }



  const handleCancel = () => {
    if (createNew) {
      return history.push(`/projects`)
    }

    dispatchSetReadonly(true);
  }



  const handleEnableEdit = () => {
    dispatchSetReadonly(false);
  };



  const handleGoToBoard = (e: any) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(`/projects/${project.id}/tasks`)
      return
    }
    history.push(`/projects/${project.id}/tasks`)
  }

  
  
  const handleNoteAdd = (note: any) => {
    if(!note || !project?.id) return;

    dispatchCreateNote(note, project.id);
  }




  const onNewProject = (): void => {
    dispatch(clearProject());
    dispatch(clearProviders());
    dispatch(clearFiles());
    dispatch(clearTags());
    dispatch(clearMembers());
  }




  const dispatchSetReadonly = (state: boolean): any => {
    return dispatch(setReadonly(state))
  }



  const dispatchGetProject = (id: number): any => {
    return dispatch(getProjectDetails(id))
  }


  
  const dispatchCreateProject = (data: any): any => {
    return dispatch(createProject(data))
  }



  const dispatchUpdateProject = (data: any): any => {
    return dispatch(updateProject(data))
  }



  const dispatchDeleteProject = (id: number): any => {
    return dispatch(deleteProject(id))
  }



  const dispatchCreateNote = (note: any, id: number): any => {
    return dispatch(createNote(note, id))
  }



  const dispatchUpdateNote = (note: any): any => {
    return dispatch(updateNote(note))
  }



  const dispatchDeleteNote = (id: number): any => {
    return dispatch(deleteNote(id))
  }



  const dispatchGetFiles = (id: number): any => {
    dispatch(setFilesType(FilesType.Projects))
    dispatch(getFiles(id))
  }








  const renderSkeleton = (): any => {
    return (
      <div className={classes.skeletonWrapper}>
        <div className={classes.skeletonColumnFirstWrapper}>
          <Skeleton count={2} className={classes.skeletonColumnFirst} />  
        </div>
        <div className={classes.skeletonColumnSecondWrapper}>
          <Skeleton count={4} className={classes.skeletonColumnSecond} />
        </div>
        <div className={classes.skeletonColumnThirdWrapper}>
          <Skeleton count={6} className={classes.skeletonColumnThird} />
        </div>
      </div>
    )
  }




  

  if(!createNew && !project?.id) {
    return renderSkeleton()
  }



  if(!project) {
    return null
  }


  

  return (
    <>
      <ProjectInfo
        onGoToBoard={handleGoToBoard}
        onSave={handleSave}
        onCancel={handleCancel}
        onEnableEdit={handleEnableEdit}
        onDelete={dialogControls.toggle}
        onNoteAdd={handleNoteAdd}
        onNoteUpdate={dispatchUpdateNote}
        onNoteDelete={dispatchDeleteNote}
        newProject={createNew}
      />


      <ConfirmDialog
        title="Confirm Delete"
        open={dialogControls.value}
        confirmLabel="Delete"
        onCancel={dialogControls.toggle}
        onConfirm={handleDelete}
      >
        <div className={classes.dialogContent}>
          <span className={classes.span}>Are you sure you want to delete the following project:</span>
          <span className={classes.span}>{project.name}</span>
        </div>
      </ConfirmDialog>
    </>
  )
}



export default withStyles(styles)(ProjectPage)