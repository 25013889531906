import React, { useState, useEffect } from 'react';
import { Theme } from '../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@material-ui/icons/Add';


const styles = (theme: Theme) => createStyles({
  root: {
    border: `1px dashed ${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '33px 0',
    borderRadius: 4,
    cursor: 'pointer',
    outline: 'none',
    transition: 'border-style .1s ease',
    '&:hover': {
      borderStyle: 'solid',
      '& $text': {
        transform: 'scale(1.1)'
      },
      '& $addIcon': {
        transform: 'scale(1.1)',
        marginRight: 5
      }
    },
    '& > input': {
      outline: 'none'
    }
  },
  addIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
    transform: 'scale(1)',
    transition: 'all .1s ease',
  },
  text: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1,
    transition: 'transform .1s ease',
    color: theme.palette.primary.main,
  }
})

interface IProps extends WithStyles<typeof styles> {
  onChange: (files: any[]) => any;
}

const FilesDropzone = (props: IProps) => {
  const { classes } = props;

  const {getRootProps, getInputProps} = useDropzone({
    // preview: URL.createObjectURL(file)
    onDrop: acceptedFiles => {
      props.onChange(acceptedFiles)
    }
  });


  return (
    <div className={classes.root} {...getRootProps()}>
      <input {...getInputProps()} />
      <AddIcon className={classes.addIcon} />
      <span className={classes.text}>Add Attachments</span>
    </div>
  )
};


export default withStyles(styles)(FilesDropzone)