import CheckboxMU from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Theme } from '../theme';
import { clickFix } from '../utils/utils';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: 0
  },
  icon: {
    fontSize: 19
  },
  iconChecked: {
    fontSize: 19,
    color: theme.palette.primary.main
  },
  wrapper: {
    display: 'flex'
  }
})

interface IProps extends WithStyles<typeof styles> {
  [otherProps: string]: any;
}

const Checkbox = (props: IProps) => {
  const { classes, className, ...otherProps } = props;

  const handleClick = (e) => {
    otherProps.onChange()
  }


  return (
    <div className={classes.wrapper}
      onClick={(otherProps.onChange || otherProps.onClick) ? clickFix : undefined}
    >
      <CheckboxMU disableRipple
        color="default"
        icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
        checkedIcon={<CheckBoxIcon className={classes.iconChecked} />}
        className={classNames(classes.root, className)}
        {...otherProps}
        onClick={handleClick}
      />
    </div>
    
  )
}


export default withStyles(styles)(Checkbox)