import React, { Fragment } from 'react';
import { Theme } from '../../../../theme';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import { StatusIcon } from '../../../icons/StatusIcon';
import Menu from '../../../Menu';
import { useAnchor } from '../../../../shared/hooks';
import { TaskTypeNames } from '../../../../shared/types';
import { setFilterStatus } from '../../../../actions/board-filters';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  active: {
    '& > span': {
      color: '#233539 !important'
    }
  },
  activeIcon: {
    color: `${theme.palette.primary.main} !important`
  }
})

const formatStatusFilter = (item: any) : any => ({
  id: +item[0],
  label: item[1]
})

const statusFilters = [
  {
    id: 0,
    label: "All"
  },
  ...Object.entries(TaskTypeNames).map(formatStatusFilter)
]

interface IProps extends WithStyles<typeof styles> {
  className?: any;
  iconClassName?: any;
}

const FilterStatus = (props: IProps) => {
  const { classes } = props;
  const anchor = useAnchor();
  const dispatch = useDispatch();
  const status = useSelector((state: any) => state.board.filters.status);


  const getCurrentFilter = () : any => {
    return statusFilters.find((item: any) => item.id === status);
  }

  const getLabel = () => {
    const filter = getCurrentFilter();
    if(!filter || (filter.id === 0)) return 'Status';
    return filter.label
  }

  const setActiveStatus = (e: any, newStatus: any) : void => {
    dispatch(setFilterStatus(newStatus.id));
  }

  const isFiltered = () : boolean => {
    const filter = getCurrentFilter();
    return filter && filter.id !== 0
  }

  return (
    <Fragment>
      <div
        onClick={anchor.set}
        className={classNames(
          props.className,
          {[classes.active]: isFiltered()}
        )}
      >
        <StatusIcon className={classNames(
          props.iconClassName,
          {[classes.activeIcon]: isFiltered()}
        )} />
        <span>{getLabel()}</span>              
      </div>
      <Menu
        anchor={anchor}
        options={statusFilters}
        onSelect={setActiveStatus}
        optionValue="label"
      />
    </Fragment>
  )
}


export default withStyles(styles)(FilterStatus)