import { useDispatch, useSelector } from "react-redux";
import { useMemo, useCallback } from "react";
import { changeSearchRow, changeSearchSubRow } from "../../../../actions/advanced-search";

export const useSearchData = (idx: number, subRowIdx?: number): any => {
  const dispatch = useDispatch();
  const row = useSelector((state: any) => state.advancedSearch.rows[idx]);
  const subRow = useSelector((state: any) => subRowIdx !== undefined ? state.advancedSearch.rows[idx].subRows?.[subRowIdx] : undefined);
  const currentRow = useMemo((): any => subRow ?? row, [row, subRow]);



  const onChange = useCallback((data: any): any => {
    return subRowIdx === undefined
      ? dispatch(changeSearchRow(idx, data))
      : dispatch(changeSearchSubRow(subRowIdx, idx, data))
  }, [idx, subRowIdx])



  return { dispatch, row, subRow, currentRow, onChange }
}