import React from 'react';
import { SvgIcon } from "@material-ui/core";

export const OpenFolderIcon = (props: any) => {
  return (
    <SvgIcon {...props} width="20" height="15" viewBox="0 0 20 15">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M5.391 7.32c.73-.337 1.429-.506 2.095-.506h8.847V5.113c0-.652-.234-1.213-.702-1.68a2.292 2.292 0 0 0-1.68-.702H8.166v-.34c0-.653-.234-1.213-.701-1.68a2.292 2.292 0 0 0-1.68-.702H2.381C1.73.009 1.17.243.702.71A2.292 2.292 0 0 0 0 2.39v10.208c0 .029.002.073.005.134.004.06.006.104.006.132l.053-.063L3.647 8.59c.433-.51 1.014-.934 1.744-1.27z"/>
        <path d="M19.698 8.313a1.44 1.44 0 0 0-.643-.138H7.485c-.467 0-.976.126-1.525.377-.55.252-.976.559-1.281.92l-3.573 4.211c-.22.248-.33.482-.33.702 0 .213.094.365.282.457.188.093.402.139.643.139h11.57c.468 0 .976-.126 1.525-.378.55-.251.977-.558 1.282-.92l3.572-4.21c.22-.249.33-.482.33-.703a.472.472 0 0 0-.282-.457z"/>
      </g>
    </SvgIcon>
  );
};