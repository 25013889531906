import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { BulkEditRowItem } from '../../../shared/interfaces';
import { Theme } from '../../../theme';
import TextInput from '../../TextInput';
import BulkEditInput from './BulkEditInput';
import BulkEditSwitch from './BulkEditSwitch';

const styles = (theme: Theme) => createStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0
    }
  },
  hidden: {
    display: 'none',
  },
  colLeft: {
    flex: 7.9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  colRight: {
    flex: 2.1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  label: {
    color: '#657274',
    fontSize: 12,
    lineHeight: 1.67,
  },
})

interface IProps extends WithStyles<typeof styles> {
  field: BulkEditRowItem;
  onSwitchToggle: (e: ChangeEvent<HTMLInputElement>) => any;
  noSwitch?: boolean;
  emptySwitch?: boolean;
  error?: string[];
  hidden?: boolean;
}

const BulkEditRow = (props: IProps) => {
  const { classes, field } = props;
  const { as, label, name, names, ...otherProps } = field;



  

  return (
    <div className={classNames(
      classes.row,
      {[classes.hidden]: props.hidden}
    )}>
      <div className={classes.colLeft}>
        {label !== undefined && (
          <span className={classes.label}>{label ? `${label}:` : null}</span>
        )}
        
          <BulkEditInput
            component={field.component ?? TextInput}
            error={props.error}
            name={name}
            names={names}
            fullwidth={props.noSwitch}
            {...otherProps}
          />
      </div>
        



      {!props.noSwitch && (
        <div className={classes.colRight}>

        {!props.emptySwitch && (
          <BulkEditSwitch
            name={name}
            names={names}
            labelChecked="add data"
            labelUnchecked="overwrite"
          />
        )}
        
        </div>
      )}

    </div>
  )
}


export default withStyles(styles)(BulkEditRow)